import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class CircuitDescription extends Component {
  static propTypes = {
    rowData: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.parseHTML = this.parseHTML.bind(this)
    this.state = {};
  }

  parseHTML = (value) => {
    const parser = new DOMParser()
    const desc = parser.parseFromString(value, 'text/html')
    const descArray = _.map(desc.getElementsByTagName('p'), p => p.textContent)
    return descArray.join('\n')
  }

  render() {
    const { rowData } = this.props
    return (
      <tr className="circuitDescription">
        <td>Description</td>
        <td id="desc-cell" colSpan={rowData.length - 3}>{this.parseHTML(rowData[9])}</td>
        <td> </td>
      </tr>
    );
  }
}

export default CircuitDescription
