import {
  GET_PICTURE_RESULTS,
  DELETE_PICTURE_RESULTS,
  RESET_PICTURE,
} from './actions'
import {
  RESET_APP,
} from '../../../../components/api/actions'

const initialState = {
  pictureList: [],
  deletePicture: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_PICTURE_RESULTS:
      return {
        ...state,
        pictureList: action.pictureList,
      }
    case DELETE_PICTURE_RESULTS:
      return {
        ...state,
        deletePicture: action.deletePicture,
      }
    case RESET_PICTURE:
      return {
        ...state,
        deletePicture: null,
      }
    default:
      return state
  }
}
