import React from 'react'
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory as createHistory } from 'history'
import {
  ConnectedRouter,
  routerMiddleware,
} from 'react-router-redux'
import AppReducer from './reducers'
import rootSaga from './sagas'
import Routes from './routes'

const history = createHistory()
const middleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  AppReducer,
  compose(
    applyMiddleware(middleware),
    applyMiddleware(sagaMiddleware),
  ),
)

sagaMiddleware.run(rootSaga)

const Router = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>
)

export default Router
