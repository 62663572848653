import {
  GET_REQUEST_STATUS_LIST_RESULTS,
  GET_REQUEST_RESULTS,
  PUT_REQUEST_RESULTS,
  PUT_REQUEST_ACCEPT_RESULTS,
  PUT_REQUEST_UPDATE_RESULTS,
  DELETE_REQUEST_RESULTS,
  RESET_REQUEST,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  request: [],
  requestStatusList: [],
  putRequest: null,
  putRequestAccept: null,
  putRequestUpdate: null,
  deleteRequest: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_REQUEST_RESULTS:
      return {
        ...state,
        request: action.request,
      }
    case GET_REQUEST_STATUS_LIST_RESULTS:
      return {
        ...state,
        requestStatusList: action.requestStatusList,
      }
    case PUT_REQUEST_RESULTS:
      return {
        ...state,
        putRequest: action.putRequest,
      }
    case PUT_REQUEST_ACCEPT_RESULTS:
      return {
        ...state,
        putRequestAccept: action.putRequestAccept,
      }
    case PUT_REQUEST_UPDATE_RESULTS:
      return {
        ...state,
        putRequestUpdate: action.putRequestUpdate,
      }
    case DELETE_REQUEST_RESULTS:
      return {
        ...state,
        deleteRequest: action.deleteRequest,
      }
    case RESET_REQUEST:
      return {
        ...state,
        putRequest: null,
        putRequestAccept: null,
        putRequestUpdate: null,
        deleteRequest: null,
      }
    default:
      return state
  }
}
