
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import Interweave from 'interweave';
import moment from 'moment'

import stage from '../../../config'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'

const savedImgUrl = stage.imgStorage

class BillHorizon extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripBilling: PropTypes.array,
    companyContent: PropTypes.object,
    userAccount: PropTypes.object,
    informationsContent: PropTypes.object,


  }

  static defaultProps = {
    tripDetails: [],
    tripAllDaysAccomodations: [],
    tripBilling: [],
    companyContent: {},
    userAccount: {},
    informationsContent: {},


  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }


  render() {
    const {
      tripDetails,
      tripAllDaysAccomodations,
      tripBilling,
      companyContent,
      informationsContent,
      userAccount,
    } = this.props


    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      date_creation,
      telephone,
      e_mail,
      society,
      langage,
      code_devis,
      //   langage,
    } = tripDetails[0]

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************
    const slogan = `${langage}_slogan`

    const Nombre_Personne = nb_adulte + nb_enfant + nb_bebe
    const longueurVoyage = tripAllDaysAccomodations.length
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(longueurVoyage, 'd').format('DD MMMM YYYY')
    const nbAdultNumb = nb_adulte && !isNaN(parseInt(nb_adulte, 10)) ? parseInt(nb_adulte, 10) : 0
    const nbChildNumb = nb_enfant && !isNaN(parseInt(nb_enfant, 10)) ? parseInt(nb_enfant, 10) : 0
    const nbBabyNumb = nb_bebe && !isNaN(parseInt(nb_bebe, 10)) ? parseInt(nb_bebe, 10) : 0
    const totalTraveler = nbAdultNumb + nbChildNumb + nbBabyNumb
    const presentation = `${langage}_presentation`

    const prixPersNoReduc = Math.round((tripBilling.sellingPrice / totalTraveler) * 100) / 100
    const Couleurdefaut = {
      color: informationsContent.main_color,
    };

    return (

      <div className="GlobalFileHvt">
        <div className="page-header-horizon">
          <div className="logoTextHorizon">
            <img className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />

            <Interweave content={companyContent[slogan]} />
          </div>
          <hr size="1" />
          <hr className="epaisseurLigne" />
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space" />
              </td>
            </tr>
          </thead>
          <tbody className="mainContentHorizon">
            <tr>
              <td>
                <div className="titreRouge">MODALITES ET CONDITIONS DE PAIEMENT</div>

                <div className="billParaphHorizon">
                  Les conditions générales de vente régissant les rapports entre les agences de voyage et leur clientèle sont
                  préalables à toutes les transactions commerciales, ces conditions font parties intégrantes des contrats,
                  verbales ou écrites entre HORIZON VIETNAM TRAVEL et ses clients.
                </div>

                <div className="soustitreRouge">
                  CONDITIONS DE VENTE
                </div>

                <div className="billParaphHorizon">
                  Dès votre confirmation du séjour, nous vous réclamons un acompte de 20 % du total de votre voyage afin
                  d’enregistrer votre demande. Le paiement sera effectué par le virement bancaire ou par carte bancaire (il
                  faut ajouter 03 % pour Visa et Master Card et 3.85 % pour American Express Card pour le frais bancaire
                  qui ne comprennent pas dans notre tarif). Le solde de votre voyage doit nous parvenir à votre arrivée au
                  Vietnam. Vous facturez le solde soit par carte crédit, soit en liquide mais c’est préférable de payer en
                  liquide. Dès la réception de l’acompte, HORIZON VIETNAM TRAVEL effectuera toutes les réservations
                  nécessaires pour votre séjour et vous fera parvenir la facture avec la signature de son directeur qui sera le
                  contrat ferme du voyage entre HORIZON VIETNAM TRAVEL et Vous.
                </div>
                <div className="soustitreRouge2">A. Facture pro-format</div>
                <Grid container className="encadreBillHorizon" style={Couleurdefaut} spacing={0} justifyContent="center" align="row">
                  <Grid item xs={8}>
                    <div className="encadreTextBillHorizon"> {langageTranslation.groupName} : {nom_responsable_groupe} {prenom_responsable_groupe}</div>
                    <div className="encadreTextBillHorizon">{langageTranslation.groupSize} : {Nombre_Personne}</div>
                    <div className="encadreTextBillHorizon">Nationalité : {society || 'Française'} </div>
                    <div className="encadreTextBillHorizon">Tel: {telephone}</div>
                    <div className="encadreTextBillHorizonBas">E mail Contact: {e_mail} </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="encadreTextBillHorizonDroite">{langageTranslation.codeQuote} : {code_devis}</div>
                    <div className="encadreTextBillHorizonDroite"> {langageTranslation.creationDate} : {date_creation}</div>
                    <div className="encadreTextBillHorizonDroite">Currency : {tripBilling.currency}</div>
                    <div className="encadreTextBillHorizonDroite">Méthode de paiement : par virement
                      bancaire ou par carte bancaire
                    </div>
                    <div className="encadreTextBillHorizonDroiteBas">Conseillère de voyage: {advisorName} </div>
                  </Grid>
                </Grid>

                <br />

                <Grid container style={Couleurdefaut} spacing={0} justifyContent="center">
                  <Grid item xs={12}>
                    <div className="encadreTextBillHorizonCentre">Description</div>

                  </Grid>
                </Grid>
                <Grid container spacing={0} style={Couleurdefaut} className="encadreBillHorizon" justifyContent="center" align="row">
                  <Grid item xs={3}>
                    <div className="encadreTextBillHorizon"> Nom du circuit: </div>
                    <div className="encadreTextBillHorizon"> Durée du circuit: </div>
                    <div className="encadreTextBillHorizonBas"> Type du circuit: </div>

                  </Grid>
                  <Grid item xs={9}>
                    <div className="encadreTextBillHorizonDroite">{tripDetails[0].nom_programme}</div>
                    <div className="encadreTextBillHorizonDroite">Du {dateDepart} au {dateFin}</div>
                    <div className="encadreTextBillHorizonDroiteBas">{langageTranslation.individual}</div>
                  </Grid>
                </Grid>
                <br />
                {tripBilling.tripPrice && (
                  <div>
                    <div className="CoupePas">


                      {tripBilling.displayTabCheck === 0 ? (
                        <div className="TabItineraireGlobal">

                          <Grid container spacing={0} align="row" justifyContent="center">
                            <Grid item xs={12}>
                              <div className="TabItineraireHorizon"> {tripDetails[0].nom_programme}</div>

                            </Grid>
                            <Grid item xs={6}>
                              {totalTraveler !== 0 && (<div className="TabItineraireHorizon"> {langageTranslation.groupSize}</div>)}
                              <div className="TabItineraireHorizon"> {langageTranslation.pricePerPerson}   </div>

                            </Grid>
                            <Grid item xs={6}>
                              {totalTraveler !== 0 && (<div className="TabItineraireHorizon"> {totalTraveler}</div>)}
                              <div className="TabItineraireHorizon">  {prixPersNoReduc} {tripBilling.currency} </div>

                            </Grid>
                            {tripBilling.amountDiscount !== 0 && (
                            <Grid item xs={12}>
                              <div className="TabItineraireHorizonReduc">{langageTranslation.giftDiscount1} {tripBilling.discount} {langageTranslation.giftDiscount2}  </div>
                            </Grid>
                            )}
                            <Grid item xs={6}>
                              {tripBilling.amountDiscount !== 0 ? (<div className="TabItineraireHorizon">{langageTranslation.priceAfterDiscount} </div>)
                                : (
                                  <div className="TabItineraireHorizon">{langageTranslation.pricePerPerson} </div>
                                )}


                            </Grid>
                            <Grid item xs={6}>
                              <div className="TabItineraireHorizon"> {tripBilling.pricePerPerson} {tripBilling.currency}/personne </div>


                            </Grid>
                            <Grid item xs={12}>
                              <div className="TabItineraireHorizon">
                                <div>{langageTranslation.total} </div>
                                <div>  {tripBilling.tripPrice} {tripBilling.currency} </div>
                              </div>
                            </Grid>
                          </Grid>

                        </div>
                      ) : (
                        <Grid container spacing={0} align="row" justifyContent="center">

                          <Grid item xs={12}>
                            <Interweave content={tripBilling.tabPrice} />
                          </Grid>
                        </Grid>
                      )}
                      <br />
                    </div>
                  </div>
                )}

                <div className="soustitreRouge2">Somme et méthode de paiement:</div>

                <Grid className="encadreBillHorizon" style={Couleurdefaut} container spacing={0} justifyContent="center">

                  <Grid item xs={3}>
                    <div className="encadreTextBillHorizon">Dépot</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="encadreTextBillHorizon">{tripBilling.deposit} %</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="encadreTextBillHorizon">{tripBilling.amountDeposit} {tripBilling.currency}</div>
                  </Grid>
                  <Grid className="encadreTextBillHorizon" item xs={5}>
                    <div>Par virement bancaire
                      ou par carte bancaire
                      via Onepay
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="encadreTextBillHorizonBas">Solde du voyage</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="encadreTextBillHorizonBas">{100 - tripBilling.deposit} %</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="encadreTextBillHorizonBas">{tripBilling.tripPrice - tripBilling.amountDeposit} {tripBilling.currency}</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="encadreTextBillHorizonDroiteBas">en liquide ou par carte
                      de crédit
                    </div>
                  </Grid>
                </Grid>
                <br />
                <Interweave content={informationsContent.fr_invoice} />
                <br />
                <div className="titreGras">Preparation par Madame: {advisorName}</div>
                <div className="titreGras">Conseillère en voyage et Responsable de Vente,</div>
                <img className="signatureBillDirecteur" src={`${savedImgUrl}/${companyContent.company}/signaturedirecteur-1652337808288.png`} />
                <div className="titreGras">Pour les plus amples informations ou questions, n'hésitez pas de nous contacter, comme nous restons
                  toujours à votre disposition.
                </div>
                <div className="titreGras">{advisorName}</div>
                <div>{langageTranslation.textConseil}</div>
                <img src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                <Interweave content={userAccount[presentation]} />
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  userAccount: state.userAccountReducer.userAccount,
})

export default withRouter(connect(mapStateToProps)(BillHorizon))
