
const resourcesColumns = [
  {
    title: 'Code',
    field: 'code_devis',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Invoice reference',
    field: 'billingReference',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Invoice creation Date',
    field: 'billingReference_creation_date',
    editable: 'never',
    hidden: true,
    type: 'date',
    resizable: true,
  },
  {
    title: 'Quote',
    field: 'nom_devis',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Quote Creation Date',
    field: 'date_creation',
    type: 'date',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Travel agent',
    field: 'advisor',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Operator',
    field: 'operator',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Client',
    field: 'demandeur',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Traveler number',
    field: 'nbTraveler',
    hidden: true,
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Status',
    field: 'status',
    editable: 'never',
    resizable: true,
    // eslint-disable-next-line consistent-return
    cellStyle: (e, rowData) => {
      if (rowData.status === 'confirmed-booked') {
        return { backgroundColor: '#7C9867' };
      }
      if (rowData.status === 'confirmed') {
        return { backgroundColor: '#ff9494' };
      }
      if (rowData.status === 'in progress') {
        return { backgroundColor: '#0bcdd6' };
      }
    },
  },
  {
    title: 'Selling Price',
    field: 'sellingPrice',
    type: 'currency',
    currencySetting: { currencyCode: 'EUR' },
    editable: 'never',
    resizable: true,
  },
  {
    title: 'benefit',
    field: 'benefit',
    type: 'currency',
    currencySetting: { currencyCode: 'EUR' },
    hidden: true,
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Deposit payed',
    field: 'depositCheck',
    type: 'boolean',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Sold payed',
    field: 'soldPayed',
    type: 'boolean',
    editable: 'never',
    resizable: true,
  },
]

const resourcesInvoiceColumns = [
  {
    title: 'Invoice reference',
    field: 'billingReference',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Invoice creation Date',
    field: 'billingReference_creation_date',
    editable: 'never',
    type: 'date',
    resizable: true,
  },
  {
    title: 'Quote',
    field: 'nom_devis',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Quote Creation Date',
    field: 'date_creation',
    hidden: true,
    type: 'date',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Travel agent',
    field: 'advisor',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Client',
    field: 'demandeur',
    hidden: true,
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Traveler number',
    field: 'nbTraveler',
    hidden: true,
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Trip Cost',
    field: 'tripCost',
    type: 'currency',
    currencySetting: { currencyCode: 'EUR' },
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Selling Price',
    field: 'sellingPrice',
    type: 'currency',
    currencySetting: { currencyCode: 'EUR' },
    editable: 'never',
    resizable: true,
  },
  {
    title: 'benefit',
    field: 'benefit',
    type: 'currency',
    currencySetting: { currencyCode: 'EUR' },
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Deposit payed',
    field: 'depositCheck',
    type: 'boolean',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Sold payed',
    field: 'soldPayed',
    type: 'boolean',
    editable: 'never',
    resizable: true,
  },
]


// eslint-disable-next-line import/prefer-default-export
export { resourcesColumns, resourcesInvoiceColumns }
