export const GET_RESOURCES_RESULTS = 'GET_RESOURCES_RESULTS'
export const PUT_RESOURCES_RESULTS = 'PUT_RESOURCES_RESULTS'
export const DELETE_RESOURCES_RESULTS = 'DELETE_RESOURCES_RESULTS'
export const RESET_RESOURCES = 'RESET_RESOURCES'

export const GET_RESERVATIONS_RESULTS = 'GET_RESERVATIONS_RESULTS'
export const PUT_RESERVATIONS_RESULTS = 'PUT_RESERVATIONS_RESULTS'
export const DELETE_RESERVATIONS_RESULTS = 'DELETE_RESERVATIONS_RESULTS'
export const RESET_RESERVATIONS = 'RESET_RESERVATIONS'

export const getResourcesResults = data => ({
  type: GET_RESOURCES_RESULTS,
  resources: data,
})

export const putResourcesResults = data => ({
  type: PUT_RESOURCES_RESULTS,
  putResources: data,
})

export const deleteResourcesResults = data => ({
  type: DELETE_RESOURCES_RESULTS,
  deleteResources: data,
})

export const resetResources = () => ({
  type: RESET_RESOURCES,
})

export const getReservationsResults = data => ({
  type: GET_RESERVATIONS_RESULTS,
  events: data,
})

export const putReservationsResults = data => ({
  type: PUT_RESERVATIONS_RESULTS,
  putEvent: data,
})

export const deleteReservationsResults = data => ({
  type: DELETE_RESERVATIONS_RESULTS,
  deleteEvent: data,
})

export const resetReservations = () => ({
  type: RESET_RESERVATIONS,
})
