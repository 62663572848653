import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getAllTravelerResults,
  getTravelerByTripResults,
  getTravelerListResults,
  putTravelerContentResults,
  deleteTravelerContentResults,
  deleteTravelersResults,
} from './actions'


function* getAllTravelerAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_all_traveler, token)
  yield put(getAllTravelerResults(data))
}

function* getTravelerByTripAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_traveler_by_trip, token)
  yield put(getTravelerByTripResults(data))
}

function* getTravelerListAsync(params) {
  const {
    token,
    idTrip,
  } = params
  const data = yield call(api.get_traveler_list, token, idTrip)
  yield put(getTravelerListResults(data))
}

function* putTravelerContentAsync(params) {
  const {
    token,
    traveler,
    action,
  } = params
  const data = yield call(api.put_traveler_content, token, traveler, action)
  yield put(putTravelerContentResults(data))
}

function* deleteTravelerContentAsync(params) {
  const {
    token,
    idTrip,
    idTraveler,
  } = params
  const data = yield call(api.delete_traveler_content, token, idTrip, idTraveler)
  yield put(deleteTravelerContentResults(data))
}

function* deleteTravelersAsync(params) {
  const {
    token,
    idTravelersArray,
  } = params
  const data = yield call(api.delete_travelers, token, idTravelersArray)
  yield put(deleteTravelersResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_ALL_TRAVELER', getAllTravelerAsync)
  yield takeEvery('GET_TRAVELER_BY_TRIP', getTravelerByTripAsync)
  yield takeEvery('GET_TRAVELER_LIST', getTravelerListAsync)
  yield takeEvery('PUT_TRAVELER_CONTENT', putTravelerContentAsync)
  yield takeEvery('DELETE_TRAVELER_CONTENT', deleteTravelerContentAsync)
  yield takeEvery('DELETE_TRAVELERS', deleteTravelersAsync)
}

export default fetchResult
