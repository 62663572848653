import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const BootstrapButton = withStyles({
  root: {
    width: '300px',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    margin: '30px 0 10px 0',
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#03838A',
    borderColor: '#03838A',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0bcdd6',
      borderColor: '#0bcdd6',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#03838A',
      borderColor: '#03838A',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(11,204,214,.8)',
    },
  },
})(Button)

export default BootstrapButton
