
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import ApiLoader from './component/ApiLoader';


class MapContainer extends React.Component {
  render() {
    return (
      <div>
        <ApiLoader />

      </div>
    );
  }
}

export default MapContainer
