import axios from 'axios'
import stage from '../../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_extension_list(userId) {
    return axios.get(`${apiUrl}/api/lists/extensionList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_extension_content(token, extensionContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/extension/update`, { extensionContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/extension/create`, { extensionContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_extension_content(token, extensionId) {
    return axios.delete(`${apiUrl}/api/management/extension/delete/${extensionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
