import React from 'react';
import {
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const secondary = '#0bcdd6'
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: secondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: secondary,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: secondary, // secondary
      },
    },
  },
})(TextField);

const Input = props => (
  <CssTextField
    {...props}
  />
)

export default Input;
