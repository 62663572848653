import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import {
  Template,
  ActionButton,
  Loader,
  Input,
  BackButton,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url
const { imgStorage } = stage

class Informations extends PureComponent {
  static propTypes = {
    GET_PICTURE: PropTypes.func.isRequired,
    DELETE_PICTURE: PropTypes.func.isRequired,
    RESET_PICTURE: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    pictureList: PropTypes.array,
    deletePicture: PropTypes.object,
    companyContent: PropTypes.object,
  }

  static defaultProps = {
    pictureList: [],
    deletePicture: {},
    companyContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetMsg = this.resetMsg.bind(this)
    this.startLoader = this.startLoader.bind(this)
    this.stopLoader = this.stopLoader.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deletePictureList = this.deletePictureList.bind(this)
    this.addImgInCheckedList = this.addImgInCheckedList.bind(this)
    this.removeImgInCheckedList = this.removeImgInCheckedList.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.updateList = this.updateList.bind(this)
    this.seachPicture = this.seachPicture.bind(this)
    this.dropImage = this.dropImage.bind(this)
    this.displaySearchBar = this.displaySearchBar.bind(this)
    this.displayPicturesSelected = this.displayPicturesSelected.bind(this)
    this.displayPictures = this.displayPictures.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      pictureListFiltered: [],
      pictureCheckedList: [],
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      validMsg: null,
      validateError: false,
      errorMsg: '',
      pictureSearched: '',
      isLoading: false,
      isReloaded: false,
      isSelectionAction: false,
      nbMaxImg: 1,
    }
  }

  componentDidMount() {
    const {
      history,
      location,
      pictureList,
      companyContent,
      GET_PICTURE,
    } = this.props
    const {
      userId,
    } = this.state
    const { company } = companyContent
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }

    // check if props companyContent exists
    if (_.isEmpty(companyContent)) {
      history.push('/trip')
    }
    // check if props exists
    if (!_.isEmpty(pictureList)) {
      this.setState({
        pictureListFiltered: _.cloneDeep(pictureList),
      })
    } else if (!_.isEmpty(companyContent)) {
      this.setState({
        isLoading: true,
      })
      GET_PICTURE(userId, company)
    }
    if (location.state && location.state.isSelectionAction) {
      const {
        isSelectionAction,
        nbMaxImg,
        currentImg,
      } = location.state
      this.setState({
        isSelectionAction: isSelectionAction,
        nbMaxImg: nbMaxImg,
        pictureCheckedList: !_.isEmpty(currentImg) && !!currentImg[0] ? currentImg.map(img => `${company}/${img}`) : [],
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.state
    const {
      companyContent,
      pictureList,
      deletePicture,
      GET_PICTURE,
      RESET_PICTURE,
    } = this.props
    const { company } = companyContent
    if (!_.isEmpty(deletePicture) && deletePicture.success) {
      this.stopLoader()
      this.deletePictureList(deletePicture.Key)
      RESET_PICTURE()
      GET_PICTURE(userId, company)
    }
    if (!_.isEqual(pictureList, prevProps.pictureList)) {
      this.updateList(pictureList)
    }
  }

  stopLoader = () => {
    this.setState({
      isLoading: false,
    })
  }

  startLoader = () => {
    this.setState({
      isLoading: true,
    })
  }

  updateList = (pictureList) => {
    const {
      isReloaded,
    } = this.state
    this.stopLoader()

    this.setState({
      pictureListFiltered: _.cloneDeep(pictureList),
      validMsg: isReloaded ? 'uplaod success' : false,
      isReloaded: false,
    }, () => this.resetMsg())
  }

  deletePictureList = (Key) => {
    const { pictureListFiltered } = this.state
    const pictureDeletedIndex = pictureListFiltered.findIndex(p => p.Key === Key)
    const pictureListUpdated = [...pictureListFiltered]
    pictureListUpdated.splice(pictureDeletedIndex, 1)
    this.setState({
      validMsg: 'The picture has been deleted.',
      pictureListFiltered: pictureListUpdated,
    }, () => this.resetMsg())
  }

  reload = () => {
    const {
      GET_PICTURE,
      companyContent,
    } = this.props
    const {
      userId,
    } = this.state
    const { company } = companyContent
    this.setState({
      isReloaded: true,
    })
    GET_PICTURE(userId, company)
  }

  resetMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
        validateError: false,
        errorMsg: '',
      })
    }, 2500);
  }

  seachPicture = (event) => {
    const { pictureList } = this.props
    this.setState({
      pictureSearched: event.target.value,
      pictureListFiltered: pictureList.filter(p => p.Key.toLowerCase().includes(event.target.value.toLowerCase())),
    })
  }

  dropImage = (e) => {
    const {
      companyContent,
    } = this.props
    const { files } = e.target
    console.log(files)
    const { company } = companyContent
    // check size under 200ko
    const allSizeOK = Array.from(files).map(file => file.size < 524288)
    if (!_.every(allSizeOK, Boolean)) {
      this.setState({
        validateError: true,
        errorMsg: 'At least one picture size is too big !',
      })
      return null
    }

    // upload image files to API server
    Array.from(files).map((file) => {
      // name format : name-date.extension
      console.log(file)
      const imageName = `${_.split(file.name, '.', 1)}-${Date.now()}.${_.split(file.name, '.')[1]}`
      // upload image file to API server
      const imageFormObj = new FormData()
      imageFormObj.append('imageName', imageName)
      imageFormObj.append('company', company.toLowerCase())
      imageFormObj.append('imageData', file)

      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'multipart-form-data',
        },
      }
      // send image to server
      axios.post(`${apiUrl}/api/upload`, imageFormObj, config)
        .then((res) => {
          if (res.data.error) {
            this.setState({
              validateError: true,
              errorMsg: 'uploading failed !',
            }, () => this.resetMsg())
          }
          if (res.data.uploadSuccess) this.reload()
          // return null
        })
      return true
    })

    // Loader
    this.startLoader()
    return null
  }

  deleteImage = (imgData) => {
    const {
      token,
    } = this.state
    const {
      DELETE_PICTURE,
    } = this.props
    if (confirm('This picture may be used for a trip. Do you really want to delete it anyway ?')) {
      this.startLoader()
      DELETE_PICTURE(token, imgData.Key)
    }
  }

  addImgInCheckedList = (Key) => {
    const {
      pictureCheckedList,
    } = this.state
    this.setState({
      pictureCheckedList: [...pictureCheckedList, Key],
    })
  }

  removeImgInCheckedList = (Key, uncheckedInList = false) => {
    const {
      pictureCheckedList,
      pictureListFiltered,
    } = this.state
    const pictureCheckedUpdated = [...pictureCheckedList]
    pictureCheckedUpdated.splice(pictureCheckedList.indexOf(Key), 1)
    this.setState({
      pictureCheckedList: pictureCheckedUpdated,
    })

    // unchecked picture un filtered list
    if (uncheckedInList) {
      const pictureListUpdated = [...pictureListFiltered]
      pictureListUpdated.map((pic) => {
        if (pic.Key === Key) {
          pic.checked = false
          return pic
        }
        return pic
      })
      this.setState({
        pictureListFiltered: pictureListUpdated,
      })
    }
  }

  handleCheckBox = p => (e) => {
    const {
      pictureListFiltered,
    } = this.state
    const { checked } = e.target
    const pictureDeletedIndex = pictureListFiltered.findIndex(pic => pic.Key === p.Key)
    const pictureListUpdated = [...pictureListFiltered]
    p.checked = !p.checked
    pictureListUpdated.splice(pictureDeletedIndex, 1, p)

    // update checked list
    if (checked) {
      this.addImgInCheckedList(p.Key)
    } else {
      this.removeImgInCheckedList(p.Key, false)
    }

    // update list filtered
    this.setState({
      pictureListFiltered: pictureListUpdated,
    })
  }

  // *************************************************
  goBack = () => {
    const {
      history,
      location,
    } = this.props
    const {
      currentPage,
      state,
      currentImg,
      itemsGoBack,
      goBack,
      idDayLineModified,
    } = location.state
    history.push(currentPage, {
      currentPage,
      state,
      currentImg,
      itemsGoBack,
      goBack,
      idDayLineModified,
    })
  }

  validate = (e) => {
    e.preventDefault()
    const {
      pictureCheckedList,
    } = this.state
    const {
      history,
      location,
    } = this.props
    const {
      currentPage,
      currentImg,
      state,
      itemsGoBack,
      goBack,
      idDayLineModified,
    } = location.state

    // const pictureSelected = [...pictureCheckedList]
    const pictureSelected = pictureCheckedList.map(p => p.split('/')[1])
    history.push(currentPage, {
      currentPage,
      currentImg,
      state,
      pictureSelected,
      itemsGoBack,
      goBack,
      idDayLineModified,
    })
  }

  displaySearchBar = () => {
    const { pictureSearched } = this.state
    return (
      <CssGrid
        container
        spacing={2}
        className="searchbar-div"
      >
        <CssGrid item sm={11} xs={10} className="search-picture-div">
          <Input
            key="search-picture-input"
            label="Search"
            value={pictureSearched}
            onChange={this.seachPicture}
            style={{ width: '100%', marginBottom: '30px' }}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment className="select-unit" position="end">
                  <SearchOutlinedIcon style={{ marginRight: '20px' }} />
                </InputAdornment>
              ),
            }}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={2} className="add-picture-div">
          <label htmlFor="images" style={{ textAlign: 'center', cursor: 'pointer' }}><span style={{ color: 'white' }}>Add image</span></label>
          <input
            id="images"
            type="file"
            multiple
            accept="image/png, image/jpeg, image.jpg"
            style={{ display: 'none' }}
            onChange={this.dropImage}
          />
        </CssGrid>
      </CssGrid>
    )
  }

  displayPicturesSelected = () => {
    const {
      pictureCheckedList,
      isSelectionAction,
      nbMaxImg,
    } = this.state
    if (!isSelectionAction) return null
    return (
      <div className="cart-checked-div">
        <h3 className="picture-checked-title">{`Pictures selected (max: ${nbMaxImg})`}</h3>
        <CssGrid
          container
          spacing={2}
          style={{ justifyContent: 'center' }}
        >
          {pictureCheckedList.map(p => (
            <CssGrid key={`${p.ETag}${p}`} item lg={2} md={3} sm={4} xs={6} className="cart-div">
              <img src={`${imgStorage}/${p}`} style={{ width: '100%' }} alt={p} />
              <CssGrid
                container
                spacing={1}
                className="cart-info-div"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <CssGrid item xs={10}>
                  <p>{p.split('/')[1].slice(0, -18)}</p>
                </CssGrid>
                <CssGrid item xs={2} style={{ cursor: 'pointer' }} onClick={() => this.removeImgInCheckedList(p, true)}>
                  <HighlightOffIcon color="error" />
                </CssGrid>
              </CssGrid>
            </CssGrid>
          ))}
        </CssGrid>
        <div className="validate-button">
          <ActionButton
            label="Validate"
            type="submit"
            disabled={_.isEmpty(pictureCheckedList)}
            onClick={e => this.validate(e)}
          />
        </div>
      </div>
    )
  }

  displayPictures = () => {
    const {
      pictureListFiltered,
      pictureCheckedList,
      isSelectionAction,
      nbMaxImg,
    } = this.state
    return (
      <CssGrid
        container
        spacing={2}
        className="cart-general-div"
        style={{ display: 'flex', justifyContent: 'space-around' }}
      >
        {pictureListFiltered.map((p) => {
          if (_.endsWith(p.Key, '/')) return null
          return (
            <CssGrid key={`${p.ETag}${p.Key}`} item lg={2} md={3} sm={4} xs={6} className="cart-div">
              <img src={`${imgStorage}/${p.Key}`} style={{ width: '100%' }} alt={p.Key} />
              <CssGrid
                container
                spacing={1}
                className="cart-info-div"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                // className={`billingDetailsRow ${index % 2 === 0 ? 'stripe-row' : null}`}
              >
                <CssGrid item xs={10}>
                  <p>{p.Key.split('/')[1].slice(0, -18)}</p>
                </CssGrid>
                <CssGrid item xs={2} style={{ cursor: 'pointer' }} onClick={() => this.deleteImage(p)}>
                  <HighlightOffIcon color="error" />
                </CssGrid>
              </CssGrid>
              {isSelectionAction && pictureCheckedList.length < nbMaxImg && (
                <Checkbox
                  checked={p.checked || false}
                  className="cart-chekbox"
                  onChange={this.handleCheckBox(p)}
                  icon={<CheckBoxOutlineBlankIcon style={{ backgroundColor: 'white', borderColor: 'blue' }} />}
                  checkedIcon={<CheckIcon style={{ backgroundColor: 'white', color: 'blue' }} />}
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
              )}
            </CssGrid>
          )
        })}
      </CssGrid>
    )
  }

  render() {
    const {
      location,
    } = this.props
    const {
      validMsg,
      validateError,
      errorMsg,
      isLoading,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management-gallery">
              <h2>GALLERY</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
            </div>
            {this.displaySearchBar()}
            {this.displayPicturesSelected()}
            {this.displayPictures()}
            {location.state && (
              <BackButton
                type="button"
                label="Back"
                goBack={() => this.goBack()}
              />
            )}
          </div>
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
  pictureList: state.pictureReducer.pictureList,
  deletePicture: state.pictureReducer.deletePicture,
})

const mapDispatchToProps = dispatch => ({
  GET_PICTURE: (userId, company) => dispatch({
    type: 'GET_PICTURE',
    userId,
    company,
  }),
  DELETE_PICTURE: (token, Key) => dispatch({
    type: 'DELETE_PICTURE',
    token,
    Key,
  }),
  RESET_PICTURE: () => dispatch({
    type: 'RESET_PICTURE',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Informations))
