import {
  GET_EXTENSION_LIST_RESULTS,
  PUT_EXTENSION_CONTENT_RESULTS,
  DELETE_EXTENSION_CONTENT_RESULTS,
  RESET_EXTENSION_CONTENT,
} from './actions'
import {
  RESET_APP,
} from '../../../../components/api/actions'

const initialState = {
  extensionList: [],
  putInformationsContent: { success: false },
  deleteExtensionContent: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_EXTENSION_LIST_RESULTS:
      return {
        ...state,
        extensionList: action.extensionList,
      }
    case PUT_EXTENSION_CONTENT_RESULTS:
      return {
        ...state,
        putExtensionContent: action.putExtensionContent,
      }
    case DELETE_EXTENSION_CONTENT_RESULTS:
      return {
        ...state,
        deleteExtensionContent: action.deleteExtensionContent,
      }
    case RESET_EXTENSION_CONTENT:
      return {
        ...state,
        putExtensionContent: { success: false },
        deleteExtensionContent: null,
      }
    default:
      return state
  }
}
