import {
  GET_INFORMATIONS_CONTENT_RESULTS,
  PUT_INFORMATIONS_CONTENT_RESULTS,
  // DELETE_INFORMATIONS_CONTENT_RESULTS,
  RESET_INFORMATIONS_CONTENT,
} from './actions'
import {
  RESET_APP,
} from '../../../../components/api/actions'

const initialState = {
  informationsContent: {},
  putInformationsContent: { success: false },
  // deleteInformationsContent: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_INFORMATIONS_CONTENT_RESULTS:
      return {
        ...state,
        informationsContent: action.informationsContent[0],
      }
    case PUT_INFORMATIONS_CONTENT_RESULTS:
      return {
        ...state,
        putInformationsContent: action.putInformationsContent,
      }
    // case DELETE_INFORMATIONS_CONTENT_RESULTS:
    //   return {
    //     ...state,
    //     deleteInformationsContent: action.deleteInformationsContent,
    //   }
    case RESET_INFORMATIONS_CONTENT:
      return {
        ...state,
        putInformationsContent: { success: false },
        // deleteInformationsContent: { success: false },
      }
    default:
      return state
  }
}
