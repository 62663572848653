/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CssGrid } from '../../../../configCSS'
import {
  Input,
  ActionButton,
  Uploader,
} from '../../../../components'

import stage from '../../../../config'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage

export default function TravelerModal(props) {
  const {
    index,
    type,
    isModalOpen,
    traveler,
    travelerList,
    onModalClose,
    putTraveler,
    userAccount,
  } = props

  const [travelerToChange, setTraveler] = useState({});
  const [newURLArray, setNewURLArray] = useState([]);
  const [newImageArray, setNewImageArray] = useState([]);
  const [errorLastname, setErrorLastname] = useState(false);
  const [errorFirstname, setErrorFirstname] = useState(false);

  useEffect(() => {
    setTraveler(traveler)
  }, [traveler])

  const readOnlyProps = type === 'edit' || type === 'add' ? null : { readOnly: true }

  const handleChange = (e) => {
    const { name, value } = e.target
    setTraveler({
      ...travelerToChange,
      [name]: value,
    })
    if (name === 'lastname') setErrorLastname(false)
    if (name === 'firstname') setErrorFirstname(false)
  }

  const onChangeTraveler = (event, newValue) => {
    // on press Enter
    setTraveler({
      ...travelerToChange,
      lastname: newValue.lastname,
      firstname: newValue.firstname,
      city: newValue.city,
      date_arrival: newValue.date_arrival,
      date_birth: newValue.date_birth,
      date_creation: newValue.date_creation,
      date_exp: newValue.date_exp,
      image: newValue.image,
      job: newValue.job,
      mail: newValue.mail,
      nationality: newValue.nationality,
      id_traveler: newValue.id_traveler,
      passport: newValue.passport,
      phone: newValue.phone,
      sexe: newValue.sexe,
    })
  }


  const filterOptionsTraveler = createFilterOptions({
    matchFrom: 'any',
    stringify: option => option.lastname + option.firstname,
  });

  const onResponseYes = () => {
    const {
      lastname,
      firstname,
    } = travelerToChange
    console.log(travelerToChange)
    if (!lastname) setErrorLastname(true)
    if (!firstname) setErrorFirstname(true)
    if (lastname && firstname) putTraveler(travelerToChange)
  }

  const onResponseNo = () => {
    onModalClose()
  }

  // ************************************************
  // IMAGE
  // Upload and show image

  const getImageUrl = () => {
    const { company } = userAccount
    const { image } = travelerToChange
    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  const saveImage = (imagesDataArray) => {
    setNewURLArray(imagesDataArray.map(img => img.imageUrl))
    setNewImageArray(imagesDataArray.map(img => img.imageName))
    setTraveler({
      ...travelerToChange,
      image: imagesDataArray[0].imageName,
    })
  }

  const deleteImage = (urlArray, imageArray) => {
    setNewURLArray(urlArray)
    setNewImageArray(imageArray)
    setTraveler({
      ...travelerToChange,
      image: imageArray.join(),
    })
  }

  // *************************************

  return (
    <Modal
      aria-labelledby="collaborator-card"
      aria-describedby="collaborator-presentation"
      open={isModalOpen}
      onClose={() => onModalClose()}
    >
      <div className="traveler-modal-container modal">
        <h2>{`Traveler n°${index + 1}`}</h2>
        <RadioGroup
          id={`gender-${index}`}
          aria-label="gender"
          name="sexe"
          row
          value={!_.isEmpty(travelerToChange) && travelerToChange.sexe ? travelerToChange.sexe : ''}
          onChange={handleChange}
        >
          <FormControlLabel value="F" disabled={!!readOnlyProps} control={<Radio />} label="Mme" />
          <FormControlLabel value="M" disabled={!!readOnlyProps} control={<Radio />} label="Mr." />
        </RadioGroup>
        <CssGrid
          container
          spacing={2}
        >
          {type === 'add' && (
          <CssGrid item xs={12}>
            <Autocomplete
              id="filter-traveler"
              options={travelerList}
              getOptionLabel={option => `${option.lastname} ${option.firstname}, creation date: ${option.date_creation}`}
              onChange={onChangeTraveler}
              filterOptions={filterOptionsTraveler}
              renderInput={params => <TextField {...params} label="traveler" variant="outlined" />}
             />
          </CssGrid>
          )}
          <CssGrid item xs={6}>
            <Input
              id={`lastname-${index}`}
              label="Lastname"
              name="lastname"
              required
              value={!_.isEmpty(travelerToChange) && travelerToChange.lastname ? travelerToChange.lastname : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="standard"
              error={errorLastname}
              className="largeWidth"
            />
          </CssGrid>
          <CssGrid item xs={6}>
            <Input
              id={`firstname-${index}`}
              label="Firstname"
              name="firstname"
              required
              value={!_.isEmpty(travelerToChange) && travelerToChange.firstname ? travelerToChange.firstname : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="standard"
              error={errorFirstname}
              className="largeWidth"
            />
          </CssGrid>
        </CssGrid>

        <div style={{ display: 'flex' }}>

          {/* Left Data */}
          <CssGrid item xs={8}>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={5}>
                <Input
                  id={`mail-${index}`}
                  label="Mail"
                  name="mail"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.mail ? travelerToChange.mail : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item xs={5}>
                <Input
                  id={`phone-${index}`}
                  label="Phone"
                  name="phone"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.phone ? travelerToChange.phone : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={5}>
                <Input
                  id={`nationality-${index}`}
                  label="Nationality"
                  name="nationality"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.nationality ? travelerToChange.nationality : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item xs={5}>
                <Input
                  id={`city-${index}`}
                  label="City"
                  name="city"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.city ? travelerToChange.city : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={5}>
                <Input
                  id={`date_birth-${index}`}
                  label="Date of birth"
                  name="date_birth"
                  type="date"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.date_birth ? travelerToChange.date_birth : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item xs={5}>
                <Input
                  id={`job-${index}`}
                  label="Job"
                  name="job"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.job ? travelerToChange.job : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={5}>
                <Input
                  id={`passport-${index}`}
                  label="Passport number"
                  name="passport"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.passport ? travelerToChange.passport : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item xs={5}>
                <Input
                  id={`date_exp-${index}`}
                  label="Date of Expiration"
                  name="date_exp"
                  type="date"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.date_exp ? travelerToChange.date_exp : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={5}>
                <Input
                  id={`date_arrival-${index}`}
                  label="Date of Arrival"
                  name="date_arrival"
                  type="date"
                  value={!_.isEmpty(travelerToChange) && travelerToChange.date_arrival ? travelerToChange.date_arrival : ''}
                  onChange={handleChange}
                  InputProps={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
          </CssGrid>

          {/* Uploader */}
          <CssGrid item md={4} xs={12} className="logo-company-div">
            <p className="logo-label">Passport</p>
            <Uploader
              imageDataArray={!_.isEmpty(travelerToChange) && getImageUrl()}
              saveImage={imagesDataArray => saveImage(imagesDataArray)}
              // redirectToGallery={() => redirectToGallery()}
              deleteImage={(urlArray, imageArray) => deleteImage(urlArray, imageArray)}
              allowDrop
              selectFromDatabase={false}
            />
          </CssGrid>
        </div>
        <div className="modalActions">
          {(type === 'edit' || type === 'add') && (
            <ActionButton
              label="Validate"
              onClick={onResponseYes}
            />
          )}
          <ActionButton
            label={type === 'edit' || type === 'add' ? 'Cancel' : 'Close'}
            onClick={onResponseNo}
          />
        </div>
      </div>
    </Modal>
  );
}

TravelerModal.propTypes = {
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  putTraveler: PropTypes.func.isRequired,
  traveler: PropTypes.object.isRequired,
  travelerList: PropTypes.array.isRequired,
  userAccount: PropTypes.object.isRequired,
};
