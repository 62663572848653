import {
  GET_TRIP_EXTENSION_RESULTS,
  RESET_TRIP_EXTENSION,
  PUT_TRIP_EXTENSION_RESULTS,
} from './actions'
import {
  RESET_APP,
  RESET_TRIP_INFORMATION,
} from '../../../components/api/actions'

const initialState = {
  putTripExtension: { success: false },
  tripExtension: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case RESET_TRIP_INFORMATION:
      return {
        ...state,
        tripExtension: null,
      }
    case GET_TRIP_EXTENSION_RESULTS:
      return {
        ...state,
        tripExtension: action.tripExtension,
      }
    case RESET_TRIP_EXTENSION:
      return {
        ...state,
        putTripExtension: { success: false },
      }
    case PUT_TRIP_EXTENSION_RESULTS:
      return {
        ...state,
        putTripExtension: action.putTripExtension,
      }
    default:
      return state
  }
}
