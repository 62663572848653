import React, { PureComponent } from 'react'

class Separator extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="separator"> </div>
    )
  }
}

export default Separator
