import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'

class AccomodationType extends PureComponent {
  static propTypes = {
    GET_ACCOMODATION_TYPE_LIST: PropTypes.func.isRequired,
    RESET_ACCOMODATION_TYPE_CONTENT: PropTypes.func.isRequired,
    PUT_ACCOMODATION_TYPE_CONTENT: PropTypes.func.isRequired,
    DELETE_ACCOMODATION_TYPE_CONTENT: PropTypes.func.isRequired,
    putAccomodationTypeContent: PropTypes.object,
    deleteAccomodationTypeContent: PropTypes.object,
    accomodationTypeList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    accomodationTypeList: [],
    putAccomodationTypeContent: {},
    deleteAccomodationTypeContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.validate = this.validate.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.onChange = this.onChange.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedAccomodationType = this.getModifiedAccomodationType.bind(this)
    this.updateAccomodationType = this.updateAccomodationType.bind(this)
    this.state = {
      actionLabel: 'Create',
      accomodationTypeList: [],
      accomodationTypeContent: '',
      isNewAccomodationType: false,
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      disabledActionBtn: true,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      accomodationTypeList,
      GET_ACCOMODATION_TYPE_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(accomodationTypeList)) {
      this.setState({
        accomodationTypeList: _.sortBy(accomodationTypeList, o => o.type_hebergement),
      })
    } else {
      GET_ACCOMODATION_TYPE_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      accomodationTypeContent,
      accomodationTypeList: accomodationTypeListOld,
    } = this.state
    const {
      accomodationTypeList,
      putAccomodationTypeContent,
      deleteAccomodationTypeContent,
      RESET_ACCOMODATION_TYPE_CONTENT,
    } = nextprops
    if (putAccomodationTypeContent && putAccomodationTypeContent.success) {
      this.reload()
      this.setState({
        accomodationTypeContent: {
          ...accomodationTypeContent,
          id_type_hebergement: putAccomodationTypeContent.id,
        },
        validMsg: 'the accomodation type was successfully recorded !',
      })
      this.resetValidMsg()
      this.handleClose()
    }
    // accomodation type delete in DB -> get back updated list
    if (deleteAccomodationTypeContent && deleteAccomodationTypeContent.success) {
      this.reload()
      this.setState({
        accomodationTypeContent: '',
        validMsg: 'the accomodation type was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // accomodation type can not be delete in DB -> it is used yet in accomodation
    if (deleteAccomodationTypeContent && !deleteAccomodationTypeContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteAccomodationTypeContent.message,
      })
      RESET_ACCOMODATION_TYPE_CONTENT()
    }
    if (accomodationTypeList !== accomodationTypeListOld) {
      this.setState({
        accomodationTypeList: _.sortBy(accomodationTypeList, o => o.type_hebergement),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_ACCOMODATION_TYPE_CONTENT,
      GET_ACCOMODATION_TYPE_LIST,
    } = this.props
    RESET_ACCOMODATION_TYPE_CONTENT()
    GET_ACCOMODATION_TYPE_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { accomodationTypeList } = this.state
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = accomodationTypeList.filter(d => d.type_hebergement === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          accomodationTypeContent: {
            id_type_hebergement: null,
            type_hebergement: newValue,
          },
          disabledActionBtn: false,
          isNewAccomodationType: true,
        });
      } else {
        this.setState({
          accomodationTypeContent: valueExist[0],
          disabledActionBtn: true,
          isNewAccomodationType: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        accomodationTypeContent: {
          id_type_hebergement: null,
          type_hebergement: newValue.inputValue,
        },
        disabledActionBtn: false,
        isNewAccomodationType: true,
      });
    } else {
      this.setState({
        accomodationTypeContent: newValue,
        disabledActionBtn: true,
        isNewAccomodationType: false,
      });
    }
  }

  filterOptions = (options, params) => {
    const { accomodationTypeList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = accomodationTypeList.filter(d => d.type_hebergement === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        type_hebergement: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.type_hebergement;
  }

  // ***************************
  // Display input

  displayAutocompleteInput = () => {
    const {
      accomodationTypeContent,
      accomodationTypeList,
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="accomodation-type-field"
            value={accomodationTypeContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={accomodationTypeList}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.type_hebergement}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Accomodation type"
                margin="normal"
                placeholder="Select an accomodation type or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item xs={1} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Delete" aria-label="delete" onClick={this.deleteItem}>
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      accomodationTypeContent,
    } = this.state
    const accomodationTypeId = accomodationTypeContent.id_type_hebergement
    const { DELETE_ACCOMODATION_TYPE_CONTENT } = this.props
    if (confirm('Do you really want to delete this accomodation type ?')) {
      if (accomodationTypeId) {
        DELETE_ACCOMODATION_TYPE_CONTENT(token, accomodationTypeId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: accomodationTypeContent.type_hebergement ? 'This accomodation type does not exist.' : 'Please, select a accomodation type.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { accomodationTypeContent } = this.state
    if (!accomodationTypeContent || !accomodationTypeContent.id_type_hebergement) return null
    this.setState({
      isNewAccomodationType: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      accomodationTypeContent,
      isNewAccomodationType,
      token,
    } = this.state
    const { PUT_ACCOMODATION_TYPE_CONTENT } = this.props
    // Check all required inputs
    if (!accomodationTypeContent.type_hebergement) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new or ask to update
    if (isNewAccomodationType) {
      PUT_ACCOMODATION_TYPE_CONTENT(token, accomodationTypeContent, 'create')
    } else {
      this.handleOpen()
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new accomodation type
  updateAccomodationType = () => {
    const {
      token,
      accomodationTypeContent,
    } = this.state
    const { PUT_ACCOMODATION_TYPE_CONTENT } = this.props
    PUT_ACCOMODATION_TYPE_CONTENT(token, accomodationTypeContent, 'update')
  }

  getModifiedAccomodationType = (item) => {
    const { accomodationTypeContent } = this.state
    this.setState({
      accomodationTypeContent: {
        ...accomodationTypeContent,
        type_hebergement: item.accomodation_type,
      },
    }, () => this.updateAccomodationType())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      accomodationTypeContent,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      disabledActionBtn,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>ACCOMODATION_TYPE MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!accomodationTypeContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedAccomodationType(item)}
            disabled={false}
            title="Enter the new value for this accomodation type"
            type="accomodation_type"
            modalContent="accomodation_type"
            warning="This will update this accomodation type in all quotes !"
            inputValue={!_.isEmpty(accomodationTypeContent) ? accomodationTypeContent.type_hebergement : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accomodationTypeList: state.api.accomodationTypeList,
  putAccomodationTypeContent: state.api.putAccomodationTypeContent,
  deleteAccomodationTypeContent: state.api.deleteAccomodationTypeContent,
})

const mapDispatchToProps = dispatch => ({
  GET_ACCOMODATION_TYPE_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_TYPE_LIST',
    userId,
  }),
  RESET_ACCOMODATION_TYPE_CONTENT: () => dispatch({
    type: 'RESET_ACCOMODATION_TYPE_CONTENT',
  }),
  PUT_ACCOMODATION_TYPE_CONTENT: (token, accomodationTypeContent, action) => dispatch({
    type: 'PUT_ACCOMODATION_TYPE_CONTENT',
    token,
    accomodationTypeContent,
    action,
  }),
  DELETE_ACCOMODATION_TYPE_CONTENT: (token, accomodationTypeId) => dispatch({
    type: 'DELETE_ACCOMODATION_TYPE_CONTENT',
    token,
    accomodationTypeId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccomodationType))
