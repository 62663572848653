
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import Chart from 'chart.js';
// import classes from './LineGraph.module.css';

class BarGraph extends Component {
  static propTypes = {
    id: PropTypes.string,
    labels: PropTypes.array,
    datasets: PropTypes.array,
    message: PropTypes.string,
  }

  static defaultProps = {
    id: '',
    labels: [],
    datasets: [],
    message: '',

  }

  constructor(props) {
    super(props)
    this.chartRef = React.createRef();
    this.barGraph = this[props.id]
  }

  componentDidMount() {
    this.buildChart()
  }

  componentDidUpdate() {
    this.buildChart()
  }

  buildChart = () => {
    const {
      labels,
      datasets,
    } = this.props
    const myChartRef = this.chartRef.current.getContext('2d');

    if (typeof this.barGraph !== 'undefined') {
      this.barGraph.destroy();
    }

    this.barGraph = new Chart(myChartRef, {
      type: 'bar',
      data: {
        // Bring in data
        labels: labels,
        datasets: datasets,
      },
      options: {
        // Customize chart options
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
    });
  }

  render() {
    const {
      id,
      message,
    } = this.props
    return (
      <div className="graph-container">
        <canvas
          id={id}
          ref={this.chartRef}
          aria-label="Hello ARIA World"
          // role="img"
        >
          <p>{message}</p>
        </canvas>
      </div>
    )
  }
}

export default BarGraph
