import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  putTourOperatorResults,
  putTripBillingDepositResults,
  putInformationCommentResults,
} from './actions'

function* putTourOperatorAsync(params) {
  const {
    token,
    tripPrice,
  } = params
  const data = yield call(api.put_tour_operator, token, tripPrice)
  yield put(putTourOperatorResults(data))
}

function* putTripBillingDepositAsync(params) {
  const {
    token,
    idDevis,
    billingDetails,
  } = params
  const data = yield call(api.put_trip_billing_deposit, token, idDevis, billingDetails)
  yield put(putTripBillingDepositResults(data))
}
function* putInformationCommentAsync(params) {
  const {
    token,
    informationComment,
  } = params
  const data = yield call(api.put_information_comment, token, informationComment)
  yield put(putInformationCommentResults(data))
}

function* fetchResult() {
  yield takeEvery('PUT_TOUR_OPERATOR', putTourOperatorAsync)
  yield takeEvery('PUT_TRIP_BILLING_DEPOSIT', putTripBillingDepositAsync)
  yield takeEvery('PUT_INFORMATION_COMMENT', putInformationCommentAsync)
}

export default fetchResult
