import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#0bcdd6',
  },
  barColorPrimary: {
    backgroundColor: '#03838A',
  },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className="loader">
      <ColorLinearProgress className={classes.margin} />
      !!!!  LOADING PLEASE WAIT !!!!
    </div>
  );
}
