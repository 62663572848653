const translateBoard = [
  {
    isoCode: 'fr',
    name: 'French',
    nativeName: 'Français',
    translation: {
      ourAgency: 'notre agence',
      parfumDestination: 'À destination de',
      hotel: 'hotel',
      siteInternet: 'site internet',
      accomodation: 'Hébergement',
      accomodationType: 'Type d’hébergement',
      Terms: 'Termes et conditions',
      NIGHTSHOTEL: 'nuit',
      NIGHTSHOTELS: 'nuits',
      roomType: 'Type de chambres',
      daysOnSite: 'jours sur place',
      priceNav: 'Prix',
      adult: 'adulte',
      adults: 'adultes',
      child: 'enfant',
      children: 'enfants',
      baby: 'bébé',
      babies: 'bébés',
      textHorizon1: 'A noter que ce voyage est préparé spécialement pour votre groupe de',
      textHorizon2: 'considéré comme groupe constitué. Durant le parcours, vous voyagez en véhicule privé, avec chauffeur, guide francophone - spécialiste par région. Ce serait un contrat ferme entre vous et HORIZON VIETNAM TRAVEL',
      forTheAttentionOf: 'A l\'attention de',
      day: 'Jour',
      days: 'jours',
      date: 'Date',
      mapOfTrip: 'Carte du voyage',
      itinerary: 'Itinéraire',
      itineraryInBrief: 'Itinéraire en bref',
      itineraryInBriefTypic: 'ITINERAIRE EN BREF',
      TitreTransport: 'Transports routiers',
      withLocal: 'Accompagnateur local',
      titreAvionTransport: 'Transports aeriens',
      detailedProgram: 'Programme détaillé',
      transport: 'transport',
      extensionPresentation: 'Si vous disposez plusieurs jours de plus pour votre voyage, il est possible d\’ajouter les extensions/excursions associées à ce voyage.',
      extension: 'extension',
      whyChooseUs: 'pourquoi nous choisir',
      tripRate: 'tarif du voyage',
      afterDiscount: 'après réduction',
      recommandationOn: 'Agence de voyage locale recommandée sur',
      priceContained: 'Le prix comprend',
      priceDoNotContained: 'Le prix ne comprend pas',
      pricePerPerson: 'PRIX PAR PERSONNE',
      pricePerPersonIn: 'prix par personne en',
      meals: 'Repas',
      typeOfRoom: 'type de chambre',
      guide: 'guide',
      driver: 'chauffeur',
      driverSpeakingEnglish: 'chauffeur parlant anglais',
      driverSpeakingFrench: 'chauffeur parlant francais',
      driverCapannan: 'véhicule privé avec chauffeur',
      withGuide: 'Guide francophone',
      withGuideEn: 'Guide anglophone',
      withGuideEnEvasion: 'Accompagnateur anglophone',
      withoutGuide: 'sans guide',
      withFrenchGuideAndDriver: 'Guide francophone et véhicule privé avec chauffeur',
      withEnglishGuideAndDriver: 'Guide anglophone et véhicule privé avec chauffeur',
      withFrenchGuideAndNoDriver: 'Guide francophone',
      withEnglishGuideAndNoDriver: 'Guide anglophone',
      ticketVisit: 'Ticket de visite inclus',
      person: 'personne',
      persons: 'personnes',
      giftDiscount1: 'Notre cadeau : réduction de',
      giftDiscount2: '% du prix pour la réservation au moins de 03 mois avant le départ',
      pricePerPersonAfterDiscount: 'prix par personne après réduction',
      priceAfterDiscount: 'PRIX FINAL PAR PERSONNE APRES LA REDUCTION',
      total: 'GRAND TOTAL',
      groupSize: 'Taille du groupe',
      and: 'et',
      ellipsis: '...',
      night: 'nuit(s)',
      nightOnPlace: 'nuits sur place',
      billTitle: 'VOTRE FACTURE',
      groupName: 'Nom du groupe',
      codeQuote: 'Code du circuit',
      contact: 'votre conseiller',
      circuitName: 'nom du circuit',
      from: 'Du',
      to: 'au',
      duration: 'durée du circuit',
      deposit: 'Dépôt',
      breakfast: 'petit-déjeuner(s)',
      lunch: 'déjeuner(s)',
      dinner: 'Dîner(s)',
      tripCostMinusDepo: 'Paiement du solde du voyage',
      textTravelerNumber: 'Nombre de parcipants',
      travelProject: 'PROJET DE VOYAGE',
      departureDate: 'Date de départ',
      AtDeparture: 'Au départ de',
      available: 'Disponible',
      circuitType: 'Type de circuit',
      start: 'Départ',
      destination: 'Destination',
      guideDriver: 'Accompagnateur',
      individual: 'Individuel et sur mesure',
      titreCarteTypic: 'CARTE ILLUSTRÉE DE L’ITINÉRAIRE',
      allDay: 'tous les jours',
      version: 'Version',
      tripType: 'Type de voyage',
      privateTrip: 'Circuit privé',
      modificationDate: 'Date de modification',
      creationDate: 'Date de création',
      textConseil: 'Conseillère en voyages et Responsable de vente',
      spiritTrip: 'L\'esprit du voyage',
      interest: 'Centre d\'intéret',
      meal: 'pension',
      noGuideNoDriver: 'Sans guide ni chauffeur',
      driverEvasion: 'Véhicule privé avec chauffeur',
      busEvasion: 'Van collectif avec chauffeur',
      room: 'Chambre',
      serviceEnd: 'Fin de nos services',
      yourHotels: 'Vos hôtels pendant votre voyage',
      yourHotels2: 'Vos hôtels',
      nightNumber: 'Nombre de nuitées',
      reservation: 'Etat de réservation des billets des vols domestiques ou de train',
      route: 'Trajet',
      reservationState: 'État de resérvation',
      quoteTrip: 'DEVIS DE VOYAGE',
      futefute: 'Avis du petit futé',
      quote: 'Devis',
      priceCalc: 'Les prix sont calculés en',
      priceCalc2: '/par personne/sur une base du groupe de',
      TravelInformation: 'INFORMATIONS SUR VOTRE VOYAGE',
      prestationDetail: 'PRECISION DE NOS PRESTATIONS',
      jonque: 'JONQUE DE CROISIERE',
      petitFute: 'Petit producteur de voyages qui doit sa célébrité au bouche-à-oreille uniquement. Depuis près d\'une décennie, monsieur Bau, ancien guide francophone, et ses amis, passionnés par leur métier, proposent des circuits sur mesure au Viêt Nam, au Cambodge et au Laos. Cette équipe attachante vous amène à l\'écart du tourisme de masse en vous proposant une découverte authentique du pays. Très professionnels et à l\'écoute.',
      finTextDoc: `<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Plus de 13000 clients sont partis avec nous, dont 98 % sont revenus enti&egrave;rement satisfaits</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐ De milliers d&rsquo;excellents commentaires sur les forums de voyage prestigieux (Routard, petit fut&eacute;, tripadvisor, Voyage forum, lonely planet...)</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Nos t&eacute;moignages : <strong><span style="color: #3333ff;"><a style="color: #3333ff;" href="http://horizon-vietnamvoyage.com/nos-temoignages/nos-voyageurs-parlent/" target="_blank" rel="noopener">http://horizon-vietnamvoyage.com/nos-temoignages/nos-voyageurs-parlent/&nbsp;</a></span></strong></span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Agence de voyage agr&eacute;e par la Direction G&eacute;n&eacute;rale du Tourisme du Vietnam (num&eacute;ro de licence : 01-387/TCDL-GP LHQT)</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Tous nos voyages sont solidaires : <strong><a href="http://horizon-vietnamvoyage.com/voyage-responsable-horizon-vietnam/agence-locale-vietnam/" target="_blank" rel="noopener"><span style="color: #3333ff;">http://horizon-vietnamvoyage.com/voyage-responsable-horizon-vietnam/agence-locale-vietnam/</span></a></strong>&nbsp;</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Agence locale recommand&eacute;e par les guides de voyage &nbsp;</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Agence locale s&eacute;rieuse de pr&egrave;s de 10 ans d&rsquo;exp&eacute;riences (gr&acirc;ce notamment au bouche &agrave; oreille)</span></p>
<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Rejoignez nous sur : <span style="color: #3333ff;"><strong><a style="color: #3333ff;" href="https://www.youtube.com/channel/UCTM7PbUlPlJw4ZAmuOYEiMQ" target="_blank" rel="noopener">+ Youtube</a></strong></span> - &nbsp;<strong><a href="https://www.facebook.com/Horizon-Vietnam-Travel-Agence-de-voyage-locale-au-Vietnam-136711983172305/" target="_blank" rel="noopener">Facebook</a></strong></span></p>
<p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 10pt;">Rencontre au bout du monde &ndash; le voyage de vos r&ecirc;ves</span></strong></p>
<p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 10pt;">⭐⭐⭐⭐⭐</span></strong></p>`,
      vehicule: '<span style="font-family: verdana, geneva; font-size: 10pt;text-align: justify;">Pour les transports terrestres, HORIZON VIETNAM Travel vous propose des véhicules généralement neufs,   soigneusement entretenus. Tous ces véhicules sont climatisés et ont un haut niveau de confort. De plus, ils sont réservés, vous ne devez pas les partager avec d’autres touristes qui ne font pas parti de votre groupe. Pour les chauffeurs, nous nous occupons particulièrement de recrutement des chauffeurs expérimentés, compétents et serviables.</span>',
      typicRemark1: '<p>Les horaires d&rsquo;enregistrement et de lib&eacute;ration des chambres dans les h&ocirc;tels sont en g&eacute;n&eacute;ral tr&egrave;s stricts et appliqu&eacute;s selon les standards internationaux :<br />&nbsp; &nbsp; &nbsp; &nbsp; + Check in (chambres disponibles) : <strong>&Agrave; partir de 14h.</strong><br />&nbsp; &nbsp; &nbsp; &nbsp; + Check out (lib&eacute;ration des chambres) : <strong>Avant midi.</strong><br />Le non-respect de ces horaires vous demande un suppl&eacute;ment de 50% &agrave; la totalit&eacute; du prix de la nuit :<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 12h00 et avant 18h00: 50% du prix de chambre public<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 18h00: 100% du prix public</p>',
      homeMenu: 'Informations générales',
      carteMenu: 'Carte',
      detailMenu: 'Programme détaillé',
      hotelMenu: 'Hôtels',
      prixMenu: 'Tarif',
      infoMenu: 'Prestations',
      avionVN: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Vietnam Airlines.</span></p>',
      avionC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Cambodia Angkors Airlines.</span></p>',
      avionVC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Vietnam Airlines et Cambodia Angkors Airlines.</span></p>',
      avionLC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Laos Airlines et Cambodia Angkors Airlines.</span></p>',
    },
  },
  {
    isoCode: 'en',
    name: 'English',
    nativeName: 'English',
    translation: {
      ourAgency: 'our agency',
      parfumDestination: 'For',
      breakfast: 'breakfast',
      lunch: 'lunch',
      dinner: 'Dinner',
      accomodationType: 'Type of accommodation',
      roomType: 'Type of room',
      NIGHTSHOTEL: 'night',
      NIGHTSHOTELS: 'nights',
      siteInternet: 'web site',
      Terms: 'Terms and conditions',
      priceNav: 'Price',
      hotel: 'hotel',
      accomodation: 'accomodation',
      daysOnSite: 'days on site',
      adult: 'adult',
      adults: 'adults',
      child: 'child',
      children: 'children',
      baby: 'baby',
      babies: 'babies',
      available: 'available',
      withLocal: 'local guide',
      TitreTransport: 'Transports routiers',
      titreAvionTransport: 'Transports aeriens',
      textHorizon1: 'A noter que ce voyage est préparé spécialement pour votre groupe de',
      textHorizon2: 'considéré comme groupe constitué. Durant le parcours, vous voyagez en véhicule privé, avec chauffeur, guide francophone - spécialiste par région. Ce serait un contrat ferme entre vous et HORIZON VIETNAM TRAVEL',
      withFrenchGuideAndDriver: 'Guide francophone et véhicule privé avec chauffeur',
      withEnglishGuideAndDriver: 'Guide anglophone et véhicule privé avec chauffeur',
      withFrenchGuideAndNoDriver: 'Guide francophone',
      withEnglishGuideAndNoDriver: 'Guide anglophone',
      textTravelerNumber: 'Traveller number',
      forTheAttentionOf: 'for the attention of',
      day: 'day',
      days: 'days',
      date: 'date',
      quote: 'Quote',
      titreCarteTypic: 'ILLUSTRATED MAP OF THE ITINERARY',
      nightOnPlace: 'nights on site',
      driverSpeakingEnglish: 'english speaking driver',
      driverSpeakingFrench: 'french speaking driver',
      privateTrip: 'Private trip',
      mapOfTrip: 'map of trip',
      itinerary: 'itinerary',
      itineraryInBrief: 'itinerary in brief',
      itineraryInBriefTypic: 'ITINERARY IN BRIEF',
      detailedProgram: 'detailed program',
      transport: 'transport',
      extensionPresentation: 'If you have several more days for your trip, it is possible to add the extensions / excursions associated with this trip',
      extension: 'extension',
      whyChooseUs: 'why choose us',
      tripRate: 'price of the trip',
      afterDiscount: 'after discount',
      priceContained: 'price includes',
      recommandationOn: 'Agence de voyage locale recommandée sur',
      priceDoNotContained: 'price do not includes',
      pricePerPerson: 'price per person',
      pricePerPersonIn: 'price per person in',
      meals: 'meals',
      typeOfRoom: 'room type',
      guide: 'guide',
      driver: 'driver',
      driverEvasion: 'private van with driver',
      busEvasion: 'Collective van with drive',
      driverCapannan: 'private vehicule with driver',
      withGuide: 'With guide',
      withGuideEn: 'With english spoken guide',
      withGuideEnEvasion: 'English-speaking escort',
      withoutGuide: 'without guide',
      person: 'person',
      persons: 'persons',
      giftDiscount1: 'Our gift : discount of',
      giftDiscount2: '% if booking at least 03 months before departure',
      pricePerPersonAfterDiscount: 'price per person after discount',
      priceAfterDiscount: 'price after discount',
      typicRemark1: '<p>Les horaires d&rsquo;enregistrement et de lib&eacute;ration des chambres dans les h&ocirc;tels sont en g&eacute;n&eacute;ral tr&egrave;s stricts et appliqu&eacute;s selon les standards internationaux :<br />&nbsp; &nbsp; &nbsp; &nbsp; + Check in (chambres disponibles) : <strong>&Agrave; partir de 14h.</strong><br />&nbsp; &nbsp; &nbsp; &nbsp; + Check out (lib&eacute;ration des chambres) : <strong>Avant midi.</strong><br />Le non-respect de ces horaires vous demande un suppl&eacute;ment de 50% &agrave; la totalit&eacute; du prix de la nuit :<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 12h00 et avant 18h00: 50% du prix de chambre public<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 18h00: 100% du prix public</p>',
      total: 'total',
      groupSize: 'group size',
      and: 'and',
      ellipsis: '...',
      night: 'night(s)',
      billTitle: 'YOUR INVOICE',
      groupName: 'group name',
      codeQuote: 'reference number',
      contact: 'your contact',
      circuitName: 'Program name',
      from: 'from',
      to: 'to',
      duration: 'program duration',
      deposit: 'Deposit',
      tripCostMinusDepo: 'Remaining to pay',
      travelProject: 'TRAVEL PLAN',
      departureDate: 'Departure Date',
      AtDeparture: 'From',
      circuitType: 'Circuit type',
      start: 'Start',
      destination: 'Destination',
      individual: 'Individual',
      allDay: 'every day',
      version: 'version',
      tripType: 'Trip type',
      modificationDate: 'modification date',
      creationDate: 'creation date',
      textConseil: 'Travel Advisor and Sales Manager',
      spiritTrip: 'Some word about the trip',
      interest: 'Highlights',
      meal: 'meal',
      noGuideNoDriver: 'No guide no driver',
      room: 'room',
      serviceEnd: 'End of our services',
      yourHotels: 'Your hotels during your trip',
      yourHotels2: 'Your hotels',
      nightNumber: 'night numbers',
      reservation: 'Reservation status of domestic flight or train tickets',
      route: 'Route',
      guideDriver: 'Guide',
      reservationState: 'Booking status',
      quoteTrip: 'TRIP QUOTE',
      priceCalc: 'The prices are calculated in',
      priceCalc2: '/per person/on a group basis',
      prestationDetail: 'SERVICE INFORMATION',
      TravelInformation: 'INFORMATIONS ABOUT YOUR TRIP',
      jonque: 'CRUISE JUNK',
      futefute: 'La opinión de Little Smart',
      petitFute: 'A small travel producer that owes its fame solely to word-of-mouth. For nearly a decade, Mr. Bau, a former French-speaking guide, and his friends, passionate about their profession, have been offering tailor-made tours in Vietnam, Cambodia and Laos. This endearing team takes you away from mass tourism by offering you an authentic discovery of the country. Very professional and attentive.',
      finTextDoc: `<p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Plus de 13000 clients sont partis avec nous, dont 98 % sont revenus enti&egrave;rement satisfaits</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐ De milliers d&rsquo;excellents commentaires sur les forums de voyage prestigieux (Routard, petit fut&eacute;, tripadvisor, Voyage forum, lonely planet...)</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Nos t&eacute;moignages : <strong><span style="color: #3333ff;"><a style="color: #3333ff;" href="http://horizon-vietnamvoyage.com/nos-temoignages/nos-voyageurs-parlent/" target="_blank" rel="noopener">http://horizon-vietnamvoyage.com/nos-temoignages/nos-voyageurs-parlent/&nbsp;</a></span></strong></span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Agence de voyage agr&eacute;e par la Direction G&eacute;n&eacute;rale du Tourisme du Vietnam (num&eacute;ro de licence : 01-387/TCDL-GP LHQT)</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; Tous nos voyages sont solidaires : <strong><a href="http://horizon-vietnamvoyage.com/voyage-responsable-horizon-vietnam/agence-locale-vietnam/" target="_blank" rel="noopener"><span style="color: #3333ff;">http://horizon-vietnamvoyage.com/voyage-responsable-horizon-vietnam/agence-locale-vietnam/</span></a></strong>&nbsp;</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Agence locale recommand&eacute;e par les guides de voyage &nbsp;</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Agence locale s&eacute;rieuse de pr&egrave;s de 10 ans d&rsquo;exp&eacute;riences (gr&acirc;ce notamment au bouche &agrave; oreille)</span></p>
      <p><span style="font-family: verdana, geneva; font-size: 10pt;">⭐&nbsp; &nbsp;Rejoignez nous sur : <span style="color: #3333ff;"><strong><a style="color: #3333ff;" href="https://www.youtube.com/channel/UCTM7PbUlPlJw4ZAmuOYEiMQ" target="_blank" rel="noopener">+ Youtube</a></strong></span> - &nbsp;<strong><a href="https://www.facebook.com/Horizon-Vietnam-Travel-Agence-de-voyage-locale-au-Vietnam-136711983172305/" target="_blank" rel="noopener">Facebook</a></strong></span></p>
      <p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 10pt;">Rencontre au bout du monde &ndash; le voyage de vos r&ecirc;ves</span></strong></p>
      <p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 10pt;">⭐⭐⭐⭐⭐</span></strong></p>`,
      vehicule: 'Pour les transports terrestres, HORIZON VIETNAM Travel vous propose des véhicules généralement neufs,   soigneusement entretenus. Tous ces véhicules sont climatisés et ont un haut niveau de confort. De plus, ils sont réservés, vous ne devez pas les partager avec d’autres touristes qui ne font pas parti de votre groupe. Pour les chauffeurs, nous nous occupons particulièrement de recrutement des chauffeurs expérimentés, compétents et serviables.',
      homeMenu: 'information générales',
      carteMenu: 'Carte',
      detailMenu: 'Programme détaillé',
      hotelMenu: 'Hôtels',
      prixMenu: 'Tarif',
      infoMenu: 'Prestations',
      avionVN: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Vietnam Airlines.</span></p>',
      avionC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Cambodia Angkors Airlines.</span></p>',
      avionVC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Vietnam Airlines et Cambodia Angkors Airlines.</span></p>',
      avionLC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Laos Airlines et Cambodia Angkors Airlines.</span></p>',
    },
  },
  {
    isoCode: 'sp',
    name: 'Spanish',
    nativeName: 'Español',
    translation: {
      ourAgency: 'nuestra agencia',
      hotel: 'hotel',
      available: 'Disponible',
      withLocal: 'local guide',
      accomodation: 'Alojamiento',
      daysOnSite: 'días en el sitio',
      adult: 'adulto',
      adults: 'adultos',
      child: 'niño',
      textTravelerNumber: 'Número de participantes:',
      children: 'niños',
      baby: 'bebé',
      babies: 'bebés',
      textHorizon1: 'Tenga en cuenta que este viaje está especialmente preparado para su grupo de',
      textHorizon2: 'considerado como un grupo. Durante el viaje, viaja en un vehículo privado, con conductor, guía de habla española,especialista por región. Esto sería un contrato firme entre usted y HORIZON VIETNAM TRAVEL',
      forTheAttentionOf: 'a la atención de',
      recommandationOn: 'Agencia de viajes local recomendada en',
      day: 'Día',
      days: 'Días',
      quote: 'Devis',
      date: 'Fecha',
      TitreTransport: 'Transporte por carretera',
      titreAvionTransport: 'Transports aeriens',
      nightOnPlace: 'noches en el sitio',
      mapOfTrip: 'Mapa de viaje',
      itinerary: 'Itinerario',
      itineraryInBrief: 'Itinerario en breve',
      itineraryInBriefTypic: 'ITINERARIO EN BREVE',
      titreCarteTypic: 'MAPA ILUSTRADO DEL ITINERARIO',
      detailedProgram: 'Programa detallado',
      transport: 'transporte',
      extensionPresentation: 'Si dispone de varios días más para su viaje, es posible añadir las extensiones/excursiones asociadas a este viaje',
      extension: 'extensión',
      whyChooseUs: 'Por qué elegirnos',
      tripRate: 'precio del viaje',
      afterDiscount: 'después del descuento',
      priceContained: 'Precio incluye',
      priceDoNotContained: 'Precio no incluye',
      pricePerPerson: 'precio por persona',
      pricePerPersonIn: 'precio por persona en',
      meals: 'Comidas',
      typeOfRoom: 'tipo de habitación',
      guide: 'guía',
      driver: 'conductor privado',
      driverEvasion: 'private van conductor privado',
      busEvasion: 'Van colectiva con conductor',
      driverCapannan: 'private coche conductor privado',
      withGuide: 'Con guía de habla español',
      withGuideEn: 'Con guía de habla inglesa',
      withoutGuide: 'sin guía',
      person: 'persona',
      persons: 'personas',
      TravelInformation: 'PARTICIPANTES DE VIAJE',
      giftDiscount1: 'Nuestro regalo : descuento de',
      giftDiscount2: '% si reserva al menos 03 meses antes de la salida',
      pricePerPersonAfterDiscount: 'precio por persona después del descuento',
      total: 'total',
      groupSize: 'Cantitad de personas',
      and: 'y',
      ellipsis: '...',
      night: 'noche(s)',
      billTitle: 'SU FACTURA',
      groupName: 'Nombre del grupo',
      codeQuote: 'Número de referencia',
      contact: 'tu contacto',
      privateTrip: 'circuito privado',
      circuitName: 'Nombre del programa',
      from: 'desde',
      to: 'para',
      duration: 'Duracion del programa',
      deposit: 'Depósito',
      tripCostMinusDepo: 'Queda por pagar',
      travelProject: 'PROYECTO DE VIAJE',
      departureDate: 'Fecha de salida',
      AtDeparture: 'A la salida de',
      circuitType: 'Tipo de circuito',
      start: 'Departido',
      destination: 'Destino',
      individual: 'Individual',
      allDay: 'Todos los días',
      version: 'Versión',
      tripType: 'Tipo de viaje',
      modificationDate: 'Fecha de modificación',
      creationDate: 'Fecha de creación',
      textConseil: 'Asesora de ventas',
      spiritTrip: 'Espíritu de viaje',
      interest: 'Centro de interés',
      meal: 'comida',
      guideDriver: 'Guía',
      noGuideNoDriver: 'Sin guía ni conductor',
      room: 'Habitación',
      serviceEnd: 'Fin de nuestros servicios',
      yourHotels: 'Sus hoteles durante su viaje',
      futefute: 'La opinión de Little Smart',
      yourHotels2: 'Tus hoteles',
      nightNumber: 'Número de noches',
      reservation: 'Estado de la reserva de billetes de tren o vuelos nacionales',
      route: 'Ruta',
      reservationState: 'Estado de la reservación',
      quoteTrip: 'PRESUPUESTO DE VIAJE',
      priceCalc: 'Precio del viaje se calculan en',
      priceCalc2: '/por persona/en grupo',
      prestationDetail: 'Precision de nuestros servicios',
      jonque: 'CRUCERO',
      petitFute: 'Pequeño productor de viajes que debe su fama solo al boca a boca. Durante casi una década, el Sr. Bau, un ex guía de habla francesa, y sus amigos, apasionados por su profesión, han ofrecido tours a medida en Vietnam, Camboya y Laos. Este atractivo equipo lo alejará del turismo de masas al ofrecerle un auténtico descubrimiento del país. Muy profesional y atento.',
      finTextDoc: `<ul>
      <li>M&aacute;s de 13,000 clientes se fueron con nosotros, el 98% de los cuales regres&oacute; completamente satisfecho</li>
      <li>Miles de excelentes cr&iacute;ticas en prestigiosos foros de viajes (Routard, petit fut&eacute;, tripadvisor, Voyage forum, lonely planet...)</li>
      <li>+ 600 testimonios de cartas de agradecimiento</li>
      <li>Agencia de viajes aprobada por la Direcci&oacute;n General de Turismo de Vietnam (n&uacute;mero de licencia: 01-387 / TCDL-GP LHQT)</li>
      <li>Todos nuestros viajes est&aacute;n unidos :</li>
      <li>Agencia local recomendada por gu&iacute;as de viaje</li>
      <li>Agencia local seria con m&aacute;s de 12 a&ntilde;os de experiencia (gracias en particular al boca a boca)</li>
      <li>&Uacute;nete a nosotros:<span style="background-color: #ffffff;"> </span><span style="text-decoration: underline;"><span style="background-color: #0000ff;"><a style="background-color: #0000ff;" href="https://www.youtube.com/channel/UCTM7PbUlPlJw4ZAmuOYEiMQ" target="_blank" rel="noopener"><span style="background-color: #ffffff;">+ Youtube </span></a></span></span><span style="background-color: #ffffff;">- &nbsp;<span style="text-decoration: underline;"><a style="background-color: #ffffff;" href="https://www.facebook.com/agence.horizonvietnamtravel/" target="_blank" rel="noopener">Facebook</a></span> -</span><a href="https://www.instagram.com/horizontravelvietnam/" target="_blank" rel="noopener">&nbsp;Instagram&nbsp;</a></li>
      </ul>
      <p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 14pt; color: #008000;">Encuentro en el fin del mundo: los viajes de tus sue&ntilde;os</span></strong></p>
      <p style="text-align: center;"><strong><span style="font-family: verdana, geneva; font-size: 14pt; color: #008000;">⭐⭐⭐⭐⭐⭐</span></strong></p>`,
      vehicule: '<span style="font-family: verdana, geneva; font-size: 10pt;text-align: justify;"> Para el transporte terrestre, HORIZON VIETNAM Travel ofrece generalmente vehículos nuevos, mantenidos cuidadosamente. Todos estos vehículos tienen aire acondicionado y tienen un alto nivel de confort. Además, están reservados, no debe compartirlos con otros turistas que no formen parte de su grupo.  Para los conductores, somos particularmente responsables de reclutar conductores experimentados, competentes y serviciales. </span>',
      typicRemark1: '<p>Les horaires d&rsquo;enregistrement et de lib&eacute;ration des chambres dans les h&ocirc;tels sont en g&eacute;n&eacute;ral tr&egrave;s stricts et appliqu&eacute;s selon les standards internationaux :<br />&nbsp; &nbsp; &nbsp; &nbsp; + Check in (chambres disponibles) : <strong>&Agrave; partir de 14h.</strong><br />&nbsp; &nbsp; &nbsp; &nbsp; + Check out (lib&eacute;ration des chambres) : <strong>Avant midi.</strong><br />Le non-respect de ces horaires vous demande un suppl&eacute;ment de 50% &agrave; la totalit&eacute; du prix de la nuit :<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 12h00 et avant 18h00: 50% du prix de chambre public<br />&nbsp; &nbsp; &nbsp; &nbsp;+ Check out apr&egrave;s 18h00: 100% du prix public</p>',
      homeMenu: 'Información general',
      carteMenu: 'Mapa',
      detailMenu: 'Programa detallado',
      hotelMenu: 'Alojamiento',
      prixMenu: 'Precio',
      infoMenu: 'Servicios',
      avionVN: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Vietnam Airlines.</span></p>',
      avionC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques sont assur&eacute;s par la compagnie a&eacute;rienne locale Cambodia Angkors Airlines.</span></p>',
      avionVC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Vietnam Airlines et Cambodia Angkors Airlines.</span></p>',
      avionLC: '<p style="text-align: justify;"><span style="font-size: 10pt; font-family: verdana, geneva;">Les vols domestiques et internationaux sont assur&eacute;s par les compagnies a&eacute;riennes Laos Airlines et Cambodia Angkors Airlines.</span></p>',

    },
  },
]

export default translateBoard
