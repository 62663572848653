import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Input,
  ActionButton,
} from '../../../../components'
import {
  CssGrid,
  eventBgColor,
} from '../../../../configCSS'

export default function ModalSchedule(props) {
  const {
    resources,
    isModalOpen,
    type,
    itemSelected,
    onClose,
    handleActionEvent,
    deleteEvent,
    nomCircuit,
  } = props

  const [itemEvent, setItemEvent] = useState({});

  useEffect(() => {
    setItemEvent({
      ...itemSelected,
      title: _.isEmpty(itemSelected.title) ? nomCircuit : itemSelected.title,
    })
  }, [itemSelected])

  // ********************* Change **********************
  // ********************************
  // On change select a resource in event change
  const onChangeSelectResourceAutocomplete = (event, newValue) => {
    if (typeof newValue === 'string') {
      // On press Enter
    } else if (!newValue) {
      // on clear field
      // setIdResourceSelected(null)
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input by click on ADD
    } else {
      // setIdResourceSelected(newValue.id)
    }
    return null
  }

  const handleChangeEvent = (event) => {
    const {
      value,
      name,
    } = event.target
    setItemEvent({
      ...itemEvent,
      [name]: value,
    })
  }

  const handleDateChange = (e) => {
    const {
      value,
      name,
    } = e.target
    setItemEvent({
      ...itemEvent,
      [name]: value,
    })
  }

  // ******************* Display autocomplete ***********
  const displayResourceAllInfosAutocomplete = () => resources && (
    <Autocomplete
      id="event"
      options={resources.sort((a, b) => -b.category.localeCompare(a.category))}
      groupBy={option => option.category}
      getOptionLabel={option => `${option.name} ${option.serial_number ? ' - ' : ''} ${option.serial_number}`}
      value={resources.filter(res => res.id === itemEvent.resourceId)[0]}
      onChange={onChangeSelectResourceAutocomplete()}
      selectOnFocus
      freeSolo
      renderInput={params => (
        <Input
          {...params}
          label="Resource"
          margin="normal"
          placeholder="Select a resource"
          variant="outlined"
        />
      )}
    />
  )

  // ***************** Display Event form *******************
  const displayEventManagement = () => (
    <div className="schedule-input-container">
      <p className="delete-btn" onClick={() => deleteEvent(itemEvent)}>delete X</p>
      <h3>RESERVATIONS</h3>
      {/* select resource */}
      <CssGrid item md={12} xs={12}>
        {displayResourceAllInfosAutocomplete()}
      </CssGrid>
      <div style={{ paddingTop: '15px' }}>
        <CssGrid
          container
          spacing={4}
        >
          <CssGrid item md={6} xs={12}>
            <Input
              id="datetime-local-start"
              label="From"
              name="start"
              type="datetime-local"
              value={moment(itemEvent.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm') || null}
              onChange={handleDateChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CssGrid>
          <CssGrid item md={6} xs={12}>
            <Input
              id="datetime-local-end"
              label="To"
              name="end"
              type="datetime-local"
              value={moment(itemEvent.end, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm') || null}
              onChange={handleDateChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CssGrid>
        </CssGrid>
      </div>
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Input
            id="event-title"
            label="Title"
            name="title"
            type="text"
            value={!_.isEmpty(itemEvent.title) ? itemEvent.title : nomCircuit}
            // value={nomCircuit || ''}
            onChange={handleChangeEvent}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
        </CssGrid>
        <CssGrid item md={2} xs={12}>
          <Input
            id="event-color"
            label="Color"
            name="bgColor"
            type="color"
            value={itemEvent.bgColor || eventBgColor}
            onChange={handleChangeEvent}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
        </CssGrid>
      </CssGrid>
      <div className="modalActions">
        <ActionButton
          label={_.capitalize(type)}
          onClick={onResponseYes}
        />
        <ActionButton
          label="Close"
          onClick={onResponseNo}
        />
      </div>
    </div>
  )

  // ********************** Responce **********************
  const onResponseYes = () => {
    handleActionEvent(itemEvent)
  }

  const onResponseNo = () => {
    onClose('cancel')
  }

  return (
    <Modal
      aria-labelledby="schedule-action"
      aria-describedby="schedule-action"
      open={isModalOpen}
      onClose={() => onClose('cancel')}
    >
      <div id="schedule-action" className="schedule-modal-container modal">
        {displayEventManagement()}
      </div>
    </Modal>
  );
}

ModalSchedule.propTypes = {
  resources: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  itemSelected: PropTypes.object,
  nomCircuit: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleActionEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
};

ModalSchedule.defaultProps = {
  itemSelected: {},
}
