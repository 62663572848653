import React from 'react'
import PropTypes from 'prop-types';

import MaterialTable from 'material-table'
import tableIcons from '../utils/icons/icons'

export default function EditableTable(props) {
  const {
    title,
    columns,
    data,
    onUpdate,
    widthDiv,
    addColumnPrice,
    deleteColumnPrice,
  } = props;

  return (
    <MaterialTable
      style={{ width: widthDiv }}
      title={title}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        search: false,
        sorting: false,
        headerStyle: { textAlign: 'center' },
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, data.length],
        actionsColumnIndex: -2,
        columnResizable: true,
        doubleHorizontalScroll: true,
        columnsButton: true,
        exportButton: true,
      }}
      actions={[
        {
          icon: tableIcons.Delete,
          tooltip: 'delete column',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => {
            deleteColumnPrice()
          },
        },
        {
          icon: tableIcons.Add,
          tooltip: 'add column',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => {
            addColumnPrice()
          },
        },
      ]}
      editable={{
        isEditable: rowData => rowData.field !== 'total',
        onBulkUpdate: changes => new Promise((resolve) => {
          onUpdate(changes)
          setTimeout(() => {
            resolve();
          }, 1000);
        }),
      }}
    />
  )
}

EditableTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  widthDiv: PropTypes.number.isRequired,
  addColumnPrice: PropTypes.func.isRequired,
  deleteColumnPrice: PropTypes.func.isRequired,
};
