
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import Interweave from 'interweave';
import moment from 'moment'
import stage from '../../../config'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'

const savedImgUrl = stage.imgStorage

class Bill extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    collaborators: PropTypes.array,
    tripBilling: PropTypes.array,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
  }

  static defaultProps = {
    tripDetails: [],
    tripAllDays: [],
    tripBilling: [],
    companyContent: {},
    collaborators: [],
    informationsContent: {},

  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }


  render() {
    const {
      tripDetails,
      tripAllDays,
      tripBilling,
      companyContent,
      informationsContent,
      collaborators,
    } = this.props

    const collaboratorbyID = collaborators.filter(c => c.id_conseiller === tripDetails[0].id_conseiller)

    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      nom_programme,
      langage,
      code_devis,
      //   langage,
    } = tripDetails[0]

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************
    const slogan = companyContent[`${langage}_slogan`]
    const Couleurdefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const Nombre_Personne = nb_adulte + nb_enfant + nb_bebe
    const PrixMoinsDepot = tripBilling.tripPrice - tripBilling.amountDiscount
    const datefin = tripDetails[0].date_depart
      ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(tripAllDays.length, 'd').format('dddd DD MMM YYYY'))
      : ''
    return (

      <div className="GlobalFileBill">
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={4}>
            <img className="LogoBill" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
          </Grid>
          <Grid item md="auto">
            <Interweave content={slogan} />

          </Grid>
        </Grid>
        <h2 className="Titre2Bill" style={Couleurdefaut}>{langageTranslation.billTitle}</h2>
        <Grid className="Titre3Bill" container spacing={0} justifyContent="center">
          <Grid item xs={6}>{langageTranslation.groupName} : {nom_responsable_groupe} {prenom_responsable_groupe}</Grid>
          <Grid item xs={6}>{langageTranslation.codeQuote} : {code_devis}</Grid>
          <Grid item xs={6}>Email : {tripDetails[0].e_mail} </Grid>
          <Grid item xs={6}>{langageTranslation.groupSize} : {Nombre_Personne} </Grid>

        </Grid>
        <br />

        <Grid container spacing={0} justifyContent="center">
          <Grid className="Titre3Bill" item xs={6}>
            <br />
            {langageTranslation.contact} : {collaboratorbyID[0].lastname} {collaboratorbyID[0].firstname} {tripDetails[0].prenom_responsable_groupe}
            <br />
            <br />
            contact email :  {collaboratorbyID[0].email}
            <br />
            <br />
            contact phone :  {collaboratorbyID[0].phone}
          </Grid>
          <Grid className="Titre3Bill" item xs={6}>
            <img className="imageAdvisorBill" src={`${savedImgUrl}/${companyContent.company}/${collaboratorbyID[0].image}`} />

          </Grid>
        </Grid>
        <br />
        <Grid container spacing={0} justifyContent="center">
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={12}>DESCRIPTION </Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.circuitName} </Grid>
          <Grid className="Titre3Bill" item xs={8}>{nom_programme}</Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.duration} </Grid>
          <Grid className="Titre3Bill" item xs={8}>{langageTranslation.from} {moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).format('dddd DD MMM')} {langageTranslation.to} {datefin} </Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.pricePerPerson}</Grid>
          <Grid className="Titre3Bill" item xs={8}>{tripBilling.pricePerPerson} {tripBilling.currency} </Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.total}</Grid>
          <Grid className="Titre3Bill" item xs={8}>{tripBilling.tripPrice} {tripBilling.currency} </Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.deposit} ({tripBilling.discount} {tripBilling.discountUnit})</Grid>
          <Grid className="Titre3Bill" item xs={8}>{tripBilling.amountDiscount} {tripBilling.currency} </Grid>
          <Grid className="Titre2Bill" style={Couleurdefaut} item xs={4}>{langageTranslation.tripCostMinusDepo}</Grid>
          <Grid className="Titre3Bill" item xs={8}>{PrixMoinsDepot} {tripBilling.currency} </Grid>
          <Grid className="Titre3Bill" item xs={12}>
            <br />
            <Interweave content={informationsContent.fr_invoice} />
          </Grid>

        </Grid>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
})

export default withRouter(connect(mapStateToProps)(Bill))
