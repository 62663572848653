import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  put_tour_operator(token, tripPrice) {
    return axios.put(`${apiUrl}/api/price/booking/update`, { tripPrice }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_trip_billing_deposit(token, idDevis, billingDetails) {
    return axios.put(`${apiUrl}/api/billing/updatedeposit`, { idDevis, billingDetails }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_information_comment(token, informationComment) {
    return axios.put(`${apiUrl}/api/tripContent/info_comment`, { informationComment }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

}
