import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  // ******************* TABLE PRICE *********************

  get_trip_price(token, idDevis) {
    return axios.get(`${apiUrl}/api/price/${idDevis}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  put_trip_price(token, idDevis, changes) {
    return axios.put(`${apiUrl}/api/price/update`, { idDevis, changes }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  delete_row_price(token, idDevis, idDay, idCircuit, dayIndex) {
    return axios.delete(`${apiUrl}/api/price/delete/row/${idDevis}/${idDay}/${idCircuit}/${dayIndex}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  reset_data_column_price(token, idDevis, column) {
    return axios.put(`${apiUrl}/api/price/reset/dataColumn`, { idDevis, column }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  get_additional_column_price(token, idDevis) {
    return axios.get(`${apiUrl}/api/price/additional_column/${idDevis}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  put_additional_column_price(token, idAdditionalColumn, idDevis, columnRenamed) {
    return axios.put(`${apiUrl}/api/price/additional_column/update`, { idAdditionalColumn, idDevis, columnRenamed }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  reorder_days_rank(token, idDevis, days, tripPrice, tripActivities) {
    return axios.put(`${apiUrl}/api/price/reorder_days_rank`, {
      idDevis,
      days,
      tripPrice,
      tripActivities,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  // ******************* BILLING *********************

  get_trip_billing(token, idDevis) {
    return axios.get(`${apiUrl}/api/billing/${idDevis}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  put_trip_billing(token, idDevis, billingDetails) {
    return axios.put(`${apiUrl}/api/billing/update`, { idDevis, billingDetails }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_billing_acco(token, idDevis, accoChoice) {
    return axios.put(`${apiUrl}/api/billing/updateAcco`, { idDevis, accoChoice }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },


  // ******************* ACTIVITIES *********************

  get_trip_activities(token, idCircuit) {
    return axios.get(`${apiUrl}/api/tripActivities/all/${idCircuit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  put_trip_default_activities(token, idCircuit, idJournee, dayIndex, nbSenior, nbAdult, nbChild) {
    return axios.put(`${apiUrl}/api/tripActivities/default-activities`, {
      idCircuit,
      idJournee,
      dayIndex,
      nbSenior,
      nbAdult,
      nbChild,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_activity(token, idCircuit, idJournee, dayIndex, idActivity, nbSenior, nbAdult, nbChild) {
    return axios.put(`${apiUrl}/api/tripActivities/add-activity`, {
      idCircuit,
      idJournee,
      dayIndex,
      idActivity,
      nbSenior,
      nbAdult,
      nbChild,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_content_acco(token, idCircuit, tableauAcco, tableauRoom, nbRoom) {
    return axios.put(`${apiUrl}/api/tripContent/update-acco-room`, {
      idCircuit,
      tableauAcco,
      tableauRoom,
      nbRoom,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_check_activity(token, tripActivities) {
    return axios.put(`${apiUrl}/api/tripActivities/update-activity`, {
      tripActivities,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_traveller_activity(token, tripActivities, nbSenior, nbAdult, nbChild) {
    return axios.put(`${apiUrl}/api/tripActivities/update-activity-traveller`, {
      tripActivities,
      nbSenior,
      nbAdult,
      nbChild,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_price_activity(token, priceChange) {
    return axios.put(`${apiUrl}/api/tripActivities/update-activity-price`, {
      priceChange,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_accomodation_activity(token, tripAllDaysAccomodations, tripActivities, idTripSelected, idAcco) {
    return axios.put(`${apiUrl}/api/tripActivities/update-activity-accomodation`, {
      tripAllDaysAccomodations,
      tripActivities,
      idTripSelected,
      idAcco,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  delete_trip_activities(token, idCircuit, idJournee, dayIndex, idActivity, nbActivities) {
    // return axios.delete(`${apiUrl}/api/tripActivities/delete/${idCircuit}/${idJournee}/${dayIndex}/${nbActivities}/${idActivity}`, {
    return axios.delete(`${apiUrl}/api/tripActivities/delete`, {
      data: {
        idCircuit,
        idJournee,
        dayIndex,
        idActivity,
        nbActivities,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  delete_trip_activity(token, id_act_cir) {
    // return axios.delete(`${apiUrl}/api/tripActivities/delete/${idCircuit}/${idJournee}/${dayIndex}/${nbActivities}/${idActivity}`, {
    return axios.delete(`${apiUrl}/api/tripActivities/deleteSingle`, {
      data: {
        id_act_cir,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
}
