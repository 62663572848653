import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input } from '../../../components';

export default function DisplayLocalisationMap(props) {
  const {
    locationList,
    localisationList,
    handleLocalisationChange,
  } = props;

  const [localisationContent, setLocalisationContent] = useState(_.split(localisationList.map_localisation, ','));
  const [onChangeList, setOnChangeList] = useState(false);

  useEffect(() => {
    const tripLocalitionList = localisationList.map_localisation ? _.split(localisationList.map_localisation, ',') : [];
    setLocalisationContent(tripLocalitionList);
  }, [localisationList]);

  useEffect(() => {
    if (localisationContent) {
      handleLocalisationChange(localisationContent);
      setOnChangeList(false);
    }
  }, [onChangeList]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(localisationContent);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setLocalisationContent(reorderedItems);
    handleLocalisationChange(reorderedItems);
  };

  const displayAutocompleteLocalisation = (field, content, list) => (
    <Autocomplete
      multiple
      id={`${field}-field-${content.id}`}
      options={list.map(option => option.lieu)}
      value={localisationContent}
      onChange={onChangeLocalisation}
      filterOptions={filterOptionsLocalisation(list)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={getOptionLabelLocalisation}
      renderOption={option => option.lieu}
      freeSolo
      renderTags={() => (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-tags" direction="horizontal">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {localisationContent.map((option, index) => (
                  <Draggable key={option} draggableId={option} index={index}>
                    {chipProvided => (
                      <div ref={chipProvided.innerRef} {...chipProvided.draggableProps} {...chipProvided.dragHandleProps}>
                        <Chip variant="outlined" label={option} style={{ margin: 4 }} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      renderInput={params => (
        <Input
          {...params}
          variant="outlined"
          label="Map localisation"
          margin="normal"
          className="largeWidth"
        />
      )}
    />
  );

  const filterOptionsLocalisation = list => (options, params) => _.filter(list, d => d.lieu.toLowerCase().includes(params.inputValue.toLowerCase()));

  const getOptionLabelLocalisation = (option) => {
    if (typeof option === 'string') return option;
    if (!option.lieu) return '';
    if (option.inputValue) return option.inputValue;
    return option.lieu;
  };

  const onChangeLocalisation = (event, newValue, reason) => {
    if (_.isEmpty(newValue)) {
      setLocalisationContent([]);
      handleLocalisationChange({});
    } else if (reason === 'remove-option') {
      setLocalisationContent(newValue);
      handleLocalisationChange(newValue);
    } else {
      setLocalisationContent([...localisationContent, newValue[newValue.length - 1].lieu]);
    }
    setOnChangeList(true);
  };

  return (
    <div>
      {displayAutocompleteLocalisation('localisation', localisationList, locationList)}
    </div>
  );
}

DisplayLocalisationMap.propTypes = {
  locationList: PropTypes.array,
  localisationList: PropTypes.object,
  handleLocalisationChange: PropTypes.func.isRequired,
};

DisplayLocalisationMap.defaultProps = {
  locationList: [],
  localisationList: '',
};
