import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getCompanyContentResults,
  putCompanyContentResults,
  // deleteCompanyContentResults,
} from './actions'

function* getCompanyContentAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_company_content, userId)
  yield put(getCompanyContentResults(data))
}

function* putCompanyContentAsync(params) {
  const {
    token,
    companyContent,
  } = params
  const data = yield call(api.put_company_content, token, companyContent)
  yield put(putCompanyContentResults(data))
}

// function* deleteCompanyContentAsync(params) {
//   const {
//     token,
//     customerId,
//   } = params
//   const data = yield call(api.delete_customer_content, token, customerId)
//   yield put(deleteCompanyContentResults(data))
// }

function* fetchResult() {
  yield takeEvery('GET_COMPANY_CONTENT', getCompanyContentAsync)
  yield takeEvery('PUT_COMPANY_CONTENT', putCompanyContentAsync)
//   yield takeEvery('DELETE_COMPANY_CONTENT', deleteCompanyContentAsync)
}

export default fetchResult
