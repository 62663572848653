import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import tableIcons from '../../../../components/utils/icons/icons'
import {
  Input,
} from '../../../../components';
import { CssGrid } from '../../../../configCSS'
import greenFlag from '../../../../assets/images/good-flag.png'
import greenFlag2x from '../../../../assets/images/good-flag@2x.png'
import greenFlag3x from '../../../../assets/images/good-flag@3x.png'
import orangeFlag from '../../../../assets/images/warning-flag.png'
import orangeFlag2x from '../../../../assets/images/warning-flag@2x.png'
import orangeFlag3x from '../../../../assets/images/warning-flag@3x.png'

const {
  Show,
  Edit,
  Delete,
} = tableIcons

export default function TravelerCard(props) {
  const {
    traveler,
    travelerIndex,
    openTravelerCard,
    deleteTraveler,
  } = props;
  const {
    id_traveler,
    firstname,
    lastname,
    date_birth,
    image,
  } = traveler

  const warningFlag = date_birth && image ? 'good-flag' : 'warning-flag'
  return (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item xs={1} className="traveler-number">
        <span className={`${warningFlag}`}>{travelerIndex + 1}</span>
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id_traveler}firstname`}
          label="Firstname"
          value={firstname}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id_traveler}lastname`}
          label="Lastname"
          value={lastname}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={1} className="action-traveler-card" onClick={() => openTravelerCard('show', id_traveler, travelerIndex)}>
        <Tooltip title="Show" className="tooltip-traveler">
          <Show />
        </Tooltip>
      </CssGrid>
      <CssGrid item xs={1} className="action-traveler-card" onClick={() => openTravelerCard('edit', id_traveler, travelerIndex)}>
        <Tooltip title="Modify" className="tooltip-traveler">
          <Edit />
        </Tooltip>
      </CssGrid>
      <CssGrid item xs={1} className="action-traveler-card" onClick={() => deleteTraveler(id_traveler, travelerIndex)}>
        <Tooltip title="Delete" className="tooltip-traveler">
          <Delete />
        </Tooltip>
      </CssGrid>
      <CssGrid item xs={1} className="action-traveler-card">
        <Tooltip title={warningFlag} className="warning-falg">
          {warningFlag === 'good-flag'
            ? (
              <img src={greenFlag} srcSet={`${greenFlag2x} 2x, ${greenFlag3x} 3x`} alt="" />
            )
            : (
              <img src={orangeFlag} srcSet={`${orangeFlag2x} 2x, ${orangeFlag3x} 3x`} alt="" />
            )
          }
        </Tooltip>
      </CssGrid>
    </CssGrid>
  );
}

TravelerCard.propTypes = {
  traveler: PropTypes.object.isRequired,
  travelerIndex: PropTypes.number.isRequired,
  openTravelerCard: PropTypes.func.isRequired,
  deleteTraveler: PropTypes.func.isRequired,
};
