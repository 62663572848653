import { all, fork } from 'redux-saga/effects'
import api from '../components/api/saga'
import customer from '../pages/clients/api_requestors/saga'
import traveler from '../pages/clients/api_travelers/saga'
import userAccount from '../pages/userAccount/api/saga'
import company from '../pages/company/api/saga'
import informations from '../pages/management/informations/api/saga'
import extension from '../pages/management/extension/api/saga'
import activity from '../pages/management/activity/api/saga'
import tripExtension from '../pages/tripExtension/api/saga'
import tripSite from '../pages/tripSite/api/saga'
import trip from '../pages/trip/api/saga'
import billing from '../pages/billing/api/saga'
import picture from '../pages/management/gallery/api/saga'
import collaborators from '../pages/collaborators/api/saga'
import invoice from '../pages/invoice/api/saga'
import request from '../pages/request/api/saga'
import tourOperator from '../pages/tourOperator/api/saga'
import resource from '../pages/resources/api/saga'
import suppliers from '../pages/management/suppliers/api/saga'


const sagas = [
  api,
  customer,
  traveler,
  userAccount,
  company,
  informations,
  extension,
  activity,
  tripExtension,
  tripSite,
  trip,
  billing,
  picture,
  collaborators,
  request,
  tourOperator,
  resource,
  invoice,
  suppliers,
]

function* rootSaga() {
  yield all(
    sagas.map(saga => fork(saga)),
  )
}

export default rootSaga
