/* eslint-disable jsx-a11y/accessible-emoji */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import meal from '../../../assets/images/meal.png'
import bed from '../../../assets/images/Bed.png'
import stage from '../../../config'
import checked from '../../../assets/images/checked.png'
import checked2x from '../../../assets/images/checked@2x.png'
import checked3x from '../../../assets/images/checked@3x.png'
import unchecked from '../../../assets/images/unchecked.png'
import unchecked2x from '../../../assets/images/unchecked@2x.png'
import unchecked3x from '../../../assets/images/unchecked@3x.png'

import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlExploration extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripBilling: PropTypes.object,
    tripDevisFinal: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    tripAllDays: [],
    tripDevisFinal: {},
    tripAllDaysAccomodations: [],
    tripContent: [],
    tripAccomodation: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.handleOSizeZoomChange = this.handleOSizeZoomChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      zoomCarte: 100,
      displayHebergement: true,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteExplo-size', `${event.currentTarget.value}px`)
  }

  handleOSizeZoomChange = (event) => {
    this.setState({
      zoomCarte: event.currentTarget.value,
    })
  }

handlePrint = (html) => {
  const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

  const newWindow = window.open('', '_blank');
  newWindow.document.write(content);
  newWindow.document.close();
}

handleSave = (devisContent) => {
  const {
    PUT_TRIP_DEVIS_FINAL,
    tripDetails,
    tripDevisFinal,
  } = this.props
  const {
    token,
  } = this.state
  const tripDevisFinalServeur = {}
  tripDevisFinalServeur.textHtml = devisContent
  tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
  if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
  } else {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
  }
}

handleEditorChange = (content) => {
  this.setState({
    devisContent: content,
  });
}


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }
    console.log(apiUrl)
    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word = (element, filename = '') => {
    const html = element;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }


  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }
    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAllDaysAccomodations,
      tripAccomodation,
      tripPension,
      tripBilling,
      companyContent,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]

    const {
      langage,
      centre_interet,
      desc_voyage,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      map_localisation,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      zoomCarte,
      displayHebergement,
      devisContent,
    } = this.state
    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************


    const gras = {
      fontWeight: 'bold',
    };
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = zoomCarte === 100 ? (`https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    ) : (
      `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&zoom=${zoomCarte}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    )
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const remarque = `${langage}_remarque`
    const longueurVoyage = tripAllDaysAccomodations.length
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const categorie = `${langage}_categorie`
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(IncDayTot, 'd').format('DD MMMM YYYY')
    const slogan = `${langage}_slogan`

    const borderRadius = {
      borderRadius: 15,
    };
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }
    const pied = (
      <Interweave content={companyContent.description} />
    )

    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFileExploration">
          {indexDisplay !== 'HTMLWORD'
           && (
           <div className="page-header-explorationhtml">
             <Grid container justifycontent="space-between" spacing={0}>
               <Grid item xs={3}>
                 <img className="LogoExploration" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
               </Grid>
               <Grid item xs={6}>
                 <Interweave content={companyContent[slogan]} />
               </Grid>
               <Grid item xs={3}>
                 <div className="centreExploration">
                   <br />
                   <div>{travelerName}</div>
                   <div>Du {dateDepart} au {dateFin}</div>
                 </div>
               </Grid>
             </Grid>
             <div className="navbar">
               <a href="#home">{langageTranslation.homeMenu}</a>
               {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
               <a href="#carte">{langageTranslation.carteMenu}</a>)}
               <a href="#detail">{langageTranslation.detailMenu}</a>
               <a href="#hotel">{langageTranslation.hotelMenu}</a>
               <a href="#prix">{langageTranslation.prixMenu}</a>
               <a href="#info">{langageTranslation.infoMenu}</a>
               <a href="#video">Video</a>
             </div>
           </div>
           )}
          <div id="home" className="banniereExploration">
            {indexDisplay !== 'HTMLWORD'
           && (
           <img width="300" height="700" className="ImageCoverExploration" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
           )}
            <Grid className="InfoPremierExploration" container spacing={0} align="row" justifyContent="center">
              <Grid item xs={12}>
                <br />
                <div className="grandTitreExplo1">Circuit sur mesure pour {travelerName}</div>
                <br />
                <div className="sousTitreExplo1">
                  <div>{IncDayTot} jours</div>
                  <div>({dateDepart} - {dateFin})</div>
                </div>
                <br />
                <div className="grandTitreExplo1">{tripDetails && (tripDetails[0].nom_programme)}</div>
              </Grid>
            </Grid>
          </div>
          {indexDisplay !== 'HTMLWORD'
           && (
             <div className="page-footer-exploration">
               <Interweave content={companyContent.description} />
             </div>
           )}

          <div>
            <table className="table-explo">
              <tbody>
                <tr>
                  <td>
                    <div className="CoupePasExplo">
                      <div className="banniereTitreExplo">
                        {indexDisplay !== 'HTMLWORD'
                        && (
                        <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                        ) }
                        <h2 className="titreExplo">PRESENTATION GENERALE</h2>
                        <br />
                      </div>
                      <div className="marginLefRightExplo">
                        <Interweave content={desc_voyage} />
                      </div>
                      <br />
                      <div className="banniereTitreExplo">
                        {indexDisplay !== 'HTMLWORD'
                      && (
                        <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                      )
                         }
                        <h2 className="titreExplo">LES POINTS FORTS DU CIRCUIT</h2>
                        <br />
                      </div>
                      <div className="marginLefRightExplo">
                        <Interweave content={centre_interet} />
                      </div>
                    </div>
                    <div className="CoupePasExplo">
                      <br />
                      <div className="banniereTitreExplo">
                        {indexDisplay !== 'HTMLWORD'
                      && (
                        <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                      )
                        }
                        <h2 className="titreExplo">VOTRE ITINENAIRE</h2>
                        <br />
                      </div>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td className="tabItineraireExploGlobal">
                              <table>
                                <tbody>
                                  {tripAllDays.map((item, index) => {
                                    const IncDay = _.sum(TabIncJour.slice(0, index))
                                    const title_perso = `titre_jour_${index + 1}`;
                                    const datejour = tripDetails[0].date_depart
                                      ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMM'))
                                      : ''
                                    return (
                                      <tr key={`${datejour}`}>
                                        <td className="TabItineraireExplo1">J{IncDay + 1}/</td>
                                        <td className="TabItineraireExplo1">
                                          <a className="fondBlackExplo" href={`#Jour${index}`}>
                                            {tripContent[0][title_perso]}
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </td>
                            <td>
                              {indexDisplay !== 'HTMLWORD' && (tripDetails[0].image || tripDetails[0].map_localisation) && (

                              <div className="CoupePasExplo">
                                {tripDetails[0].image
                                  ? (
                                    <div id="carte">
                                      <Grid container spacing={0} justifyContent="center">
                                        <Grid item xs={12}>
                                          <img style={borderRadius} width="500" height="450" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ) : (tripDetails[0].map_localisation && (
                                  <div id="carte">
                                    <img style={borderRadius} width="500" height="450" src={urlMap} />
                                    <div>
                                      <input className="adjustSize" title="adjust map height" name="size" min="200" max="1000" type="range" defaultValue="450" onChange={this.handleOSizeChange} />
                                      <input className="adjustSizeZoom" title="adjust map zoom" name="size" min="1" max="15" type="range" defaultValue="5" onChange={this.handleOSizeZoomChange} />
                                    </div>
                                  </div>
                                  )
                                  )}
                              </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="premierePageExplo" />
                    <div id="detail" className="banniereTitreExplo">
                      {indexDisplay !== 'HTMLWORD'
                      && (
                      <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                      )
                      }
                      <h2 className="titreExplo">PROGRAMME DETAILLE</h2>
                      <br />
                    </div>
                    <div>
                      {tripAllDays.map((item_jour, index) => {
                        const desc = `desc_jour_${index + 1}`;
                        const imageArray = item_jour.image.split(',')
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('dddd DD MMMM YYYY'))
                          : ''
                          // eslint-disable-next-line no-nested-ternary
                        let guideNeed = ''
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = ''
                            break;
                          case '1':
                            guideNeed = 'Guide francophone'
                            break;
                          case '2':
                            guideNeed = 'Guide anglophone'
                            break;
                          default:
                            guideNeed = ''
                        }
                        let driverNeed = ''
                        switch (chauffeurArray[index]) {
                          case '0':
                            driverNeed = ''
                            break;
                          case '1':
                            driverNeed = 'chauffeur'
                            break;
                          case '2':
                            driverNeed = 'moto'
                            break;
                          case '3':
                            driverNeed = 'moto + voiture'
                            break;
                          default:
                            driverNeed = ''
                        }
                        const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                        const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver
                        const title_perso = `titre_jour_${index + 1}`;
                        const BandeauJour = () => (
                          <div id={`Jour${index}`} className="titreJourExplo">
                            <br />
                            Jour {IncDay + 1} - {datejour}
                            <br />
                            {tripContent[0][title_perso]}
                          </div>
                        );

                        const TableService = () => (
                          <div>
                            {tripAccomodation[index].nom_hebergement !== 'none' && (
                              <div>
                                <div className="inlineExplo">
                                  {indexDisplay !== 'HTMLWORD' && (<img className="logoService" src={`${savedImgUrl}/duyendams/lithotel-1674202517098.png`} />)}
                                  {tripAccomodation[index].nom_hebergement !== 'none' && (tripAccomodation[index].nom_hebergement)}
                                </div>
                                <div className="inlineExplo">
                                  {indexDisplay !== 'HTMLWORD' && nbOfRoomLabel(index) && (<img className="logoService" src={bed} />)}
                                  {nbOfRoomLabel(index) && (<span>{!roomsNumberArray ? '' : nbOfRoomLabel(index)}</span>)}
                                </div>
                              </div>
                            )
                          }
                            <div className="inlineExplo">
                              {indexDisplay !== 'HTMLWORD' && (<img className="logoService" src={`${savedImgUrl}/duyendams/checkCap-1674204436016.png`} />)}
                              {asteriskText}
                            </div>
                            {tripPension[index].pension !== 'none' && (
                              <div className="inlineExplo">
                                {indexDisplay !== 'HTMLWORD' && (<img className="logoService" src={meal} />)}
                                {tripPension[index].pension}
                              </div>
                            )
                                            }
                          </div>
                        );
                        return (
                          <div key={`${datejour}`}>
                            <div>
                              {imageArray[0] !== ''
                                ? (
                                  <div>
                                    <br />
                                    <div className="CoupePasExplo">
                                      <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} lg={12} sm={12}>
                                          <BandeauJour />
                                        </Grid>
                                        <Grid item xs={8} lg={8} sm={12}>
                                          <div className="marginJourneeLefRightExplo">
                                            <Interweave content={tripContent[0][desc]} />
                                          </div>
                                        </Grid>
                                        <Grid item xs={4} lg={4} sm={12}>
                                          <div className="titreImgExplo">
                                            {
                                        imageArray.map((item, indexImg) => (
                                          <div>
                                            <img width="270" height="150" className="Image1Jour1Explo" id={`image_${indexImg}`} src={`${savedImgUrl}/${companyContent.company}/${item}`} />
                                            <br />
                                            <br />
                                          </div>
                                        ))
                                      }
                                          </div>
                                          <TableService />
                                        </Grid>
                                      </Grid>

                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="CoupePasExplo">
                                      <div>
                                        <BandeauJour />
                                        <Interweave content={tripContent[0][desc]} />
                                        <TableService />
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                )
            }
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="buttonHebergementExplo" onClick={() => this.setState({ displayHebergement: !displayHebergement })}>
                      <br />
                      display hebergement tab? :
                      {displayHebergement
                        ? (
                          <img className="imgChecked" src={checked} srcSet={`${checked2x} 2x, ${checked3x} 3x`} alt="Checked" />
                        )
                        : (
                          <img className="imgChecked" src={unchecked} srcSet={`${unchecked2x} 2x, ${unchecked3x} 3x`} alt="Checked" />
                        )}
                    </div>
                    {displayHebergement
                        && (
                          <div className="CoupePasExplo">
                            <div id="hotel" className="banniereTitreExplo">
                              {indexDisplay !== 'HTMLWORD'
                              && (
                              <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                              )
                              }
                              <h2 className="titreExplo">VOS HEBERGEMENTS</h2>
                              <br />
                            </div>
                            <table width="100%" className="TabItineraireExploHotelGlobal">
                              <tbody>
                                <tr>
                                  <td width="20%" style={gras} className="TabItineraireExploHotel">{langageTranslation.destination}</td>
                                  <td width="60%" style={gras} className="TabItineraireExploHotel">{langageTranslation.accomodation}</td>
                                  <td width="20%" style={gras} className="TabItineraireExploHotel">{langageTranslation.nightNumber}</td>
                                </tr>
                                {Hotel_Unique && Hotel_Unique.map((item, i) => {
                                  const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                                  const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');

                                  return (
                                    item.nom_hebergement !== 'none'
                && (
                  <tr key={`Acco${item.id_journee}`}>
                    <td width="20%" style={gras} className="TabItineraireExploHotel">{LieuFinJournee.lieu}</td>
                    <td width="60%" className="TabItineraireExploHotel">
                      <div style={gras}>{item.nom_hebergement} {item[categorie]}</div>
                      <Interweave content={adresseHebClean} />
                    </td>
                    <td width="20%" style={gras} className="TabItineraireExploHotel">{Nombre_Occurence_Hotel[i].occurrence}</td>
                  </tr>
                )
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                    {tripBilling.displayTabCheck && (
                    <div>
                      <div className="CoupePasExplo">
                        <div>
                          <div id="prix" className="banniereTitreExplo">
                            {indexDisplay !== 'HTMLWORD'
                            && (
                            <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                            )
                            }
                            <h2 className="titreExplo">L'OFFRE TARIFAIRE</h2>
                            <br />
                          </div>
                          <div className="texteFondExplo">
                            <Interweave content={tripBilling.tabPrice} />
                          </div>
                        </div>
                        <br />
                      </div>
                      <div id="info" className="CoupePasExplo">
                        <div className="banniereTitreExplo">
                          {indexDisplay !== 'HTMLWORD'
                          && (
                          <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                          )
                          }
                          <h2 className="titreExplo">LES SERVICES</h2>
                          <br />
                        </div>
                        <div className="texteFondExplo">
                          <h3>  Inclus dans la prestation  </h3>
                          <br />
                          <Interweave content={tripDetails[0].prix_comprend} />
                          <h3> Non inclus dans la prestation  </h3>
                          <br />
                          <Interweave content={tripDetails[0].prix_comprend_pas} />
                        </div>
                      </div>
                    </div>
                    )}
                    {indexDisplay !== 'HTMLWORD'
           && (
           <Grid container spacing={0} justifyContent="center">
             <Grid item xs={12}>
               <div className="banniereTitreExplo">
                 {indexDisplay !== 'HTMLWORD'
                 && (
                 <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                 )
                 }
                 <h2 className="titreExplo">CONDITIONS DE PAIEMENT ET D’ANNULATION</h2>
                 <br />
               </div>
               <div className="texteFondExplo">
                 <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
               </div>
             </Grid>
           </Grid>
           )}
                    {langage && langage === 'fr' && (
                    <div id="video" className="videoBoxExplo">
                      <div className="banniereTitreExplo">
                        {indexDisplay !== 'HTMLWORD'
                        && (
                        <img className="imageTitreExplo" width="300" height="700" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/vietnam_exploration/bandeauExploration-1684225702347.png" />
                        )
                        }
                        <h2 className="titreExplo">VIDEO</h2>
                        <br />
                      </div>
                      <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoExplo" width="424" height="238" src="https://www.youtube.com/embed/K6luv_RDjf8" title="Voyage Moto Vietnam | Nord du Vietnam à moto | Vietnam Exploration" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoExplo" width="424" height="238" src="https://www.youtube.com/embed/IWxZMzm1Fh0" title="Nghia Lo| Roadtrip Moto Vietnam | Vietnamexploration" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                      </Grid>
                    </div>
                    )}

                  </td>
                </tr>
              </tbody>
              {(indexDisplay !== 'HTMLWORD')
           && (
           <tfoot>
             <tr>
               <td>
                 <div className="page-footer-space-exploration" />
               </td>
             </tr>
           </tfoot>
           )}
            </table>
          </div>
        </div>

      )
      return myPage
    }
    const footerHtml = renderToStaticMarkup(pied)
    const pageHtml = renderToStaticMarkup(myPageFunc('HTMLWEB'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));

    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlExploration.css">
     <title>Votre proposition de voyage</title>
     </head>
     <body>
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a> 
        ${devisContent}
      </body>
   </html>
   `;
    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlExploration.css">
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>

<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
   
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
             
            <div style='mso-element:footer' id="f1">
            <p class="MsoFooter">
                <table width="100%" >
                    <tr>
                        <td  class="footer">
                    ${footerHtml}
                        </td>
                    </tr>
                </table>
            </p>
        </div>
        </td>                         
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}.docx`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlExploration.css"
          height={1000}
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          handleEditorChange={content => this.handleEditorChange(content)}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripDevisFinal: state.api.tripDevisFinal,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlExploration))
