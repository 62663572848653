import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';

class index extends Component {
  static propTypes = {
    deleteItem: PropTypes.func,
  }

  static defaultProps = {
    deleteItem: () => {},
  }

  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this)
    this.state = {};
  }

  delete = () => {
    const { deleteItem } = this.props
    deleteItem()
  }

  render() {
    return (
      <div className="FAB-root">
        <Fab
          aria-label="delete"
          onClick={this.delete}
        >
          <DeleteIcon />
        </Fab>
      </div>
    );
  }
}

export default index;
