const resourcesColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'nom_journee',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'nom_journee_court',
    label: 'Short name',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'version',
    label: 'Version',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'start_location',
    label: 'Start',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'end_location',
    label: 'End',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'category',
    label: 'Category',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'language',
    label: 'Language',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'country',
    label: 'Country',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'transport',
    label: 'Transport',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'distance',
    label: 'Distance',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'desc_journee',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'image',
    label: 'Image',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
]

export default resourcesColumns
