export const GET_FILTER_QUOTE_TABLE_RESULTS = 'GET_FILTER_QUOTE_TABLE_RESULTS'
export const PUT_COLUMN_QUOTE_TABLE_RESULTS = 'PUT_COLUMN_QUOTE_TABLE_RESULTS'
export const PUT_FILTER_QUOTE_TABLE_RESULTS = 'PUT_FILTER_QUOTE_TABLE_RESULTS'
export const RESET_FILTER_QUOTE_RESULTS = 'RESET_FILTER_QUOTE_RESULTS'

export const getFilterQuoteTableResults = data => ({
  type: GET_FILTER_QUOTE_TABLE_RESULTS,
  filterQuoteTable: data,
})

export const putColumnQuoteTableResults = data => ({
  type: PUT_COLUMN_QUOTE_TABLE_RESULTS,
  putColumnQuoteTable: data,
})

export const putFilterQuoteTableResults = data => ({
  type: PUT_FILTER_QUOTE_TABLE_RESULTS,
  putFilterQuoteTable: data,
})

export const resetFilterQuoteResults = () => ({
  type: RESET_FILTER_QUOTE_RESULTS,
})
