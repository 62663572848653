// Management
export const GET_EXTENSION_LIST_RESULTS = 'GET_EXTENSION_LIST_RESULTS'
export const PUT_EXTENSION_CONTENT_RESULTS = 'PUT_EXTENSION_CONTENT_RESULTS'
export const DELETE_EXTENSION_CONTENT_RESULTS = 'DELETE_EXTENSION_CONTENT_RESULTS'
export const RESET_EXTENSION_CONTENT = 'RESET_EXTENSION_CONTENT'

export const getExtensionListResults = data => ({
  type: GET_EXTENSION_LIST_RESULTS,
  extensionList: data,
})

export const putExtensionContentResults = data => ({
  type: PUT_EXTENSION_CONTENT_RESULTS,
  putExtensionContent: data,
})

export const deleteExtensionContentResults = data => ({
  type: DELETE_EXTENSION_CONTENT_RESULTS,
  deleteExtensionContent: data,
})

export const resetExtensionContent = () => ({
  type: RESET_EXTENSION_CONTENT,
})
