export const GET_INVOICE_LIST_RESULTS = 'GET_INVOICE_LIST_RESULTS'
export const PUT_COMPANY_INVOICE_RESULTS = 'PUT_COMPANY_INVOICE_RESULTS'
export const PUT_TRIP_BILLING_BILLINGREF_RESULTS = 'PUT_TRIP_BILLING_BILLINGREF_RESULTS'
export const RESET_GENERATE_INVOICE = 'RESET_GENERATE_INVOICE'


export const getInvoiceListResults = data => ({
  type: GET_INVOICE_LIST_RESULTS,
  invoiceList: data,
})

export const putCompanyInvoiceResults = data => ({
  type: PUT_COMPANY_INVOICE_RESULTS,
  putCompanyInvoice: data,
})

export const putTripBillingBillingRefResults = data => ({
  type: PUT_TRIP_BILLING_BILLINGREF_RESULTS,
  putTripBilling: data,
})

export const resetGenerateInvoice = () => ({
  type: RESET_GENERATE_INVOICE,
})
