import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import MenuItem from '@material-ui/core/MenuItem';
import {
  Input,
} from '../../../../components'
import { CssGrid } from '../../../../configCSS'

export default function CategoryFilter(props) {
  const {
    list,
    updateResourcesFilter,
  } = props

  const [filterChoise, setFilterChoise] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  useEffect(() => {
    setCategoryList(getUniqueValue('category'))
    setSubCategoryList(getUniqueValue('sub_category'))
  }, [list])

  useEffect(() => {
    updateResourcesFilter(filterChoise)
  }, [filterChoise])

  const getUniqueValue = (proprety) => {
    const uniquevalueArray = new Set()
    list.map(obj => uniquevalueArray.add(obj[proprety]))
    return _.pull(['-- no filter --', ...uniquevalueArray], undefined)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (value === '-- no filter --') {
      if (name === 'category') {
        setFilterChoise({})
      } else {
        const newFilterChoise = { ...filterChoise }
        delete newFilterChoise[name]
        setFilterChoise(newFilterChoise)
      }
    } else {
      setFilterChoise({
        ...filterChoise,
        [name]: value,
      })
    }
  }

  return (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item md={6} xs={12}>
        <Input
          id="category"
          name="category"
          label="Category"
          select
          value={filterChoise.category || ''}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        >
          {categoryList.map(option => (
            <MenuItem key={option} value={option}>{`${option}`}</MenuItem>
          ))}
        </Input>
      </CssGrid>
      <CssGrid item md={6} xs={12}>
        <Input
          id="sub-category"
          name="sub_category"
          label="Sub-category"
          select
          value={filterChoise.sub_category || ''}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        >
          {subCategoryList.map(option => option && (
            <MenuItem key={option} value={option}>
              {`${option}`}
            </MenuItem>
          ))}
        </Input>
      </CssGrid>
    </CssGrid>
  )
}

CategoryFilter.propTypes = {
  list: PropTypes.array.isRequired,
  updateResourcesFilter: PropTypes.func.isRequired,
};
