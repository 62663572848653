import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import MUIDataTable from 'mui-datatables'
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import _ from 'lodash'
import { CssGrid } from '../../configCSS'

import {
  Template,
  CircuitDescription,
  Stepper,
  ActionButton,
  Loader,
  ModalComponent,
  Input,
} from '../../components'

class Trip extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    listCircuit: PropTypes.array,
    idTripSelected: PropTypes.number,
    rowTripSelected: PropTypes.array,
    deletedTrip: PropTypes.object,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripActivities: PropTypes.object,
    tripAllDaysAccomodations: PropTypes.array,
    tripSeasonByAccomodation: PropTypes.array,
    tripSeasonByAccomodationOpt1: PropTypes.array,
    tripSeasonByAccomodationOpt2: PropTypes.array,
    tripPension: PropTypes.array,
    tripBilling: PropTypes.object,
    userAccount: PropTypes.object,
    putTripDetails: PropTypes.object,
    filterQuoteTable: PropTypes.object,
    putFilterQuoteTable: PropTypes.object,
    RESET_APP: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    ID_TRIP_SELECT: PropTypes.func.isRequired,
    GET_FILTER_QUOTE_TABLE: PropTypes.func.isRequired,
    PUT_COLUMN_QUOTE_TABLE: PropTypes.func.isRequired,
    PUT_FILTER_QUOTE_TABLE: PropTypes.func.isRequired,
    RESET_FILTER_QUOTE_RESULTS: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    GET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    GET_LIST_CIRCUIT: PropTypes.func.isRequired,
    GET_REQUEST: PropTypes.func.isRequired,
    DELETE_TRIP: PropTypes.func.isRequired,
    RESET_DELETE_TRIP: PropTypes.func.isRequired,
    GET_PENSION_LIST: PropTypes.func.isRequired,
    GET_STATUS_LIST: PropTypes.func.isRequired,
    GET_DAYS_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_LOCATION_GRAND_LIST: PropTypes.func.isRequired,
    GET_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_ROOM_LIST: PropTypes.func.isRequired,
    GET_ROOM_BY_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_EXTENSION_LIST: PropTypes.func.isRequired,
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    GET_PROFIL_USER_LIST: PropTypes.func.isRequired,
    GET_CUSTOMER_LIST: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    GET_TRIP_DETAILS: PropTypes.func.isRequired,
    GET_TRIP_CONTENT: PropTypes.func.isRequired,
    GET_TRIP_ALLDAYS: PropTypes.func.isRequired,
    GET_TRIP_ACTIVITIES: PropTypes.func.isRequired,
    GET_TRIP_ALLDAYS_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_SEASON_BY_ACCOMODATION: PropTypes.func.isRequired,
    GET_TRIP_SEASON_BY_ACCOMODATION_OPT1: PropTypes.func.isRequired,
    GET_TRIP_SEASON_BY_ACCOMODATION_OPT2: PropTypes.func.isRequired,
    GET_SEASON_BY_ACTIVITY_ALL: PropTypes.func.isRequired,
    GET_TRIP_PENSION: PropTypes.func.isRequired,
    GET_TRIP_BILLING: PropTypes.func.isRequired,
    GET_TRAVELER_BY_TRIP: PropTypes.func.isRequired,
    GET_COLLABORATORS: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    ACTIVE_STEP: PropTypes.func.isRequired,
    VALIDATE_STEP: PropTypes.func.isRequired,
    PUT_TRIP_DETAILS: PropTypes.func.isRequired,
    RESET_TRIP_DETAILS: PropTypes.func.isRequired,
    collaborators: PropTypes.array,
  }

  static defaultProps = {
    isLoading: true,
    idTripSelected: 0,
    rowTripSelected: [],
    listCircuit: [],
    deletedTrip: {},
    tripDetails: [],
    tripContent: [],
    tripAllDays: [],
    tripActivities: {},
    tripAllDaysAccomodations: [],
    tripSeasonByAccomodation: [],
    tripSeasonByAccomodationOpt1: [],
    tripSeasonByAccomodationOpt2: [],
    tripPension: [],
    tripBilling: null,
    userAccount: {},
    putTripDetails: {},
    filterQuoteTable: {},
    putFilterQuoteTable: {},
    collaborators: [],
  }

  constructor(props) {
    super(props);
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.getAllTripInformation = this.getAllTripInformation.bind(this)
    this.selectTrip = this.selectTrip.bind(this)
    this.validateStep = this.validateStep.bind(this)
    this.formatQuoteTable = this.formatQuoteTable.bind(this)
    this.deleteTrip = this.deleteTrip.bind(this)
    this.resetInformation = this.resetInformation.bind(this)
    this.duplicateTrip = this.duplicateTrip.bind(this)
    this.saveFilter = this.saveFilter.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.changeColumnView = this.changeColumnView.bind(this)
    this.changeFilterView = this.changeFilterView.bind(this)
    this.getModifiedItem = this.getModifiedItem.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      tripSelected: {},
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      listCircuit: [],
      isReadyToPrint: false,
      isModalOpen: false,
      isFilterDateChanged: false,
      columns: [],
      validMsg: '',
      circuitSearch: '',
      isQueryLaunched: false,
      filterStartDate: moment().subtract(120, 'd').format('YYYY-MM'),
      filterEndDate: '',
      isDateChanged: false,
    }
  }

  componentDidMount() {
    const {
      token,
      userId,
      filterStartDate,
    } = this.state
    const {
      history,
      filterQuoteTable,
      LOADER,
      GET_FILTER_QUOTE_TABLE,
      GET_USER_ACCOUNT,
      GET_COMPANY_CONTENT,
      GET_INFORMATIONS_CONTENT,
      GET_LIST_CIRCUIT,
      GET_REQUEST,
      GET_STATUS_LIST,
      GET_DAYS_LIST,
      GET_PENSION_LIST,
      GET_LOCATION_LIST,
      GET_LOCATION_GRAND_LIST,
      GET_ACCOMODATION_LIST,
      GET_ROOM_LIST,
      GET_ROOM_BY_ACCOMODATION_LIST,
      GET_EXTENSION_LIST,
      GET_ACTIVITY_LIST,
      GET_PROFIL_USER_LIST,
      GET_CUSTOMER_LIST,
      GET_TRAVELER_BY_TRIP,
      GET_SEASON_BY_ACTIVITY_ALL,
      GET_COLLABORATORS,
      ACTIVE_STEP,
    } = this.props
    // Active Loader

    if (history.location.state) {
      if (history.location.state.source === 'homePage') {
        this.setState({
          circuitSearch: history.location.state && (history.location.state.source === 'homePage') ? history.location.state.NomCircuit : '',
        })
      }
    }

    LOADER(true)
    // define active step for Stepper
    ACTIVE_STEP('trip')
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (filterQuoteTable) {
      this.setState({
        columns: this.formatQuoteTable(filterQuoteTable),
      })
    }


    // Load lists
    GET_FILTER_QUOTE_TABLE(token)
    GET_USER_ACCOUNT(userId)
    GET_COMPANY_CONTENT(userId)
    GET_INFORMATIONS_CONTENT(userId)
    GET_LIST_CIRCUIT(token, filterStartDate, userId)
    GET_STATUS_LIST()
    GET_DAYS_LIST(userId)
    GET_PENSION_LIST(userId)
    GET_LOCATION_LIST(userId)
    GET_LOCATION_GRAND_LIST(userId)
    GET_ACCOMODATION_LIST(userId)
    GET_ROOM_LIST(userId)
    GET_ROOM_BY_ACCOMODATION_LIST(userId)
    GET_PROFIL_USER_LIST()
    GET_CUSTOMER_LIST(userId)
    GET_EXTENSION_LIST(userId)
    GET_ACTIVITY_LIST(userId)
    GET_TRAVELER_BY_TRIP(token)
    GET_SEASON_BY_ACTIVITY_ALL(userId)
    GET_REQUEST(token)
    GET_COLLABORATORS(token)
  }

  componentWillReceiveProps(nextProps) {
    const {
      token,
      tripSelected,
      listCircuit: listCircuitOld,
      isReadyToPrint,
      isQueryLaunched,
      filterStartDate,
      isDateChanged,
      userId,
    } = this.state
    const { filterQuoteTable: filterQuoteTableOld } = this.props
    const {
      listCircuit,
      idTripSelected,
      deletedTrip,
      tripDetails,
      tripContent,
      tripAllDays,
      tripPension,
      tripBilling,
      userAccount,
      history,
      putTripDetails,
      rowTripSelected,
      filterQuoteTable,
      putFilterQuoteTable,
      RESET_APP,
      LOADER,
      VALIDATE_STEP,
      ID_TRIP_SELECT,
      RESET_DELETE_TRIP,
      RESET_FILTER_QUOTE_RESULTS,
      GET_FILTER_QUOTE_TABLE,
      GET_LIST_CIRCUIT,
      GET_TRIP_CONTENT,
      GET_TRIP_DETAILS,
      RESET_TRIP_DETAILS,
      ROW_TRIP_SELECT,
    } = nextProps

    // check if token is always valid
    if (!_.isEmpty(listCircuit) && listCircuit[0].error) {
      localStorage.setItem('isLogged', false)
      alert(listCircuit[0].error)
      history.push('/')
    }
    // chek if user account is filled
    if (!_.isEmpty(userAccount) && userAccount.company === 'undefined') {
      alert('Please, contact your administrator to fill in your account')
      LOADER(false)
      RESET_APP()
      history.push('/')
    }

    // update filter
    if (!_.isEqual(filterQuoteTable, filterQuoteTableOld)) {
      this.setState({
        columns: this.formatQuoteTable(filterQuoteTable),
      })
    }

    if (putFilterQuoteTable && putFilterQuoteTable.success) {
      RESET_FILTER_QUOTE_RESULTS()
      this.setState({
        validMsg: 'Filters were successfully recorded !',
      })
      GET_FILTER_QUOTE_TABLE(token)
      this.resetValidMsg()
    }


    // update trip list and row reslected
    if (!_.isEqual(listCircuit, listCircuitOld)) {
      this.setState({
        listCircuit,
      }, () => {
        // update row selected
        if (!_.isEmpty(tripSelected) || idTripSelected > 0) {
          const newIndexRow = listCircuit.findIndex(l => l.id_devis === tripSelected.id_devis || l.id_devis === idTripSelected)
          ROW_TRIP_SELECT([newIndexRow])
        } else if (_.isEmpty(rowTripSelected)) {
          this.selectTrip([])
          LOADER(false)
        }
      })
    }

    // stop loader when date filter change and listCircuit is already up to date
    if (_.isEqual(listCircuit, listCircuitOld) && isDateChanged) {
      LOADER(false)
      this.setState({
        isDateChanged: false,
      })
    }

    // when duplicated trip, save new id and go to next page
    if (putTripDetails && putTripDetails.success) {
      this.setState({
        tripSelected: {
          ...tripSelected,
          id_devis: putTripDetails.id,
        },
      })
      GET_LIST_CIRCUIT(token, filterStartDate, userId)
      GET_TRIP_DETAILS(token, putTripDetails.id)
      // GET_TRIP_ROOM(tripContent[0].type_chambre, tripContent[0].hebergement)
      RESET_TRIP_DETAILS()
      ID_TRIP_SELECT(putTripDetails.id, false, false, true)
      ROW_TRIP_SELECT([])
    }

    // Prepare to print *******************************************************************
    // when we get details then get content
    if (!isReadyToPrint && !_.isEmpty(tripDetails) && _.isEmpty(tripContent)) {
      if (tripDetails[0].id_circuit > 1) {
        GET_TRIP_CONTENT(tripDetails[0].id_circuit)
      } else {
        ID_TRIP_SELECT(idTripSelected, true)
        VALIDATE_STEP('Trip', true)
        VALIDATE_STEP('TripDetails', true)
        LOADER(false)
      }
    }
    // get all informations for this trip

    if (!isReadyToPrint && !_.isEmpty(tripContent) && !isQueryLaunched) {
      this.setState({
        isQueryLaunched: true,
      })
      this.getAllTripInformation(tripContent, tripDetails)
    }
    // check if all informations have been received adn valid step
    if (!isReadyToPrint && !_.isEmpty(tripAllDays) && !_.isEmpty(tripPension)) {
      this.setState({
        isReadyToPrint: true,
        isQueryLaunched: false,
      })
      VALIDATE_STEP('Trip', true)
      VALIDATE_STEP('TripDetails', true)
      VALIDATE_STEP('DaysList', true)
      VALIDATE_STEP('TripExtension', true)
      VALIDATE_STEP('TourOperator', true)
      VALIDATE_STEP('Billing', !!tripBilling)
      LOADER(false)
    }

    // Delete *********************************************************************************
    // after delete refresh list
    if (!_.isEmpty(deletedTrip) && deletedTrip.success) {
      RESET_DELETE_TRIP()
      LOADER(false)
      this.setState({
        validMsg: 'This trip has been deleted.',
      })
      this.resetValidMsg()
      GET_LIST_CIRCUIT(token, filterStartDate, userId)
    }
  }

  componentDidUpdate() {
    this.getListCircuitFiltered()
  }


  async getAllTripInformation(content, details) {
    const { token } = this.state
    const {
      GET_TRIP_ALLDAYS,
      GET_TRIP_BILLING,
      GET_TRIP_SEASON_BY_ACCOMODATION,
      GET_TRIP_SEASON_BY_ACCOMODATION_OPT1,
      GET_TRIP_SEASON_BY_ACCOMODATION_OPT2,
      GET_TRIP_ALLDAYS_ACCOMODATION,
      GET_TRIP_PENSION,
      GET_TRIP_ACTIVITIES,
      tripBilling,
      tripAllDays,
      tripAllDaysAccomodations,
      tripSeasonByAccomodation,
      tripSeasonByAccomodationOpt1,
      tripSeasonByAccomodationOpt2,
      tripPension,
      tripActivities,
    } = this.props
    // get trip informations

    if (!_.isEmpty(content) && content[0].journee && content[0].hebergement && content[0].hebergement_opt && content[0].hebergement_opt1 && content[0].hebergement_opt2 && content[0].pension && content[0].type_chambre) {
      try {
        if (_.isEmpty(tripBilling)) await GET_TRIP_BILLING(token, details[0].id_devis)
        if (_.isEmpty(tripAllDays)) await GET_TRIP_ALLDAYS(content[0].journee, content[0].id_circuit)
        if (_.isEmpty(tripAllDaysAccomodations)) await GET_TRIP_ALLDAYS_ACCOMODATION(content[0].journee, content[0].hebergement)
        if (_.isEmpty(tripSeasonByAccomodation)) await GET_TRIP_SEASON_BY_ACCOMODATION(content[0].hebergement)
        if (_.isEmpty(tripSeasonByAccomodationOpt1)) await GET_TRIP_SEASON_BY_ACCOMODATION_OPT1(content[0].hebergement_opt1)
        if (_.isEmpty(tripSeasonByAccomodationOpt2)) await GET_TRIP_SEASON_BY_ACCOMODATION_OPT2(content[0].hebergement_opt2)
        if (_.isEmpty(tripPension)) await GET_TRIP_PENSION(content[0].pension)
        if (_.isEmpty(tripActivities)) await GET_TRIP_ACTIVITIES(token, content[0].id_circuit)
      } catch (error) {
        console.log('Route await error', error)
      }
    }
    return null
  }

  getListCircuitFiltered() {
    const {
      isFilterDateChanged,
      filterEndDate,
      filterStartDate,
    } = this.state

    const {
      listCircuit,
    } = this.props
    if (isFilterDateChanged) {
      this.setState({
        listCircuit: _.isEmpty(filterEndDate) ? (listCircuit.filter(c => c.date_creation >= filterStartDate)) : (listCircuit.filter(c => c.date_creation >= filterStartDate).filter(c => moment(c.date_creation).format('YYYY-MM') <= filterEndDate)),
        isFilterDateChanged: false,
      })
    }
  }

  // ************************************************************************

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  // Save row selected index
  // Save id trip
  // Unvalidate step
  selectTrip = (row) => {
    const {
      token,
      listCircuit,
    } = this.state
    const {
      ROW_TRIP_SELECT,
      ID_TRIP_SELECT,
      GET_TRIP_DETAILS,
      RESET_TRIP_INFORMATION,
      VALIDATE_STEP,
      LOADER,
      isLoading,
    } = this.props

    if (isLoading) return false

    // reset print autorisation and all trip informations
    // this.setState({ isReadyToPrint: false })
    this.setState({
      isReadyToPrint: false,
      tripSelected: listCircuit[row[0]],
    })
    RESET_TRIP_INFORMATION()
    // save row selected index
    ROW_TRIP_SELECT(row)

    // unvalidate step
    VALIDATE_STEP('Trip', false)
    VALIDATE_STEP('TripDetails', false)
    VALIDATE_STEP('DaysList', false)
    VALIDATE_STEP('Billing', false)
    // save id trip and specify is not new
    if (!_.isEmpty(row) && listCircuit[row[0]].id_devis !== 1) {
      ID_TRIP_SELECT(listCircuit[row[0]].id_devis, false, false)
      // ID_TRIP_SELECT(listCircuit[row[0].dataIndex].id_devis, false, false)
      // get all details
      GET_TRIP_DETAILS(token, listCircuit[row[0]].id_devis)
      // GET_TRIP_DETAILS(token, listCircuit[row[0].dataIndex].id_devis)
      LOADER(true)
    } else {
      ID_TRIP_SELECT(null, true, false)
      LOADER(false)
    }
    return true
  }

  // valid step for Stepper
  validateStep = () => {
    const { VALIDATE_STEP } = this.props
    VALIDATE_STEP('Trip', true)
  }

  // ******************************************
  // TABLE

  // Format table with user preferences saved in DB
  formatQuoteTable = (filterQuoteTable) => {
    const {
      nom_devis_name,
      nom_devis_label,
      nom_devis_filter,
      nom_devis_sort,
      nom_devis_sortOrder,
      nom_devis_filterList,
      nom_devis_display,
      demandeur_name,
      demandeur_label,
      demandeur_filter,
      demandeur_sort,
      demandeur_display,
      demandeur_filterList,
      date_creation_name,
      date_creation_label,
      date_creation_filter,
      date_creation_sort,
      date_creation_display,
      date_creation_filterList,
      langage_name,
      langage_label,
      langage_filter,
      langage_sort,
      langage_display,
      langage_filterList,
      categorie_name,
      categorie_label,
      categorie_filter,
      categorie_sort,
      categorie_display,
      categorie_filterList,
      version_name,
      version_label,
      version_filter,
      version_sort,
      version_display,
      version_filterList,
      status_name,
      status_label,
      status_filter,
      status_sort,
      status_display,
      status_filterList,
      advisor_name,
      advisor_label,
      advisor_filter,
      advisor_sort,
      advisor_display,
      advisor_filterList,
      operator_name,
      operator_label,
      operator_filter,
      operator_sort,
      operator_display,
      operator_filterList,
      description_name,
      description_label,
      description_filter,
      description_sort,
      description_display,
      id_name,
      id_label,
      id_filter,
      id_sort,
      id_display,
    } = filterQuoteTable

    const FondProgress = {
      backgroundColor: '#F5CBA7',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondTemplate = {
      backgroundColor: '#D2B4DE',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondConfirmed = {
      backgroundColor: '#EAFAF1',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondCompleted = {
      backgroundColor: '#FF33CE',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondOldVersion = {
      backgroundColor: '#D7BDE2',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondLost = {
      backgroundColor: '#F1948A',
      borderRadius: 5,
      textAlign: 'center',
    };

    const FondConfirmedBooked = {
      backgroundColor: '#D0ECE7',
      borderRadius: 5,
      textAlign: 'center',
    };
    return [
      {
        name: nom_devis_name,
        label: nom_devis_label,
        options: {
          filter: nom_devis_filter === 1,
          sort: nom_devis_sort === 1,
          sortOrder: nom_devis_sortOrder,
          display: nom_devis_display === 1 ? 'true' : 'false',
          filterList: nom_devis_filterList ? nom_devis_filterList.split(',') : [],
        },
      },
      {
        name: demandeur_name,
        label: demandeur_label,
        options: {
          filter: demandeur_filter === 1,
          sort: demandeur_sort === 1,
          display: demandeur_display === 1 ? 'true' : 'false',
          filterList: demandeur_filterList ? demandeur_filterList.split(',') : [],
        },
      },
      {
        name: date_creation_name,
        label: date_creation_label,
        options: {
          filter: date_creation_filter === 1,
          sort: date_creation_sort === 1,
          display: date_creation_display === 1 ? 'true' : 'false',
          filterList: date_creation_filterList ? date_creation_filterList.split(',') : [],
        },
      },
      {
        name: langage_name,
        label: langage_label,
        options: {
          filter: langage_filter === 1,
          sort: langage_sort === 1,
          display: langage_display === 1 ? 'true' : 'false',
          filterList: langage_filterList ? langage_filterList.split(',') : [],
        },
      },
      {
        name: categorie_name,
        label: categorie_label,
        options: {
          filter: categorie_filter === 1,
          sort: categorie_sort === 1,
          display: categorie_display === 1 ? 'true' : 'false',
          filterList: categorie_filterList ? categorie_filterList.split(',') : [],
        },
      },
      {
        name: version_name,
        label: version_label,
        options: {
          filter: version_filter === 1,
          sort: version_sort === 1,
          display: version_display === 1 ? 'true' : 'false',
          filterList: version_filterList ? version_filterList.split(',') : [],
        },
      },
      {
        name: status_name,
        label: status_label,
        options: {
          // eslint-disable-next-line consistent-return
          customBodyRender: (value) => {
            if (value === 'in progress') {
              return (
                <div style={FondProgress}>{value}</div>
              )
            } if (value === 'template') {
              return (
                <div style={FondTemplate}>{value}</div>
              )
            } if (value === 'confirmed') {
              return (
                <div style={FondConfirmed}>{value}</div>
              )
            } if (value === 'completed') {
              return (
                <div style={FondCompleted}>{value}</div>
              )
            } if (value === 'old version') {
              return (
                <div style={FondOldVersion}>{value}</div>
              )
            } if (value === 'confirmed-booked') {
              return (
                <div style={FondConfirmedBooked}>{value}</div>
              )
            } if (value === 'lost' || value === 'no answer') {
              return (
                <div style={FondLost}>{value}</div>
              )
            } return (
              <div>{value}</div>
            )
          },
          filter: status_filter === 1,
          sort: status_sort === 1,
          display: status_display === 1 ? 'true' : 'false',
          filterList: status_filterList ? status_filterList.split(',') : [],
        },
      },
      {
        name: advisor_name,
        label: advisor_label,
        options: {
          filter: advisor_filter === 1,
          sort: advisor_sort === 1,
          display: advisor_display === 1 ? 'true' : 'false',
          filterList: advisor_filterList ? advisor_filterList.split(',') : [],
        },
      },
      {
        name: operator_name,
        label: operator_label,
        options: {
          filter: operator_filter === 1,
          sort: operator_sort === 1,
          display: operator_display === 1 ? 'true' : 'false',
          filterList: operator_filterList ? operator_filterList.split(',') : [],
        },
      },
      {
        name: description_name,
        label: description_label,
        options: {
          filter: description_filter === 1,
          sort: description_sort === 1,
          display: description_display,
        },
      },
      {
        name: id_name,
        label: id_label,
        options: {
          filter: id_filter === 1,
          sort: id_sort === 1,
          display: id_display,
        },
      },
    ]
  }

  // Action on table tool bar
  deleteTrip = (rowsDeleted) => {
    const {
      listCircuit,
      token,
      userId,
    } = this.state
    const {
      userAccount,
      DELETE_TRIP,
      LOADER,
    } = this.props
    let isDeletable = true
    // users can not delete template or trip created by an other user
    if (userAccount.profil > 2) {
      isDeletable = listCircuit[rowsDeleted.data[0].dataIndex].status !== 'template' && listCircuit[rowsDeleted.data[0].dataIndex].id_conseiller === parseInt(userId, 10)
    }
    if (!isDeletable) {
      alert('You can not delete this quote !')
    } else {
      const idTripSelected = listCircuit[rowsDeleted.data[0].dataIndex].id_devis
      if (confirm('Do you really want to delete this trip ?')) {
        if (idTripSelected) {
          DELETE_TRIP(token, idTripSelected)
          // reset all informations and id trip in store
          this.selectTrip([])
          this.setState({
            filterStartDate: moment().subtract(120, 'd').format('YYYY-MM'),
          })
          LOADER(true)
        }
      }
    }
  }

  resetInformation = () => {
    const {
      ID_TRIP_SELECT,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
    } = this.props
    ID_TRIP_SELECT(null, true, false, false)
    ROW_TRIP_SELECT()
    RESET_TRIP_INFORMATION()
  }

  duplicateTrip = () => {
    this.handleOpen()
  }

  saveFilter = () => {
    const {
      columns,
      token,
    } = this.state
    const { PUT_FILTER_QUOTE_TABLE } = this.props
    const filterContent = {
      nom_devis_filterList: columns[0].options.filterList ? columns[0].options.filterList.join() : '',
      demandeur_filterList: columns[1].options.filterList ? columns[1].options.filterList.join() : '',
      date_creation_filterList: columns[2].options.filterList ? columns[2].options.filterList.join() : '',
      langage_filterList: columns[3].options.filterList ? columns[3].options.filterList.join() : '',
      categorie_filterList: columns[4].options.filterList ? columns[4].options.filterList.join() : '',
      version_filterList: columns[5].options.filterList ? columns[5].options.filterList.join() : '',
      status_filterList: columns[6].options.filterList ? columns[6].options.filterList.join() : '',
      advisor_filterList: columns[7].options.filterList ? columns[7].options.filterList.join() : '',
      operator_filterList: columns[8].options.filterList ? columns[8].options.filterList.join() : '',
    }

    PUT_FILTER_QUOTE_TABLE(token, filterContent)
  }

  // custom tool bar when a trip is selected
  displayToolBarSelect = (selectedRows) => {
    const { isReadyToPrint } = this.state
    const { history } = this.props
    return (
      <Toolbar>
        <Tooltip title="Duplicate">
          <IconButton
            aria-label="duplicate"
            onClick={() => this.duplicateTrip()}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        {/* display a loader as long as all informations haven't been received */}
        {isReadyToPrint
          ? (
            <Tooltip title="Print">
              <IconButton
                aria-label="print"
                onClick={() => history.push('print')}
              >
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )
          : (
            <Tooltip title="Loader">
              <IconButton aria-label="loading...">
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          )
        }
        <Tooltip title="Reset selection">
          <IconButton
            aria-label="reset"
            onClick={() => this.resetInformation()}
          >
            <RestorePageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => this.deleteTrip(selectedRows)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    )
  }

  // custom tool bar
  displayToolBar = () => (
    <Tooltip title="Save filters">
      <IconButton
        aria-label="save_filters"
        onClick={() => this.saveFilter()}
      >
        <BookmarkBorderIcon />
      </IconButton>
    </Tooltip>
  )

  // Action on table generale


  changeColumnView = (column, action) => {
    const {
      columns,
      token,
    } = this.state
    const { PUT_COLUMN_QUOTE_TABLE } = this.props
    const indexColumn = columns.findIndex(c => c.name === column)
    const actionOnColumn = action === 'add'
    this.setState({
      columns: _.map(columns, (o, i) => (i === indexColumn ? Object.assign(o, { options: { display: actionOnColumn, filter: actionOnColumn, sort: actionOnColumn } }) : o)),
    })
    const filterContent = {
      field1: `${column}_filter`,
      field2: `${column}_sort`,
      field3: `${column}_display`,
      newValue: actionOnColumn ? 1 : 0,
    }
    PUT_COLUMN_QUOTE_TABLE(token, filterContent)
  }

  changeFilterView = (column, filterList) => {
    const {
      columns,
    } = this.state
    const indexColumn = columns.findIndex(c => c.name === column)
    this.setState({
      columns: _.map(columns, (o, i) => (i === indexColumn ? Object.assign(o, { options: { ...o.options, filterList: filterList[i] } }) : o)),
    })
  }

  // **************************
  // Modal action

  // Duplicate quote
  getModifiedItem = (item) => {
    const {
      tripSelected,
      token,
    } = this.state
    const {
      tripDetails,
      PUT_TRIP_DETAILS,
      RESET_TRIP_INFORMATION,
      LOADER,
    } = this.props
    this.setState({
      tripSelected: {
        ...tripSelected,
        nom_devis: item.title,
      },
      isReadyToPrint: false,
    }, () => {
      const copyTripDetails = [{
        ...tripDetails[0],
        // id_devis: null,
        nom_devis: item.title,
      }]
      RESET_TRIP_INFORMATION()
      PUT_TRIP_DETAILS(token, copyTripDetails, 'copy')
      this.handleClose()
      LOADER(true)
    })
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  // manage filter date
  handleChange = (e) => {
    const {
      value, name,
    } = e.target

    const {
      token,
      filterStartDate,
      filterAdvisor,
      userId,
    } = this.state
    const {
      GET_LIST_CIRCUIT,
      LOADER,
    } = this.props

    this.setState({
      [name]: value,
      isFilterDateChanged: true,
    })

    if (name === 'filterStartDate') {
      GET_LIST_CIRCUIT(token, value || '2020-01', filterAdvisor || userId)
      this.selectTrip([])
      LOADER(true)
      this.setState({
        isDateChanged: true,
      })
    }

    if (name === 'filterAdvisor') {
      GET_LIST_CIRCUIT(token, filterStartDate || '2020-01', value || userId)
      this.selectTrip([])
      LOADER(true)
      this.setState({
        isDateChanged: true,
      })
    }
  }

  // **********************************************************************$
  render() {
    const {
      tripSelected,
      listCircuit,
      isModalOpen,
      columns,
      validMsg,
      circuitSearch,
      filterStartDate,
      filterAdvisor,
    } = this.state
    const {
      isLoading,
      rowTripSelected,
      collaborators,
    } = this.props
    const copyTripTitle = !_.isEmpty(tripSelected) ? `${tripSelected.nom_devis}-copy` : ''
    // *****************************************************
    // config options for mui-datatables
    const options = {
      filterType: 'multiselect',
      responsive: 'simple',
      pagination: true,
      rowsPerPageOptions: [10, 25, 50, 100],
      rowsSelected: rowTripSelected,
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: false,
      download: true,
      expandableRows: true,
      searchText: circuitSearch,
      onViewColumnsChange: (column, action) => this.changeColumnView(column, action),
      onFilterChange: (column, filterList) => this.changeFilterView(column, filterList),
      renderExpandableRow: (rowData, rowMeta) => <CircuitDescription rowData={rowData} rowMeta={rowMeta} />,
      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => this.selectTrip(rowsSelected),
      onRowsDelete: (rowsDeleted, data) => this.deleteTrip(rowsDeleted, data),
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
      onSearchClose: () => {
        this.setState({
          circuitSearch: null,
        })
      },
    }
    const classContainer = isLoading ? 'container2' : 'container'
    return (
      <div className={classContainer}>
        <Template>
          <div className="requestContainer">
            <Stepper />
            <div className="dividerStepper" />
            <div className={classContainer}>
              {/* <h2>TRIP LIST</h2> */}

              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <div>
                <CssGrid
                  container
                  spacing={1}
                >
                  <CssGrid item md="auto" xs={12}>
                    <h3 className="Question">Quote created on :</h3>
                  </CssGrid>
                  <CssGrid item md={3} xs={12}>
                    <Input
                      type="month"
                      name="filterStartDate"
                      value={filterStartDate || '2020-01'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => this.handleChange(e)}
                      label="date from"
                      margin="normal"
                      variant="outlined"
                    />

                  </CssGrid>
                  <CssGrid item md={3} xs={12}>
                    <Input
                      id="advisor"
                      label="advisor"
                      name="filterAdvisor"
                      select
                      margin="normal"
                      variant="outlined"
                      value={filterAdvisor}
                      className="largeWidth"
                      onChange={e => this.handleChange(e)}
                      // eslint-disable-next-line react/jsx-closing-bracket-location
            >
                      {collaborators && _.map(collaborators, (collab, k) => (
                        <MenuItem key={k} value={collab.id_conseiller}>{collab.lastname} {collab.firstname}</MenuItem>
                      ))}
                      <MenuItem key="everybody" value="all">all</MenuItem>
                      <menuItem />
                    </Input>
                  </CssGrid>

                </CssGrid>
              </div>
              {_.isEmpty(listCircuit) || _.some(columns, o => o.name === undefined)
                ? null
                : (
                  <MUIDataTable
                    className="table"
                    title="Select a trip"
                    data={listCircuit}
                    columns={columns}
                    options={options}
                  />
                )
              }
              <ActionButton
                label={_.isEmpty(rowTripSelected) ? 'Create a new trip' : 'Validate'}
                disabled={isLoading}
                target="tripDetails"
                onClick={() => this.validateStep()}
              />
            </div>
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the name for this new trip"
            modalContent="title"
            type="trip"
            inputValue={copyTripTitle}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  idTripSelected: state.api.idTripSelected,
  rowTripSelected: state.api.rowTripSelected,
  deletedTrip: state.api.deletedTrip,
  isLoading: state.api.isLoading,
  tripDetails: state.api.tripDetails,
  tripContent: state.api.tripContent,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  tripSeasonByAccomodation: state.api.tripSeasonByAccomodation,
  tripPension: state.api.tripPension,
  tripExtension: state.tripExtensionReducer.tripExtension,
  tripPrice: state.billingReducer.tripPrice,
  additionalColumnPrice: state.billingReducer.additionalColumnPrice,
  tripBilling: state.billingReducer.tripBilling,
  userAccount: state.userAccountReducer.userAccount,
  putTripDetails: state.api.putTripDetails,
  filterQuoteTable: state.tripReducer.filterQuoteTable,
  putFilterQuoteTable: state.tripReducer.putFilterQuoteTable,
  collaborators: state.collaboratorsReducer.collaborators,
})

const mapDispatchToProps = dispatch => ({
  RESET_APP: () => dispatch({
    type: 'RESET_APP',
  }),
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_FILTER_QUOTE_TABLE: token => dispatch({
    type: 'GET_FILTER_QUOTE_TABLE',
    token,
  }),
  PUT_COLUMN_QUOTE_TABLE: (token, filterContent) => dispatch({
    type: 'PUT_COLUMN_QUOTE_TABLE',
    token,
    filterContent,
  }),
  RESET_FILTER_QUOTE_RESULTS: () => dispatch({
    type: 'RESET_FILTER_QUOTE_RESULTS',
  }),
  PUT_FILTER_QUOTE_TABLE: (token, filterContent) => dispatch({
    type: 'PUT_FILTER_QUOTE_TABLE',
    token,
    filterContent,
  }),

  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
  GET_INFORMATIONS_CONTENT: userId => dispatch({
    type: 'GET_INFORMATIONS_CONTENT',
    userId,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    filterAdvisor,
  }),
  GET_REQUEST: token => dispatch({
    type: 'GET_REQUEST',
    token,
  }),
  DELETE_TRIP: (token, idTripSelected) => dispatch({
    type: 'DELETE_TRIP',
    token,
    idTripSelected,
  }),
  RESET_DELETE_TRIP: () => dispatch({
    type: 'RESET_DELETE_TRIP',
  }),
  GET_STATUS_LIST: () => dispatch({
    type: 'GET_STATUS_LIST',
  }),
  GET_PENSION_LIST: userId => dispatch({
    type: 'GET_PENSION_LIST',
    userId,
  }),
  GET_DAYS_LIST: userId => dispatch({
    type: 'GET_DAYS_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  GET_LOCATION_GRAND_LIST: userId => dispatch({
    type: 'GET_LOCATION_GRAND_LIST',
    userId,
  }),
  GET_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_LIST',
    userId,
  }),
  GET_ROOM_LIST: userId => dispatch({
    type: 'GET_ROOM_LIST',
    userId,
  }),
  GET_ROOM_BY_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ROOM_BY_ACCOMODATION_LIST',
    userId,
  }),
  GET_EXTENSION_LIST: userId => dispatch({
    type: 'GET_EXTENSION_LIST',
    userId,
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  GET_SEASON_BY_ACTIVITY_ALL: userId => dispatch({
    type: 'GET_SEASON_BY_ACTIVITY_ALL',
    userId,
  }),
  GET_PROFIL_USER_LIST: () => dispatch({
    type: 'GET_PROFIL_USER_LIST',
  }),
  GET_CUSTOMER_LIST: userId => dispatch({
    type: 'GET_CUSTOMER_LIST',
    userId,
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  ID_TRIP_SELECT: (idTripSelected, isNewTrip, isDetailModified, isDuplicatedTrip) => dispatch({
    type: 'ID_TRIP_SELECT',
    idTripSelected,
    isNewTrip,
    isDetailModified,
    isDuplicatedTrip,
  }),
  GET_TRIP_DETAILS: (token, idTripSelected) => dispatch({
    type: 'GET_TRIP_DETAILS',
    token,
    idTripSelected,
  }),
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  GET_TRIP_ALLDAYS: (tripDaysList, idCircuit) => dispatch({
    type: 'GET_TRIP_ALLDAYS',
    tripDaysList,
    idCircuit,
  }),
  GET_TRIP_ALLDAYS_ACCOMODATION: (tripDaysList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ALLDAYS_ACCOMODATION',
    tripDaysList,
    tripAccoList,
  }),
  GET_TRIP_SEASON_BY_ACCOMODATION: tripAccomodationList => dispatch({
    type: 'GET_TRIP_SEASON_BY_ACCOMODATION',
    tripAccomodationList,
  }),
  GET_TRIP_SEASON_BY_ACCOMODATION_OPT1: tripAccomodationList => dispatch({
    type: 'GET_TRIP_SEASON_BY_ACCOMODATION_OPT1',
    tripAccomodationList,
  }),
  GET_TRIP_SEASON_BY_ACCOMODATION_OPT2: tripAccomodationList => dispatch({
    type: 'GET_TRIP_SEASON_BY_ACCOMODATION_OPT2',
    tripAccomodationList,
  }),
  GET_TRIP_PENSION: tripPensionList => dispatch({
    type: 'GET_TRIP_PENSION',
    tripPensionList,
  }),
  GET_TRIP_BILLING: (token, idDevis) => dispatch({
    type: 'GET_TRIP_BILLING',
    token,
    idDevis,
  }),
  GET_TRAVELER_BY_TRIP: token => dispatch({
    type: 'GET_TRAVELER_BY_TRIP',
    token,
  }),
  GET_COLLABORATORS: token => dispatch({
    type: 'GET_COLLABORATORS',
    token,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
  ACTIVE_STEP: activeStep => dispatch({
    type: 'ACTIVE_STEP',
    activeStep,
  }),
  VALIDATE_STEP: (page, value, progression) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
    progression,
  }),
  PUT_TRIP_DETAILS: (token, tripDetails, action) => dispatch({
    type: 'PUT_TRIP_DETAILS',
    token,
    tripDetails,
    action,
  }),
  GET_TRIP_ACTIVITIES: (token, idCircuit) => dispatch({
    type: 'GET_TRIP_ACTIVITIES',
    token,
    idCircuit,
  }),
  RESET_TRIP_DETAILS: () => dispatch({
    type: 'RESET_TRIP_DETAILS',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Trip))
