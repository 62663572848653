import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getInformationsContentResults,
  putInformationsContentResults,
  // deleteInformationsContentResults,
} from './actions'

function* getInformationsContentAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_informations_content, userId)
  yield put(getInformationsContentResults(data))
}

function* putInformationsContentAsync(params) {
  const {
    token,
    informationsContent,
    action,
  } = params
  const data = yield call(api.put_informations_content, token, informationsContent, action)
  yield put(putInformationsContentResults(data))
}

// function* deleteInformationsContentAsync(params) {
//   const {
//     token,
//     customerId,
//   } = params
//   const data = yield call(api.delete_customer_content, token, customerId)
//   yield put(deleteInformationsContentResults(data))
// }

function* fetchResult() {
  yield takeEvery('GET_INFORMATIONS_CONTENT', getInformationsContentAsync)
  yield takeEvery('PUT_INFORMATIONS_CONTENT', putInformationsContentAsync)
//   yield takeEvery('DELETE_INFORMATIONS_CONTENT', deleteInformationsContentAsync)
}

export default fetchResult
