import {
  GET_ALL_TRAVELER_RESULTS,
  GET_TRAVELER_BY_TRIP_RESULTS,
  GET_TRAVELER_LIST_RESULTS,
  PUT_TRAVELER_CONTENT_RESULTS,
  DELETE_TRAVELER_CONTENT_RESULTS,
  DELETE_TRAVELERS_RESULTS,
  RESET_TRAVELER_CONTENT,
  RESET_TRAVELER_LIST,
} from './actions'

const initialState = {
  travelerList: [],
  numberTravelerByTrip: [],
  travelerListByTrip: [],
  putTravelerContent: {},
  deleteTravelerContent: {},
  deleteTravelers: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TRAVELER_RESULTS:
      return {
        ...state,
        travelerList: action.travelerList,
      }
    case GET_TRAVELER_BY_TRIP_RESULTS:
      return {
        ...state,
        numberTravelerByTrip: action.numberTravelerByTrip,
      }
    case GET_TRAVELER_LIST_RESULTS:
      return {
        ...state,
        travelerListByTrip: action.travelerListByTrip,
      }
    case PUT_TRAVELER_CONTENT_RESULTS:
      return {
        ...state,
        putTravelerContent: action.putTravelerContent,
      }
    case DELETE_TRAVELER_CONTENT_RESULTS:
      return {
        ...state,
        deleteTravelerContent: action.deleteTravelerContent,
      }
    case DELETE_TRAVELERS_RESULTS:
      return {
        ...state,
        deleteTravelers: action.deleteTravelers,
      }
    case RESET_TRAVELER_CONTENT:
      return {
        ...state,
        putTravelerContent: {},
        deleteTravelerContent: {},
        deleteTravelers: {},
      }
    case RESET_TRAVELER_LIST:
      return {
        ...state,
        travelerListByTrip: [],
      }
    default:
      return state
  }
}
