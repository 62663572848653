import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_filter_quote_table(token) {
    return axios.get(`${apiUrl}/api/filter/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_column_quote_table(token, filterContent) {
    return axios.put(`${apiUrl}/api/filter/update/column`, { filterContent }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_filter_quote_table(token, filterContent) {
    return axios.put(`${apiUrl}/api/filter/update/filter`, { filterContent }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
