
import React, { PureComponent } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import _ from 'lodash'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class MapDirectionsRenderer extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    locationList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    locationList: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      heightMap: 950,
    }
  }

  handleOSizeChange = (event) => {
    this.setState({
      heightMap: event.currentTarget.value,
    })
  }


  render() {
    const {
      tripDetails,
      locationList,
    } = this.props
    const {
      heightMap,
    } = this.state

    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    const numero = tabMap.length === 1 ? 0 : Math.round(tabMap.length / 2)

    return (

      <div id="map" className="map-container">
        <GoogleMap
          // required
          id="direction"
          // required
          mapContainerStyle={{
            height: `${heightMap}px`,
            width: '700px',
            overflow: true,
          }}
          // required
          zoom={6}
          // required
          center={{
            lat: tabMap[numero][0].latitude,
            lng: tabMap[numero][0].longitude,
          }}
        >

          {tabMap.map((mark, index) => {
            const position = { lat: mark[0].latitude, lng: mark[0].longitude };
            return (
              <Marker key={mark} position={position} title={`${mark[0].lieu}`} label={`${index + 1}`} />
            );
          })}

        </GoogleMap>
        <input className="adjustSize" name="size" min="200" max="1600" type="range" defaultValue="1200" onChange={this.handleOSizeChange} />

      </div>


    )
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
})
export default withRouter(connect(mapStateToProps)(MapDirectionsRenderer))
