import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import duyenWhite from '../../assets/images/duyen-white.png'
import duyenWhite2x from '../../assets/images/duyen-white@2x.png'
import duyenWhite3x from '../../assets/images/duyen-white@3x.png'
import profil from '../../assets/images/profil.png'
import profil2x from '../../assets/images/profil@2x.png'
import profil3x from '../../assets/images/profil@3x.png'

class Header extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
    RESET_APP: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
  }

  static defaultProps = {
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.goToMyPage = this.goToMyPage.bind(this)
    this.logOut = this.logOut.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      anchorEl: null,
    };
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      userAccount,
      GET_USER_ACCOUNT,
    } = this.props
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  }

  goToMyPage = (page) => {
    const { history } = this.props
    this.handleClose()
    history.push(page)
  }

  logOut = () => {
    const {
      history,
      RESET_APP,
    } = this.props
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('isLogged')
    this.handleClose()
    RESET_APP()
    history.push('/')
  }

  goTo = (page) => {
    alert(page)
  }

  render() {
    const { anchorEl } = this.state
    const { userAccount } = this.props
    return (
      <div className="header">
        <img className="logo" src={duyenWhite} srcSet={`${duyenWhite2x} 2x, ${duyenWhite3x} 3x`} alt="" />
        <div className="user">
          <p className="userName">Bonjour {userAccount.firstname}</p>
          <button type="button" className="userBtnAction">
            <img
              src={profil}
              srcSet={`${profil2x} 2x, ${profil3x} 3x`}
              alt=""
              onClick={this.handleClick}
            />
          </button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={() => this.goToMyPage('myAccount')}>My account</MenuItem>
            {/* {userAccount && userAccount.profil < 2
              ? <MenuItem onClick={() => this.goToMyPage('company')}>My company</MenuItem>
              : null
            } */}
            <MenuItem onClick={() => this.goToMyPage('company')}>My company</MenuItem>
            <MenuItem onClick={this.logOut}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  RESET_APP: () => dispatch({
    type: 'RESET_APP',
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
