import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getPictureResults,
  deletePictureResults,
} from './actions'

function* getPictureAsync(params) {
  const {
    userId,
    company,
  } = params
  const data = yield call(api.get_picture, userId, company)
  yield put(getPictureResults(data))
}

function* deletePictureAsync(params) {
  const {
    token,
    Key,
  } = params
  const data = yield call(api.delete_picture, token, Key)
  yield put(deletePictureResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_PICTURE', getPictureAsync)
  yield takeEvery('DELETE_PICTURE', deletePictureAsync)
}

export default fetchResult
