import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment'
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  Loader,
  AddButton,
} from '../../../components'
import accomodationsColumns from '../../../components/utils/table/accomodationColumns'

const {
  Edit,
} = tableIcons

class AccomodationTable extends PureComponent {
  static propTypes = {
    GET_ACCOMODATION_LIST: PropTypes.func.isRequired,
    DELETE_ACCOMODATION_CONTENT: PropTypes.func.isRequired,
    RESET_ACCOMODATION_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    accomodationList: PropTypes.array,
    deleteAccomodationContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    accomodationList: [],
    deleteAccomodationContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateAccomodationList = this.updateAccomodationList.bind(this)
    this.displayTableAccomodation = this.displayTableAccomodation.bind(this)
    this.deleteAccomodation = this.deleteAccomodation.bind(this)
    this.getIdAccomodation = this.getIdAccomodation.bind(this)
    this.goToAccomodation = this.goToAccomodation.bind(this)
    this.editAccomodation = this.editAccomodation.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      accomodationList: [],
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      accomodationList,
      GET_ACCOMODATION_LIST,
      LOADER,
    } = this.props
    const { userId } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(accomodationList)) {
      GET_ACCOMODATION_LIST(userId)
      LOADER(true)
    } else {
      this.setState({
        accomodationList,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.state
    const {
      accomodationList,
      deleteAccomodationContent,
      RESET_ACCOMODATION_CONTENT,
      GET_ACCOMODATION_LIST,
      LOADER,
    } = this.props
    if (deleteAccomodationContent && deleteAccomodationContent.success) {
      RESET_ACCOMODATION_CONTENT()
      GET_ACCOMODATION_LIST(userId)
      this.reload(deleteAccomodationContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (deleteAccomodationContent && !deleteAccomodationContent.success) {
      RESET_ACCOMODATION_CONTENT()
      this.updateAccomodationList()
      this.reload(deleteAccomodationContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (!_.isEqual(accomodationList, prevProps.accomodationList)) {
      LOADER(false)
      this.updateAccomodationList()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  displayTableAccomodation = (accomodationList) => {
    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'Accomodations_list.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      // onRowsDelete: (rowsDeleted, data) => this.deleteAccomodation(rowsDeleted, data),
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }
    return (
      <MUIDataTable
        className="table"
        title="Accomodations"
        data={accomodationList}
        columns={accomodationsColumns}
        options={options}
      />
    )
  }

  updateAccomodationList = () => {
    const { accomodationList } = this.props
    this.setState({
      accomodationList,
    })
  }

  getIdAccomodation = (rowsSelected) => {
    const {
      accomodationList,
    } = this.props
    return accomodationList[rowsSelected.data[0].dataIndex].id_hebergement
  }

  deleteAccomodation = (rowsDeleted) => {
    const { token } = this.state
    const {
      accomodationList,
      DELETE_ACCOMODATION_CONTENT,
      LOADER,
    } = this.props

    if (confirm('Do you really want to delete accomodation ?')) {
      LOADER(true)
      const idAccomodation = this.getIdAccomodation(rowsDeleted)
      DELETE_ACCOMODATION_CONTENT(token, idAccomodation)
      // update state without travelers deleted
      const newAccomodationList = _.cloneDeep(accomodationList)
      newAccomodationList.splice(idAccomodation, 1)
      this.setState({
        accomodationList: newAccomodationList,
      })
    }
  }

  goToAccomodation = (data = {}) => {
    const { history } = this.props
    history.push('accomodation', { ...data })
  }

  editAccomodation = (rowsSelected) => {
    const idAccomodation = this.getIdAccomodation(rowsSelected)
    this.goToAccomodation({
      source: 'accomodation-table',
      idAccomodation,
    })
  }

  // ************************* Display *************************************
  // custom tool bar when a Accomodation is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editAccomodation(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteAccomodation(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => (
    <AddButton
      title="Add new Accomodation"
      goTo={() => this.goToAccomodation()}
    />
  )

  render() {
    const {
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      accomodationList,
    } = this.state
    const { isLoading } = this.props


    const accomodationList2 = accomodationList.map((obj) => {
      const isExpired = moment(obj.date_expiration_price).isBefore(moment());
      return { ...obj, date_expire: isExpired };
    });

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="resourcesContainer">
              <h2>ACCOMODATIONS LIST</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              {this.displayTableAccomodation(accomodationList2)}
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  accomodationList: state.api.accomodationList.length > 1 ? _.filter(state.api.accomodationList, d => d.id_hebergement !== 1) : state.api.accomodationList,
  deleteAccomodationContent: state.api.deleteAccomodationContent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_LIST',
    userId,
  }),
  DELETE_ACCOMODATION_CONTENT: (token, accomodationId) => dispatch({
    type: 'DELETE_ACCOMODATION_CONTENT',
    token,
    accomodationId,
  }),
  RESET_ACCOMODATION_CONTENT: () => dispatch({
    type: 'RESET_ACCOMODATION_CONTENT',
  }),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccomodationTable))
