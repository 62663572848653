import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_all_traveler(token) {
    return axios.get(`${apiUrl}/api/travelers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_traveler_by_trip(token) {
    return axios.get(`${apiUrl}/api/travelers/count-traveler`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_traveler_list(token, idTrip) {
    return axios.get(`${apiUrl}/api/travelers/${idTrip}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_traveler_content(token, traveler, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/travelers/update`, { traveler }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/travelers/create`, { traveler }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_traveler_content(token, idTrip, idTraveler) {
    return axios.delete(`${apiUrl}/api/travelers/delete/${idTrip}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        idTraveler,
      },
    })
      .then(res => res.data)
  },
  delete_travelers(token, idTravelersArray) {
    return axios.delete(`${apiUrl}/api/travelers/delete/travelers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        idTravelersArray,
      },
    })
      .then(res => res.data)
  },
}
