import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getCustomerListResults,
  putCustomerContentResults,
  deleteCustomerContentResults,
} from './actions'

function* getCustomerListAsync() {
  const data = yield call(api.get_customer_list)
  yield put(getCustomerListResults(data))
}

function* putCustomerContentAsync(params) {
  const {
    token,
    customerContent,
    action,
  } = params
  const data = yield call(api.put_customer_content, token, customerContent, action)
  yield put(putCustomerContentResults(data))
}

function* deleteCustomerContentAsync(params) {
  const {
    token,
    customerId,
  } = params
  const data = yield call(api.delete_customer_content, token, customerId)
  yield put(deleteCustomerContentResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_CUSTOMER_LIST', getCustomerListAsync)
  yield takeEvery('PUT_CUSTOMER_CONTENT', putCustomerContentAsync)
  yield takeEvery('DELETE_CUSTOMER_CONTENT', deleteCustomerContentAsync)
}

export default fetchResult
