import axios from 'axios'
import stage from '../../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_picture(userId, company) {
    return axios.get(`${apiUrl}/api/management/pictures/${userId}/${company}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  delete_picture(token, Key) {
    return axios.put(`${apiUrl}/api/management/pictures/delete`, { Key }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
