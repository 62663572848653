import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Geocode from 'react-geocode';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  // FabDelete,
  Uploader,
  ModalComponent,
  TextEditor,
} from '../../../components'
import stage from '../../../config'
import { CssGrid } from '../../../configCSS'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'

const savedImgUrl = stage.imgStorage
const locationFormatRegExp = new RegExp(' ', 'g')

class Extension extends PureComponent {
  static propTypes = {
    GET_EXTENSION_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    PUT_EXTENSION_CONTENT: PropTypes.func.isRequired,
    DELETE_EXTENSION_CONTENT: PropTypes.func.isRequired,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_EXTENSION_CONTENT: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    putExtensionContent: PropTypes.object,
    deleteExtensionContent: PropTypes.object,
    putLocationContent: PropTypes.object,
    locationList: PropTypes.array,
    history: PropTypes.object.isRequired,
    extensionList: PropTypes.array,
    companyContent: PropTypes.object,
  }

  static defaultProps = {
    locationList: [],
    extensionList: [],
    putExtensionContent: {},
    putLocationContent: {},
    deleteExtensionContent: {},
    companyContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.findIdLocation = this.findIdLocation.bind(this)
    this.displayAutocompleteExtension = this.displayAutocompleteExtension.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayExtensionInputs = this.displayExtensionInputs.bind(this)
    this.onChangeExtension = this.onChangeExtension.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.validate = this.validate.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItemTitle = this.modifyItemTitle.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)

    this.state = {
      extensionList: [],
      restrictedList: null,
      extensionContent: '',
      isValidated: true,
      isNewExtension: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      newImageArray: [],
      newURLArray: [],
      isModalOpen: false,
      actionLabel: 'Create',
      btnActionDisabled: false,
      indexInTripExtension: null,
      tripExtension: null,
      goBack: null,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      extensionList,
      companyContent,
      locationList,
      GET_EXTENSION_LIST,
      GET_LOCATION_LIST,
      GET_COMPANY_CONTENT,
      RESET_EXTENSION_CONTENT,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    RESET_EXTENSION_CONTENT()
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'extension') {
        const {
          state,
          currentImg,
          pictureSelected,
        } = history.location.state
        this.setState({
          extensionContent: {
            ...state.item,
            image: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          isNewExtension: state.isNewExtension,
          actionLabel: state.isNewExtension ? 'Create' : 'Modify',
          indexInTripExtension: state.indexInTripExtension,
          tripExtension: state.tripExtension,
          goBack: state.goBack,
        })
      }
      if (currentPage === 'tripExtension') {
        const {
          newExtension,
          index,
          tripExtension,
        } = history.location.state
        const {
          extension,
          location,
          id_location,
        } = newExtension
        this.setState({
          extensionContent: {
            extension,
            location,
            id_location: id_location || this.findIdLocation(location),
          },
          isNewExtension: true,
          actionLabel: 'Create',
          indexInTripExtension: index,
          tripExtension,
          goBack: currentPage,
        })
      }
    }
    // check extension list
    if (!_.isEmpty(extensionList)) {
      this.setState({
        extensionList: _.sortBy(extensionList, o => o.extension),
      })
    } else {
      GET_EXTENSION_LIST(userId)
    }
    if (_.isEmpty(companyContent)) {
      GET_COMPANY_CONTENT(userId)
    }
    if (_.isEmpty(locationList)) {
      GET_LOCATION_LIST(userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      userId,
      extensionList: extensionListOld,
      extensionContent,
      btnActionDisabled,
      goBack,
      indexInTripExtension,
      tripExtension,
    } = this.state
    const { history } = this.props
    const {
      extensionList,
      putLocationContent,
      putExtensionContent,
      deleteExtensionContent,
      RESET_LOCATION_CONTENT,
      GET_LOCATION_LIST,
      RESET_EXTENSION_CONTENT,
    } = nextProps
    // location saved in DB -> get back updated list
    if (putLocationContent && putLocationContent.success) {
      RESET_LOCATION_CONTENT()
      GET_LOCATION_LIST(userId)
      this.setState({
        extensionContent: {
          ...extensionContent,
          id_location: putLocationContent.id,
        },
        btnActionDisabled: !btnActionDisabled,
        validMsg: 'Location was successfully recorded !',
        isValidated: false,
      })
      this.resetValidMsg(2000)
    }
    // Extension saved in DB -> get back updated list
    if (putExtensionContent && putExtensionContent.success) {
      this.setState({
        extensionContent: {
          ...extensionContent,
          id_extension: putExtensionContent.id,
        },
        validMsg: 'Extension was successfully recorded !',
        isValidated: true,
      })
      this.reload()
      this.resetValidMsg(2000)

      // redirect to tripExtension
      if (goBack) {
        tripExtension[indexInTripExtension] = { id_extension: putExtensionContent.id, id_lieu: extensionContent.id_location, ...extensionContent }
        setTimeout(() => {
          history.push(goBack, { currentPage: 'tripExtension', tripExtension })
        }, 2500)
      }
      // }
    }
    // extension delete in DB -> get back updated list
    if (deleteExtensionContent && deleteExtensionContent.success) {
      this.reload()
      this.setState({
        extensionContent: {},
        isValidated: true,
        validMsg: 'Extension was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // extension can not be delete in DB -> it is used yet in route
    if (deleteExtensionContent && !deleteExtensionContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteExtensionContent.message,
      })
      RESET_EXTENSION_CONTENT()
    }
    if (extensionList !== extensionListOld) {
      this.setState({
        extensionList: _.sortBy(extensionList, o => o.extension),
      })
    }
  }

  resetValidMsg = (t = 3000) => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, t)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_EXTENSION_CONTENT,
      GET_EXTENSION_LIST,
    } = this.props
    RESET_EXTENSION_CONTENT()
    GET_EXTENSION_LIST(userId)
    this.setState({ validateError: false })
  }

  findIdLocation = (location) => {
    const { locationList } = this.props
    return locationList.filter(l => l.lieu === location)[0].id_lieu
  }

  // ********************************
  // On change extension
  onChangeExtension = (event, newValue) => {
    const {
      isValidated,
      extensionList,
    } = this.state
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = extensionList.filter(d => d.extension === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          extensionContent: {
            id_extension: null,
            extension: newValue,
            image: '',
          },
          actionLabel: 'Create',
          isNewExtension: true,
          newImageArray: [],
          newURLArray: [],
          isValidated: false,
          restrictedList: null,
        });
      } else {
        this.setState({
          extensionContent: valueExist[0],
          actionLabel: 'Modify',
          newImageArray: [],
          newURLArray: [],
          isNewExtension: false,
          isValidated: true,
          restrictedList: null,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        extensionContent: {
          id_extension: null,
          extension: newValue.inputValue,
          image: '',
        },
        newImageArray: [],
        newURLArray: [],
        actionLabel: 'Create',
        isNewExtension: true,
        isValidated: false,
        restrictedList: null,
      });
    } else {
      this.setState({
        extensionContent: newValue,
        actionLabel: 'Modify',
        newImageArray: [],
        newURLArray: [],
        isNewExtension: false,
        isValidated: true,
        restrictedList: null,
      });
    }
    return null
  }

  filterOptionsExtension = (options, params) => {
    const { extensionList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = extensionList.filter(d => d.extension === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        extension: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelExtension = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.extension) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.extension;
  }

  // On change location
  onChangeLocation = (event, newValue) => {
    const { extensionContent } = this.state
    const {
      locationList,
      extensionList,
    } = this.props
    const isExtensionEmpty = extensionContent ? !extensionContent.extension : true
    // on press Enter
    if (typeof newValue === 'string') {
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const locationObject = locationList.filter(l => l.location === valueClean)[0]
      const idLocation = locationObject && locationObject.id_lieu ? locationObject.id_lieu : null

      if (!idLocation) this.createNewLocation({ lieu: valueClean })

      if (_.isEmpty(locationObject)) {
        this.setState({
          extensionContent: {
            ...extensionContent,
            id_location: idLocation,
            location: valueClean,
          },
          isValidated: isExtensionEmpty,
          isNewExtension: false,
          restrictedList: isExtensionEmpty ? extensionList.filter(e => e.location === valueClean) : null,
        });
      } else {
        this.setState({
          extensionContent: {
            ...extensionContent,
            id_location: locationObject.id_lieu,
            location: locationObject.lieu,
          },
          restrictedList: isExtensionEmpty ? _.filter(extensionList, e => e.location === locationObject.lieu) : null,
          isValidated: isExtensionEmpty,
        })
      }
    } else if (!newValue) {
      // clear input location
      this.setState({
        extensionContent: {
          ...extensionContent,
          id_location: null,
          location: '',
        },
        restrictedList: null,
        isValidated: isExtensionEmpty,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        extensionContent: {
          ...extensionContent,
          id_location: null,
          location: newValue.inputValue,
        },
        restrictedList: isExtensionEmpty ? extensionList.filter(e => e.location === newValue.inputValue) : null,
        isValidated: isExtensionEmpty,
      });
      // save new location
      this.createNewLocation({ lieu: newValue.inputValue })
    } else {
      this.setState({
        extensionContent: {
          ...extensionContent,
          id_location: newValue.id_lieu,
          location: newValue.lieu,
        },
        restrictedList: isExtensionEmpty ? _.filter(extensionList, e => e.location === newValue.lieu) : null,
        isValidated: isExtensionEmpty,
      })
    }
  }

  filterOptionsLocation = (options, params) => {
    const { locationList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(l => l.lieu === valueClean)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }

  getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return ''
    }
    // Regular option
    return option.location;
  }

  // ************************************************
  // On change Editor
  handleEditorChange = (field, content) => {
    const { extensionContent } = this.state
    if (extensionContent && content !== extensionContent.description) {
      this.setState({
        extensionContent: {
          ...extensionContent,
          [field]: content,
        },
        validateError: false,
        errorMsg: null,
        isValidated: false,
      });
    }
  }

  // save input change
  handleChange = field => (event) => {
    const { extensionContent } = this.state
    this.setState({
      extensionContent: {
        ...extensionContent,
        [field]: event.target.value,
      },
      isValidated: false,
    })
  }
  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = () => {
    const { companyContent } = this.props
    const { company } = companyContent
    const {
      extensionContent,
      newURLArray,
      newImageArray,
    } = this.state
    const image = extensionContent && extensionContent.image ? extensionContent.image : null
    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${savedImgUrl}/${company}/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
      validateError: false,
      errorMsg: null,
      isValidated: false,
    });
  }

  deleteImage = (urlArray, imageArray) => {
    const { extensionContent } = this.state
    this.setState({
      extensionContent: {
        ...extensionContent,
        image: imageArray.join(),
      },
      newImageArray: imageArray,
      newURLArray: urlArray,
      isValidated: false,
    })
  }

  redirectToGallery = () => {
    const {
      extensionContent,
      isNewExtension,
      actionLabel,
      indexInTripExtension,
      tripExtension,
      goBack,
    } = this.state
    const {
      history,
    } = this.props
    this.setState({
      isValidated: true,
    }, () => {
      history.push('gallery', {
        currentPage: 'extension',
        isSelectionAction: true,
        nbMaxImg: 2,
        currentImg: extensionContent.image ? extensionContent.image.split(',') : [],
        state: {
          item: extensionContent,
          isNewExtension,
          actionLabel,
          indexInTripExtension,
          tripExtension,
          goBack,
        },
      })
    })
  }

  // ***************************
  // Display input

  displayAutocompleteExtension = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item sm={10} xs={12}>
          <Autocomplete
            id={`${field}-field`}
            value={content}
            onChange={this.onChangeExtension}
            filterOptions={this.filterOptionsExtension}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelExtension}
            renderOption={option => option[field]}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Modify title" aria-label="modify" onClick={this.modifyItemTitle}>
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Delete" aria-label="delete" onClick={this.deleteItem}>
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  displayAutocompleteLocation = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeLocation}
        filterOptions={this.filterOptionsLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelLocation}
        renderOption={option => option.lieu}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

  // Editor
  displayTextEditor = () => {
    const { extensionContent } = this.state
    const extensionObject = typeof extensionContent === 'string' ? {} : extensionContent
    const descriptionValue = extensionContent && extensionContent.description ? extensionContent.description : ''
    return (
      <TextEditor
        object={extensionObject}
        value={descriptionValue}
        handleEditorChange={content => this.handleEditorChange('description', content)}
      />
    )
  }

  // return all other input
  displayExtensionInputs = () => {
    const {
      extensionContent,
    } = this.state
    const { locationList } = this.props
    return (
      <div>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={9} xs={12}>
            {/* extension description */}
            <div className="editorStyle">
              <p className="editorTitle background-white">Descritopn</p>
              {this.displayTextEditor('description')}
            </div>
            {this.displayAutocompleteLocation('location', extensionContent, locationList)}
            <Input
              id="adresse"
              label="Adress"
              value={!_.isEmpty(extensionContent) && extensionContent.adresse ? extensionContent.adresse : ''}
              onChange={this.handleChange('adresse')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
          </CssGrid>
          {/* Uploader */}
          <CssGrid item md={3} xs={12} className="logo-company-div">
            <p className="logo-label">picture</p>
            <Uploader
              imageDataArray={this.getImageUrl()}
              saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
              redirectToGallery={() => this.redirectToGallery()}
              deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
              nbMaxImg={2}
              allowDrop
            />
          </CssGrid>
        </CssGrid>
      </div>
    )
  }

  // *******************************
  // Item actions *******
  // Delete
  deleteItem = () => {
    const {
      token,
      extensionContent,
    } = this.state
    const extensionId = extensionContent.id_extension
    const { DELETE_EXTENSION_CONTENT } = this.props

    if (confirm('Do you really want to delete this extension ?')) {
      if (extensionId) {
        DELETE_EXTENSION_CONTENT(token, extensionId)
        // this.setState({})
      } else {
        this.setState({
          validateError: true,
          errorMsg: extensionContent.extension ? 'This extension do not exist.' : 'Please, select an extension.',
        })
      }
    }
  }

  // Modify title
  modifyItemTitle = () => {
    this.handleOpen()
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      extensionContent,
      isNewExtension,
      newImageArray,
      token,
    } = this.state
    const {
      PUT_EXTENSION_CONTENT,
    } = this.props
    const {
      extension,
      description,
      id_location,
      image,
      adresse,
    } = extensionContent
    this.setState({
      isValidated: true,
    })
    // Check all required inputs
    if (!extensionContent.extension || !extensionContent.location) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    const newExtensionContent = {
      ...extensionContent,
      extension: extension || '',
      description: description ? description.toString().replace(/"/g, '\\"') : '',
      id_lieu: id_location || null,
      image: newImageArray.join() || image || '',
      adresse: adresse || '',
    }
    if (isNewExtension) PUT_EXTENSION_CONTENT(token, newExtensionContent, 'create')
    else PUT_EXTENSION_CONTENT(token, newExtensionContent, 'update')
    return null
  }


  // *************************************
  createNewLocation = (locationContent) => {
    Geocode.fromAddress(locationContent.lieu).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, locationContent);
      },
      (error) => {
        console.error(error);
        alert('cannot find GPS information for this location, please contact your administrator to create it manually.')
      },
    )
  }


  // create a new location


  createNewLocation_withGeoLoc = (response, locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu = locationContent.lieu
    locationDetails.geocode = response.results[0].geometry.location

    const { PUT_LOCATION_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }

  // **************************
  // Modal action

  getModifiedItem = (item) => {
    const { extensionContent } = this.state
    this.setState({
      extensionContent: {
        ...extensionContent,
        extension: item.extension,
      },
    }, () => this.handleClose())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
    setTimeout(() => {
      this.setState({
        isValidated: false,
      })
    }, 300);
  };

  // **********************************
  render() {
    const {
      extensionContent,
      extensionList,
      restrictedList,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      isModalOpen,
      actionLabel,
      btnActionDisabled,
    } = this.state
    const selectableExtensionList = !restrictedList ? extensionList : restrictedList

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              {/* <FabDelete
                deleteItem={this.deleteItem}
              /> */}
              <h2>EXTENSIONS</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteExtension('extension', extensionContent, selectableExtensionList)}
              {this.displayExtensionInputs()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!extensionContent || btnActionDisabled}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the new title for this extension"
            modalContent="extension"
            warning="This will update this extension in all quotes !"
            type="extension"
            inputValue={!_.isEmpty(extensionContent) ? extensionContent.extension : ''}
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  extensionList: state.extensionReducer.extensionList,
  putExtensionContent: state.extensionReducer.putExtensionContent,
  deleteExtensionContent: state.extensionReducer.deleteExtensionContent,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  companyContent: state.companyReducer.companyContent,
  putLocationContent: state.api.putLocationContent,
})

const mapDispatchToProps = dispatch => ({
  GET_EXTENSION_LIST: userId => dispatch({
    type: 'GET_EXTENSION_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  PUT_EXTENSION_CONTENT: (token, extensionContent, action) => dispatch({
    type: 'PUT_EXTENSION_CONTENT',
    token,
    extensionContent,
    action,
  }),
  DELETE_EXTENSION_CONTENT: (token, extensionId) => dispatch({
    type: 'DELETE_EXTENSION_CONTENT',
    token,
    extensionId,
  }),
  RESET_EXTENSION_CONTENT: () => dispatch({
    type: 'RESET_EXTENSION_CONTENT',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Extension))
