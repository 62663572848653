import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import Interweave from 'interweave';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Input,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import tableIcons from '../../../components/utils/icons/icons'

export default function DaySelect(props) {
  const {
    tripDetails,
    tripAllDays,
    daySelected,
    tripContent,
    dayIndexSelected,
    sendDaySelected,
    sendDayIndexSelected,
  } = props

  const [disableLeftArrow, setDisableLeftArrow] = useState(true)
  const [disableRightArrow, setDisableRightArrow] = useState(false)

  const {
    ArrowLeft,
    ArrowRight,
  } = tableIcons

  useEffect(() => {
    disableArrow()
  }, [dayIndexSelected])

  const handleChange = (e) => {
    const { value } = e.target
    sendDay(value)
    sendDayIndex(value.dayIndex)
  }

  const disableArrow = () => {
    if (tripAllDays && tripAllDays.length - 1 >= dayIndexSelected + 1) {
      setDisableRightArrow(false)
    } else {
      setDisableRightArrow(true)
    }
    if (dayIndexSelected - 1 > -1) {
      setDisableLeftArrow(false)
    } else {
      setDisableLeftArrow(true)
    }
  }

  const changeDayByArrow = (action) => {
    switch (action) {
      case 'add':
        if (tripAllDays.length - 1 >= dayIndexSelected + 1) {
          sendDay(tripAllDays[dayIndexSelected + 1])
          sendDayIndex(dayIndexSelected + 1)
        }
        // disableArrow()
        break;
      case 'sub':
        if (dayIndexSelected - 1 > -1) {
          sendDay(tripAllDays[dayIndexSelected - 1])
          sendDayIndex(dayIndexSelected - 1)
        }
        // disableArrow()
        break;
      default:
        break;
    }
  }

  const sendDayIndex = (rank) => {
    sendDayIndexSelected(rank)
  }

  const sendDay = (day) => {
    sendDaySelected(day)
  }


  // determine room cells content

  const TabIncJour = tripAllDays ? tripAllDays.map(item => (item.day_duration)) : []
  const IncDay = _.sum(TabIncJour.slice(0, dayIndexSelected))
  const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDay, 'd').format('dddd DD MMMM YYYY'))
    : ''

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 520,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  const desc = `desc_jour_${dayIndexSelected + 1}`;
  return (
    <div className="day-select-container">
      <CssGrid
        container
        spacing={2}
        className="day-select"
      >
        <CssGrid item md={2} xs={12} className="day-selection">
          <div className={`arrow-selection ${disableLeftArrow ? 'disabled-arrow' : null}`} onClick={() => changeDayByArrow('sub')}>
            <ArrowLeft
              disabled={disableLeftArrow}
            />
          </div>
          <HtmlTooltip
            placement="right-start"
            title={(
              <React.Fragment>
                <Interweave content={tripContent[0][desc]} />
              </React.Fragment>
             )}
          >
            <div className="day-number-selected">
              <p>{dayIndexSelected + 1}</p>
            </div>
          </HtmlTooltip>
          <div className={`arrow-selection ${disableRightArrow ? 'disabled-arrow' : null}`} onClick={() => changeDayByArrow('add')}>
            <ArrowRight
              disabled={disableRightArrow}
            />
          </div>
        </CssGrid>
        <CssGrid item md={8} xs={12}>
          <Input
            id="daySelect"
            name="daySelect"
            label="Day"
            select
            value={!_.isEmpty(daySelected) ? daySelected : {}}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          >
            {tripAllDays && tripAllDays.map(option => (
              <MenuItem key={`${option.id_journee}-${dayIndexSelected}`} value={option}>
                {option.nom_journee}
              </MenuItem>
            ))}
          </Input>
        </CssGrid>
        <CssGrid item md={2} xs={12}>
          <Input
            label="Date"
            type="text"
            value={datejour}
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
            variant="outlined"
          />
        </CssGrid>
      </CssGrid>
    </div>
  );
}

DaySelect.propTypes = {
  dayIndexSelected: PropTypes.number,
  daySelected: PropTypes.object,
  tripDetails: PropTypes.array,
  tripContent: PropTypes.array,
  tripAllDays: PropTypes.array,
  sendDaySelected: PropTypes.func.isRequired,
  sendDayIndexSelected: PropTypes.func.isRequired,
};

DaySelect.defaultProps = {
  dayIndexSelected: null,
  tripContent: [],
  daySelected: {},
  tripDetails: [],
  tripAllDays: [],
};
