export const GET_PICTURE_RESULTS = 'GET_PICTURE_RESULTS'
// export const PUT_PICTURE_RESULTS = 'PUT_PICTURE_RESULTS'
export const DELETE_PICTURE_RESULTS = 'DELETE_PICTURE_RESULTS'
export const RESET_PICTURE = 'RESET_PICTURE'

export const getPictureResults = data => ({
  type: GET_PICTURE_RESULTS,
  pictureList: data,
})

// export const putPictureResults = data => ({
//   type: PUT_PICTURE_RESULTS,
//   putPicture: data,
// })

export const deletePictureResults = data => ({
  type: DELETE_PICTURE_RESULTS,
  deletePicture: data,
})

export const resetPicture = () => ({
  type: RESET_PICTURE,
})
