export const GET_SUPPLIERS_LIST_RESULTS = 'GET_SUPPLIERS_LIST_RESULTS'
export const PUT_SUPPLIERS_CONTENT_RESULTS = 'PUT_SUPPLIERS_CONTENT_RESULTS'
export const RESET_SUPPLIERS_CONTENT = 'RESET_SUPPLIERS_CONTENT'
export const DELETE_SUPPLIERS_CONTENT_RESULTS = 'DELETE_SUPPLIERS_CONTENT_RESULTS'

export const getSuppliersListResults = data => ({
  type: GET_SUPPLIERS_LIST_RESULTS,
  suppliersList: data,
})

export const resetSuppliersContent = () => ({
  type: RESET_SUPPLIERS_CONTENT,
})

export const putSuppliersContentResults = data => ({
  type: PUT_SUPPLIERS_CONTENT_RESULTS,
  putSuppliersContent: data,
})

export const deleteSuppliersContentResults = data => ({
  type: DELETE_SUPPLIERS_CONTENT_RESULTS,
  deleteSuppliersContent: data,
})
