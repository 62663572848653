import {
  RESET_TRIP_DETAILS_SITE,
  PUT_TRIP_DETAILS_SITE_RESULTS,
} from './actions'


const initialState = {
  putTripDetailsSite: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_TRIP_DETAILS_SITE:
      return {
        ...state,
        putTripDetailsSite: { success: false },
      }
    case PUT_TRIP_DETAILS_SITE_RESULTS:
      return {
        ...state,
        putTripDetailsSite: action.putTripDetailsSite,
      }
    default:
      return state
  }
}
