import {
  GET_COLLABORATORS_RESULTS,
  PUT_COLLABORATORS_RESULTS,
  // DELETE_COLLABORATOR_RESULTS,
  RESET_COLLABORATORS,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  collaborators: [],
  putCollaborators: null,
  // deleteCollaborator: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_COLLABORATORS_RESULTS:
      return {
        ...state,
        collaborators: action.collaborators,
      }
    case PUT_COLLABORATORS_RESULTS:
      return {
        ...state,
        putCollaborators: action.putCollaborators,
      }
    // case DELETE_COLLABORATORS_RESULTS:
    //   return {
    //     ...state,
    //     deleteCollaborator: action.deleteCollaborator,
    //   }
    case RESET_COLLABORATORS:
      return {
        ...state,
        putCollaborators: null,
        // deleteCompany: { success: false },
      }
    default:
      return state
  }
}
