import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete';

import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  Loader,
  AddButton,
} from '../../../components'
import daysColumns from '../../../components/utils/table/daysColumns'

const {
  Edit,
} = tableIcons

class DaysTable extends PureComponent {
  static propTypes = {
    GET_DAYS_LIST: PropTypes.func.isRequired,
    DELETE_DAY_CONTENT: PropTypes.func.isRequired,
    RESET_DAY_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    daysList: PropTypes.array,
    deleteDayContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    daysList: [],
    deleteDayContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateDaysList = this.updateDaysList.bind(this)
    this.deleteDay = this.deleteDay.bind(this)
    this.getIdDay = this.getIdDay.bind(this)
    this.goToDay = this.goToDay.bind(this)
    this.editDay = this.editDay.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      daysList: [],
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      daysList,
      GET_DAYS_LIST,
      LOADER,
    } = this.props
    const { userId } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(daysList)) {
      GET_DAYS_LIST(userId)
      LOADER(true)
    } else {
      this.setState({
        daysList,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.state
    const {
      daysList,
      deleteDayContent,
      RESET_DAY_CONTENT,
      GET_DAYS_LIST,
      LOADER,
    } = this.props
    if (deleteDayContent && deleteDayContent.success) {
      RESET_DAY_CONTENT()
      GET_DAYS_LIST(userId)
      this.reload(deleteDayContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (deleteDayContent && !deleteDayContent.success) {
      RESET_DAY_CONTENT()
      this.updateDaysList()
      this.reload(deleteDayContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (!_.isEqual(daysList, prevProps.daysList)) {
      LOADER(false)
      this.updateDaysList()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  updateDaysList = () => {
    const { daysList } = this.props
    this.setState({
      daysList,
    })
  }

  getIdDay = (rowsSelected) => {
    const {
      daysList,
    } = this.props
    return daysList[rowsSelected.data[0].dataIndex].id_journee
  }

  deleteDay = (rowsDeleted) => {
    const { token } = this.state
    const {
      daysList,
      DELETE_DAY_CONTENT,
      LOADER,
    } = this.props
    const { version, nom_journee } = daysList[rowsDeleted.data[0].dataIndex]
    const idDay = this.getIdDay(rowsDeleted)
    const compteVersion = daysList.filter(d => d.nom_journee === nom_journee).length

    if (version === compteVersion) {
      if (confirm('Do you really want to delete day ?')) {
        LOADER(true)
        DELETE_DAY_CONTENT(token, idDay)
        // update state without travelers deleted
        const newDaysList = _.cloneDeep(daysList)
        newDaysList.splice(idDay, 1)
        this.setState({
          daysList: newDaysList,
        })
      }
    } else {
      alert('You can only delete or archive the higher version of the day')
    }
  }

  goToDay = (data = {}) => {
    const { history } = this.props
    history.push('day', { ...data })
  }

  editDay = (rowsSelected) => {
    const idDay = this.getIdDay(rowsSelected)
    this.goToDay({
      source: 'days-table',
      idDay,
    })
  }

  // ************************* Display *************************************
  // custom tool bar when a day is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editDay(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteDay(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => (
    <AddButton
      title="Add new day"
      goTo={() => this.goToDay()}
    />
  )

  render() {
    const {
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      daysList,
    } = this.state
    const { isLoading } = this.props

    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'days_list.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      // onRowsDelete: (rowsDeleted, data) => this.deleteDay(rowsDeleted, data),
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="resourcesContainer">
              <h2>DAYS LIST</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <MUIDataTable
                className="table"
                title="Days"
                data={daysList}
                columns={daysColumns}
                options={options}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  daysList: state.api.daysList.length > 1 ? _.filter(state.api.daysList, d => d.id_journee !== 1 && d.active !== '2') : state.api.daysList,
  // daysList: _.filter(state.api.daysList, d => d.id_journee !== 1),
  deleteDayContent: state.api.deleteDayContent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_DAYS_LIST: userId => dispatch({
    type: 'GET_DAYS_LIST',
    userId,
  }),
  DELETE_DAY_CONTENT: (token, dayId) => dispatch({
    type: 'DELETE_DAY_CONTENT',
    token,
    dayId,
  }),
  RESET_DAY_CONTENT: () => dispatch({
    type: 'RESET_DAY_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DaysTable))
