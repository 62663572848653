
import React, { PureComponent } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import emailjs from 'emailjs-com';
import PropTypes from 'prop-types'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  ActionButton,
} from '..'


class CreatePDF1 extends PureComponent {
  static propTypes = {
    tripAllDays: PropTypes.array,

  }

    static defaultProps = {
      tripAllDays: [],

    }

    constructor(props) {
      super(props);
      this.state = {


      };
    }

    render() {
      const {
        tripAllDays,

      } = this.props

      const contenumessage = {
        message: tripAllDays[0].desc_journee,
        subject: 'test',
        mail: 'damsthoms@gmail.com',
      }

      function sendEmail(e) {
        e.preventDefault();

        emailjs.send('service_6jbe278', 'template_g0whscg', contenumessage, 'user_Qrh2YTa1CCmueJM6raION')
          .then((result) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
      }
      return (
        <div>

          <ActionButton
            label="Envoyer le devis par email"
          // eslint-disable-next-line react/jsx-no-bind
            onClick={sendEmail}
          />
        </div>


      )
    }
}

const mapStateToProps = state => ({
  informationsContent: state.informationsReducer.informationsContent,
  tripAllDays: state.api.tripAllDays,
})

export default withRouter(connect(mapStateToProps)(CreatePDF1))
