export const GET_ALL_TRAVELER_RESULTS = 'GET_ALL_TRAVELER_RESULTS'
export const GET_TRAVELER_BY_TRIP_RESULTS = 'GET_TRAVELER_BY_TRIP_RESULTS'
export const GET_TRAVELER_LIST_RESULTS = 'GET_TRAVELER_LIST_RESULTS'
export const PUT_TRAVELER_CONTENT_RESULTS = 'PUT_TRAVELER_CONTENT_RESULTS'
export const DELETE_TRAVELER_CONTENT_RESULTS = 'DELETE_TRAVELER_CONTENT_RESULTS'
export const DELETE_TRAVELERS_RESULTS = 'DELETE_TRAVELERS_RESULTS'
export const RESET_TRAVELER_CONTENT = 'RESET_TRAVELER_CONTENT'
export const RESET_TRAVELER_LIST = 'RESET_TRAVELER_LIST'

export const getAllTravelerResults = data => ({
  type: GET_ALL_TRAVELER_RESULTS,
  travelerList: data,
})

export const getTravelerByTripResults = data => ({
  type: GET_TRAVELER_BY_TRIP_RESULTS,
  numberTravelerByTrip: data,
})

export const getTravelerListResults = data => ({
  type: GET_TRAVELER_LIST_RESULTS,
  travelerListByTrip: data,
})

export const putTravelerContentResults = data => ({
  type: PUT_TRAVELER_CONTENT_RESULTS,
  putTravelerContent: data,
})

export const deleteTravelerContentResults = data => ({
  type: DELETE_TRAVELER_CONTENT_RESULTS,
  deleteTravelerContent: data,
})

export const deleteTravelersResults = data => ({
  type: DELETE_TRAVELERS_RESULTS,
  deleteTravelers: data,
})

export const resetTravelerContent = () => ({
  type: RESET_TRAVELER_CONTENT,
})

export const resetTravelerList = () => ({
  type: RESET_TRAVELER_LIST,
})
