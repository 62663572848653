import {
  GET_RESOURCES_RESULTS,
  PUT_RESOURCES_RESULTS,
  DELETE_RESOURCES_RESULTS,
  RESET_RESOURCES,
  GET_RESERVATIONS_RESULTS,
  PUT_RESERVATIONS_RESULTS,
  DELETE_RESERVATIONS_RESULTS,
  RESET_RESERVATIONS,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  resources: [],
  putResources: null,
  deleteResources: null,
  events: [],
  putEvent: null,
  deleteEvent: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    // **************** RESOURCES *********************
    case GET_RESOURCES_RESULTS:
      return {
        ...state,
        resources: action.resources,
      }
    case PUT_RESOURCES_RESULTS:
      return {
        ...state,
        putResources: action.putResources,
      }
    case DELETE_RESOURCES_RESULTS:
      return {
        ...state,
        deleteResources: action.deleteResources,
      }
    case RESET_RESOURCES:
      return {
        ...state,
        putResources: null,
        deleteResources: null,
      }
    // **************** RESOURCES *********************
    case GET_RESERVATIONS_RESULTS:
      return {
        ...state,
        events: action.events,
      }
    case PUT_RESERVATIONS_RESULTS:
      return {
        ...state,
        putEvent: action.putEvent,
      }
    case DELETE_RESERVATIONS_RESULTS:
      return {
        ...state,
        deleteEvent: action.deleteEvent,
      }
    case RESET_RESERVATIONS:
      return {
        ...state,
        putEvent: null,
        deleteEvent: null,
      }
    default:
      return state
  }
}
