import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';

import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'

import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'


const locationFormatRegExp = new RegExp(' ', 'g')

class Location_grand extends PureComponent {
  static propTypes = {
    GET_LOCATION_GRAND_LIST: PropTypes.func.isRequired,
    RESET_LOCATION_GRAND_CONTENT: PropTypes.func.isRequired,
    PUT_LOCATION_GRAND_CONTENT: PropTypes.func.isRequired,
    DELETE_LOCATION_GRAND_CONTENT: PropTypes.func.isRequired,
    putLocationGrandContent: PropTypes.object,
    deleteLocationGrandContent: PropTypes.object,
    locationGrandList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    locationGrandList: [],
    putLocationGrandContent: {},
    deleteLocationGrandContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.validate = this.validate.bind(this)
    this.reload = this.reload.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.onChange = this.onChange.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedLocation = this.getModifiedLocation.bind(this)
    this.updateLocation = this.updateLocation.bind(this)
    this.state = {
      actionLabel: 'Create',
      locationList: [],
      isNewLocation: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      disabledActionBtn: true,
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      locationContent: '',
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      locationGrandList,
      GET_LOCATION_GRAND_LIST,
    } = this.props

    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(locationGrandList)) {
      this.setState({
        locationList: _.sortBy(locationGrandList, o => o.lieu_grand),
      })
    } else {
      GET_LOCATION_GRAND_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      locationContent,
      locationList: locationGrandListOld,
    } = this.state
    const {
      locationGrandList,
      putLocationGrandContent,
      deleteLocationGrandContent,
      RESET_LOCATION_GRAND_CONTENT,
    } = nextprops
    // location saved in DB -> get back updated list
    if (putLocationGrandContent && putLocationGrandContent.success) {
      this.reload()
      this.setState({
        locationContent: {
          ...locationContent,
          id_lieu_grand: putLocationGrandContent.id,
        },
        validMsg: 'the location was successfully recorded !',
      })
      this.resetValidMsg()
      this.handleClose()
    }
    // location delete in DB -> get back updated list
    if (deleteLocationGrandContent && deleteLocationGrandContent.success) {
      this.reload()
      this.setState({
        locationContent: '',
        validMsg: 'the province was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // location can not be delete in DB -> it is used yet in day or accomodation
    if (deleteLocationGrandContent && !deleteLocationGrandContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteLocationGrandContent.message,
      })
      RESET_LOCATION_GRAND_CONTENT()
    }
    if (locationGrandList !== locationGrandListOld) {
      this.setState({
        locationList: _.sortBy(locationGrandList, o => o.lieu_grand),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_LOCATION_GRAND_CONTENT,
      GET_LOCATION_GRAND_LIST,
    } = this.props
    RESET_LOCATION_GRAND_CONTENT()
    GET_LOCATION_GRAND_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { locationList } = this.state

    this.setState({
      validateError: false,
    })
    if (typeof newValue === 'string') {
      // On press Enter
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const valueExist = locationList.filter(d => d._grand === valueClean)
      if (_.isEmpty(valueExist)) {
        this.setState({
          locationContent: {
            id_lieu_grand: null,
            lieu_grand: newValue,
          },
          disabledActionBtn: false,
          isNewLocation: true,
        });
      } else {
        this.setState({
          locationContent: valueExist[0],
          disabledActionBtn: true,
          isNewLocation: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const valueClean = _.upperCase(_.trim(newValue.inputValue)).replace(locationFormatRegExp, '-')
      const isNew = _.findIndex(locationList, o => o.lieu_grand === valueClean) < 0
      this.setState({
        locationContent: {
          id_lieu_grand: null,
          lieu_grand: newValue.inputValue,
        },
        disabledActionBtn: !isNew,
        isNewLocation: isNew,
      });
    } else {
      this.setState({
        locationContent: newValue,
        disabledActionBtn: true,
        isNewLocation: false,
      });
    }
  }

  filterOptions = (options, params) => {
    const { locationList } = this.state
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.locationGrand.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(d => d.lieu_grand === valueClean)

    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu_grand: `Add "${valueClean}"`,
      });
    }

    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.lieu_grand;
  }

  // ***************************
  // Display input
  displayAutocompleteInput = () => {
    const {
      locationContent,
      locationList,
      validateError,
    } = this.state
    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="location-field"
            value={locationContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={locationList}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.lieu_grand}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Location"
                margin="normal"
                placeholder="Select a place or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      locationContent,
    } = this.state
    const locationId = locationContent.id_lieu_grand
    const { DELETE_LOCATION_GRAND_CONTENT } = this.props

    if (confirm('Do you really want to delete this location ?')) {
      if (locationId) {
        DELETE_LOCATION_GRAND_CONTENT(token, locationId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: locationContent.lieu_grand ? 'This location do not exist.' : 'Please, select a location.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { locationContent } = this.state
    if (!locationContent || !locationContent.id_lieu_grand) return null
    this.setState({
      isNewLocation: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      // locationContent,
      isNewLocation,
      locationContent,
    } = this.state
    // Check all required inputs
    this.setState({
      disabledActionBtn: true,
    })
    if (!locationContent.lieu_grand) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewLocation) {
      this.createNewLocation(locationContent);
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new location
  updateLocation = () => {
    const {
      token,
      locationContent,
    } = this.state
    const { PUT_LOCATION_GRAND_CONTENT } = this.props
    PUT_LOCATION_GRAND_CONTENT(token, locationContent, 'update')
    this.handleClose()
  }

  createNewLocation = (locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu_grand = locationContent.lieu_grand
    const { PUT_LOCATION_GRAND_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_GRAND_CONTENT(token, locationDetails, 'create')
  }


  getModifiedLocation = (item) => {
    const { locationContent } = this.state
    this.setState({
      locationContent: {
        ...locationContent,
        lieu_grand: _.upperCase(_.trim(item.location)).replace(locationFormatRegExp, '-'),
      },
    }, () => this.updateLocation())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      disabledActionBtn,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      locationContent,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>PROVINCE OR REGION MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!locationContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedLocation(item)}
            disabled={false}
            title="Enter the new value for this location"
            warning="This will update this location in all quotes !"
            type="location"
            modalContent="location"
            inputValue={!_.isEmpty(locationContent) ? locationContent.lieu_grand : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  putLocationGrandContent: state.api.putLocationGrandContent,
  deleteLocationGrandContent: state.api.deleteLocationGrandContent,
})

const mapDispatchToProps = dispatch => ({
  GET_LOCATION_GRAND_LIST: userId => dispatch({
    type: 'GET_LOCATION_GRAND_LIST',
    userId,
  }),
  RESET_LOCATION_GRAND_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_GRAND_CONTENT',
  }),
  PUT_LOCATION_GRAND_CONTENT: (token, locationGrandContent, action) => dispatch({
    type: 'PUT_LOCATION_GRAND_CONTENT',
    token,
    locationGrandContent,
    action,
  }),
  DELETE_LOCATION_GRAND_CONTENT: (token, locationGrandId) => dispatch({
    type: 'DELETE_LOCATION_GRAND_CONTENT',
    token,
    locationGrandId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Location_grand))
