import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

class ActionButton extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    target: PropTypes.string,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    hide: PropTypes.string,
  }

  static defaultProps = {
    type: 'button',
    title: '',
    disabled: false,
    target: null,
    onClick: () => {},
    color: null,
    hide: null,
  }

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this)
    this.state = { }
  }

  goTo = (page) => {
    const { history } = this.props
    history.push(page)
  }

  render() {
    const {
      type,
      target,
      label,
      disabled,
      onClick,
      color,
      title,
      hide,
    } = this.props
    const disabledClass = disabled ? 'disabled' : ''
    const backgroundColor = color === 'reverse' ? 'reverse-color' : null
    return (
      <div className="actionButton">
        <a
          href="#"
          className={`button ${disabledClass} ${backgroundColor} ${hide}`}
          type={type}
          title={title}
          disabled={disabled}
          onClick={(e) => {
            if (!disabled) {
              if (target) this.goTo(target)
              if (onClick) onClick(e)
            }
          }}
        >
          {label}
        </a>
      </div>
    )
  }
}

export default withRouter(ActionButton)
