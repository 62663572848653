const resourcesColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'category',
    label: 'Category',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'sub_category',
    label: 'Sub Category',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'serial_number',
    label: 'Serial Number',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'comment',
    label: 'Comment',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'date_commisioning',
    label: 'Commisioning Date',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'date_creation',
    label: 'Creation Date',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'date_modification',
    label: 'Modification Date',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'image',
    label: 'Image',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
]

export default resourcesColumns
