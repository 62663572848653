import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import { CssGrid } from '../../../configCSS'

import greenFlag from '../../../assets/images/good-flag.png'
import greenFlag2x from '../../../assets/images/good-flag@2x.png'
import greenFlag3x from '../../../assets/images/good-flag@3x.png'
import orangeFlag from '../../../assets/images/warning-flag.png'
import orangeFlag2x from '../../../assets/images/warning-flag@2x.png'
import orangeFlag3x from '../../../assets/images/warning-flag@3x.png'
import badFlag from '../../../assets/images/bad-flag.png'
import badFlag2x from '../../../assets/images/bad-flag@2x.png'
import badFlag3x from '../../../assets/images/bad-flag@3x.png'

export default function ReservationColumnHeader(props) {
  const {
    itemsCheck,
  } = props

  const [checkFlag, setCheckFlag] = useState('red')
  useEffect(() => {
    if (!_.isEmpty(itemsCheck)) {
      const activityChecks = _.map(itemsCheck, 'activityCheck');
      if (_.every(activityChecks, i => i === 0)) {
        setCheckFlag('red')
      } else if (_.every(activityChecks, i => i === 1)) {
        setCheckFlag('green')
      } else {
        setCheckFlag('orange')
      }
    } else {
      setCheckFlag('green')
    }
  }, [itemsCheck])

  const getFlag = () => {
    switch (checkFlag) {
      case 'red':
        return <img src={badFlag} srcSet={`${badFlag2x} 2x, ${badFlag3x} 3x`} alt="" />
      case 'green':
        return <img src={greenFlag} srcSet={`${greenFlag2x} 2x, ${greenFlag3x} 3x`} alt="" />
      case 'orange':
        return <img src={orangeFlag} srcSet={`${orangeFlag2x} 2x, ${orangeFlag3x} 3x`} alt="" />
      default:
        break;
    }
    return false
  }

  return (
    <CssGrid
      container
      spacing={2}
      className="reservation-header"
    >
      <CssGrid item md={2} xs={12} className="reservation-column-header">
        Title
      </CssGrid>
      <CssGrid item md={4} xs={12} className="reservation-column-header">
        Informations
      </CssGrid>
      <CssGrid item md={5} xs={12} className="reservation-column-header">
        Reservation comments
      </CssGrid>
      <CssGrid item md={1} xs={12} className="reservation-column-header">
        <span>Check</span>
        {getFlag()}
      </CssGrid>
    </CssGrid>
  );
}

ReservationColumnHeader.propTypes = {
  itemsCheck: PropTypes.array,
};

ReservationColumnHeader.defaultProps = {
  itemsCheck: [],
};
