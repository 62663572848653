export const GET_CUSTOMER_LIST_RESULTS = 'GET_CUSTOMER_LIST_RESULTS'
export const PUT_CUSTOMER_CONTENT_RESULTS = 'PUT_CUSTOMER_CONTENT_RESULTS'
export const DELETE_CUSTOMER_CONTENT_RESULTS = 'DELETE_CUSTOMER_CONTENT_RESULTS'
export const RESET_CUSTOMER_CONTENT = 'RESET_CUSTOMER_CONTENT'

export const getCustomerListResults = data => ({
  type: GET_CUSTOMER_LIST_RESULTS,
  customerList: data,
})

export const putCustomerContentResults = data => ({
  type: PUT_CUSTOMER_CONTENT_RESULTS,
  putCustomerContent: data,
})

export const deleteCustomerContentResults = data => ({
  type: DELETE_CUSTOMER_CONTENT_RESULTS,
  deleteCustomerContent: data,
})

export const resetCustomerContent = () => ({
  type: RESET_CUSTOMER_CONTENT,
})
