import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  Template,
  DashBoard,
} from '../../components'

class HomePage extends PureComponent {
  static propTypes = {

  }

  static defaultProps = {


  }

  constructor(props) {
    super(props);
    this.state = {

      // eslint-disable-next-line react/no-unused-state

    }
  }

  componentDidMount() {

  }


  render() {
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <DashBoard />
          </div>
        </Template>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  collaborators: state.collaboratorsReducer.collaborators,
  putCollaborators: state.collaboratorsReducer.putCollaborators,
  profilUserList: state.api.profilUserList,
})


export default withRouter(connect(mapStateToProps)(HomePage))
