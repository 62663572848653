import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'

import trip from '../../assets/images/trip.png'
import trip2x from '../../assets/images/trip@2x.png'
import trip3x from '../../assets/images/trip@3x.png'
import request from '../../assets/images/request.png'
import request2x from '../../assets/images/request@2x.png'
import request3x from '../../assets/images/request@3x.png'
import encyclopedia from '../../assets/images/encyclopedia.png'
import encyclopedia2x from '../../assets/images/encyclopedia@2x.png'
import encyclopedia3x from '../../assets/images/encyclopedia@3x.png'
import modules from '../../assets/images/modules.png'
import modules2x from '../../assets/images/modules@2x.png'
import modules3x from '../../assets/images/modules@3x.png'
import client from '../../assets/images/client.png'
import client2x from '../../assets/images/client@2x.png'
import client3x from '../../assets/images/client@3x.png'
import collaborator from '../../assets/images/collaborator.png'
import collaborator2x from '../../assets/images/collaborator@2x.png'
import collaborator3x from '../../assets/images/collaborator@3x.png'
import statistics from '../../assets/images/statistics.png'
import statistics2x from '../../assets/images/statistics@2x.png'
import statistics3x from '../../assets/images/statistics@3x.png'
import calendar from '../../assets/images/calendar.png'
import calendar2x from '../../assets/images/calendar@2x.png'
import calendar3x from '../../assets/images/calendar@3x.png'
import invoice from '../../assets/images/invoice.png'
import invoice2x from '../../assets/images/invoice@2x.png'
import invoice3x from '../../assets/images/invoice@3x.png'


const menu = [
  {
    title: 'Trip',
    link: 'trip',
    profilAuthorized: 10,
    img: trip,
    img2x: trip2x,
    img3x: trip3x,
  },
  {
    title: 'Dashboard',
    link: 'homePage',
    profilAuthorized: 10,
    img: encyclopedia,
    img2x: encyclopedia2x,
    img3x: encyclopedia3x,
  },
  {
    title: 'Request',
    link: 'request',
    profilAuthorized: 10,
    img: request,
    img2x: request2x,
    img3x: request3x,
  },
  {
    title: 'Modules',
    module: 'moduleManagement',
    profilAuthorized: 10,
    img: modules,
    img2x: modules2x,
    img3x: modules3x,
    submenu: [
      {
        title: 'Day',
        link: 'days-table',
      },
      {
        title: 'Accomodation',
        link: 'accomodation-table',
      },
      {
        title: 'Suppliers',
        link: 'suppliers',
      },
      {
        title: 'Service & Activity',
        link: 'Activities-table',
      },
      {
        title: 'General informations',
        link: 'informations',
      },
      {
        title: 'Other modules',
        module2: 'Other',
        submenu2: [
          {
            title: 'Accomodation type',
            link: 'accomodationType',
          },
          {
            title: 'Extension',
            link: 'extension',
          },
          {
            title: 'Gallery',
            link: 'gallery',
          },
          {
            title: 'Location',
            link: 'location',
          },
          {
            title: 'Province Region',
            link: 'location_grand',
          },
          {
            title: 'Pension',
            link: 'pension',
          },
          {
            title: 'Room',
            link: 'room',
          },
        ],
      },
    ],
  },
  {
    title: 'Clients',
    module: 'customerManagement',
    profilAuthorized: 10,
    img: client,
    img2x: client2x,
    img3x: client3x,
    submenu: [
      {
        title: 'Travelers',
        link: 'traveler-table',
      },
      {
        title: 'Requestors',
        link: 'requestor-table',
      },
    ],
  },
  {
    title: 'Resources',
    module: 'resourceManagement',
    profilAuthorized: 10,
    img: calendar,
    img2x: calendar2x,
    img3x: calendar3x,
    submenu: [
      {
        title: 'Resources Management',
        link: 'resources-management',
      },
      {
        title: 'Resources List',
        link: 'resources-table',
      },
      {
        title: 'Schedule',
        link: 'schedule',
      },
    ],
  },
  {
    title: 'Collaborators',
    link: 'collaborators',
    profilAuthorized: 1,
    img: collaborator,
    img2x: collaborator2x,
    img3x: collaborator3x,
  },
  {
    title: 'Statistics',
    link: 'statistics',
    profilAuthorized: 1,
    img: statistics,
    img2x: statistics2x,
    img3x: statistics3x,
  },
  {
    title: 'Invoice',
    link: 'invoice',
    profilAuthorized: 1,
    img: invoice,
    img2x: invoice2x,
    img3x: invoice3x,
  },
]

class Menu extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    location: PropTypes.object.isRequired,
    informationsContent: PropTypes.object,
    unvalidTokenList: PropTypes.array,
    userAccount: PropTypes.object,
    requestList: PropTypes.array,
    GET_UNVALID_TOKEN_LIST: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: true,
    unvalidTokenList: [],
    informationsContent: {},
    userAccount: {},
    requestList: [],
  }

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this)
    this.displaySubmenu = this.displaySubmenu.bind(this)
    this.displaySubmenu2 = this.displaySubmenu2.bind(this)
    this.selectMenu = this.selectMenu.bind(this)
    this.selectMenu2 = this.selectMenu2.bind(this)
    this.displayRequestIndicator = this.displayRequestIndicator.bind(this)
    this.state = {
      moduleManagement: false,
      Other: false,
      customerManagement: false,
      resourceManagement: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
    };
  }

  componentDidMount() {
    const { token } = this.state
    const {
      history,
      location,
      unvalidTokenList,
      GET_UNVALID_TOKEN_LIST,
    } = this.props

    GET_UNVALID_TOKEN_LIST()

    // checks if the token has not been invalidated by another connection
    if (!_.isEmpty(unvalidTokenList)) {
      const isUnvalidToken = unvalidTokenList.indexOf(token) > -1
      if (isUnvalidToken) history.push('/')
    }

    // let moduleManagement submenu open
    switch (location.pathname) {
      case '/location':
      case '/location_grand':
      case '/pension':
      case '/room':
      case '/accomodationType':
      case '/extension':
      case '/gallery':
        this.setState({
          moduleManagement: true,
          Other: true,
        })
        break;
      case '/accomodation-table':
      case '/day':
      case '/days-table':
      case '/Activities-table':
      case '/informations':
        this.setState({
          moduleManagement: true,
        })
        break;
      case '/traveler-management':
      case '/traveler-table':
      case '/requestor-management':
      case '/requestor-table':
        this.setState({
          customerManagement: true,
        })
        break;
      case '/resources-management':
      case '/resources-table':
      case '/schedule':
        this.setState({
          resourceManagement: true,
        })
        break;
      default:
        break;
    }
  }

  goTo = (dest) => {
    const {
      history,
      isLoading,
    } = this.props
    if (isLoading) return null
    history.push(dest)
    return true
  }

  displaySubmenu = (item) => {
    const { informationsContent } = this.props
    const { theme_devis } = informationsContent
    // eslint-disable-next-line react/destructuring-assignment
    const hide = !this.state[item.module] ? 'hide' : null
    return (
      <div className={`submenu ${hide}`}>
        {_.map(item.submenu, (l, k) => {
          // Don't display Extension menu for template 'standard' and 'duyen'
          if (['standard', 'standard2', 'duyen', 'duyen-template'].indexOf(theme_devis) > -1 && l.title === 'Extension') return null
          return (
            l.submenu2 ? (
              <div
                className="title_submenu"
                key={`sub2${l}${k}`}
                onClick={() => this.selectMenu2(l)}
              >
                {l.title}
                {this.displaySubmenu2(l)}
              </div>
            )
              : (
                <div
                  key={`sub4${l}${k}`}
                  className="title_submenu"
                  onClick={() => this.goTo(l.link)}
                >
                  {l.title}
                </div>
              )
          )
        })}
      </div>
    )
  }

  displaySubmenu2 = (item) => {
    const { informationsContent } = this.props
    const { theme_devis } = informationsContent
    const hide2 = !this.state[item.module2] ? 'hide' : null
    // eslint-disable-next-line react/destructuring-assignment

    return (
      <div className={`submenu ${hide2}`}>
        {_.map(item.submenu2, (l, k) => {
          // Don't display Extension menu for template 'standard' and 'duyen'
          if (['standard', 'standard2', 'duyen', 'duyen-template'].indexOf(theme_devis) > -1 && l.title === 'Extension') return null
          return (
            <div
              key={`sub3${l}${k}`}
              className="title_submenu"
              onClick={() => this.goTo(l.link)}
            >
              {l.title}
            </div>
          )
        })}
      </div>
    )
  }

  displayRequestIndicator = () => {
    const { requestList } = this.props
    const { userId } = this.state
    const pendingRequestNb = requestList.filter(r => (r.status === 'received' || r.trip_name) && r.id_conseiller_dest === parseInt(userId, 10) && !r.date_accept).length
    if (pendingRequestNb === 0) return null
    return (
      <div className="request-number-indicator">
        {pendingRequestNb}
      </div>
    )
  }

  selectMenu = (item) => {
    if (!item.submenu) {
      this.goTo(item.link)
    } else {
      this.setState(prevState => ({
        [item.module]: !prevState[item.module],
      }))
    }
  }

  selectMenu2 = (item) => {
    this.setState(prevState => ({
      [item.module2]: !prevState[item.module2],
    }))
  }

  render() {
    const {
      userAccount,
    } = this.props
    return (
      <div className="menu">
        {_.map(menu, (item, k) => {
          if (userAccount.profil > item.profilAuthorized) return null
          return (
            <div key={`${item.title}${k}`}>
              <div
                className="title_menu"
                onClick={() => this.selectMenu(item)}
              >
                <div className="mainTitle">
                  <img className="imgMenu" src={item.img} srcSet={`${item.img2x} 2x, ${item.img3x} 3x`} alt="" />
                  {item.title}
                  {item.title === 'Request' && this.displayRequestIndicator()}
                </div>
              </div>
              <div className="title_menu">
                {item.submenu ? this.displaySubmenu(item) : null}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  unvalidTokenList: state.api.unvalidTokenList,
  informationsContent: state.informationsReducer.informationsContent,
  userAccount: state.userAccountReducer.userAccount,
  requestList: state.requestReducer.request,
  isLoading: state.api.isLoading,
})

const mapDispatchToProps = dispatch => ({
  GET_UNVALID_TOKEN_LIST: () => dispatch({
    type: 'GET_UNVALID_TOKEN_LIST',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu))
