import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getResourcesResults,
  putResourcesResults,
  deleteResourcesResults,
  getReservationsResults,
  putReservationsResults,
  deleteReservationsResults,
} from './actions'

// **************** Resources ************************
function* getResourcesAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_resources, token)
  yield put(getResourcesResults(data))
}

function* putResourcesAsync(params) {
  const {
    token,
    resource,
    action,
  } = params
  const data = yield call(api.put_resources, token, resource, action)
  yield put(putResourcesResults(data))
}

function* deleteResourcesAsync(params) {
  const {
    token,
    resourceId,
  } = params
  const data = yield call(api.delete_resources, token, resourceId)
  yield put(deleteResourcesResults(data))
}

// **************** Reservations ************************
function* getReservationsAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_reservations, token)
  yield put(getReservationsResults(data))
}

function* putReservationsAsync(params) {
  const {
    token,
    event,
    action,
  } = params
  const data = yield call(api.put_reservations, token, event, action)
  yield put(putReservationsResults(data))
}

function* deleteReservationsAsync(params) {
  const {
    token,
    eventId,
  } = params
  const data = yield call(api.delete_reservations, token, eventId)
  yield put(deleteReservationsResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_RESOURCES', getResourcesAsync)
  yield takeEvery('PUT_RESOURCES', putResourcesAsync)
  yield takeEvery('DELETE_RESOURCES', deleteResourcesAsync)
  yield takeEvery('GET_RESERVATIONS', getReservationsAsync)
  yield takeEvery('PUT_RESERVATIONS', putReservationsAsync)
  yield takeEvery('DELETE_RESERVATIONS', deleteReservationsAsync)
}

export default fetchResult
