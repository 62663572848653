import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

import _ from 'lodash'
import {
  Input,
} from '../../../../components';

export default function AutocompleteSelectAmongExisting(props) {
  const {
    field,
    label,
    dayContent,
    daysList,
    readOnlyProps,
    updateDayContent,
  } = props;

  const [dayProprety, setDayContent] = useState({});
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    setItemsList(_.uniqBy(daysList, field).filter(obj => !!obj[field]))
  }, [daysList])

  useEffect(() => {
    if (!_.isEmpty(dayProprety)) updateDayContent(dayProprety)
  }, [dayProprety])

  // ********************* Change **********************
  // ********************************
  // On change accomodation
  const onChangeAutocomplete = (event, newValue) => {
    if (typeof newValue === 'string') {
      // On press Enter
      setDayContent({
        [field]: _.capitalize(newValue),
      })
    } else if (!newValue) {
      // on clear field
      setDayContent({
        [field]: '',
      })
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input by click on ADD
      setDayContent({
        [field]: _.capitalize(newValue.inputValue),
      })
    } else {
      setDayContent({
        [field]: newValue[field],
      })
    }
    return null
  }

  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = daysList.filter(cat => cat[field] && cat[field].toLowerCase() === params.inputValue.toLowerCase())
    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        [field]: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option[field]) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[field];
  }

  return (
    <Autocomplete
      id={`AC-${field}`}
      value={dayContent}
      onChange={onChangeAutocomplete}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={itemsList}
      getOptionLabel={getOptionLabel}
      renderOption={option => option[field]}
      freeSolo
      disabled={readOnlyProps}
      renderInput={params => (
        <Input
          {...params}
          label={_.capitalize(label)}
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
}

AutocompleteSelectAmongExisting.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dayContent: PropTypes.object.isRequired,
  daysList: PropTypes.array.isRequired,
  readOnlyProps: PropTypes.bool.isRequired,
  updateDayContent: PropTypes.func.isRequired,
};
