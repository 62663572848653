import {
  GET_USER_ACCOUNT_RESULTS,
  PUT_USER_ACCOUNT_RESULTS,
  // DELETE_USER_ACCOUNT_RESULTS,
  RESET_USER_ACCOUNT,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  userAccount: {},
  putUserAccount: { success: false },
  deleteUserAccount: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_USER_ACCOUNT_RESULTS:
      return {
        ...state,
        userAccount: action.userAccount[0],
      }
    case PUT_USER_ACCOUNT_RESULTS:
      return {
        ...state,
        putUserAccount: action.putUserAccount,
      }
    // case DELETE_USER_ACCOUNT_RESULTS:
    //   return {
    //     ...state,
    //     deleteUserAccount: action.deleteUserAccount,
    //   }
    case RESET_USER_ACCOUNT:
      return {
        ...state,
        putUserAccount: { success: false },
        deleteUserAccount: { success: false },
      }
    default:
      return state
  }
}
