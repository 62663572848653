/* eslint-disable jsx-a11y/accessible-emoji */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import stage from '../../../config'
import 'moment/locale/it';

import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlParfum extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripAccomodationOpt1: PropTypes.array,
    tripAccomodationOpt2: PropTypes.array,
    tripBilling: PropTypes.object,
    tripDevisFinal: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
    accomodationList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    tripAllDays: [],
    tripDevisFinal: {},
    tripContent: [],
    tripAccomodation: [],
    tripAccomodationOpt1: [],
    tripAccomodationOpt2: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],
    accomodationList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.handleOSizeZoomChange = this.handleOSizeZoomChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      zoomCarte: 100,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteSense-size', `${event.currentTarget.value}px`)
  }

  handleOSizeZoomChange = (event) => {
    this.setState({
      zoomCarte: event.currentTarget.value,
    })
  }

handlePrint = (html) => {
  const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

  const newWindow = window.open('', '_blank');
  newWindow.document.write(content);
  newWindow.document.close();
}

handleSave = (devisContent) => {
  const {
    PUT_TRIP_DEVIS_FINAL,
    tripDetails,
    tripDevisFinal,
  } = this.props
  const {
    token,
  } = this.state
  const tripDevisFinalServeur = {}
  tripDevisFinalServeur.textHtml = devisContent
  tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
  if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
  } else {
    PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
  }
}

handleEditorChange = (content) => {
  this.setState({
    devisContent: content,
  });
}


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }
    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }


  Export2Word = (element, filename = '') => {
    const html = element;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }


  render() {
    const compterPensions = (trippension) => {
      // Initialiser les compteurs
      let nombreB = 0;
      let nombreL = 0;
      let nombreD = 0;

      // Parcourir chaque élément dans trippension
      trippension.forEach((element) => {
        // Compter le nombre de "B"
        // Compter le nombre de "B"
        nombreB += (element.pension.match(/B(?!R)/gi) || []).length;

        // Compter le nombre de "L"
        nombreL += (element.pension.match(/L/gi) || []).length;

        // Compter le nombre de "D"
        nombreD += (element.pension.match(/D/gi) || []).length;
      });


      // Retourner un objet contenant les résultats
      return {
        nombreB: nombreB,
        nombreL: nombreL,
        nombreD: nombreD,
      };
    }
    const tableauHebergement = (roomAccoArray, roomAcco1Array, roomAcco2Array) => {
      const {
        accomodationList,
        tripAllDays,
      } = this.props
      // Initialiser les compteurs


      return (roomAccoArray.map((item, i) => {
        const hebTrouve = parseInt(roomAccoArray[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAccoArray[i], 10))
        )
        const hebTrouve1 = parseInt(roomAcco1Array[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco1Array[i], 10))
        )
        const hebTrouve2 = parseInt(roomAcco2Array[i], 10) === 1 ? 'none' : (

          accomodationList.find(objet => objet.id_hebergement === parseInt(roomAcco2Array[i], 10))
        )
        return (
          {
            lieu_fin_journee: tripAllDays[i].lieu_fin_journee,
            nomHeb: hebTrouve === 'none' ? 'none' : hebTrouve.nom_hebergement,
            nomHeb1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.nom_hebergement,
            nomHeb2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.nom_hebergement,
            adresse: hebTrouve === 'none' ? 'none' : hebTrouve.adresse,
            adresse1: hebTrouve1 === 'none' ? 'none' : hebTrouve1.adresse,
            adresse2: hebTrouve2 === 'none' ? 'none' : hebTrouve2.adresse,
            heb: roomAccoArray[i],
            heb1: roomAcco1Array[i],
            heb2: roomAcco2Array[i],
            day_duration: tripAllDays[i].day_duration,
            cle: `${roomAccoArray[i]}${roomAcco1Array[i]}${roomAcco2Array[i]}${tripAllDays[i].lieu_fin_journee}`,
          }

        )
      })
      )
    }

    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAccomodation,
      tripAccomodationOpt1,
      tripAccomodationOpt2,
      tripPension,
      tripBilling,
      companyContent,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
      nb_chambre_opt1,
      type_chambre_opt1,
      nb_chambre_opt2,
      type_chambre_opt2,
      hebergement,
      hebergement_opt1,
      hebergement_opt2,
    } = tripContent[0]

    const {
      langage,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      map_localisation,
      nb_adulte,
      nb_enfant,
      nb_bebe,
      code_devis,
      destination,
      desc_voyage,
      centre_interet,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      zoomCarte,
      devisContent,
    } = this.state
    // get langage
    // *****************************************************************
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    const guideArray = _.split(guide, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomTypeArray_opt1 = _.split(type_chambre_opt1, ',')
    const roomTypeArray_opt2 = _.split(type_chambre_opt2, ',')
    const resultat = compterPensions(tripPension);
    const roomAccoArray = _.split(hebergement, ',')
    const roomAcco1Array = _.split(hebergement_opt1, ',')
    const roomAcco2Array = _.split(hebergement_opt2, ',')
    const tableauUnique = tableauHebergement(roomAccoArray, roomAcco1Array, roomAcco2Array)
    const uniqueHotels = {};
    const originalOrder = [];
    tableauUnique.forEach((hotel, index) => {
      // Vérifier si l'email de l'hôtel existe dans les hôtels uniques
      if (hotel.cle && !uniqueHotels[hotel.cle]) {
        // Ajouter l'hôtel unique à l'objet uniqueHotels avec le jour de la réservation
        uniqueHotels[hotel.cle] = {
          ...hotel,
          reservedDays: [index + 1], // Ajoutez le jour de la réservation
        };
        originalOrder.push(index);
      } else if (hotel.cle && uniqueHotels[hotel.cle]) {
        // Si l'email existe déjà, ajoutez le jour de la réservation à l'array reservedDays
        uniqueHotels[hotel.cle].reservedDays.push(index + 1);
      }
    });
    const Hotel_Unique = originalOrder.map(index => uniqueHotels[tableauUnique[index].cle]);
    const Nombre_Occurence_Hotel = findOcc(tableauUnique, 'cle')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const roomsNumberArray_opt1 = nb_chambre_opt1 ? _.split(nb_chambre_opt1, ',') : null
    const roomsNumberArray_opt2 = nb_chambre_opt2 ? _.split(nb_chambre_opt2, ',') : null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = zoomCarte === 100 ? (`https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    ) : (
      `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&zoom=${zoomCarte}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    )
    const TabIncJour = tripAllDays.map(item => (item.day_duration))
    const remarque = `${langage}_remarque`
    const longueurVoyage = tripAllDays.length
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD').add(IncDayTot, 'd').locale(langage).format('DD MMMM YYYY')
    const slogan = `${langage}_slogan`
    const chauffeurArray = _.split(chauffeur, ',')
    const borderRadius = {
      borderRadius: 15,
    };


    const Colordefaut = {
      color: informationsContent.main_color,
    };


    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }

    const nbOfRoomLabel_opt1 = (index) => {
      const roomsTypeForDay_opt1 = roomTypeArray_opt1[index] && roomTypeArray_opt1[index] !== '1' ? roomTypeArray_opt1[index].split('-') : []
      const roomsNbForDay_opt1 = roomsNumberArray_opt1 && roomsNumberArray_opt1[index] !== 'null' ? roomsNumberArray_opt1[index].split('-') : []
      const roomsNameForDay_opt1 = roomsTypeForDay_opt1.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay_opt1) ? [] : roomsNameForDay_opt1.map((r, j) => `${r} (x${roomsNbForDay_opt1[j]})${roomsNameForDay_opt1.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }

    const nbOfRoomLabel_opt2 = (index) => {
      const roomsTypeForDay_opt2 = roomTypeArray_opt2[index] && roomTypeArray_opt2[index] !== '1' ? roomTypeArray_opt2[index].split('-') : []
      const roomsNbForDay_opt2 = roomsNumberArray_opt2 && roomsNumberArray_opt2[index] !== 'null' ? roomsNumberArray_opt2[index].split('-') : []
      const roomsNameForDay_opt2 = roomsTypeForDay_opt2.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay_opt2) ? [] : roomsNameForDay_opt2.map((r, j) => `${r} (x${roomsNbForDay_opt2[j]})${roomsNameForDay_opt2.length === j + 1 ? '' : ', '}`)
      return label.join(''); // Utilisation de slice() pour retirer la dernière virgule
    }
    const pied = (
      <Interweave content={companyContent.description} />
    )

    const enTete = (
      <div className="page-header-sense">
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">
                <img width="400" height="200" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
              </td>
              <td width="75%">
                <Interweave content={companyContent[slogan]} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    )
    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFileSense">
          {indexDisplay !== 'HTMLWORD'
             && (
             <div className="page-header-sense">
               <table width="100%">
                 <tbody>
                   <tr>
                     <td width="25%">
                       <img width="400" height="200" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                     </td>
                     <td width="75%">
                       <Interweave content={companyContent[slogan]} />
                     </td>
                   </tr>
                 </tbody>
               </table>
             </div>
             )}
          <div>
            <table className="table-sense">
              {(indexDisplay !== 'HTMLWORD')
           && (
           <thead>
             <tr>
               <td>
                 <div className="page-header-space-sense" />
               </td>
             </tr>
           </thead>
           )}
              <tbody>
                <tr>
                  <td>
                    <div id="home">
                      <br />
                      <br />
                      <div className="titreProposition" style={Colordefaut}>
                        Proposition de voyage
                      </div>
                      <br />
                      <div className="centreMoi" style={Colordefaut}>
                        ---------------------------------------------------
                      </div>
                      <br />
                      <div className="titre">
                        {tripDetails && (tripDetails[0].nom_programme)}
                      </div>
                      <div className="titre">
                        {langageTranslation.parfumDestination} {travelerName}
                      </div>
                      <br />
                      <img
                        className="ImageCoverSense"
                        width="1000"
                        height="250"
                        src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`}
                      />
                      <br />
                      <div className="menu2" style={Colordefaut}>
                        <a href="#Home">General -
                        </a>
                        <a href="#brief"> Itinéraire en bref -
                        </a>
                        {(tripDetails[0].image || tripDetails[0].map_localisation)
                        && (
                        <a href="#map"> Sur la carte -
                        </a>
                        )}
                        <a href="#brief"> Programme détaillé -
                        </a>
                        <a href="#hotel"> Hébergéments -
                        </a>
                        <a href="#prix"> Devis
                        </a>
                      </div>
                      <br />
                      <br />
                      <div className="titre2" style={Colordefaut}>
                        Informations générales
                      </div>
                      <table className="tabInfo">
                        <tbody>
                          <tr>
                            <td>
                              {langageTranslation.groupName}: {travelerName}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {langageTranslation.textTravelerNumber}: {nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {langageTranslation.accomodationType}: {code_devis}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {langageTranslation.roomType}: {destination}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {langageTranslation.departureDate}: {dateDepart}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {desc_voyage && !_.isEmpty(desc_voyage) && (
                    <div>
                      <br />
                      <div className="titre2" style={Colordefaut}>
                        Introduction au circuit
                      </div>
                      <Interweave content={desc_voyage} />
                    </div>
                    )}
                    <div className="sautePage" />
                    <div className="CoupePasSense">
                      <div id="brief" className="titre2" style={Colordefaut}>
                        Itinéraire en bref
                      </div>
                      <br />
                      <div className="centreMoi">
                        B (breakfast = petit déjeuner), L (Lunch = déjeuner), D (dinner = diner)
                      </div>
                      <br />
                      <br />
                      <div>
                        <table
                          style={{
                            BorderRadius: '10px', overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                          }}
                          className="tabItineraireSenseGlobal"
                        >
                          <tbody>
                            <tr>
                              <th width="20%">Jour</th>
                              <th width="20%">Date</th>
                              <th width="50%">Destinations</th>
                              <th width="10%">Repas</th>

                            </tr>
                            {tripAllDays.map((item, index) => {
                              const IncDay = _.sum(TabIncJour.slice(0, index))
                              const datejour = tripDetails[0].date_depart
                                ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                                : ''
                              return (
                                <tr key={`Bref${item.id_journee}`}>
                                  <td width="20%">
                                    <a href={`#Jour${index}`}>
                                      Jour {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} to ${IncDay + TabIncJour[index]}`)}
                                    </a>
                                  </td>
                                  <td width="20%">
                                    <a href={`#Jour${index}`}>
                                      {datejour}
                                    </a>
                                  </td>
                                  <td width="50%">
                                    <a href={`#Jour${index}`}>
                                      {item.nom_journee}
                                    </a>
                                  </td>
                                  <td width="10%">
                                    <a href={`#Jour${index}`}>
                                      {tripPension[index].pension !== 'none' && tripPension[index].pension}
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <br />
                        <div>{resultat.nombreB} {langageTranslation.breakfast}, {resultat.nombreL} {langageTranslation.lunch}, {resultat.nombreD} {langageTranslation.dinner}, {resultat.nombreBR} {langageTranslation.brunch}</div>
                        <br />
                      </div>
                    </div>
                    <div className="sautePage" />
                    {indexDisplay !== 'HTMLWORD' && (tripDetails[0].image || tripDetails[0].map_localisation) && (
                      <div id="map" className="CoupePasSense">
                        {tripDetails[0].image
                          ? (
                            <div className="centreTabPrice" id="carte">
                              <div className="titre2" style={Colordefaut}>Itinéraire sur carte</div>
                              <br />
                              <img className="carte" style={borderRadius} width="500" height="850" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                            </div>
                          ) : (tripDetails[0].map_localisation && (
                          <div className="centreTabPrice" id="carte">
                            <div className="titre2" style={Colordefaut}>Itinéraire sur carte</div>
                            <br />
                            <img className="carte" style={borderRadius} width="700" height="850" src={urlMap} />
                          </div>
                          )
                          )}
                        <div className="sautePage" />
                      </div>
                    )}
                    <div id="detail" className="titre2" style={Colordefaut}>Programme détaillé de votre voyage</div>
                    <br />
                    <div>
                      {tripAllDays.map((item_jour, index) => {
                        const desc = `desc_jour_${index + 1}`;
                        const imageArray = item_jour.image.split(',')
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        const datejour = tripDetails[0].date_depart
                          ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(IncDay, 'd').format('ddd DD MMM'))
                          : ''
                        // eslint-disable-next-line no-nested-ternary
                        let guideNeed = ''
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = ''
                            break;
                          case '1':
                            guideNeed = langageTranslation.withGuide
                            break;
                          case '2':
                            guideNeed = langageTranslation.withGuideEn
                            break;
                          case '3':
                            guideNeed = langageTranslation.withGuideEnEvasion
                            break;
                          default:
                            guideNeed = ''
                        }
                        let driverNeed = ''
                        switch (chauffeurArray[index]) {
                          case '0':
                            driverNeed = ''
                            break;
                          case '1':
                            driverNeed = langageTranslation.driverEvasion
                            break;
                          case '2':
                            driverNeed = ''
                            break;
                          case '3':
                            driverNeed = 'Transport Locaux'
                            break;
                          case '4':
                            driverNeed = langageTranslation.busEvasion
                            break;
                          default:
                            driverNeed = ''
                        }
                        const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                        const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver

                        const BandeauJour = () => (
                          <div className="titreJournee">
                            <div style={Colordefaut} id={`Jour${index}`}> Jour {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} {tripDetails[0].date_depart && `(${datejour})`}: {item_jour.nom_journee}  {tripPension[index].pension !== 'none' && `(${tripPension[index].pension})`}  </div>
                          </div>
                        );

                        const TableService = () => (
                          <div className="marginDay">
                            {tripPension[index].pension !== 'none' && (<div>Repas: {tripPension[index].pension}</div>)}
                            {(tripAccomodation && tripAccomodation[index].nom_hebergement !== 'none')

                                                      && (
                                                      <div className="fontAccoColor">
                                                        {tripAccomodationOpt1 && tripAccomodationOpt1[index].nom_hebergement !== 'none' && 'Opt 1: '} Hébergement: {tripAccomodation[index].nom_hebergement !== 'none' && (`${tripAccomodation[index].nom_hebergement}${!_.isEmpty(tripAccomodation[index].categorie) ? `(${tripAccomodation[index].categorie})` : ''}`)}
                                                        {nbOfRoomLabel(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel(index)}`)}
                                                      </div>
                                                      )
                          }
                            {(tripAccomodationOpt1 && tripAccomodationOpt1[index].nom_hebergement !== 'none')

                          && (
                          <div className="fontAccoColor">
                            Hébergement: option 2:{tripAccomodationOpt1[index].nom_hebergement !== 'none' && (`${tripAccomodationOpt1[index].nom_hebergement}${!_.isEmpty(tripAccomodationOpt1[index].categorie) ? `(${tripAccomodationOpt1[index].categorie})` : ''}`)}
                            {nbOfRoomLabel_opt1(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel_opt1(index)}`)}
                          </div>
                          )
                          }
                            {(tripAccomodationOpt2 && tripAccomodationOpt2[index].nom_hebergement !== 'none')

                          && (
                          <div className="fontAccoColor">
                            Hébergement: option 3: {tripAccomodationOpt2[index].nom_hebergement !== 'none' && (`${tripAccomodationOpt2[index].nom_hebergement}${!_.isEmpty(tripAccomodationOpt2[index].categorie) ? `(${tripAccomodationOpt2[index].categorie})` : ''}`)}
                            {nbOfRoomLabel_opt2(index) && (!roomsNumberArray ? '' : `, ${nbOfRoomLabel_opt2(index)}`)}
                          </div>
                          )
                          }
                            <div>{asteriskText}</div>
                            {item_jour.distance && (<div>{item_jour.distance}</div>)}
                            <br />
                            <br />
                          </div>
                        );
                        return (
                          <div className="centreTabPrice" key={`${datejour}`}>
                            <div>
                              <div>
                                <div>
                                  <Grid container spacing={2} justifyContent="left">
                                    <div>
                                      <Grid className="CoupePasSense" item xs={12} lg={12} sm={12}>
                                        <BandeauJour />
                                      </Grid>
                                      <Grid item xs={12} lg={12} sm={12}>
                                        <div className="marginDay">
                                          <Interweave content={tripContent[0][desc]} />
                                        </div>
                                      </Grid>
                                      <Grid className="CoupePasSense" item xs={12} lg={12} sm={12}>
                                        <TableService />
                                      </Grid>
                                    </div>
                                  </Grid>
                                  {imageArray[0] !== '' && imageArray.length <= 2
                            && (
                            <Grid container spacing={0} justifyContent="center">
                              {imageArray.map((item, indexImage) => (
                                <Grid item xs={12} lg={6} sm={6}>
                                  <img className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`} src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`} />
                                </Grid>
                              ))}
                            </Grid>
                            )}
                                  {imageArray[0] !== '' && imageArray.length > 2
                            && (
                            <Grid container spacing={0} justifyContent="center">
                              {imageArray.map((item, indexImage) => (
                                <Grid item xs={12} lg={4} sm={4}>
                                  <img
                                    className={`${indexImage === 0 ? 'Image1Jour1Ciel' : 'Image1Jour1Ciel_plus'}`}
                                    src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                            )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <br />
                    <div>
                      {parseInt(roomAccoArray[0], 10) !== 1 && (
                      <div className="CoupePasSense">
                        <div id="hotel" className="titre2" style={Colordefaut}>LISTE DES HEBERGEMENTS</div>
                        <br />
                        <table
                          style={{
                            BorderRadius: '10px', overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                          }}
                          className="tabItineraireSenseGlobalHotel"
                        >
                          <tbody>
                            <tr>
                              <th width="20%">{langageTranslation.destination}</th>
                              <th width="30%">{langageTranslation.accomodation}</th>
                              {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <th width="10%">Check in</th>
                                    <th width="10%">Check out</th>
                                  </>
                                  )
                              }
                              <th width="30%">{langageTranslation.siteInternet}</th>
                            </tr>
                            {Hotel_Unique && Hotel_Unique.map((item, i) => {
                              const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                              const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');
                              const checkIn = item.reservedDays.map((rD, index) => {
                                const dateJour = tripDetails[0].date_depart
                                  ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD - 1, 'd').format('DD/MM'))
                                  : ''
                                const isConsecutive = rD && item.reservedDays[index - 1] === rD - 1;

                                // Si c'est consécutif, ne rien rendre
                                if (isConsecutive) {
                                  return null;
                                }

                                return (
                                  <div>{dateJour}</div>
                                )
                              })
                              const checkOut = item.reservedDays.map((rD, index) => {
                                const dateJour = tripDetails[0].date_depart
                                  ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD + item.day_duration - 1, 'd').format('DD/MM'))
                                  : ''
                                const isConsecutive = rD && item.reservedDays[index + 1] === rD + 1;

                                // Si c'est consécutif, ne rien rendre
                                if (isConsecutive) {
                                  return null;
                                }
                                return (
                                  <div>{dateJour}</div>
                                )
                              })
                              return (
                                item.nomHeb !== 'none'
                                && (
                                <tr key={`Acco${item.id_journee}`}>
                                  <td width="20%">
                                    <div>{LieuFinJournee.lieu}</div>
                                    <div>({Nombre_Occurence_Hotel[i].occurrence} {Nombre_Occurence_Hotel[i].occurrence === 1 ? langageTranslation.NIGHTSHOTEL : langageTranslation.NIGHTSHOTELS})</div>
                                  </td>
                                  <td width="30%">
                                    {item.nomHeb}
                                  </td>
                                  {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <td width="10%">{checkIn}</td>
                                    <td width="10%">{checkOut}</td>
                                  </>
                                  )
                                  }
                                  <td width="30%">
                                    <Interweave content={adresseHebClean} />
                                  </td>

                                </tr>
                                )
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      ) }
                      {parseInt(roomAcco1Array[0], 10) !== 1 && (
                        <div className="CoupePasSense">
                          <div className="titre2" style={Colordefaut}>LISTE DES HEBERGEMENTS option 2</div>
                          <br />
                          <table
                            style={{
                              BorderRadius: '10px', overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                            }}
                            className="tabItineraireSenseGlobalHotel"
                          >
                            <tbody>
                              <tr>
                                <th width="20%">{langageTranslation.destination}</th>
                                <th width="30%">{langageTranslation.accomodation}</th>
                                {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <th width="10%">Check in</th>
                                    <th width="10%">Check out</th>
                                  </>
                                  )
                                }
                                <th width="30%">{langageTranslation.siteInternet}</th>
                              </tr>
                              {Hotel_Unique && Hotel_Unique.map((item, i) => {
                                const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                                const adresseHebClean1 = item.adresse1.replace(/<p>/g, '').replace(/<\/p>/g, '');
                                const checkIn = item.reservedDays.map((rD, index) => {
                                  const dateJour = tripDetails[0].date_depart
                                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD - 1, 'd').format('DD/MM'))
                                    : ''
                                  const isConsecutive = rD && item.reservedDays[index - 1] === rD - 1;

                                  // Si c'est consécutif, ne rien rendre
                                  if (isConsecutive) {
                                    return null;
                                  }

                                  return (
                                    <div>{dateJour}</div>
                                  )
                                })
                                const checkOut = item.reservedDays.map((rD, index) => {
                                  const dateJour = tripDetails[0].date_depart
                                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD + item.day_duration - 1, 'd').format('DD/MM'))
                                    : ''
                                  const isConsecutive = rD && item.reservedDays[index + 1] === rD + 1;

                                  // Si c'est consécutif, ne rien rendre
                                  if (isConsecutive) {
                                    return null;
                                  }
                                  return (
                                    <div>{dateJour}</div>
                                  )
                                })
                                return (
                                  <tr key={`Acco${item.id_journee}`}>
                                    <td width="20%">
                                      <div>{LieuFinJournee.lieu}</div>
                                      <div>({Nombre_Occurence_Hotel[i].occurrence} {Nombre_Occurence_Hotel[i].occurrence === 1 ? langageTranslation.NIGHTSHOTEL : langageTranslation.NIGHTSHOTELS})</div>
                                    </td>
                                    <td width="30%">
                                      {item.nomHeb1}
                                    </td>
                                    {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <td width="10%">{checkIn}</td>
                                    <td width="10%">{checkOut}</td>
                                  </>
                                  )
                                  }
                                    <td width="30%">
                                      <Interweave content={adresseHebClean1} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) }
                      {parseInt(roomAcco2Array[0], 10) !== 1 && (
                        <div className="CoupePasSense">
                          <div className="titre2" style={Colordefaut}>LISTE DES HEBERGEMENTS option 3</div>
                          <br />
                          <table
                            style={{
                              BorderRadius: '10px', overflow: 'hidden', tableLayout: 'fixed;', width: '100%',
                            }}
                            className="tabItineraireSenseGlobalHotel"
                          >
                            <tbody>
                              <tr>
                                <th width="20%">{langageTranslation.destination}</th>
                                <th width="30%">{langageTranslation.accomodation}</th>
                                {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <th width="10%">Check in</th>
                                    <th width="10%">Check out</th>
                                  </>
                                  )
                                }
                                <th width="30%">{langageTranslation.siteInternet}</th>
                              </tr>
                              {Hotel_Unique && Hotel_Unique.map((item, i) => {
                                const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                                const adresseHebClean2 = item.adresse2.replace(/<p>/g, '').replace(/<\/p>/g, '');
                                const checkIn = item.reservedDays.map((rD, index) => {
                                  const dateJour = tripDetails[0].date_depart
                                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD - 1, 'd').format('DD/MM'))
                                    : ''
                                  const isConsecutive = rD && item.reservedDays[index - 1] === rD - 1;

                                  // Si c'est consécutif, ne rien rendre
                                  if (isConsecutive) {
                                    return null;
                                  }

                                  return (
                                    <div>{dateJour}</div>
                                  )
                                })
                                const checkOut = item.reservedDays.map((rD, index) => {
                                  const dateJour = tripDetails[0].date_depart
                                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD').locale(langage).add(rD + item.day_duration - 1, 'd').format('DD/MM'))
                                    : ''
                                  const isConsecutive = rD && item.reservedDays[index + 1] === rD + 1;

                                  // Si c'est consécutif, ne rien rendre
                                  if (isConsecutive) {
                                    return null;
                                  }
                                  return (
                                    <div>{dateJour}</div>
                                  )
                                })

                                return (
                                  <tr key={`Acco${item.id_journee}`}>
                                    <td width="10%">
                                      <div>{LieuFinJournee.lieu}</div>
                                      <div>({Nombre_Occurence_Hotel[i].occurrence} {Nombre_Occurence_Hotel[i].occurrence === 1 ? langageTranslation.NIGHTSHOTEL : langageTranslation.NIGHTSHOTELS})</div>
                                    </td>
                                    <td width="30%">
                                      {item.nomHeb2}
                                    </td>
                                    {tripDetails[0].date_depart
                                  && (
                                  <>
                                    <td width="10%">{checkIn}</td>
                                    <td width="10%">{checkOut}</td>
                                  </>
                                  )
                                  }
                                    <td width="30%">
                                      <Interweave content={adresseHebClean2} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) }
                      <br />
                    </div>
                    <br />
                    <div className="CoupePasSense">
                      <div id="prix" className="titre2" style={Colordefaut}>DEVIS DU CIRCUIT EN EUROS</div>
                      <div>
                        <div>
                          <Interweave content={tripBilling.tabPrice} />
                        </div>
                        <br />
                      </div>
                    </div>
                    <div>
                      <br />
                      <div className="CoupePasSense">
                        <div className="TitrePrixComprend">  {langageTranslation.priceContained}:  </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend} />
                        <br />
                      </div>
                      <div className="CoupePasSense">
                        <div className="TitrePrixComprendPas">  {langageTranslation.priceDoNotContained}:  </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend_pas} />
                      </div>
                    </div>
                    <br />


                    {indexDisplay !== 'HTMLWORD'
           && (
           <Grid container spacing={0} justifyContent="center">
             <Grid item xs={12}>
               <div id="info" className="centreTabPrice">
                 <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
               </div>
             </Grid>
           </Grid>
           )}
                    {(indexDisplay !== 'HTMLWORD') && (
                    <div id="video" className="videoBoxSense">
                      <Grid item xs={12}>
                        <div className="titre2">VIDEO</div>
                      </Grid>
                      <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item xs={12} lg={12} sm={12}>
                          <iframe className="VideoSense" width="324" height="238" src={centre_interet} title="Parfum d'automne" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                      </Grid>
                    </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      )
      return myPage
    }
    const headerHtml = renderToStaticMarkup(enTete)
    const footerHtml = renderToStaticMarkup(pied)
    const pageHtml = renderToStaticMarkup(myPageFunc('HTMLWEB'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));

    const urlBel = _.isEmpty(urlDevis) ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta name="viewport" content="width=device-width, initial-scale=1">
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlParfum.css">
     <title>Votre proposition de voyage</title>
     <style>    
  
    .centreTabPrice table {
      break-inside: avoid;
    }
      </style>
     </head>
     <body>
     <script>
     function toggleMenu() {
      var navbar = document.querySelector(".navbar");
      var iconMenu = document.querySelector(".iconMenu");
      navbar.classList.toggle("responsive");
      iconMenu.classList.toggle("responsive");
    }
    </script>
     <div class="page-header-sensehtml">   
       <div class="MuiGrid-root MuiGrid-container">
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-3" style="text-align: center; padding: 10px; display: flex; align-items: center;">
           <img src="${savedImgUrl}/${companyContent.company}/${companyContent.logo}" style="max-width: 100%; height: 60px; display: inline-block; margin: auto;">
       </div>
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
              <div class="centreSense padded">           
             ${travelerName ? `<div class="name">${travelerName}</div>` : ''}
               <div class="date-range" style="font-weight: 500;">Du ${dateDepart} au ${dateFin}</div>
              </div>
         </div>
         <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2">
           <div style="padding: 10px; display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
           <div class="iconMenu" onclick="toggleMenu()">
           <div style="background-color: black;" class="bar1"></div>
           <div style="background-color: black;" class="bar2"></div>
           <div style="background-color: black;" class="bar3"></div>
            </div>
           <a href="#" class="affiche-print" OnClick="javascript:window.print()">
           <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/cielyunnan/icons8-download-1704263805625.png" alt="Download Icon" style="width: 50px; height: 50px;">
               <div style="font-weight: bold; font-size: 10px; color: black":">Télécharger</div>
           </a> 
           </div>
       </div>           
       <div class="navbar">
         <a href="#home" class="animation-button" onclick="toggleMenu()">Home</a>
     <a href="#brief" class="animation-button" onclick="toggleMenu()">${langageTranslation.itinerary}</a>
     <a href="#hotel" class="animation-button" onclick="toggleMenu()">${langageTranslation.accomodation}</a>
     <a href="#prix" class="animation-button" onclick="toggleMenu()">${langageTranslation.priceNav}</a>
     <a href="#detail" class="animation-button" onclick="toggleMenu()">${langageTranslation.detailedProgram}</a>
     <a href="#info" class="animation-button" onclick="toggleMenu()">${langageTranslation.Terms}</a>
     <a href="#video" class="animation-button" onclick="toggleMenu()">Video</a>
    
         </div>
         </div>
      </div>    
        ${devisContent}
      </body>
   </html>
   `;
    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlParfum.css">
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>

<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
   
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
         <div style='mso-element:header' id="h1" >
         <p class="MsoHeader">
             <table border="0" width="100%">
                 <tr>
                     <td>
                     ${headerHtml}
                     </td>
                  </tr>
            </table>
        </p>
         </div>
            <div style='mso-element:footer' id="f1">
            <p class="MsoFooter">
                <table width="100%" >
                    <tr>
                        <td  class="footer">
                    ${footerHtml}
                        </td>
                    </tr>
                </table>
            </p>
        </div>
        </td>                         
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlParfum.css"
          height={1000}
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          handleEditorChange={content => this.handleEditorChange(content)}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  accomodationList: state.api.accomodationList,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripDevisFinal: state.api.tripDevisFinal,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlParfum))
