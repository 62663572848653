import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getTripExtensionResults,
  putTripExtensionResults,
} from './actions'

function* getTripExtensionAsync(params) {
  const { tripExtensionList } = params
  const data = yield call(api.get_trip_extension, tripExtensionList)
  yield put(getTripExtensionResults(data))
}

function* putTripExtensionAsync(params) {
  const {
    token,
    extension,
    idTour,
    idDemandeur,
    idTrip,
    action,
  } = params
  const data = yield call(api.put_trip_extension, token, extension, idTour, idDemandeur, idTrip, action)
  yield put(putTripExtensionResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_TRIP_EXTENSION', getTripExtensionAsync)
  yield takeEvery('PUT_TRIP_EXTENSION', putTripExtensionAsync)
}

export default fetchResult
