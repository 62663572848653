import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash'
import { Input } from '..'
import translateBoard from '../../generate/utils/translateBoard'

const langageArray = translateBoard.map(l => (
  {
    code: l.isoCode,
    name: l.nativeName,
  }
))

export default function SelectLangage(props) {
  const {
    langage,
    changeLangage,
  } = props;

  return (
    <Input
      id="langage"
      label="Langage"
      select
      value={langage}
      onChange={e => changeLangage(e)}
      margin="normal"
      variant="outlined"
      width="100%"
    >
      {_.map(langageArray, (l, k) => <MenuItem key={`langage-${k.toString()}`} value={l.code.toString()}>{l.name}</MenuItem>)}
    </Input>
  );
}

SelectLangage.propTypes = {
  langage: PropTypes.string.isRequired,
  changeLangage: PropTypes.func.isRequired,
};
