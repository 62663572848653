import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'

class TextEditorLine extends PureComponent {
  static propTypes = {
    object: PropTypes.object,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    handleEditorChange: PropTypes.func,
  }

  static defaultProps = {
    object: {},
    value: '',
    disabled: false,
    handleEditorChange: () => {},
  }

  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.state = {
      object: {},
      value: '',
    };
  }

  componentDidMount() {
    const {
      object,
      value,
    } = this.props
    this.setState({
      object,
      value,
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      object: objectOld,
      value: valueOld,
    } = this.state
    const {
      object,
      value,
    } = nextProps
    if (value !== valueOld || object !== objectOld) {
      this.setState({
        object,
        value,
      })
    }
  }

  handleEditorChange = (content) => {
    const {
      handleEditorChange,
    } = this.props
    handleEditorChange(content)
  }

  render() {
    const { value } = this.state
    const { disabled } = this.props
    return (
      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        value={value || ''}
        disabled={disabled}
        init={{
          height: 100,
          menubar: false,
          plugins: 'lists | link | code ',
          toolbar: 'undo redo | bold | italic | underline | forecolor | fontselect | fontsizeselect | link | code',
          font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Cambria=cambria,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
          content_style: 'body { font-family: Arial; }',
          default_link_target: '_blank',
          lineheight_formats: '0.3 0.5 1 1.1 1.2 1.3 1.4 1.5 2',
          paste_as_text: true,
        }}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}

export default TextEditorLine;
