import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import tableIcons from '../../../../components/utils/icons/icons'
import { CssGrid } from '../../../../configCSS'
import {
  Input,
} from '../../../../components'

const {
  Delete,
} = tableIcons


export default function DisplayListRoom(props) {
  const {
    roomByAccomodation,
    seasonByAccomodation,
    changeAccoRoom,
    changeAccoSeason,
    addSeasonLine,
    deleteDayLine,
    accoSelected,
  } = props
  const [checkListItem, setCheckListItem] = useState({})
  const [checkListItemUniq, setCheckListItemUniq] = useState({})
  const [checkSeasonItem, setCheckSeasonItem] = useState({})
  const [checkSeasonItemUniq, setCheckSeasonItemUniq] = useState({})
  const nombreSaison = seasonByAccomodation.length


  useEffect(() => {
    const newroomByAccomodation = _.cloneDeep(roomByAccomodation)
    setCheckListItem(newroomByAccomodation)
    if (!_.isEmpty(seasonByAccomodation)) {
      setCheckSeasonItem(seasonByAccomodation)
    }
  }, [accoSelected])


  useEffect(() => {
    if (checkListItemUniq) changeAccoRoom(checkListItemUniq)
    setCheckListItem(roomByAccomodation)
  }, [checkListItemUniq])

  useEffect(() => {
    if (checkSeasonItemUniq) changeAccoSeason(checkSeasonItemUniq)
    setCheckSeasonItem(seasonByAccomodation)
  }, [checkSeasonItemUniq])

  useEffect(() => {
    setCheckListItem(roomByAccomodation)
  }, [roomByAccomodation])

  useEffect(() => {
    if (seasonByAccomodation) setCheckSeasonItem(seasonByAccomodation)
  }, [seasonByAccomodation])


  const handleComment = (e, id) => {
    const { value, name } = e.target
    setCheckListItemUniq({
      ...checkListItem[id],
      [name]: value,
    })
  }
  const handleSeason = (e, id) => {
    const {
      value, name,
    } = e.target


    setCheckSeasonItemUniq({
      ...checkSeasonItem[id],
      [name]: value,
    })
  }


  return (
    <div>
      <div>
        <CssGrid item md={12} xs={12}>
          <h2>Room prices</h2>
          {!_.isEmpty(checkListItem) && roomByAccomodation.map((item, index) => (
            <div key={`${item.id_heb_ch}room`}>
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={3} xs={12}>
                  <Input
                    value={item.type_chambre}
                    margin="normal"
                    variant="outlined"
                  />
                </CssGrid>
                <CssGrid item md={3} xs={12}>
                  <Input
                    name="theme_chambre"
                    label="type"
                    select
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(item) && item.theme_chambre ? item.theme_chambre : 0}
                    onChange={e => handleComment(e, index)}
                  >
                    <MenuItem value="0">Room</MenuItem>
                    <MenuItem value="1">Transportation</MenuItem>
                    <MenuItem value="2">Service</MenuItem>
                  </Input>
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price standard season"
                    name="price_peakseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(item) && item.price_peakseason ? item.price_peakseason : ''}
                    onChange={e => handleComment(e, index)}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price high season"
                    type="number"
                    name="price_highseason"
                    value={!_.isEmpty(item) && item.price_highseason ? item.price_highseason : ''}
                    margin="normal"
                    variant="outlined"
                    onChange={e => handleComment(e, index)}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price low season"
                    name="price_lowseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(item) && item.price_lowseason ? item.price_lowseason : ''}
                    onChange={e => handleComment(e, index)}
                  />
                </CssGrid>

              </CssGrid>
            </div>


          ))}
          <div>(save data to see the last room added)</div>
        </CssGrid>
        <CssGrid item md={12} xs={12}>
          <h2>Season interval</h2>
          {!_.isEmpty(checkSeasonItem) && checkSeasonItem.map((item, index) => (
            <div key={`${item.id_heb_sea}season`}>

              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={3} xs={12}>
                  <Input
                    name="period_name"
                    label="period_name"
                    select
                    required
                    className="enterSeason"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(item) && item.period_name ? item.period_name : ''}
                    onChange={e => handleSeason(e, index)}
                  >
                    <MenuItem value="lowseason">low season</MenuItem>
                    <MenuItem value="highseason">high season</MenuItem>
                    <MenuItem value="peakseason">standard season</MenuItem>
                  </Input>
                </CssGrid>
                <CssGrid item md={3} xs={12}>
                  <Input
                    InputProps={{
                      inputProps: {
                        min: '2022-01-01',
                        max: '2022-12-31',
                        format: 'MM-DD',
                        title: 'Select a date between January 1st and December 31st',
                      },
                    }}
                    type="date"
                    className="enterSeason"
                    name="start_date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    label="date from"
                    onChange={e => handleSeason(e, index)}
                    value={!_.isEmpty(item) && item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                    margin="normal"
                    variant="outlined"
                  />

                </CssGrid>
                <CssGrid item md={3} xs={12}>
                  <Input
                    InputProps={
                          !_.isEmpty(item) && item.start_date && item.start_date !== '0000-00-00 00:00:00'
                            ? { inputProps: { min: item.start_date, max: '2022-12-31', title: `Select a date between ${moment(item.start_date).format('YYYY-MM-DD')} and December 31st` } }
                            : { inputProps: { min: '2022-01-01', max: '2022-12-31', title: 'Select a date between January 1st and December 31st' } }
                        }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => handleSeason(e, index)}
                    value={!_.isEmpty(item) && item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                    required
                    className="enterSeason"
                    type="date"
                    label="to"
                    format="MM-DD"
                    name="end_date"
                    margin="normal"
                    variant="outlined"
                  />
                </CssGrid>
                {nombreSaison === index + 1
                  && (
                  <CssGrid item md={3} xs={12}>
                    <Tooltip title="Delete" className="tooltip-season">
                      <Delete
                        onClick={() => deleteDayLine(item.id_heb_sea)}
                      />
                    </Tooltip>
                  </CssGrid>
                  )
}
              </CssGrid>
            </div>
          ))}
          <div className="addSeason">
            <Fab
              aria-label="Add"
              size="large"
              name={accoSelected}
               // disabled={(!_.isEmpty(items) && !items[0].title) || isActionButtonDisabled}
              onClick={() => addSeasonLine(accoSelected)}
            >
              <AddIcon />
            </Fab>
          </div>
        </CssGrid>
      </div>
    </div>
  );
}

DisplayListRoom.propTypes = {
  roomByAccomodation: PropTypes.array,
  seasonByAccomodation: PropTypes.array,
  addSeasonLine: PropTypes.func.isRequired,
  deleteDayLine: PropTypes.func.isRequired,
  changeAccoRoom: PropTypes.func.isRequired,
  changeAccoSeason: PropTypes.func.isRequired,
  accoSelected: PropTypes.number,

};

DisplayListRoom.defaultProps = {
  roomByAccomodation: [],
  seasonByAccomodation: [],
  accoSelected: null,
};
