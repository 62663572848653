import React from 'react';
import PropTypes from 'prop-types';

import backButton from '../../assets/images/backButton.png'
import backButton2x from '../../assets/images/backButton@2x.png'
import backButton3x from '../../assets/images/backButton@3x.png'

export default function BackButton(props) {
  const {
    type,
    label,
    goBack,
    hidden,
  } = props;

  return (
    <div className={`back-button-div ${hidden}`} onClick={() => goBack()}>
      <a
        href="#"
        className="back-button-label"
        type={type}
      >
        {label}
      </a>
      <img className="back-button-img" src={backButton} srcSet={`${backButton2x} 2x, ${backButton3x} 3x`} alt="back button" />
    </div>
  );
}

BackButton.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  hidden: PropTypes.string,
}

BackButton.defaultProps = {
  hidden: null,
}
