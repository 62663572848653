import {
  GET_COMPANY_CONTENT_RESULTS,
  PUT_COMPANY_CONTENT_RESULTS,
  // DELETE_COMPANY_CONTENT_RESULTS,
  RESET_COMPANY_CONTENT,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  companyContent: {},
  putCompanyContent: { success: false },
  // deleteCompanyContent: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_COMPANY_CONTENT_RESULTS:
      return {
        ...state,
        companyContent: action.companyContent[0],
      }
    case PUT_COMPANY_CONTENT_RESULTS:
      return {
        ...state,
        putCompanyContent: action.putCompanyContent,
      }
    // case DELETE_COMPANY_CONTENT_RESULTS:
    //   return {
    //     ...state,
    //     deleteCompanyContent: action.deleteCompanyContent,
    //   }
    case RESET_COMPANY_CONTENT:
      return {
        ...state,
        putCompanyContent: { success: false },
        // deleteCompanyContent: { success: false },
      }
    default:
      return state
  }
}
