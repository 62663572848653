/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import _ from 'lodash'
import Geocode from 'react-geocode';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';


import {
  Template,
  Input,
  ActionButton,
  // FabDelete,
  Uploader,
  ModalComponent,
  TextEditor,
  BackButton,
} from '../../../components'
import stage from '../../../config'
import { CssGrid, CssCheckbox } from '../../../configCSS'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import tableIcons from '../../../components/utils/icons/icons'


const {
  Delete,
} = tableIcons

const savedImgUrl = stage.imgStorage
const locationFormatRegExp = new RegExp(' ', 'g')

class Activity extends PureComponent {
  static propTypes = {
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    GET_SUPPLIERS_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    PUT_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    DELETE_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_SEASON_BY_ACTIVITY: PropTypes.func.isRequired,
    GET_SEASON_BY_ACTIVITY: PropTypes.func.isRequired,
    DELETE_SEASON_BY_ACTIVITY: PropTypes.func.isRequired,
    PUT_SEASON_BY_ACTIVITY: PropTypes.func.isRequired,
    deleteSeasonByActivity: PropTypes.object,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    putActivityContent: PropTypes.object,
    deleteActivityContent: PropTypes.object,
    putSeasonByActivity: PropTypes.object,
    putLocationContent: PropTypes.object,
    locationList: PropTypes.array,
    locationGrandList: PropTypes.array,
    history: PropTypes.object.isRequired,
    activityList: PropTypes.array,
    seasonByActivity: PropTypes.array,
    companyContent: PropTypes.object,
    suppliersList: PropTypes.array,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    suppliersList: [],
    userAccount: {},
    locationList: [],
    locationGrandList: [],
    activityList: [],
    seasonByActivity: [],
    putActivityContent: {},
    putLocationContent: {},
    putSeasonByActivity: {},
    deleteActivityContent: {},
    deleteSeasonByActivity: {},
    companyContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.findIdLocation = this.findIdLocation.bind(this)
    this.displayAutocompleteActivity = this.displayAutocompleteActivity.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.displayAutocompleteLocationGrand = this.displayAutocompleteLocationGrand.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayActivityInputs = this.displayActivityInputs.bind(this)
    this.displayActivityTheme = this.displayActivityTheme.bind(this)
    this.onChangeActivity = this.onChangeActivity.bind(this)
    this.onChangeLocationGrand = this.onChangeLocationGrand.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.onChangeSupplier = this.onChangeSupplier.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleCheckChange = this.handleCheckChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.saveSeason = this.saveSeason.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.addSeasonLine = this.addSeasonLine.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.goToSupplier = this.goToSupplier.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.validate = this.validate.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItemTitle = this.modifyItemTitle.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleSeason = this.handleSeason.bind(this)
    this.deleteDayLine = this.deleteDayLine.bind(this)
    this.displayActivityByTab = this.displayActivityByTab.bind(this)
    this.onChangeActivityTheme = this.onChangeActivityTheme.bind(this)
    this.displayAutocompleteActivityTheme = this.displayAutocompleteActivityTheme.bind(this)
    this.displayAutocompleteSupplier = this.displayAutocompleteSupplier.bind(this)
    this.getOptionLabelLocation = this.getOptionLabelLocation.bind(this)
    this.getOptionLabelLocationGrand = this.getOptionLabelLocationGrand.bind(this)
    this.getOptionLabelSupplier = this.getOptionLabelSupplier.bind(this)
    this.filterOptionsLocation = this.filterOptionsLocation.bind(this)
    this.filterOptionsSupplier = this.filterOptionsSupplier.bind(this)


    this.state = {
      activityList: [],
      activityContent: {
        locationGrand: '',
        location: '',
        activity_theme: '',
        id_activity_theme: '',
        id_supplier: '',
      },
      itemsGoBack: '',
      isValidated: true,
      isNewActivity: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      newImageArray: [],
      newURLArray: [],
      isModalOpen: false,
      actionLabel: 'Create',
      isModalOpenSupplier: false,
      supplierToInsert: '',
      btnActionDisabled: false,
      indexInTripActivity: null,
      tripActivity: null,
      goBack: null,
      seasonByActivity: [],
      suppliersList: [],
      initialSeasonByActivity: [],
      sourcePage: 'activities-table',
      themeList: [
        { id_activity_theme: 1, activity_theme: 'Accomodation' },
        { id_activity_theme: 2, activity_theme: 'Food' },
        { id_activity_theme: 3, activity_theme: 'Transportation' },
        { id_activity_theme: 4, activity_theme: 'Activity' },
        { id_activity_theme: 5, activity_theme: 'Service guide' },
        { id_activity_theme: 6, activity_theme: 'Flight' },
        { id_activity_theme: 7, activity_theme: 'Extra' },
        { id_activity_theme: 8, activity_theme: 'Other' },
        { id_activity_theme: 9, activity_theme: 'Multiple' },
        { id_activity_theme: 10, activity_theme: 'Distance' },
      ],
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      activityList,
      companyContent,
      locationList,
      GET_ACTIVITY_LIST,
      GET_LOCATION_LIST,
      GET_SUPPLIERS_LIST,
      GET_SEASON_BY_ACTIVITY,
      GET_COMPANY_CONTENT,
      RESET_SEASON_BY_ACTIVITY,
      suppliersList,
      RESET_ACTIVITY_CONTENT,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    RESET_ACTIVITY_CONTENT()
    RESET_SEASON_BY_ACTIVITY()
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'activity') {
        const {
          state,
          currentImg,
          pictureSelected,
        } = history.location.state
        this.setState({
          activityContent: {
            ...state.item,
            image: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          isNewActivity: state.isNewActivity,
          actionLabel: state.isNewActivity ? 'Create' : 'Modify',
          indexInTripActivity: state.indexInTripActivity,
          tripActivity: state.tripActivity,
          goBack: state.goBack,
        })
      }
      if (currentPage === 'tripActivity') {
        const {
          newActivity,
          index,
          tripActivity,
        } = history.location.state
        const {
          activity,
          location,
          id_location,
        } = newActivity
        this.setState({
          activityContent: {
            activity,
            location,
            id_location: id_location || this.findIdLocation(location),
          },
          isNewActivity: true,
          actionLabel: 'Create',
          indexInTripActivity: index,
          tripActivity,
          goBack: currentPage,
        })
      }
    }
    // access form supplier
    if (history.location.state && history.location.state.currentPage === 'suppliers') {
      const {
        itemsGoBack,
        idSupplierNew,
        isNewActivityBack,
      } = history.location.state

      this.setState({
        activityContent: {
          ...itemsGoBack,
          id_supplier: idSupplierNew,
        },
        isNewActivity: isNewActivityBack,
      })
    }
    // access from activities table for editing mode
    if (history.location.state.source === 'activities-table') {
      const {
        idActivity,
      } = history.location.state
      if (idActivity) {
        const dayToActivity = activityList.filter(d => d.id_activity === idActivity)[0]
        this.setState({
          activityContent: dayToActivity,
          actionLabel: 'Modify',
          sourcePage: 'activities-table',
        })
      }
      GET_SEASON_BY_ACTIVITY(userId, idActivity)
    }

    // access from suppliers for editing mode
    if (history.location.state && history.location.state.goBack === 'suppliers') {
      const {
        idActivity,
        suppliersContent,
      } = history.location.state
      if (idActivity) {
        const dayToActivity = activityList.filter(d => d.id_activity === idActivity)[0]
        this.setState({
          activityContent: dayToActivity,
          actionLabel: 'Modify',
          sourcePage: 'suppliers',
          itemsGoBack: suppliersContent,
        })
        GET_SEASON_BY_ACTIVITY(userId, idActivity)
      } else {
        this.setState({
          sourcePage: 'suppliers',
          itemsGoBack: suppliersContent,
          activityContent: {
            id_supplier: suppliersContent.id_supplier,
            supplier: suppliersContent.supplier,
            id_location: suppliersContent.id_lieu,
            location: suppliersContent.location,
            id_location_grand: suppliersContent.id_lieu_grand,
            locationGrand: suppliersContent.locationGrand,
            phone: suppliersContent.phone,
            email: suppliersContent.email,
            activity_type: suppliersContent.activity_type,
            currency: suppliersContent.currency,
            activity_theme: suppliersContent.activity_theme !== 'Multi' ? suppliersContent.activity_theme : '',
            id_activity_theme: suppliersContent.id_activity_theme !== 9 ? suppliersContent.id_activity_theme : '',

          },
        })
      }
    }

    // check activity list
    if (!_.isEmpty(activityList)) {
      this.setState({
        activityList: _.sortBy(activityList, o => o.activity),
      })
    } else {
      GET_ACTIVITY_LIST(userId)
    }
    if (_.isEmpty(companyContent)) {
      GET_COMPANY_CONTENT(userId)
    }
    if (_.isEmpty(locationList)) {
      GET_LOCATION_LIST(userId)
    }
    if (!_.isEmpty(suppliersList)) {
      this.setState({
        suppliersList: _.sortBy(suppliersList, o => o.supplier),
      })
    } else {
      GET_SUPPLIERS_LIST(userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      userId,
      activityList: activityListOld,
      seasonByActivity: seasonByActivityOld,
      activityContent,
      btnActionDisabled,
      goBack,
      indexInTripActivity,
      tripActivity,
    } = this.state
    const { history } = this.props
    const {
      activityList,
      putLocationContent,
      putActivityContent,
      deleteActivityContent,
      RESET_LOCATION_CONTENT,
      putSeasonByActivity,
      seasonByActivity,
      deleteSeasonByActivity,
      GET_LOCATION_LIST,
      RESET_ACTIVITY_CONTENT,
      RESET_SEASON_BY_ACTIVITY,
      GET_SEASON_BY_ACTIVITY,
    } = nextProps

    // location saved in DB -> get back updated list
    if (putLocationContent && putLocationContent.success) {
      RESET_LOCATION_CONTENT()
      GET_LOCATION_LIST(userId)
      this.setState({
        activityContent: {
          ...activityContent,
          id_location: putLocationContent.id,
        },
        btnActionDisabled: !btnActionDisabled,
        validMsg: 'Location was successfully recorded !',
        isValidated: false,
      })
      this.resetValidMsg(2000)
    }
    // Activity saved in DB -> get back updated list
    if (putActivityContent && putActivityContent.success) {
      this.setState({
        activityContent: {
          ...activityContent,
          id_activity: putActivityContent.id,
        },
        validMsg: 'Activity was successfully recorded !',
        isValidated: true,
        isNewActivity: false,
      })

      this.reload()
      this.resetValidMsg(2000)
      if (activityContent) GET_SEASON_BY_ACTIVITY(userId, activityContent.id_activity)

      // redirect to tripActivity
      if (goBack) {
        tripActivity[indexInTripActivity] = { id_activity: putActivityContent.id, id_lieu: activityContent.id_location, ...activityContent }
        setTimeout(() => {
          history.push(goBack, { currentPage: 'tripActivity', tripActivity })
        }, 2500)
      }
      // }
    }

    if (seasonByActivity !== seasonByActivityOld) {
      this.setState({ seasonByActivity })
    }
    this.setState({ initialSeasonByActivity: seasonByActivity })

    // activity delete in DB -> get back updated list
    if (deleteActivityContent && deleteActivityContent.success) {
      this.reload()
      this.setState({
        activityContent: {},
        isValidated: true,
        validMsg: 'Activity was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // add or delete some season for activity get back updated list
    if ((putSeasonByActivity && putSeasonByActivity.success) || (deleteSeasonByActivity && deleteSeasonByActivity.success)) {
      RESET_SEASON_BY_ACTIVITY()
      if (activityContent) GET_SEASON_BY_ACTIVITY(userId, activityContent.id_activity)
    }
    // activity can not be delete in DB -> it is used yet in route
    if (deleteActivityContent && !deleteActivityContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteActivityContent.message,
      })
      RESET_ACTIVITY_CONTENT()
    }
    if (activityList !== activityListOld) {
      this.setState({
        activityList: _.sortBy(activityList, o => o.activity),
      })
    }
  }

  resetValidMsg = (t = 3000) => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, t)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_ACTIVITY_CONTENT,
      GET_ACTIVITY_LIST,
    } = this.props
    RESET_ACTIVITY_CONTENT()
    GET_ACTIVITY_LIST(userId)
    this.setState({ validateError: false })
  }

  findIdLocation = (location) => {
    const { locationList } = this.props
    return locationList.filter(l => l.lieu === location)[0].id_lieu
  }

  // ********************************
  // On change supplier
  onChangeSupplier = (event, newValue) => {
    const { activityContent } = this.state
    const {
      suppliersList,
    } = this.props
    const isActivityEmpty = activityContent ? !activityContent.activity : true
    // on press Enter
    if (typeof newValue === 'string') {
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const SupplierObject = suppliersList.filter(s => s.supplier === valueClean)[0]
      const idSupplier = SupplierObject && SupplierObject.id_supplier ? SupplierObject.id_supplier : null

      if (!idSupplier) {
        this.setState({
          supplierToInsert: valueClean,
          isModalOpenSupplier: true,
        })
      }

      if (_.isEmpty(SupplierObject)) {
        this.setState({
          activityContent: {
            ...activityContent,
            id_supplier: idSupplier,
            supplier: valueClean,
          },
          isValidated: isActivityEmpty,
          isNewActivity: false,
        });
      } else {
        this.setState({
          activityContent: {
            ...activityContent,
            id_supplier: SupplierObject.id_supplier,
            supplier: SupplierObject.supplier,
          },
          isValidated: isActivityEmpty,
        })
      }
    } else if (!newValue) {
      // clear input location
      this.setState({
        activityContent: {
          ...activityContent,
          id_supplier: null,
          supplier: '',
        },
        isValidated: isActivityEmpty,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        activityContent: {
          ...activityContent,
          id_supplier: null,
          supplier: newValue.inputValue,
        },
        isValidated: isActivityEmpty,
        supplierToInsert: newValue.inputValue,
        isModalOpenSupplier: true,
      });
    } else {
      this.setState({
        activityContent: {
          ...activityContent,
          id_supplier: newValue.id_supplier,
          supplier: newValue.supplier,
        },
        isValidated: isActivityEmpty,
      })
    }
  }

  filterOptionsSupplier = (options, params) => {
    const { suppliersList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(suppliersList, d => d.supplier.indexOf(valueClean) > -1)
    const valueExist = suppliersList.filter(l => l.supplier === valueClean)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        supplier: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }

  getOptionLabelSupplier = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.supplier) {
      return ''
    }
    // Regular option
    return option.supplier;
  }

  displayAutocompleteSupplier = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeSupplier}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={this.filterOptionsSupplier}
        options={list}
        getOptionLabel={this.getOptionLabelSupplier}
        renderOption={option => option.supplier}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

  // ********************************
  // On change activity
  onChangeActivity = (event, newValue) => {
    const {
      isValidated,
      activityList,
      activityContent,
      userId,
    } = this.state
    const {
      GET_SEASON_BY_ACTIVITY,
    } = this.props
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = activityList.filter(d => d.activity === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          activityContent: {
            id_activity: null,
            activity: newValue,
            image: '',
          },
          actionLabel: 'Create',
          seasonByActivity: [],
          isNewActivity: true,
          newImageArray: [],
          newURLArray: [],
          isValidated: false,
        });
      } else {
        this.setState({
          activityContent: valueExist[0],
          actionLabel: 'Modify',
          seasonByActivity: [],
          newImageArray: [],
          newURLArray: [],
          isNewActivity: false,
          isValidated: true,
        });
        GET_SEASON_BY_ACTIVITY(userId, valueExist[0].id_activity)
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        activityContent: {
          id_activity: null,
          activity: newValue.inputValue,
          image: '',
          id_supplier: activityContent && activityContent.id_supplier ? activityContent.id_supplier : '',
          supplier: activityContent && activityContent.supplier ? activityContent.supplier : '',
          id_location: activityContent && activityContent.id_location ? activityContent.id_location : '',
          location: activityContent && activityContent.location ? activityContent.location : '',
          id_location_grand: activityContent && activityContent.id_location_grand ? activityContent.id_location_grand : '',
          locationGrand: activityContent && activityContent.locationGrand ? activityContent.locationGrand : '',
          phone: activityContent && activityContent.phone ? activityContent.phone : '',
          email: activityContent && activityContent.email ? activityContent.email : '',
          activity_theme: activityContent && activityContent.activity_theme ? activityContent.activity_theme : '',
          activity_type: activityContent && activityContent.activity_type ? activityContent.activity_type : '',
          currency: activityContent && activityContent.currency ? activityContent.currency : '',
          id_activity_theme: activityContent && activityContent.id_activity_theme ? activityContent.id_activity_theme : '',
        },
        newImageArray: [],
        newURLArray: [],
        actionLabel: 'Create',
        isNewActivity: true,
        seasonByActivity: [],
        isValidated: false,
      });
    } else if (!newValue) {
      // clear input location
      this.setState({
        activityContent: {
          id_supplier: null,
          location: '',
          locationGrand: '',
          activity_theme: '',
          id_activity_theme: '',
        },
      });
    } else {
      this.setState({
        activityContent: newValue,
        actionLabel: 'Modify',
        seasonByActivity: [],
        newImageArray: [],
        newURLArray: [],
        isNewActivity: false,
        isValidated: true,
      });
      if (newValue) { GET_SEASON_BY_ACTIVITY(userId, newValue.id_activity) }
    }
    return null
  }

  filterOptionsActivity = (options, params) => {
    const { activityList, activityContent } = this.state
    const location = activityContent && activityContent.location ? activityContent.location : '';
    const locationGrand = activityContent && activityContent.locationGrand ? activityContent.locationGrand : '';
    const activityTheme = activityContent && activityContent.id_activity_theme ? activityContent.id_activity_theme : '';
    const supplierId = activityContent && activityContent.id_supplier ? activityContent.id_supplier : '';
    const selectableActivityList = activityList.filter((supplier) => {
      const locationMatch = (location === 'none' || location === '') || supplier.location === location;
      const regionMatch = (locationGrand === 'none' || locationGrand === '') || supplier.locationGrand === locationGrand;
      const activityThemeMatch = activityTheme === '' || supplier.id_activity_theme === activityTheme;
      const supplierMatch = supplierId === '' || supplier.id_supplier === supplierId;

      return supplierMatch && activityThemeMatch && locationMatch && regionMatch;
    });
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = selectableActivityList.filter(d => d.activity === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        activity: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelActivity = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.activity) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.activity;
  }

  // On change location
  onChangeLocation = (event, newValue) => {
    const { activityContent } = this.state
    const {
      locationList,
    } = this.props
    const isActivityEmpty = activityContent ? !activityContent.activity : true
    // on press Enter
    if (typeof newValue === 'string') {
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const locationObject = locationList.filter(l => l.location === valueClean)[0]
      const idLocation = locationObject && locationObject.id_lieu ? locationObject.id_lieu : null

      if (!idLocation) this.createNewLocation({ lieu: valueClean })

      if (_.isEmpty(locationObject)) {
        this.setState({
          activityContent: {
            ...activityContent,
            id_location: idLocation,
            location: valueClean,
          },
          isValidated: isActivityEmpty,
          isNewActivity: false,
        });
      } else {
        this.setState({
          activityContent: {
            ...activityContent,
            id_location: locationObject.id_lieu,
            location: locationObject.lieu,
          },
          isValidated: isActivityEmpty,
        })
      }
    } else if (!newValue) {
      // clear input location
      this.setState({
        activityContent: {
          ...activityContent,
          id_location: null,
          location: '',
        },
        isValidated: isActivityEmpty,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        activityContent: {
          ...activityContent,
          id_location: null,
          location: newValue.inputValue,
        },
        isValidated: isActivityEmpty,
      });
      // save new location
      this.createNewLocation({ lieu: newValue.inputValue })
    } else {
      this.setState({
        activityContent: {
          ...activityContent,
          id_location: newValue.id_lieu,
          location: newValue.lieu,
        },
        isValidated: isActivityEmpty,
      })
    }
  }

  filterOptionsLocation = (options, params) => {
    const { locationList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(l => l.lieu === valueClean)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }

  goBack = () => {
    const { history } = this.props
    const {
      sourcePage,
      itemsGoBack,
    } = this.state


    if (sourcePage) history.push(sourcePage, { itemsGoBack, goBack: 'activityFromSupplier' })
  }


  getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return ''
    }
    // Regular option
    return option.location;
  }

  getOptionLabelLocationGrand = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.locationGrand) {
      return ''
    }
    // Regular option
    return option.locationGrand;
  }

  getOptionLabelActivityTheme = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.activity_theme) {
      return ''
    }
    // Regular option
    return option.activity_theme;
  }


  // ************************************************
  // On change Editor
  handleEditorChange = (field, content) => {
    const { activityContent } = this.state
    const { userAccount } = this.props

    const description = userAccount.language ? `${userAccount.language}_description` : 'fr_description'

    if (activityContent && content !== activityContent[description]) {
      this.setState({
        activityContent: {
          ...activityContent,
          [field]: content,
        },
        validateError: false,
        errorMsg: null,
        isValidated: false,
      });
    }
  }

  // save input change
  handleChange = field => (event) => {
    const { activityContent } = this.state
    this.setState({
      activityContent: {
        ...activityContent,
        [field]: event.target.value,
      },
      isValidated: false,
    })
  }


  onChangeLocationGrand = (event, value) => {
    const { activityContent } = this.state
    // find the global object from this value
    this.setState({
      activityContent: {
        ...activityContent,
        id_location_grand: value ? value.id_lieu_grand : '',
        locationGrand: value ? value.locationGrand : '',
      },
      isValidated: false,
    })
  }


  onChangeActivityTheme = (event, value) => {
    const { activityContent } = this.state
    // find the global object from this value
    this.setState({
      activityContent: {
        ...activityContent,
        activity_theme: value ? value.activity_theme : '',
        activity_type: value && value.id_activity_theme === 10 ? 'group' : 'individual',
      },
      isValidated: false,
    })
  }
  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = () => {
    const { companyContent } = this.props
    const { company } = companyContent
    const {
      activityContent,
      newURLArray,
      newImageArray,
    } = this.state
    const image = activityContent && activityContent.image ? activityContent.image : null
    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${savedImgUrl}/${company}/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
      validateError: false,
      errorMsg: null,
      isValidated: false,
    });
  }

  deleteImage = (urlArray, imageArray) => {
    const { activityContent } = this.state
    this.setState({
      activityContent: {
        ...activityContent,
        image: imageArray.join(),
      },
      newImageArray: imageArray,
      newURLArray: urlArray,
      isValidated: false,
    })
  }

  // function add a new season period
  addSeasonLine = (e) => {
    const { seasonByActivity } = this.state
    const newSeason = {
      id_act_sea: 1,
      id_activity: e,
      period_name: '',
      start_date: '',
      end_date: '',
    }
    if (!e) {
      alert('Please first save the Activity')
    } else if (!_.isEmpty(seasonByActivity)) {
      const oneSeasonMore = _.clone(seasonByActivity)
      const nombreSaison = oneSeasonMore.length
      const today = seasonByActivity[nombreSaison - 1].end_date
      oneSeasonMore.push({
        ...newSeason,
        start_date: moment(today).add(1, 'days').format('YYYY-MM-DD'),
        id_act_sea: Math.max(...seasonByActivity.map(item => item.id_act_sea)) + 1,
      })
      this.setState({
        seasonByActivity: oneSeasonMore,
      })
    } else {
      newSeason.start_date = '2022-01-01'
      newSeason.end_date = '2022-01-01'
      const oneSeasonMore = _.clone(seasonByActivity)
      oneSeasonMore.push(newSeason)
      this.setState({
        seasonByActivity: oneSeasonMore,
      })
    }
  }

  redirectToGallery = () => {
    const {
      activityContent,
      isNewActivity,
      actionLabel,
      indexInTripActivity,
      tripActivity,
      goBack,
    } = this.state
    const {
      history,
    } = this.props
    this.setState({
      isValidated: true,
    }, () => {
      history.push('gallery', {
        currentPage: 'activity',
        isSelectionAction: true,
        nbMaxImg: 2,
        currentImg: activityContent.image ? activityContent.image.split(',') : [],
        state: {
          item: activityContent,
          isNewActivity,
          actionLabel,
          indexInTripActivity,
          tripActivity,
          goBack,
        },
      })
    })
  }


  // ***************************
  // Display input

  displayActivityByTab = () => {
    const {
      activityContent,
      activityList,
    } = this.state

    const location = activityContent && activityContent.location ? activityContent.location : '';
    const locationGrand = activityContent && activityContent.locationGrand ? activityContent.locationGrand : '';
    const activityTheme = activityContent && activityContent.id_activity_theme ? activityContent.id_activity_theme : '';
    const supplierId = activityContent && activityContent.id_supplier ? activityContent.id_supplier : '';
    const selectableActivityList = activityList.filter((supplier) => {
      const locationMatch = (location === 'none' || location === '') || supplier.location === location;
      const regionMatch = (locationGrand === 'none' || locationGrand === '') || supplier.locationGrand === locationGrand;
      const activityThemeMatch = activityTheme === '' || supplier.id_activity_theme === activityTheme;
      const supplierMatch = supplierId === '' || supplier.id_supplier === supplierId;

      return supplierMatch && activityThemeMatch && locationMatch && regionMatch;
    });

    return (
      <div>

        { this.displayActivityTheme() }
        {this.displayAutocompleteActivity('activity', activityContent, selectableActivityList)}
        { this.displayActivityInputs() }
      </div>
    )
  }

  displayAutocompleteActivity = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item sm={10} xs={12}>
          <Autocomplete
            id={`${field}-field`}
            value={content}
            onChange={this.onChangeActivity}
            filterOptions={this.filterOptionsActivity}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelActivity}
            renderOption={option => option[field]}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Modify title" aria-label="modify" onClick={this.modifyItemTitle}>
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Delete" aria-label="delete" onClick={this.deleteItem}>
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  handleSeason = (e, id) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const newSeasonByActivity = prevState.seasonByActivity.map((item, index) => {
        if (index === id) {
          return { ...item, [name]: value };
        }
        return item;
      });
      return { seasonByActivity: newSeasonByActivity };
    });
  };


   handleCheckChange = field => (event) => {
     const { activityContent } = this.state
     this.setState({
       activityContent: {
         ...activityContent,
         [field]: event.target.checked,
       },
       validateError: false,
       errorMsg: null,
       isValidated: false,
     })
   }

   deleteDayLine = (e) => {
     const { seasonByActivity } = this.state
     const oneSeasonLess = _.clone(seasonByActivity).filter(c => c.id_act_sea !== e)
     setTimeout(() => {
       this.setState({
         seasonByActivity: oneSeasonLess,
       })
     }, 400)
   }

  displayAutocompleteLocation = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeLocation}
        filterOptions={this.filterOptionsLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelLocation}
        renderOption={option => option.lieu}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }


  displayAutocompleteLocationGrand = (locationGrandList) => {
    const {
      activityContent,
      validateError,
    } = this.state
    return (
      <Autocomplete
        id="locationGrand"
        value={activityContent}
        options={locationGrandList}
        getOptionLabel={this.getOptionLabelLocationGrand}
        onChange={this.onChangeLocationGrand}
        renderInput={params => (
          <TextField
            {...params}
            label="province or region*"
            margin="normal"
            variant="outlined"
            error={validateError ? params.inputProps.value === '' : false}

          />
        )}
      />
    )
  }


  displayAutocompleteActivityTheme = (themeList) => {
    const {
      activityContent,
      validateError,
    } = this.state
    return (
      <Input
        id="activity_theme"
        label="service theme"
        select
        margin="normal"
        variant="outlined"
        value={activityContent ? activityContent.id_activity_theme : ''}
        className="largeWidth"
        onChange={this.handleChange('id_activity_theme')}
        error={validateError ? (activityContent && activityContent.id_activity_theme === '') : false}
      >
      {themeList.map(theme => (
        <MenuItem key={theme.id_activity_theme} value={theme.id_activity_theme}>
          {theme.activity_theme}
        </MenuItem>
      ))}
      </Input>
    )
  }


  // Editor
  displayTextEditor = () => {
    const { activityContent } = this.state
    const { userAccount } = this.props
    const activityObject = typeof activityContent === 'string' ? {} : activityContent
    const description = userAccount.language ? `${userAccount.language}_description` : 'fr_description'

    const descriptionValue = activityContent && activityContent[description] ? activityContent[description] : ''
    return (
      <TextEditor
        object={activityObject}
        value={descriptionValue}
        handleEditorChange={content => this.handleEditorChange(description, content)}
      />
    )
  }

  // return filter


  // return all other input
    // return all other input
    displayActivityTheme = () => {
      const {
        activityContent,
        suppliersList,
        themeList,
      } = this.state
      const { locationList, locationGrandList } = this.props
      return (
        <div>
          <CssGrid
            container
            spacing={2}
          >
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteActivityTheme(themeList)}
            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteSupplier('supplier', activityContent, suppliersList)}
            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteLocationGrand(locationGrandList)}

            </CssGrid>
            <CssGrid item md={3} xs={12}>
              {this.displayAutocompleteLocation('location', activityContent, locationList)}
            </CssGrid>
          </CssGrid>
        </div>
      )
    }

  // return all other input
  displayActivityInputs = () => {
    const {
      activityContent,
      seasonByActivity,
      validateError,
    } = this.state

    const nombreSaison = seasonByActivity.length
    return (
      <div>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid
            container
            spacing={2}
          >
            <CssGrid item md={4} xs={12}>

              <Input
                id="activity_type"
                label="service type *"
                select
                margin="normal"
                variant="outlined"
                value={!_.isEmpty(activityContent) && activityContent.activity_type ? activityContent.activity_type : ''}
                className="largeWidth"
                error={validateError ? activityContent.activity_type === '' : false}
                onChange={this.handleChange('activity_type')}
              >
                <MenuItem value="individual">Individual</MenuItem>
                <MenuItem value="group">Group</MenuItem>
              </Input>
            </CssGrid>
          </CssGrid>
          <CssGrid item md={9} xs={12}>
            {/* activity description */}
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={3} xs={12}>

                <Input
                  id="currency"
                  label="currency *"
                  select
                  value={!_.isEmpty(activityContent) && activityContent.currency ? activityContent.currency : ''}
                  onChange={this.handleChange('currency')}
                  margin="normal"
                  variant="outlined"
                  error={validateError ? activityContent.currency === '' : false}
                  className="largeWidth"
                >
                  <MenuItem value="$">$</MenuItem>
                  <MenuItem value="€">€</MenuItem>
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="THB">THB</MenuItem>
                  <MenuItem value="CNY">CNY</MenuItem>
                </Input>
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="adresse"
                  label="Adress"
                  value={!_.isEmpty(activityContent) && activityContent.adresse ? activityContent.adresse : ''}
                  onChange={this.handleChange('adresse')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="email"
                  label="email"
                  value={!_.isEmpty(activityContent) && activityContent.email ? activityContent.email : ''}
                  onChange={this.handleChange('email')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="phone"
                  label="phone"
                  value={!_.isEmpty(activityContent) && activityContent.phone ? activityContent.phone : ''}
                  onChange={this.handleChange('phone')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <div className="editorStyle">
              <p className="editorTitle background-white">Description</p>
              {this.displayTextEditor()}
            </div>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={4} xs={12}>
                <Input
                  id="date_expiration_price"
                  label="expiration price date"
                  type="date"
                  value={!_.isEmpty(activityContent) && activityContent.date_expiration_price ? moment(activityContent.date_expiration_price).format('YYYY-MM-DD') : ''}
                  onChange={this.handleChange('date_expiration_price')}
                  margin="normal"
                  variant="outlined"
                  width="100%"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </CssGrid>
            </CssGrid>
            <FormControlLabel
              control={(
                <CssCheckbox
                  checked={activityContent ? !!activityContent.universel : false}
                  onChange={this.handleCheckChange('universel')}
                  name="universel"
                />
              )}
              label="Universel"
            />
          </CssGrid>

          {/* Uploader */}
          <CssGrid item md={3} xs={12} className="logo-company-div">
            <p className="logo-label">picture</p>
            <Uploader
              imageDataArray={this.getImageUrl()}
              saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
              redirectToGallery={() => this.redirectToGallery()}
              deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
              nbMaxImg={2}
              allowDrop
            />
          </CssGrid>
        </CssGrid>
        <div>
          <CssGrid
            container
            spacing={0}
          >
            <CssGrid item md={6} xs={12}>
            {activityContent && activityContent.activity_type === 'individual' && (
            <CssGrid
              container
              spacing={2}
            >
                <CssGrid item md={2} xs={12}>
                 <h2>Senior price/person</h2>
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price standard season"
                    name="price_seniorpeakseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_seniorpeakseason ? activityContent.price_seniorpeakseason : ''}
                    onChange={this.handleChange('price_seniorpeakseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price high season"
                    name="price_seniorhighseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_seniorhighseason ? activityContent.price_seniorhighseason : ''}
                    onChange={this.handleChange('price_seniorhighseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price low season"
                    name="price_seniorlowseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_seniorlowseason ? activityContent.price_seniorlowseason : ''}
                    onChange={this.handleChange('price_seniorlowseason')}
                  />
                </CssGrid>
            </CssGrid>
            )}
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={2} xs={12}>
                  {activityContent && activityContent.activity_type === 'individual' ? <h2>Adult price/person</h2> : <h2>Price</h2>}
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label={activityContent && activityContent.id_activity_theme === 10 ? 'price standard season for one km or miles' : 'Price standard season'}
                    name="price_adultpeakseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_adultpeakseason ? activityContent.price_adultpeakseason : ''}
                    onChange={this.handleChange('price_adultpeakseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label={activityContent && activityContent.id_activity_theme === 10 ? 'price high season for one km or miles' : 'Price high season'}
                    name="price_adulthighseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_adulthighseason ? activityContent.price_adulthighseason : ''}
                    onChange={this.handleChange('price_adulthighseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label={activityContent && activityContent.id_activity_theme === 10 ? 'price low season for one km or miles' : 'Price low season'}
                    name="price_adultlowseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_adultlowseason ? activityContent.price_adultlowseason : ''}
                    onChange={this.handleChange('price_adultlowseason')}
                  />
                </CssGrid>
              </CssGrid>
              {activityContent && activityContent.activity_type === 'individual' && (
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={2} xs={12}>
                  <h2>Child price/person</h2>
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price peak season"
                    name="price_childpeakseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_childpeakseason ? activityContent.price_childpeakseason : ''}
                    onChange={this.handleChange('price_childpeakseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price high season"
                    name="price_childhighseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_childhighseason ? activityContent.price_childhighseason : ''}
                    onChange={this.handleChange('price_childhighseason')}
                  />
                </CssGrid>
                <CssGrid item md={2} xs={12}>
                  <Input
                    label="Price low season"
                    name="price_childlowseason"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={!_.isEmpty(activityContent) && activityContent.price_childlowseason ? activityContent.price_childlowseason : ''}
                    onChange={this.handleChange('price_childlowseason')}
                  />
                </CssGrid>
              </CssGrid>
              )}
            </CssGrid>
            <CssGrid item md={6} xs={12}>
              <h2>Season interval</h2>
              {!_.isEmpty(seasonByActivity) && seasonByActivity.map((item, index) => (
                <div key={`${item.id_act_sea}season`}>

                  <CssGrid
                    container
                    spacing={2}
                  >
                    <CssGrid item md={3} xs={12}>
                      <Input
                        name="period_name"
                        label="period_name"
                        select
                        required
                        className="enterSeason"
                        margin="normal"
                        variant="outlined"
                        value={!_.isEmpty(item) && item.period_name ? item.period_name : ''}
                        onChange={e => this.handleSeason(e, index)}
                      >
                        <MenuItem value="lowseason">low season</MenuItem>
                        <MenuItem value="highseason">high season</MenuItem>
                        <MenuItem value="peakseason">standard season</MenuItem>
                      </Input>
                    </CssGrid>
                    <CssGrid item md={3} xs={12}>
                      <Input
                        InputProps={{
                          inputProps: {
                            min: '2022-01-01',
                            max: '2022-12-31',
                            format: 'MM-DD',
                            title: 'Select a date between January 1st and December 31st',
                          },
                        }}
                        type="date"
                        className="enterSeason"
                        name="start_date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        label="date from"
                        onChange={e => this.handleSeason(e, index)}
                        value={!_.isEmpty(item) && item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                        margin="normal"
                        variant="outlined"
                      />

                    </CssGrid>
                    <CssGrid item md={3} xs={12}>
                      <Input
                        InputProps={
                        !_.isEmpty(item) && item.start_date && item.start_date !== '0000-00-00 00:00:00'
                          ? { inputProps: { min: item.start_date, max: '2022-12-31', title: `Select a date between ${moment(item.start_date).format('YYYY-MM-DD')} and December 31st` } }
                          : { inputProps: { min: '2022-01-01', max: '2022-12-31', title: 'Select a date between January 1st and December 31st' } }
                      }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => this.handleSeason(e, index)}
                        value={!_.isEmpty(item) && item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                        required
                        className="enterSeason"
                        type="date"
                        label="to"
                        format="MM-DD"
                        name="end_date"
                        margin="normal"
                        variant="outlined"
                      />
                    </CssGrid>
                    {nombreSaison === index + 1
                  && (
                  <CssGrid item md={3} xs={12}>
                    <Tooltip title="Delete" className="tooltip-season">
                      <Delete
                        onClick={() => this.deleteDayLine(item.id_act_sea)}
                      />
                    </Tooltip>
                  </CssGrid>
                  )
}
                  </CssGrid>
                </div>
              ))}
              <div className="addSeason">
                <Fab
                  aria-label="Add"
                  size="large"
                  name={activityContent && activityContent.id_actvitiy}
               // disabled={(!_.isEmpty(items) && !items[0].title) || isActionButtonDisabled}
                  onClick={() => this.addSeasonLine(activityContent.id_activity)}
                >
                  <AddIcon />
                </Fab>
              </div>
            </CssGrid>
          </CssGrid>
        </div>
      </div>
    )
  }

  // *******************************
  // Item actions *******
  // Delete
  deleteItem = () => {
    const {
      token,
      activityContent,
    } = this.state
    const activityId = activityContent.id_activity
    const { DELETE_ACTIVITY_CONTENT } = this.props

    if (confirm('Do you really want to delete this activity ?')) {
      if (activityId) {
        DELETE_ACTIVITY_CONTENT(token, activityId)
        // this.setState({})
      } else {
        this.setState({
          validateError: true,
          errorMsg: activityContent.activity ? 'This activity do not exist.' : 'Please, select an activity.',
        })
      }
    }
  }

  // Modify title
  modifyItemTitle = () => {
    this.handleOpen()
  }

  // Save season interval
  saveSeason = () => {
    const {
      token,
      seasonByActivity,
      initialSeasonByActivity,
    } = this.state
    const {
      PUT_SEASON_BY_ACTIVITY,
    } = this.props

    // calculate difference of seasons
    const filteredSeasonByActivity = seasonByActivity.filter(season => initialSeasonByActivity.some(initialSeason => initialSeason.id_act_sea === season.id_act_sea));

    const SeasonDifftoAdd = _.differenceBy(seasonByActivity, initialSeasonByActivity, 'id_act_sea')
    const SeasonDifftoDelete = _.differenceBy(initialSeasonByActivity, seasonByActivity, 'id_act_sea')
    const SeasonDifftoupdate = _.difference(filteredSeasonByActivity, initialSeasonByActivity, _.isEqual)

    if (!_.isEmpty(seasonByActivity) && _.isEmpty(initialSeasonByActivity)) {
      PUT_SEASON_BY_ACTIVITY(token, seasonByActivity, '', '', '', 'create')
    } else if (!_.isEmpty(SeasonDifftoupdate) || !_.isEmpty(SeasonDifftoDelete) || !_.isEmpty(SeasonDifftoAdd)) {
      PUT_SEASON_BY_ACTIVITY(token, '', SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, 'update')
    }


    // write changes in DB
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      activityContent,
      isNewActivity,
      newImageArray,
      token,
    } = this.state
    const {
      PUT_ACTIVITY_CONTENT,
    } = this.props
    const {
      activity,
      fr_description,
      en_description,
      sp_description,
      id_location,
      id_location_grand,
      image,
      adresse,
      phone,
      email,
      activity_type,
      id_activity_theme,
      activity_theme,
      activity_sub_theme,
      currency,
      id_supplier,
    } = activityContent
    this.setState({
      isValidated: true,
    })
    // Check all required inputs
    if (!activityContent.activity || (!activityContent.location && !activityContent.locationGrand) || !activityContent.id_activity_theme || !activityContent.activity_type || !activityContent.currency) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    const newActivityContent = {
      ...activityContent,
      activity: activity || '',
      fr_description: fr_description ? fr_description.toString().replace(/"/g, '\\"') : '',
      en_description: en_description ? en_description.toString().replace(/"/g, '\\"') : '',
      sp_description: sp_description ? sp_description.toString().replace(/"/g, '\\"') : '',
      id_lieu: id_location || null,
      id_lieu_grand: id_location_grand || null,
      id_Supplier: id_supplier || null,
      image: newImageArray.join() || image || '',
      adresse: adresse || '',
      phone: phone || '',
      email: email || '',
      activity_type: activity_type || '',
      id_activity_theme: id_activity_theme || '',
      activity_theme: activity_theme || '',
      activity_sub_theme: activity_sub_theme || '',
      currency: currency || '',
    }
    if (isNewActivity) {
      PUT_ACTIVITY_CONTENT(token, newActivityContent, 'create')
    } else {
      PUT_ACTIVITY_CONTENT(token, newActivityContent, 'update')
      this.saveSeason()
    }
    return null
  }

  // *************************************
  createNewLocation = (locationContent) => {
    Geocode.fromAddress(locationContent.lieu).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, locationContent);
      },
      (error) => {
        console.error(error);
        alert('cannot find GPS information for this location, please contact your administrator to create it manually.')
      },
    )
  }

  // create a new location

  createNewLocation_withGeoLoc = (response, locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu = locationContent.lieu
    locationDetails.geocode = response.results[0].geometry.location

    const { PUT_LOCATION_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }

  // **************************
  // Modal action

  getModifiedItem = (item) => {
    const { activityContent } = this.state
    this.setState({
      activityContent: {
        ...activityContent,
        activity: item.activity,
      },
    }, () => this.handleClose())
  }

  goToSupplier = (item) => {
    const { history } = this.props
    const {
      activityContent,
      isNewActivity,
    } = this.state
    history.push('suppliers', {
      item, isNewActivityBack: isNewActivity, activityContent, goBack: 'activity',
    })
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
    setTimeout(() => {
      this.setState({
        isValidated: false,
      })
    }, 300);
  };

  // **********************************
  render() {
    const {
      activityContent,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      isModalOpen,
      actionLabel,
      btnActionDisabled,
      isModalOpenSupplier,
      supplierToInsert,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              {/* <FabDelete
                deleteItem={this.deleteItem}
              /> */}
              <h2>SERVICES AND ACTIVITIES MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <BackButton
                type="button"
                label="Back"
                goBack={() => this.goBack()}
              />
              {this.displayActivityByTab()}

            </div>
            <ActionButton
              label={actionLabel}
              disabled={!activityContent || btnActionDisabled}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the new title for this activity"
            modalContent="activity"
            warning="This will update this activity in all quotes !"
            type="activity"
            inputValue={!_.isEmpty(activityContent) ? activityContent.activity : ''}
          />
          <ModalComponent
            isModalOpen={isModalOpenSupplier}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.goToSupplier(item)}
            disabled={false}
            title="Do you want to create a new supplier?"
            modalContent="title"
            inputValue={supplierToInsert}
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activityList: _.filter(state.activityReducer.activityList, aL => aL.active === '1'),
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  putActivityContent: state.activityReducer.putActivityContent,
  seasonByActivity: state.api.seasonByActivity,
  putSeasonByActivity: state.api.putSeasonByActivity,
  deleteSeasonByActivity: state.api.deleteSeasonByActivity,
  deleteActivityContent: state.activityReducer.deleteActivityContent,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  companyContent: state.companyReducer.companyContent,
  putLocationContent: state.api.putLocationContent,
  userAccount: state.userAccountReducer.userAccount,
  suppliersList: _.filter(state.suppliersReducer.suppliersList, l => l.id_supplier !== 1),
})

const mapDispatchToProps = dispatch => ({
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  GET_SUPPLIERS_LIST: userId => dispatch({
    type: 'GET_SUPPLIERS_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  PUT_ACTIVITY_CONTENT: (token, activityContent, action) => dispatch({
    type: 'PUT_ACTIVITY_CONTENT',
    token,
    activityContent,
    action,
  }),
  DELETE_ACTIVITY_CONTENT: (token, activityId) => dispatch({
    type: 'DELETE_ACTIVITY_CONTENT',
    token,
    activityId,
  }),
  RESET_ACTIVITY_CONTENT: () => dispatch({
    type: 'RESET_ACTIVITY_CONTENT',
  }),
  RESET_SEASON_BY_ACTIVITY: () => dispatch({
    type: 'RESET_SEASON_BY_ACTIVITY',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  PUT_SEASON_BY_ACTIVITY: (token, seasonByActivity, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action) => dispatch({
    type: 'PUT_SEASON_BY_ACTIVITY',
    token,
    seasonByActivity,
    SeasonDifftoupdate,
    SeasonDifftoDelete,
    SeasonDifftoAdd,
    action,
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
  GET_SEASON_BY_ACTIVITY: (userId, actId) => dispatch({
    type: 'GET_SEASON_BY_ACTIVITY',
    userId,
    actId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity))
