import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_invoice_list() {
    const userId = localStorage.getItem('userId')
    return axios.get(`${apiUrl}/api/lists/invoiceList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },

  put_company_invoice(token, companyContent) {
    return axios.put(`${apiUrl}/api/company/updateInvoice`, { companyContent }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

  put_trip_billing_billingref(token, idDevis, billingDetails) {
    return axios.put(`${apiUrl}/api/billing/updatebillingref`, { idDevis, billingDetails }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
