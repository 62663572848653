import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Input,
} from '../../../components'

export default function SelectRequestor(props) {
  const {
    requestorList,
    requestorId,
    updateRequestor,
  } = props

  // *************************************************
  const [content, setRequestorContent] = useState({})

  useEffect(() => {
    const requestor = requestorList.filter(r => r.id_demandeur === requestorId)[0]
    if (requestor) {
      setRequestorContent(requestor)
    }
  }, [requestorId])

  // *************************************************
  // update requestor


  // *************************************************
  // Autocomplete selection
  // On change trip
  const onChangeRequestor = (event, newValue) => {
    // on press Enter
    if (typeof newValue === 'string') {
      setRequestorContent({})
      updateRequestor({})
    // clear input trip
    } else if (!newValue) {
      setRequestorContent({})
      updateRequestor({})
      // Create a new value from the user input (ADD 'xxx')
    } else if (newValue && newValue.inputValue) {
      setRequestorContent({})
      updateRequestor({})
      // select value
    } else {
      setRequestorContent(newValue)
      updateRequestor({
        ...newValue,
        isRequestorFieldDisabled: true,
      })
    }
  }

  const filterOptionsRequestor = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    return filtered;
  }

  const getOptionLabelRequestor = (option) => {
    if (_.isEmpty(option)) return ''
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.demandeur) {
      return ''
    }
    // Regular option
    return option.demandeur
  }
  return (
    <Autocomplete
      id="requestor"
      value={content}
      onChange={onChangeRequestor}
      filterOptions={filterOptionsRequestor}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={requestorList}
      getOptionLabel={getOptionLabelRequestor}
      renderOption={option => option.demandeur}
      freeSolo
      renderInput={params => (
        <Input
          {...params}
          label="Requestor"
          margin="normal"
          placeholder="Select a requestor"
          variant="outlined"
        />
      )}
    />
  )
}

SelectRequestor.propTypes = {
  requestorList: PropTypes.array,
  requestorId: PropTypes.number,
  updateRequestor: PropTypes.func.isRequired,
};

SelectRequestor.defaultProps = {
  requestorList: [],
  requestorId: null,
};
