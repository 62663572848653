import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash'
import { CssGrid } from '../../../../configCSS'
import {
  Input,
  ActionButton,
} from '../../../../components'

export default function ResourcesFilter(props) {
  const {
    resourcesFiltred,
    updateFilter,
    resetFilter,
    checkPeriod,
  } = props


  const [filterChoise, setFilterChoise] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [serialNbList, setSerialNbList] = useState([]);

  useEffect(() => {
    setCategoryList(getUniqueValue('category'))
    setSubCategoryList(getUniqueValue('sub_category'))
    setSerialNbList(getUniqueValue('serial_number'))
  }, [resourcesFiltred])

  const getUniqueValue = (proprety) => {
    const uniquevalueArray = new Set()
    resourcesFiltred.map(obj => uniquevalueArray.add(obj[proprety]))
    return _.pull(['-- no option --', ...uniquevalueArray], undefined)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    let action = 'add'
    let newFilterChoise = {
      ...filterChoise,
      [name]: value !== '-- no option --' ? value : '',
    }
    if (value === '-- no option --') {
      newFilterChoise = _.omit(newFilterChoise, [name])
      switch (name) {
        case 'category':
          setCategoryList(getUniqueValue('category'))
          break;
        case 'sub_category':
          setCategoryList(getUniqueValue('sub_category'))
          break;
        case 'serial_number':
          setCategoryList(getUniqueValue('serial_number'))
          break;
        default:
          break;
      }
      action = 'remove'
    }
    setFilterChoise(newFilterChoise)

    if (['start', 'end'].includes(name)) checkPeriod(newFilterChoise)
    else updateFilter(newFilterChoise, action)
  }

  const reset = () => {
    resetFilter()
    setFilterChoise({})
  }

  return (
    <div>
      {/* Filtre Resource */}
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={4} xs={12}>
          <Input
            id="category"
            name="category"
            label="Category"
            select
            value={filterChoise.category || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          >
            {categoryList.map(option => (
              <MenuItem key={option} value={option}>{`${option}`}</MenuItem>
            ))}
          </Input>
        </CssGrid>
        <CssGrid item md={4} xs={12}>
          <Input
            id="sub-category"
            name="sub_category"
            label="Sub-category"
            select
            value={filterChoise.sub_category || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          >
            {subCategoryList.map(option => option && (
              <MenuItem key={option} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Input>
        </CssGrid>
        <CssGrid item md={4} xs={12}>
          <Input
            id="serial_number"
            name="serial_number"
            label="Serial Number"
            select
            value={filterChoise.serial_number || ''}
            // value={filterChoise.serial_number || ''}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
          >
            {serialNbList.map(option => option && (
              <MenuItem key={option} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Input>
        </CssGrid>
      </CssGrid>

      {/* Filtre periode */}
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={4} xs={12}>
          <Input
            id="start"
            name="start"
            label="From"
            type="date"
            value={filterChoise.start || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            variant="outlined"
          />
        </CssGrid>
        <CssGrid item md={4} xs={12}>
          <Input
            id="end"
            name="end"
            label="To"
            type="date"
            value={filterChoise.end || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            variant="outlined"
          />
        </CssGrid>
        <CssGrid
          container
          item
          md={4}
          xs={12}
          alignItems="center"
        >
          <ActionButton
            label="Reset"
            onClick={reset}
          />
        </CssGrid>
      </CssGrid>
    </div>
  )
}

ResourcesFilter.propTypes = {
  resourcesFiltred: PropTypes.array.isRequired,
  updateFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  checkPeriod: PropTypes.func.isRequired,
};

ResourcesFilter.defaultProps = {
};
