import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_user_account(userId) {
    return axios.get(`${apiUrl}/api/userAccount/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_user_account(token, userAccount, userId) {
    return axios.put(`${apiUrl}/api/userAccount/update/${userId}`, { userAccount }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
