import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Geocode from 'react-geocode';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';

import {
  CssGrid,
  CssSelect,
  CSSRadio,
  AntTabs,
  CssCheckbox,
} from '../../../configCSS'

import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
  Uploader,
  TextEditor,
  BackButton,
  TabPanel,
} from '../../../components'
import tableIcons from '../../../components/utils/icons/icons'
import stage from '../../../config'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'
import {
  AutocompleteSelectAmongExisting,
} from './component'

const {
  Show,
  Copy,
  Copyhtml,
  Edit,
} = tableIcons

Geocode.setApiKey('AIzaSyBMxoS8lxRRfEmRU9NuJyNGVUZkEbxVMC0');


const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage
const locationFormatRegExp = new RegExp(' ', 'g')

class Day extends PureComponent {
  static propTypes = {
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    GET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_DAYS_LIST: PropTypes.func.isRequired,
    PUT_DAY_CONTENT: PropTypes.func.isRequired,
    DELETE_DAY_CONTENT: PropTypes.func.isRequired,
    RESET_DAY_CONTENT: PropTypes.func.isRequired,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    putDayContent: PropTypes.object,
    putLocationContent: PropTypes.object,
    deleteDayContent: PropTypes.object,
    history: PropTypes.object.isRequired,
    daysList: PropTypes.array,
    locationList: PropTypes.array,
    activityList: PropTypes.array,
    userAccount: PropTypes.object,
    informationsContent: PropTypes.object,
  }

  static defaultProps = {
    userAccount: {},
    locationList: [],
    activityList: [],
    daysList: [],
    putDayContent: {},
    putLocationContent: {},
    deleteDayContent: {},
    informationsContent: {},
  }

  constructor(props) {
    super(props);
    this.compare = this.compare.bind(this)
    this.removeDuplicates = this.removeDuplicates.bind(this)
    this.toggleDisplay = this.toggleDisplay.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.reload = this.reload.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.onChangeDay = this.onChangeDay.bind(this)
    this.filterOptionsDay = this.filterOptionsDay.bind(this)
    this.getOptionLabelDay = this.getOptionLabelDay.bind(this)
    this.onChangeActivity = this.onChangeActivity.bind(this)
    this.displayInputActivity = this.displayInputActivity.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.filterOptionsLocation = this.filterOptionsLocation.bind(this)
    this.getOptionLabelLocation = this.getOptionLabelLocation.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.addVersion = this.addVersion.bind(this)
    this.closeVersionList = this.closeVersionList.bind(this)
    this.openVersionList = this.openVersionList.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.displayAutocompleteDay = this.displayAutocompleteDay.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.displayAutocompleteActivity = this.displayAutocompleteActivity.bind(this)
    this.displayDayContentInputs = this.displayDayContentInputs.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayArchiveRadioButton = this.displayArchiveRadioButton.bind(this)
    this.displayPictures = this.displayPictures.bind(this)
    this.disabledArchiveButton = this.disabledArchiveButton.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItemTitle = this.modifyItemTitle.bind(this)
    this.validate = this.validate.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.getModifiedItem = this.getModifiedItem.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
    this.tabProps = this.tabProps.bind(this)
    this.handleActivityDayChange = this.handleActivityDayChange.bind(this)
    this.state = {
      activityItem: {},
      filterLoc: true,
      informationsContent: {},
      daysList: [],
      sortedUniqDayList: [],
      restrictedList: null,
      versionList: [],
      versionValue: '',
      locationList: [],
      dayContent: {},
      switchDisplayEdit: false,
      isValidated: true,
      isNewDay: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      itemsGoBack: [],
      goBack: '',
      idDayLineModified: null,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      newImageArray: [],
      newURLArray: [],
      isOpenVersionList: false,
      btnActionDisabled: false,
      actionLabel: 'Create',
      locationCreated: '',
      isModalOpen: false,
      keyTab: 0,
      sourcePage: 'days-table',
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      daysList,
      locationList,
      userAccount,
      informationsContent,
      GET_USER_ACCOUNT,
      GET_INFORMATIONS_CONTENT,
      GET_LOCATION_LIST,
      RESET_DAY_CONTENT,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    RESET_DAY_CONTENT()
    if (!_.isEmpty(daysList)) {
      this.setState({
        daysList,
        sortedUniqDayList: this.removeDuplicates(daysList.sort(this.compare)),
      })
    }
    // check userAccount
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
    // When this page is activated from daysList page, get allinformations
    if (history.location.state) {
      if (history.location.state.goBack === 'daysList') {
        const {
          state,
          items,
          goBack,
        } = history.location.state
        const {
          idDayLineModified,
          field,
          value,
        } = state
        this.setState({
          dayContent: {
            field,
            [field]: value,
            nom_journee: value,
            version: 1,
            language: userAccount.language,
          },
          versionValue: 1,
          versionList: [{
            version: 1,
          }],
          itemsGoBack: items,
          goBack,
          idDayLineModified,
          isNewDay: true,
        })
      }
      // access from days table for editing mode
      if (history.location.state.source === 'days-table') {
        const {
          idDay,
        } = history.location.state
        if (idDay) {
          const dayToEdit = daysList.filter(d => d.id_journee === idDay)[0]
          this.setState({
            dayContent: dayToEdit,
            actionLabel: 'Modify',
            versionValue: dayToEdit.version,
            versionList: daysList.filter(d => d.nom_journee === dayToEdit.nom_journee),
            sourcePage: 'days-table',
          })
        }
      }
      // come back from 'Gallery'
      if (history.location.state.currentPage === 'day') {
        const {
          state,
          currentImg,
          pictureSelected,
          itemsGoBack,
          goBack,
          idDayLineModified,
        } = history.location.state
        this.setState({
          dayContent: {
            ...state.item,
            image: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          versionList: state.versionList,
          versionValue: state.versionValue,
          actionLabel: state.actionLabel,
          keyTab: state.keyTab,
          itemsGoBack,
          goBack,
          idDayLineModified,
          isNewDay: state.isNewDay,
        })
      }
    }
    if (!_.isEmpty(locationList)) {
      this.setState({
        locationList: _.sortBy(locationList, o => o.location),
      })
    } else {
      GET_LOCATION_LIST(userId)
    }
    if (!_.isEmpty(informationsContent)) {
      this.setState({
        informationsContent,
      })
    } else {
      GET_INFORMATIONS_CONTENT(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      userId,
      informationsContent: informationsContentOld,
      daysList: daysListOld,
      locationList: locationListOld,
      dayContent,
      goBack,
      idDayLineModified,
      itemsGoBack,
      locationCreated,
      btnActionDisabled,
    } = this.state
    const {
      history,
      informationsContent,
      daysList,
      locationList,
      putDayContent,
      putLocationContent,
      deleteDayContent,
      GET_LOCATION_LIST,
      RESET_LOCATION_CONTENT,
      RESET_DAY_CONTENT,
    } = nextprops

    // day saved in DB -> get back updated list
    if (putDayContent && putDayContent.success) {
      this.reload()
      this.setState({
        dayContent: {
          ...dayContent,
          id_journee: putDayContent.id,
        },
        isNewDay: false,
        versionValue: dayContent.version,
        validMsg: 'the day was successfully recorded !',
        isValidated: true,
      }, () => {
        if (goBack) {
          history.push(goBack, {
            itemsGoBack,
            idDayLineModified,
            idItemCreated: putDayContent.id,
            field: 'title',
          })
        } else {
          this.resetValidMsg()
        }
      })
    }
    // day delete in DB -> get back updated list
    if (deleteDayContent && deleteDayContent.success) {
      this.reload()
      this.setState({
        dayContent: {},
        versionList: [],
        versionValue: '',
        isNewDay: false,
        validMsg: 'the day was successfully deleted !',
        isValidated: true,
      })
      this.resetValidMsg()
    }
    // day can not be delete in DB -> it is used yet in route
    if (deleteDayContent && !deleteDayContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteDayContent.message,
      })
      RESET_DAY_CONTENT()
    }
    // location saved in DB -> get back updated list
    if (putLocationContent && putLocationContent.success) {
      this.setState({
        dayContent: {
          ...dayContent,
          [`id_${locationCreated}`]: putLocationContent.id,
        },
        btnActionDisabled: !btnActionDisabled,
        validMsg: 'Location was successfully recorded !',
        isValidated: false,
      }, () => this.resetValidMsg(2000))
      RESET_LOCATION_CONTENT()
      GET_LOCATION_LIST(userId)
    }
    if (daysList !== daysListOld) {
      this.setState({
        daysList,
        sortedUniqDayList: this.removeDuplicates(daysList.sort(this.compare)),
      })
    }
    if (locationList !== locationListOld) {
      this.setState({
        locationList: _.sortBy(locationList, o => o.location),
      })
    }
    if (informationsContent !== informationsContentOld) {
      this.setState({
        informationsContent,
      })
    }
  }

  // ********************************************

  // Sort and remove duplicate title program

  // Sort all days A -> Z
  // eslint-disable-next-line react/sort-comp
  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const titleA = a.nom_journee.toUpperCase();
    const titleB = b.nom_journee.toUpperCase();

    let comparison = 0;
    if (titleA > titleB) {
      comparison = 1;
    } else if (titleA < titleB) {
      comparison = -1;
    }
    return comparison;
  }

  // keep only version 1 remove all others
  removeDuplicates = data => [...new Map(data.map(o => [o.nom_journee, o])).values()]

  // *********************************************
  resetValidMsg =(t = 2000) => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, t)
  }

  toggleDisplay = (value) => {
    this.setState({
      switchDisplayEdit: value, // Activer le mode d'édition
    })
  }

  reload = () => {
    const {
      userId,
      goBack,
    } = this.state
    const {
      RESET_DAY_CONTENT,
      GET_DAYS_LIST,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
    } = this.props
    // reset results after put request (success: false)
    RESET_DAY_CONTENT()
    if (!goBack) {
      // unselect trip in page 'trip'
      ROW_TRIP_SELECT([])
      // reset all trip information
      RESET_TRIP_INFORMATION()
    }
    GET_DAYS_LIST(userId)
    this.setState({ validateError: false })
  }

  // ************************************************
  // IMAGE
  // Upload and show image
  // Storage in folder aws S3 duyen-pictures/<company>

  getImageUrl = () => {
    const { userAccount } = this.props
    const { company } = userAccount
    const {
      dayContent,
      newURLArray,
      newImageArray,
    } = this.state
    const { image } = dayContent
    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company.toLowerCase()}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
      validateError: false,
      errorMsg: null,
      isValidated: false,
    });
  }

  deleteImage = (urlArray, imageArray) => {
    const { dayContent } = this.state
    this.setState({
      dayContent: {
        ...dayContent,
        image: imageArray.join(),
      },
      newImageArray: imageArray,
      newURLArray: urlArray,
      isValidated: false,
    })
  }

  redirectToGallery = () => {
    const {
      dayContent,
      versionList,
      versionValue,
      itemsGoBack,
      goBack,
      idDayLineModified,
      actionLabel,
      isNewDay,
      keyTab,
    } = this.state
    const {
      history,
    } = this.props
    this.setState({
      isValidated: true,
    }, () => {
      history.push('gallery', {
        currentPage: 'day',
        isSelectionAction: true,
        nbMaxImg: '5',
        currentImg: dayContent.image ? dayContent.image.split(',') : [],
        state: {
          item: dayContent,
          versionList,
          versionValue,
          actionLabel,
          isNewDay,
          keyTab,
        },
        itemsGoBack: itemsGoBack || null,
        goBack: goBack || null,
        idDayLineModified: idDayLineModified || null,
      })
    })
  }

  onChangeActivity = (event, value) => {
    // find the global object from this value
    this.setState({
      activityItem: value,
    })
  }

  displayInputActivity = activityList => (
    <Autocomplete
      id="activity"
      className="option-input-css"
      options={activityList}
      getOptionLabel={option => `${option.activity} (${option.universel === 1 ? ('universel') : (`(${option.locationGrand ? `province: ${option.locationGrand}` : 'No province'})(${option.location ? `Location: ${option.location}` : 'No location'})`)})`}
      onChange={this.onChangeActivity}
      renderInput={params => <TextField {...params} label="search an activity" title="Select an activity to get the description activity and copy paste on the day description" variant="outlined" />}
    />
  )

  // On change accomodation
  onChangeDay = (event, newValue) => {
    const {
      daysList,
      isValidated,
    } = this.state
    const {
      userAccount,
    } = this.props
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }
    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = daysList.filter(d => d.nom_journee === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          dayContent: {
            id_journee: null,
            nom_journee: newValue,
            version: 1,
            language: userAccount.language,
            image: '',
          },
          versionValue: 1,
          versionList: [
            {
              nom_journee: newValue,
              version: 1,
            },
          ],
          actionLabel: 'Create',
          isNewDay: true,
          newImageArray: [],
          newURLArray: [],
          isValidated: false,
          restrictedList: null,
        });
      } else {
        this.setState({
          dayContent: valueExist[0],
          actionLabel: 'Modify',
          versionList: valueExist,
          versionValue: valueExist[0].version,
          newImageArray: [],
          newURLArray: [],
          isNewDay: false,
          isValidated: true,
          restrictedList: null,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        dayContent: {
          id_journee: null,
          nom_journee: newValue.inputValue,
          version: 1,
          language: userAccount.language,
          image: '',
        },
        versionValue: 1,
        versionList: [
          {
            nom_journee: newValue.inputValue,
            version: 1,
          },
        ],
        newImageArray: [],
        newURLArray: [],
        actionLabel: 'Create',
        isNewDay: true,
        isValidated: false,
        restrictedList: null,
      });
    } else if (!newValue) {
      this.setState({
        dayContent: {},
        actionLabel: 'Create',
        versionList: [],
        versionValue: '',
        newImageArray: [],
        newURLArray: [],
        isNewDay: false,
        isValidated: true,
        restrictedList: null,
      })
    } else {
      const dayContentSelected = _.filter(daysList, o => o.nom_journee === newValue.nom_journee)[0]
      const getVersionList = daysList.filter(d => d.nom_journee === newValue.nom_journee)
      this.setState({
        dayContent: dayContentSelected,
        actionLabel: 'Modify',
        versionList: getVersionList,
        versionValue: dayContentSelected.version,
        newImageArray: [],
        newURLArray: [],
        isNewDay: false,
        isValidated: true,
        restrictedList: null,
      });
    }
    return null
  }

  filterOptionsDay = (options, params) => {
    const { daysList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = daysList.filter(d => d.nom_journee === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        nom_journee: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelDay = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.nom_journee) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.nom_journee;
  }


  // On change location
  onChangeLocation = field => (event, newValue) => {
    const {
      dayContent,
      sortedUniqDayList,
    } = this.state
    const {
      locationList,
    } = this.props
    const isDayEmpty = _.isEmpty(dayContent) || !dayContent.nom_journee
    const oppositeLocation = field === 'start' ? 'end' : 'start'
    // on press Enter
    if (typeof newValue === 'string') {
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const locationObject = locationList.filter(l => l.location === valueClean)[0]
      const idLocation = locationObject && locationObject.id_lieu ? locationObject.id_lieu : null
      if (!idLocation) {
        this.setState({
          locationCreated: field,
        }, () => this.createNewLocation({ lieu: valueClean }))
      }

      if (_.isEmpty(locationObject)) {
        this.setState({
          dayContent: {
            ...dayContent,
            [`id_${field}`]: idLocation,
            [`${field}_location`]: valueClean,
          },
          isValidated: isDayEmpty,
          isNewDay: false,
          restrictedList: isDayEmpty ? sortedUniqDayList.filter(e => e[`${field}_location`] === valueClean) : null,
        });
      } else {
        this.setState({
          dayContent: {
            ...dayContent,
            [`id_${field}`]: locationObject.id_lieu,
            [`${field}_location`]: locationObject.lieu,
            [`id_${oppositeLocation}`]: isDayEmpty ? null : dayContent[`id_${oppositeLocation}`],
            [`${oppositeLocation}_location`]: isDayEmpty ? '' : dayContent[`${oppositeLocation}_location`],
          },
          restrictedList: isDayEmpty ? _.filter(sortedUniqDayList, e => e[`${field}_location`] === locationObject.lieu) : null,
          isValidated: isDayEmpty,
        })
      }
    } else if (!newValue) {
      // clear input location
      this.setState({
        dayContent: {
          ...dayContent,
          [`id_${field}`]: null,
          [`${field}_location`]: '',
        },
        restrictedList: null,
        isValidated: isDayEmpty,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        dayContent: {
          ...dayContent,
          [`id_${field}`]: null,
          [`${field}_location`]: newValue.inputValue,
        },
        locationCreated: field,
        restrictedList: isDayEmpty ? sortedUniqDayList.filter(e => e[`${field}_location`] === newValue.inputValue) : null,
        isValidated: isDayEmpty,
        // save new location
      }, () => this.createNewLocation({ lieu: newValue.inputValue }));
    } else {
      this.setState({
        dayContent: {
          ...dayContent,
          [`id_${field}`]: newValue.id_lieu,
          [`${field}_location`]: newValue.lieu,
          [`id_${oppositeLocation}`]: isDayEmpty ? null : dayContent[`id_${oppositeLocation}`],
          [`${oppositeLocation}_location`]: isDayEmpty ? '' : dayContent[`${oppositeLocation}_location`],
        },
        restrictedList: isDayEmpty ? _.filter(sortedUniqDayList, e => e[`${field}_location`] === newValue.lieu) : null,
        isValidated: isDayEmpty,
      })
    }
  }

  filterOptionsLocation = (options, params) => {
    const { locationList } = this.state
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(d => d.lieu === valueClean)
    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }

  getOptionLabelLocation = field => (option) => {
    if (_.isEmpty(option)) return ''
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return option[`${field}_location`] || ''
    }
    // Regular option
    return option.location
  }

  // On change Editor
  handleEditorChange = field => (content) => {
    const { dayContent } = this.state
    if (!_.isEmpty(dayContent) && content !== dayContent.desc_journee) {
      this.setState({
        dayContent: {
          ...dayContent,
          [field]: content,
        },
        validateError: false,
        errorMsg: null,
        isValidated: false,
      });
    }
  }

  // save all other (other than title) new informations for this day
  handleChange = field => (event) => {
    const { dayContent } = this.state
    const { value } = event.target
    this.setState({
      dayContent: {
        ...dayContent,
        [field]: value,
      },
      validateError: false,
      errorMsg: null,
      isValidated: false,
    })
  }


  updateDayContent = (updatedDay) => {
    const { dayContent } = this.state
    this.setState({
      dayContent: {
        ...dayContent,
        ...updatedDay,
      },
    })
  }

  handleActivityDayChange = (content) => {
    const {
      dayContent,
    } = this.state
    const tabArrayId = content.map(c => c.id_activity)
    this.setState({
      dayContent: {
        ...dayContent,
        day_activity: _.join(tabArrayId, ','),
      },
    });
    return true
  }


  handleRadioChange = (event) => {
    const { daysList, versionValue, dayContent } = this.state
    const compteVersion = daysList.filter(d => d.nom_journee === dayContent.nom_journee).length

    if (versionValue === compteVersion) {
      this.setState({
        dayContent: {
          ...dayContent,
          active: event.target.value,
        },
        isValidated: false,
      })
    } else {
      alert('You can only delete or archive the higher version of the day')
    }
  }

  // Version function *******************************
  // control version list open
  openVersionList = () => {
    const { isValidated } = this.state
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }
    this.setState({ isOpenVersionList: true })
    //   if (this.confirmChangeContent()) {
    //   this.setState({ isOpenVersionList: true })
    // } else {
    //   this.setState({ isOpenVersionList: false })
    // }
    return null
  }

  closeVersionList = () => {
    this.setState({
      isOpenVersionList: false,
    })
  }

  // action on version
  versionChange = (event) => {
    const {
      daysList,
      dayContent,
      isNewDay,
      versionList,
    } = this.state

    const version = event.target.value
    if (!isNewDay) {
      this.setState({
        newImageArray: [],
        newURLArray: [],
        versionValue: version,
        dayContent: daysList.filter(d => d.nom_journee === dayContent.nom_journee && d.version === version)[0],
        isValidated: true,
      })
    } else if (isNewDay && version !== dayContent.version) {
      // if new version is not validated
      // cancel new version without saving

      this.setState({
        versionValue: version,
        dayContent: daysList.filter(d => d.nom_journee === dayContent.nom_journee && d.version === version)[0],
        versionList: _.dropRight(versionList),
        isNewDay: false,
        isValidated: true,
      })
    }
    this.closeVersionList()
  }

  addVersion = () => {
    const {
      versionList,
      dayContent,
    } = this.state
    // Check all required inputs
    if (!dayContent.nom_journee || !dayContent.start_location || !dayContent.end_location) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // config new version
    const nextVersion = versionList[versionList.length - 1].version + 1
    this.setState({
      dayContent: {
        ...dayContent,
        id_journee: null,
        version: nextVersion,
      },
      versionList: [
        ...versionList,
        {
          ...dayContent,
          id_journee: null,
          version: nextVersion,
        },
      ],
      versionValue: nextVersion,
      isNewDay: true,
      isValidated: true,
    }, () => this.validate())
    return null
  }

  // ***************************
  // Display input


  displayAutocompleteDay = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item sm={10} xs={12}>
          <Autocomplete
            id={`${field}-field`}
            value={content}
            onChange={this.onChangeDay}
            filterOptions={this.filterOptionsDay}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelDay}
            renderOption={option => option.nom_journee}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css" onClick={this.modifyItemTitle}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  displayAutocompleteLocation = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeLocation(field)}
        filterOptions={this.filterOptionsLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelLocation(field)}
        renderOption={option => option.lieu}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

  displayAutocompleteActivity = (field, content, list) => {
    const {
      validateError,
    } = this.state
    const tabDayActivity = _.split(content.day_activity, ',')
    const tabDayActivity2 = tabDayActivity.map(tDA => _.toNumber(tDA))
    const dayActivityList = list.filter(aL => tabDayActivity2.includes(aL.id_activity))


    const FondDefautIndividual = {
      backgroundColor: '#CB8562',
    };
    const FondDefautGroup = {
      backgroundColor: '#CBC062',
    };
    return (
      <Autocomplete
        id={`${field}-field`}
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={dayActivityList}
        onChange={(event, newValue) => {
          this.handleActivityDayChange(newValue);
        }}
        options={list}
        getOptionLabel={option => `${option.activity} (${option.universel === 1 ? ('universel') : (`(${option.locationGrand ? `province: ${option.locationGrand}` : 'No province'})(${option.location ? `Location: ${option.location}` : 'No location'})`)})`}
        renderTags={(value, getTagProps) => value.map((option, index) => (
          option.activity_type === 'individual'
            ? (<Chip style={FondDefautIndividual} variant="outlined" label={`${option.activity}` || ''} {...getTagProps({ index })} />)
            : (<Chip style={FondDefautGroup} variant="outlined" label={`${option.activity}` || ''} {...getTagProps({ index })} />)
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            margin="normal"
            placeholder={`Select a ${field}`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }


  // Editor
  displayTextEditor = () => {
    const {
      dayContent,
      switchDisplayEdit,
    } = this.state
    const dayObject = typeof dayContent === 'string' ? {} : dayContent
    const descriptionValue = dayContent && dayContent.desc_journee ? dayContent.desc_journee : ''


    return (
      switchDisplayEdit ? (
        <div>
          <TextEditor
            object={dayObject}
            value={descriptionValue}
            handleEditorChange={this.handleEditorChange('desc_journee')}
          />
          <Edit
            onClick={() => this.toggleDisplay(false)}
            className="icons"
          />
        </div>
      ) : (
        <div onClick={() => this.toggleDisplay('edit1', true)}>
          <Interweave content={descriptionValue} />
          <Edit
            onClick={() => this.toggleDisplay('edit1', true)}
            className="icons"
          />
        </div>
      )
    )
  }

  // return all other input
  displayDayContentInputs = () => {
    const {
      versionList,
      informationsContent,
      locationList,
      dayContent,
      versionValue,
      isOpenVersionList,
      activityItem,
      filterLoc,
    } = this.state
    const {
      userAccount,
      daysList,
      activityList,
    } = this.props
    const { theme_devis } = informationsContent
    const description = userAccount.language ? `${userAccount.language}_description` : 'fr_description'
    const parser = activityItem && !_.isEmpty(activityItem[description]) && new DOMParser();
    const parsed = activityItem && !_.isEmpty(activityItem[description]) && parser.parseFromString(activityItem[description], 'text/html');
    const plainText = activityItem && !_.isEmpty(activityItem[description]) && parsed.body.textContent;
    const filteredLieuGrand = locationList.filter(ll => ll.id_lieu === dayContent.id_start);
    const filteredLieuGrandFin = locationList.filter(ll => ll.id_lieu === dayContent.id_end);
    let listFilteredByLocation = ''
    if (filterLoc) {
      listFilteredByLocation = activityList.filter(aL => ((aL.active !== '2' && aL.id_location === dayContent.id_end)
      || (aL.active !== '2' && !_.isEmpty(filteredLieuGrand) && aL.id_location_grand === filteredLieuGrand[0].id_lieu_grand)
      || (aL.active !== '2' && !_.isEmpty(filteredLieuGrandFin) && aL.id_location_grand === filteredLieuGrandFin[0].id_lieu_grand)
      || (aL.active !== '2' && aL.id_location === dayContent.id_start)
      || (aL.universel === 1)))
    } else {
      listFilteredByLocation = activityList
    }
    const HtmlTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 520,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);
    return (
      <div>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={2} xs={12} className="version-grid">
            <FormControl variant="outlined" className="version-form">
              <InputLabel id="label-version" className="label-version">Version</InputLabel>
              <Select
                id="version"
                label="Version"
                select="true"
                value={versionValue}
                open={isOpenVersionList}
                onChange={this.versionChange}
                onOpen={this.openVersionList}
                onClose={this.closeVersionList}
                input={<CssSelect />}
                variant="outlined"
                className="version-select"
              >
                {
                  versionList.map(day => (
                    <MenuItem
                      // if is new day give a key number
                      key={day.id_journee || 9999}
                      value={day.version}
                      selected={versionValue === dayContent.version}
                    >
                      {day.version.toString()}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </CssGrid>
          <CssGrid item md={3} xs={12}>
            <Input
              id="dayduration"
              label="Duration in day(s)"
              value={!_.isEmpty(dayContent) && dayContent.day_duration ? dayContent.day_duration : ''}
              onChange={this.handleChange('day_duration')}
              type="number"
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
          </CssGrid>
          <CssGrid item md={3} xs={12}>
            <AutocompleteSelectAmongExisting
              field="country"
              label="country"
              dayContent={dayContent}
              daysList={daysList}
              readOnlyProps={false}
              updateDayContent={updatedDay => this.updateDayContent(updatedDay)}
            />
          </CssGrid>
          <CssGrid item md={3} xs={12}>
            <Input
              id="daylanguage"
              value={!_.isEmpty(dayContent) && dayContent.language ? dayContent.language : userAccount.language}
              onChange={this.handleChange('language')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              disabled
            />
          </CssGrid>
        </CssGrid>

        <CssGrid item md={12} xs={12}>
          <Input
            id="shortTitle"
            label="Short Name"
            value={!_.isEmpty(dayContent) && dayContent.nom_journee_court ? dayContent.nom_journee_court : ''}
            onChange={this.handleChange('nom_journee_court')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
        </CssGrid>

        <div className="editorStyle">
          <p className="editorTitle background-white">Description</p>
          {this.displayTextEditor()}
        </div>
        {/* <CssGrid
          container
          spacing={2}
        > */}
        <CssGrid item xs={12}>
          {this.displayAutocompleteLocation('start', dayContent, locationList)}
          {this.displayAutocompleteLocation('end', dayContent, locationList)}
          <Input
            id="transport"
            label="Transport"
            value={!_.isEmpty(dayContent) && dayContent.transport ? dayContent.transport : ''}
            onChange={this.handleChange('transport')}
            margin="normal"
            variant="outlined"
            className="largeWidth"
          />
          <FormControlLabel
            control={(
              <CssCheckbox
                checked={filterLoc ? !!filterLoc : false}
                onChange={() => this.setState({ filterLoc: !filterLoc })}
                name="filter by location or province"
              />
              )}
            label="filter by location or province"
          />
          {dayContent && !_.isEmpty(dayContent) && this.displayAutocompleteActivity('day_activity', dayContent, listFilteredByLocation)}
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={8} className="option-div">
              {this.displayInputActivity(listFilteredByLocation)}
            </Grid>
            {activityItem && activityItem.id_activity && (
            <Grid item md={1} xs={1} className="option-div">
              <div className="option-div-css">
                <HtmlTooltip
                  className="tooltip-activity"
                  placement="right-start"
                  title={(
                    <React.Fragment>
                      <Interweave content={activityItem ? activityItem[description] : ''} />
                    </React.Fragment>
                  )}
                >
                  <Show />
                </HtmlTooltip>
              </div>
            </Grid>
            )}
            {activityItem && activityItem.id_activity && (
            <Grid item md={1} xs={1} className="option-div">
              <div className="option-div-css">
                <Tooltip title="copy as text" aria-label="copy as text">
                  <CopyToClipboard text={plainText}>
                    <Copy />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </Grid>
            )}
            {activityItem && activityItem.id_activity && (
            <Grid item md={1} xs={1} className="option-div">
              <div className="option-div-css">
                <Tooltip title="copy as html" aria-label="copy as html">
                  <CopyToClipboard text={activityItem && activityItem[description]}>
                    <Copyhtml />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </Grid>
            )}
          </Grid>
          {
            (theme_devis === 'Horizon' || theme_devis === 'evasion')
              ? (
            // for historical reason we use the field categorie to store the distance km
                <div>
                  <Input
                    id="distance"
                    label="Distance text "
                    value={!_.isEmpty(dayContent) && dayContent.distance ? dayContent.distance : ''}
                    onChange={this.handleChange('distance')}
                    margin="normal"
                    multiline
                    variant="outlined"
                    className="largeWidth"
                  />
                  <Input
                    id="distancenumber"
                    label="Distance(KM)"
                    type="number"
                    value={!_.isEmpty(dayContent) && dayContent.categorie ? dayContent.categorie : ''}
                    onChange={this.handleChange('categorie')}
                    margin="normal"
                    variant="outlined"
                    className="largeWidth"
                  />
                  <Input
                    id="distance_unit"
                    label="distance unit"
                    select
                    value={!_.isEmpty(dayContent) && dayContent.distance_unit ? dayContent.distance_unit : ''}
                    onChange={this.handleChange('distance_unit')}
                    margin="normal"
                    variant="outlined"
                    className="largeWidth"
                  >
                    <MenuItem value="KM">KM</MenuItem>
                    <MenuItem value="KM_MOUNTAIN">KM mountain</MenuItem>
                  </Input>
                </div>
              )
              : (
            // for historical reason we use the field categorie to store the distance km
                <div>
                  <Input
                    id="distancenumber"
                    label="Distance(KM)"
                    type="number"
                    value={!_.isEmpty(dayContent) && dayContent.categorie ? dayContent.categorie : ''}
                    onChange={this.handleChange('categorie')}
                    margin="normal"
                    variant="outlined"
                    className="largeWidth"
                  />
                  <Input
                    id="distance_unit"
                    label="distance unit"
                    select
                    value={!_.isEmpty(dayContent) && dayContent.distance_unit ? dayContent.distance_unit : ''}
                    onChange={this.handleChange('distance_unit')}
                    margin="normal"
                    variant="outlined"
                    className="largeWidth"
                  >
                    <MenuItem value="KM">KM</MenuItem>
                    <MenuItem value="KM_MOUNTAIN">KM mountain</MenuItem>
                  </Input>
                </div>
              )
          }
          {userAccount.profil <= 2 ? this.displayArchiveRadioButton() : null}
        </CssGrid>
        {/* </CssGrid> */}
      </div>
    )
  }

  displayPictures = () => {
    const {
      dayContent,
    } = this.state
    return (
      <CssGrid
        item
        xs={12}
        spacing={2}
      >
        <Uploader
          imageDataArray={this.getImageUrl()}
          saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
          redirectToGallery={() => this.redirectToGallery()}
          deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
          allowDrop={!!(dayContent && dayContent.nom_journee)}
          nbMaxImg="3"
          inLine="inLine"
        />
      </CssGrid>
    )
  }

  disabledArchiveButton = () => {
    const { dayContent } = this.state
    const { userAccount } = this.props
    return !dayContent.nom_journee || userAccount.profil > 1
  }

  displayArchiveRadioButton = () => {
    const { dayContent } = this.state
    return (
      <FormControl component="fieldset" disabled={this.disabledArchiveButton()}>
        <RadioGroup className="radio-group-active" aria-label="gender" name="gender1" value={!_.isEmpty(dayContent) && dayContent.active ? dayContent.active : '1'} onChange={this.handleRadioChange}>
          <FormControlLabel value="1" control={<CSSRadio />} label="Active" />
          <FormControlLabel value="0" control={<CSSRadio />} label="Archive" />
        </RadioGroup>
      </FormControl>
    )
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      dayContent,
      daysList,
      versionValue,
    } = this.state
    const dayId = dayContent.id_journee
    const { DELETE_DAY_CONTENT } = this.props
    const compteVersion = daysList.filter(d => d.nom_journee === dayContent.nom_journee).length

    if (versionValue === compteVersion) {
      if (confirm('Do you really want to delete this day ?')) {
        if (dayId) {
          DELETE_DAY_CONTENT(token, dayId)
        } else {
          this.setState({
            validateError: true,
            errorMsg: dayContent.nom_journee ? 'This day do not exist.' : 'Please, select a day.',
          })
        }
      }
    } else {
      alert('You can only delete or archive the higher version of the day')
    }
  }

  // Modify
  modifyItemTitle = () => {
    this.handleOpen()
  }

  goBack = () => {
    const { history } = this.props
    const { sourcePage } = this.state
    if (sourcePage) history.push(sourcePage)
  }

  // *******************************
  // Validate
  validate = () => {
    const {
      dayContent,
      newImageArray,
      isNewDay,
      token,
    } = this.state
    const { PUT_DAY_CONTENT } = this.props
    // Check all required inputs
    if (!dayContent.nom_journee || !dayContent.id_start || !dayContent.id_end) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }

    this.setState({
      isValidated: true,
    })
    // fill all keys
    const newDayContent = {
      ...dayContent,
      nom_journee: dayContent.nom_journee ? dayContent.nom_journee : dayContent.nom_journee_court,
      nom_journee_court: dayContent.nom_journee_court ? dayContent.nom_journee_court : dayContent.nom_journee,
      version: dayContent.version ? dayContent.version : 1,
      day_duration: dayContent.day_duration ? dayContent.day_duration : 1,
      day_activity: dayContent.day_activity ? dayContent.day_activity : '',
      id_start: dayContent.id_start || null,
      id_end: dayContent.id_end || null,
      desc_journee: dayContent.desc_journee ? dayContent.desc_journee.toString().replace(/"/g, '\\"') : '',
      transport: dayContent.transport ? dayContent.transport : '',
      distance: dayContent.distance ? dayContent.distance : '',
      distance_unit: dayContent.distance_unit ? dayContent.distance_unit : '',
      categorie: dayContent.categorie ? dayContent.categorie : '',
      image: newImageArray.join() || dayContent.image || '',
      active: dayContent.active ? dayContent.active : '1',
    }

    if (isNewDay) PUT_DAY_CONTENT(token, newDayContent, 'create')
    else PUT_DAY_CONTENT(token, newDayContent, 'update')
    return null
  }

  // *************************************
  createNewLocation = (locationContent) => {
    Geocode.fromAddress(locationContent.lieu).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, locationContent);
      },
      (error) => {
        console.error(error);
        alert('cannot find GPS information for this location, the location is created without GPS information, if needed consult your administrator to manually add GPS info at thomas.damien@duyen.pro.')

        this.createNewLocation_withGeoLoc('', locationContent);
      },
    )
  }


  // create a new location


  createNewLocation_withGeoLoc = (response, locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu = locationContent.lieu
    locationDetails.geocode = response !== '' ? response.results[0].geometry.location : ''
    const { PUT_LOCATION_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }

  // **************************
  // Modal action

  getModifiedItem = (item) => {
    const { dayContent } = this.state
    this.setState({
      dayContent: {
        ...dayContent,
        nom_journee: item.day,
      },
    }, () => {
      this.handleClose()
      this.validate()
    })
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  // TABS
  onChangeTab = (event, keyTab) => {
    this.setState({
      keyTab,
    })
  }

  tabProps = index => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  // **********************************
  render() {
    const {
      sortedUniqDayList,
      restrictedList,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      dayContent,
      btnActionDisabled,
      actionLabel,
      isModalOpen,
      keyTab,
    } = this.state

    const selectableDaysList = !restrictedList ? sortedUniqDayList : restrictedList
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>DAY MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <BackButton
                type="button"
                label="Back"
                goBack={() => this.goBack()}
              />
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="General" {...this.tabProps(0)} />
                  <Tab label="Photos" {...this.tabProps(1)} />
                </AntTabs>
              </AppBar>
              <TabPanel value={keyTab} index={0}>
                {dayContent && this.displayAutocompleteDay('title', dayContent, selectableDaysList)}
                {dayContent && this.displayDayContentInputs()}
              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                {this.displayPictures()}
              </TabPanel>
            </div>
            <CssGrid
              container
              spacing={2}
              className="actionContainer"
            >
              <CssGrid item md={4} xs={12}>
                <ActionButton
                  id="saveAsNewVersionBtn"
                  label="Save as new version"
                  disabled={!dayContent.id_journee || btnActionDisabled}
                  onClick={() => this.addVersion()}
                />
              </CssGrid>
              <CssGrid item md={4} xs={12}>
                <ActionButton
                  id="saveBtn"
                  label={actionLabel}
                  disabled={(!dayContent && btnActionDisabled)}
                  onClick={() => this.validate()}
                />
              </CssGrid>
            </CssGrid>
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the new title for this day"
            modalContent="day"
            warning="This will update this day in all quotes !"
            type="day"
            inputValue={!_.isEmpty(dayContent) ? dayContent.nom_journee : ''}
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  daysList: _.filter(state.api.daysList, d => d.id_journee !== 1),
  putDayContent: state.api.putDayContent,
  activityList: state.activityReducer.activityList,
  locationList: state.api.locationList,
  putLocationContent: state.api.putLocationContent,
  deleteDayContent: state.api.deleteDayContent,
  userAccount: state.userAccountReducer.userAccount,
  informationsContent: state.informationsReducer.informationsContent,
})

const mapDispatchToProps = dispatch => ({
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_INFORMATIONS_CONTENT: userId => dispatch({
    type: 'GET_INFORMATIONS_CONTENT',
    userId,
  }),
  GET_DAYS_LIST: userId => dispatch({
    type: 'GET_DAYS_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  PUT_DAY_CONTENT: (token, dayContent, action) => dispatch({
    type: 'PUT_DAY_CONTENT',
    token,
    dayContent,
    action,
  }),
  DELETE_DAY_CONTENT: (token, dayId) => dispatch({
    type: 'DELETE_DAY_CONTENT',
    token,
    dayId,
  }),
  RESET_DAY_CONTENT: () => dispatch({
    type: 'RESET_DAY_CONTENT',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Day))
