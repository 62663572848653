import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_trip_extension(tripExtensionList) {
    const list = tripExtensionList || '0'
    return axios.get(`${apiUrl}/api/tripExtension/${list}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_trip_extension(token, extension, idTour, idDemandeur, idTrip, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/tripExtension/update/${idTour}`, { extension }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'version':
        return axios.put(`${apiUrl}/api/tripExtension/create/${idTour}/${idDemandeur}/${idTrip}`, { extension }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
}
