
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import guidecheck from '../../../assets/images/guide-white@2x.png'
import drivercheck from '../../../assets/images/driver-white@2x.png'
import hotel from '../../../assets/images/hotel.png'
import meal from '../../../assets/images/meal.png'

import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlDuyen extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripDevisFinal: PropTypes.object,
    tripAllDaysAccomodations: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripBilling: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},

    tripDetails: [],
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    tripContent: [],
    tripDevisFinal: {},
    tripAccomodation: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',


    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--Carte-size', `${event.currentTarget.value}px`)
  }

  handleOSizeChange2 = (event) => {
    document.documentElement.style.setProperty('--hauteurBref-size-duyen', `${event.currentTarget.value}px`)
  }

  handleOSizeChange3 = (event) => {
    document.documentElement.style.setProperty('--hauteurCover-size-duyen', `${event.currentTarget.value}px`)
  }

  handlePrint = (html) => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }

  handleSave = (devisContent) => {
    const {
      PUT_TRIP_DEVIS_FINAL,
      tripDetails,
      tripDevisFinal,
    } = this.props
    const {
      token,
    } = this.state
    const tripDevisFinalServeur = {}
    tripDevisFinalServeur.textHtml = devisContent
    tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
    } else {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
    }
  }

  handleEditorChange = (content) => {
    this.setState({
      devisContent: content,
    });
  }


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word(element, filename = '') {
    const html = element;
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }


  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];
      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAllDaysAccomodations,
      tripAccomodation,
      tripPension,
      tripBilling,
      userAccount,
      companyContent,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]

    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      langage,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      devisContent,

    } = this.state

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************

    const Couleurdefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const description = tripDetails[0].desc_voyage;
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const NombreInformation = [0, 1, 2, 3, 4];
    const NombreJourVoyage = tripAllDays.length
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`

    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const nbAdult = nb_adulte && nb_adulte !== '0' ? `${nb_adulte} ${nb_adulte > 1 ? langageTranslation.adults : langageTranslation.adult}` : ''
    const nbChildren = nb_enfant && nb_enfant !== '0' ? `${nb_enfant} ${nb_enfant > 1 ? langageTranslation.children : langageTranslation.child}` : ''
    const nbBaby = nb_bebe && nb_bebe !== '0' ? `${nb_bebe} ${nb_bebe > 1 ? langageTranslation.babies : langageTranslation.baby}` : ''
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const remarque = `${langage}_remarque`
    const slogan = `${langage}_slogan`
    const longueurVoyage = tripAllDaysAccomodations.length
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(IncDayTot - 1, 'd').format('DD MMMM YYYY')
    const presentation = `${langage}_presentation`
    const tauxchange = `${langage}_taux_change`
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }

    const enTete = (
      <div className="page-header-duyen">
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">
                <Interweave content={companyContent[slogan]} />
              </td>
              <td width="50%">
                <img width="100" height="100" className="LogoDuyen" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
              </td>
              <td width="25%">
                <div>
                  <br />
                  <div>{travelerName}</div>
                  <div>Du {dateDepart} au {dateFin}</div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    )
    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFile">
          {(indexDisplay === 'HTMLPDF' || indexDisplay === 'HTMLWEB')
           && (
           <div className="page-header-duyenhtml">
             <div className="logoTextDuyen">
               <div>
                 <br />
                 <div>{travelerName}</div>
                 <div>Du {dateDepart} au {dateFin}</div>
               </div>
               <img className="LogoDuyen" width="50" height="30" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />

               <Interweave content={companyContent[slogan]} />
             </div>
             <hr size="1" />
             <div className="navbar">
               <a href="#home">{langageTranslation.homeMenu}</a>
               {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
               <a href="#carte">{langageTranslation.carteMenu}</a>)}
               <a href="#detail">{langageTranslation.detailMenu}</a>
               <a href="#hotel">{langageTranslation.hotelMenu}</a>
               <a href="#prix">{langageTranslation.prixMenu}</a>
               <a href="#info">{langageTranslation.infoMenu}</a>
             </div>
           </div>
           )}
          <div>
            {(indexDisplay === 'HTMLPDF' || indexDisplay === 'HTMLWEB')
           && (
           <div className="page-header-duyen">
             <div className="logoTextDuyen">
               <div>
                 <br />
                 <div>{travelerName}</div>
                 <div>Du {dateDepart} au {dateFin}</div>
               </div>
               <img className="LogoDuyen" width="50" height="30" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />

               <Interweave content={companyContent[slogan]} />
             </div>
             <hr size="1" />
             <hr className="epaisseurLigne" />

           </div>
           )}
            <table className="table-horizon">
              {(indexDisplay === 'HTMLPDF' || indexDisplay === 'HTMLWEB')
           && (
           <thead>
             <tr>
               <td>
                 <div className="page-header-space-duyen" />
               </td>
             </tr>
           </thead>
           )}
              <tbody>
                <tr>
                  <td>
                    <div id="home" className="Banniere">
                      {(indexDisplay === 'HTMLPDF' || indexDisplay === 'HTMLWEB')
           && (
           <img width="300" height="700" className="ImageCover" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
           )}
                      <Grid className="InfoPremier" container spacing={0} align="row" justifyContent="center">
                        {(indexDisplay === 'HTMLPDF' || indexDisplay === 'HTMLWEB')
           && (
           <Grid item xs={12}>
             <img className="LogoDuyenCover" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
           </Grid>
           )}
                        <Grid item xs={12}>
                          <br />
                          <div className="Titre">{tripDetails && (tripDetails[0].nom_programme)}</div>
                        </Grid>
                        <Grid item xs={12}>
                          <br />
                          <div className="Titre">{NombreJourVoyage} {langageTranslation.daysOnSite}, {moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).format('DD MMMM YYYY')} </div>
                        </Grid>
                      </Grid>
                      <Grid className="InfoVoyageur" container spacing={0} align="row" justifyContent="center">
                        <Grid item xs={12}>
                          <div> {langageTranslation.forTheAttentionOf} </div>
                          <div>{travelerName}</div>
                          <div>{nbAdult && nbAdult} {nbChildren && nbChildren} {nbBaby && nbBaby}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="CoupePas">
                      <h2 className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.itineraryInBrief}</h2>
                      <Interweave content={description} />
                      <br />
                      <div className="TabItineraireGlobalDuyen">
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td width="10%" className="TabItineraire" style={Couleurdefaut}>{langageTranslation.day} </td>
                              <td width="10%" className="TabItineraire" style={Couleurdefaut}>{langageTranslation.date}</td>
                              <td width="50%" className="TabItineraire" style={Couleurdefaut}>{langageTranslation.itinerary}</td>
                              <td width="30%" className="TabItineraire" style={Couleurdefaut}>{langageTranslation.transport}</td>

                            </tr>
                            {tripAllDays.map((item, index) => {
                              const IncDay = _.sum(TabIncJour.slice(0, index))
                              const datejour = tripDetails[0].date_depart

                                ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMM'))
                                : ''
                              return (
                                <tr key={`${datejour}`}>
                                  <td width="10%" className="TabItineraire1">
                                    <a href={`#Jour${index}`}>
                                      Jour {IncDay + 1}
                                    </a>
                                  </td>
                                  <td width="10%" className="TabItineraire1">
                                    <a href={`#Jour${index}`}>
                                      {datejour}
                                    </a>
                                  </td>
                                  <td width="50%" className="TabItineraire1">
                                    <a href={`#Jour${index}`}>
                                      {item.nom_journee}
                                    </a>
                                  </td>
                                  <td width="30%" className="TabItineraire1">
                                    <a href={`#Jour${index}`}>
                                      {item.transport}
                                    </a>
                                  </td>

                                </tr>


                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {(tripDetails[0].image || tripDetails[0].map_localisation) && (

                    <div className="CoupePas">
                      {tripDetails[0].image
                        ? (
                          <div id="carte">
                            <h2 className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.mapOfTrip}</h2>
                            <br />
                            <br />
                            <Grid container spacing={0} justifyContent="center">
                              <Grid item xs={12}>
                                <img width="750" height="650" className="Carte" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                              </Grid>
                            </Grid>
                            <input className="adjustSize" name="size" min="200" max="1600" type="range" defaultValue="1200" onChange={this.handleOSizeChange} />
                          </div>
                        ) : (tripDetails[0].map_localisation && (
                        <div>
                          <h2 id="carte" className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.mapOfTrip}</h2>
                          <br />
                          <br />
                          <img className="Carte" src={urlMap} />
                        </div>
                        )
                        )
              }
                    </div>
                    )}
                    <div className="CoupePas">
                      <h2 id="detail" className="ProgrammeDetail" style={Couleurdefaut}>{langageTranslation.detailedProgram}</h2>

                      {tripAllDays.map((item_jour, index) => {
                        const desc = `desc_jour_${index + 1}`;
                        const imageArray = item_jour.image.split(',')
                        const IncDay = _.sum(TabIncJour.slice(0, index + 1))
                        const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))
                          : ''

                        const BandeauJour = () => (
                          <div className="BandeauJour" style={Couleurdefaut}>
                            <div className="CoupePas">
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td style={Couleurdefaut} width="15%" className="BandeauJourDetail">Jour {index + 1}</td>
                                    <td style={Couleurdefaut} width="70%" className="BandeauJourDetail">
                                      <div>{datejour}</div>
                                      <div id={`Jour${index}`}>{item_jour.nom_journee}</div>
                                    </td>
                                    <td style={Couleurdefaut} width="15%" className="BandeauJourDetail1">
                                      {guideArray[index] === '1' && (indexDisplay !== 'HTMLWORD' ? <img className="GuideDriver" src={guidecheck} /> : 'guide ')}
                                      {chauffeurArray[index] === '1' && (indexDisplay !== 'HTMLWORD' ? <img className="GuideDriver" src={drivercheck} /> : 'driver')}

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );

                        return (
                          <div key={`${datejour}`}>

                            {imageArray[0] !== '' && imageArray.length === 1
                              ? (
                                <div className="CoupePas">
                                  <BandeauJour />

                                  {(indexDisplay !== 'HTMLWORD')
                                      && (
                                        <Grid container spacing={2} justifyContent="center">
                                          <Grid className="OptionTextJournee" item xs={8} lg={8} sm={12}>
                                            <Interweave content={tripContent[0][desc]} />
                                          </Grid>

                                          <Grid className="Image1Jour1" xs={4} lg={4} sm={12}>
                                            <img width="250" height="150" className="Image1Jour1" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                          </Grid>
                                        </Grid>
                                      )}
                                  {(indexDisplay === 'HTMLWORD')
                                      && (
                                        <table width="100%">
                                          <tbody>
                                            <tr>
                                              <td width="70%" className="OptionTextJournee">
                                                <Interweave content={tripContent[0][desc]} />
                                              </td>
                                              <td width="30%" className="Image1Jour1">
                                                <img width="250" height="150" className="Image1Jour1" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}
                                </div>

                              ) : (
                                <div>
                                  <div className="CoupePas">
                                    <BandeauJour />
                                    <Grid container spacing={0} justifyContent="center">
                                      <Grid className="OptionTextJournee" item xs={12}>
                                        <Interweave content={tripContent[0][desc]} />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              )
            }
                            <div className="CoupePas">
                              <Grid container spacing={0}>
                                {indexDisplay !== 'HTMLWORD' && tripAccomodation[index].nom_hebergement !== 'none' && (<Grid className="Centre" item xs={1}><img className="ImageOptionService" src={hotel} /> </Grid>)}
                                {tripAccomodation[index].nom_hebergement !== 'none' && (<Grid className="OptionService" item xs={2}>{tripAccomodation[index].nom_hebergement}</Grid>)}
                                {tripAccomodation[index].nom_hebergement !== 'none' && (<Grid className="OptionService" item xs={2}>{!roomsNumberArray ? '' : nbOfRoomLabel(index)}</Grid>)}
                                {indexDisplay !== 'HTMLWORD' && tripPension[index].pension !== 'none' && (<Grid className="Centre" item xs={1}><img className="ImageOptionService" src={meal} /></Grid>)}
                                {tripPension[index].pension !== 'none' && (<Grid className="OptionService" item xs={2}>{tripPension[index].pension}</Grid>)}
                              </Grid>
                            </div>
                            {imageArray[0] !== '' && imageArray.length === 2
                && (
                  <div>
                    {indexDisplay !== 'HTMLWORD'
                  && (
                    <div>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6}>
                          <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                        </Grid>
                        <Grid item xs={6}>
                          <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                          </td>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}

                            {imageArray[0] !== '' && imageArray.length === 3
        && (
          <div>
            {(indexDisplay !== 'HTMLWORD')
          && (
          <div>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
              </Grid>
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
              </Grid>
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
              </Grid>
            </Grid>
          </div>
          )
                      }
            {indexDisplay === 'HTMLWORD'
                    && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="30%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
          </div>
        )}

                          </div>
                        )
                      })}
                    </div>
                    {tripBilling.displayTabCheck && (
                    <div>
                      <div className="CoupePas">
                        <Grid container spacing={0} align="row" justifyContent="center">
                          <Grid item xs={12}>
                            <h2 id="prix" className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.tripRate}</h2>
                            <br />
                            <div style={Couleurdefaut} className="Titre2CircuitHtml"> {tripDetails[0].nom_programme}</div>
                          </Grid>
                          <Grid item xs={12}>
                            <Interweave content={tripBilling.tabPrice} />
                          </Grid>
                        </Grid>

                        <br />
                        <div className="Titre2CircuitHtml" style={Couleurdefaut}>{informationsContent[tauxchange]}</div>

                      </div>
                      <h3>{langageTranslation.priceContained}</h3><Interweave content={tripDetails[0].prix_comprend} /><h3>{langageTranslation.priceDoNotContained}</h3>
                      <Interweave content={tripDetails[0].prix_comprend_pas} />
                    </div>
                    )}
                    <h2 id="hotel" className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.accomodation}</h2>
                    <br />
                    {Hotel_Unique.map((item, i) => {
                      const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                      const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                      const desc_hebergement = `${langage}_${desc_hebergement}`
                      return (
                        <div key={`${item.id_journee}`}>
                          {item.nom_hebergement !== 'none'
              && (
              <div>
                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Titre2CircuitHtml" style={Couleurdefaut} item xs={12}>
                    <div>{LieuFinJournee.lieu}</div>
                    <div>{langageTranslation.accomodation} : {item.nom_hebergement} - {Nombre_Occurence_Hotel[i].occurrence} {langageTranslation.night}</div>
                  </Grid>
                  {item.desc_hebergement && (
                  <Grid className="OptionTextJournee" item xs={12}>
                    <Interweave content={item.desc_hebergement} />
                  </Grid>

                  )}
                </Grid>
                {imageArrayHotel[0] !== '' && imageArrayHotel.length === 1
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
                  && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                    </Grid>
                  </Grid>
                  )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}
                {imageArrayHotel[0] !== '' && imageArrayHotel.length === 2
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
                  && (
                  <div>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                      </Grid>
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                      </Grid>
                    </Grid>
                  </div>
                  )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}
                {imageArrayHotel[0] !== '' && imageArrayHotel.length === 3
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
                  && (
                  <div>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={4}>
                        <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                      </Grid>
                      <Grid item xs={4}>
                        <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                      </Grid>
                      <Grid item xs={4}>
                        <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                      </Grid>
                    </Grid>
                  </div>
                  )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                          </td>
                          <td width="40%">
                            <img width="250" height="150" className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}
              </div>

              )}

                        </div>

                      )
                    })}
                    <h2 id="info" className="Titre2CircuitHtml" style={Couleurdefaut}>{langageTranslation.whyChooseUs}</h2>
                    {NombreInformation.map((item, index) => {
                      const InformationText = langage && `${langage}_information_${index + 1}_description`;
                      const InformationImage = `information_${index + 1}_image`;
                      const InformationTitre = langage && `${langage}_information_${index + 1}`;

                      return (
                        <div key={`info${item}`}>
                          {informationsContent[InformationText] !== ''
                && (
                <div className="CoupePas">
                  <br />
                  <div style={Fonddefaut} className="flexInfoContainer">
                    <div className="TitreInfoGeneralDuyen">{informationsContent[InformationTitre]}</div>
                    <button id={`togg${index}`} type="button">
                      <svg width="20" height="20">
                        <line x1="1" x2="5" y1="1" y2="15" stroke="black" strokeWidth="1" />
                        <line x1="5" x2="9" y1="15" y2="1" stroke="black" strokeWidth="1" />
                      </svg>
                    </button>
                  </div>
                  <br />
                  {informationsContent[InformationImage] !== '' ? (
                    <Grid id={`d${index}`} lcontainer spacing={1} justifyContent="center">
                      {(indexDisplay !== 'HTMLWORD')
                      && (
                      <Grid item xs={4}>
                        <img width="250" height="150" className="imgInformation" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />
                      </Grid>
                      )}
                      <Grid item xs={8}>
                        <Interweave content={informationsContent[InformationText]} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid id={`d${index}`} container spacing={1} justifyContent="center">
                      <Grid item xs={12}>
                        <Interweave content={informationsContent[InformationText]} />
                      </Grid>
                    </Grid>
                  )
                  }
                </div>
                )}
                        </div>
                      )
                    })}
                    {langage && langage === 'fr' && (
                    <div className="videoBoxDuyen">
                      <div style={Fonddefaut} className="TitreInfoGeneralDuyen">Video</div>
                      <br />
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoDuyen" width="424" height="238" src="https://www.youtube.com/embed/Fv2XiIerdSU" title="Présentation de Duyên" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

                        </Grid>
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoDuyen" width="424" height="238" src="https://www.youtube.com/embed/NykjsBWz0vk" title="Ils sont satisfaits de leur voyage au Vietnam grâce à Horizon Vietnam Travel - Avis des voyageurs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                      </Grid>
                    </div>
                    )}
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={12}>
                        <Interweave content={informationsContent[remarque]} />
                      </Grid>
                    </Grid>
                    <div className="CoupePas">
                      <div className="titreGras">{advisorName}</div>
                      <img className="LogoDuyen" width="50" height="30" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                      <Interweave content={userAccount[presentation]} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      )
      return myPage
    }

    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');
    const headerHtml = renderToStaticMarkup(enTete)
    const pageHtml = renderToStaticMarkup(myPageFunc('HTMLWEB'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlDuyen.css">
     <title>Votre proposition de voyage</title>     
     </head>
     <body>   
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a> 
        ${devisContent}
      </body>
   </html>
   <script>
   let togg0 = document.getElementById("togg0");
   let togg3 = document.getElementById("togg3");
   let togg4 = document.getElementById("togg4");

     let togg1 = document.getElementById("togg1");
let togg2 = document.getElementById("togg2");
let d0 = document.getElementById("d0");
let d1 = document.getElementById("d1");
let d2 = document.getElementById("d2");
let d3 = document.getElementById("d3");
let d4 = document.getElementById("d4");
togg0.addEventListener("click", () => {
  if(getComputedStyle(d0).display != "none"){
    d0.style.display = "none";
  } else {
    d0.style.display = "block";
  }
})
togg1.addEventListener("click", () => {
  if(getComputedStyle(d1).display != "none"){
    d1.style.display = "none";
  } else {
    d1.style.display = "block";
  }
})
togg2.addEventListener("click", () => {
  if(getComputedStyle(d2).display != "none"){
    d2.style.display = "none";
  } else {
    d2.style.display = "block";
  }
})
togg3.addEventListener("click", () => {
  if(getComputedStyle(d3).display != "none"){
    d3.style.display = "none";
  } else {
    d3.style.display = "block";
  }
})
togg4.addEventListener("click", () => {
  if(getComputedStyle(d4).display != "none"){
    d4.style.display = "none";
  } else {
    d4.style.display = "block";
  }
})


     </script>
   `;

    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlHorizon.css">
     <title>Votre proposition de voyage avec Horizon Vietnam Travel</title>
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>

<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
   
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
             <div style='mso-element:header' id="h1" >
                 <p class="MsoHeader">
                     <table border="0" width="100%">
                         <tr>
                             <td>

                                 ${headerHtml}

                             </td>
                         </tr>
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;


    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}.docx`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlDuyen.css"
          height={1000}
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          handleEditorChange={content => this.handleEditorChange(content)}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripDevisFinal: state.api.tripDevisFinal,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlDuyen))
