import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import axios from 'axios';
import api from '../../components/api/api'
import { Input } from '../../components'
import BootstrapButton from '../../assets/styles/bootstrapButton'
import duyen from '../../assets/images/duyen.png'
import duyen2x from '../../assets/images/duyen@2x.png'
import duyen3x from '../../assets/images/duyen@3x.png'
import stage from '../../config'

class SignUp extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sendForm = this.sendForm.bind(this)
    this.control = this.control.bind(this)
    this.error = this.error.bind(this)
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmedPassword: '',
      ctrlError: null,
    };
  }

  componentDidMount() {
    // Load all saved email in database
    api.get_list_email_users()
  }

  goTo = () => {
    const { history } = this.props
    history.push('/')
  }

  handleChange = field => (event) => {
    this.setState({ [field]: event.target.value });
  };

  control = () => {
    const {
      email,
      password,
      confirmedPassword,
    } = this.state
    const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
    const isPassword = /^\w{8,}$/g
    const ctrlEmail = isEmail.test(email)
    const ctrlPassword = isPassword.test(password)
    // verify password conformity
    if (!ctrlPassword) {
      this.setState({
        ctrlError: 'password not conform',
      })
      return false
    }
    // verify email conformity
    if (!ctrlEmail) {
      this.setState({
        ctrlError: 'not valid mail',
      })
      return false
    }
    // verify confirmation password
    if (password !== confirmedPassword) {
      this.setState({
        ctrlError: 'password not confirmed',
      })
      return false
    }
    this.setState({
      ctrlError: null,
    })
    const isValidForm = ctrlEmail && ctrlPassword
    return isValidForm
  }

  error = () => {
    const { ctrlError } = this.state
    return ctrlError ? <p style={{ color: 'red' }}>{ctrlError}</p> : null
  }

  sendForm = () => {
    event.preventDefault()
    const apiUrl = stage.api.duyen.url
    const {
      firstname,
      lastname,
      email,
      password,
    } = this.state
    if (this.control()) {
      // post new user
      axios.post(`${apiUrl}/api/register`, {
        firstname,
        lastname,
        email,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            alert('Your account has been successfully created')
            this.goTo()
          } else {
            return this.error()
          }
          return null
        })
        .catch(err => console.log(err))
    } else {
      this.error()
    }
  }

  render() {
    const {
      firstname,
      lastname,
      email,
      password,
      confirmedPassword,
    } = this.state
    return (
      <form className="signin" method="POST" onSubmit={this.sendForm}>
        <img
          className="logo"
          src={duyen}
          srcSet={`${duyen2x} 2x, ${duyen3x} 3x`}
          alt=""
          style={{ width: '200px', marginBottom: '30px' }}
        />
        {this.error()}
        <Input
          id="firstname"
          label="Firstname"
          value={firstname}
          className="inputWidth"
          onChange={this.handleChange('firstname')}
          margin="normal"
          required
        />
        <Input
          id="lastname"
          label="Lastname"
          value={lastname}
          className="inputWidth"
          onChange={this.handleChange('lastname')}
          margin="normal"
          required
        />
        <Input
          id="email"
          label="Mail"
          value={email}
          className="inputWidth"
          onChange={this.handleChange('email')}
          margin="normal"
          required
        />
        <Input
          id="password"
          label="Password"
          value={password}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('password')}
          margin="normal"
          required
        />
        <Input
          id="confirmPassword"
          label="Confirm Password"
          value={confirmedPassword}
          type="password"
          className="inputWidth"
          onChange={this.handleChange('confirmedPassword')}
          margin="normal"
          required
        />
        <BootstrapButton
          type="submit"
          variant="contained"
          color="primary"
          disableRipple
        >
          SEND
        </BootstrapButton>
      </form>
    );
  }
}

export default withRouter(SignUp)
