import {
  GET_ACTIVITY_LIST_RESULTS,
  PUT_ACTIVITY_CONTENT_RESULTS,
  DELETE_ACTIVITY_CONTENT_RESULTS,
  RESET_ACTIVITY_CONTENT,
} from './actions'
import {
  RESET_APP,
} from '../../../../components/api/actions'

const initialState = {
  activityList: [],
  putInformationsContent: { success: false },
  deleteActivityContent: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_ACTIVITY_LIST_RESULTS:
      return {
        ...state,
        activityList: action.activityList,
      }
    case PUT_ACTIVITY_CONTENT_RESULTS:
      return {
        ...state,
        putActivityContent: action.putActivityContent,
      }
    case DELETE_ACTIVITY_CONTENT_RESULTS:
      return {
        ...state,
        deleteActivityContent: action.deleteActivityContent,
      }
    case RESET_ACTIVITY_CONTENT:
      return {
        ...state,
        putActivityContent: { success: false },
        deleteActivityContent: null,
      }
    default:
      return state
  }
}
