import axios from 'axios'
import stage from '../../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_activity_list(userId) {
    return axios.get(`${apiUrl}/api/lists/activityList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_activity_content(token, activityContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/activity/update`, { activityContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/activity/create`, { activityContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_activity_content(token, activityId) {
    return axios.delete(`${apiUrl}/api/management/activity/delete/${activityId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
