import axios from 'axios'
import stage from '../../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_informations_content(userId) {
    return axios.get(`${apiUrl}/api/management/informations/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_informations_content(token, informationsContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/management/informations/update`, { informationsContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/management/informations/create`, { informationsContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  // delete_informations_content(token, customerId) {
  //   return axios.delete(`${apiUrl}/api/management/informations/${customerId}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then(res => res.data)
  // },
}
