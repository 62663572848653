export const GET_COLLABORATORS_RESULTS = 'GET_COLLABORATORS_RESULTS'
export const PUT_COLLABORATORS_RESULTS = 'PUT_COLLABORATORS_RESULTS'
export const DELETE_COLLABORATOR_RESULTS = 'DELETE_COLLABORATOR_RESULTS'
export const RESET_COLLABORATORS = 'RESET_COLLABORATORS'

export const getCollaboratorsResults = data => ({
  type: GET_COLLABORATORS_RESULTS,
  collaborators: data,
})

export const putCollaboratorsResults = data => ({
  type: PUT_COLLABORATORS_RESULTS,
  putCollaborators: data,
})

export const deleteCollaboratorResults = data => ({
  type: DELETE_COLLABORATOR_RESULTS,
  deleteCollaborator: data,
})

export const resetCollaborators = () => ({
  type: RESET_COLLABORATORS,
})
