import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';

export default function CheckIconItem(props) {
  const {
    extension,
  } = props;
  const needFilledBackground = extension ? 'filled' : null

  return (
    <div className="check-icon-flex">
      <div className={`check-icon-container ${needFilledBackground}`}>
        {extension
          ? (
            <CheckIcon
              fontSize="large"
            />
          )
          : null
        }
      </div>
    </div>
  )
}

CheckIconItem.propTypes = {
  extension: PropTypes.string,
};

CheckIconItem.defaultProps = {
  extension: '',
}
