import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  // *************** RESOURCES *************************
  get_resources(token) {
    return axios.get(`${apiUrl}/api/resources/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_resources(token, resource, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/resources/update`, { resource }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/resources/create`, { resource }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_resources(token, resourceId) {
    return axios.delete(`${apiUrl}/api/resources/delete/${resourceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  // *************** RESERVATIONS *************************
  get_reservations(token) {
    return axios.get(`${apiUrl}/api/reservations/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_reservations(token, event, action) {
    switch (action) {
      case 'modify':
        return axios.put(`${apiUrl}/api/reservations/update`, { event }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/reservations/create`, { event }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },
  delete_reservations(token, eventId) {
    return axios.delete(`${apiUrl}/api/reservations/delete/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
