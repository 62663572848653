
/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactDOMServer from 'react-dom/server';


class DevExperiment extends PureComponent {
  /* eslint-disable react/jsx-filename-extension */

 static propTypes = {


   companyContent: PropTypes.object,

 }

  static defaultProps = {

    companyContent: {},

  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    const {
      companyContent,

    } = this.props
    const myPage = (
      <div className="GlobalFileHvt">
        {companyContent.phone}
      </div>
    )
    const staticMarkup = ReactDOMServer.renderToStaticMarkup(myPage);

    return (
      <div>
        <div>{staticMarkup}</div>
        <img className="Image1Jour1" src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/horizon_vietnam_voyage/nha tho duc ba - sai gon-1650447993816.jpg" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,

})


export default withRouter(connect(mapStateToProps)(DevExperiment))
