export const GET_REQUEST_STATUS_LIST_RESULTS = 'GET_REQUEST_STATUS_LIST_RESULTS'
export const GET_REQUEST_RESULTS = 'GET_REQUEST_RESULTS'
export const PUT_REQUEST_RESULTS = 'PUT_REQUEST_RESULTS'
export const PUT_REQUEST_ACCEPT_RESULTS = 'PUT_REQUEST_ACCEPT_RESULTS'
export const PUT_REQUEST_UPDATE_RESULTS = 'PUT_REQUEST_UPDATE_RESULTS'
export const DELETE_REQUEST_RESULTS = 'DELETE_REQUEST_RESULTS'
export const RESET_REQUEST = 'RESET_REQUEST'

export const getRequestStatusListResults = data => ({
  type: GET_REQUEST_STATUS_LIST_RESULTS,
  requestStatusList: data,
})

export const getRequestResults = data => ({
  type: GET_REQUEST_RESULTS,
  request: data,
})

export const putRequestResults = data => ({
  type: PUT_REQUEST_RESULTS,
  putRequest: data,
})

export const putRequestAcceptResults = data => ({
  type: PUT_REQUEST_ACCEPT_RESULTS,
  putRequestAccept: data,
})

export const putRequestUpdateResults = data => ({
  type: PUT_REQUEST_UPDATE_RESULTS,
  putRequestUpdate: data,
})

export const deleteRequestResults = data => ({
  type: DELETE_REQUEST_RESULTS,
  deleteRequest: data,
})

export const resetRequest = () => ({
  type: RESET_REQUEST,
})
