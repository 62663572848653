
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import stage from '../../../config'
import {
  ActionButton,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlAtypique extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripBilling: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    userAccount: PropTypes.object,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    tripDetails: [],
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    tripContent: [],
    tripAccomodation: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',

    };
  }

  componentDidMount() {

  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteAtypique-size', `${event.currentTarget.value}px`)
  }

  handleOSizeChange2 = (event) => {
    document.documentElement.style.setProperty('--hauteurBrefAtypique-size', `${event.currentTarget.value}px`)
  }


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  render() {
    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAllDaysAccomodations,
      tripAccomodation,
      tripPension,
      tripBilling,
      userAccount,
      companyContent,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      nb_chambre,
      type_chambre,
    } = tripContent[0]

    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      desc_voyage,
      centre_interet,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      langage,
      code_devis,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
    } = this.state

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************

    const Couleurdefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const Nombre_Personne = nb_adulte + nb_enfant + nb_bebe
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    const presentation = `${langage}_presentation`
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const longueurVoyage = tripAllDaysAccomodations.length
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(longueurVoyage, 'd').format('DD MMMM YYYY')
    const remarque = `${langage}_remarque`
    const slogan = `${langage}_slogan`
    const InformationImage = 'information_1_image';
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    // const afficheLieu = tripLocalisationList && tripLocalisationList.map(item => (` ${item}`)).toString() */ }
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }
    const myPage = (
      <div className="GlobalFileAtypique">
        <div className="page-header-Atypiquehtml">
          <Grid container justifycontent="space-between" spacing={0}>
            <Grid item xs={3}>
              <img className="LogoAtypique" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
            </Grid>
            <Grid item xs={6}>
              <Interweave content={companyContent[slogan]} />
            </Grid>
            <Grid item xs={3}>
              <div className="centreAtypique">
                <br />
                <div>Devis N°{code_devis} </div>
              </div>
            </Grid>
          </Grid>
          <div className="navbar">
            <a href="#home">Votre voyage</a>
            {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
            <a href="#carte">{langageTranslation.carteMenu}</a>)}
            <a href="#detail">Votre itinéraire</a>
            <a href="#prix">Devis</a>
            <a href="#info">Infos utiles et CGV</a>
            <a href="#video">Video</a>

          </div>

        </div>


        <div>


          <div className="page-footer-atypique">
            <Interweave content={companyContent.description} />
          </div>
          <table className="table-atypique">
            <tbody>
              <tr>
                <td>
                  <img className="ImageCoverAtypique" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
                  <br />
                  <div className="TitreAtypique1">{tripDetails[0].nom_programme} - N°{tripDetails[0].version}</div>
                  <div className="TitreAtypique1">{travelerName}</div>
                  <div className="TitreAtypique1">Du {dateDepart} au {dateFin}</div>

                  <div className="CoupePasAtypique">
                    <img className="bandeauAtypique" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />

                    <br />
                    <h2 id="home" className="Titre2CircuitAtypique" style={Couleurdefaut}>VOTRE VOYAGE</h2>
                    <br />
                    <Grid container spacing={0} justifyContent="center">
                      <Grid className="tabIntroAtypique" item xs={5}>
                        <Interweave content={desc_voyage} />
                      </Grid>
                      <Grid className="tabIntroAtypique" item xs={7}>
                        <div>Du {dateDepart} au {dateFin}</div>
                        <br />
                        {nb_adulte && <div>Nombre de voyageurs :</div>}
                        {nb_adulte && <div>{nb_adulte}</div>}
                        {nb_enfant && <div>{nb_enfant}</div>}
                        {nb_bebe && <div>{nb_bebe}</div>}
                        <br />
                        <Interweave content={centre_interet} />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={5}>
                        <img className="photoAtypique" src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
                      </Grid>
                      <Grid item xs={7}>
                        <Interweave className="textUserAtypique" content={userAccount[presentation]} />
                      </Grid>
                    </Grid>
                    <div className="nextPageAtypique" />
                    <h2 className="Titre2CircuitAtypique" style={Couleurdefaut}>  Itinéraire du voyage  </h2>

                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={12}>
                        <Grid container spacing={0} justifyContent="center">
                          <Grid className="TabItineraireAtypique" style={Couleurdefaut} item xs={1}>{langageTranslation.day} </Grid>
                          <Grid className="TabItineraireAtypique" style={Couleurdefaut} item xs={8}>{langageTranslation.itinerary}</Grid>
                          <Grid className="TabItineraireAtypique" style={Couleurdefaut} item xs={3}>{langageTranslation.date}</Grid>
                        </Grid>
                        {tripAllDays.map((item, index) => {
                          const IncDay = _.sum(TabIncJour.slice(0, index))
                          const datejour = tripDetails[0].date_depart

                            ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMM'))
                            : ''
                          return (
                            <div key={`${datejour}`}>
                              <Grid container spacing={0} justifyContent="center">
                                <Grid className="TabIntroAtypique2" item xs={1}>Jour {IncDay + 1}</Grid>
                                <Grid className="TabIntroAtypique2" item xs={8}>
                                  <a href={`#Jour${index}`}>
                                    {item.nom_journee}
                                  </a>
                                </Grid>
                                <Grid className="TabIntroAtypique2" item xs={3}>{datejour}</Grid>
                              </Grid>
                            </div>
                          )
                        })}
                      </Grid>
                    </Grid>
                    <input className="adjustSize" name="size" min="15" max="40" type="range" defaultValue="35" onChange={this.handleOSizeChange2} />


                  </div>
                  {(tripDetails[0].image || tripDetails[0].map_localisation) && (

                  <div className="CoupePasAtypique">
                    {tripDetails[0].image
                      ? (
                        <div id="carte">
                          <br />
                          <h2 className="Titre2CircuitAtypique" style={Couleurdefaut}>  Carte  </h2>

                          <Grid container spacing={0} justifyContent="center">
                            <Grid item xs={12}>
                              <img className="CarteAtypique" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                            </Grid>
                          </Grid>
                          <input className="adjustSize" name="size" min="200" max="1600" type="range" defaultValue="1200" onChange={this.handleOSizeChange} />
                        </div>
                      ) : (tripDetails[0].map_localisation && (
                      <div>
                        <h2 className="Titre2CircuitAtypique" style={Couleurdefaut}>  Carte  </h2>
                        <br />
                        <img className="CarteAtypique" src={urlMap} />
                      </div>
                      )
                      )
              }
                  </div>
                  )}
                  <div className="nextPageAtypique" />
                  <h2 id="detail" className="Titre2CircuitAtypique" style={Couleurdefaut}>  Programme détaillé  </h2>
                  <br />
                  <div className="premiereAtypique">
                    {tripAllDays.map((item_jour, index) => {
                      const desc = `desc_jour_${index + 1}`;
                      const imageArray = item_jour.image.split(',')
                      const imageArrayHotel = tripAccomodation[index].image ? tripAccomodation[index].image.split(',') : ''

                      const IncDay = _.sum(TabIncJour.slice(0, index + 1))
                      const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))
                        : ''
                      // eslint-disable-next-line no-nested-ternary

                      const BandeauJour = () => (
                        <div>
                          <h3 className="Titre2CircuitAtypique" style={Couleurdefaut}>
                            <div className="centre" id={`Jour${index}`}>  {index + 1} {item_jour.nom_journee} - {datejour}  </div>
                          </h3>
                        </div>
                      );

                      return (
                        <div key={`${datejour}`}>
                          <div className="CoupePasAtypique">
                            {imageArray[0] !== ''
                              ? (
                                <div>
                                  <br />
                                  <div className="CoupePasAtypique">
                                    <BandeauJour />
                                    <Grid container spacing={0} justifyContent="center">
                                      <Grid className="OptionTextJourneeAtypique" item xs={6} lg={6} sm={12}>
                                        <Interweave content={tripContent[0][desc]} />
                                      </Grid>
                                      <Grid className="Image1Jour1Atypique" item xs={6} lg={6} sm={12}>
                                        <img className="Image1Jour1Atypique" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>

                              ) : (
                                <div>
                                  <div className="CoupePasAtypique">
                                    <BandeauJour />
                                    <Interweave className="OptionTextJourneeAtypique" content={tripContent[0][desc]} />
                                  </div>
                                </div>
                              )
            }
                            {tripAccomodation[index].nom_hebergement !== 'none' && (
                              imageArrayHotel[0] !== ''
                                ? (
                                  <div>
                                    <br />
                                    <div className="CoupePasAtypique">
                                      <Grid container spacing={0} justifyContent="center">
                                        <Grid className="Image1Jour1Atypique" item xs={6} lg={6} sm={12}>
                                          <img className="Image1Jour1Atypique" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                                        </Grid>
                                        <Grid className="OptionTextJourneeAtypique" item xs={6} lg={6} sm={12}>
                                          <div className="titreHotelAtypique">{tripAccomodation[index].nom_hebergement}</div>

                                          {tripAccomodation[index].adresse && (
                                            <div>
                                              <Interweave content={tripAccomodation[index].adresse} />
                                            </div>
                                          )}
                                          {tripPension[index].pension !== 'none' && (<div>{tripPension[index].pension}</div>)}

                                          <Interweave content={tripAccomodation[index].fr_desc_hebergement} />
                                          {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}

                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>

                                ) : (
                                  <div>
                                    <div className="CoupePasEvasion">
                                      <BandeauJour />
                                      <Grid container spacing={0} justifyContent="center">
                                        <Grid className="OptionTextJourneeAtypique" item xs={12}>
                                          <div>{tripAccomodation[index].nom_hebergement}</div>

                                          {tripAccomodation[index].adresse && (<Interweave content={tripAccomodation[index].adresse} />)}
                                          {tripPension[index].pension !== 'none' && (<div>{tripPension[index].pension}</div>)}

                                          <Interweave content={tripAccomodation[index].fr_desc_hebergement} />
                                          {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                )

                            )}

                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <br />
                  {tripBilling.tripPrice && (
                  <div>

                    <div className="CoupePasAtypique">
                      {tripBilling.displayTabCheck === 0 ? (
                        <div>
                          <br />
                          <h2 id="prix" className="Titre2CircuitAtypique" style={Couleurdefaut}>  Tarif  </h2>
                          <br />
                          <Grid container spacing={0} justifyContent="center">
                            <Grid style={Couleurdefaut} item xs={12}>{tripDetails[0].nom_programme} </Grid>
                            <Grid style={Couleurdefaut} item xs={6}>{langageTranslation.groupSize} </Grid>
                            <Grid item xs={6}>{Nombre_Personne} </Grid>
                            <Grid style={Couleurdefaut} item xs={6}>{langageTranslation.pricePerPerson} </Grid>
                            <Grid item xs={6}>{tripBilling.pricePerPerson} {tripBilling.currency} </Grid>
                            <Grid style={Couleurdefaut} item xs={6}>{langageTranslation.total}</Grid>
                            <Grid item xs={6}>{tripBilling.tripPrice} {tripBilling.currency} </Grid>
                          </Grid>
                        </div>
                      )
                        : (
                          <div>
                            <br />
                            <h2 id="prix" className="Titre2CircuitAtypique" style={Couleurdefaut}>  Tarif  </h2>

                            <br />
                            <Interweave content={tripBilling.tabPrice} />
                          </div>
                        )
                 }
                      <br />
                      <div className="CoupePasAtypique">
                        <Grid container spacing={0} justifyContent="center">
                          <Grid className="tabIntroAtypique" item xs={6} lg={6} sm={12}>
                            <h3 className="Titre2CircuitAtypique" style={Couleurdefaut}>  {langageTranslation.priceContained}  </h3>
                            <br />
                            <Interweave content={tripDetails[0].prix_comprend} />
                          </Grid>
                          <Grid className="tabIntroAtypique" item xs={6} lg={6} sm={12}>
                            <h3 className="Titre2CircuitAtypique" style={Couleurdefaut}>  {langageTranslation.priceDoNotContained}  </h3>
                            <br />
                            <Interweave content={tripDetails[0].prix_comprend_pas} />
                          </Grid>
                        </Grid>

                      </div>


                      <br />
                    </div>
                  </div>
                  )}
                  <Grid id="info" container spacing={0} justifyContent="center">
                    <Grid item xs={12}>
                      <Interweave content={informationsContent[remarque]} />
                    </Grid>
                  </Grid>
                  {langage && langage === 'fr' && (
                  <div className="videoBoxAtypique">
                    <h2 id="video" className="Titre2CircuitAtypique" style={Couleurdefaut}>  Video  </h2>
                    <br />
                    <iframe className="VideoAtypique" width="800" height="500" src="https://www.youtube.com/embed/i3KPykf8VEk" title="La Thaïlande vous accueille de nouveau sans conditions au 1er juillet 2022" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                  </div>
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="page-footer-space-atypique" />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    )

    const pageHtml = renderToStaticMarkup(myPage);
    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlAtypique.css">
     <title>Votre proposition de voyage</title>
     </head>
     <body>
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a> 
        ${pageHtml}
      </body>
   </html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={10}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
          </Grid>
        </div>
        <div>{myPage}</div>
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlAtypique))
