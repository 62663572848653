import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  putTripDetailsSiteResults,
} from './actions'


function* putTripDetailsSiteAsync(params) {
  const {
    token,
    idTour,
    siteDetails,
  } = params
  const data = yield call(api.put_trip_details_site, token, idTour, siteDetails)
  yield put(putTripDetailsSiteResults(data))
}

function* fetchResult() {
  yield takeEvery('PUT_TRIP_DETAILS_SITE', putTripDetailsSiteAsync)
}

export default fetchResult
