import {
  GET_CUSTOMER_LIST_RESULTS,
  PUT_CUSTOMER_CONTENT_RESULTS,
  DELETE_CUSTOMER_CONTENT_RESULTS,
  RESET_CUSTOMER_CONTENT,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  customerList: [],
  putCustomerContent: { success: false },
  deleteCustomerContent: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_CUSTOMER_LIST_RESULTS:
      return {
        ...state,
        customerList: action.customerList,
      }
    case PUT_CUSTOMER_CONTENT_RESULTS:
      return {
        ...state,
        putCustomerContent: action.putCustomerContent,
      }
    case DELETE_CUSTOMER_CONTENT_RESULTS:
      return {
        ...state,
        deleteCustomerContent: action.deleteCustomerContent,
      }
    case RESET_CUSTOMER_CONTENT:
      return {
        ...state,
        putCustomerContent: { success: false },
        deleteCustomerContent: null,
      }
    default:
      return state
  }
}
