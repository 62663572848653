import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Template,
  ActionButton,
  Loader,
} from '../../../components'
import {
  CategoryFilter,
  ResourceCard,
  ResourceModal,
} from './component'

class ResourcesManagement extends PureComponent {
  static propTypes = {
    GET_RESOURCES: PropTypes.func.isRequired,
    PUT_RESOURCES: PropTypes.func.isRequired,
    DELETE_RESOURCES: PropTypes.func.isRequired,
    RESET_RESOURCES: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    resources: PropTypes.array,
    putResources: PropTypes.object,
    deleteResources: PropTypes.object,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    resources: [],
    putResources: {},
    deleteResources: {},
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateResources = this.updateResources.bind(this)
    this.updateResourcesFilter = this.updateResourcesFilter.bind(this)
    this.deleteResource = this.deleteResource.bind(this)
    this.putResource = this.putResource.bind(this)
    // this.openResourceCard = this.openResourceCard(this)
    this.closeModal = this.closeModal.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      resourcesFiltred: [],
      resourceSelected: {},
      modalType: '',
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
    }
  }

  componentDidMount() {
    const {
      history,
      resources,
      GET_RESOURCES,
    } = this.props
    const { token } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(resources)) GET_RESOURCES(token)
  }

  componentDidUpdate(prevProps) {
    const {
      LOADER,
      resources,
      putResources,
      deleteResources,
    } = this.props
    if (putResources && putResources.success) {
      this.reload()
      this.resetValidMsg()
    }
    if (deleteResources && deleteResources.success) {
      this.reload()
      this.resetValidMsg()
      LOADER(false)
    }
    if (resources !== prevProps.resources) {
      LOADER(false)
      this.updateResources()
    }
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { token } = this.state
    const {
      RESET_RESOURCES,
      GET_RESOURCES,
    } = this.props
    RESET_RESOURCES()
    GET_RESOURCES(token)
    this.setState({
      validMsg: 'Resources are updated !',
    })
  }

  updateResources = () => {
    const {
      resources,
    } = this.props
    this.setState({
      resourcesFiltred: resources,
    })
  }

  // ********** Filter ************************
  updateResourcesFilter = (filter) => {
    const { resources } = this.props
    let newResources = _.cloneDeep(resources)
    _.forEach(filter, (value, key) => {
      newResources = [..._.filter(newResources, { [key]: value })]
    })
    this.setState({
      resourcesFiltred: newResources,
    })
  }

  // ***************** Action *****************
  deleteResource = (resource) => {
    const { token } = this.state
    const {
      DELETE_RESOURCES,
      LOADER,
    } = this.props
    if (resource.id && confirm('Do you really want to delete this resource ?')) DELETE_RESOURCES(token, resource.id)
    LOADER(true)
  }

  putResource = (res) => {
    const { PUT_RESOURCES } = this.props
    const {
      token,
      modalType,
    } = this.state
    if (res.name) PUT_RESOURCES(token, res, modalType)
    this.closeModal()
  }

  // ****************** Modal **************
  openResourceCard = (action, resource) => {
    this.setState({
      resourceSelected: resource,
      modalType: action,
      isModalOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  // ******************* Render ***************
  render() {
    const {
      userId,
      resourcesFiltred,
      resourceSelected,
      modalType,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
    } = this.state
    const {
      isLoading,
      resources,
      userAccount,
    } = this.props

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="resourcesContainer">
              <h2>RESOURCES</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <CategoryFilter
                list={resourcesFiltred}
                updateResourcesFilter={resourceFilter => this.updateResourcesFilter(resourceFilter)}
              />
              <div className="dividerStepper half" />

              <ActionButton
                label="Add resource"
                onClick={() => this.openResourceCard('create', { id_conseiller: parseInt(userId, 10) })}
              />
              <div className="resources-list">
                {!_.isEmpty(resourcesFiltred) && resourcesFiltred.map((t, index) => (
                  <ResourceCard
                    key={Math.random(10000).toString()}
                    resource={t}
                    resourceIndex={index}
                    openResourceCard={(action, resource) => this.openResourceCard(action, resource)}
                    deleteResource={idTraveler => this.deleteResource(idTraveler)}
                  />
                ))}
              </div>
            </div>
          </div>
          {!_.isEmpty(resourceSelected) && (
            <ResourceModal
              resource={resourceSelected}
              resources={resources}
              type={modalType}
              isModalOpen={isModalOpen}
              onModalClose={() => this.closeModal()}
              putResource={res => this.putResource(res)}
              userAccount={userAccount}
            />
          )}
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  resources: state.resourceReducer.resources,
  putResources: state.resourceReducer.putResources,
  deleteResources: state.resourceReducer.deleteResources,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_RESOURCES: token => dispatch({
    type: 'GET_RESOURCES',
    token,
  }),
  PUT_RESOURCES: (token, resource, action) => dispatch({
    type: 'PUT_RESOURCES',
    token,
    resource,
    action,
  }),
  DELETE_RESOURCES: (token, resourceId) => dispatch({
    type: 'DELETE_RESOURCES',
    token,
    resourceId,
  }),
  RESET_RESOURCES: () => dispatch({
    type: 'RESET_RESOURCES',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesManagement))
