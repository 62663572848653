
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlEvasion extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripDevisFinal: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripBilling: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    tripDetails: [],
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    tripContent: [],
    tripDevisFinal: [],
    tripAccomodation: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      zoomCarte: 100,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }


    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteEvasion-size', `${event.currentTarget.value}px`)
  }

  handleOSizeZoomChange = (event) => {
    this.setState({
      zoomCarte: event.currentTarget.value,
    })
  }

  handlePrint = (html) => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }

  handleEditorChange = (content) => {
    this.setState({
      devisContent: content,
    });
  }

  handleSave = (devisContent) => {
    const {
      PUT_TRIP_DEVIS_FINAL,
      tripDetails,
      tripDevisFinal,
    } = this.props
    const {
      token,
    } = this.state
    const tripDevisFinalServeur = {}
    tripDevisFinalServeur.textHtml = devisContent
    tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
    } else {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
    }
  }


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word(element, filename = '') {
    const html = element;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }


  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];
      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAllDaysAccomodations,
      tripAccomodation,
      tripPension,
      tripBilling,
      companyContent,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]

    const {
      prenom_responsable_groupe,
      nom_responsable_groupe,
      langage,
      code_devis,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      zoomCarte,
      devisContent,
    } = this.state

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************

    const Couleurdefaut = {
      backgroundColor: informationsContent.main_color,
      textAlign: 'center',
    };
    const fonddefaut = {
      color: informationsContent.main_color,
    };
    const borderdefaut = {
      borderColor: informationsContent.main_color,
    };
    const description = tripDetails[0].desc_voyage;
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = zoomCarte === 100 ? (`https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    ) : (
      `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&zoom=${zoomCarte}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    )
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const longueurVoyage = tripAllDaysAccomodations.length
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(IncDayTot - 1, 'd').format('DD MMMM YYYY')
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const remarque = `${langage}_remarque`
    const slogan = `${langage}_slogan`
    const texteHotel = langage && `${langage}_information_1_description`;
    const texteInfoPratique = langage && `${langage}_information_2_description`;
    const texteReglement = langage && `${langage}_information_3_description`;
    const texteTarif1 = langage && `${langage}_information_4_description`;
    const texteTarif2 = langage && `${langage}_information_5_description`;
    const InformationImage = 'information_1_image';

    const carteEvasion = {
      display: 'flex',
      margin: 'auto',
      paddingBottom: 20,
    };

    // const afficheLieu = tripLocalisationList && tripLocalisationList.map(item => (` ${item}`)).toString() */ }
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }
    const pied = (
      <Interweave content={companyContent.description} />
    )
    const enTete = (
      <div className="page-header-evasion">
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">
                <img width="100" height="100" className="LogoEvasion" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
              </td>
              <td width="50%">
                <Interweave content={companyContent[slogan]} />
              </td>
              <td width="25%">
                <div className="centreEvasion">
                  <br />
                  <div>Devis N°{code_devis} </div>
                  <div>{travelerName}</div>
                  <div>Du {dateDepart} au {dateFin}</div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    )
    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFileEvasion">
          {indexDisplay !== 'HTMLWORD'
           && (
           <div className="page-header-evasionhtml">
             <Grid container justifycontent="space-between" spacing={0}>
               <Grid item xs={3}>
                 <img className="LogoEvasion" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
               </Grid>
               <Grid item xs={6}>
                 <Interweave content={companyContent[slogan]} />
               </Grid>
               <Grid item xs={3}>
                 <div className="centreEvasion">
                   <br />
                   <div>Devis N°{code_devis} </div>
                   <div>{travelerName}</div>
                   <div>Du {dateDepart} au {dateFin}</div>
                 </div>
               </Grid>
             </Grid>
             <div className="navbar">
               <a href="#home">{langageTranslation.homeMenu}</a>
               {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
               <a href="#carte">{langageTranslation.carteMenu}</a>)}
               <a href="#detail">{langageTranslation.detailMenu}</a>
               <a href="#hotel">{langageTranslation.hotelMenu}</a>
               <a href="#info">{langageTranslation.infoMenu}</a>
               <a href="#prix">{langageTranslation.prixMenu}</a>
               <a href="#video">Video</a>

             </div>

           </div>
           )}
          <div className="premierePageEvasion">
            {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <img className="ImageCoverEvasion" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
           </div>
           )}
            <br />
            <div id="home" className="TitreEvasion1">{tripDetails[0].nom_programme}</div>
            <br />
            <br />
            {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <img width="100" height="100" className="LogoEvasion2" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
             <br />
             <br />
           </div>
           )}
            <div className="titreGardeEvasion">Circuit de {IncDayTot} jours / {IncDayTot - 1} nuits </div>
            <br />
            <br />
            <div className="gardeEvasionLieu" style={borderdefaut}>
              {tripDetails[0].destination}
            </div>
            <br />

          </div>
          <div className="premierePageEvasion">
            {indexDisplay !== 'HTMLWORD'
           && (
             tripDetails[0].image ? (
               <img className="ImageCoverEvasion" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
             ) : (
               <img className="ImageCoverEvasion" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />
             )
           )}
            <br />
            <br />
            <hr style={Couleurdefaut} className="epaisseurLigneEvasion" />
            <br />
            <Interweave content={description} />
          </div>
          <div>
            {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <div className="page-header-evasion">
               <Grid container spacing={0} justifycontent="space-between">
                 <Grid item xs={3}>
                   <img className="LogoEvasion" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                 </Grid>
                 <Grid item xs={6}>
                   <Interweave content={companyContent[slogan]} />
                 </Grid>
                 <Grid item xs={3}>
                   <div className="centreEvasion">
                     <br />
                     <div>Devis N°{code_devis} </div>
                     <div>{travelerName}</div>
                     <div>Du {dateDepart} au {dateFin}</div>
                   </div>
                 </Grid>
               </Grid>
             </div>
           </div>
           )}
            <table className="table-evasion">
              {indexDisplay !== 'HTMLWORD'
           && (
           <thead>
             <tr>
               <td>
                 <div className="page-header-space-evasion" />
               </td>
             </tr>
           </thead>
           )}
              <tbody>
                <tr>
                  <td>
                    <div className="CoupePasEvasion">
                      {(indexDisplay !== 'HTMLWORD')
                                      && (
                                      <div>
                                        <img width="750" height="150" className="bandeauEvasion" src={`${savedImgUrl}/thailande_evasion/bandeauEvasion-1659337639329-1685603712551.png`} />
                                        <br />
                                      </div>
                                      )}
                      <h2 className="Titre2CircuitEvasion" style={Couleurdefaut}>Programme en bref</h2>
                      <br />
                      <br />
                      <table className="TabItineraireEvasionGlobal" width="100%">
                        <tbody>
                          <tr>
                            <td className="TabItineraireEvasion" style={Couleurdefaut}>{langageTranslation.day} </td>
                            <td className="TabItineraireEvasion" style={Couleurdefaut}>{langageTranslation.date}</td>
                            <td className="TabItineraireEvasion" style={Couleurdefaut}>{langageTranslation.itinerary}</td>
                            <td className="TabItineraireEvasion" style={Couleurdefaut}>{langageTranslation.transport}</td>
                          </tr>
                          {tripAllDays.map((item, index) => {
                            const IncDay = _.sum(TabIncJour.slice(0, index))
                            const datejour = tripDetails[0].date_depart
                              ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMM'))
                              : ''
                            return (
                              <tr key={`${datejour}`}>
                                <td className="TabItineraireEvasion1" style={fonddefaut}>Jour {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)}</td>
                                <td className="TabItineraireEvasion1" style={fonddefaut}>{datejour}</td>
                                <td className="TabItineraireEvasion1" style={fonddefaut}>
                                  <a style={fonddefaut} href={`#Jour${index}`}>
                                    {item.nom_journee}
                                  </a>
                                </td>
                                <td className="TabItineraireEvasion1" style={fonddefaut}>{item.transport}</td>
                              </tr>

                            )
                          })}
                        </tbody>
                      </table>

                    </div>
                    {(tripDetails[0].map_localisation) && (
                    <div className="CoupePasEvasion">
                      <div id="carte">
                        {(indexDisplay !== 'HTMLWORD')
                                      && (
                                      <div>
                                        <br />
                                        <img width="750" height="150" className="bandeauEvasion" src={`${savedImgUrl}/thailande_evasion/bandeauEvasion-1659337639329-1685603712551.png`} />
                                      </div>
                                      )}
                        <h2 className="Titre2CircuitEvasion" style={Couleurdefaut}>  Itinéraire du voyage  </h2>
                        <br />
                        <br />
                        <img style={carteEvasion} height="850" src={urlMap} />
                      </div>
                    </div>
                    )}
                    <div className="premierePageEvasion" />
                    <br />
                    <h2 id="detail" className="TitreDetailEvasion">  PROGRAMME DETAILLÉ  </h2>
                    <div>
                      {tripAllDays.map((item_jour, index) => {
                        const desc = `desc_jour_${index + 1}`;
                        const imageArray = item_jour.image.split(',')
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        // eslint-disable-next-line no-nested-ternary
                        const datejour = tripDetails[0].date_depart ? (TabIncJour[index] === 1
                          ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))
                          : `${_.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))} à ${_.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay + TabIncJour[index] - 1, 'd').format('ddd DD MMMM'))}`)
                          : '';
                          // eslint-disable-next-line no-nested-ternary
                        let guideNeed = ''
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = ''
                            break;
                          case '1':
                            guideNeed = langageTranslation.withGuide
                            break;
                          case '2':
                            guideNeed = langageTranslation.withGuideEn
                            break;
                          case '3':
                            guideNeed = langageTranslation.withGuideEnEvasion
                            break;
                          default:
                            guideNeed = ''
                        }

                        let driverNeed = ''
                        switch (chauffeurArray[index]) {
                          case '0':
                            driverNeed = ''
                            break;
                          case '1':
                            driverNeed = langageTranslation.driverEvasion
                            break;
                          case '2':
                            driverNeed = ''
                            break;
                          case '3':
                            driverNeed = 'Transport Locaux'
                            break;
                          case '4':
                            driverNeed = langageTranslation.busEvasion
                            break;
                          default:
                            driverNeed = ''
                        }
                        const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                        const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver
                        const BandeauJour = () => (
                          <div>
                            <div className="Titre2CircuitEvasion" style={Couleurdefaut}>
                              <div style={{ textAlign: 'center' }}>  Jour {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} - {datejour}  </div>
                            </div>
                            <div className="petitSpace" />
                            <div className="Titre4CircuitEvasion">
                              <div id={`Jour${index}`}>{tripContent[0][item_jour.nom_journee]}</div>
                            </div>
                            <div className="petitSpace" />
                          </div>
                        );

                        return (
                          <div key={`${datejour}`}>
                            <div>
                              {imageArray[0] !== '' && imageArray.length === 1
                                ? (
                                  <div className="CoupePasEvasion">
                                    <br />
                                    <div>
                                      <BandeauJour />
                                      {(indexDisplay !== 'HTMLWORD')
                                      && (
                                      <Grid container spacing={0} justifyContent="center">
                                        <Grid className="OptionTextJourneeEvasion" item xs={12} lg={8} sm={8}>
                                          <Interweave content={tripContent[0][desc]} />
                                          <div className="paragrapheEvasion" style={fonddefaut}>* {asteriskText}</div>
                                          {item_jour.distance && (<div className="paragrapheEvasion" style={fonddefaut}>* {item_jour.distance}</div>)}
                                        </Grid>
                                        <Grid className="Image1Jour1Evasion" item xs={12} lg={4} sm={4}>
                                          <img width="250" height="150" className="Image1Jour1Evasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                        </Grid>
                                      </Grid>
                                      )}
                                      {(indexDisplay === 'HTMLWORD')
                                      && (
                                        <table width="100%">
                                          <tbody>
                                            <tr>
                                              <td width="70%" className="OptionTextJourneeEvasion">
                                                <Interweave content={tripContent[0][desc]} />
                                              </td>
                                              <td width="30%" className="Image1Jour1Evasion">
                                                <img width="550" height="330" className="Image1Jour1Evasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  </div>

                                ) : (
                                  <div>
                                    <div>
                                      <BandeauJour />
                                      <div className="OptionTextJourneeEvasion">
                                        <Interweave content={tripContent[0][desc]} />
                                        <div className="paragrapheEvasion" style={fonddefaut}>* {asteriskText}</div>
                                        {item_jour.distance && (<div className="paragrapheEvasion" style={fonddefaut}>* {item_jour.distance}</div>)}
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                )
            }
                              {(tripAccomodation[index].nom_hebergement !== 'none' || nbOfRoomLabel(index) || tripPension[index].pension !== 'none')

                          && (
                          <div className="CoupePasEvasion">
                            <br />
                            <Grid container justifyContent="center" spacing={0}>
                              {indexDisplay !== 'HTMLWORD' && tripAccomodation[index].nom_hebergement !== 'none' && (<Grid className="CentreEvasion" item xs={1}><img className="ImageOptionServiceEvasion" src={`${savedImgUrl}/${companyContent.company}/hotel.png`} /> </Grid>)}
                              {tripAccomodation[index].nom_hebergement !== 'none' && (<Grid className="OptionServiceEvasion" item xs={2}>{tripAccomodation[index].nom_hebergement} {tripAccomodation[index].fr_categorie}</Grid>)}
                              {indexDisplay !== 'HTMLWORD' && nbOfRoomLabel(index) && (<Grid className="CentreEvasion" item xs={1}><img className="ImageOptionServiceEvasion" src={`${savedImgUrl}/${companyContent.company}/bed.png`} /> </Grid>)}
                              {nbOfRoomLabel(index) && (<Grid className="OptionServiceEvasion" item xs={2}>{!roomsNumberArray ? '' : nbOfRoomLabel(index)}</Grid>)}
                              {indexDisplay !== 'HTMLWORD' && tripPension[index].pension !== 'none' && (<Grid className="Centre" item xs={1}><img className="ImageOptionServiceEvasion" src={`${savedImgUrl}/${companyContent.company}/meal.png`} /></Grid>)}
                              {tripPension[index].pension !== 'none' && (<Grid className="OptionServiceEvasion" item xs={2}>{tripPension[index].pension}</Grid>)}
                            </Grid>
                            <br />
                          </div>
                          )
          }
                            </div>
                            {imageArray[0] !== '' && imageArray.length === 2
                && (
                  <div>
                    {indexDisplay !== 'HTMLWORD'
                && (
                  <div className="CentreEvasion">
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                      </Grid>
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                      </Grid>
                    </Grid>
                  </div>
                )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table className="CentreEvasion" width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="550" height="330" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                          </td>
                          <td width="40%">
                            <img width="550" height="330" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}


                            {imageArray[0] !== '' && imageArray.length === 3
        && (
          <div>
            {(indexDisplay !== 'HTMLWORD')
        && (
          <div>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
              </Grid>
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
              </Grid>
              <Grid item xs={4}>
                <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
              </Grid>
            </Grid>
          </div>
        )}
            {indexDisplay === 'HTMLWORD'
                    && (
                    <table className="CentreEvasion" width="100%">
                      <tbody>
                        <tr>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
          </div>
        )}
                          </div>
                        )
                      })}
                    </div>
                    <div className="CoupePasEvasion">

                      <div id="hotel" className="Titre2CircuitEvasion" style={Couleurdefaut}>  Hôtels - Homestay  </div>
                      <br />
                      {Hotel_Unique && Hotel_Unique.map((item, i) => {
                        const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                        const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                        const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');

                        return (
                          <div key={`Acco${item.id_journee}`}>
                            {item.nom_hebergement !== 'none'
                && (
                  <div className="CoupePasEvasion">
                    <div>
                      <div className="afficheHotelEvasion">
                        <div className="CentreEvasion">
                          <span className="grasEvasion">{LieuFinJournee.lieu}: </span> {item.nom_hebergement} {item.fr_categorie}-   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)

                          <div><Interweave content={adresseHebClean} /></div>
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length === 1
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
                  && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                    </Grid>
                  </Grid>
                  )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table className="CentreEvasion" width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="550" height="330" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length === 2
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
                && (
                  <div>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                      </Grid>
                      <Grid item xs={6}>
                        <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                      </Grid>
                    </Grid>
                  </div>
                )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table className="CentreEvasion" width="100%">
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img width="550" height="330" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                          <td width="40%">
                            <img width="550" height="330" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>

                )}
                          {imageArrayHotel[0] !== '' && imageArrayHotel.length === 3
                && (
                  <div>
                    {(indexDisplay !== 'HTMLWORD')
              && (
              <div>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={4}>
                    <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                  </Grid>
                  <Grid item xs={4}>
                    <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                  </Grid>
                  <Grid item xs={4}>
                    <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                  </Grid>
                </Grid>
              </div>
              )}
                    {indexDisplay === 'HTMLWORD'
                    && (
                    <table className="CentreEvasion" width="100%">
                      <tbody>
                        <tr>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                          </td>
                          <td width="30%">
                            <img width="250" height="150" className="Image1JourEvasion" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    )}
                  </div>
                )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Interweave content={item.fr_desc_hebergement} />
                    </div>
                    <br />
                  </div>
                )}
                          </div>
                        )
                      })}
                      <div className="CoupePasEvasion">
                        <Interweave content={informationsContent[texteHotel]} />
                      </div>
                    </div>
                    {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <br />
             <Interweave content={informationsContent[texteInfoPratique]} />
             <br />
           </div>
           )}
                    {tripBilling.displayTabCheck && (
                    <div>
                      <br />
                      <div id="info" className="CoupePasEvasion">
                        <h3 className="Titre2CircuitEvasion" style={Couleurdefaut}>  {langageTranslation.priceContained}  </h3>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend} />
                      </div>
                      <div className="CoupePasEvasion">
                        <h3 className="Titre2CircuitEvasion" style={Couleurdefaut}>  {langageTranslation.priceDoNotContained}  </h3>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend_pas} />
                      </div>
                      <div className="CoupePasEvasion">
                        <div>
                          {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <Interweave content={informationsContent[texteReglement]} />


             <div className="nextPageEvasion" />
             <Interweave content={informationsContent[texteTarif1]} />
             <br id="prix" />
           </div>
           )}
                          <Interweave content={tripBilling.tabPrice} />
                          {indexDisplay !== 'HTMLWORD'
           && (
           <div>
             <Interweave content={informationsContent[texteTarif2]} />
           </div>
           )}
                          <div className="nextPageEvasion" />
                        </div>
                        <br />
                      </div>
                    </div>
                    )}
                    {indexDisplay !== 'HTMLWORD'
           && (
           <Grid container spacing={0} justifyContent="center">
             <Grid item xs={12}>
               <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
             </Grid>
           </Grid>
           )}
                    {indexDisplay !== 'HTMLWORD' && langage && langage === 'fr' && (
                    <div className="videoBoxEvasion">
                      <h2 id="video" className="Titre2CircuitEvasion" style={Couleurdefaut}>  Video  </h2>
                      <br />
                      <iframe className="VideoEvasion" width="800" height="500" src="https://www.youtube.com/embed/28FaOVmwY1c" title="La Thaïlande vous accueille de nouveau sans conditions au 1er juillet 2022" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                    )}

                  </td>
                </tr>
              </tbody>
              {(indexDisplay !== 'HTMLWORD')
           && (
           <tfoot>
             <tr>
               <td>
                 <div className="page-footer-space-evasion">
                   <Grid container spacing={0} justifyContent="center">
                     <Grid item xs={12}>
                       <Interweave content={companyContent.description} />
                     </Grid>
                   </Grid>
                 </div>
               </td>
             </tr>
           </tfoot>
           )}
            </table>
          </div>
        </div>

      )
      return myPage
    }
    const headerHtml = renderToStaticMarkup(enTete)
    const footerHtml = renderToStaticMarkup(pied)
    const pageHtml = renderToStaticMarkup(myPageFunc('STANDARD'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));

    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlEvasion.css">
     <title>Votre proposition de voyage</title>
     </head>
     <body>
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a> 
        ${devisContent}
      </body>
   </html>
   `;

    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlEvasion.css">
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>

<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
:root{

  --CarteEvasion-size: 850px;
  
  
}  

@media screen {
  .page-header-evasion, .bandeauEvasion, .page-header-space-evasion, .page-footer-evasion, .adjustSize, .adjustSizeZoom  {
    
    display:none;
     
   }



  .page-header-evasionhtml {
    top: 0; /* Position the navbar at the top of the page */
    position: fixed;
    overflow: hidden;
    border: 1px solid #2b748d;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;
    height: 200px;
    width:100%;
    max-width:1000px;
    background-color: white;
    z-index: 1  
  }

  .navbar a {
    float: left;
    display: block;
    color: #2b748d;
    text-align: center;
    padding: 5px 16px;
    text-decoration: none;
  }

 .GlobalFileEvasion {
    /*background: url('images/fond_jaune.png');*/
     padding-right: 20px;
    padding-left: 20px;
   margin-left: auto;
  margin-right: auto;
   max-width:1000px;
   margin-top:150px;   
  
  }

 /* Change background on mouse-over */
  .navbar a:hover {
    background: #ddd;
    color: black;
  }

   .ImageCoverEvasion {
    height: 800px;
    max-width:1000px;
    display:flex;
    margin: auto;
  
  }

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    scroll-padding-top: 200px;
}

.printLink {
  top: 150px; 
  width: 60px;
  z-index: 2;
  position: fixed;
  left: 70%;
  transform: translate(-50%, -50%);
  
 
}

  }


   .LogoEvasion {
      height: 43.2mm;
    width: 43.2mm;
  
  }



  .LogoEvasion2 {
    margin: auto;
    display: flex;
    max-height: 200px;
  
  }
  .bandeauEvasion {
    height: 180px;
    width: 1000px;
  }

 .epaisseurLigneEvasion{
    height: 4px;
  }

  .centre {
    text-align: center;
    margin: auto;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  }

 
  .TitreEvasion1 {
    text-align: center;
 font-size: 35px;  
    color:#f09209;
  }

  .titreGardeEvasion{
    text-align: center;
    font-size: 35px;  
    font-style:italic;
  }

.CarteEvasion {
  display: flex;
  margin: auto;
  padding-bottom: 20px;
  
  height: var(--CarteEvasion-size);

  max-width: 100%;
  max-height: 100%;  
}

.CarteEvasion2 {
  display: flex;
  margin: auto;
  padding-bottom: 20px;
 
  max-width: 100%;
  
}

  .gardeEvasionLieu{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 35px;  
    width: fit-content;
    max-width: 800px;
    border: 0.5px solid #f09209;
  }

  .TabItineraireEvasionGlobal {
    border-collapse: collapse;
  
   }

  .TabItineraireEvasion {
    border-radius: 5px;
    text-align: center;
    font-size: large;
    color: white;
    font-weight: bold;
  }
  .TabItineraireEvasion1 {
    border: 0.5px solid #000000;
  border-radius: 5px;
    text-align: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .Titre2CircuitEvasion {
       margin-left: auto;
    margin-right: auto;
    width: fit-content;
    height: 35px;
    color: white;
    font-size:25px;
    font-weight:normal;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    
 }

.petitSpace{
  height: 1mm;
}

.TitreDetailEvasion {
  margin-left: auto;
    margin-right: auto;
  font-size:25px;
  font-weight:normal;
  width: fit-content;
  padding: 3px;

}

 .Titre3CircuitEvasion {
   margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  border-radius: 5px;
  width:300px
 
}

.Titre4CircuitEvasion {
   margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  font-size: large; 
  font-weight: bold;
}

.BandeauJour {
  break-inside: avoid;
}

.paragrapheEvasion {
  justify-content: space-around;
  font-weight: bold;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 13px;  
  padding-bottom: 10px;
}

.OptionTextJourneeEvasion {
  padding-bottom: 5px;
}

.CentreEvasion {
  text-align: center;
  
}

.Image1Jour1Evasion {

  width: 450px;
  height: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}

 .Image1Jour1Evasion:hover {
   transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
 }

 .Image1JourEvasion:hover {
   transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
 }

.Image1JourEvasion {
  width: 350px;
  height: 300px;
  padding-top: 5px;
  margin-bottom: 15px;
  border-radius: 15px;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}

.CoupePasEvasion {
  break-inside: avoid;
}

.justPaddingBottomEvasion{
  padding-bottom: 20px;
 
}

.OptionServiceEvasion {
  text-align: left;
  font-size: small;
  font-weight: bold;
  padding-top: 5px;
}


.ImageOptionServiceEvasion {
  text-align: center;
  height: 30px;
  width: 30px;
  background-color: white;
  image-rendering: auto;
  border-radius: 15px;
}

.grasEvasion{
  font-weight: bold;
 
}

.VideoEvasion {
 
  border-radius: 15px;
  display: flex;
  margin:auto;
}

.afficheHotelEvasion {
  font-size: 25px;
}




@media print {
  .page-header-evasionhtml, .urlBox, .adjustSize, .adjustSizeZoom, .printLink, .videoBoxEvasion  {
    
    display:none;
     
   }



   table:not(.table-evasion) {
    page-break-inside: avoid !important;
}

.page-header-space-evasion {
    height: 156px;
  }

.page-footer-space-evasion{
    height: 33px;
    opacity: 0.5;
  }
  
  .page-footer-evasion {
    position:fixed;
   bottom:0;
    text-align: center;
    opacity: 0.5;
    width: 960px;
   height: 33px;
 }

  .page-header-evasion {
    position:fixed;
    text-align: center;
    opacity: 0.5;
    height: 156px;
    width: 960px;
   
    /* for demo */
  }
 

  .GlobalFileEvasion {
    /*background: url('images/fond_jaune.png');*/
    border-radius: 5px;
    width: 1000px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .ImageCoverEvasion {
    
    height: 600px;
    width: 1000px;
    display:flex;
    margin: auto;
  
  }



 .premierePageEvasion{
  page-break-after: always;
 }

 .nextPageEvasion{
  page-break-after: always;
 }
}

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  }
  .MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
  }
  .MuiGrid-zeroMinWidth {
  min-width: 0;
  }
  .MuiGrid-direction-xs-column {
  flex-direction: column;
  }
  .MuiGrid-direction-xs-column-reverse {
  flex-direction: column-reverse;
  }
  .MuiGrid-direction-xs-row-reverse {
  flex-direction: row-reverse;
  }
  .MuiGrid-wrap-xs-nowrap {
  flex-wrap: nowrap;
  }
  .MuiGrid-wrap-xs-wrap-reverse {
  flex-wrap: wrap-reverse;
  }
  .MuiGrid-align-items-xs-center {
  align-items: center;
  }
  .MuiGrid-align-items-xs-flex-start {
  align-items: flex-start;
  }
  .MuiGrid-align-items-xs-flex-end {
  align-items: flex-end;
  }
  .MuiGrid-align-items-xs-baseline {
  align-items: baseline;
  }
  .MuiGrid-align-content-xs-center {
  align-content: center;
  }
  .MuiGrid-align-content-xs-flex-start {
  align-content: flex-start;
  }
  .MuiGrid-align-content-xs-flex-end {
  align-content: flex-end;
  }
  .MuiGrid-align-content-xs-space-between {
  align-content: space-between;
  }
  .MuiGrid-align-content-xs-space-around {
  align-content: space-around;
  }
  .MuiGrid-justify-content-xs-center {
  justify-content: center;
  }
  .MuiGrid-justify-content-xs-flex-end {
  justify-content: flex-end;
  }
  .MuiGrid-justify-content-xs-space-between {
  justify-content: space-between;
  }
  .MuiGrid-justify-content-xs-space-around {
  justify-content: space-around;
  }
  .MuiGrid-justify-content-xs-space-evenly {
  justify-content: space-evenly;
  }
  .MuiGrid-spacing-xs-1 {
  width: calc(100% + 8px);
  margin: -4px;
  }
  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 4px;
  }
  .MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px);
  margin: -8px;
  }
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 8px;
  }
  .MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px;
  }
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 12px;
  }
  .MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -16px;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 16px;
  }
  .MuiGrid-spacing-xs-5 {
  width: calc(100% + 40px);
  margin: -20px;
  }
  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding: 20px;
  }
  .MuiGrid-spacing-xs-6 {
  width: calc(100% + 48px);
  margin: -24px;
  }
  .MuiGrid-spacing-xs-6 > .MuiGrid-item {
  padding: 24px;
  }
  .MuiGrid-spacing-xs-7 {
  width: calc(100% + 56px);
  margin: -28px;
  }
  .MuiGrid-spacing-xs-7 > .MuiGrid-item {
  padding: 28px;
  }
  .MuiGrid-spacing-xs-8 {
  width: calc(100% + 64px);
  margin: -32px;
  }
  .MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 32px;
  }
  .MuiGrid-spacing-xs-9 {
  width: calc(100% + 72px);
  margin: -36px;
  }
  .MuiGrid-spacing-xs-9 > .MuiGrid-item {
  padding: 36px;
  }
  .MuiGrid-spacing-xs-10 {
  width: calc(100% + 80px);
  margin: -40px;
  }
  .MuiGrid-spacing-xs-10 > .MuiGrid-item {
  padding: 40px;
  }
  .MuiGrid-grid-xs-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
  }
  .MuiGrid-grid-xs-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  }
  .MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  }
  .MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  }
  .MuiGrid-grid-xs-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  }
  .MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  }
  .MuiGrid-grid-xs-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  }
  .MuiGrid-grid-xs-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  }
  .MuiGrid-grid-xs-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  }
  .MuiGrid-grid-xs-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  }
  .MuiGrid-grid-xs-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  }
  .MuiGrid-grid-xs-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  }
  .MuiGrid-grid-xs-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  }
  .MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  }
  @media (min-width:600px) {
  .MuiGrid-grid-sm-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
  }
  .MuiGrid-grid-sm-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  }
  .MuiGrid-grid-sm-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  }
  .MuiGrid-grid-sm-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  }
  .MuiGrid-grid-sm-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  }
  .MuiGrid-grid-sm-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  }
  .MuiGrid-grid-sm-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  }
  .MuiGrid-grid-sm-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  }
  .MuiGrid-grid-sm-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  }
  .MuiGrid-grid-sm-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  }
  .MuiGrid-grid-sm-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  }
  .MuiGrid-grid-sm-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  }
  .MuiGrid-grid-sm-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  }
  .MuiGrid-grid-sm-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  }
  }
  @media (min-width:960px) {
  .MuiGrid-grid-md-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
  }
  .MuiGrid-grid-md-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  }
  .MuiGrid-grid-md-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  }
  .MuiGrid-grid-md-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  }
  .MuiGrid-grid-md-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  }
  .MuiGrid-grid-md-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  }
  .MuiGrid-grid-md-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  }
  .MuiGrid-grid-md-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  }
  .MuiGrid-grid-md-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  }
  .MuiGrid-grid-md-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  }
  .MuiGrid-grid-md-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  }
  .MuiGrid-grid-md-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  }
  .MuiGrid-grid-md-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  }
  .MuiGrid-grid-md-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  }
  }
  @media (min-width:1280px) {
  .MuiGrid-grid-lg-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
  }
  .MuiGrid-grid-lg-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  }
  .MuiGrid-grid-lg-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  }
  .MuiGrid-grid-lg-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  }
  .MuiGrid-grid-lg-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  }
  .MuiGrid-grid-lg-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  }
  .MuiGrid-grid-lg-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  }
  .MuiGrid-grid-lg-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  }
  .MuiGrid-grid-lg-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  }
  .MuiGrid-grid-lg-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  }
  .MuiGrid-grid-lg-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  }
  .MuiGrid-grid-lg-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  }
  .MuiGrid-grid-lg-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  }
  .MuiGrid-grid-lg-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  }
  }
  @media (min-width:1920px) {
  .MuiGrid-grid-xl-auto {
  flex-grow: 0;
  max-width: none;
  flex-basis: auto;
  }
  .MuiGrid-grid-xl-true {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;
  }
  .MuiGrid-grid-xl-1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  }
  .MuiGrid-grid-xl-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  }
  .MuiGrid-grid-xl-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  }
  .MuiGrid-grid-xl-4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  }
  .MuiGrid-grid-xl-5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  }
  .MuiGrid-grid-xl-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  }
  .MuiGrid-grid-xl-7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  }
  .MuiGrid-grid-xl-8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  }
  .MuiGrid-grid-xl-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  }
  .MuiGrid-grid-xl-10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  }
  .MuiGrid-grid-xl-11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  }
  .MuiGrid-grid-xl-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  }
  }
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
   
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
             <div style='mso-element:header' id="h1" >
                 <p class="MsoHeader">
                     <table border="0" width="100%">
                         <tr>
                             <td>
                             ${headerHtml}
                             </td>
                          </tr>
                    </table>
                </p>
            </div>
            <div style='mso-element:footer' id="f1">
            <p class="MsoFooter">
                <table width="100%" >
                    <tr>
                        <td  class="footer">
                    ${footerHtml}
                        </td>
                    </tr>
                </table>
            </p>
        </div>
        </td>
                         
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}.docx`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlEvasion.css"
          handleEditorChange={content => this.handleEditorChange(content)}
          height={1000}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  tripDevisFinal: state.api.tripDevisFinal,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlEvasion))
