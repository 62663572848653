import {
  GET_INVOICE_LIST_RESULTS,
  PUT_COMPANY_INVOICE_RESULTS,
  PUT_TRIP_BILLING_BILLINGREF_RESULTS,
  RESET_GENERATE_INVOICE,
} from './actions'
import {
  RESET_APP,
} from '../../../components/api/actions'

const initialState = {
  invoiceList: [],
  putCompanyInvoice: { success: false },
  putTripBilling: { success: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case GET_INVOICE_LIST_RESULTS:
      return {
        ...state,
        invoiceList: action.invoiceList,
      }
    case PUT_COMPANY_INVOICE_RESULTS:
      return {
        ...state,
        putCompanyInvoice: action.putCompanyInvoice,
      }
    case PUT_TRIP_BILLING_BILLINGREF_RESULTS:
      return {
        ...state,
        putTripBilling: action.putTripBilling,
      }
    case RESET_GENERATE_INVOICE:
      return {
        ...state,
        putTripBilling: { success: false },
      }
    default:
      return state
  }
}
