// Management
export const GET_ACTIVITY_LIST_RESULTS = 'GET_ACTIVITY_LIST_RESULTS'
export const PUT_ACTIVITY_CONTENT_RESULTS = 'PUT_ACTIVITY_CONTENT_RESULTS'
export const DELETE_ACTIVITY_CONTENT_RESULTS = 'DELETE_ACTIVITY_CONTENT_RESULTS'
export const RESET_ACTIVITY_CONTENT = 'RESET_ACTIVITY_CONTENT'

export const getActivityListResults = data => ({
  type: GET_ACTIVITY_LIST_RESULTS,
  activityList: data,
})

export const putActivityContentResults = data => ({
  type: PUT_ACTIVITY_CONTENT_RESULTS,
  putActivityContent: data,
})

export const deleteActivityContentResults = data => ({
  type: DELETE_ACTIVITY_CONTENT_RESULTS,
  deleteActivityContent: data,
})

export const resetActivityContent = () => ({
  type: RESET_ACTIVITY_CONTENT,
})
