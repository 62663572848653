import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { CheckIconItem } from './index'
import { CssGrid } from '../../../configCSS'
import {
  Input,
} from '../../../components'
import locationFormatRegExp from '../../../components/utils/function/regEx'

export default function ExtensionItem(props) {
  const {
    extensionList,
    locationList,
    item,
    index,
    onChange,
    onDelete,
    redirectToExtension,
  } = props;

  // ********************************
  // On change extension
  const onChangeExtension = (event, newValue) => {
    let newItem = {}
    if (typeof newValue === 'string') {
      // on press Enter
      const valueExist = extensionList.filter(d => d.extension === newValue)
      if (_.isEmpty(valueExist)) {
        redirectToExtension({ extension: newValue, location: item.location, id_location: item.id_lieu }, index)
      } else {
        newItem = { ...valueExist[0] }
        onChange(newItem, index)
      }
    } else if (newValue && newValue.inputValue) {
      // select naw value 'add'
      redirectToExtension({ extension: newValue.inputValue, location: item.location, id_location: item.id_lieu }, index)
    } else if (!newValue) {
      // clear input by the cross
      newItem = { extension: '', location: '' }
      onChange(newItem, index)
    } else {
      // select an extension in list
      newItem = { ...newValue }
      onChange(newItem, index)
    }
    return null
  }

  const filterOptionsExtension = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = extensionList.filter(d => d.extension === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        extension: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  const getOptionLabelExtension = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.extension) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.extension;
  }

  // On change location
  const onChangeLocation = (event, newValue) => {
    // const isExtensionEmpty = !!item.extension
    let newItem = {}
    if (typeof newValue === 'string') {
    // on press Enter
      newItem = { extension: '', location: '' }
    } else if (!newValue) {
      // clear input location
      newItem = { extension: '', location: '' }
    } else {
      // select a location in list
      newItem = { extension: '', location: newValue.location }
    }
    onChange(newItem, index)
  }

  const filterOptionsLocation = (options, params) => {
    // const filter = createFilterOptions();
    // const filtered = filter(options, params);
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    return filtered;
  }

  const getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      // return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return ''
    }
    // Regular option
    return option.location;
  }

  // ***********************************
  // utils functions
  const filterExtensionList = location => extensionList.filter(ex => ex.location === location) || extensionList

  // ***************************
  // Display input
  const displayAutocompleteExtension = () => {
    const matchLocationInList = filterExtensionList(item.location)
    const extensionListFiltered = _.isEmpty(matchLocationInList) ? extensionList : matchLocationInList
    return (
      <Autocomplete
        id={`extension-${index}`}
        value={item.extension}
        onChange={onChangeExtension}
        filterOptions={filterOptionsExtension}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={extensionListFiltered}
        getOptionLabel={getOptionLabelExtension}
        renderOption={option => option.extension}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label="Extension"
            margin="normal"
            placeholder="Select an extension or create a new one"
            variant="outlined"
            required
          />
        )}
      />
    )
  }

  const displayAutocompleteLocation = () => (
    <Autocomplete
      id={`location-${index}`}
      value={item}
      onChange={onChangeLocation}
      filterOptions={filterOptionsLocation}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={locationList}
      getOptionLabel={getOptionLabelLocation}
      renderOption={option => option.lieu}
      freeSolo
      renderInput={params => (
        <Input
          {...params}
          label="Location"
          margin="normal"
          placeholder="Select a location"
          variant="outlined"
          required
        />
      )}
    />
  );

  const displayDeleteBtn = () => (
    <div className="deleteExtension-flex">
      <div
        title="delete"
        className="deleteExtension"
        onClick={() => onDelete(index)}
      >
        <DeleteOutlineTwoToneIcon
          fontSize="large"
        />
      </div>
    </div>
  )

  return (
    <div key={index} className="extension-content-container">
      {/* Bullet */}
      <CheckIconItem extension={item.extension} />
      <CssGrid
        container
        spacing={2}
      >
        {/* Extension */}
        <CssGrid item md={9} xs={12} className="extension-select-container">
          {displayAutocompleteExtension()}
        </CssGrid>
        {/* location */}
        <CssGrid item md={3} xs={12} className="location-select-container">
          {displayAutocompleteLocation()}
        </CssGrid>
      </CssGrid>
      {displayDeleteBtn(index)}
    </div>
  )
}

ExtensionItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  extensionList: PropTypes.array.isRequired,
  locationList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  redirectToExtension: PropTypes.func.isRequired,
};
