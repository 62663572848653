import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getTripPriceResults,
  putTripPriceResults,
  deleteRowPriceResults,
  resetDataColumnPriceResults,
  getAdditionalColumnPriceResults,
  putAdditionalColumnPriceResults,
  getTripBillingResults,
  putTripBillingResults,
  putTripBillingAccoResults,
  reorderDaysRankResults,
  getTripActivitiesResults,
  putTripDefaultActivitiesResults,
  putTripActivityResults,
  putTripCheckActivityResults,
  putTripTravellerActivityResults,
  putTripPriceActivityResults,
  putTripAccomodationActivityResults,
  putTripContentAccoResults,
  deleteTripActivitiesResults,
  deleteTripActivityResults,
} from './actions'

// ********** TABLE PRICE *********************

function* getTripPriceAsync(params) {
  const {
    token,
    idDevis,
  } = params
  const data = yield call(api.get_trip_price, token, idDevis)
  yield put(getTripPriceResults(data))
}

function* putTripPriceAsync(params) {
  const {
    token,
    idDevis,
    changes,
  } = params
  const data = yield call(api.put_trip_price, token, idDevis, changes)
  yield put(putTripPriceResults(data))
}

function* deleteRowPriceAsync(params) {
  const {
    token,
    idDevis,
    idDay,
    idCircuit,
    dayIndex,
  } = params
  const data = yield call(api.delete_row_price, token, idDevis, idDay, idCircuit, dayIndex)
  yield put(deleteRowPriceResults(data))
}

function* resetDataColumnPriceAsync(params) {
  const {
    token,
    idDevis,
    column,
  } = params
  const data = yield call(api.reset_data_column_price, token, idDevis, column)
  yield put(resetDataColumnPriceResults(data))
}

function* getAdditionalColumnPriceAsync(params) {
  const {
    token,
    idDevis,
  } = params
  const data = yield call(api.get_additional_column_price, token, idDevis)
  yield put(getAdditionalColumnPriceResults(data))
}

function* putAdditionalColumnPriceAsync(params) {
  const {
    token,
    idAdditionalColumn,
    idDevis,
    columnRenamed,
  } = params
  const data = yield call(api.put_additional_column_price, token, idAdditionalColumn, idDevis, columnRenamed)
  yield put(putAdditionalColumnPriceResults(data))
}


// ********** ACCO ROOM *********************
function* putTripContentAccoAsync(params) {
  const {
    token,
    idCircuit,
    tableauAcco,
    tableauRoom,
    nbRoom,
  } = params
  const data = yield call(api.put_trip_content_acco, token, idCircuit, tableauAcco, tableauRoom, nbRoom)
  yield put(putTripContentAccoResults(data))
}

// ********** BILLING *********************


function* getTripBillingAsync(params) {
  const {
    token,
    idDevis,
  } = params
  const data = yield call(api.get_trip_billing, token, idDevis)
  yield put(getTripBillingResults(data))
}

function* putTripBillingAsync(params) {
  const {
    token,
    idDevis,
    billingDetails,
  } = params
  const data = yield call(api.put_trip_billing, token, idDevis, billingDetails)
  yield put(putTripBillingResults(data))
}

function* putTripBillingAccoAsync(params) {
  const {
    token,
    idDevis,
    accoChoice,
  } = params
  const data = yield call(api.put_trip_billing_acco, token, idDevis, accoChoice)
  yield put(putTripBillingAccoResults(data))
}

function* reorderDaysRankAsync(params) {
  const {
    token,
    idDevis,
    days,
    tripPrice,
    tripActivities,
  } = params
  const data = yield call(api.reorder_days_rank, token, idDevis, days, tripPrice, tripActivities)
  yield put(reorderDaysRankResults(data))
}


// ********** ACTIVITIES *********************


function* getTripActivitiesAsync(params) {
  const {
    token,
    idCircuit,
  } = params
  const data = yield call(api.get_trip_activities, token, idCircuit)
  yield put(getTripActivitiesResults(data))
}

function* putTripDefaultActivitiesAsync(params) {
  const {
    token,
    idCircuit,
    idJournee,
    dayIndex,
    nbSenior,
    nbAdult,
    nbChild,
  } = params
  const data = yield call(api.put_trip_default_activities, token, idCircuit, idJournee, dayIndex, nbSenior, nbAdult, nbChild)
  yield put(putTripDefaultActivitiesResults(data))
}

function* putTripActivityAsync(params) {
  const {
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbSenior,
    nbAdult,
    nbChild,
  } = params
  const data = yield call(api.put_trip_activity, token, idCircuit, idJournee, dayIndex, idActivity, nbSenior, nbAdult, nbChild)
  yield put(putTripActivityResults(data))
}

function* putTripCheckActivityAsync(params) {
  const {
    token,
    tripActivities,
  } = params
  const data = yield call(api.put_trip_check_activity, token, tripActivities)
  yield put(putTripCheckActivityResults(data))
}

function* putTripTravellerActivityAsync(params) {
  const {
    token,
    tripActivities,
    nbSenior,
    nbAdult,
    nbChild,
  } = params
  const data = yield call(api.put_trip_traveller_activity, token, tripActivities, nbSenior, nbAdult, nbChild)
  yield put(putTripTravellerActivityResults(data))
}

function* putTripPriceActivityAsync(params) {
  const {
    token,
    priceChange,
  } = params
  const data = yield call(api.put_trip_price_activity, token, priceChange)
  yield put(putTripPriceActivityResults(data))
}

function* putTripAccomodationActivityAsync(params) {
  const {
    token,
    tripAllDaysAccomodations,
    tripActivities,
    idTripSelected,
    idAcco,
  } = params
  const data = yield call(api.put_trip_accomodation_activity, token, tripAllDaysAccomodations, tripActivities, idTripSelected, idAcco)
  yield put(putTripAccomodationActivityResults(data))
}

function* deleteTripActivitiesAsync(params) {
  const {
    token,
    idCircuit,
    idJournee,
    dayIndex,
    idActivity,
    nbActivities,
  } = params
  const data = yield call(api.delete_trip_activities, token, idCircuit, idJournee, dayIndex, idActivity, nbActivities)
  yield put(deleteTripActivitiesResults(data))
}

function* deleteTripActivityAsync(params) {
  const {
    token,
    id_act_cir,
  } = params
  const data = yield call(api.delete_trip_activity, token, id_act_cir)
  yield put(deleteTripActivityResults(data))
}
// *********************************************************

function* fetchResult() {
  yield takeEvery('GET_TRIP_PRICE', getTripPriceAsync)
  yield takeEvery('PUT_TRIP_PRICE', putTripPriceAsync)
  yield takeEvery('DELETE_ROW_PRICE', deleteRowPriceAsync)
  yield takeEvery('RESET_DATA_COLUMN_PRICE', resetDataColumnPriceAsync)
  yield takeEvery('GET_ADDITIONAL_COLUMN_PRICE', getAdditionalColumnPriceAsync)
  yield takeEvery('PUT_ADDITIONAL_COLUMN_PRICE', putAdditionalColumnPriceAsync)
  yield takeEvery('GET_TRIP_BILLING', getTripBillingAsync)
  yield takeEvery('PUT_TRIP_BILLING', putTripBillingAsync)
  yield takeEvery('PUT_TRIP_BILLING_ACCO', putTripBillingAccoAsync)
  yield takeEvery('REORDER_DAYS_RANK', reorderDaysRankAsync)
  yield takeEvery('GET_TRIP_ACTIVITIES', getTripActivitiesAsync)
  yield takeEvery('PUT_TRIP_DEFAULT_ACTIVITIES', putTripDefaultActivitiesAsync)
  yield takeEvery('PUT_TRIP_ACTIVITY', putTripActivityAsync)
  yield takeEvery('PUT_TRIP_CHECK_ACTIVITY', putTripCheckActivityAsync)
  yield takeEvery('PUT_TRIP_TRAVELLER_ACTIVITY', putTripTravellerActivityAsync)
  yield takeEvery('PUT_TRIP_PRICE_ACTIVITY', putTripPriceActivityAsync)
  yield takeEvery('PUT_TRIP_CONTENT_ACCO', putTripContentAccoAsync)
  yield takeEvery('PUT_TRIP_ACCOMODATION_ACTIVITY', putTripAccomodationActivityAsync)
  yield takeEvery('DELETE_TRIP_ACTIVITIES', deleteTripActivitiesAsync)
  yield takeEvery('DELETE_TRIP_ACTIVITY', deleteTripActivityAsync)
}

export default fetchResult
