import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  AddButton,
  Loader,
} from '../../../components'
import travelersColumns from '../../../components/utils/table/travlersColumns'

const {
  Edit,
} = tableIcons

class TravelerTable extends PureComponent {
  static propTypes = {
    GET_ALL_TRAVELER: PropTypes.func.isRequired,
    DELETE_TRAVELERS: PropTypes.func.isRequired,
    RESET_TRAVELER_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    travelerList: PropTypes.array,
    deleteTravelers: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    travelerList: [],
    deleteTravelers: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateTravelerList = this.updateTravelerList.bind(this)
    this.deleteTravelers = this.deleteTravelers.bind(this)
    this.editTraveler = this.editTraveler.bind(this)
    this.getIdTrip = this.getIdTrip.bind(this)
    this.goToTraveler = this.goToTraveler.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      travelerList: [],
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      travelerList,
      GET_ALL_TRAVELER,
      LOADER,
    } = this.props
    const { token } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(travelerList)) {
      GET_ALL_TRAVELER(token)
      LOADER(true)
    } else {
      this.setState({
        travelerList,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { token } = this.state
    const {
      travelerList,
      deleteTravelers,
      RESET_TRAVELER_CONTENT,
      GET_ALL_TRAVELER,
      LOADER,
    } = this.props
    if (deleteTravelers && deleteTravelers.success) {
      RESET_TRAVELER_CONTENT()
      GET_ALL_TRAVELER(token)
      this.reload(deleteTravelers.message)
      this.resetValidMsg()
    }
    if (travelerList !== prevProps.travelerList) {
      LOADER(false)
      this.updateTravelerList()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  updateTravelerList = () => {
    const { travelerList } = this.props
    this.setState({
      travelerList,
    })
  }

  deleteTravelers = (rowsDeleted) => {
    const { token } = this.state
    const {
      travelerList,
      DELETE_TRAVELERS,
      LOADER,
    } = this.props
    const indexTravelersDeleted = rowsDeleted.data.map(d => d.dataIndex)
    if (confirm(`Do you really want to delete traveler${indexTravelersDeleted.length > 1 ? 's' : ''} ?`)) {
      LOADER(true)
      const idTravelersDeleted = indexTravelersDeleted.map(i => travelerList[i].id_traveler)
      DELETE_TRAVELERS(token, idTravelersDeleted)
      // update state without travelers deleted
      const newTravelerList = _.cloneDeep(travelerList)
      indexTravelersDeleted.map(id => newTravelerList.splice(id, 1))
      this.setState({
        travelerList: newTravelerList,
      })
    }
  }

  editTraveler = (rowsSelected) => {
    const idTrip = this.getIdTrip(rowsSelected)
    this.goToTraveler({
      source: 'traveler-table',
      idTrip,
    })
  }

  getIdTrip = (rowsSelected) => {
    const {
      travelerList,
    } = this.props
    return travelerList[rowsSelected.data[0].dataIndex].id_devis
  }

  goToTraveler = (data = {}) => {
    const { history } = this.props
    history.push('traveler-management', { ...data })
  }

  // ************************* Display *************************************
  // custom tool bar when a day is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editTraveler(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteTravelers(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => (
    <AddButton
      title="Add new day"
      goTo={() => this.goToTraveler()}
    />
  )

  // ********************************************************
  render() {
    const {
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      travelerList,
    } = this.state
    const { isLoading } = this.props

    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'multiple',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'tableDownload.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }


    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="travelerContainer">
              <h2>TRAVELERS LIST</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <MUIDataTable
                className="table"
                title="Travelers"
                data={travelerList}
                columns={travelersColumns}
                options={options}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  travelerList: state.travelerReducer.travelerList,
  deleteTravelers: state.travelerReducer.deleteTravelers,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_ALL_TRAVELER: token => dispatch({
    type: 'GET_ALL_TRAVELER',
    token,
  }),
  DELETE_TRAVELERS: (token, idTravelersArray) => dispatch({
    type: 'DELETE_TRAVELERS',
    token,
    idTravelersArray,
  }),
  RESET_TRAVELER_CONTENT: () => dispatch({
    type: 'RESET_TRAVELER_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TravelerTable))
