import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getExtensionListResults,
  putExtensionContentResults,
  deleteExtensionContentResults,
} from './actions'

function* getExtensionListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_extension_list, userId)
  yield put(getExtensionListResults(data))
}

function* putExtensionContentAsync(params) {
  const {
    token,
    extensionContent,
    action,
  } = params
  const data = yield call(api.put_extension_content, token, extensionContent, action)
  yield put(putExtensionContentResults(data))
}

function* deleteExtensionContentAsync(params) {
  const {
    token,
    extensionId,
  } = params
  const data = yield call(api.delete_extension_content, token, extensionId)
  yield put(deleteExtensionContentResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_EXTENSION_LIST', getExtensionListAsync)
  yield takeEvery('PUT_EXTENSION_CONTENT', putExtensionContentAsync)
  yield takeEvery('DELETE_EXTENSION_CONTENT', deleteExtensionContentAsync)
}

export default fetchResult
