
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import { connect } from 'react-redux'
import { renderToStaticMarkup } from 'react-dom/server';
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import Interweave from 'interweave';
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'

const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlHorizonSpecific extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripBilling: PropTypes.object,
    tripAllDays: PropTypes.array,
    tripDevisFinal: PropTypes.object,
    tripPension: PropTypes.array,
    tripAccomodation: PropTypes.array,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    tripDetails: [],
    tripPension: [],
    tripDevisFinal: {},

    tripBilling: {},
    tripContent: [],
    tripAccomodation: [],
    tripAllDaysAccomodations: [],
    tripAllDays: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],
  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.handleOSizeZoomChange = this.handleOSizeZoomChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',

    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }
    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteHorizon-size', `${event.currentTarget.value}px`)
  }

  handlePrint = (html) => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }

  handleEditorChange = (content) => {
    this.setState({
      devisContent: content,
    });
  }


  handleSave = (devisContent) => {
    const {
      PUT_TRIP_DEVIS_FINAL,
      tripDetails,
      tripDevisFinal,
    } = this.props
    const {
      token,
    } = this.state
    const tripDevisFinalServeur = {}
    tripDevisFinalServeur.textHtml = devisContent
    tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
    } else {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
    }
  }


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis
        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'http://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word(element, filename = '') {
    const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = '</body></html>';
    const html = preHtml + document.getElementById('exportContent').innerHTML + postHtml;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];
      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }
    const {
      tripDetails,
      tripAllDaysAccomodations,
      companyContent,
      informationsContent,
      tripAccomodation,
      userAccount,
      tripBilling,
      tripPension,
      tripContent,
      tripAllDays,
      locationList,
      roomList,
    } = this.props

    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      date_creation,
      version,
      desc_voyage,
      code_devis,
      destination,
      centre_interet,
      langage,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      devisContent,

    } = this.state

    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]
    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const NombreInformation = [0, 1, 2, 3, 4];
    const nbAdultNumb = nb_adulte && !isNaN(parseInt(nb_adulte, 10)) ? parseInt(nb_adulte, 10) : 0
    const nbChildNumb = nb_enfant && !isNaN(parseInt(nb_enfant, 10)) ? parseInt(nb_enfant, 10) : 0
    const nbBabyNumb = nb_bebe && !isNaN(parseInt(nb_bebe, 10)) ? parseInt(nb_bebe, 10) : 0
    const totalTraveler = nbAdultNumb + nbChildNumb + nbBabyNumb
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const advisorMail = !_.isEmpty(userAccount) ? userAccount.email : null
    const advisorPhone = !_.isEmpty(userAccount) ? userAccount.phone : null
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const isTransport = tripAllDays.filter(c => c.transport !== '')
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const remarque = `${langage}_remarque`
    const jonqueDesc = `${langage}_desc_hebergement`
    const slogan = `${langage}_slogan`
    const presentation = `${langage}_presentation`
    const categorie = `${langage}_categorie`
    const tauxchange = `${langage}_taux_change`
    const langageDate = langage === 'sp' ? 'es' : langage
    const prixPersNoReduc = Math.round((tripBilling.sellingPrice / totalTraveler) * 100) / 100
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const Couleurdefaut = {
      color: informationsContent.main_color,
    };

    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const encadre = {
      borderStyle: 'solid',
      borderWidth: '0.5px',
    };

    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : '. '}`)
      return label.join('')
    }
    const myPage = (
      <div id="exportContent" className="GlobalFileHvt">
        <div className="page-header-horizonhtml">
          <div className="logoTextHorizon">
            <img width="168" height="112" className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
            <Interweave content={companyContent[slogan]} />
          </div>
          <hr size="1" />
          <hr className="epaisseurLigne" />
          <div className="navbar">
            <a href="#home">{langageTranslation.homeMenu}</a>
            {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
            <a href="#carte">{langageTranslation.carteMenu}</a>)}
            <a href="#detail">{langageTranslation.detailMenu}</a>
            <a href="#hotel">{langageTranslation.hotelMenu}</a>
            <a href="#prix">{langageTranslation.prixMenu}</a>
            <a href="#info">{langageTranslation.infoMenu}</a>
          </div>
        </div>
        <div className="page-header-horizon">
          <div className="logoTextHorizon">
            <img width="168" height="112" className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
            <Interweave content={companyContent[slogan]} />
          </div>
          <hr size="1" />
          <hr className="epaisseurLigne" />
        </div>
        <table className="table-horizon">
          <thead>
            <tr>
              <td>
                <div className="page-header-space" />
              </td>
            </tr>
          </thead>
          <tbody className="mainContentHorizon">
            <tr>
              <td>
                <div id="home" className="titre" style={Couleurdefaut}>{langageTranslation.travelProject}</div>
                <div className="soustitre" style={Couleurdefaut}>{langageTranslation.departureDate}: {moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langageDate).format('DD MMMM YYYY')}</div>
                <div className="titre">{tripDetails && (tripDetails[0].nom_programme)}</div>
                <Grid className="FirstInfo" container spacing={1}>
                  <Grid md="auto" item xs={3}>
                    <div className="titrounet" style={Couleurdefaut}>
                      { code_devis && (<div>{langageTranslation.codeQuote}: </div>)}
                      {tripAllDaysAccomodations[0].start_location && (<div>{langageTranslation.AtDeparture}: </div>)}
                      <div>{langageTranslation.circuitType}:  </div>
                      <div>{langageTranslation.start}: </div>
                      {destination && (<div>{langageTranslation.destination}: </div>)}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="titrounet2">
                      {code_devis && (<div> {code_devis}</div>)}
                      { tripAllDaysAccomodations[0].start_location && (<div> {tripAllDaysAccomodations[0].start_location}</div>)}
                      <div> {langageTranslation.individual}</div>
                      <div> {langageTranslation.allDay}</div>
                      {destination && (<div> {destination}</div>)}
                    </div>
                  </Grid>
                </Grid>
                <Grid className="TableauIntro" container spacing={0} align="row" justifyContent="center">
                  <Grid item xs={3}>
                    <div className="TabIntroCol1">{langageTranslation.groupName}</div>
                    <div className="TabIntroCol1">{langageTranslation.version}</div>
                    <div className="TabIntroCol1">{langageTranslation.groupSize}</div>
                    <div className="TabIntroCol1bottom">{langageTranslation.tripType}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="TabIntroCol1">{travelerName}</div>
                    <div className="TabIntroCol1">{version}</div>
                    <div className="TabIntroCol1">{nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}</div>
                    <div className="TabIntroCol1bottom">{langageTranslation.individual}</div>
                  </Grid>
                  <Grid item xs={3}>
                    {version !== 1 ? (<div className="TabIntroCol1">{langageTranslation.modificationDate}</div>) : (<div className="TabIntroCol1">{langageTranslation.creationDate}</div>)}
                    <div className="encadreinfoconseiller">
                      <div className="textconseil">{langageTranslation.textConseil}</div>
                      <div className="textconseil">{advisorName}</div>
                      <div className="textconseil">
                        <a href={advisorMail} className="emailconseil">{advisorMail}</a>
                      </div>
                      <div className="textconseil">{advisorPhone}</div>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="TabIntroCol1right"> {date_creation}</div>
                    <div className="encadre">
                      <img width="168" height="112" className="photo" src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
                    </div>
                  </Grid>
                </Grid>
                <div className="paragraphevert" style={Couleurdefaut}>{langageTranslation.textHorizon1} {nb_adulte && nb_adulte} {nb_enfant && `, ${nb_enfant}`} {nb_bebe && `, ${nb_bebe}`}, {langageTranslation.textHorizon2}</div>
                <div className="titrevert" style={Couleurdefaut}>{langageTranslation.spiritTrip} : </div>
                <Interweave content={desc_voyage} />
                <div className="titrevert" style={Couleurdefaut}>{langageTranslation.interest} : </div>
                <Interweave content={centre_interet} />
                <div className="CoupePas">
                  <div className="titrevert" style={Couleurdefaut}>{langageTranslation.itineraryInBrief} : </div>
                  <div>
                    <table width="100%" color="blue" border="0.5px">
                      <tbody>
                        <tr>
                          <td style={encadre}>{langageTranslation.date}</td>
                          <td>{langageTranslation.itinerary}</td>
                          <td>{langageTranslation.accomodation}</td>
                          <td>{langageTranslation.meals}</td>
                        </tr>
                        {tripAllDaysAccomodations.map((item, index) => {
                          const IncDay = _.sum(TabIncJour.slice(0, index))
                          return (
                            <div key={`Bref${IncDay}`}>
                              <a href={`#Jour${index}`}>
                                <tr>
                                  <td style={Couleurdefaut}>{langageTranslation.day} {IncDay + 1}</td>
                                  <td style={Couleurdefaut}>{item.nom_journee_court}</td>
                                  <td style={Couleurdefaut}>{tripAccomodation[index].type}</td>
                                  <td style={Couleurdefaut}>{tripPension[index].pension !== 'none' && tripPension[index].pension}</td>
                                </tr>
                              </a>
                            </div>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {(tripDetails[0].image || (tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
                <div>
                  <div className="CoupePas">
                    {tripDetails[0].image
                      ? (
                        <div>
                          <div id="carte" className="titrevert" style={Couleurdefaut}>{langageTranslation.mapOfTrip} : </div>
                          <Grid container spacing={0} justifyContent="center">
                            <Grid item xs={12}>
                              <img width="168" height="112" className="CarteHorizon" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                            </Grid>
                          </Grid>
                          <input className="adjustSize" name="size" min="200" max="1600" type="range" defaultValue="1000" onChange={this.handleOSizeChange} />
                        </div>
                      ) : (
                        tripDetails[0].map_localisation
                        && (
                        <div>
                          <div id="carte" className="titrevert" style={Couleurdefaut}>{langageTranslation.mapOfTrip} : </div>
                          <img width="168" height="112" className="CarteHorizon" src={urlMap} />
                        </div>
                        )
                      )
                    }
                  </div>
                </div>
                )}
                <div className="CoupePas">
                  <div id="detail" className="titrevert" style={Couleurdefaut}>{langageTranslation.detailedProgram} : </div>
                  {tripAllDays.map((item_jour, index) => {
                    const desc = `desc_jour_${index + 1}`;
                    const title_perso = `titre_jour_${index + 1}`;
                    const imageArray = item_jour.image.split(',')
                    const IncDay = _.sum(TabIncJour.slice(0, index))
                    const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langageDate).add(IncDay, 'd').format('dddd DD MMMM YYYY'))
                      : ''
                    // eslint-disable-next-line no-nested-ternary
                    const guideNeed = guideArray[index] === '1' ? langageTranslation.withGuide : guideArray[index] === '2' ? langageTranslation.withGuideEn : ''
                    const driverNeed = chauffeurArray[index] === '1' ? langageTranslation.driver : ''
                    const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                    const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver
                    return (
                      <div key={`detail${IncDay}`}>
                        <div className="CoupePas">
                          <div className="titrevert" id={`Jour${index}`} style={Couleurdefaut}> {langageTranslation.day} {IncDay + 1} : {tripContent[0][title_perso]} {tripPension[index].pension !== 'none' ? (`(${tripPension[index].pension})`) : ('')}</div>
                          <div className="datejour" style={Couleurdefaut}> {datejour}</div>
                          <div className="paragraphevert" style={Couleurdefaut}>* {asteriskText}</div>
                          {item_jour.distance && (<div className="paragraphevert" style={Couleurdefaut}>* {item_jour.distance}</div>)}
                          {imageArray[0] !== '' && imageArray.length === 1
                            ? (
                              <Grid className="justPaddingBottom" container spacing={2} justifyContent="center">
                                <Grid className="OptionTextJournee" item xs={12} sm={12} md={8}>
                                  <Interweave content={tripContent[0][desc]} />
                                  {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
                                  {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={tripAccomodation[index].adresse} /></span><span>)</span></>)}
                                  {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                </Grid>
                                <Grid className="Image1Jour1Horizon" item xs={12} sm={12} md={4}>
                                  <img width="168" height="112" className="Image1Jour1Horizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                </Grid>
                              </Grid>
                            ) : (
                              <div>
                                <Grid container spacing={0} justifyContent="center">
                                  <Grid className="OptionTextJournee" item xs={12}>
                                    <Interweave content={tripContent[0][desc]} />
                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
                                    {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={tripAccomodation[index].adresse} /></span><span>)</span></>)}
                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                  </Grid>
                                </Grid>
                              </div>
                            )
                     }
                        </div>
                        {imageArray[0] !== '' && imageArray.length === 2
                  && (
                    <div>
                      <Grid container spacing={10} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                        {imageArray[0] !== '' && imageArray.length === 3
          && (
            <div>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={4}>
                  <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                </Grid>
                <Grid item xs={4}>
                  <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                </Grid>
                <Grid item xs={4}>
                  <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
                </Grid>
              </Grid>
            </div>
          )}
                      </div>
                    )
                  })}
                </div>
                <div className="paragraphebleu">*** {langageTranslation.serviceEnd} ***</div>
                <div className="titreGras" style={Couleurdefaut}>{langageTranslation.TravelInformation}: </div>
                <div className="titreGras" style={Couleurdefaut}>{langageTranslation.groupName}: {travelerName}</div>
                <div className="titreGras" style={Couleurdefaut}>{langageTranslation.textTravelerNumber}: {nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}</div>
                <div className="CoupePas">
                  <div id="hotel" className="titrevert" style={Couleurdefaut}>{langageTranslation.yourHotels} </div>
                  <table className="justPaddingBottom" spacing={0}>
                    <tr>
                      <td className="TabItineraireHorizonHead" style={Couleurdefaut}>{langageTranslation.destination}</td>
                      <td className="TabItineraireHorizonHead" style={Couleurdefaut}>{langageTranslation.accomodation}</td>
                      <td className="TabItineraireHorizonHeadRight" style={Couleurdefaut}>{langageTranslation.nightNumber}</td>
                    </tr>
                    {Hotel_Unique && Hotel_Unique.map((item, i) => {
                      const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                      return (
                        <div key={`Acco${item.id_journee}`}>
                          {item.nom_hebergement !== 'none'
                && (
                <div>
                  <tr>
                    <td className="TabItineraireHorizonHotel" style={Couleurdefaut}>{LieuFinJournee.lieu}</td>
                    <td className="TabItineraireHorizonHotelDesc" style={Couleurdefaut}>
                      <div>{item.nom_hebergement} {item[categorie]}</div>
                      <Interweave content={item.adresse} />
                    </td>
                    <td className="TabItineraireHorizonRightHotel" style={Couleurdefaut}>{Nombre_Occurence_Hotel[i].occurrence}</td>
                  </tr>
                </div>
                )}
                        </div>
                      )
                    })}
                  </table>
                </div>
                {!_.isEmpty(isTransport)
              && (
              <div className="CoupePas">
                <div className="titrevert" style={Couleurdefaut}>{langageTranslation.reservation}: </div>
                <div className="TabItineraireGlobal">
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={12}>
                      <Grid container spacing={0} justifyContent="center">
                        <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={3}>{langageTranslation.date}</Grid>
                        <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={7}>{langageTranslation.route}</Grid>
                        <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={2}>{langageTranslation.reservationState}</Grid>
                      </Grid>
                      {tripAllDays.map((item_jour, index) => (
                        <div key={`Transport${item_jour.id_journee}`}>
                          {item_jour.transport && (
                            <Grid container spacing={0} justifyContent="center">
                              <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={3}>{langageTranslation.day} {index + 1}</Grid>
                              <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={7}>{item_jour.transport}</Grid>
                              <Grid className="TabItineraireHorizon" style={Couleurdefaut} item xs={2}>{langageTranslation.available}</Grid>
                            </Grid>
                          )}
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </div>
              )}
                {tripBilling.tripPrice && (
                <div>
                  <div className="CoupePas">
                    <div id="prix" className="titrevert" style={Couleurdefaut}>{langageTranslation.quoteTrip} :  </div>
                    <div className="paragraphevert2" style={Couleurdefaut}> ({langageTranslation.priceCalc} {tripBilling.currency} {langageTranslation.priceCalc2} {nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}) </div>
                    {tripBilling.displayTabCheck === 0 ? (
                      <div className="TabItineraireGlobal">
                        <Grid container spacing={0} align="row" justifyContent="center">
                          <Grid item xs={12}>
                            <div style={Couleurdefaut} className="TabItineraireHorizon"> {tripDetails[0].nom_programme}</div>
                          </Grid>
                          <Grid item xs={6}>
                            {totalTraveler !== 0 && (<div style={Couleurdefaut} className="TabItineraireHorizon"> {langageTranslation.groupSize}</div>)}
                            <div style={Couleurdefaut} className="TabItineraireHorizon"> {langageTranslation.pricePerPerson}   </div>
                          </Grid>
                          <Grid item xs={6}>
                            {totalTraveler !== 0 && (<div style={Couleurdefaut} className="TabItineraireHorizon"> {totalTraveler}</div>)}
                            <div style={Couleurdefaut} className="TabItineraireHorizon">  {prixPersNoReduc} {tripBilling.currency} </div>
                          </Grid>
                          {tripBilling.amountDiscount !== 0 && (
                          <Grid item xs={12}>
                            <div className="TabItineraireHorizonReduc">{langageTranslation.giftDiscount1} {tripBilling.discount} {langageTranslation.giftDiscount2}  </div>
                          </Grid>
                          )}
                          <Grid item xs={6}>
                            {tripBilling.amountDiscount !== 0 ? (<div style={Couleurdefaut} className="TabItineraireHorizon">{langageTranslation.priceAfterDiscount} </div>)
                              : (
                                <div style={Couleurdefaut} className="TabItineraireHorizon">{langageTranslation.pricePerPerson} </div>
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            <div style={Couleurdefaut} className="TabItineraireHorizon"> {tripBilling.pricePerPerson} {tripBilling.currency}/personne </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div style={Couleurdefaut} className="TabItineraireHorizon">
                              <div>{langageTranslation.total} </div>
                              <div>  {tripBilling.tripPrice} {tripBilling.currency} </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <Grid container spacing={0} align="row" justifyContent="center">
                        <Grid item xs={12}>
                          <div style={Couleurdefaut} className="titreGrasCentre"> {tripDetails[0].nom_programme}</div>
                        </Grid>
                        <Grid item xs={12}>
                          <Interweave content={tripBilling.tabPrice} />
                        </Grid>
                      </Grid>
                    )}
                    <div className="paragraphevert" style={Couleurdefaut}>{informationsContent[tauxchange]}</div>
                  </div>
                  <div style={Couleurdefaut} className="titrevert">{langageTranslation.priceContained}: </div>
                  <Interweave content={tripDetails[0].prix_comprend} />
                  <div style={Couleurdefaut} className="titrevert">{langageTranslation.priceDoNotContained}: </div>
                  <Interweave content={tripDetails[0].prix_comprend_pas} />
                </div>
                )}
                <div className="CoupePas">
                  <div id="info" style={Couleurdefaut} className="titrevert"> {langageTranslation.prestationDetail} </div>
                  {Hotel_Unique && Hotel_Unique.map((item, i) => {
                    const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                    const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                    return (
                      <div key={`Acco${item.id_journee}`}>
                        {(item.type === 'Jonque' || item.type === 'Crucero')
                && (
                  <div>
                    <div className="CoupePas">
                      <Grid container spacing={0} justifyContent="center">
                        <Grid style={Fonddefaut} className="TitreInfoGeneral" item xs={12}>
                          <div> {langageTranslation.jonque} {LieuFinJournee.lieu}</div>
                          <div>{item.nom_hebergement} - {Nombre_Occurence_Hotel[i].occurrence} {langageTranslation.night}</div>
                        </Grid>
                        {item[jonqueDesc] && (
                        <Grid item xs={12}>
                          <Interweave content={item[jonqueDesc]} />
                        </Grid>
                        )}
                      </Grid>
                    </div>
                    {imageArrayHotel[0] !== '' && imageArrayHotel.length === 1
                  && (
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={4}>
                        <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                      </Grid>
                    </Grid>
                  )}
                    {imageArrayHotel[0] !== '' && imageArrayHotel.length === 2
                  && (
                    <div>
                      <Grid container spacing={10} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                    {imageArrayHotel[0] !== '' && imageArrayHotel.length === 3
                  && (
                    <div>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="168" height="112" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  </div>
                )}
                      </div>
                    )
                  })}
                </div>
                <div className="CoupePas">
                  <div style={Fonddefaut} className="TitreInfoGeneral">{langageTranslation.TitreTransport}</div>
                  <Grid container spacing={1} justifyContent="center">
                    {totalTraveler <= 2 && (
                    <Grid item xs={4}>
                      <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/transportsroutiers-1649659741472.jpg`} />
                    </Grid>
                    )}
                    {totalTraveler > 2 && totalTraveler <= 7 && (
                    <Grid item xs={4}>
                      <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule6place-1650443589909.png`} />
                    </Grid>
                    )}
                    {totalTraveler > 7 && totalTraveler <= 12 && (
                    <Grid item xs={4}>
                      <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule812place-1650445402443.png`} />
                    </Grid>
                    )}
                    {totalTraveler > 12 && totalTraveler <= 18 && (
                    <Grid item xs={4}>
                      <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule1318places-1650445483440.png`} />
                    </Grid>
                    )}
                    {totalTraveler > 18 && (
                    <Grid item xs={4}>
                      <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule1845places-1650445579063.png`} />
                    </Grid>
                    )}
                    <Grid item xs={8}>
                      <Interweave content={langageTranslation.vehicule} />
                    </Grid>
                  </Grid>
                </div>
                {NombreInformation.map((item, index) => {
                  const InformationText = langage && `${langage}_information_${index + 1}_description`;
                  const InformationImage = `information_${index + 1}_image`;
                  const InformationTitre = langage && `${langage}_information_${index + 1}`;
                  return (
                    <div key={`info${item}`}>
                      {informationsContent[InformationText] !== ''
                  && (
                  <div className="CoupePas">
                    <div style={Fonddefaut} className="TitreInfoGeneral">{informationsContent[InformationTitre]}</div>
                    {informationsContent[InformationImage] !== '' ? (
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="168" height="112" className="imgInformationHorizon" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />
                        </Grid>
                        <Grid item xs={8}>
                          <Interweave content={informationsContent[InformationText]} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                          <Interweave content={informationsContent[InformationText]} />
                        </Grid>
                      </Grid>
                    )
                    }
                  </div>
                  )}
                    </div>
                  )
                })}
                {langage && langage === 'fr' && (
                <div className="videoBox">
                  <div style={Fonddefaut} className="TitreInfoGeneral">Témoignages  et présentation vidéo</div>
                  <br />
                  <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={6} lg={6} sm={12}>
                      <iframe className="VideoHorizon" width="424" height="238" src="https://www.youtube.com/embed/NykjsBWz0vk" title="Ils sont satisfaits de leur voyage au Vietnam grâce à Horizon Vietnam Travel - Avis des voyageurs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </Grid>
                    <Grid item xs={6} lg={6} sm={12}>
                      <iframe className="VideoHorizon" width="424" height="238" src="https://www.youtube.com/embed/nXNJ7kyTGYo" title="Présentation de Horizon Vietnam Travel, une agence de voyage locale à taille humaine" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </Grid>
                  </Grid>
                </div>
                )}
                <Grid container spacing={0} justifyContent="center">
                  <Grid item xs={12}>
                    <Interweave content={informationsContent[remarque]} />
                  </Grid>
                </Grid>
                <div style={Couleurdefaut} className="titreGras2">{advisorName}</div>
                <div>{langageTranslation.textConseil}</div>
                <img width="168" height="112" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                <Interweave content={userAccount[presentation]} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
    const pageHtml = renderToStaticMarkup(myPage);
    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');
    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlHorizon.css">
     <title>Votre proposition de voyage avec Horizon Vietnam Travel</title>
     </head>
     <body>
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a>   ${devisContent}
     </body>
   </html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlExploration.css"
          height={1000}
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          handleEditorChange={content => this.handleEditorChange(content)}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripDevisFinal: state.api.tripDevisFinal,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlHorizonSpecific))
