import React from 'react'
import PropTypes from 'prop-types'

export default function RedirectionButton(props) {
  const {
    goTo,
    target,
    label,
    disabled,
    color,
    type,
    hide,
  } = props

  const disabledClass = disabled ? 'disabled' : ''
  const backgroundColor = color

  return (
    <div className="redirection-button">
      <a
        href="#"
        className={`redirection-button ${disabledClass} ${backgroundColor} ${hide}`}
        type={type}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            if (!target) goTo()
            // if (onClick) onClick(e)
          }
        }}
      >
        {label}
      </a>
    </div>
  )
}

RedirectionButton.propTypes = {
  goTo: PropTypes.func,
  target: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  hide: PropTypes.string,
};

RedirectionButton.defaultProps = {
  type: 'button',
  disabled: false,
  target: null,
  goTo: () => {},
  color: null,
  hide: null,
};
