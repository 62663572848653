export const GET_INFORMATIONS_CONTENT_RESULTS = 'GET_INFORMATIONS_CONTENT_RESULTS'
export const PUT_INFORMATIONS_CONTENT_RESULTS = 'PUT_INFORMATIONS_CONTENT_RESULTS'
export const DELETE_INFORMATIONS_CONTENT_RESULTS = 'DELETE_INFORMATIONS_CONTENT_RESULTS'
export const RESET_INFORMATIONS_CONTENT = 'RESET_INFORMATIONS_CONTENT'

export const getInformationsContentResults = data => ({
  type: GET_INFORMATIONS_CONTENT_RESULTS,
  informationsContent: data,
})

export const putInformationsContentResults = data => ({
  type: PUT_INFORMATIONS_CONTENT_RESULTS,
  putInformationsContent: data,
})

export const deleteInformationsContentResults = data => ({
  type: DELETE_INFORMATIONS_CONTENT_RESULTS,
  deleteInformationsContent: data,
})

export const resetInformationsContent = () => ({
  type: RESET_INFORMATIONS_CONTENT,
})
