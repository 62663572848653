import {
  GET_FILTER_QUOTE_TABLE_RESULTS,
  PUT_COLUMN_QUOTE_TABLE_RESULTS,
  PUT_FILTER_QUOTE_TABLE_RESULTS,
  RESET_FILTER_QUOTE_RESULTS,
} from './actions'
import {
  RESET_APP,
  RESET_TRIP_INFORMATION,
} from '../../../components/api/actions'

const initialState = {
  filterQuoteTable: {},
  putColumnQuoteTable: {},
  putFilterQuoteTable: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case RESET_TRIP_INFORMATION:
      return {
        ...state,
        tripPrice: [],
      }
    case GET_FILTER_QUOTE_TABLE_RESULTS:
      return {
        ...state,
        filterQuoteTable: action.filterQuoteTable[0],
      }
    case PUT_COLUMN_QUOTE_TABLE_RESULTS:
      return {
        ...state,
        putColumnQuoteTable: action.putColumnQuoteTable,
      }
    case PUT_FILTER_QUOTE_TABLE_RESULTS:
      return {
        ...state,
        putFilterQuoteTable: action.putFilterQuoteTable,
      }
    case RESET_FILTER_QUOTE_RESULTS:
      return {
        ...state,
        putColumnQuoteTable: {},
        putFilterQuoteTable: {},
      }
    default:
      return state
  }
}
