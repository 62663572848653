import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'
import Interweave from 'interweave';
import Chip from '@material-ui/core/Chip';
import Geocode from 'react-geocode';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  Input,
  ActionButton,
  Uploader,
  TextEditor,
  TextEditorLine,
  ModalComponent,
  TabPanel,
  BackButton,
} from '../../../components'
import { DisplayListRoom } from './component'
import stage from '../../../config'
import { CssGrid, CssCheckbox, AntTabs } from '../../../configCSS'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'

const {
  Edit,
} = tableIcons

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage
const locationFormatRegExp = new RegExp(' ', 'g')

class Accomodation extends PureComponent {
  static propTypes = {
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    GET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    GET_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_ROOM_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_ACCOMODATION_TYPE_LIST: PropTypes.func.isRequired,
    GET_ROOM_BY_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_ROOM_BY_ACCOMODATION: PropTypes.func.isRequired,
    GET_SEASON_BY_ACCOMODATION: PropTypes.func.isRequired,
    PUT_ACCOMODATION_CONTENT: PropTypes.func.isRequired,
    DELETE_ACCOMODATION_CONTENT: PropTypes.func.isRequired,
    PUT_ROOM_CONTENT: PropTypes.func.isRequired,
    RESET_ROOM_CONTENT: PropTypes.func.isRequired,
    PUT_ROOM_BY_ACCOMODATION: PropTypes.func.isRequired,
    PUT_SEASON_BY_ACCOMODATION: PropTypes.func.isRequired,
    PUT_ROOM_PRICE_BY_ACCOMODATION: PropTypes.func.isRequired,
    DELETE_ROOM_BY_ACCOMODATION: PropTypes.func.isRequired,
    RESET_ROOM_BY_ACCOMODATION: PropTypes.func.isRequired,
    RESET_ROOM_PRICE_BY_ACCOMODATION: PropTypes.func.isRequired,
    RESET_SEASON_BY_ACCOMODATION: PropTypes.func.isRequired,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    RESET_ACCOMODATION_CONTENT: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    putAccomodationContent: PropTypes.object,
    deleteAccomodationContent: PropTypes.object,
    putRoomByAccomodation: PropTypes.object,
    putRoomPriceByAccomodation: PropTypes.object,
    putSeasonByAccomodation: PropTypes.object,
    putRoomContent: PropTypes.object,
    deleteRoomByAccomodation: PropTypes.object,
    deleteSeasonByAccomodation: PropTypes.object,
    putLocationContent: PropTypes.object,
    locationList: PropTypes.array,
    accomodationTypeList: PropTypes.array,
    roomByAccomodationList: PropTypes.array,
    roomByAccomodation: PropTypes.array,
    seasonByAccomodation: PropTypes.array,
    history: PropTypes.object.isRequired,
    accomodationList: PropTypes.array,
    roomList: PropTypes.array,
    userAccount: PropTypes.object,
    informationsContent: PropTypes.object,
    tripDetails: PropTypes.array,
    listCircuit: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    informationsContent: {},
    locationList: [],
    accomodationTypeList: [],
    roomByAccomodationList: [],
    roomByAccomodation: [],
    seasonByAccomodation: [],
    accomodationList: [],
    roomList: [],
    putAccomodationContent: {},
    putRoomContent: {},
    putLocationContent: {},
    deleteAccomodationContent: {},
    putRoomByAccomodation: {},
    putRoomPriceByAccomodation: {},
    putSeasonByAccomodation: {},
    deleteRoomByAccomodation: {},
    deleteSeasonByAccomodation: {},
    tripDetails: [],
    listCircuit: [],
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.refreshPage = this.refreshPage.bind(this)
    this.toggleDisplay = this.toggleDisplay.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.displayAutocompleteAccomodation = this.displayAutocompleteAccomodation.bind(this)
    this.displayAutocompleteLocation = this.displayAutocompleteLocation.bind(this)
    this.displayAutocompleteRoom = this.displayAutocompleteRoom.bind(this)
    this.displayAccomodationContenInputs = this.displayAccomodationContenInputs.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayTextEditorAdress = this.displayTextEditorAdress.bind(this)
    this.defineMaxNbPictures = this.defineMaxNbPictures.bind(this)
    this.onChangeAccomodation = this.onChangeAccomodation.bind(this)
    this.filterOptionsAccomodation = this.filterOptionsAccomodation.bind(this)
    this.getOptionLabelAccomodation = this.getOptionLabelAccomodation.bind(this)
    this.onChangeLocation = this.onChangeLocation.bind(this)
    this.filterOptionsLocation = this.filterOptionsLocation.bind(this)
    this.getOptionLabelLocation = this.getOptionLabelLocation.bind(this)
    this.onChangeRoom = this.onChangeRoom.bind(this)
    this.filterOptionsRoom = this.filterOptionsRoom.bind(this)
    this.getOptionLabelRoom = this.getOptionLabelRoom.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckChange = this.handleCheckChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.addSeasonLine = this.addSeasonLine.bind(this)
    this.deleteDayLine = this.deleteDayLine.bind(this)
    this.validate = this.validate.bind(this)
    this.saveRoomType = this.saveRoomType.bind(this)
    this.saveSeason = this.saveSeason.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItemTitle = this.modifyItemTitle.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.changeAccoRoom = this.changeAccoRoom.bind(this)
    this.changeAccoSeason = this.changeAccoSeason.bind(this)
    this.getModifiedItem = this.getModifiedItem.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      accomodationList: [],
      restrictedList: [],
      accomodationTypeList: [],
      switchDisplayEdit: false,
      roomByAccomodationList: [],
      roomByAccomodation: [],
      seasonByAccomodation: [],
      initialSeasonByAccomodation: [],
      roomTypeList: [],
      accomodationContent: {},
      currentIddAccomodation: null,
      isValidated: true,
      isNewAccomodation: false,
      goBack: '',
      itemsGoBack: [],
      idDayLineModified: null,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      newImageArray: [],
      newURLArray: [],
      actionLabel: 'Create',
      btnActionDisabled: false,
      isModalOpen: false,
      sourcePage: 'accomodation-table',
      keyTab: 0,
    }
  }

  componentDidMount() {
    const {
      userId,
    } = this.state
    const {
      history,
      listCircuit,
      accomodationList,
      locationList,
      roomList,
      accomodationTypeList,
      roomByAccomodationList,
      userAccount,
      informationsContent,
      GET_USER_ACCOUNT,
      GET_INFORMATIONS_CONTENT,
      GET_ACCOMODATION_LIST,
      GET_LOCATION_LIST,
      GET_ROOM_LIST,
      GET_ACCOMODATION_TYPE_LIST,
      GET_ROOM_BY_ACCOMODATION_LIST,
      GET_ROOM_BY_ACCOMODATION,
      GET_SEASON_BY_ACCOMODATION,
      RESET_SEASON_BY_ACCOMODATION,
      RESET_ROOM_BY_ACCOMODATION,
    } = this.props

    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(listCircuit)) {
      history.push('/trip')
    }
    // check userAccount
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
    // check general informations
    if (_.isEmpty(informationsContent)) {
      GET_INFORMATIONS_CONTENT(userId)
    }
    // When this page is activated from an other page, get allinformations
    if (history.location.state) {
      // access from accomodation table for editing mode
      if (history.location.state.source === 'accomodation-table') {
        const {
          idAccomodation,
        } = history.location.state

        if (idAccomodation) {
          const accomodationToEdit = accomodationList.filter(d => d.id_hebergement === idAccomodation)[0]
          this.setState({
            accomodationContent: accomodationToEdit,
            roomTypeList: !_.isEmpty(accomodationToEdit) ? roomByAccomodationList.filter(rba => rba.id_hebergement === accomodationToEdit.id_hebergement).map(r => r.type_chambre) : [],
            actionLabel: 'Modify',
          })
          if (accomodationToEdit && accomodationToEdit.id_hebergement) {
            GET_ROOM_BY_ACCOMODATION(userId, accomodationToEdit.id_hebergement)
            GET_SEASON_BY_ACCOMODATION(userId, accomodationToEdit.id_hebergement)
          }
        }
      } else {
        RESET_ROOM_BY_ACCOMODATION()
        RESET_SEASON_BY_ACCOMODATION()
        this.setState({
          roomByAccomodation: [],
          seasonByAccomodation: [],
        });
      }
      // request come from 'daysList'
      if (history.location.state.goBack === 'daysList') {
        const {
          state,
          items,
          goBack,
        } = history.location.state
        const {
          idDayLineModified,
          end,
          id_end,
          field,
          value,
          id_accomodation,
        } = state
        if (!_.isEmpty(id_accomodation)) {
          this.setState({
            accomodationContent: {
              field,
              [field]: value,
              nom_hebergement: value,
              location: end,
              id_location: id_end,
            },
            itemsGoBack: items,
            goBack,
            idDayLineModified,
            isNewAccomodation: true,
          })
        } else {
          const accomodationToEdit = accomodationList.filter(d => d.id_hebergement === id_accomodation)[0]
          this.setState({
            accomodationContent: accomodationToEdit,
            roomTypeList: !_.isEmpty(accomodationToEdit) ? roomByAccomodationList.filter(rba => rba.id_hebergement === accomodationToEdit.id_hebergement).map(r => r.type_chambre) : [],
            actionLabel: 'Modify',
            itemsGoBack: items,
            goBack,
            idDayLineModified,
          })
          if (accomodationToEdit && accomodationToEdit.id_hebergement) {
            GET_ROOM_BY_ACCOMODATION(userId, accomodationToEdit.id_hebergement)
            GET_SEASON_BY_ACCOMODATION(userId, accomodationToEdit.id_hebergement)
          }
        }
      }
      // come back from 'Gallery'
      if (history.location.state.currentPage === 'accomodation') {
        const {
          state,
          currentImg,
          pictureSelected,
          itemsGoBack,
          goBack,
          idDayLineModified,
        } = history.location.state
        this.setState({
          accomodationContent: {
            ...state.item,
            image: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          roomTypeList: state.roomTypeList,
          isNewAccomodation: state.isNewAccomodation,
          actionLabel: state.actionLabel,
          itemsGoBack,
          goBack,
          idDayLineModified,
        })
      }
    }
    if (!_.isEmpty(accomodationList)) {
      this.setState({
        accomodationList: _.sortBy(accomodationList, a => a.nom_hebergement),
      })
    } else {
      GET_ACCOMODATION_LIST(userId)
    }
    if (_.isEmpty(roomList)) {
      GET_ROOM_LIST(userId)
    }

    if (!_.isEmpty(accomodationTypeList)) {
      this.setState({ accomodationTypeList })
    } else {
      GET_ACCOMODATION_TYPE_LIST(userId)
    }
    if (!_.isEmpty(roomByAccomodationList)) {
      this.setState({ roomByAccomodationList })
    } else {
      GET_ROOM_BY_ACCOMODATION_LIST(userId)
    }

    if (_.isEmpty(locationList)) {
      GET_LOCATION_LIST(userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      userId,
      accomodationList: accomodationListOld,
      roomByAccomodationList: roomByAccomodationListOld,
      roomByAccomodation: roomByAccomodationOld,
      seasonByAccomodation: seasonByAccomodationOld,
      accomodationContent,
      btnActionDisabled,
      // goBack,
      // idDayLineModified,
      // itemsGoBack,
    } = this.state
    const {
      accomodationTypeList: accomodationTypeListOld,
    } = this.props
    const {
      // history,
      accomodationList,
      accomodationTypeList,
      roomByAccomodationList,
      roomByAccomodation,
      seasonByAccomodation,
      putLocationContent,
      putAccomodationContent,
      putRoomContent,
      deleteAccomodationContent,
      putRoomByAccomodation,
      putRoomPriceByAccomodation,
      putSeasonByAccomodation,
      deleteRoomByAccomodation,
      deleteSeasonByAccomodation,
      RESET_LOCATION_CONTENT,
      GET_LOCATION_LIST,
      RESET_ACCOMODATION_CONTENT,
      GET_ROOM_LIST,
      RESET_ROOM_CONTENT,
      GET_SEASON_BY_ACCOMODATION,
      GET_ROOM_BY_ACCOMODATION_LIST,
      RESET_ROOM_BY_ACCOMODATION,
      RESET_ROOM_PRICE_BY_ACCOMODATION,
      RESET_SEASON_BY_ACCOMODATION,
      GET_ROOM_BY_ACCOMODATION,
    } = nextProps
    // Accomodation saved in DB -> get back updated list
    if (putAccomodationContent && putAccomodationContent.success) {
      // let waitEndCreation = false
      const idAccomodationCreated = putAccomodationContent.id
      if (putAccomodationContent.isNew) {
        // waitEndCreation = true
        RESET_ACCOMODATION_CONTENT()
        this.setState({
          accomodationContent: {
            ...accomodationContent,
            id_hebergement: idAccomodationCreated,
          },
          validMsg: 'the accomodation was successfully recorded !',
          isValidated: true,
          isNewAccomodation: false,
          actionLabel: 'Modify',
          currentIddAccomodation: idAccomodationCreated,
        }, () => this.saveRoomType(idAccomodationCreated))
        // this.saveSeason()
      } else {
        this.refreshPage()
      }
    }
    // accomodation delete in DB -> get back updated list
    if (deleteAccomodationContent && deleteAccomodationContent.success) {
      this.reload()
      this.setState({
        accomodationContent: {},
        roomTypeList: [],
        isValidated: true,
        validMsg: 'the accomodation was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // accomodation can not be delete in DB -> it is used yet in route
    if (deleteAccomodationContent && !deleteAccomodationContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteAccomodationContent.message,
      })
      RESET_ACCOMODATION_CONTENT()
      RESET_SEASON_BY_ACCOMODATION()
      RESET_ROOM_BY_ACCOMODATION()
    }
    // location saved in DB -> get back updated list
    if (putLocationContent && putLocationContent.success) {
      RESET_LOCATION_CONTENT()
      GET_LOCATION_LIST(userId)
      this.setState({
        accomodationContent: {
          ...accomodationContent,
          id_location: putLocationContent.id,
        },
        btnActionDisabled: !btnActionDisabled,
        validMsg: 'Location was successfully recorded !',
        isValidated: false,
      })
      this.resetValidMsg()
    }
    // control created room result and get back updated list

    if (putRoomContent && putRoomContent.success) {
      RESET_ROOM_CONTENT()
      GET_ROOM_LIST(userId)
    }
    // update room by accomodation once price change
    if (putRoomPriceByAccomodation && putRoomPriceByAccomodation.success) {
      RESET_ROOM_PRICE_BY_ACCOMODATION()
      GET_ROOM_BY_ACCOMODATION(userId, accomodationContent.id_hebergement)
    }
    // add or delete some season for accomodation get back updated list
    if ((putSeasonByAccomodation && putSeasonByAccomodation.success) || (deleteSeasonByAccomodation && deleteSeasonByAccomodation.success)) {
      RESET_SEASON_BY_ACCOMODATION()
      if (accomodationContent) GET_SEASON_BY_ACCOMODATION(userId, accomodationContent.id_hebergement)
    }
    // add or delete some rooms for accomodation get back updated list
    if ((putRoomByAccomodation && putRoomByAccomodation.success) || (deleteRoomByAccomodation && deleteRoomByAccomodation.success)) {
      RESET_ROOM_BY_ACCOMODATION()
      GET_ROOM_BY_ACCOMODATION_LIST(userId)
      this.refreshPage()
    }

    if (accomodationList !== accomodationListOld) {
      this.setState({
        accomodationList: _.sortBy(accomodationList, a => a.nom_hebergement),
      })
    }
    if (accomodationTypeList !== accomodationTypeListOld) this.setState({ accomodationTypeList })
    if (roomByAccomodationList !== roomByAccomodationListOld) this.setState({ roomByAccomodationList })
    if (roomByAccomodation !== roomByAccomodationOld) {
      this.setState({ roomByAccomodation })
    }

    if (seasonByAccomodation !== seasonByAccomodationOld) {
      this.setState({ seasonByAccomodation })
    }
    this.setState({ initialSeasonByAccomodation: seasonByAccomodation })
  }

  toggleDisplay = (value) => {
    this.setState({
      switchDisplayEdit: value, // Activer le mode d'édition
    })
  }

  refreshPage = () => {
    this.setState({
      validMsg: 'the accomodation was successfully recorded !',
      isValidated: true,
    }, () => {
      this.reload()
      this.setState({ validateError: false })
      this.resetValidMsg()
    })
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 2000)
  }

  reload = () => {
    const {
      userId,
      goBack,
    } = this.state
    const {
      RESET_ACCOMODATION_CONTENT,
      GET_ACCOMODATION_LIST,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
    } = this.props
    RESET_ACCOMODATION_CONTENT()
    if (!goBack) {
      ROW_TRIP_SELECT([])
      RESET_TRIP_INFORMATION()
    }
    GET_ACCOMODATION_LIST(userId)
  }

  // ********************************
  // On change accomodation
  onChangeAccomodation = (event, newValue) => {
    const {
      isValidated,
      accomodationList,
      roomByAccomodationList,
      roomByAccomodation,
      seasonByAccomodation,
      userId,
    } = this.state
    const {
      GET_ROOM_BY_ACCOMODATION,
      GET_SEASON_BY_ACCOMODATION,
      RESET_ROOM_BY_ACCOMODATION,
      RESET_SEASON_BY_ACCOMODATION,
    } = this.props
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }

    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = accomodationList.filter(d => d.nom_hebergement === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          accomodationContent: {
            id_hebergement: null,
            nom_hebergement: newValue,
            image: '',
          },
          actionLabel: 'Create',
          isNewAccomodation: true,
          newImageArray: [],
          newURLArray: [],
          isValidated: false,
          restrictedList: [],
          roomByAccomodation: [],
          roomTypeList: [],
        });
      } else {
        const accomodationContentSelected = valueExist[0]
        if (accomodationContentSelected) {
          if (accomodationContentSelected.id_hebergement) {
            GET_ROOM_BY_ACCOMODATION(userId, accomodationContentSelected.id_hebergement)
            GET_SEASON_BY_ACCOMODATION(userId, accomodationContentSelected.id_hebergement)
          }
        }
        this.setState({
          accomodationContent: newValue,
          roomByAccomodation: roomByAccomodation,
          seasonByAccomodation: seasonByAccomodation,
          actionLabel: 'Modify',
          newImageArray: [],
          newURLArray: [],
          isNewAccomodation: false,
          isValidated: true,
          restrictedList: [],
          roomTypeList: !_.isEmpty(accomodationContentSelected) ? roomByAccomodationList.filter(rba => rba.id_hebergement === accomodationContentSelected.id_hebergement).map(r => r.type_chambre) : [],
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        accomodationContent: {
          id_hebergement: null,
          nom_hebergement: newValue.inputValue,
          image: '',
        },
        newImageArray: [],
        newURLArray: [],
        actionLabel: 'Create',
        isNewAccomodation: true,
        isValidated: false,
        restrictedList: [],
        roomByAccomodation: [],
        seasonByAccomodation: [],
        roomTypeList: [],
      });
    } else {
      const accomodationContentSelected = newValue
      if (accomodationContentSelected) {
        if (accomodationContentSelected.id_hebergement) {
          GET_ROOM_BY_ACCOMODATION(userId, accomodationContentSelected.id_hebergement)
          GET_SEASON_BY_ACCOMODATION(userId, accomodationContentSelected.id_hebergement)
        }
      } else {
        RESET_ROOM_BY_ACCOMODATION()
        RESET_SEASON_BY_ACCOMODATION()
        this.setState({
          roomByAccomodation: [],
          seasonByAccomodation: [],
        });
      }

      this.setState({
        accomodationContent: newValue,
        roomByAccomodation: roomByAccomodation,
        seasonByAccomodation: seasonByAccomodation,
        actionLabel: 'Modify',
        newImageArray: [],
        newURLArray: [],
        isNewAccomodation: false,
        isValidated: true,
        restrictedList: [],
        roomTypeList: !_.isEmpty(accomodationContentSelected) ? roomByAccomodationList.filter(rba => rba.id_hebergement === accomodationContentSelected.id_hebergement).map(r => r.type_chambre) : [],
      });
    }
    return null
  }

  filterOptionsAccomodation = (options, params) => {
    const { accomodationList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = accomodationList.filter(d => d.nom_hebergement === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        nom_hebergement: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelAccomodation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.nom_hebergement) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.nom_hebergement;
  }

  // On change location
  onChangeLocation = (event, newValue) => {
    const { accomodationContent } = this.state
    const {
      locationList,
      accomodationList,
    } = this.props
    const isAccomodationEmpty = accomodationContent ? !accomodationContent.nom_hebergement : true
    // on press Enter
    if (typeof newValue === 'string') {
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const locationObject = locationList.filter(l => l.location === valueClean)[0]
      const idLocation = locationObject && locationObject.id_lieu ? locationObject.id_lieu : null

      if (!idLocation) this.createNewLocation({ lieu: valueClean })

      if (_.isEmpty(locationObject)) {
        this.setState({
          accomodationContent: {
            ...accomodationContent,
            id_location: idLocation,
            location: valueClean,
          },
          isValidated: isAccomodationEmpty,
          // isNewLocation: true,
          restrictedList: isAccomodationEmpty ? accomodationList.filter(e => e.location === valueClean) : null,
        });
      } else {
        this.setState({
          accomodationContent: {
            ...accomodationContent,
            id_location: locationObject.id_lieu,
            location: locationObject.lieu,
          },
          restrictedList: isAccomodationEmpty ? _.filter(accomodationList, e => e.location === locationObject.lieu) : null,
          // isNewLocation: false,
          isValidated: isAccomodationEmpty,
        })
      }
    } else if (!newValue) {
      // clear input location
      this.setState({
        accomodationContent: {
          ...accomodationContent,
          id_location: null,
          location: '',
        },
        restrictedList: null,
        // isNewLocation: false,
        isValidated: isAccomodationEmpty,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        accomodationContent: {
          ...accomodationContent,
          id_location: null,
          location: newValue.inputValue,
        },
        restrictedList: isAccomodationEmpty ? accomodationList.filter(e => e.location === newValue.inputValue) : null,
        // isNewLocation: true,
        isValidated: isAccomodationEmpty,
      });
      // save new location
      this.createNewLocation({ lieu: newValue.inputValue })
    } else {
      this.setState({
        accomodationContent: {
          ...accomodationContent,
          id_location: newValue.id_lieu,
          location: newValue.lieu,
        },
        restrictedList: isAccomodationEmpty ? _.filter(accomodationList, e => e.location === newValue.lieu) : null,
        // isNewLocation: false,
        isValidated: isAccomodationEmpty,
      })
    }
  }

  filterOptionsLocation = (options, params) => {
    const { locationList } = this.props
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(d => d.lieu === valueClean)
    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }
    return filtered;
  }

  getOptionLabelLocation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.location) {
      return ''
    }
    // Regular option
    return option.location;
  }

  // on change room type
  // On change room
  onChangeRoom = (event, newValue, reason) => {
    const {
      token,
      roomTypeList,
      roomByAccomodation,
    } = this.state
    const {
      roomList,
      PUT_ROOM_CONTENT,
    } = this.props

    const roomDiff = _.difference(roomTypeList.map(f => f), newValue, _.isEqual)
    const index = roomByAccomodation.findIndex(element => element.type_chambre === `${roomDiff}`);

    // newValue is an array of string representative the rooms selected
    // It contains an object representative the room added
    // if a room is deleted, the input return an array of string without the room deleted
    if (_.isEmpty(newValue)) {
      // room input is empty now
      this.setState({
        roomTypeList: [],
        roomByAccomodation: [],
        isValidated: false,
      })
    } else if (reason === 'remove-option') {
      // a room has been deleted
      roomByAccomodation.splice(index, 1)
      this.setState({
        roomTypeList: newValue,
        isValidated: false,
        roomByAccomodation,
      })
    } else if (newValue && newValue[newValue.length - 1].inputValue) {
      // Create a new value from the user input

      const value = _.capitalize(newValue[newValue.length - 1].inputValue)
      if (!roomList.includes(value)) {
        this.setState({
          roomTypeList: [
            ...roomTypeList,
            value,
          ],
          // roomByAccomodation,
          isValidated: false,
        })
        PUT_ROOM_CONTENT(token, { type_chambre: value }, 'create')
      }
    } else {
      // when a room is added
      const newTypeChambre = _.capitalize(newValue[newValue.length - 1].type_chambre)
      if (newTypeChambre && !newValue.includes(newTypeChambre)) {
        this.setState({
          roomTypeList: [
            ...roomTypeList,
            newTypeChambre,
          ],
          // roomByAccomodation,
          isValidated: false,
        })
      }
    }
    return null
  }

  filterOptionsRoom = list => (options, params) => {
    const filtered = _.filter(list, d => d.type_chambre.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
    const valueExist = _.filter(list, d => d.type_chambre.toLowerCase() === params.inputValue.toLowerCase())

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        type_chambre: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelRoom = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.room) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.room;
  }

  // save all other (other than title) new informations for this accomodation
  handleChange = field => (event) => {
    const { accomodationContent } = this.state
    this.setState({
      accomodationContent: {
        ...accomodationContent,
        [field]: event.target.value,
      },
      validateError: false,
      errorMsg: null,
      isValidated: false,
    })
  }

  handleCheckChange = field => (event) => {
    const { accomodationContent } = this.state
    this.setState({
      accomodationContent: {
        ...accomodationContent,
        [field]: event.target.checked,
      },
      validateError: false,
      errorMsg: null,
      isValidated: false,
    })
  }

  // TABS
   onChangeTab = (event, keyTab) => {
     this.setState({
       keyTab,
     })
   }

  tabProps = index => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  // On change Editor
  handleEditorChange = (field, content) => {
    const {
      accomodationContent,
    } = this.state
    if (accomodationContent && content !== accomodationContent[field]) {
      this.setState({
        accomodationContent: {
          ...accomodationContent,
          [field]: content,
        },
        validateError: false,
        errorMsg: null,
        isValidated: false,
      });
    }
  }

  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = () => {
    const { userAccount } = this.props
    const { company } = userAccount
    const {
      accomodationContent,
      newURLArray,
      newImageArray,
    } = this.state
    const image = accomodationContent && accomodationContent.image ? accomodationContent.image : null

    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
      validateError: false,
      errorMsg: null,
      isValidated: false,
    });
  }

  deleteImage = (urlArray, imageArray) => {
    const { accomodationContent } = this.state
    this.setState({
      accomodationContent: {
        ...accomodationContent,
        image: imageArray.join(),
      },
      newImageArray: imageArray,
      newURLArray: urlArray,
      isValidated: false,
    })
  }

  redirectToGallery = () => {
    const {
      accomodationContent,
      roomTypeList,
      isNewAccomodation,
      actionLabel,
      itemsGoBack,
      goBack,
      idDayLineModified,
    } = this.state
    const {
      history,
    } = this.props
    // this.validate()
    this.setState({
      isValidated: true,
    }, () => {
      history.push('gallery', {
        currentPage: 'accomodation',
        isSelectionAction: true,
        nbMaxImg: 3,
        currentImg: accomodationContent.image ? accomodationContent.image.split(',') : [],
        state: {
          item: accomodationContent,
          roomTypeList,
          isNewAccomodation,
          actionLabel,
        },
        itemsGoBack: itemsGoBack || null,
        goBack: goBack || null,
        idDayLineModified: idDayLineModified || null,
      })
    })
  }

  // ***************************
  // Display input

  defineMaxNbPictures = () => 3

  displayAutocompleteAccomodation = (field, content, list) => {
    const {
      validateError,
    } = this.state
    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item sm={10} xs={12}>
          <Autocomplete
            id={`${field}-field`}
            value={content}
            onChange={this.onChangeAccomodation}
            filterOptions={this.filterOptionsAccomodation}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelAccomodation}
            renderOption={option => option.nom_hebergement}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css" onClick={this.modifyItemTitle}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  displayAutocompleteLocation = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <Autocomplete
        id={`${field}-field`}
        value={content}
        onChange={this.onChangeLocation}
        filterOptions={this.filterOptionsLocation}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={list}
        getOptionLabel={this.getOptionLabelLocation}
        renderOption={option => option.lieu}
        freeSolo
        renderInput={params => (
          <Input
            {...params}
            label={_.capitalize(field)}
            margin="normal"
            placeholder={`Select a ${field} or create a new one`}
            variant="outlined"
            required
            error={validateError ? params.inputProps.value === '' : false}
          />
        )}
      />
    );
  }

  displayAutocompleteRoom = (field, content, list) => {
    const {
      roomTypeList,
    } = this.state
    return (
      <Autocomplete
        multiple
        id={`${field}-field-${content.id}`}
        options={list.map(option => option.type_chambre)}
        value={roomTypeList}
        onChange={this.onChangeRoom}
        filterOptions={this.filterOptionsRoom(list)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={this.getOptionLabelRoom}
        renderOption={option => option.type_chambre}
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            variant="outlined"
            label="Room & hotel services"
            margin="normal"
            className="largeWidth"
          />
        )}
      />
    )
  }

  // Editor
  displayTextEditor = () => {
    const {
      accomodationContent,
      switchDisplayEdit,
    } = this.state
    const {
      userAccount,
    } = this.props
    const description = userAccount.language ? `${userAccount.language}_desc_hebergement` : 'fr_desc_hebergement'
    const accomodationObject = typeof accomodationContent === 'string' ? {} : accomodationContent
    const descriptionValue = accomodationContent && accomodationContent[description] ? accomodationContent[description] : ''
    return (
      switchDisplayEdit ? (
        <div>
          <TextEditor
            object={accomodationObject}
            value={descriptionValue}
            handleEditorChange={content => this.handleEditorChange(description, content)}
          />
          <Edit
            onClick={() => this.toggleDisplay(false)}
            className="icons"
          />
        </div>
      ) : (
        <div onClick={() => this.toggleDisplay('edit1', true)}>
          <Interweave content={descriptionValue} />
          <Edit
            onClick={() => this.toggleDisplay('edit1', true)}
            className="icons"
          />
        </div>
      )
    )
  }

  displayTextEditorAdress = () => {
    const {
      accomodationContent,
    } = this.state
    const accomodationObject = typeof accomodationContent === 'string' ? {} : accomodationContent
    const descriptionValue = accomodationContent && accomodationContent.adresse ? accomodationContent.adresse : ''
    return (
      <TextEditorLine
        object={accomodationObject}
        value={descriptionValue}
        handleEditorChange={content => this.handleEditorChange('adresse', content)}
      />
    )
  }

  goBack = () => {
    const { history } = this.props
    const {
      sourcePage,
      goBack,
      idDayLineModified,
      currentIddAccomodation,
      itemsGoBack,
    } = this.state
    if (goBack) {
      history.push(goBack, {
        itemsGoBack,
        idDayLineModified,
        idItemCreated: currentIddAccomodation,
        field: 'accomodation',
      })
    } else if (sourcePage) history.push(sourcePage)
  }

  // return all other input
  displayAccomodationContenInputs = () => {
    const {
      accomodationContent,
      accomodationTypeList,
      roomByAccomodation,
    } = this.state
    const {
      userAccount,
      locationList,
      roomList,
    } = this.props
    const category = userAccount.language ? `${userAccount.language}_categorie` : 'fr_categorie'
    return (
      <div>
        <div className="editorStyle">
          <div className="editorTitle background-white">Description</div>
          {this.displayTextEditor()}
        </div>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={9} xs={12}>
            {this.displayAutocompleteLocation('location', accomodationContent, locationList)}
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={3} xs={12}>

                <Input
                  id="currency"
                  label="currency"
                  select
                  margin="normal"
                  variant="outlined"
                  value={!_.isEmpty(accomodationContent) && accomodationContent.currency ? accomodationContent.currency : ''}
                  className="largeWidth"
                  onChange={this.handleChange('currency')}
                >
                  <MenuItem value="$">$</MenuItem>
                  <MenuItem value="€">€</MenuItem>
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="THB">THB</MenuItem>
                  <MenuItem value="CNY">CNY</MenuItem>
                  <MenuItem value="">none</MenuItem>
                </Input>
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="email"
                  label="Email"
                  value={!_.isEmpty(accomodationContent) && accomodationContent.email ? accomodationContent.email : ''}
                  onChange={this.handleChange('email')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="phone"
                  label="Phone"
                  value={!_.isEmpty(accomodationContent) && accomodationContent.phone ? accomodationContent.phone : ''}
                  onChange={this.handleChange('phone')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  id="date_expiration_price"
                  label="expiration price date"
                  type="date"
                  value={!_.isEmpty(accomodationContent) && accomodationContent.date_expiration_price ? moment(accomodationContent.date_expiration_price).format('YYYY-MM-DD') : ''}
                  onChange={this.handleChange('date_expiration_price')}
                  margin="normal"
                  variant="outlined"
                  width="100%"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </CssGrid>
            </CssGrid>

            <div>
              <div className="editorStyle">
                <div className="editorTitle background-white">Adresse</div>
                {this.displayTextEditorAdress()}
              </div>
              <CssGrid
                container
                spacing={2}
              >
                <CssGrid item md={6} xs={12}>
                  <Input
                    id="Latitude"
                    label="Latitude"
                    value={!_.isEmpty(accomodationContent) && accomodationContent.latitude ? accomodationContent.latitude : ''}
                    onChange={this.handleChange('latitude')}
                    margin="normal"
                    type="number"
                    variant="outlined"
                    className="largeWidth"
                  />
                </CssGrid>
                <CssGrid item md={6} xs={12}>
                  <Input
                    id="lomgitude"
                    label="longitude"
                    value={!_.isEmpty(accomodationContent) && accomodationContent.longitude ? accomodationContent.longitude : ''}
                    onChange={this.handleChange('longitude')}
                    margin="normal"
                    type="number"
                    variant="outlined"
                    className="largeWidth"
                  />
                </CssGrid>
              </CssGrid>
              <Input
                id="type"
                select
                label="Type"
                value={!_.isEmpty(accomodationContent) && accomodationContent.type ? accomodationContent.type : ''}
                onChange={this.handleChange('type')}
                margin="normal"
                variant="outlined"
                className="largeWidth"
              >
                {accomodationTypeList.map(option => (
                  <MenuItem key={option.type_hebergement} value={option.type_hebergement}>
                    {option.type_hebergement}
                  </MenuItem>
                ))}
              </Input>
              <Input
                id="category"
                label="Category"
                value={!_.isEmpty(accomodationContent) && accomodationContent[category] ? accomodationContent[category] : ''}
                onChange={this.handleChange(category)}
                margin="normal"
                variant="outlined"
                className="largeWidth"
              />
            </div>


            {/* {this.displayRoomList()} */}
            {this.displayAutocompleteRoom('room', roomByAccomodation, roomList)}
            <FormControlLabel
              control={(
                <CssCheckbox
                  checked={accomodationContent ? !!accomodationContent.universel : false}
                  onChange={this.handleCheckChange('universel')}
                  name="universel"
                />
              )}
              label="Universel"
            />
          </CssGrid>
          <CssGrid item md={3} xs={12}>
            <Uploader
              imageDataArray={this.getImageUrl()}
              saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
              deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
              redirectToGallery={() => this.redirectToGallery()}
              allowDrop={!!(accomodationContent && accomodationContent.nom_hebergement)}
              nbMaxImg={this.defineMaxNbPictures()}
            />
          </CssGrid>
        </CssGrid>
      </div>
    )
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      accomodationContent,
    } = this.state
    const accomodationId = accomodationContent.id_hebergement
    const { DELETE_ACCOMODATION_CONTENT } = this.props

    if (confirm('Do you really want to delete this accomodation ?')) {
      if (accomodationId) {
        DELETE_ACCOMODATION_CONTENT(token, accomodationId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: accomodationContent.nom_hebergement ? 'This accomodation do not exist.' : 'Please, select an accomodation.',
        })
      }
    }
  }

  // Modify title
  modifyItemTitle = () => {
    const { accomodationContent } = this.state
    if (!accomodationContent || !accomodationContent.id_hebergement) return null
    this.setState({
      isNewAccomodation: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  // Save Room type
  saveRoomType = (idHebergement) => {
    const {
      token,
      roomTypeList,
      roomByAccomodationList,
      roomByAccomodation,
    } = this.state
    const {
      PUT_ROOM_BY_ACCOMODATION,
      DELETE_ROOM_BY_ACCOMODATION,
      PUT_ROOM_PRICE_BY_ACCOMODATION,
      roomList,
    } = this.props
    const initialroomByAccomodation = _.filter(roomByAccomodationList, r => r.id_hebergement === idHebergement)
    const roomAdded = _.difference(roomTypeList, initialroomByAccomodation.map(f => f.type_chambre), _.isEqual)
    const roomDeleted = _.difference(initialroomByAccomodation.map(f => f.type_chambre), roomTypeList, _.isEqual)
    const roomAddedToSave = _.flatten(roomAdded.map(rA => _.filter(roomList, r => r.type_chambre === rA)))
    const roomDeletedToSave = _.flatten(roomDeleted.map(rD => _.filter(initialroomByAccomodation, r => r.type_chambre === rD)))
    // refresh page if no room to add or to delete

    if (_.isEmpty(roomAddedToSave) && _.isEmpty(roomDeletedToSave)) {
      PUT_ROOM_PRICE_BY_ACCOMODATION(token, roomByAccomodation)
      this.refreshPage()
      return null
    }

    // Add room
    roomAddedToSave.map((rAd) => {
      const newRoomByAccomodation = {
        ...rAd,
        id_hebergement: idHebergement,
      }
      // write changes in DB
      PUT_ROOM_BY_ACCOMODATION(token, newRoomByAccomodation, 'create')
      return null
    })
    // Delete room
    roomDeletedToSave.map(rDe => DELETE_ROOM_BY_ACCOMODATION(token, rDe.id_heb_ch))
    PUT_ROOM_PRICE_BY_ACCOMODATION(token, roomByAccomodation)

    return null
  }

   // Save Room type
   saveSeason = () => {
     const {
       token,
       seasonByAccomodation,
       initialSeasonByAccomodation,
     } = this.state
     const {
       PUT_SEASON_BY_ACCOMODATION,
     } = this.props

     const filteredSeasonByAccomodation = seasonByAccomodation.filter(season => initialSeasonByAccomodation.some(initialSeason => initialSeason.id_heb_sea === season.id_heb_sea));

     // calculate difference of seasons
     const SeasonDifftoAdd = _.differenceBy(seasonByAccomodation, initialSeasonByAccomodation, 'id_heb_sea')
     const SeasonDifftoDelete = _.differenceBy(initialSeasonByAccomodation, seasonByAccomodation, 'id_heb_sea')
     const SeasonDifftoupdate = _.difference(filteredSeasonByAccomodation, initialSeasonByAccomodation, _.isEqual)

     if (!_.isEmpty(seasonByAccomodation) && _.isEmpty(initialSeasonByAccomodation)) {
       PUT_SEASON_BY_ACCOMODATION(token, seasonByAccomodation, '', '', '', 'create')
     } else if (!_.isEmpty(SeasonDifftoupdate) || !_.isEmpty(SeasonDifftoDelete) || !_.isEmpty(SeasonDifftoAdd)) {
       PUT_SEASON_BY_ACCOMODATION(token, '', SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, 'update')
     }

     // write changes in DB
   }

  validate = () => {
    const {
      accomodationContent,
      isNewAccomodation,
      newImageArray,
      token,
    } = this.state
    const {
      PUT_ACCOMODATION_CONTENT,
    } = this.props

    this.setState({
      isValidated: true,
    })

    // Check all required inputs
    if (!accomodationContent.nom_hebergement || (!accomodationContent.location && !accomodationContent.universel)) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    const newAccomodationContent = {
      ...accomodationContent,
      type: accomodationContent.type ? accomodationContent.type : '',
      currency: accomodationContent.currency ? accomodationContent.currency : '',
      adresse: accomodationContent.adresse ? accomodationContent.adresse : '',
      categorie: accomodationContent.categorie ? accomodationContent.categorie : '',
      ville: accomodationContent.id_location,
      image: newImageArray.join() || accomodationContent.image || '',
    }
    // Save informations
    if (isNewAccomodation) {
      PUT_ACCOMODATION_CONTENT(token, newAccomodationContent, 'create')
    } else {
      // Save accomodation room type
      this.saveRoomType(accomodationContent.id_hebergement)
      this.saveSeason()
      // Save accomodation others infos
      PUT_ACCOMODATION_CONTENT(token, newAccomodationContent, 'update')
    }

    return null
  }

  createNewLocation = (locationContent) => {
    Geocode.fromAddress(locationContent.lieu).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, locationContent);
      },
      (error) => {
        console.error(error);
        alert('cannot find GPS information for this location, please contact your administrator to create it manually.')
      },
    )
  }


  // create a new location


  createNewLocation_withGeoLoc = (response, locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu = locationContent.lieu
    locationDetails.geocode = response.results[0].geometry.location

    const { PUT_LOCATION_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }


  // **************************
  // Modal action

  getModifiedItem = (item) => {
    const { accomodationContent } = this.state
    this.setState({
      accomodationContent: {
        ...accomodationContent,
        nom_hebergement: item.accomodation,
      },
    }, () => {
      this.handleClose()
      this.validate()
    })
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
    });
    setTimeout(() => {
      this.setState({
        isValidated: false,
      })
    }, 300);
  };

  changeAccoRoom = (day) => {
    const { roomByAccomodation } = this.state
    this.setState({
      roomByAccomodation: roomByAccomodation.map(t => (t.id_heb_ch === day.id_heb_ch ? Object.assign({}, t, day) : t)),
    })
  }

  changeAccoSeason = (day) => {
    const { seasonByAccomodation } = this.state
    this.setState({
      seasonByAccomodation: seasonByAccomodation.map(t => (t.id_heb_sea === day.id_heb_sea ? Object.assign({}, t, day) : t)),
    })
  }

  // function add a new season period
  addSeasonLine = (e) => {
    const { seasonByAccomodation } = this.state
    const newSeason = {
      id_heb_sea: 1,
      id_hebergement: e,
      period_name: '',
      start_date: '',
      end_date: '',
    }
    if (!e) {
      alert('Please first save the accomodation')
    } else if (!_.isEmpty(seasonByAccomodation)) {
      const oneSeasonMore = _.clone(seasonByAccomodation)
      const nombreSaison = oneSeasonMore.length
      const today = seasonByAccomodation[nombreSaison - 1].end_date
      oneSeasonMore.push({
        ...newSeason,
        start_date: moment(today).add(1, 'days').format('YYYY-MM-DD'),
        id_heb_sea: Math.max(...seasonByAccomodation.map(item => item.id_heb_sea)) + 1,
      })
      this.setState({
        seasonByAccomodation: oneSeasonMore,
      })
    } else {
      newSeason.start_date = '2022-01-01'
      newSeason.end_date = '2022-01-01'
      const oneSeasonMore = _.clone(seasonByAccomodation)
      oneSeasonMore.push(newSeason)
      this.setState({
        seasonByAccomodation: oneSeasonMore,
      })
    }
  }

  deleteDayLine = (e) => {
    const { seasonByAccomodation } = this.state
    const oneSeasonLess = _.clone(seasonByAccomodation).filter(c => c.id_heb_sea !== e)
    setTimeout(() => {
      this.setState({
        seasonByAccomodation: oneSeasonLess,
      })
    }, 400)
  }

  // **********************************
  render() {
    const {
      accomodationContent,
      accomodationList,
      restrictedList,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      actionLabel,
      btnActionDisabled,
      isModalOpen,
      keyTab,
      roomByAccomodation,
      seasonByAccomodation,
    } = this.state

    const selectableAccomodationList = _.isEmpty(restrictedList) ? accomodationList : restrictedList
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>ACCOMODATION MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <BackButton
                type="button"
                label="Back"
                goBack={() => this.goBack()}
              />
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="ACCOMODATION DETAIL" {...this.tabProps(0)} />
                  <Tab label="ROOM DETAIL" {...this.tabProps(1)} />
                </AntTabs>
              </AppBar>
              <TabPanel value={keyTab} index={0}>
                {this.displayAutocompleteAccomodation('accomodation', accomodationContent, selectableAccomodationList)}
                {this.displayAccomodationContenInputs()}
              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                <DisplayListRoom
                  roomByAccomodation={roomByAccomodation}
                  seasonByAccomodation={seasonByAccomodation}
                  changeAccoRoom={day => this.changeAccoRoom(day)}
                  changeAccoSeason={day => this.changeAccoSeason(day)}
                  addSeasonLine={day => this.addSeasonLine(day)}
                  deleteDayLine={day => this.deleteDayLine(day)}
                  accoSelected={accomodationContent && accomodationContent.id_hebergement}
                  accomodationContent={accomodationContent}
                />
              </TabPanel>
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!accomodationContent || btnActionDisabled}
              onClick={() => this.validate()}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the new title for this accomodation"
            modalContent="accomodation"
            warning="This will update this accomodation in all quotes !"
            type="accomodation"
            inputValue={!_.isEmpty(accomodationContent) ? accomodationContent.nom_hebergement : ''}
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  accomodationList: state.api.accomodationList,
  putAccomodationContent: state.api.putAccomodationContent,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  accomodationTypeList: state.api.accomodationTypeList,
  roomList: state.api.roomList,
  roomByAccomodationList: state.api.roomByAccomodationList,
  roomByAccomodation: state.api.roomByAccomodation,
  seasonByAccomodation: state.api.seasonByAccomodation,
  putLocationContent: state.api.putLocationContent,
  deleteAccomodationContent: state.api.deleteAccomodationContent,
  putRoomContent: state.api.putRoomContent,
  putRoomByAccomodation: state.api.putRoomByAccomodation,
  putRoomPriceByAccomodation: state.api.putRoomPriceByAccomodation,
  putSeasonByAccomodation: state.api.putSeasonByAccomodation,
  deleteRoomByAccomodation: state.api.deleteRoomByAccomodation,
  deleteSeasonByAccomodation: state.api.deleteSeasonByAccomodation,
  userAccount: state.userAccountReducer.userAccount,
  informationsContent: state.informationsReducer.informationsContent,
  tripDetails: state.api.tripDetails,
})

const mapDispatchToProps = dispatch => ({
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_INFORMATIONS_CONTENT: userId => dispatch({
    type: 'GET_INFORMATIONS_CONTENT',
    userId,
  }),
  GET_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_LIST',
    userId,
  }),
  GET_ROOM_LIST: userId => dispatch({
    type: 'GET_ROOM_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  GET_ACCOMODATION_TYPE_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_TYPE_LIST',
    userId,
  }),
  GET_ROOM_BY_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ROOM_BY_ACCOMODATION_LIST',
    userId,
  }),
  GET_ROOM_BY_ACCOMODATION: (userId, accoId) => dispatch({
    type: 'GET_ROOM_BY_ACCOMODATION',
    userId,
    accoId,
  }),
  GET_SEASON_BY_ACCOMODATION: (userId, accoId) => dispatch({
    type: 'GET_SEASON_BY_ACCOMODATION',
    userId,
    accoId,
  }),
  PUT_ACCOMODATION_CONTENT: (token, accomodationContent, action) => dispatch({
    type: 'PUT_ACCOMODATION_CONTENT',
    token,
    accomodationContent,
    action,
  }),

  PUT_SEASON_BY_ACCOMODATION: (token, seasonByAccomodation, SeasonDifftoupdate, SeasonDifftoDelete, SeasonDifftoAdd, action) => dispatch({
    type: 'PUT_SEASON_BY_ACCOMODATION',
    token,
    seasonByAccomodation,
    SeasonDifftoupdate,
    SeasonDifftoDelete,
    SeasonDifftoAdd,
    action,
  }),
  DELETE_ACCOMODATION_CONTENT: (token, accomodationId) => dispatch({
    type: 'DELETE_ACCOMODATION_CONTENT',
    token,
    accomodationId,
  }),
  RESET_ACCOMODATION_CONTENT: () => dispatch({
    type: 'RESET_ACCOMODATION_CONTENT',
  }),
  PUT_ROOM_CONTENT: (token, roomContent, action) => dispatch({
    type: 'PUT_ROOM_CONTENT',
    token,
    roomContent,
    action,
  }),
  RESET_ROOM_CONTENT: () => dispatch({
    type: 'RESET_ROOM_CONTENT',
  }),
  PUT_ROOM_BY_ACCOMODATION: (token, roomByAccomodation, action) => dispatch({
    type: 'PUT_ROOM_BY_ACCOMODATION',
    token,
    roomByAccomodation,
    action,
  }),
  DELETE_ROOM_BY_ACCOMODATION: (token, roomByAccomodationId) => dispatch({
    type: 'DELETE_ROOM_BY_ACCOMODATION',
    token,
    roomByAccomodationId,
  }),
  RESET_ROOM_BY_ACCOMODATION: () => dispatch({
    type: 'RESET_ROOM_BY_ACCOMODATION',
  }),
  RESET_ROOM_PRICE_BY_ACCOMODATION: () => dispatch({
    type: 'RESET_ROOM_PRICE_BY_ACCOMODATION',
  }),
  RESET_SEASON_BY_ACCOMODATION: () => dispatch({
    type: 'RESET_SEASON_BY_ACCOMODATION',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  PUT_ROOM_PRICE_BY_ACCOMODATION: (token, roomByAccomodation) => dispatch({
    type: 'PUT_ROOM_PRICE_BY_ACCOMODATION',
    token,
    roomByAccomodation,
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accomodation))
