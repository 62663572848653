
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import { renderToStaticMarkup } from 'react-dom/server';
import Interweave from 'interweave';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import stage from '../../../config'
import {
  ActionButton,
  TextEditor,
} from '../..'

// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlCiel extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
    tripDetails: PropTypes.array,
    tripAllDays: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripDevisFinal: PropTypes.array,
    tripContent: PropTypes.array,
    tripAccomodation: PropTypes.array,
    tripBilling: PropTypes.object,
    tripPension: PropTypes.array,
    companyContent: PropTypes.object,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    tripDetails: [],
    tripAllDays: [],
    tripAllDaysAccomodations: [],
    tripContent: [],
    tripDevisFinal: [],
    tripAccomodation: [],
    tripBilling: {},
    tripPension: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],

  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      dateUpdateUrl: '',
      devisContent: '',
      zoomCarte: 100,
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }

    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteEvasion-size', `${event.currentTarget.value}px`)
  }

  handleOSizeZoomChange = (event) => {
    this.setState({
      zoomCarte: event.currentTarget.value,
    })
  }

  handlePrint = (html) => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur

    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }

  handleEditorChange = (content) => {
    this.setState({
      devisContent: content,
    });
  }

  handleSave = (devisContent) => {
    const {
      PUT_TRIP_DEVIS_FINAL,
      tripDetails,
      tripDevisFinal,
    } = this.props
    const {
      token,
    } = this.state
    const tripDevisFinalServeur = {}
    tripDevisFinalServeur.textHtml = devisContent
    tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
    } else {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
    }
  }


  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word(element, filename = '') {
    const html = element;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }


  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];
      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      tripContent,
      tripAllDays,
      tripAllDaysAccomodations,
      tripAccomodation,
      tripPension,
      tripBilling,
      companyContent,
      userAccount,
      informationsContent,
      locationList,
      roomList,
    } = this.props
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]

    const {
      prenom_responsable_groupe,
      nom_responsable_groupe,
      langage,
      centre_interet,
      code_devis,
    } = tripDetails[0]

    const {
      urlDevis,
      dateUpdateUrl,
      zoomCarte,
      devisContent,
    } = this.state

    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    // *****************************************************************

    const Couleurdefaut = {
      backgroundColor: informationsContent.main_color,
    };
    const check = {
      fontSize: '12.0pt; mso-bidi-font-size: 10.5pt',
      fontFamily: 'MS Mincho',
      msoFareastFontFamily: 'DengXian',
      msoFareastThemeFont: 'minor-fareast',
      msoBidiFontFamily: 'MS Mincho',
      color: '#00b050',
      background: 'white',
      msoAnsiLanguage: 'FR',
      msoFaBidiLanguage: 'AR-SA',
    }
    const fonddefaut = {
      color: informationsContent.main_color,
    };

    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = zoomCarte === 100 ? (`https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    ) : (
      `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&zoom=${zoomCarte}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    )
    const firstname = prenom_responsable_groupe || null
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null

    const lastname = nom_responsable_groupe || null
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const longueurVoyage = tripAllDaysAccomodations.length
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const IncDayTot = _.sum(TabIncJour.slice(0, longueurVoyage))
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(IncDayTot - 1, 'd').format('DD MMMM YYYY')
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const remarque = `${langage}_remarque`
    const slogan = `${langage}_slogan`
    const texteItineraire = langage && `${langage}_information_1_description`;
    const texteEntreeSite = langage && `${langage}_information_2_description`;
    const texteHotel = langage && `${langage}_information_3_description`;
    const carteCiel = {
      display: 'flex',
      margin: 'auto',
      paddingBottom: 20,
    };

    // const afficheLieu = tripLocalisationList && tripLocalisationList.map(item => (` ${item}`)).toString() */ }
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : ', '}`)
      return label.join('')
    }
    const pied = (
      <Interweave content={companyContent.description} />
    )
    const enTete = (
      <div className="page-header-evasion">
        <table width="100%">
          <tbody>
            <tr>
              <td width="25%">
                <img width="100" height="100" className="LogoEvasion" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
              </td>
              <td width="50%">
                <Interweave content={companyContent[slogan]} />
              </td>
              <td width="25%">
                <div className="centreEvasion">
                  <br />
                  <div>Devis N°{code_devis} </div>
                  <div>{travelerName}</div>
                  <div>Du {dateDepart} au {dateFin}</div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    )
    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFileCiel">
          {indexDisplay !== 'HTMLWORD'
           && (
           <div className="page-header-evasionhtml">
             <Grid container justifycontent="space-between" spacing={0}>
               <Grid item xs={3}>
                 <img className="LogoCiel2" width="100" height="100" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
               </Grid>
               <Grid item xs={6}>
                 <Interweave content={companyContent[slogan]} />
               </Grid>
               <Grid item xs={3}>
                 <div className="centreEvasion">
                   <br />
                   <div>Devis N°{code_devis} </div>
                   <div>{travelerName}</div>
                   <div>Du {dateDepart} au {dateFin}</div>
                 </div>
               </Grid>
             </Grid>
             <div className="navbar">
               <a href="#home">{langageTranslation.homeMenu}</a>
               {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
               <a href="#carte">{langageTranslation.carteMenu}</a>)}
               <a href="#detail">{langageTranslation.detailMenu}</a>
               <a href="#hotel">{langageTranslation.hotelMenu}</a>
               <a href="#info">{langageTranslation.infoMenu}</a>
               <a href="#video">Video</a>

             </div>

           </div>
           )}
          <div className="breakAfter">
            <div className="Banniere">
              <img className="ImageCoverCiel" width="1000" height="603" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
              <img width="150" height="150" className="LogoCiel3" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
            <br />
            <div id="home" className="TitreCiel1">{tripDetails[0].nom_programme}</div>
            <br />
            <hr className="LigneTitreCiel" />
            <div className="fontPresentation">
              <div> PARTICIPANTS: {travelerName} </div>
              <br />
              <div>Date: Du {dateDepart} au {dateFin} </div>
              <br />
              <div>Durée :{IncDayTot} jours sur {tripDetails[0].destination}</div>
              <br />
              <div> Programme Créé par  {advisorName} de CielChine.com </div>
              <br />
            </div>

          </div>
          <div className="breakAfter">
            <br />
            <div className="TitreCiel1" style={fonddefaut}>ITINERAIRE EN BREF</div>
            <br />
            <Interweave content={informationsContent[texteItineraire]} />
            <br />
            <table className="TabItineraireCielGlobal" width="100%">
              <tbody>
                <tr>
                  <td className="TabItineraireCiel">Jour & date </td>
                  <td className="TabItineraireCiel">Programme</td>
                  <td className="TabItineraireCiel">Transport</td>
                  <td className="TabItineraireCiel">Repas</td>
                  <td className="TabItineraireCiel">Guide</td>
                  <td className="TabItineraireCiel">Hébergement</td>

                </tr>
                {tripAllDays.map((item, index) => {
                  const IncDay = _.sum(TabIncJour.slice(0, index))
                  const title_perso = `titre_jour_${index + 1}`;
                  const datejour = tripDetails[0].date_depart
                    ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMM'))
                    : ''

                  return (
                    <tr key={`${datejour}`}>
                      <td className="TabItineraireCiel1">
                        <div> J {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)}</div>
                        <div>{datejour}</div>
                      </td>
                      <td className="TabItineraireCiel1">
                        {tripContent[0][title_perso]}
                      </td>
                      <td className="TabItineraireCiel1">
                        <div>{item.transport}</div>
                      </td>
                      <td className="TabItineraireCiel1">
                        {tripPension[index].pension !== 'none' && tripPension[index].pension}
                      </td>
                      <td className="TabItineraireCiel1">
                        {guideArray[index] === '1' ? <span style={check}>✓</span> : ''}
                      </td>
                      <td className="TabItineraireCiel1">
                        {tripAccomodation[index].nom_hebergement}
                      </td>
                    </tr>

                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="breakAfter">
            <br />
            <div className="TitreCiel1" style={fonddefaut}>Carte du voyage</div>
            <br />
            {tripDetails[0].image
              ? (
                <div id="carte">
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={12}>
                      <img style={carteCiel} height="850" width="600" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                    </Grid>
                  </Grid>
                </div>
              ) : (tripDetails[0].map_localisation && (
              <div id="carte">
                <img style={carteCiel} height="850" width="600" src={urlMap} />
                <div>
                  <input className="adjustSize" title="adjust map height" name="size" min="200" max="1000" type="range" defaultValue="850" onChange={this.handleOSizeChange} />
                  <input className="adjustSizeZoom" title="adjust map zoom" name="size" min="1" max="15" type="range" defaultValue="5" onChange={this.handleOSizeZoomChange} />
                </div>
              </div>
              )
              )}

          </div>
          <div className="breakAfter">
            <br />
            <div id="detail" className="TitreCiel1" style={fonddefaut}>Programme</div>
            <br />
            {tripAllDays.map((item_jour, index) => {
              const desc = `desc_jour_${index + 1}`;
              const imageArray = item_jour.image.split(',')
              const IncDay = _.sum(TabIncJour.slice(0, index))
              // eslint-disable-next-line no-nested-ternary
              const datejour = tripDetails[0].date_depart ? (TabIncJour[index] === 1
                ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))
                : `${_.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay, 'd').format('ddd DD MMMM'))} à ${_.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langage).add(IncDay + TabIncJour[index] - 1, 'd').format('ddd DD MMMM'))}`)
                : '';
              // eslint-disable-next-line no-nested-ternary
              const guideNeed = guideArray[index] === '1' ? langageTranslation.withGuide : guideArray[index] === '2' ? langageTranslation.withGuideEn : ''
              let driverNeed = ''
              switch (chauffeurArray[index]) {
                case '0':
                  driverNeed = ''
                  break;
                case '1':
                  driverNeed = langageTranslation.driverEvasion
                  break;
                case '2':
                  driverNeed = ''
                  break;
                case '3':
                  driverNeed = 'Transport Locaux'
                  break;
                case '4':
                  driverNeed = langageTranslation.busEvasion
                  break;
                default:
                  driverNeed = ''
              }
              const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
              const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver
              const BandeauJour = () => (
                <div>
                  <div className="titreJournee" id={`Jour${index}`}>  Jour {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} - {datejour}: {item_jour.nom_journee_court}  </div>
                </div>
              );

              const BandeauPiedJour = () => (
                <div>
                  {tripPension[index].pension !== 'none' && (<div><span className="boldText">Repas: </span>{tripPension[index].pension}</div>)}
                  {(tripAccomodation[index].nom_hebergement !== 'none' || nbOfRoomLabel(index))

                                              && (
                                              <div className="fontAcco">
                                                Nuit en {tripAccomodation[index].nom_hebergement !== 'none' && (`${tripAccomodation[index].nom_hebergement}${!_.isEmpty(tripAccomodation[index].categorie) ? `(${tripAccomodation[index].categorie})` : ''}`)}
                                                {nbOfRoomLabel(index) && (!roomsNumberArray ? '' : nbOfRoomLabel(index))}
                                              </div>
                                              )
                  }
                  <div className="fontAcco">{asteriskText}</div>
                  {item_jour.distance && (<div className="fontAcco">{item_jour.distance}</div>)}
                  <br />
                </div>
              );

              return (
                <div key={`${datejour}`}>
                  <div>

                    <div>
                      <div>
                        <div className="CoupePasCiel">
                          <BandeauJour />
                          <Interweave content={tripContent[0][desc]} />
                          <BandeauPiedJour />
                        </div>
                        {imageArray[0] !== '' && imageArray.length <= 2
                            && (
                            <Grid container spacing={2} justifyContent="center">
                              {imageArray.map((item, indexImage) => (
                                <Grid item xs={6}>
                                  <img width="428" height="343" className="Image1Jour1Ciel" src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`} />
                                </Grid>
                              ))}
                            </Grid>
                            )}
                        {imageArray[0] !== '' && imageArray.length > 2
                            && (
                            <Grid container spacing={2} justifyContent="center">
                              {imageArray.map((item, indexImage) => (
                                <Grid item xs={4}>
                                  <img width="428" height="343" className="Image1Jour1Ciel" src={`${savedImgUrl}/${companyContent.company}/${imageArray[indexImage]}`} />
                                </Grid>
                              ))}
                            </Grid>
                            )}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {tripBilling.displayTabCheck && (
          <div className="breakAfter">
            <br />
            <div id="detail" className="TitreCiel1" style={fonddefaut}>Prix du voyage</div>
            <br />

            <div id="info" className="CoupePasCiel">
              <div>
                <Interweave content={tripBilling.tabPrice} />
              </div>
              <br />
            </div>
            <div>
              <br />
              <div className="CoupePasCiel">
                <div className="TitrePrixComprend">  {langageTranslation.priceContained}:  </div>
                <br />
                <Interweave content={tripDetails[0].prix_comprend} />
              </div>
              <div className="CoupePasCiel">
                <div className="TitrePrixComprendPas">  {langageTranslation.priceDoNotContained}:  </div>
                <br />
                <Interweave content={tripDetails[0].prix_comprend_pas} />
              </div>
            </div>
          </div>
          )}
          <div className="breakAfter">
            <br />
            <div id="detail" className="TitreCiel1" style={fonddefaut}>Entrée des sites</div>
            <br />
            <Interweave content={informationsContent[texteEntreeSite]} />
            <Interweave content={centre_interet} />
          </div>
          <div>
            <div className="breakAfter">
              <br />
              <div id="hotel" className="TitreCiel1" style={fonddefaut}>Proposition d’hôtels</div>
              <br />
              <Interweave content={informationsContent[texteHotel]} />
              <br />
              {Hotel_Unique && Hotel_Unique.map((item, i) => {
                const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');

                return (
                  <div key={`Acco${item.id_journee}`}>
                    {item.nom_hebergement !== 'none'
                && (
                  <div className="CoupePasCiel">
                    <div>
                      <div className="afficheHotelEvasion">
                        <div className="CentreEvasion">
                          <span className="grasEvasion">{LieuFinJournee.lieu}: </span> {item.nom_hebergement} -   {Nombre_Occurence_Hotel[i].occurrence} nuit(s)
                          <Interweave content={item.fr_desc_hebergement} />
                          <div><Interweave content={adresseHebClean} /></div>
                          {imageArrayHotel[0] !== ''
                && (

                  <div>
                    {imageArrayHotel[0] !== '' && imageArrayHotel.length <= 2
&& (
<Grid container spacing={2} justifyContent="center">
  {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
    <Grid item xs={6}>
      <img width="428" height="343" className="Image1Jour1Ciel" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
    </Grid>
  ))}
</Grid>
)}
                    {imageArrayHotel[0] !== '' && imageArrayHotel.length > 2
&& (
<Grid container spacing={2} justifyContent="center">
  {imageArrayHotel && imageArrayHotel.map((itemHotel, indexImage) => (
    <Grid item xs={4}>
      <img width="428" height="343" className="Image1Jour1Ciel" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[indexImage]}`} />
    </Grid>
  ))}
</Grid>
)}
                  </div>
                )}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                )}
                  </div>
                )
              })}
            </div>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12}>
                <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
              </Grid>
            </Grid>
            {indexDisplay !== 'HTMLWORD' && langage && langage === 'fr' && (
            <div className="videoBoxEvasion">
              <h2 id="video" className="Titre2CircuitEvasion" style={Couleurdefaut}>  Video  </h2>
              <br />
              <iframe className="VideoEvasion" width="800" height="500" src="https://www.youtube.com/embed/28FaOVmwY1c" title="La Thaïlande vous accueille de nouveau sans conditions au 1er juillet 2022" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
            )}
          </div>
        </div>

      )
      return myPage
    }
    const headerHtml = renderToStaticMarkup(enTete)
    const footerHtml = renderToStaticMarkup(pied)
    const pageHtml = renderToStaticMarkup(myPageFunc('STANDARD'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));

    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');


    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCiel.css">
     <title>Votre proposition de voyage</title>
     </head>
     <body>
     <a class="printLink" href="#" OnClick="javascript:window.print()">
     <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
     </a> 
        ${devisContent}
      </body>
   </html>
   `;

    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCiel.css">
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>

<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
   
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
             <div style='mso-element:header' id="h1" >
                 <p class="MsoHeader">
                     <table border="0" width="100%">
                         <tr>
                             <td>
                             ${headerHtml}
                             </td>
                          </tr>
                    </table>
                </p>
            </div>
            <div style='mso-element:footer' id="f1">
            <p class="MsoFooter">
                <table width="100%" >
                    <tr>
                        <td  class="footer">
                    ${footerHtml}
                        </td>
                    </tr>
                </table>
            </p>
        </div>
        </td>
                         
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}.docx`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet">Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet">Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          value={_.isEmpty(devisContent) ? pageHtml : devisContent}
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlCiel.css"
          handleEditorChange={content => this.handleEditorChange(content)}
          height={1000}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  tripDevisFinal: state.api.tripDevisFinal,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlCiel))
