import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Geocode from 'react-geocode';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'

import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'


Geocode.setApiKey('AIzaSyBkscY7LCKWGDdUms2ZBenfb_kFaCmOnqo');


const locationFormatRegExp = new RegExp(' ', 'g')

class Location extends PureComponent {
  static propTypes = {
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    RESET_LOCATION_CONTENT: PropTypes.func.isRequired,
    PUT_LOCATION_CONTENT: PropTypes.func.isRequired,
    DELETE_LOCATION_CONTENT: PropTypes.func.isRequired,
    putLocationContent: PropTypes.object,
    deleteLocationContent: PropTypes.object,
    locationList: PropTypes.array,
    locationGrandList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    locationList: [],
    locationGrandList: [],
    putLocationContent: {},
    deleteLocationContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.displayAutocompleteLocationGrand = this.displayAutocompleteLocationGrand.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.validate = this.validate.bind(this)
    this.reload = this.reload.bind(this)
    this.createNewLocation = this.createNewLocation.bind(this)
    this.createNewLocation_withGeoLoc = this.createNewLocation_withGeoLoc.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onChangeLocationGrand = this.onChangeLocationGrand.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.getOptionLabelLocationGrand = this.getOptionLabelLocationGrand.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedLocation = this.getModifiedLocation.bind(this)
    this.updateLocation = this.updateLocation.bind(this)
    this.state = {
      actionLabel: 'Create',
      locationList: [],
      isNewLocation: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      disabledActionBtn: true,
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      locationContent: '',
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      locationList,
      GET_LOCATION_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(locationList)) {
      this.setState({
        locationList: _.sortBy(locationList, o => o.lieu),
      })
    } else {
      GET_LOCATION_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      locationContent,
      locationList: locationListOld,
    } = this.state
    const {
      locationList,
      putLocationContent,
      deleteLocationContent,
      RESET_LOCATION_CONTENT,
    } = nextprops
    // location saved in DB -> get back updated list
    if (putLocationContent && putLocationContent.success) {
      this.reload()
      this.setState({
        locationContent: {
          ...locationContent,
          id_lieu: putLocationContent.id,
        },
        validMsg: 'the location was successfully recorded !',
      })
      this.resetValidMsg()
      this.handleClose()
    }
    // location delete in DB -> get back updated list
    if (deleteLocationContent && deleteLocationContent.success) {
      this.reload()
      this.setState({
        locationContent: '',
        validMsg: 'the day was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // location can not be delete in DB -> it is used yet in day or accomodation
    if (deleteLocationContent && !deleteLocationContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteLocationContent.message,
      })
      RESET_LOCATION_CONTENT()
    }
    if (locationList !== locationListOld) {
      this.setState({
        locationList: _.sortBy(locationList, o => o.lieu),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_LOCATION_CONTENT,
      GET_LOCATION_LIST,
    } = this.props
    RESET_LOCATION_CONTENT()
    GET_LOCATION_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { locationList } = this.state

    this.setState({
      validateError: false,
    })
    if (typeof newValue === 'string') {
      // On press Enter
      const valueClean = _.upperCase(_.trim(newValue)).replace(locationFormatRegExp, '-')
      const valueExist = locationList.filter(d => d.lieu === valueClean)
      if (_.isEmpty(valueExist)) {
        this.setState({
          locationContent: {
            id_lieu: null,
            lieu: newValue,
          },
          disabledActionBtn: false,
          isNewLocation: true,
        });
      } else {
        this.setState({
          locationContent: valueExist[0],
          disabledActionBtn: true,
          isNewLocation: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const valueClean = _.upperCase(_.trim(newValue.inputValue)).replace(locationFormatRegExp, '-')
      const isNew = _.findIndex(locationList, o => o.lieu === valueClean) < 0
      this.setState({
        locationContent: {
          id_lieu: null,
          lieu: newValue.inputValue,
        },
        disabledActionBtn: !isNew,
        isNewLocation: isNew,
      });
    } else {
      this.setState({
        locationContent: newValue,
        disabledActionBtn: true,
        isNewLocation: false,
      });
    }
  }

  onChangeLocationGrand = (event, value) => {
    const { locationContent } = this.state
    console.log(locationContent)
    this.setState({
      locationContent: {
        ...locationContent,
        id_lieu_grand: value.id_lieu_grand,
        locationGrand: value.locationGrand,
      },
      disabledActionBtn: false,
    })
  }


  filterOptions = (options, params) => {
    const { locationList } = this.state
    const valueClean = _.upperCase(_.trim(params.inputValue)).replace(locationFormatRegExp, '-')
    const filtered = _.filter(locationList, d => d.location.indexOf(valueClean) > -1)
    const valueExist = locationList.filter(d => d.lieu === valueClean)

    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: valueClean,
        lieu: `Add "${valueClean}"`,
      });
    }

    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.lieu;
  }

  getOptionLabelLocationGrand = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return _.upperCase(_.trim(option)).replace(locationFormatRegExp, '-');
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.locationGrand) {
      return ''
    }
    // Regular option
    return option.locationGrand;
  }

  // ***************************
  // Display input
  displayAutocompleteInput = () => {
    const {
      locationContent,
      locationList,
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="location-field"
            value={locationContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={locationList}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.lieu}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Location"
                margin="normal"
                placeholder="Select a place or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      locationContent,
    } = this.state
    const locationId = locationContent.id_lieu
    const { DELETE_LOCATION_CONTENT } = this.props

    if (confirm('Do you really want to delete this location ?')) {
      if (locationId) {
        DELETE_LOCATION_CONTENT(token, locationId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: locationContent.lieu ? 'This location do not exist.' : 'Please, select a location.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { locationContent } = this.state
    if (!locationContent || !locationContent.id_lieu) return null
    this.setState({
      isNewLocation: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      // locationContent,
      isNewLocation,
      locationContent,
      token,
    } = this.state
    const {
      PUT_LOCATION_CONTENT,
    } = this.props
    // Check all required inputs
    this.setState({
      disabledActionBtn: true,
    })
    if (!locationContent.lieu) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewLocation) {
      this.createNewLocation(locationContent);
    } else {
      PUT_LOCATION_CONTENT(token, locationContent, 'update')
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new location
  updateLocation = () => {
    const {
      token,
      locationContent,
    } = this.state
    const { PUT_LOCATION_CONTENT } = this.props
    PUT_LOCATION_CONTENT(token, locationContent, 'update')
    this.handleClose()
  }

  createNewLocation = (locationContent) => {
    Geocode.fromAddress(locationContent.lieu).then(
      (response) => {
        this.createNewLocation_withGeoLoc(response, locationContent);
      },
      (error) => {
        console.error(error);
        alert('cannot find GPS information for this location, the location is created without GPS information, if needed consult your administrator to manually add GPS info at thomas.damien@duyen.pro.')

        this.createNewLocation_withGeoLoc('', locationContent);
      },
    )
  }

  displayAutocompleteLocationGrand = (locationGrandList) => {
    const {
      locationContent,
    } = this.state
    return (
      <Autocomplete
        id="locationGrand"
        value={locationContent}
        options={locationGrandList}
        getOptionLabel={this.getOptionLabelLocationGrand}
        onChange={this.onChangeLocationGrand}
        renderInput={params => (
          <TextField
            {...params}
            label="province or region"
            margin="normal"
            variant="outlined"
          />
        )}
      />
    )
  }

  createNewLocation_withGeoLoc = (response, locationContent) => {
    const {
      token,
      btnActionDisabled,

    } = this.state

    // locationClean
    const locationDetails = {}
    locationDetails.lieu = locationContent.lieu
    locationDetails.geocode = response !== '' ? response.results[0].geometry.location : ''
    const { PUT_LOCATION_CONTENT } = this.props
    this.setState({
      btnActionDisabled: !btnActionDisabled,
    })
    PUT_LOCATION_CONTENT(token, locationDetails, 'create')
  }

  getModifiedLocation = (item) => {
    const { locationContent } = this.state
    this.setState({
      locationContent: {
        ...locationContent,
        lieu: _.upperCase(_.trim(item.location)).replace(locationFormatRegExp, '-'),
      },
    }, () => this.updateLocation())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      disabledActionBtn,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      locationContent,
    } = this.state
    const { locationGrandList } = this.props
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>LOCATION MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
              {this.displayAutocompleteLocationGrand(locationGrandList)}

            </div>
            <ActionButton
              label={actionLabel}
              disabled={!locationContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedLocation(item)}
            disabled={false}
            title="Enter the new value for this location"
            warning="This will update this location in all quotes !"
            type="location"
            modalContent="location"
            inputValue={!_.isEmpty(locationContent) ? locationContent.lieu : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  putLocationContent: state.api.putLocationContent,
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  deleteLocationContent: state.api.deleteLocationContent,
})

const mapDispatchToProps = dispatch => ({
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  RESET_LOCATION_CONTENT: () => dispatch({
    type: 'RESET_LOCATION_CONTENT',
  }),
  PUT_LOCATION_CONTENT: (token, locationContent, action) => dispatch({
    type: 'PUT_LOCATION_CONTENT',
    token,
    locationContent,
    action,
  }),
  DELETE_LOCATION_CONTENT: (token, locationId) => dispatch({
    type: 'DELETE_LOCATION_CONTENT',
    token,
    locationId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Location))
