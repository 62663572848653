import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getFilterQuoteTableResults,
  putColumnQuoteTableResults,
  putFilterQuoteTableResults,
} from './actions'

function* getFilterQuoteTableAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_filter_quote_table, token)
  yield put(getFilterQuoteTableResults(data))
}

function* putColumnQuoteTableAsync(params) {
  const {
    token,
    filterContent,
  } = params
  const data = yield call(api.put_column_quote_table, token, filterContent)
  yield put(putColumnQuoteTableResults(data))
}

function* putFilterQuoteTableAsync(params) {
  const {
    token,
    filterContent,
  } = params
  const data = yield call(api.put_filter_quote_table, token, filterContent)
  yield put(putFilterQuoteTableResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_FILTER_QUOTE_TABLE', getFilterQuoteTableAsync)
  yield takeEvery('PUT_COLUMN_QUOTE_TABLE', putColumnQuoteTableAsync)
  yield takeEvery('PUT_FILTER_QUOTE_TABLE', putFilterQuoteTableAsync)
}

export default fetchResult
