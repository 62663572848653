import React from 'react';

const FondLost = {
  backgroundColor: '#F1948A',
  borderRadius: 5,
  textAlign: 'center',
};

const FondConfirmed = {
  backgroundColor: '#EAFAF1',
  borderRadius: 5,
  textAlign: 'center',
};

const resourcesColumns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'activity',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'location',
    label: 'Activity location',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'activity_type',
    label: 'activity type',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'date_expire',
    label: 'date expiration price',
    options: {
      customBodyRender: (value) => {
        if (value) {
          return (
            <div style={FondLost}> expired</div>
          )
        } return (
          <div style={FondConfirmed}>not expired</div>
        )
      },
      filter: true,
      sort: true,
      display: 'true',
    },
  },
]

export default resourcesColumns
