import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/en-gb'
import _ from 'lodash'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';

import {
  Template,
  BarGraph,
  TabPanel,
} from '../../components'
import {
  CssGrid,
  colorBarGraph,
  MenuSelectGraph,
  AntTabs,
} from '../../configCSS'

// config english langage for date
moment.locale('en-gb')

class Statistics extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    listCircuit: PropTypes.array,
    numberTravelerByTrip: PropTypes.array,
  }

  static defaultProps = {
    listCircuit: [],
    numberTravelerByTrip: [],
  }

  constructor(props) {
    super(props);
    this.tabProps = this.tabProps.bind(this)
    this.displayTripStats = this.displayTripStats.bind(this)
    this.displayTravelerStats = this.displayTravelerStats.bind(this)
    this.displayGraphTripCreated = this.displayGraphTripCreated.bind(this)
    this.displayGraphTripOpened = this.displayGraphTripOpened.bind(this)
    this.displayGraphTripClosed = this.displayGraphTripClosed.bind(this)
    this.displayGraphTraveler = this.displayGraphTraveler.bind(this)
    this.handleChangeAdvisor = this.handleChangeAdvisor.bind(this)
    this.handleChangeTimeline = this.handleChangeTimeline.bind(this)
    this.onChangeTab = this.onChangeTab.bind(this)
    this.listCircuitSorted = props.listCircuit.sort((a, b) => {
      const aDate = new Date(moment(a.date_creation, 'YYYY-MM-DD'))
      const bDate = new Date(moment(b.date_creation, 'YYYY-MM-DD'))
      if (bDate > aDate) return -1
      if (bDate < aDate) return 1
      return 0
    })
    this.state = {
      yearList: [
        'Last 12 months',
        'this month',
        'previous month',
        ..._.reverse(_.uniq(this.listCircuitSorted.map(d => moment(d.date_creation, 'YYYY-MM-DD').format('YYYY')))),
      ],
      timelineSelected: 'Last 12 months',
      // get for labels last 12 months
      defaultTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
      labelsTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
      advisorList: [],
      advisorSelected: [],
      keyTab: 0,
    }
  }

  componentDidMount() {
    const {
      history,
      listCircuit,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // check ListCircuit is valid else return to request
    if (_.isEmpty(listCircuit)) {
      history.push('trip')
    } else {
      this.setState({
        advisorList: [
          'Total',
          ..._.uniq(listCircuit.map(d => d.advisor)),
        ],
        advisorSelected: [
          'Total',
        ],
      })
    }
  }

  componentWillReceiveProps() {
  }

  tabProps = index => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  fillLabelsTimeline = (yearSelected) => {
    const { defaultTimeline } = this.state
    const labelsTimelineSelected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    // get the 12 last months where a trip was created
    if (yearSelected === 'Last 12 months') {
      return defaultTimeline.slice(defaultTimeline.length - 12)
    }

    // retourner uniquement le mois en cours
    if (yearSelected === 'this month') {
      const currentMonthLabel = moment().format('MMM YY');
      return [currentMonthLabel];
    }

    // retourner uniquement le mois precedent
    if (yearSelected === 'previous month') {
      const dateMoisPrecedent = moment().subtract(1, 'months').format('MMM YY');
      return [dateMoisPrecedent];
    }
    // build an array with the 12 months for the year selected
    return labelsTimelineSelected.map((d, i) => moment(`${i + 1}-${yearSelected}`, 'M-YYYY').format('MMM YY'))
  }

  handleChangeAdvisor = (event) => {
    // value is an array of value checked ["name1", "name2", ...]
    this.setState({
      advisorSelected: [
        ...event.target.value,
      ],
    })
  };

  handleChangeTimeline = (event) => {
    const { value } = event.target

    this.setState({
      timelineSelected: value,
      labelsTimeline: this.fillLabelsTimeline(value),
    })
  }

  displayGraphTripCreated = () => {
    const {
      yearList,
      timelineSelected,
      advisorList,
      advisorSelected,
      labelsTimeline,
    } = this.state
    // get number of trip created by advisor and by month
    const datasetsFindAdvisor = (ad, indexAdvisor) => {
      // index 0 for all advisor
      if (ad === 'Total') return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => l === moment(t.date_creation, 'YYYY-MM-DD').format('MMM YY'))).length)
      return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => t.advisor === advisorSelected[indexAdvisor] && l === moment(t.date_creation, 'YYYY-MM-DD').format('MMM YY'))).length)
    }
    console.log(advisorSelected)

    // build datastes for graph
    const datasets = advisorSelected.map((ad, i) => (
      {
        label: ad,
        data: datasetsFindAdvisor(ad, i),
        backgroundColor: colorBarGraph[i],
      }
    ))

    return (
      <div className="graph-main-div">
        <h2 className="graph-title">Quote created</h2>
        <div className="select-div">
          <FormControl className="select-control">
            <InputLabel id="advisor-select-label">Advisor</InputLabel>
            <Select
              labelId="Quote_advisor-checkbox-label"
              id="Quote_advisor-checkbox"
              multiple
              value={advisorSelected}
              onChange={this.handleChangeAdvisor}
              input={<Input />}
              // eslint-disable-next-line no-confusing-arrow
              renderValue={selected => selected.length > 1 ? `${selected[1]} ...` : 'Total'}
              MenuProps={MenuSelectGraph}
            >
              {advisorList.map(name => (
                <MenuItem key={`trip${name}`} value={name}>
                  <Checkbox checked={advisorSelected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="select-control">
            <InputLabel id="timeline">Date</InputLabel>
            <Select
              labelId="Timeline-checkbox-label"
              id="Timeline-checkbox"
              value={timelineSelected}
              onChange={this.handleChangeTimeline}
            >
              {yearList.map(year => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <BarGraph
          id="tripPerAdvisor"
          key="tripPerAdvisor"
          labels={labelsTimeline}
          datasets={datasets}
          message="Trip created per advisor"
        />
      </div>
    )
  }

  displayGraphTripOpened = (title, filterArray) => {
    const {
      yearList,
      timelineSelected,
      advisorList,
      advisorSelected,
      labelsTimeline,
    } = this.state

    // get number of trip with status confirmed or in progress by advisor and by month
    const datasetsFindAdvisor = (ad, indexAdvisor) => {
      // index 0 for all advisor
      if (ad === 'Total') return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => filterArray.indexOf(t.status) > -1 && l === moment(t.date_modif_status, 'YYYY-MM-DD').format('MMM YY'))).length)
      return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => t.advisor === advisorSelected[indexAdvisor] && filterArray.indexOf(t.status) > -1 && l === moment(t.date_modif_status, 'YYYY-MM-DD').format('MMM YY'))).length)
    }


    // build datastes for graph
    const datasets = advisorSelected.map((ad, i) => (
      {
        label: ad,
        data: datasetsFindAdvisor(ad, i),
        backgroundColor: colorBarGraph[i],
      }
    ))

    return (
      <div id={`trip${title}PerAdvisorDiv`} className="graph-main-div">
        <h2 className="graph-title">{`Quote ${title.toLowerCase()}`}</h2>
        <div className="select-div">
          <FormControl className="select-control">
            <InputLabel id={`Quote_${title}_advisor_label`}>Advisor</InputLabel>
            <Select
              labelId={`Quote_${title}_advisor-checkbox-label`}
              id={`Quote_${title}_advisor-checkbox`}
              multiple
              value={advisorSelected}
              onChange={this.handleChangeAdvisor}
              input={<Input />}
              // eslint-disable-next-line no-confusing-arrow
              renderValue={selected => selected.length > 1 ? `${selected[1]} ...` : 'Total'}
              MenuProps={MenuSelectGraph}
            >
              {advisorList.map(name => (
                <MenuItem key={`${title}${name}`} value={name}>
                  <Checkbox checked={advisorSelected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="select-control">
            <InputLabel id={`Quote_${title}_timeline_label`}>Date</InputLabel>
            <Select
              labelId={`Quote_${title}_timeline-checkbox-label`}
              id={`Quote_${title}_timeline-checkbox`}
              value={timelineSelected}
              onChange={this.handleChangeTimeline}
            >
              {yearList.map(year => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <BarGraph
          id={`trip${title}PerAdvisor`}
          key={`trip${title}PerAdvisor`}
          labels={labelsTimeline}
          datasets={datasets}
          message="Trip confirmed per advisor"
        />
      </div>
    )
  }

  displayGraphTripClosed = (title, filterArray) => {
    const {
      yearList,
      timelineSelected,
      advisorList,
      advisorSelected,
      labelsTimeline,
    } = this.state
    // get number of trip according to their status (completed, canceled, unanwsered or these 3 at the same time) by advisor and by month
    const datasetsFindAdvisor = (ad, indexAdvisor) => {
      // index 0 for all advisor
      if (ad === 'Total') return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => filterArray.indexOf(t.status) > -1 && l === moment(t.date_cloture, 'YYYY-MM-DD').format('MMM YY'))).length)
      return labelsTimeline.map(l => (this.listCircuitSorted.filter(t => t.advisor === advisorSelected[indexAdvisor] && filterArray.indexOf(t.status) > -1 && l === moment(t.date_cloture, 'YYYY-MM-DD').format('MMM YY'))).length)
    }

    // build datastes for graph
    const datasets = advisorSelected.map((ad, i) => (
      {
        label: ad,
        data: datasetsFindAdvisor(ad, i),
        backgroundColor: colorBarGraph[i],
      }
    ))

    return (
      <div className="graph-main-div">
        <h2 className="graph-title">{`Quote ${title.toLowerCase()}`}</h2>
        <div className="select-div">
          <FormControl className="select-control">
            <InputLabel id={`Quote_${title}_advisor_label`}>Advisor</InputLabel>
            <Select
              labelId={`Quote_${title}_advisor-checkbox-label`}
              id={`Quote_${title}_advisor-checkbox`}
              multiple
              value={advisorSelected}
              onChange={this.handleChangeAdvisor}
              input={<Input />}
              // eslint-disable-next-line no-confusing-arrow
              renderValue={selected => selected.length > 1 ? `${selected[1]} ...` : 'Total'}
              MenuProps={MenuSelectGraph}
            >
              {advisorList.map(name => (
                <MenuItem key={`${title}${name}`} value={name}>
                  <Checkbox checked={advisorSelected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="select-control">
            <InputLabel id={`Quote_${title}_timeline_label`}>Date</InputLabel>
            <Select
              labelId={`Quote_${title}_timeline-checkbox-label`}
              id={`Quote_${title}_timeline-checkbox`}
              value={timelineSelected}
              onChange={this.handleChangeTimeline}
            >
              {yearList.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <BarGraph
          id={`trip${title}PerAdvisor`}
          key={`trip${title}PerAdvisor`}
          labels={labelsTimeline}
          datasets={datasets}
          message="Trip confirmed per advisor"
        />
      </div>
    )
  }

  displayGraphTraveler = () => {
    const {
      yearList,
      timelineSelected,
      labelsTimeline,
    } = this.state

    const { numberTravelerByTrip } = this.props

    // get number of travelers for trip with status completed and sortes by month
    const timedFilteredCompletedTrip = labelsTimeline.map(l => (this.listCircuitSorted.filter(t => ['completed'].indexOf(t.status) > -1 && l === moment(t.date_cloture, 'YYYY-MM-DD').format('MMM YY'))))
    const data = timedFilteredCompletedTrip.map((tCT) => {
      if (_.isEmpty(tCT)) return 0
      const travArray = tCT.map(trip => numberTravelerByTrip.find(trav => trav.id_devis === trip.id_devis))
      if (!_.every(travArray, Boolean)) return 0
      return travArray.map(o => o.nb_traveler).reduce((sum, n) => sum + n)
      // return tCT.map(trip => numberTravelerByTrip.find(trav => trav.id_devis === trip.id_devis).nb_traveler).reduce((sum, n) => sum + n)
    })

    // build datastes for graph
    const datasets = [{
      label: 'Travelers',
      data: data,
      backgroundColor: colorBarGraph[0],
    }]

    return (
      <div className="graph-main-div">
        <h2 className="graph-title">
          <p>Number of traveler</p>
        </h2>
        <div className="select-div">
          <FormControl className="select-control">
            <InputLabel id="timeline">Date</InputLabel>
            <Select
              labelId="Timeline-checkbox-label"
              id="Timeline-checkbox"
              value={timelineSelected}
              onChange={this.handleChangeTimeline}
            >
              {yearList.map(year => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <BarGraph
          id="nb_traveler"
          key="nb_traveler"
          labels={labelsTimeline}
          datasets={datasets}
          message="Trip confirmed per advisor"
        />
      </div>
    )
  }

  displayTripStats = () => (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripCreated()}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripClosed('Closed', ['completed', 'canceled', 'no answer'])}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripOpened('Confirmed', ['confirmed'])}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripOpened('In progress', ['in progress'])}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripClosed('Completed', ['completed'])}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripClosed('Canceled', ['canceled'])}
      </CssGrid>
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTripClosed('Unanswered', ['no answer'])}
      </CssGrid>
    </CssGrid>
  )

  displayTravelerStats = () => (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item lg={6} md={12}>
        {this.displayGraphTraveler()}
      </CssGrid>
    </CssGrid>
  )

  onChangeTab = (event, keyTab) => {
    this.setState({
      keyTab,
    })
  }

  // **********************************
  render() {
    const { keyTab } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>STATISTICS</h2>
              <AppBar position="static" className="tab-bar">
                <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
                  <Tab label="Trip" {...this.tabProps(0)} />
                  <Tab label="Travelers" {...this.tabProps(1)} />
                </AntTabs>
              </AppBar>
              <TabPanel value={keyTab} index={0}>
                {this.displayTripStats()}
              </TabPanel>
              <TabPanel value={keyTab} index={1}>
                {this.displayTravelerStats()}
              </TabPanel>
            </div>
          </div>
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  numberTravelerByTrip: state.travelerReducer.numberTravelerByTrip,
})

const mapDispatchToProps = dispatch => ({
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistics))
