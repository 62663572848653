import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_request_status_list(token) {
    return axios.get(`${apiUrl}/api/lists/requestStatusList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  get_request(token) {
    return axios.get(`${apiUrl}/api/request/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_request(token, request) {
    return axios.put(`${apiUrl}/api/request/create`, { request }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_request_accept(token, requestId) {
    return axios.put(`${apiUrl}/api/request/accept/`, { requestId }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  put_request_update(token, request) {
    return axios.put(`${apiUrl}/api/request/update/`, { request }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  delete_request(token, requestId) {
    return axios.delete(`${apiUrl}/api/request/delete/${requestId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
