
const resourcesColumns = [
  {
    title: 'Quote',
    field: 'nom_devis',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Creation Date',
    field: 'date_creation',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Status',
    field: 'status',
    editable: 'never',
    resizable: true,
    // eslint-disable-next-line consistent-return
    cellStyle: (e, rowData) => {
      if (rowData.status === 'confirmed-booked') {
        return { backgroundColor: '#7C9867' };
      }
      if (rowData.status === 'confirmed') {
        return { backgroundColor: '#ff9494' };
      }
      if (rowData.status === 'in progress') {
        return { backgroundColor: '#0bcdd6' };
      }
    },
  },
]

const resourcesColumnsRequest = [
  {
    title: 'Creation Date',
    field: 'date_request',
  },
  {
    title: 'Date Depart',
    field: 'date_departure',
  },
  {
    title: 'Traveller Number',
    field: 'nb_traveler',
  },
  {
    title: 'Trip name',
    field: 'trip_name',
    hidden: true,
    resizable: true,
    cellStyle: {
      backgroundColor: '#e57373',
    },
  },
]
const resourcesColumnsRequest1 = [
  {
    title: 'Creation Date',
    field: 'date_request',
  },
  {
    title: 'Date Depart',
    field: 'date_departure',
  },
  {
    title: 'Traveller Number',
    field: 'nb_traveler',
  },
]


export { resourcesColumns, resourcesColumnsRequest, resourcesColumnsRequest1 }
