import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Input,
  ActionButton,
} from '../../../components'
import { CssGrid } from '../../../configCSS'

export default function ModalRequest(props) {
  const {
    title,
    type,
    request,
    isModalOpen,
    collaborators,
    onClose,
    addNewRequest,
    updateRequestDB,
  } = props

  const [requestToChange, setRequest] = useState({});
  const [requiredValue, setRequiredValue] = useState(false);

  useEffect(() => {
    setRequest(request)
  }, [request])

  const handleChange = (e) => {
    const { name, value } = e.target
    setRequest({
      ...requestToChange,
      [name]: value,
      status: requestToChange.status || 'received',
    })
    if (name === 'id_conseiller_dest') setRequiredValue(false)
  }

  const onResponseYes = () => {
    checkRequiredField()
  }

  const onResponseYesUpdate = () => {
    checkRequiredFieldUpdate()
  }

  const onResponseNo = () => {
    onClose()
  }

  const checkRequiredField = () => {
    const {
      firstname,
      lastname,
      id_conseiller_dest,
    } = requestToChange
    if ((!firstname && !lastname) || !id_conseiller_dest) {
      setRequiredValue(true)
    } else {
      addNewRequest(requestToChange)
    }
  }

  const checkRequiredFieldUpdate = () => {
    const {
      firstname,
      lastname,
      id_conseiller_dest,
    } = requestToChange
    if ((!firstname && !lastname) || !id_conseiller_dest) {
      setRequiredValue(true)
    } else {
      updateRequestDB(requestToChange)
    }
  }

  const readOnlyProps = type === 'create' || type === 'update' ? null : { readOnly: true }

  return (
    <Modal
      aria-labelledby="collaborator-card"
      aria-describedby="collaborator-presentation"
      open={isModalOpen}
      onClose={() => onClose()}
    >
      <div className="request-modal-container modal">
        <h2>{title}</h2>
        <CssGrid
          container
          spacing={2}
        >
          {requestToChange && requestToChange.trip_name && (
            <CssGrid item xs={12}>
              <Input
                id="tripName"
                name="trip_name"
                label="Trip name"
                type="text"
                value={!_.isEmpty(requestToChange) && requestToChange.trip_name ? requestToChange.trip_name : ''}
                // onChange={handleChange}
                InputProps={{
                  readOnly: true,
                }}
                margin="normal"
                variant="outlined"
              />
            </CssGrid>
          )}
          {/*  <CssGrid item xs={6}>
            <Input
              id="lastname"
              name="lastname"
              label="Lastname"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.lastname ? requestToChange.lastname : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
              </CssGrid> */}
          <CssGrid item xs={6}>
            <Input
              id="firstname"
              name="firstname"
              label="Firstanme"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.firstname ? requestToChange.firstname : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={8}>
            <Input
              id="email"
              name="email"
              label="Mail"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.email ? requestToChange.email : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>

          <CssGrid item xs={4}>
            <Input
              id="nb_traveler"
              name="nb_traveler"
              label="Number of Traveler"
              type="number"
              value={!_.isEmpty(requestToChange) && requestToChange.nb_traveler ? requestToChange.nb_traveler : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={4} xs={6}>
            <Input
              id="country"
              name="country"
              label="Country"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.country ? requestToChange.country : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item md={4} xs={6}>
            <Input
              id="duration"
              name="duration"
              label="Duration (day)"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.duration ? requestToChange.duration : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
          <CssGrid item md={4} xs={12}>
            <Input
              id="date_departure"
              name="date_departure"
              label="Date of departure"
              type="date"
              InputProps={{
                ...readOnlyProps,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={!_.isEmpty(requestToChange) && requestToChange.date_departure ? requestToChange.date_departure : ''}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item xs={12}>
            <Input
              id="details"
              name="details"
              label="Details"
              type="text"
              multiline
              minRows={5}
              maxRows={10}
              value={!_.isEmpty(requestToChange) && requestToChange.details ? requestToChange.details : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={6} xs={12}>
            <Input
              id="advisor"
              name="id_conseiller_dest"
              label="Travel agent"
              select
              required
              value={!_.isEmpty(requestToChange) && requestToChange.id_conseiller_dest ? requestToChange.id_conseiller_dest : ''}
              onChange={handleChange}
              InputProps={readOnlyProps}
              margin="normal"
              variant="outlined"
              error={requiredValue}
            >
              {collaborators.map(option => (
                <MenuItem key={option.id_conseiller} value={option.id_conseiller}>
                  {`${option.firstname} ${option.lastname}`}
                </MenuItem>
              ))}
            </Input>
          </CssGrid>
          <CssGrid item md={6} xs={12}>
            <Input
              id="init-by"
              name="initBy"
              label="Request init by"
              type="text"
              value={!_.isEmpty(requestToChange) && requestToChange.mainAdvisor ? requestToChange.mainAdvisor : ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </CssGrid>
        </CssGrid>
        <div className="modalActions">
          {type === 'create' && (
            <ActionButton
              label="Create"
              onClick={onResponseYes}
            />
          )}
          {type === 'update' && (
            <ActionButton
              label="update"
              onClick={onResponseYesUpdate}
            />
          )}
          <ActionButton
            label={type === 'create' ? 'Cancel' : 'close'}
            onClick={onResponseNo}
          />
        </div>
      </div>
    </Modal>
  );
}

ModalRequest.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addNewRequest: PropTypes.func.isRequired,
  updateRequestDB: PropTypes.func.isRequired,
  collaborators: PropTypes.array.isRequired,
};
