import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getRequestStatusListResults,
  getRequestResults,
  putRequestResults,
  putRequestAcceptResults,
  putRequestUpdateResults,
  deleteRequestResults,
} from './actions'


function* getRequestStatusListAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_request_status_list, token)
  yield put(getRequestStatusListResults(data))
}

function* getRequestAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_request, token)
  yield put(getRequestResults(data))
}

function* putRequestAsync(params) {
  const {
    token,
    request,
  } = params
  const data = yield call(api.put_request, token, request)
  yield put(putRequestResults(data))
}

function* putRequestAcceptAsync(params) {
  const {
    token,
    requestId,
  } = params
  const data = yield call(api.put_request_accept, token, requestId)
  yield put(putRequestAcceptResults(data))
}

function* putRequestUpdateAsync(params) {
  const {
    token,
    request,
  } = params
  const data = yield call(api.put_request_update, token, request)
  yield put(putRequestUpdateResults(data))
}

function* deleteRequestAsync(params) {
  const {
    token,
    requestId,
  } = params
  const data = yield call(api.delete_request, token, requestId)
  yield put(deleteRequestResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_REQUEST_STATUS_LIST', getRequestStatusListAsync)
  yield takeEvery('GET_REQUEST', getRequestAsync)
  yield takeEvery('PUT_REQUEST', putRequestAsync)
  yield takeEvery('PUT_REQUEST_ACCEPT', putRequestAcceptAsync)
  yield takeEvery('PUT_REQUEST_UPDATE', putRequestUpdateAsync)
  yield takeEvery('DELETE_REQUEST', deleteRequestAsync)
}

export default fetchResult
