import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash'
import { CssGrid } from '../../../configCSS'
import {
  Template,
  ActionButton,
  Loader,
  BackButton,
  Input,
} from '../../../components'
import { SelectTrip, TravelerCard, TravelerModal } from './component'

class TravelerManagement extends PureComponent {
  static propTypes = {
    GET_LIST_CIRCUIT: PropTypes.func.isRequired,
    GET_ALL_TRAVELER: PropTypes.func.isRequired,
    GET_TRAVELER_LIST: PropTypes.func.isRequired,
    PUT_TRAVELER_CONTENT: PropTypes.func.isRequired,
    DELETE_TRAVELER_CONTENT: PropTypes.func.isRequired,
    RESET_TRAVELER_CONTENT: PropTypes.func.isRequired,
    RESET_TRAVELER_LIST: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    listCircuit: PropTypes.array,
    travelerListByTrip: PropTypes.array,
    travelerList: PropTypes.array,
    putTravelerContent: PropTypes.object,
    deleteTravelerContent: PropTypes.object,
    userAccount: PropTypes.object,
    collaborators: PropTypes.array,
  }

  static defaultProps = {
    isLoading: false,
    listCircuit: [],
    travelerListByTrip: [],
    travelerList: [],
    putTravelerContent: {},
    deleteTravelerContent: {},
    userAccount: {},
    collaborators: [],
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.updateTripSelected = this.updateTripSelected.bind(this)
    this.getTravelerList = this.getTravelerList.bind(this)
    this.deleteTraveler = this.deleteTraveler.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.openTravelerCard = this.openTravelerCard.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addNewTraveler = this.addNewTraveler.bind(this)
    this.putTraveler = this.putTraveler.bind(this)
    this.updateTravelerList = this.updateTravelerList.bind(this)
    this.updateResetDateChanged = this.updateResetDateChanged.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      userId: localStorage.getItem('userId'),
      tripInfo: {},
      travelerListByTrip: [],
      travelerSelected: {},
      travelerIndexSelected: null,
      modalType: '',
      isModalOpen: false,
      token: localStorage.getItem('token'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
      initContent: {},
      source: null,
      filterStartDate: '',
      isDateChanged: false,
    }
  }

  componentDidMount() {
    const {
      history,
      listCircuit,
      GET_LIST_CIRCUIT,
      RESET_TRAVELER_LIST,
    } = this.props
    const {
      token,
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    RESET_TRAVELER_LIST()
    if (_.isEmpty(listCircuit)) GET_LIST_CIRCUIT(token, '2020-01', userId)

    // access from traveler table for editing mode
    if (history.location.state && history.location.state.source) {
      const {
        idTrip,
        source,
      } = history.location.state
      const tripToEdit = listCircuit.filter(d => d.id_devis === idTrip)[0]
      this.setState({
        initContent: tripToEdit,
        source,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      LOADER,
      travelerListByTrip,
      putTravelerContent,
      deleteTravelerContent,
      listCircuit,
    } = this.props
    const {
      isDateChanged,
    } = this.state
    // // traveler saved in DB -> get back updated list
    if (putTravelerContent && putTravelerContent.success) {
      this.reload()
      this.resetValidMsg()
    }
    if (deleteTravelerContent && deleteTravelerContent.success) {
      this.reload()
      this.resetValidMsg()
    }
    if (travelerListByTrip !== prevProps.travelerListByTrip) {
      LOADER(false)
      this.updateTravelerList()
    }
    // stop loader when date filter change and listCircuit is already up to date
    if (listCircuit !== prevProps.listCircuit && isDateChanged) {
      LOADER(false)
      this.updateResetDateChanged()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const {
      tripInfo,
    } = this.state
    const {
      RESET_TRAVELER_CONTENT,
    } = this.props
    this.setState({
      validMsg: 'Travelers were successfully recorded !',
    })
    RESET_TRAVELER_CONTENT()
    this.getTravelerList(tripInfo.id_devis)
  }

  // *******************************
  // Get list of travelers

  getTravelerList = (idTrip) => {
    const {
      GET_TRAVELER_LIST,
      GET_ALL_TRAVELER,
    } = this.props
    const {
      token,
    } = this.state
    GET_ALL_TRAVELER(token)
    GET_TRAVELER_LIST(token, idTrip)
  }

  updateTravelerList = () => {
    const { travelerListByTrip } = this.props
    this.setState({
      travelerListByTrip,
    })
  }

  updateResetDateChanged = () => {
    this.setState({
      isDateChanged: false,
    })
  }
  // *******************************

  updateTripSelected = (tripSelected) => {
    const {
      RESET_TRAVELER_LIST,
      LOADER,
    } = this.props
    this.setState({
      tripInfo: tripSelected,
    })
    if (!_.isEmpty(tripSelected)) {
      LOADER(true)
      this.getTravelerList(tripSelected.id_devis)
    } else RESET_TRAVELER_LIST()
  }

  openTravelerCard = (action, idTraveler, travelerIndex) => {
    const { travelerListByTrip } = this.state
    this.setState({
      travelerSelected: travelerListByTrip[travelerIndex],
      travelerIndexSelected: travelerIndex,
      modalType: action,
      isModalOpen: true,
    })
  }

  // *******************************
  // Delete
  deleteTraveler = (idTraveler, indexTraveler) => {
    const {
      token,
      tripInfo,
      travelerListByTrip,
    } = this.state
    const { DELETE_TRAVELER_CONTENT } = this.props
    if (confirm('Do you really want to delete this traveler ?')) {
      const newList = _.cloneDeep(travelerListByTrip)
      newList.splice(indexTraveler, 1)

      this.setState({
        travelerListByTrip: newList,
        validateError: false,
        errorMsg: null,
      })
      if (idTraveler) {
        DELETE_TRAVELER_CONTENT(token, tripInfo.id_devis, idTraveler)
      }
    }
  }

  // *******************************
  // Modal

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  // *******************************
  // Validate

  addNewTraveler = () => {
    const {
      tripInfo,
      travelerListByTrip,
    } = this.state
    this.setState({
      travelerSelected: {
        id_devis: tripInfo.id_devis,
        nom_devis: tripInfo.nom_devis,
      },
      travelerIndexSelected: travelerListByTrip.length,
      modalType: 'add',
      isModalOpen: true,
    })
  }

  putTraveler = (trav) => {
    const {
      token,
      travelerListByTrip,
      travelerIndexSelected,
    } = this.state
    const {
      travelerList,
    } = this.props
    const checkExistTrav = travelerListByTrip.filter(t => t.id_traveler === trav.id_traveler)
    const keyCheckList = travelerList.map(t => (t.lastname + t.firstname + t.date_birth))
    const keyCheckTrav = trav.lastname + trav.firstname + (trav.date_birth ? trav.date_birth : null)
    const checkExistTravUpdate = keyCheckList.filter(t => t === keyCheckTrav)
    const {
      PUT_TRAVELER_CONTENT,
    } = this.props
    if (travelerIndexSelected < travelerListByTrip.length) {
      this.setState({
        travelerListByTrip: travelerListByTrip.map(t => (t.id_traveler === trav.id_traveler ? Object.assign({}, t, trav) : t)),
      })
      PUT_TRAVELER_CONTENT(token, trav, 'update')
    } else if (!_.isEmpty(checkExistTrav)) {
      alert('this traveler is already on the list!')
    } else if (!_.isEmpty(checkExistTravUpdate) && !trav.id_traveler) {
      alert('this traveler is already on the traveler database!')
    } else {
      this.setState({
        travelerListByTrip: [
          ...travelerListByTrip,
          trav,
        ],
      })
      trav.nb_voyage = checkExistTravUpdate.length + 1
      PUT_TRAVELER_CONTENT(token, trav, 'create')
    }

    this.closeModal()
  }

  goBack = () => {
    const { history } = this.props
    const { source } = this.state
    if (source) history.push(source)
  }

  // manage filter date
  handleChange = (e) => {
    const {
      value, name,
    } = e.target

    const {
      token,
      filterStartDate,
      filterAdvisor,
      userId,
    } = this.state
    const {
      GET_LIST_CIRCUIT,
      LOADER,
    } = this.props

    this.setState({
      [name]: value,
    })
    if (name === 'filterStartDate') {
      GET_LIST_CIRCUIT(token, value || '2020-01', filterAdvisor || userId)
      LOADER(true)
      this.setState({
        isDateChanged: true,
      })
    }

    if (name === 'filterAdvisor') {
      GET_LIST_CIRCUIT(token, filterStartDate || '2020-01', value || userId)
      LOADER(true)
      this.setState({
        isDateChanged: true,
      })
    }
  }


  render() {
    const {
      tripInfo,
      travelerSelected,
      travelerIndexSelected,
      travelerListByTrip,
      modalType,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      initContent,
      filterStartDate,
      filterAdvisor,
    } = this.state
    const {
      isLoading,
      listCircuit,
      userAccount,
      travelerList,
      collaborators,
    } = this.props
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">

            {validMsg ? <div className="validMsg">{validMsg}</div> : null}
            {isLoading ? <Loader /> : <div className="loader-null" />}
            <div>
              <CssGrid
                container
                spacing={1}
              >
                <CssGrid item md="auto" xs={12}>
                  <h3 className="Question">Quote created on :</h3>
                </CssGrid>
                <CssGrid item md={3} xs={12}>
                  <Input
                    type="month"
                    name="filterStartDate"
                    value={filterStartDate || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => this.handleChange(e)}
                    label="date from"
                    margin="normal"
                    variant="outlined"
                  />

                </CssGrid>
                <CssGrid item md={3} xs={12}>
                  <Input
                    id="advisor"
                    label="advisor"
                    name="filterAdvisor"
                    select
                    margin="normal"
                    variant="outlined"
                    value={filterAdvisor}
                    className="largeWidth"
                    onChange={e => this.handleChange(e)}
                      // eslint-disable-next-line react/jsx-closing-bracket-location
            >
                    {collaborators && _.map(collaborators, (collab, k) => (
                      <MenuItem key={k} value={collab.id_conseiller}>{collab.lastname} {collab.firstname}</MenuItem>
                    ))}
                    <menuItem />
                  </Input>
                </CssGrid>

              </CssGrid>
            </div>
            <div className="travelerContainer">
              <h2>TRAVELERS BY TRIP</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <BackButton
                type="button"
                label="Back"
                goBack={() => this.goBack()}
              />
              <SelectTrip
                field="trip"
                initContent={initContent}
                list={listCircuit}
                updateTripSelected={tripSelected => this.updateTripSelected(tripSelected)}
              />
              <div className="dividerStepper half" />

              <ActionButton
                label="Add traveler"
                disabled={_.isEmpty(tripInfo)}
                onClick={() => this.addNewTraveler()}
              />
              <div className="travelers-list">
                {!_.isEmpty(travelerListByTrip) && travelerListByTrip.map((t, index) => (
                  <TravelerCard
                    key={t.id_traveler || t.id_devis}
                    traveler={t}
                    travelerIndex={index}
                    openTravelerCard={(action, idTraveler, travelerIndex) => this.openTravelerCard(action, idTraveler, travelerIndex)}
                    deleteTraveler={(idTraveler, indexTraveler) => this.deleteTraveler(idTraveler, indexTraveler)}
                  />
                ))}
              </div>
            </div>
            {!_.isEmpty(travelerSelected) && (
            <TravelerModal
              traveler={travelerSelected}
              travelerList={travelerList}
              index={travelerIndexSelected}
              type={modalType}
              isModalOpen={isModalOpen}
              onModalClose={() => this.closeModal()}
              putTraveler={trav => this.putTraveler(trav)}
              userAccount={userAccount}
            />
            )}
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  listCircuit: state.api.listCircuit.filter(l => ['confirmed', 'completed'].indexOf(l.status) > -1),
  travelerListByTrip: state.travelerReducer.travelerListByTrip,
  travelerList: state.travelerReducer.travelerList,
  putTravelerContent: state.travelerReducer.putTravelerContent,
  deleteTravelerContent: state.travelerReducer.deleteTravelerContent,
  userAccount: state.userAccountReducer.userAccount,
  collaborators: state.collaboratorsReducer.collaborators,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    filterAdvisor,
  }),
  GET_ALL_TRAVELER: token => dispatch({
    type: 'GET_ALL_TRAVELER',
    token,
  }),
  GET_TRAVELER_LIST: (token, idTrip) => dispatch({
    type: 'GET_TRAVELER_LIST',
    token,
    idTrip,
  }),
  PUT_TRAVELER_CONTENT: (token, traveler, action) => dispatch({
    type: 'PUT_TRAVELER_CONTENT',
    token,
    traveler,
    action,
  }),
  DELETE_TRAVELER_CONTENT: (token, idTrip, idTraveler) => dispatch({
    type: 'DELETE_TRAVELER_CONTENT',
    token,
    idTrip,
    idTraveler,
  }),
  RESET_TRAVELER_CONTENT: () => dispatch({
    type: 'RESET_TRAVELER_CONTENT',
  }),
  RESET_TRAVELER_LIST: () => dispatch({
    type: 'RESET_TRAVELER_LIST',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TravelerManagement))
