
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  Template,
  DevExperiment,
} from '../../components'


// import GenerateHtmlDuyen from '../../components'


class Publicview extends PureComponent {
  static propTypes = {

    match: {
      params: {
        id: Number,
      },
    },

  }

  static defaultProps = {

    match: {
      params: {
        id: 1,
      },
    },
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (

      <div>
        <h2>{this.props.match.params.id}</h2>

        <div>toto</div>
        <div className="container">
          <Template>
            <div className="requestContainer">
              titi
            </div>
            <DevExperiment />
          </Template>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  tripDetails: state.api.tripDetails,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  userAccount: state.userAccountReducer.userAccount,
  request: state.requestReducer.request,
  tripContent: state.api.tripContent,
})

const mapDispatchToProps = dispatch => ({
  GET_TRIP_CONTENT: idTourSelected => dispatch({
    type: 'GET_TRIP_CONTENT',
    idTourSelected,
  }),
  GET_TRIP_DETAILS: (token, idTripSelected) => dispatch({
    type: 'GET_TRIP_DETAILS',
    token,
    idTripSelected,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    filterAdvisor,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_TRIP_ALLDAYS: (tripDaysList, idCircuit) => dispatch({
    type: 'GET_TRIP_ALLDAYS',
    tripDaysList,
    idCircuit,
  }),
  GET_TRIP_ALLDAYS_ACCOMODATION: (tripDaysList, tripAccoList) => dispatch({
    type: 'GET_TRIP_ALLDAYS_ACCOMODATION',
    tripDaysList,
    tripAccoList,
  }),
  VALIDATE_STEP: (page, value, progression) => dispatch({
    type: 'VALIDATE_STEP',
    page,
    value,
    progression,
  }),
  ID_TRIP_SELECT: (idTripSelected, isNewTrip, isDetailModified, isDuplicatedTrip) => dispatch({
    type: 'ID_TRIP_SELECT',
    idTripSelected,
    isNewTrip,
    isDetailModified,
    isDuplicatedTrip,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Publicview))
