import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import stage from '../../../config'

const savedImgUrl = stage.imgStorage

export default function ModalCard(props) {
  const {
    collaborator,
    isModalOpen,
    onClose,
  } = props;
  const {
    firstname,
    lastname,
    image,
    company,
    profil_utilisateur,
    phone,
    email,
    presentation,
  } = collaborator

  const avatar = image || 'default-avatar.jpg'

  const extractContent = (text) => {
    const textFormated = text.replaceAll('<p>', '').split('</p>')
    return textFormated
  };


  return (
    <Modal
      aria-labelledby="collaborator-card"
      aria-describedby="collaborator-presentation"
      open={isModalOpen}
      onClose={() => onClose()}
    >
      <div id="collaborator_card" className="collaborator_card_container modal">
        <img src={`${savedImgUrl}/${company}/${avatar}`} alt={`avatar-${lastname}`} />
        <h2>{firstname} {lastname}</h2>
        <p>{profil_utilisateur}</p>
        {phone && <p>{phone}</p>}
        {email && <p>{email}</p>}
        {presentation && <div style={{ textAlign: 'center' }}>{extractContent(presentation).map(p => <p>{p}</p>)}</div>}
      </div>
    </Modal>
  );
}

ModalCard.propTypes = {
  collaborator: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
