const travelersColumns = [
  {
    name: 'id_demandeur',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'demandeur',
    label: 'Client',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'prenom_responsable_groupe',
    label: 'Firstname',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'nom_responsable_groupe',
    label: 'Lastname',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'e_mail',
    label: 'Mail',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'telephone',
    label: 'Phone',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'society',
    label: 'Society',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'trip_number',
    label: 'Trip number',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'Travel agent',
    label: 'Advisor',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'date_creation',
    label: 'Date creation',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
]

export default travelersColumns
