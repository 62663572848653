import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {

  put_trip_details_site(token, idTour, siteDetails) {
    return axios.put(`${apiUrl}/api/tripDetails/site/${idTour}`, { siteDetails }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
}
