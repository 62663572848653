/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import {
  Template,
  ActionButton,
  TextEditor,
  Uploader,
  Input,
} from '../../../components'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage
const secondary = '#0bcdd6'
const CssGrid = withStyles({
  root: {
    '& .MuiFormControl-root': {
      display: 'grid',
    },
  },
})(Grid);

const CSSRadio = withStyles({
  root: {
    color: secondary,
    '&$checked': {
      color: secondary,
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

class Informations extends PureComponent {
  static propTypes = {
    GET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    PUT_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    RESET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    informationsContent: PropTypes.object,
    userAccount: PropTypes.object,
    tripDetails: PropTypes.array,
  }

  static defaultProps = {
    informationsContent: {},
    userAccount: {},
    tripDetails: [],
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.displayInformationsContentInputs = this.displayInformationsContentInputs.bind(this)
    this.displayTemplateQuote = this.displayTemplateQuote.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayQuoteDetails = this.displayQuoteDetails.bind(this)
    this.displayBlockInformations = this.displayBlockInformations.bind(this)
    this.validate = this.validate.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.state = {
      informationsContent: {},
      hasChangedInformationsContent: false,
      userAccount: {},
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      validMsg: null,
      errorMsg: null,
      isDisplayedBlockInfo1: false,
      isDisplayedBlockInfo2: false,
      isDisplayedBlockInfo3: false,
      isDisplayedBlockInfo4: false,
      isDisplayedBlockInfo5: false,
      newImageArray_information_1_image: [],
      newURLArray_information_1_image: [],
      newImageArray_information_2_image: [],
      newURLArray_information_2_image: [],
      newImageArray_information_3_image: [],
      newURLArray_information_3_image: [],
      newImageArray_information_4_image: [],
      newURLArray_information_4_image: [],
      newImageArray_information_5_image: [],
      newURLArray_information_5_image: [],
      newImageArray_cover_image: [],
      newURLArray_cover_image: [],
      newImageArray_header_image: [],
      newURLArray_header_image: [],
      isValidated: false,
    }
  }

  componentDidMount() {
    const {
      history,
      informationsContent,
      userAccount,
      tripDetails,
      GET_USER_ACCOUNT,
      GET_INFORMATIONS_CONTENT,
    } = this.props
    const {
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // check if props exists
    if (!_.isEmpty(informationsContent)) {
      // come back from 'Gallery'
      if (history.location.state) {
        const {
          state,
          currentImg,
          pictureSelected,
        } = history.location.state
        this.setState({
          informationsContent: {
            ...state.item,
            [state.informationImage]: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          actionLabel: 'Modify',
          isValidated: false,
        })
      } else {
        this.setState({
          informationsContent,
        })
      }
    } else {
      GET_INFORMATIONS_CONTENT(userId)
    }
    if (!_.isEmpty(userAccount)) {
      this.setState({
        userAccount,
      })
    } else {
      GET_USER_ACCOUNT(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    // const {
    //   informationsContent: informationsContentOld,
    // } = this.state
    const {
      informationsContent: informationsContentOld,
      userAccount: userAccountOld,
    } = this.props
    const {
      history,
      informationsContent,
      putInformationsContent,
      userAccount,
    } = nextprops
    // Content saved in DB -> get back updated content
    if (putInformationsContent && putInformationsContent.success) {
      this.reload()
      this.setState({
        validMsg: 'Modification was successfully recorded !',
        hasChangedInformationsContent: false,
        isValidated: false,
      })
      this.resetValidMsg()
    }
    if (putInformationsContent && putInformationsContent.error) {
      this.setState({
        errorMsg: putInformationsContent.error,
        hasChangedInformationsContent: false,
        isValidated: false,
      })
      this.resetValidMsg()
    }
    if (informationsContent !== informationsContentOld) {
      this.setState({ informationsContent })
    }
    if (userAccount !== userAccountOld) {
      this.setState({ userAccount })
    }
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
        errorMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const {
      userId,
    } = this.state
    const {
      RESET_INFORMATIONS_CONTENT,
      GET_INFORMATIONS_CONTENT,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
    } = this.props
    RESET_INFORMATIONS_CONTENT()
    ROW_TRIP_SELECT()
    RESET_TRIP_INFORMATION()
    GET_INFORMATIONS_CONTENT(userId)
  }

  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = (field) => {
    const {
      informationsContent,
    } = this.state
    const { company } = informationsContent

    const newURLArray = this.state[`newURLArray_${field}`]
    const newImageArray = this.state[`newImageArray_${field}`]
    const image = informationsContent[`${field}`]

    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company.toLowerCase()}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray, field) => {
    this.setState({
      [`newImageArray_${field}`]: imagesDataArray.map(img => img.imageName),
      [`newURLArray_${field}`]: imagesDataArray.map(img => img.imageUrl),
      hasChangedInformationsContent: true,
    });
  }

  deleteImage = (urlArray, imageArray, field) => {
    const { informationsContent } = this.state
    this.setState({
      informationsContent: {
        ...informationsContent,
        [field]: imageArray.join(),
      },
      [`newImageArray_${field}`]: imageArray,
      [`newURLArray_${field}`]: urlArray,
      hasChangedInformationsContent: true,
    })
  }

  redirectToGallery = (informationImage) => {
    const {
      informationsContent,
      roomTypeList,
    } = this.state
    const {
      history,
    } = this.props
    // this.validate()
    this.setState({
      isValidated: true,
    }, () => {
      history.push('gallery', {
        currentPage: 'informations',
        isSelectionAction: true,
        nbMaxImg: 1,
        currentImg: informationsContent[`${informationImage}`] ? informationsContent[`${informationImage}`].split(',') : [],
        state: {
          item: informationsContent,
          informationImage: `${informationImage}`,
        },
      })
    })
  }

  // ******************************* HANDLE CHANGE ******************
  // save all new informations for this user
  handleChange = field => (event) => {
    const { informationsContent } = this.state
    this.setState({
      informationsContent: {
        ...informationsContent,
        [field]: event.target.value,
      },
      [`${field}Error`]: false,
      validateError: false,
      errorMsg: null,
      hasChangedInformationsContent: true,
    })
  }

  // On change Editor
  handleEditorChange = field => (content) => {
    const { informationsContent } = this.state
    this.setState({
      informationsContent: {
        ...informationsContent,
        [field]: content,
      },
      validateError: false,
      errorMsg: null,
    });
  }


  // change template
  handleRadioChange = (event) => {
    const { informationsContent } = this.state
    this.setState({
      informationsContent: {
        ...informationsContent,
        theme_devis: event.target.value,
      },
    })
  }

  // ********************** DISPLAY ELEMENT **************************
  // Editor
  displayTextEditor = (field) => {
    const { informationsContent } = this.state
    return (
      <TextEditor
        object={informationsContent}
        value={informationsContent[field]}
        handleEditorChange={this.handleEditorChange(field)}
      />
    )
  }

  displayTemplateQuote = (profilAllowed) => {
    const { informationsContent } = this.state
    const {
      company,
      liste_theme,
      theme_devis,
    } = informationsContent
    const listeThemeArray = liste_theme ? liste_theme.split(',') : []
    if (company && theme_devis) {
      return (
        <FormControl component="fieldset" className="template-container" disabled={!profilAllowed}>
          <RadioGroup className="radio-group-template" aria-label="template" name="template" value={theme_devis} onChange={this.handleRadioChange}>
            {listeThemeArray.map((t, i) => (
              <div key={`template${t}`} className="template-div">
                <img src={`${apiUrl}/images/template-${t}.png`} />
                <FormControlLabel value={t} control={<CSSRadio />} label={t} />
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      )
    }
    return null
  }

  displayQuoteDetails = (profilAllowed) => {
    const {
      informationsContent,
    } = this.state
    const theme = informationsContent.theme_devis
    const color = informationsContent.main_color
    return (
      <div className="quote-upload-div">

        <div className="quote-details-div">
          <p className="quote-image-label">Main color: </p>
          <input
            id="color"
            type="color"
            value={color || '#0bcdd6'}
            className="colorInput"
            onChange={this.handleChange('main_color')}
            disabled={!profilAllowed}
          />
        </div>
      </div>
    )
  }

  // display 5 blocks for various informations
  displayBlockInformations = (i) => {
    const {
      informationsContent,
      [`isDisplayedBlockInfo${i}`]: isDisplayedBlockInfo,
      userAccount,
    } = this.state
    const informationName = userAccount.language ? `${userAccount.language}_information_${i}` : `fr_information_${i}`
    const informationDescription = userAccount.language ? `${userAccount.language}_information_${i}_description` : `fr_information_${i}_description`
    const informationLabel = `information_${i}`
    const informationImage = `information_${i}_image`
    return (
      <div key={`blockInfo${i}`}>
        <Input
          id={informationLabel}
          label={_.capitalize(informationLabel)}
          value={!_.isEmpty(informationsContent) && informationsContent[informationName] ? informationsContent[informationName] : ''}
          onChange={this.handleChange(informationName)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="openDescButton">
                <KeyboardArrowDownOutlinedIcon
                  onClick={() => this.setState({ [`isDisplayedBlockInfo${i}`]: !isDisplayedBlockInfo })}
                />
              </InputAdornment>
            ),
          }}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        {/* hide info description */}
        {
          !isDisplayedBlockInfo
            ? null
            : (
              <CssGrid
                container
                spacing={2}
                className={`blockInfo${i}`}
              >
                <CssGrid item md={9} xs={12}>
                  <div className="editorStyle">
                    <p className="editorTitle background-white">Description</p>
                    {this.displayTextEditor(informationDescription)}
                  </div>
                </CssGrid>
                {/* Uploader */}
                <CssGrid item md={3} xs={12} className="logo-company-div">
                  <p className="logo-label">Image</p>
                  <Uploader
                    imageDataArray={this.getImageUrl(informationImage)}
                    saveImage={imagesDataArray => this.saveImage(imagesDataArray, informationImage)}
                    redirectToGallery={() => this.redirectToGallery(informationImage)}
                    deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray, informationImage)}
                    allowDrop
                  />
                </CssGrid>
              </CssGrid>
            )
          }
      </div>
    )
  }

  // return all other input
  displayInformationsContentInputs = () => {
    const {
      informationsContent,
      userAccount,
    } = this.state
    const { profil } = userAccount
    const profilAllowed = profil < 3
    const theme = informationsContent.theme_devis
    const exchangeRate = userAccount.language ? `${userAccount.language}_taux_change` : 'fr_taux_change'
    const accomodationPresentation = userAccount.language ? `${userAccount.language}_hebergement` : 'fr_hebergement'
    const remark = userAccount.language ? `${userAccount.language}_remarque` : 'fr_remarque'
    const invoice = userAccount.language ? `${userAccount.language}_invoice` : 'fr_invoice'

    return (
      <div>
        {/* quote */}
        <p className="template-title">Quote Template</p>
        {this.displayTemplateQuote(profilAllowed)}
        {this.displayQuoteDetails(profilAllowed)}
        <p className="template-title">General Informations</p>
        <Input
          id="change"
          label="Exchange Rate"
          value={!_.isEmpty(informationsContent) && informationsContent[exchangeRate] ? informationsContent[exchangeRate] : ''}
          onChange={this.handleChange(exchangeRate)}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        {/* hebergement */}
        {['prenium', 'duyen-template'].includes(theme)
          ? (
            <div className="editorStyle">
              <p className="editorTitle background-white">Accomodation</p>
              {this.displayTextEditor(accomodationPresentation)}
            </div>
          )
          : null
        }
        {/* display 5 block of informations */}
        {
          [1, 2, 3, 4, 5].map(info => this.displayBlockInformations(info))
        }
        {/* remarks */}
        <div className="editorStyle">
          <p className="editorTitle background-white">Remarks</p>
          {this.displayTextEditor(remark)}
        </div>
        {/* invoice */}
        <div className="editorStyle">
          <p className="editorTitle background-white">Hotel description</p>
          {this.displayTextEditor(invoice)}
        </div>
      </div>
    )
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      token,
      informationsContent,
      newImageArray_information_1_image,
      newImageArray_information_2_image,
      newImageArray_information_3_image,
      newImageArray_information_4_image,
      newImageArray_information_5_image,
      newImageArray_cover_image,
      newImageArray_header_image,
    } = this.state
    const {
      userAccount,
      PUT_INFORMATIONS_CONTENT,
    } = this.props

    // save images
    const newInformationsContent = {
      ...informationsContent,
      id_company: userAccount.id_company,
      cover_image: newImageArray_cover_image.join() || informationsContent.cover_image,
      header_image: newImageArray_header_image.join() || informationsContent.header_image,
      information_1_image: newImageArray_information_1_image.join() || informationsContent.information_1_image,
      information_2_image: newImageArray_information_2_image.join() || informationsContent.information_2_image,
      information_3_image: newImageArray_information_3_image.join() || informationsContent.information_3_image,
      information_4_image: newImageArray_information_4_image.join() || informationsContent.information_4_image,
      information_5_image: newImageArray_information_5_image.join() || informationsContent.information_5_image,
    }
    this.setState({
      isValidated: true,
    })
    if (!informationsContent.id_devis_informations) {
      PUT_INFORMATIONS_CONTENT(token, newInformationsContent, 'create')
    } else {
      PUT_INFORMATIONS_CONTENT(token, newInformationsContent, 'update')
    }
  }

  render() {
    const {
      hasChangedInformationsContent,
      validMsg,
      errorMsg,
      isValidated,
      // informationsContent,
    } = this.state
    const { userAccount } = this.props

    const isUserAllowed = !_.isEmpty(userAccount) && userAccount.profil < 3

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>GENERAL INFORMATIONS</h2>
              {userAccount.profil > 2 && (
                <p className="profil-authorization" style={{ marginTop: '10px' }}>You are not allowed to modify general information</p>
              )}
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayInformationsContentInputs()}
            </div>
            <ActionButton
              label="Validate"
              onClick={e => this.validate(e)}
              disabled={!isUserAllowed}
            />
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={hasChangedInformationsContent && !isValidated && isUserAllowed}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  informationsContent: state.informationsReducer.informationsContent,
  putInformationsContent: state.informationsReducer.putInformationsContent,
  userAccount: state.userAccountReducer.userAccount,
  tripDetails: state.api.tripDetails,
})

const mapDispatchToProps = dispatch => ({
  GET_INFORMATIONS_CONTENT: userId => dispatch({
    type: 'GET_INFORMATIONS_CONTENT',
    userId,
  }),
  PUT_INFORMATIONS_CONTENT: (token, informationsContent, action) => dispatch({
    type: 'PUT_INFORMATIONS_CONTENT',
    token,
    informationsContent,
    action,
  }),
  RESET_INFORMATIONS_CONTENT: () => dispatch({
    type: 'RESET_INFORMATIONS_CONTENT',
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Informations))
