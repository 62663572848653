import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Template,
  ActionButton,
  Loader,
} from '../../components'
import {
  RequestTable,
  ModalRequest,
} from './component'
import {
  allRequestColumns,
  pendingRequestColumns,
} from './configComponent/columns'

class Request extends PureComponent {
  static propTypes = {
    GET_REQUEST: PropTypes.func.isRequired,
    GET_REQUEST_STATUS_LIST: PropTypes.func.isRequired,
    GET_COLLABORATORS: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    PUT_REQUEST: PropTypes.func.isRequired,
    PUT_REQUEST_ACCEPT: PropTypes.func.isRequired,
    PUT_REQUEST_UPDATE: PropTypes.func.isRequired,
    DELETE_REQUEST: PropTypes.func.isRequired,
    RESET_REQUEST: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    request: PropTypes.array,
    requestStatusList: PropTypes.array,
    collaborators: PropTypes.array,
    putRequest: PropTypes.object,
    putRequestAccept: PropTypes.object,
    putRequestUpdate: PropTypes.object,
    deleteRequest: PropTypes.object,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    request: [],
    requestStatusList: [],
    collaborators: [],
    putRequest: {},
    putRequestAccept: {},
    putRequestUpdate: {},
    deleteRequest: {},
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.displayValidMessage = this.displayValidMessage.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateRequest = this.updateRequest.bind(this)
    this.openEditRequest = this.openEditRequest.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.addNewRequest = this.addNewRequest.bind(this)
    this.updateRequestDB = this.updateRequestDB.bind(this)
    this.deleteRequest = this.deleteRequest.bind(this)
    this.showRequest = this.showRequest.bind(this)
    this.editRequest = this.editRequest.bind(this)
    this.acceptRequest = this.acceptRequest.bind(this)
    this.isUserAuthorized = this.isUserAuthorized.bind(this)
    this.divTable = React.createRef()
    this.initialTableWidth = this.divTable.current ? this.divTable.current.offsetWidth : 0
    this.state = {
      request: [],
      pendingRequest: [],
      requestSelected: {},
      isModalOpen: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isValidated: true,
      validMsg: '',
      errorMsg: '',
      isLoading: false,
      modalTitle: '',
      modalType: 'create',
    }
  }

  componentDidMount() {
    const {
      history,
      request,
      requestStatusList,
      collaborators,
      userAccount,
      GET_REQUEST,
      GET_REQUEST_STATUS_LIST,
      GET_COLLABORATORS,
      GET_USER_ACCOUNT,
    } = this.props
    const {
      userId,
      token,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }

    // Resize table with
    // fix bug on second mount
    this.updateDimensions()
    // this event listener allows resizing table price
    window.addEventListener('resize', this.updateDimensions)

    // check if props exists
    if (!_.isEmpty(request)) {
      this.updateRequest(request)
    } else {
      GET_REQUEST(token)
    }
    if (_.isEmpty(requestStatusList)) {
      GET_REQUEST_STATUS_LIST(token)
    }
    if (_.isEmpty(collaborators)) {
      GET_COLLABORATORS(token)
    }
    if (_.isEmpty(userAccount)) {
      GET_USER_ACCOUNT(userId)
    }
  }

  componentDidUpdate(previousProps) {
    const {
      token,
    } = this.state
    const {
      request,
      putRequest,
      putRequestAccept,
      putRequestUpdate,
      deleteRequest,
      RESET_REQUEST,
      GET_REQUEST,
    } = this.props

    if (request !== previousProps.request) {
      this.updateRequest(request)
    }
    if (putRequest && putRequest.success) {
      RESET_REQUEST()
      GET_REQUEST(token)
      this.displayValidMessage()
      this.resetValidMsg()
    }
    if (putRequestAccept && putRequestAccept.success) {
      RESET_REQUEST()
      GET_REQUEST(token)
      this.displayValidMessage()
      this.resetValidMsg()
    }
    if (putRequestUpdate && putRequestUpdate.success) {
      RESET_REQUEST()
      GET_REQUEST(token)
      this.displayValidMessage()
      this.resetValidMsg()
    }
    if (deleteRequest && deleteRequest.success) {
      RESET_REQUEST()
      GET_REQUEST(token)
      this.displayValidMessage()
      this.resetValidMsg()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // fit div Table Price when window is resized
  updateDimensions = () => {
    this.setState({
      widthTable: window.innerWidth - 220,
    })
  }

  updateRequest = (request) => {
    const { userId } = this.state
    const { userAccount } = this.props
    const { profil } = userAccount
    const isUser = profil > 2
    const pendingRequest = _.cloneDeep(request).filter((r) => {
      if (isUser) return r.id_conseiller_dest === parseInt(userId, 10) && (r.status === 'received' || r.trip_name) && !r.date_accept
      return r.status === 'received' || (r.trip_name && !r.date_accept)
    })
    const requestFiltredByUser = _.cloneDeep(request).filter((r) => {
      if (isUser) return r.id_conseiller_dest === parseInt(userId, 10)
      return r
    })
    this.setState({
      request: requestFiltredByUser,
      pendingRequest,
    })
  }

  displayValidMessage = () => {
    this.setState({
      errorMsg: '',
      validMsg: 'Modification was successfully recorded !',
      isValidated: true,
    })
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  // ********************************
  // Modal
  openEditRequest = () => {
    const { userId } = this.state
    const { collaborators } = this.props
    const mainAdvisor = collaborators.filter(c => c.id_conseiller === parseInt(userId, 10))[0]
    this.setState({
      requestSelected: { mainAdvisor: `${mainAdvisor.firstname || ''} ${mainAdvisor.lastname || ''}` },
      modalTitle: 'Create your request',
      modalType: 'create',
      isModalOpen: true,
    })
  }

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  // **********************************
  // Action on table
  addNewRequest = (newRequest) => {
    const {
      pendingRequest,
      token,
    } = this.state
    const { PUT_REQUEST } = this.props
    this.modalClose()
    this.setState({
      pendingRequest: [
        ...pendingRequest,
        newRequest,
      ],
    })
    PUT_REQUEST(token, newRequest)
  }

  updateRequestDB = (newRequest) => {
    const {
      pendingRequest,
      token,
    } = this.state
    const { PUT_REQUEST_UPDATE } = this.props
    this.modalClose()
    this.setState({
      pendingRequest: [
        ...pendingRequest,
        newRequest,
      ],
    })
    PUT_REQUEST_UPDATE(token, newRequest)
  }

  deleteRequest = (requestDeleted) => {
    const {
      token,
      // request,
    } = this.state
    const {
      DELETE_REQUEST,
    } = this.props
    if (confirm(`You want to delete request for : ${requestDeleted.firstname} ${requestDeleted.lastname}`)) {
      // const newRequestList = request.filter(r => r.id_request !== requestDeleted.id_request)
      // this.updateRequest(newRequestList)
      DELETE_REQUEST(token, requestDeleted.id_request)
    }
  }

  showRequest = (id) => {
    const {
      request,
    } = this.state
    this.setState({
      requestSelected: request.filter(r => r.id_request === id)[0],
      modalTitle: 'Request',
      modalType: 'show',
    })
    setTimeout(() => {
      this.setState({
        isModalOpen: true,
      })
    }, 500);
  }

  editRequest = (id) => {
    const {
      request,
    } = this.state
    this.setState({
      requestSelected: request.filter(r => r.id_request === id)[0],
      modalTitle: 'Request',
      modalType: 'update',
    })
    setTimeout(() => {
      this.setState({
        isModalOpen: true,
      })
    }, 500);
  }

  acceptRequest = (id) => {
    const { token } = this.state
    const { PUT_REQUEST_ACCEPT } = this.props
    PUT_REQUEST_ACCEPT(token, id)
  }

  // ******************************
  isUserAuthorized = () => {
    const { userAccount } = this.props
    const { profil } = userAccount
    return profil < 3
  }

  render() {
    const {
      userId,
      pendingRequest,
      request,
      requestSelected,
      isModalOpen,
      validMsg,
      errorMsg,
      isValidated,
      isLoading,
      modalTitle,
      modalType,
      widthTable,
    } = this.state
    const {
      collaborators,
      userAccount,
    } = this.props

    if (this.divTable.current && !this.initialTableWidth) this.initialTableWidth = this.divTable.current.offsetWidth

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>REQUEST</h2>
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              {request && (
                <div ref={this.divTable} style={{ width: '100%' }}>
                  <RequestTable
                    tableName="pendingRequest"
                    request={pendingRequest}
                    columns={pendingRequestColumns}
                    title="Pending Request"
                    widthDiv={widthTable || this.initialTableWidth}
                    deleteRequest={requestDeleted => this.deleteRequest(requestDeleted)}
                    showRequest={requestId => this.showRequest(requestId)}
                    editRequest={requestId => this.editRequest(requestId)}
                    acceptRequest={requestId => this.acceptRequest(requestId)}
                    isUserAuthorized={this.isUserAuthorized()}
                    userId={parseInt(userId, 10)}
                    pageSize={5}
                  />
                </div>
              )}
              <ActionButton
                label="Create Request"
                onClick={this.openEditRequest}
                disabled={!_.isEmpty(userAccount) && userAccount.profil > 2}
              />
              <div style={{ marginBottom: '35px' }} />
              {request && (
                <div ref={this.divTable} style={{ width: '100%' }}>
                  <RequestTable
                    tableName="allRequest"
                    request={request}
                    columns={allRequestColumns}
                    title="All Request"
                    widthDiv={widthTable || this.initialTableWidth}
                    deleteRequest={requestDeleted => this.deleteRequest(requestDeleted)}
                    showRequest={requestId => this.showRequest(requestId)}
                    editRequest={requestId => this.editRequest(requestId)}
                    isUserAuthorized={this.isUserAuthorized()}
                    userId={parseInt(userId, 10)}
                    pageSize={5}
                  />
                </div>
              )}
            </div>
            {request && (
              <ModalRequest
                request={requestSelected}
                title={modalTitle}
                type={modalType}
                collaborators={collaborators}
                isModalOpen={isModalOpen}
                onClose={() => this.modalClose()}
                addNewRequest={req => this.addNewRequest(req)}
                updateRequestDB={req => this.updateRequestDB(req)}

              />
            )}
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  request: state.requestReducer.request,
  putRequest: state.requestReducer.putRequest,
  putRequestAccept: state.requestReducer.putRequestAccept,
  putRequestUpdate: state.requestReducer.putRequestUpdate,
  deleteRequest: state.requestReducer.deleteRequest,
  requestStatusList: state.requestReducer.requestStatusList,
  collaborators: state.collaboratorsReducer.collaborators,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  GET_REQUEST_STATUS_LIST: token => dispatch({
    type: 'GET_REQUEST_STATUS_LIST',
    token,
  }),
  GET_REQUEST: token => dispatch({
    type: 'GET_REQUEST',
    token,
  }),
  PUT_REQUEST: (token, request) => dispatch({
    type: 'PUT_REQUEST',
    token,
    request,
  }),
  PUT_REQUEST_ACCEPT: (token, requestId) => dispatch({
    type: 'PUT_REQUEST_ACCEPT',
    token,
    requestId,
  }),
  PUT_REQUEST_UPDATE: (token, request) => dispatch({
    type: 'PUT_REQUEST_UPDATE',
    token,
    request,
  }),
  DELETE_REQUEST: (token, requestId) => dispatch({
    type: 'DELETE_REQUEST',
    token,
    requestId,
  }),
  RESET_REQUEST: () => dispatch({
    type: 'RESET_REQUEST',
  }),
  GET_PROFIL_USER_LIST: () => dispatch({
    type: 'GET_PROFIL_USER_LIST',
  }),
  GET_COLLABORATORS: token => dispatch({
    type: 'GET_COLLABORATORS',
    token,
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Request))
