import axios from 'axios'
import stage from '../../../config'

const apiUrl = stage.api.duyen.url

export default {
  get_company_content(userId) {
    return axios.get(`${apiUrl}/api/company/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },
  put_company_content(token, companyContent) {
    return axios.put(`${apiUrl}/api/company/update`, { companyContent }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },
  // delete_company_content(token, customerId) {
  //   return axios.delete(`${apiUrl}/api/company/${customerId}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then(res => res.data)
  // },
}
