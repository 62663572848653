export const GET_USER_ACCOUNT_RESULTS = 'GET_USER_ACCOUNT_RESULTS'
export const PUT_USER_ACCOUNT_RESULTS = 'PUT_USER_ACCOUNT_RESULTS'
export const DELETE_USER_ACCOUNT_RESULTS = 'DELETE_USER_ACCOUNT_RESULTS'
export const RESET_USER_ACCOUNT = 'RESET_USER_ACCOUNT'

export const getUserAccountResults = data => ({
  type: GET_USER_ACCOUNT_RESULTS,
  userAccount: data,
})

export const putUserAccountResults = data => ({
  type: PUT_USER_ACCOUNT_RESULTS,
  putUserAccount: data,
})

export const deleteUserAccountResults = data => ({
  type: DELETE_USER_ACCOUNT_RESULTS,
  deleteUserAccount: data,
})

export const resetUserAccount = () => ({
  type: RESET_USER_ACCOUNT,
})
