import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Modal from '@material-ui/core/Modal';
import { MenuItem } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

import { CssGrid } from '../../../../configCSS'
import {
  Input,
  ActionButton,
  Uploader,
} from '../../../../components'

import stage from '../../../../config'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage

export default function ResourceModal(props) {
  const {
    type,
    isModalOpen,
    resource,
    resources,
    onModalClose,
    putResource,
    userAccount,
  } = props

  const [resourceToChange, setResource] = useState({});
  const [resourcesList, setResourcesList] = useState({});
  const [newURLArray, setNewURLArray] = useState([]);
  const [newImageArray, setNewImageArray] = useState([]);
  const [errorName, setErrorName] = useState(false);

  useEffect(() => {
    setResource(resource)
  }, [resource])

  useEffect(() => {
    setResourcesList({
      category: getUniqueValue('category'),
      sub_category: getUniqueValue('sub_category'),
    })
  }, [resources])

  const getUniqueValue = (proprety) => {
    const uniquevalueArray = new Set()
    resources.map(obj => uniquevalueArray.add(obj[proprety]))
    const cleanArray = _.pullAll([...uniquevalueArray], ['', null, undefined])
    return cleanArray.map(v => ({ [proprety]: v }))
  }

  // *************
  const readOnlyProps = type === 'show'

  // ********************* Change **********************
  // ********************************
  // On change accomodation
  const onChangeAutocomplete = field => (event, newValue) => {
    if (typeof newValue === 'string') {
      // On press Enter
      setResource({
        ...resourceToChange,
        [field]: _.capitalize(newValue),
      })
    } else if (!newValue) {
      // on clear field
      const currentResource = { ...resourceToChange }
      delete currentResource[field]
      setResource({ ...currentResource })
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input by click on ADD
      setResource({
        ...resourceToChange,
        [field]: _.capitalize(newValue.inputValue),
      })
    } else {
      setResource({
        ...resourceToChange,
        [field]: newValue[field],
      })
    }
    return null
  }

  const filterOptions = field => (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = resourcesList[field].map(r => r).filter(cat => cat[field].toLowerCase() === params.inputValue.toLowerCase())
    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        [field]: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  const getOptionLabel = field => (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option[field]) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option[field];
  }

  // ******************* Display autocomplete ***********
  const displayResourceAutocomplete = field => (
    <Autocomplete
      id={`resource-${field}`}
      value={resourceToChange}
      onChange={onChangeAutocomplete(field)}
      filterOptions={filterOptions(field)}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={field === 'serial_number' && type === 'create' ? [] : resourcesList[field]}
      getOptionLabel={getOptionLabel(field)}
      renderOption={option => option[field]}
      freeSolo
      disabled={readOnlyProps}
      renderInput={params => (
        <Input
          {...params}
          label={_.capitalize(field)}
          margin="normal"
          variant="standard"
        />
      )}
    />
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    const newValue = value
    if (name === 'category' || name === 'sub_category') {
      newValue.trim().toLowerCase()
    }
    setResource({
      ...resourceToChange,
      [name]: newValue,
    })
    if (name === 'name') setErrorName(false)
  }

  const onResponseYes = () => {
    const {
      name,
    } = resourceToChange
    let nameExist = false
    if (type === 'create') {
      nameExist = resources.find(res => res.name === name)
    }
    if (!name || nameExist) {
      setErrorName(true)
    } else {
      putResource(resourceToChange)
      resetState()
    }
  }

  const onResponseNo = () => {
    resetState()
    onModalClose()
  }

  const resetState = () => {
    setResource({})
    setNewURLArray([])
    setNewImageArray([])
  }
  // ************************************************
  // IMAGE
  // Upload and show image

  const getImageUrl = () => {
    const { company } = userAccount
    const { image } = resourceToChange
    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  const saveImage = (imagesDataArray) => {
    setNewURLArray(imagesDataArray.map(img => img.imageUrl))
    setNewImageArray(imagesDataArray.map(img => img.imageName))
    setResource({
      ...resourceToChange,
      image: imagesDataArray[0].imageName,
    })
  }

  const deleteImage = (urlArray, imageArray) => {
    setNewURLArray(urlArray)
    setNewImageArray(imageArray)
    setResource({
      ...resourceToChange,
      image: imageArray.join(),
    })
  }

  // *************************************

  return (
    <Modal
      aria-labelledby="resource-card"
      aria-describedby="resource-presentation"
      open={isModalOpen}
      onClose={() => onModalClose()}
    >
      <div className="traveler-modal-container modal">
        <h2>Resource</h2>

        <CssGrid
          container
          spacing={6}
        >
          <CssGrid item md={8} xs={12}>
            <Input
              id={`name-${resource.id_resource}`}
              label="Name"
              name="name"
              required
              value={!_.isEmpty(resourceToChange) && resourceToChange.name ? resourceToChange.name : ''}
              onChange={handleChange}
              disabled={readOnlyProps}
              margin="normal"
              variant="standard"
              error={errorName}
              className="largeWidth"
            />
          </CssGrid>
        </CssGrid>
        <CssGrid
          container
          spacing={6}
        >
          <CssGrid item md={8} xs={12}>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={6} xs={12}>
                {displayResourceAutocomplete('category')}
              </CssGrid>
              <CssGrid item md={6} xs={12}>
                {displayResourceAutocomplete('sub_category')}
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={6} xs={12}>
                <Input
                  id={`serial_number-${resource.id_resource}`}
                  label="Serial Number"
                  name="serial_number"
                  value={!_.isEmpty(resourceToChange) && resourceToChange.serial_number ? resourceToChange.serial_number : ''}
                  onChange={handleChange}
                  disabled={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
              <CssGrid item md={6} xs={12}>
                <Input
                  id={`date_commisioning-${resource.id_resourcev}`}
                  label="Commissioning Date"
                  name="date_commisioning"
                  type="date"
                  value={!_.isEmpty(resourceToChange) && resourceToChange.date_commisioning ? resourceToChange.date_commisioning : ''}
                  onChange={handleChange}
                  disabled={readOnlyProps}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={6} xs={12}>
                <Input
                  id={`status-${resource.id_resource}`}
                  label="Status"
                  name="status"
                  select
                  value={!_.isEmpty(resourceToChange) && resourceToChange.status ? resourceToChange.status : ''}
                  onChange={handleChange}
                  disabled={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                >
                  <MenuItem value="production">Production</MenuItem>
                  <MenuItem value="archived">Archived</MenuItem>
                </Input>
              </CssGrid>
              <CssGrid item md={6} xs={12}>
                <Input
                  id={`state-${resource.id_resourcev}`}
                  label="Condition"
                  name="state"
                  value={!_.isEmpty(resourceToChange) && resourceToChange.state ? resourceToChange.state : ''}
                  onChange={handleChange}
                  disabled={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item xs={12}>
                <Input
                  id={`comment-${resource.id_resourcev}`}
                  label="Comment"
                  name="comment"
                  type="textarea"
                  value={!_.isEmpty(resourceToChange) && resourceToChange.comment ? resourceToChange.comment : ''}
                  onChange={handleChange}
                  disabled={readOnlyProps}
                  margin="normal"
                  variant="standard"
                  className="largeWidth"
                />
              </CssGrid>
            </CssGrid>
          </CssGrid>
          {/* Uploader */}
          <CssGrid item md={4} xs={12} className="logo-company-div">
            <p className="logo-label">Passport</p>
            <Uploader
              imageDataArray={getImageUrl()}
              saveImage={imagesDataArray => saveImage(imagesDataArray)}
              deleteImage={(urlArray, imageArray) => deleteImage(urlArray, imageArray)}
              allowDrop={!readOnlyProps}
              selectFromDatabase={false}
            />
          </CssGrid>
        </CssGrid>

        <div className="modalActions">
          {type !== 'show' && (
            <ActionButton
              label="Validate"
              onClick={onResponseYes}
            />
          )}
          <ActionButton
            label={type !== 'show' ? 'Cancel' : 'Close'}
            onClick={onResponseNo}
          />
        </div>
      </div>
    </Modal>
  );
}

ResourceModal.propTypes = {
  type: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  putResource: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
  userAccount: PropTypes.object.isRequired,
};
