/* eslint-disable react/jsx-filename-extension */


import React, { PureComponent } from 'react'
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'


class CircuitHtml extends PureComponent {
  static propTypes = {

    companyContent: PropTypes.object,

  }

  static defaultProps = {

    companyContent: {},

  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    const {
      companyContent,

    } = this.props
    const myPage = (
      <div className="GlobalFileHvt">
        {companyContent.phone}
      </div>
    )
    const staticMarkup = ReactDOMServer.renderToStaticMarkup(myPage);

    return (
      <div>{staticMarkup}</div>
    );
  }
}


export default withRouter(connect(CircuitHtml))
