import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'

class Pension extends PureComponent {
  static propTypes = {
    GET_PENSION_LIST: PropTypes.func.isRequired,
    RESET_PENSION_CONTENT: PropTypes.func.isRequired,
    PUT_PENSION_CONTENT: PropTypes.func.isRequired,
    DELETE_PENSION_CONTENT: PropTypes.func.isRequired,
    putPensionContent: PropTypes.object,
    deletePensionContent: PropTypes.object,
    pensionList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    pensionList: [],
    putPensionContent: {},
    deletePensionContent: {},
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.validate = this.validate.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.onChange = this.onChange.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedPension = this.getModifiedPension.bind(this)
    this.updatePension = this.updatePension.bind(this)
    this.state = {
      actionLabel: 'Create',
      pensionList: [],
      pensionContent: '',
      isNewPension: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      disabledActionBtn: true,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      pensionList,
      GET_PENSION_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(pensionList)) {
      this.setState({
        pensionList: _.sortBy(pensionList, o => o.pension),
      })
    } else {
      GET_PENSION_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      pensionContent,
      pensionList: pensionListOld,
    } = this.state
    const {
      pensionList,
      putPensionContent,
      deletePensionContent,
      RESET_PENSION_CONTENT,
    } = nextprops
    if (putPensionContent && putPensionContent.success) {
      this.reload()
      this.setState({
        pensionContent: {
          ...pensionContent,
          id_pension: putPensionContent.id,
        },
        validMsg: 'the pension was successfully recorded !',
      })
      this.resetValidMsg()
      this.handleClose()
    }
    // pension delete in DB -> get back updated list
    if (deletePensionContent && deletePensionContent.success) {
      this.reload()
      this.setState({
        pensionContent: '',
        validMsg: 'the pension was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // pension can not be delete in DB -> it is used yet in route
    if (deletePensionContent && !deletePensionContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deletePensionContent.message,
      })
      RESET_PENSION_CONTENT()
    }
    if (pensionList !== pensionListOld) {
      this.setState({
        pensionList: _.sortBy(pensionList, o => o.pension),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_PENSION_CONTENT,
      GET_PENSION_LIST,
    } = this.props
    RESET_PENSION_CONTENT()
    GET_PENSION_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { pensionList } = this.state
    this.setState({
      validateError: false,
    })

    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = pensionList.filter(d => d.pension === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          pensionContent: {
            id_pension: null,
            pension: newValue,
          },
          disabledActionBtn: false,
          isNewPension: true,
        });
      } else {
        this.setState({
          pensionContent: valueExist[0],
          disabledActionBtn: true,
          isNewPension: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const isNew = _.findIndex(pensionList, o => o.pension === newValue.inputValue) < 0
      this.setState({
        pensionContent: {
          id_pension: null,
          pension: newValue.inputValue,
        },
        disabledActionBtn: !isNew,
        isNewPension: isNew,
      });
    } else {
      this.setState({
        pensionContent: newValue,
        disabledActionBtn: true,
        isNewPension: false,
      });
    }
  }

  filterOptions = (options, params) => {
    const { pensionList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = pensionList.filter(d => d.pension === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        pension: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.pension;
  }

  // ***************************
  // Display input

  displayAutocompleteInput = () => {
    const {
      pensionContent,
      pensionList,
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="pension-field"
            value={pensionContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={pensionList}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.pension}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Pension"
                margin="normal"
                placeholder="Select a pension or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      pensionContent,
    } = this.state
    const pensionId = pensionContent.id_pension
    const { DELETE_PENSION_CONTENT } = this.props

    if (confirm('Do you really want to delete this pension ?')) {
      if (pensionId) {
        DELETE_PENSION_CONTENT(token, pensionId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: pensionContent.pension ? 'This pension do not exist.' : 'Please, select a pension.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { pensionContent } = this.state
    if (!pensionContent || !pensionContent.id_pension) return null
    this.setState({
      isNewPension: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      pensionContent,
      isNewPension,
      token,
    } = this.state
    const { PUT_PENSION_CONTENT } = this.props

    this.setState({
      disabledActionBtn: true,
    })

    // Check all required inputs
    if (!pensionContent.pension) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewPension) {
      PUT_PENSION_CONTENT(token, pensionContent, 'create')
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new location
  updatePension = () => {
    const {
      token,
      pensionContent,
    } = this.state
    const { PUT_PENSION_CONTENT } = this.props
    PUT_PENSION_CONTENT(token, pensionContent, 'update')
  }

  getModifiedPension = (item) => {
    const { pensionContent } = this.state
    this.setState({
      pensionContent: {
        ...pensionContent,
        pension: item.pension,
      },
    }, () => this.updatePension())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      pensionContent,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      disabledActionBtn,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>PENSION MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!pensionContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedPension(item)}
            disabled={false}
            title="Enter the new value for this pension"
            modalContent="pension"
            warning="This will update this pension in all quotes !"
            type="pension"
            inputValue={!_.isEmpty(pensionContent) ? pensionContent.pension : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pensionList: state.api.pensionList,
  putPensionContent: state.api.putPensionContent,
  deletePensionContent: state.api.deletePensionContent,
})

const mapDispatchToProps = dispatch => ({
  GET_PENSION_LIST: userId => dispatch({
    type: 'GET_PENSION_LIST',
    userId,
  }),
  DELETE_PENSION_CONTENT: (token, pensionId) => dispatch({
    type: 'DELETE_PENSION_CONTENT',
    token,
    pensionId,
  }),
  RESET_PENSION_CONTENT: () => dispatch({
    type: 'RESET_PENSION_CONTENT',
  }),
  PUT_PENSION_CONTENT: (token, pensionContent, action) => dispatch({
    type: 'PUT_PENSION_CONTENT',
    token,
    pensionContent,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pension))
