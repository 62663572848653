// Trip
export const PUT_TRIP_DETAILS_SITE_RESULTS = 'PUT_TRIP_DETAILS_SITE_RESULTS'
export const RESET_TRIP_DETAILS_SITE = 'RESET_TRIP_DETAILS_SITE'

export const resetTripDetailsSite = () => ({
  type: RESET_TRIP_DETAILS_SITE,
})


export const putTripDetailsSiteResults = data => ({
  type: PUT_TRIP_DETAILS_SITE_RESULTS,
  putTripDetailsSite: data,
})
