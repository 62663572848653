
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import { connect } from 'react-redux'
import { renderToStaticMarkup } from 'react-dom/server';
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import Interweave from 'interweave';
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import stage from '../../../config'

import {
  ActionButton,
  TextEditor,
} from '../..'
import duplicate from '../../../assets/images/duplicate.png'
import iconeimage from '../../../assets/images/icone-image.png'


// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'

const savedImgUrl = stage.imgStorage
const apiUrl = stage.api.duyen.url

class GenerateHtmlDuyen3 extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    tripDetails: PropTypes.array,
    tripContent: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripBilling: PropTypes.object,
    tripAllDays: PropTypes.array,
    tripDevisFinal: PropTypes.array,
    tripPension: PropTypes.array,
    tripAccomodation: PropTypes.array,
    PUT_TRIP_DETAILS_URL: PropTypes.func.isRequired,
    PUT_TRIP_DEVIS_FINAL: PropTypes.func.isRequired,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
    locationList: PropTypes.array,
    userAccount: PropTypes.object,
    roomList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    tripDetails: [],
    tripPension: [],
    tripBilling: {},
    tripDevisFinal: [],
    tripContent: [],
    tripAccomodation: [],
    tripAllDaysAccomodations: [],
    tripAllDays: [],
    companyContent: {},
    informationsContent: {},
    locationList: [],
    roomList: [],
  }

  constructor(props) {
    super(props);
    this.dropFile = this.dropFile.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleOSizeChange = this.handleOSizeChange.bind(this)
    this.Export2Word = this.Export2Word.bind(this)

    this.state = {
      token: localStorage.getItem('token'),
      urlDevis: '',
      devisContent: '',
      dateUpdateUrl: '',
    };
  }

  componentDidMount() {
    const {
      tripDevisFinal,
      history,
    } = this.props
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      this.setState({
        devisContent: tripDevisFinal[0].textHtml,
      })
    }

    if (history.location.state) {
      const {
        currentPage,
      } = history.location.state
      if (currentPage === 'print') {
        const {
          state,
        } = history.location.state
        this.setState({
          devisContent: state.item,
        })
      }
    }
  }

  redirectToGallery = () => {
    const {
      devisContent,
    } = this.state
    const {
      history,
    } = this.props

    history.push('gallery', {
      currentPage: 'print',
      isSelectionAction: false,
      nbMaxImg: 0,
      currentImg: [],
      state: {
        item: devisContent,
      },
    })
  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteHorizon-size', `${event.currentTarget.value}px`)
  }

  handlePrint = (html) => {
    const content = html; // Récupérez la valeur du contenu HTML de votre éditeur
    const newWindow = window.open('', '_blank');
    newWindow.document.write(content);
    newWindow.document.close();
  }

  handleSave = (devisContent) => {
    const {
      PUT_TRIP_DEVIS_FINAL,
      tripDetails,
      tripDevisFinal,
    } = this.props
    const {
      token,
    } = this.state
    const tripDevisFinalServeur = {}
    tripDevisFinalServeur.textHtml = devisContent
    tripDevisFinalServeur.id_devis = tripDetails[0].id_devis
    if (tripDevisFinal && !_.isEmpty(tripDevisFinal)) {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'update')
    } else {
      PUT_TRIP_DEVIS_FINAL(token, tripDevisFinalServeur, 'create')
    }
  }

  handleEditorChange = (content) => {
    this.setState({
      devisContent: content,
    });
  }

  dropFile = (html) => {
    const {
      companyContent,
      tripDetails,
      PUT_TRIP_DETAILS_URL,
    } = this.props

    const {
      token,
    } = this.state

    const blob = new Blob([html]);
    const { company } = companyContent
    const file = new File([blob], `${tripDetails[0].date_depart}-${tripDetails[0].id_devis}${tripDetails[0].id_circuit}${tripDetails[0].id_conseiller}${tripDetails[0].version}.html`, { type: 'text/html' });
    // upload image files to API server
    // name format : name-date.extension

    // upload image file to API server
    const imageFormObj = new FormData()
    imageFormObj.append('imageName', file.name)
    imageFormObj.append('company', company.toLowerCase())
    imageFormObj.append('file', file);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'text/html',
      },
    }

    // send image to server
    axios.post(`${apiUrl}/api/uploadDevis`, imageFormObj, config)
      .then((res) => {
        const tripDetailsUrl = {}
        tripDetailsUrl.urlDevis = res.data.urlDevis
        tripDetailsUrl.id_devis = tripDetails[0].id_devis

        PUT_TRIP_DETAILS_URL(token, tripDetailsUrl)
        this.setState({
          urlDevis: res.data.urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'http://resa.duyen.pro'),
          dateUpdateUrl: moment().format('YYYY-MM-DD HH:mm'),
        })
      })
    return true
  }

  Export2Word = (element, filename = '') => {
    const html = element;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`;

    // Specify file name
    filename = filename ? `${filename}.doc` : 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence += x.day_duration
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = x.day_duration
          arr2.push(a);
        }
      })
      return arr2
    }
    const {
      tripDetails,
      tripAllDaysAccomodations,
      companyContent,
      informationsContent,
      tripAccomodation,
      userAccount,
      tripBilling,
      tripPension,
      tripContent,
      tripAllDays,
      locationList,
      roomList,
    } = this.props

    const {
      nb_adulte,
      nb_enfant,
      nb_bebe,
      prenom_responsable_groupe,
      nom_responsable_groupe,
      date_creation,
      version,
      desc_voyage,
      code_devis,
      destination,
      centre_interet,
      langage,
    } = tripDetails[0]
    const {
      urlDevis,
      dateUpdateUrl,
      devisContent,
    } = this.state
    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]
    // get langage
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const tripLocalisationList = !_.isEmpty(tripDetails) ? _.split(tripDetails[0].map_localisation, ',') : []
    const tabMap = tripLocalisationList.map(tLL => locationList.filter(c => c.lieu === tLL))
    let markerMap = ''
    tabMap.map((item, index) => tripDetails[0].map_localisation && item[0].latitude && (markerMap = `${markerMap}&markers=size:mid%7Ccolor:red%7Clabel:${index + 1}%7C${item[0].latitude},${item[0].longitude}`))
    const urlMap = `https://maps.googleapis.com/maps/api/staticmap?size=800x1000&maptype=roadmap${markerMap}&key=AIzaSyC9zzNZcGSPW-WfxevpCgUGnPb9OoJQU_0`
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const NombreInformation = [0, 1, 2, 3, 4];
    const nbAdultNumb = nb_adulte && !isNaN(parseInt(nb_adulte, 10)) ? parseInt(nb_adulte, 10) : 0
    const nbChildNumb = nb_enfant && !isNaN(parseInt(nb_enfant, 10)) ? parseInt(nb_enfant, 10) : 0
    const nbBabyNumb = nb_bebe && !isNaN(parseInt(nb_bebe, 10)) ? parseInt(nb_bebe, 10) : 0
    const totalTraveler = nbAdultNumb + nbChildNumb + nbBabyNumb
    const advisorName = !_.isEmpty(userAccount) ? `${userAccount.firstname} ${userAccount.lastname}` : null
    const advisorMail = !_.isEmpty(userAccount) ? userAccount.email : null
    const advisorPhone = !_.isEmpty(userAccount) ? userAccount.phone : null
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const isTransport = tripAllDays.filter(c => c.transport !== '')
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const remarque = `${langage}_remarque`
    const jonqueDesc = `${langage}_desc_hebergement`
    const slogan = `${langage}_slogan`
    const presentation = `${langage}_presentation`
    const categorie = `${langage}_categorie`
    const tauxchange = `${langage}_taux_change`
    const langageDate = langage === 'sp' ? 'es' : langage
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const Couleurdefaut = {
      color: informationsContent.main_color,
    };
    const titreCouleurdefaut = {
      color: informationsContent.main_color,
      fontSize: '16px',
    };
    const grandTitreCouleurdefaut = {
      color: informationsContent.main_color,
      fontSize: '33px',
    };
    const grandTitre = {
      fontSize: '33px',
    };
    const Fonddefaut = {
      backgroundColor: informationsContent.main_color,
    };

    const carteHorizon = {
      display: 'flex',
      margin: 'auto',
      paddingBottom: 20,
    };
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : '. '}`)
      return label.join('')
    }
    let imageAvion = 'vide'
    let textAvion = 'vide'
    switch (destination) {
      case 'CAMBODGE':
        imageAvion = 'cambodgeairlines-1667962172275.jpg';
        textAvion = langageTranslation.avionC
        break;
      case 'VIETNAM, CAMBODGE':
        imageAvion = 'vietnam-cam-1667968598558.png';
        textAvion = langageTranslation.avionVC
        break;
      case 'LAOS, CAMBODGE':
        imageAvion = 'cam-laos-1667968691975.png';
        textAvion = langageTranslation.avionLC
        break;
      case 'LAOS':
        imageAvion = 'laos-1676431499170.png';
        textAvion = langageTranslation.avionLC
        break;
      case '':
        imageAvion = 'vietnamairlines-1667961029756.jpg';
        textAvion = langageTranslation.avionVN
        break;
      default:
        imageAvion = 'vietnamairlines-1667961029756.jpg';
        textAvion = langageTranslation.avionVN
    }
    const enTete = (
      <div className="page-header-horizon">
        <table width="100%">
          <tbody>
            <tr>
              <td width="30%">
                <img width="100" height="100" className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
              </td>
              <td width="70%">
                <Interweave content={companyContent[slogan]} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
    const myPageFunc = (indexDisplay) => {
      const myPage = (
        <div className="GlobalFileHvt">
          {(indexDisplay !== 'HTMLWORD')
           && (
           <div className="page-header-horizonhtml">
             <div className="logoTextHorizon">
               <img className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
               <Interweave content={companyContent[slogan]} />
             </div>
             <hr size="1" />
             <hr className="epaisseurLigne" />
             <div className="navbar">
               <a href="#home">{langageTranslation.homeMenu}</a>
               {(tripDetails[0].image || (tripDetails[0].map_localisation && tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
               <a href="#carte">{langageTranslation.carteMenu}</a>)}
               <a href="#detail">{langageTranslation.detailMenu}</a>
               <a href="#hotel">{langageTranslation.hotelMenu}</a>
               <a href="#prix">{langageTranslation.prixMenu}</a>
               <a href="#info">{langageTranslation.infoMenu}</a>
             </div>
           </div>
           )
        }
          <div>
            {(indexDisplay !== 'HTMLWORD')
           && (
           <div className="page-header-horizon">
             <div className="logoTextHorizon">
               <img className="LogoHorizon" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
               <Interweave content={companyContent[slogan]} />
             </div>
             <hr size="1" />
             <hr className="epaisseurLigne" />
           </div>
           )}
            <table className="table-horizon">
              {(indexDisplay !== 'HTMLWORD')
           && (
           <thead>
             <tr>
               <td>
                 <div className="page-header-space" />
               </td>
             </tr>
           </thead>
           )}
              <tbody className="mainContentHorizon">
                <tr>
                  <td>
                    <div id="home" className="titre" style={grandTitreCouleurdefaut}>{langageTranslation.travelProject}</div>
                    <div className="soustitre" style={Couleurdefaut}>{langageTranslation.departureDate}: {moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langageDate).format('DD MMMM YYYY')}</div>
                    <div style={grandTitre} className="titre">{tripDetails && (tripDetails[0].nom_programme)}</div>
                    <table width="70%" className="FirstInfo">
                      <tbody>
                        <tr>
                          <td width="12%">
                            <div className="titrounet" style={Couleurdefaut}>
                              { code_devis && (<div>{langageTranslation.codeQuote}: </div>)}
                              {tripAllDaysAccomodations[0].start_location && (<div>{langageTranslation.AtDeparture}: </div>)}
                              <div>{langageTranslation.circuitType}:  </div>
                              <div>{langageTranslation.start}: </div>
                              {destination && (<div>{langageTranslation.destination}: </div>)}
                            </div>
                          </td>
                          <td width="50%">
                            <div className="titrounet2">
                              {code_devis && (<div> {code_devis}</div>)}
                              { tripAllDaysAccomodations[0].start_location && (<div> {tripAllDaysAccomodations[0].start_location}</div>)}
                              <div> {langageTranslation.individual}</div>
                              <div> {langageTranslation.allDay}</div>
                              {destination && (<div> {destination}</div>)}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table height="100" className="TabItineraireGlobal" width="100%">
                      <tbody>
                        <tr height="25">
                          <td className="TabItineraireHorizon" width="25%" height="25">{langageTranslation.groupName}</td>
                          <td className="TabItineraireHorizon" width="25%" height="25">{travelerName}</td>
                          <td className="TabItineraireHorizon" width="25%" height="50"> {version !== 1 ? (<div>{langageTranslation.modificationDate}</div>) : (<div>{langageTranslation.creationDate}</div>)}
                          </td>
                          <td className="TabItineraireHorizon" width="25%" height="50">{date_creation}</td>
                        </tr>
                        <tr height="25">
                          <td className="TabItineraireHorizon" width="25%" height="25">{langageTranslation.version}</td>
                          <td className="TabItineraireHorizon" width="25%" height="25">{version}</td>
                          <td className="TabItineraireHorizon" width="25%" height="50" rowSpan="3">
                            <div>
                              <div className="textconseil">{langageTranslation.textConseil}</div>
                              <div className="textconseil">{advisorName}</div>
                              <div className="textconseil">
                                <a href={advisorMail} className="emailconseil">{advisorMail}</a>
                              </div>
                              <div className="textconseil">{advisorPhone}</div>
                            </div>
                          </td>
                          <td width="25%" className="TabItineraireHorizon" height="50" rowSpan="3">
                            <div>
                              {(indexDisplay !== 'HTMLWORD')
                                      && (
                                      <img width="200" height="150" src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
                                      )}
                            </div>
                          </td>
                        </tr>
                        <tr height="25">
                          <td width="25%" className="TabItineraireHorizon" height="25">{langageTranslation.groupSize}</td>
                          <td width="25%" className="TabItineraireHorizon" height="25">{nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}</td>
                        </tr>
                        <tr height="25">
                          <td width="25%" className="TabItineraireHorizon" height="25">{langageTranslation.tripType}</td>
                          <td width="25%" className="TabItineraireHorizon" height="25">{langageTranslation.individual}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="paragraphevert" style={Couleurdefaut}>{langageTranslation.textHorizon1} {nb_adulte && nb_adulte} {nb_enfant && `, ${nb_enfant}`} {nb_bebe && `, ${nb_bebe}`}, {langageTranslation.textHorizon2}</div>
                    <div className="titrevert" style={titreCouleurdefaut}>{langageTranslation.spiritTrip} : </div>
                    <Interweave content={desc_voyage} />
                    <div className="titrevert" style={titreCouleurdefaut}>{langageTranslation.interest} : </div>
                    <Interweave content={centre_interet} />
                    <div className="CoupePas">
                      <div className="paragraphevert" style={titreCouleurdefaut}>{langageTranslation.itineraryInBrief} : </div>
                      <div>
                        <table className="TabItineraireGlobal" width="100%">
                          <tbody>
                            <tr>
                              <td width="10%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.date}</td>
                              <td width="60%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.itinerary}</td>
                              <td width="15%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.accomodation}</td>
                              <td width="15%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.meals}</td>
                            </tr>
                            {tripAllDaysAccomodations.map((item, index) => {
                              const IncDay = _.sum(TabIncJour.slice(0, index))
                              return (
                                <tr key={`Bref${item.id_journee}`}>
                                  <td width="10%" className="TabItineraireHorizon">
                                    <a href={`#Jour${index}`} style={Couleurdefaut}>
                                      {langageTranslation.day} {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)}
                                    </a>
                                  </td>
                                  <td width="60%" className="TabItineraireHorizon">
                                    <a href={`#Jour${index}`} style={Couleurdefaut}>
                                      {item.nom_journee}
                                    </a>
                                  </td>
                                  <td width="15%" className="TabItineraireHorizon">
                                    <a href={`#Jour${index}`} style={Couleurdefaut}>
                                      {tripAccomodation[index].type}
                                    </a>
                                  </td>
                                  <td width="15%" className="TabItineraireHorizon">
                                    <a href={`#Jour${index}`} style={Couleurdefaut}>
                                      {tripPension[index].pension !== 'none' && tripPension[index].pension}
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {(indexDisplay !== 'HTMLWORD') && (tripDetails[0].image || (tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (
                    <div>
                      <div className="CoupePas">
                        {tripDetails[0].image
                          ? (
                            <div>
                              <div id="carte" className="titrevert" style={titreCouleurdefaut}>{langageTranslation.mapOfTrip} : </div>
                              <Grid container spacing={0} justifyContent="center">
                                <Grid item xs={12}>
                                  <img style={carteHorizon} height="850" width="600" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            tripDetails[0].map_localisation
                        && (
                        <div>
                          <div id="carte" className="titrevert" style={titreCouleurdefaut}>{langageTranslation.mapOfTrip} : </div>
                          <img style={carteHorizon} height="850" width="600" src={urlMap} />
                        </div>
                        )
                          )
                    }
                      </div>
                    </div>
                    )}
                    <div className="CoupePas">
                      <div id="detail" className="titrevert" style={titreCouleurdefaut}>{langageTranslation.detailedProgram} : </div>
                      {tripAllDays.map((item_jour, index) => {
                        const desc = `desc_jour_${index + 1}`;
                        const adresseHebClean = tripAccomodation[index].adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');
                        const imageArray = item_jour.image.split(',')
                        const IncDay = _.sum(TabIncJour.slice(0, index))
                        const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', langageDate).add(IncDay, 'd').format('dddd DD MMMM YYYY'))
                          : ''
                        // eslint-disable-next-line no-nested-ternary
                        let guideNeed = ''
                        switch (guideArray[index]) {
                          case '0':
                            guideNeed = ''
                            break;
                          case '1':
                            guideNeed = langageTranslation.withGuide
                            break;
                          case '2':
                            guideNeed = langageTranslation.withGuideEn
                            break;
                          case '3':
                            guideNeed = langageTranslation.withGuideEnEvasion
                            break;
                          default:
                            guideNeed = ''
                        }
                        let driverNeed = ''
                        switch (chauffeurArray[index]) {
                          case '0':
                            driverNeed = ''
                            break;
                          case '1':
                            driverNeed = langageTranslation.driver
                            break;
                          case '2':
                            driverNeed = ''
                            break;
                          case '3':
                            driverNeed = 'Transport Locaux'
                            break;
                          case '4':
                            driverNeed = langageTranslation.busEvasion
                            break;
                          default:
                            driverNeed = ''
                        }
                        const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''

                        const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver
                        return (
                          <div key={`detail${item_jour.id_journee}`}>
                            <div className="CoupePas">
                              <div className="titrevert" id={`Jour${index}`} style={titreCouleurdefaut}> {langageTranslation.day} {TabIncJour[index] === 1 ? (IncDay + 1) : (`${IncDay + 1} à ${IncDay + TabIncJour[index]}`)} : {item_jour.nom_journee} {tripPension[index].pension !== 'none' ? (`(${tripPension[index].pension})`) : ('')}</div>
                              <div className="datejour" style={Couleurdefaut}> {datejour}</div>
                              <div className="paragraphevert" style={Couleurdefaut}>* {asteriskText}</div>
                              {item_jour.distance && (<div className="paragraphevert" style={Couleurdefaut}>* {item_jour.distance}</div>)}
                              {imageArray[0] !== '' && imageArray.length === 1
                                && (
                                <Grid className="justPaddingBottom" container spacing={2} justifyContent="center">
                                  <Grid className="OptionTextJournee" item xs={8}>
                                    <Interweave content={tripContent[0][desc]} />
                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
                                    {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={adresseHebClean} /></span><span>)</span></>)}
                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                  </Grid>
                                  {(indexDisplay !== 'HTMLWORD')
                                      && (
                                      <Grid className="Image1Jour1Horizon" item xs={4}>
                                        <img width="250" height="150" className="Image1Jour1Horizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                      </Grid>
                                      )}
                                </Grid>
                                )
                               }
                              {imageArray[0] !== '' && imageArray.length >= 2
&& (
<Grid className="justPaddingBottom" container spacing={2} justifyContent="center">
  <Grid className="OptionTextJournee" item xs={8} lg={8} sm={12}>
    <Interweave content={tripContent[0][desc]} />
    {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
    {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={adresseHebClean} /></span><span>)</span></>)}
    {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
  </Grid>
  {indexDisplay !== 'HTMLWORD'
    && (
    <Grid className="Image1Jour1Horizon" item xs={4} lg={4} sm={12}>
      <img width="250" height="150" className="Image1Jour1Horizon" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
    </Grid>

    )}
</Grid>
)
}
                            </div>
                            {imageArray[0] === ''

          && (
          <div>
            <div>
              <Grid container spacing={0} justifyContent="center">
                <Grid className="OptionTextJournee" item xs={12}>
                  <Interweave content={tripContent[0][desc]} />
                  {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
                  {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={adresseHebClean} /></span><span>)</span></>)}
                  {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                </Grid>
              </Grid>
            </div>
          </div>
          )}
                          </div>
                        )
                      })}
                    </div>
                    <div className="paragraphebleu">*** {langageTranslation.serviceEnd} ***</div>
                    <div className="titreGras" style={Couleurdefaut}>{langageTranslation.TravelInformation}: </div>
                    <div className="titreGras" style={Couleurdefaut}>{langageTranslation.groupName}: {travelerName}</div>
                    <div className="titreGras" style={Couleurdefaut}>{langageTranslation.textTravelerNumber}: {nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}</div>
                    <div className="CoupePas">
                      <div id="hotel" className="titrevert" style={titreCouleurdefaut}>{langageTranslation.yourHotels} </div>
                      <table width="100%" className="TabHotel">
                        <tbody>
                          <tr>
                            <td width="20%" className="TabItineraireHorizonHead" style={Couleurdefaut}>{langageTranslation.destination}</td>
                            <td width="60%" className="TabItineraireHorizonHead" style={Couleurdefaut}>{langageTranslation.accomodation}</td>
                            <td width="20%" className="TabItineraireHorizonHeadRight" style={Couleurdefaut}>{langageTranslation.nightNumber}</td>
                          </tr>
                          {Hotel_Unique && Hotel_Unique.map((item, i) => {
                            const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                            const adresseHebClean = item.adresse.replace(/<p>/g, '').replace(/<\/p>/g, '');
                            return (
                              item.nom_hebergement !== 'none'
                && (
                  <tr key={`Acco${item.id_journee}`}>
                    <td width="20%" className="TabItineraireHorizonHead" style={Couleurdefaut}>{LieuFinJournee.lieu}</td>
                    <td width="60%" className="TabItineraireHorizonHotelDesc" style={Couleurdefaut}>
                      <div>{item.nom_hebergement} {item[categorie]}</div>
                      <Interweave content={adresseHebClean} />
                    </td>
                    <td width="20%" className="TabItineraireHorizonRightHotel" style={Couleurdefaut}>{Nombre_Occurence_Hotel[i].occurrence}</td>
                  </tr>
                )
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    {!_.isEmpty(isTransport)
              && (
              <div className="CoupePas">
                <div className="titrevert" style={titreCouleurdefaut}>{langageTranslation.reservation}: </div>
                <div>
                  <table className="TabHotel" width="100%">
                    <tbody>
                      <tr>
                        <td width="20%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.date}</td>
                        <td width="60%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.route}</td>
                        <td width="20%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.reservationState}</td>
                      </tr>
                      {tripAllDays.map((item_jour, index) => (
                        item_jour.transport && (
                        <tr key={`Transport${item_jour.id_journee}`}>
                          <td width="20%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.day} {index + 1}</td>
                          <td width="60%" className="TabItineraireHorizon" style={Couleurdefaut}>{item_jour.transport}</td>
                          <td width="20%" className="TabItineraireHorizon" style={Couleurdefaut}>{langageTranslation.available}</td>
                        </tr>
                        )
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              )}
                    {tripBilling.displayTabCheck && (
                    <div>
                      <div className="CoupePas">
                        <div id="prix" className="titrevert" style={titreCouleurdefaut}>{langageTranslation.quoteTrip} :  </div>
                        <div className="paragraphevert2" style={Couleurdefaut}> ({langageTranslation.priceCalc} {tripBilling.currency !== '0' ? tripBilling.currency : '€'} {langageTranslation.priceCalc2} {nb_adulte && nb_adulte} {nb_enfant && `,${nb_enfant}`} {nb_bebe && `,${nb_bebe}`}) </div>

                        <Grid container spacing={0} align="row" justifyContent="center">
                          <Grid item xs={12}>
                            <div style={Couleurdefaut} className="titreGrasCentre"> {tripDetails[0].nom_programme}</div>
                          </Grid>
                          <Grid item xs={12}>
                            <Interweave content={tripBilling.tabPrice} />
                          </Grid>
                        </Grid>
                        <div className="paragraphevert" style={Couleurdefaut}>{informationsContent[tauxchange]}</div>
                      </div>
                      <div style={titreCouleurdefaut} className="titrevert">{langageTranslation.priceContained}: </div>
                      <Interweave content={tripDetails[0].prix_comprend} />
                      <div style={titreCouleurdefaut} className="titrevert">{langageTranslation.priceDoNotContained}: </div>
                      <Interweave content={tripDetails[0].prix_comprend_pas} />
                    </div>
                    )}
                    <div className="CoupePas">
                      <div id="info" style={titreCouleurdefaut} className="titrevert"> {langageTranslation.prestationDetail} </div>
                      {Hotel_Unique && Hotel_Unique.map((item, i) => {
                        const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)
                        const imageArrayHotel = item.image_hebergement ? item.image_hebergement.split(',') : ''
                        return (
                          <div key={`Acco${item.id_journee}`}>
                            {(item.type === 'Jonque' || item.type === 'Crucero')
                && (
                  <div>
                    <div className="CoupePas">
                      <Grid container spacing={0} justifyContent="center">
                        <Grid style={Fonddefaut} className="TitreInfoGeneral" item xs={12}>
                          <div> {langageTranslation.jonque} {LieuFinJournee.lieu}</div>
                          <div>{item.nom_hebergement} - {Nombre_Occurence_Hotel[i].occurrence} {langageTranslation.night}</div>
                        </Grid>
                        {item[jonqueDesc] && (
                        <Grid item xs={12}>
                          <Interweave content={item[jonqueDesc]} />
                        </Grid>
                        )}
                      </Grid>
                    </div>
                    {(indexDisplay !== 'HTMLWORD') && imageArrayHotel[0] !== '' && imageArrayHotel.length === 1
                  && (
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={4}>
                        <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                      </Grid>
                    </Grid>
                  )}
                    {(indexDisplay !== 'HTMLWORD') && imageArrayHotel[0] !== '' && imageArrayHotel.length === 2
                  && (
                    <div>
                      <Grid container spacing={10} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                    {(indexDisplay !== 'HTMLWORD') && imageArrayHotel[0] !== '' && imageArrayHotel.length === 3
                  && (
                    <div>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={4}>
                          <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[0]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[1]}`} />
                        </Grid>
                        <Grid item xs={4}>
                          <img width="250" height="150" className="Image1JourHorizon" src={`${savedImgUrl}/${companyContent.company}/${imageArrayHotel[2]}`} />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  </div>
                )}
                          </div>
                        )
                      })}
                    </div>
                    <div className="CoupePas">
                      <div style={Fonddefaut} className="TitreInfoGeneral">{langageTranslation.titreAvionTransport}</div>
                      <Grid container spacing={1} justifyContent="center">
                        {(indexDisplay !== 'HTMLWORD')
                              && (
                              <Grid item xs={4}>
                                <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/${imageAvion}`} />
                              </Grid>
                              )}
                        <Grid item xs={8}>
                          <Interweave content={textAvion} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="CoupePas">
                      <div style={Fonddefaut} className="TitreInfoGeneral">{langageTranslation.TitreTransport}</div>
                      {destination === 'VIETNAM'
                        ? (
                          <Grid container spacing={1} justifyContent="center">
                            {(indexDisplay !== 'HTMLWORD') && totalTraveler <= 2 && (
                            <Grid item xs={4}>
                              <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/transportsroutiers-1649659741472.jpg`} />
                            </Grid>
                            )}
                            {(indexDisplay !== 'HTMLWORD') && totalTraveler > 2 && totalTraveler <= 7 && (
                            <Grid item xs={4}>
                              <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule6place-1650443589909.png`} />
                            </Grid>
                            )}
                            {(indexDisplay !== 'HTMLWORD') && totalTraveler > 7 && totalTraveler <= 12 && (
                            <Grid item xs={4}>
                              <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule812place-1650445402443.png`} />
                            </Grid>
                            )}
                            {(indexDisplay !== 'HTMLWORD') && totalTraveler > 12 && totalTraveler <= 18 && (
                            <Grid item xs={4}>
                              <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule1318places-1650445483440.png`} />
                            </Grid>
                            )}
                            {(indexDisplay !== 'HTMLWORD') && totalTraveler > 18 && (
                            <Grid item xs={4}>
                              <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/horizon_vietnam_voyage/vehicule1845places-1650445579063.png`} />
                            </Grid>
                            )}
                            <Grid item xs={8}>
                              <Interweave content={langageTranslation.vehicule} />
                            </Grid>
                          </Grid>
                        ) : (
                          <div>
                            <br />
                            <Interweave content={langageTranslation.vehicule} />
                            <br />
                            <br />
                          </div>
                        )
  }
                    </div>
                    {NombreInformation.map((item, index) => {
                      const InformationText = langage && `${langage}_information_${index + 1}_description`;
                      const InformationImage = `information_${index + 1}_image`;
                      const InformationTitre = langage && `${langage}_information_${index + 1}`;
                      return (
                        <div key={`info${item}`}>
                          {informationsContent[InformationText] !== ''
                  && (
                  <div className="CoupePas">
                    <div style={Fonddefaut} className="TitreInfoGeneral">{informationsContent[InformationTitre]}</div>
                    {informationsContent[InformationImage] !== '' ? (
                      <Grid container spacing={1} justifyContent="center">
                        {(indexDisplay !== 'HTMLWORD')
           && (
           <Grid item xs={4}>
             <img width="250" height="150" className="imgInformationHorizon" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />
           </Grid>
           )}
                        <Grid item xs={8}>
                          <Interweave content={informationsContent[InformationText]} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                          <Interweave content={informationsContent[InformationText]} />
                        </Grid>
                      </Grid>
                    )
                    }
                  </div>
                  )}
                        </div>
                      )
                    })}
                    {indexDisplay !== 'HTMLWORD' && langage && langage === 'fr' && (
                    <div className="videoBox">
                      <div style={Fonddefaut} className="TitreInfoGeneral">Témoignages  et présentation vidéo</div>
                      <br />
                      <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoHorizon" width="424" height="238" src="https://www.youtube.com/embed/NykjsBWz0vk" title="Ils sont satisfaits de leur voyage au Vietnam grâce à Horizon Vietnam Travel - Avis des voyageurs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                        <Grid item xs={6} lg={6} sm={12}>
                          <iframe className="VideoHorizon" width="424" height="238" src="https://www.youtube.com/embed/nXNJ7kyTGYo" title="Présentation de Horizon Vietnam Travel, une agence de voyage locale à taille humaine" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Grid>
                      </Grid>
                    </div>
                    )}
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={12}>
                        <Interweave content={_.isEmpty(tripDetails[0].comment) ? informationsContent[remarque] : tripDetails[0].comment} />
                      </Grid>
                    </Grid>
                    <div style={Couleurdefaut} className="titreGras2">{advisorName}</div>
                    <div>{langageTranslation.textConseil}</div>
                    <img width="50" height="30" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
                    <Interweave content={userAccount[presentation]} />
                    {(indexDisplay !== 'HTMLWORD')
           && (
           <div>
             <div className="titreGras">{langageTranslation.recommandationOn}:                                               </div>
             <Grid container spacing={0} justifyContent="center">
               <Grid item xs={2}>
                 <a href="https://www.petitfute.com/v45031-hanoi/c1122-voyage-transports/c747-tours-operateurs/1499486-horizon-vietnam-travel.html">
                   <img width="50" height="30" className="ImageGuide" src={`${savedImgUrl}/horizon_vietnam_voyage/petitfute-enddoc-1650419395869.png`} />
                 </a>
               </Grid>
               <Grid item xs={2}>
                 <a href="http://www.routard.com/forum_message/2754141/horizon_vietnam_travel.htm">
                   <img width="50" height="30" className="ImageGuide" src={`${savedImgUrl}/horizon_vietnam_voyage/routard-enddoc-1650419420351.png`} />
                 </a>
               </Grid>
               <Grid item xs={2}>
                 <a href="https://www.tripadvisor.fr/Attraction_Review-g293924-d8797035-Reviews-Horizon_Vietnam_Travel_Day_Tours-Hanoi.html">
                   <img width="50" height="30" className="ImageGuide" src={`${savedImgUrl}/horizon_vietnam_voyage/tripadvisor-enddoc-1650419442630.png`} />
                 </a>
               </Grid>
               <Grid item xs={2}>
                 <a href="https://fr.trustpilot.com/review/horizon-vietnamvoyage.com">
                   <img width="50" height="30" className="ImageGuide" src={`${savedImgUrl}/horizon_vietnam_voyage/trustpilot-enddoc-1650419565295.png`} />
                 </a>
               </Grid>
               <Grid item xs={2}>
                 <img width="50" height="30" className="ImageGuide" src={`${savedImgUrl}/horizon_vietnam_voyage/voyageforum-enddoc-1650419585934.jpg`} />
               </Grid>
             </Grid>
             <div className="titreGras">{langageTranslation.futefute}:</div>
             <div className="centreinteret">{langageTranslation.petitFute}</div>
             <div>------------------------------------------------------------------------------</div>
             <Grid container spacing={0} justifyContent="flex-start">
               <Grid item xs={2}>
                 <img width="50" height="30" className="ImageRecoHVT" src={`${savedImgUrl}/horizon_vietnam_voyage/temoignages-enddoc-1650419543758.png`} />
               </Grid>
               <Grid item xs={2}>
                 <img width="50" height="30" className="ImageRecoHVT" src={`${savedImgUrl}/horizon_vietnam_voyage/voyagesolidaire-enddoc-1650422159343.png`} />
               </Grid>
               <Grid item xs={2}>
                 <img width="50" height="30" className="ImageRecoLicenceHVT" src={`${savedImgUrl}/horizon_vietnam_voyage/licence-enddoc-1650419522616.png`} />
               </Grid>
             </Grid>
           </div>
           )}
                    <Interweave content={langageTranslation.finTextDoc} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )
      return myPage
    }

    const headerHtml = renderToStaticMarkup(enTete)
    const pageStandard = renderToStaticMarkup(myPageFunc('HTMLPDF'));
    const pageHtmlWord = renderToStaticMarkup(myPageFunc('HTMLWORD'));

    const urlBel = urlDevis === '' ? tripDetails[0].urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro') : urlDevis.replace('https://duyendoc.s3.ap-south-1.amazonaws.com', 'https://resa.duyen.pro');
    const html = `
   <!DOCTYPE html>
   <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlHorizon.css">
     <title>Votre proposition de voyage avec Horizon Vietnam Travel</title>
     <link rel="canonical" href="votre programme">
     </head>
     <body>
    <a class="printLink" href="#" OnClick="javascript:window.print()">
    <img src="https://duyen-pictures.s3.ap-south-1.amazonaws.com/imagepublic/telecharger-pdf.jpg"/>
    </a>
    ${devisContent}
     </body>
   </html>
   `;

    const htmlWord = `
   <!DOCTYPE html>
   <html
xmlns:o='urn:schemas-microsoft-com:office:office'
xmlns:w='urn:schemas-microsoft-com:office:word'
xmlns='http://www.w3.org/TR/REC-html40'>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta  Content-Disposition: "inline"/>
     <link rel="stylesheet" href="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlHorizon.css">
     <title>Votre proposition de voyage avec Horizon Vietnam Travel</title>
     <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title></title>
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:View>Print</w:View>
  <w:Zoom>100</w:Zoom>
</w:WordDocument>
</xml><![endif]-->
<style>
<!-- /* Style Definitions */
p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}
@page Section1{
    size:8.5in 11.0in;
    margin:0.5in 0.5in 0.5in 0.5in;
    mso-header-margin:0.5in;
    mso-header:h1;
    mso-footer:f1;
    mso-footer-margin:0.5in;
    mso-paper-source:0;
}
div.Section1{
    page:Section1;
}
table#hrdftrtbl{
    margin:0in 0in 0in 9in;
} 
-->
</style>
<style type="text/css" media="screen,print">
body {
    font-family: "Calibri", "Verdana","HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size:12pt;
}
pageBreak {
  clear:all;
  page-break-before:always;
  mso-special-character:line-break;
}
</style>
     </head>
     <body style='tab-interval:.5in'>
     <div class="Section1">
     ${pageHtmlWord}
     <table id='hrdftrtbl' border='1' cellspacing='0' cellpadding='0'>
     <tr>
         <td>
             <div style='mso-element:header' id="h1" >
                 <p class="MsoHeader">
                     <table border="0" width="100%">
                         <tr>
                             <td>
                               ${headerHtml}
                             </td>
                         </tr>
                     </table>
                 </p>
            </div>
       </td>     
 </tr>
 </table>
</div>
</body>
</html>
   `;
    return (
      <>
        <div className="urlBox">
          <Grid container spacing={0} justifyContent="flex-start">
            <br />
            <Grid item xs={2}>
              <ActionButton
                label="Generate word"
                onClick={() => this.Export2Word(htmlWord, `${tripDetails[0].nom_devis}.docx`)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate PDF"
                onClick={() => this.handlePrint(html)}
              />
            </Grid>
            <Grid item xs={2}>
              <ActionButton
                label="Generate URL"
                onClick={() => this.dropFile(html)}
              />
            </Grid>
            <Grid item xs={5}>
              <span className="titrounet" style={Couleurdefaut}>Link: </span><span className="urlDevisHorizon">{urlBel} </span>
              <CopyToClipboard text={urlBel}><img title="Copy URL" className="copyUrl" src={duplicate} /></CopyToClipboard>
              <br />
              <span className="titrounet" style={Couleurdefaut}>Last link update: </span>
              <span>{dateUpdateUrl === '' ? moment(tripDetails[0].date_modif_url).format('YYYY-MM-DD HH:mm') : dateUpdateUrl}</span>
              <br />
            </Grid>
            <Grid item xs={1}>
              <img className="copyUrl" title="Go to gallery" src={iconeimage} onClick={this.redirectToGallery} />
            </Grid>
          </Grid>
        </div>
        <TextEditor
          value={_.isEmpty(devisContent) ? pageStandard : devisContent}
          linkCss="https://duyen-pictures.s3.ap-south-1.amazonaws.com/css-template-devis/GenerateHtmlHorizonTest.css"
          handleEditorChange={content => this.handleEditorChange(content)}
          height={1000}
        />
        <ActionButton
          label="SAVE"
          onClick={() => this.handleSave(devisContent)}
        />
      </>

    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  tripDevisFinal: state.api.tripDevisFinal,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  PUT_TRIP_DETAILS_URL: (token, tripDetails) => dispatch({
    type: 'PUT_TRIP_DETAILS_URL',
    token,
    tripDetails,
  }),
  PUT_TRIP_DEVIS_FINAL: (token, tripDevisFinal, action) => dispatch({
    type: 'PUT_TRIP_DEVIS_FINAL',
    token,
    tripDevisFinal,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateHtmlDuyen3))
