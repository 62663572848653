import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete';

import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  Loader,
  AddButton,
} from '../../../components'
import activitiesColumns from '../../../components/utils/table/activitiesColumns'

const {
  Edit,
} = tableIcons

class ActivitiesTable extends PureComponent {
  static propTypes = {
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    DELETE_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    RESET_ACTIVITY_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    activityList: PropTypes.array,
    deleteActivityContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    activityList: [],
    deleteActivityContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateActivitiesList = this.updateActivitiesList.bind(this)
    this.deleteActivity = this.deleteActivity.bind(this)
    this.getIdActivity = this.getIdActivity.bind(this)
    this.goToActivity = this.goToActivity.bind(this)
    this.editActivity = this.editActivity.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      activityList: [],
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      activityList,
      GET_ACTIVITY_LIST,
      LOADER,
    } = this.props
    const { userId } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(activityList)) {
      GET_ACTIVITY_LIST(userId)
      LOADER(true)
    } else {
      this.setState({
        activityList,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.state
    const {
      activityList,
      deleteActivityContent,
      RESET_ACTIVITY_CONTENT,
      GET_ACTIVITY_LIST,
      LOADER,
    } = this.props
    if (deleteActivityContent && deleteActivityContent.success) {
      RESET_ACTIVITY_CONTENT()
      GET_ACTIVITY_LIST(userId)
      this.reload(deleteActivityContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (deleteActivityContent && !deleteActivityContent.success) {
      RESET_ACTIVITY_CONTENT()
      this.updateActivitiesList()
      this.reload(deleteActivityContent.message)
      this.resetValidMsg()
      LOADER(false)
    }
    if (!_.isEqual(activityList, prevProps.activityList)) {
      LOADER(false)
      this.updateActivitiesList()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  updateActivitiesList = () => {
    const { activityList } = this.props
    this.setState({
      activityList,
    })
  }

  getIdActivity = (rowsSelected) => {
    const {
      activityList,
    } = this.props
    return activityList[rowsSelected.data[0].dataIndex].id_activity
  }

  deleteActivity = (rowsDeleted) => {
    const { token } = this.state
    const {
      activityList,
      DELETE_ACTIVITY_CONTENT,
      LOADER,
    } = this.props
    const idActivity = this.getIdActivity(rowsDeleted)


    if (confirm('Do you really want to delete activity ?')) {
      LOADER(true)
      DELETE_ACTIVITY_CONTENT(token, idActivity)
      // update state without travelers deleted
      const newActivityList = _.cloneDeep(activityList)
      newActivityList.splice(idActivity, 1)
      this.setState({
        activityList: newActivityList,
      })
    }
  }

  goToActivity = (data = {}) => {
    const { history } = this.props
    history.push('activity', { ...data })
  }

  editActivity = (rowsSelected) => {
    const idActivity = this.getIdActivity(rowsSelected)
    this.goToActivity({
      source: 'activities-table',
      idActivity,
    })
  }

  // ************************* Display *************************************
  // custom tool bar when an activity is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editActivity(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteActivity(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => (
    <AddButton
      title="Add new activity"
      goTo={() => this.goToActivity()}
    />
  )

  render() {
    const {
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      activityList,
    } = this.state
    const { isLoading } = this.props
    const activityList2 = activityList.map((obj) => {
      const isExpired = moment(obj.date_expiration_price).isBefore(moment());
      return { ...obj, date_expire: isExpired };
    });
    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'activities_list.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      // onRowsDelete: (rowsDeleted, data) => this.deleteDay(rowsDeleted, data),
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="resourcesContainer">
              <h2>ACTIVITIES AND SERVICES LIST</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <MUIDataTable
                className="table"
                title="activities"
                data={activityList2}
                columns={activitiesColumns}
                options={options}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  activityList: _.filter(state.activityReducer.activityList, aL => aL.active === '1'),
  // daysList: _.filter(state.api.daysList, d => d.id_journee !== 1),
  deleteActivityContent: state.activityReducer.deleteActivityContent,

})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  DELETE_ACTIVITY_CONTENT: (token, activityId) => dispatch({
    type: 'DELETE_ACTIVITY_CONTENT',
    token,
    activityId,
  }),
  RESET_ACTIVITY_CONTENT: () => dispatch({
    type: 'RESET_ACTIVITY_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivitiesTable))
