import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import {
  Template,
  Loader,
  BackButton,
} from '../../../components'
import {
  ResourcesFilter,
  TimelineScheduler,
  ModalSchedule,
} from './component'

class ResourcesSchedule extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    resources: PropTypes.array,
    events: PropTypes.array,
    putEvent: PropTypes.object,
    deleteEvent: PropTypes.object,
    LOADER: PropTypes.func.isRequired,
    GET_RESOURCES: PropTypes.func.isRequired,
    GET_RESERVATIONS: PropTypes.func.isRequired,
    PUT_RESERVATIONS: PropTypes.func.isRequired,
    DELETE_RESERVATIONS: PropTypes.func.isRequired,
    RESET_RESERVATIONS: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isLoading: false,
    resources: [],
    events: [],
    putEvent: {},
    deleteEvent: {},
  }

  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this)
    this.resetFilter = this.resetFilter.bind(this)
    this.updateState = this.updateState.bind(this)
    this.reloadEvent = this.reloadEvent.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.checkPeriod = this.checkPeriod.bind(this)
    this.configModal = this.configModal.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.handleActionEvent = this.handleActionEvent.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
    this.goBack = this.goBack.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      resourcesFiltred: [],
      resourcesAndPeriodFiltred: [],
      events: [],
      isModalOpen: false,
      modalType: 'create',
      itemSelected: {},
      source: null,
      nomCircuit: '',
    }
  }

  componentDidMount() {
    const {
      history,
      LOADER,
      GET_RESOURCES,
      GET_RESERVATIONS,
    } = this.props
    const {
      token,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (history.location.state) {
      const { source, nomCircuit } = history.location.state
      this.setState({
        source,
      })
      this.setState({
        nomCircuit,
      })
    }
    // if (_.isEmpty(resources)) {
    GET_RESOURCES(token)
    // }
    // if (_.isEmpty(events)) {
    GET_RESERVATIONS(token)
    // }
    LOADER(true)
  }

  componentDidUpdate(prevProps) {
    const {
      resources,
      events,
      putEvent,
      deleteEvent,
      LOADER,
    } = this.props
    if (resources !== prevProps.resources) {
      this.updateState('resourcesFiltred', resources)
      this.updateState('resourcesAndPeriodFiltred', resources)
      LOADER(false)
    }
    if (events !== prevProps.events) {
      this.updateState('events', events)
      LOADER(false)
    }
    if (putEvent && putEvent.success) {
      this.reloadEvent()
      this.resetValidMsg()
      LOADER(false)
    }
    if (deleteEvent && deleteEvent.success) {
      this.reloadEvent()
      this.resetValidMsg()
      LOADER(false)
    }
  }

  updateState = (field, value) => {
    this.setState({
      [field]: value,
    })
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reloadEvent = () => {
    const { token } = this.state
    const {
      RESET_RESERVATIONS,
      GET_RESERVATIONS,
    } = this.props
    RESET_RESERVATIONS()
    GET_RESERVATIONS(token)
    this.setState({
      validMsg: 'Event is saved !',
    })
  }

  // ***************** Filter *******************************
  updateFilter = (filter, action) => {
    const { resourcesFiltred } = this.state
    const { resources } = this.props
    let newResources = action === 'remove' ? _.cloneDeep(resources) : _.cloneDeep(resourcesFiltred)
    // if there is a resource filter an if it's not a timed filter : filter resource
    // eslint-disable-next-line no-prototype-builtins
    if (['category', 'sub_category', 'serial_number'].some(item => filter.hasOwnProperty(item))) {
      _.forEach(filter, (value, key) => {
        if (key !== 'start' && key !== 'end') newResources = [..._.filter(newResources, { [key]: value })]
      })
    }

    this.setState({
      resourcesFiltred: newResources,
    }, () => {
      if (action === 'remove' && filter.start && filter.end) this.checkPeriod(filter)
      else {
        this.setState({
          resourcesAndPeriodFiltred: newResources,
        })
      }
    })
  }

  checkPeriod = (filter) => {
    const {
      events,
      resourcesFiltred,
    } = this.state
    const {
      start,
      end,
    } = filter
    if (!start || !end) return null
    if (moment(start).isAfter(moment(end))) return null
    const resourcesUnAvailable = events.filter((e) => {
      const startIsUnavailable = moment(start).isBetween(moment(e.start).format('YYYY-MM-DD'), moment(e.end).format('YYYY-MM-DD'), undefined, '[]');
      const endIsUnavailable = moment(end).isBetween(moment(e.start).format('YYYY-MM-DD'), moment(e.end).format('YYYY-MM-DD'), undefined, '[]');
      const startIsBefore = moment(start).isBefore(moment(e.start).format('YYYY-MM-DD'))
      const endIsAfter = moment(end).isAfter(moment(e.end).format('YYYY-MM-DD'))
      if (startIsBefore && endIsAfter) return e
      if (!startIsUnavailable && !endIsUnavailable) return null
      return e
    }).map(r => r.resourceId)

    const periodResourcesFiltered = resourcesFiltred.filter((r) => {
      if (resourcesUnAvailable.includes(r.id)) return null
      return r
    })

    this.setState({
      resourcesAndPeriodFiltred: periodResourcesFiltered,
    })
    return null
  }

  resetFilter = () => {
    const { resources } = this.props
    this.setState({
      resourcesFiltred: resources,
      resourcesAndPeriodFiltred: resources,
    })
  }

  // ****************** Modal *******************************
  configModal = (config) => {
    const {
      isModalOpen,
      modalType,
      item,
    } = config
    this.setState({
      isModalOpen: isModalOpen,
      modalType: modalType,
      itemSelected: item,
    })
  }

  modalClose = (cancel) => {
    const { token } = this.state
    const {
      RESET_RESERVATIONS,
      GET_RESERVATIONS,
      LOADER,
    } = this.props
    this.setState({
      isModalOpen: false,
    });
    if (cancel) {
      RESET_RESERVATIONS()
      GET_RESERVATIONS(token)
      LOADER(true)
    }
  };

  deleteEvent = (ev) => {
    if (confirm('Do you really want to delete this event ?')) {
      this.setState({
        modalType: 'delete',
      }, () => this.handleActionEvent(ev))
    }
  }

  handleActionEvent = (ev) => {
    const {
      token,
      modalType,
    } = this.state
    const {
      LOADER,
      PUT_RESERVATIONS,
      DELETE_RESERVATIONS,
    } = this.props
    this.setState({
      isModalOpen: false,
    })
    switch (modalType) {
      case 'delete':
        if (ev.id) DELETE_RESERVATIONS(token, ev.id)
        break;
      case 'create':
      case 'modify':
        PUT_RESERVATIONS(token, ev, modalType)
        break;
      default:
        break;
    }
    LOADER(true)
  }

  goBack = () => {
    const { history } = this.props
    const { source } = this.state
    if (source) history.push(source)
  }

  // ***************** Render *******************************
  render() {
    const {
      validMsg,
      errorMsg,
      resourcesFiltred,
      resourcesAndPeriodFiltred,
      events,
      isModalOpen,
      modalType,
      itemSelected,
      source,
      nomCircuit,
    } = this.state
    const {
      resources,
      isLoading,
    } = this.props

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>SCHEDULE</h2>
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              {source && (
                <BackButton
                  type="button"
                  label="Back"
                  goBack={() => this.goBack()}
                />
              )}
              {/* Filters */}
              <div className="containerRow">
                <div className="scheduler-filter-container">
                  <h3>FILTER</h3>
                  <ResourcesFilter
                    resourcesFiltred={resourcesFiltred}
                    updateFilter={(filter, action) => this.updateFilter(filter, action)}
                    checkPeriod={filter => this.checkPeriod(filter)}
                    resetFilter={this.resetFilter}
                  />
                </div>
              </div>

              {/* Scheduler */}
              <div className="containerRow">
                {resourcesAndPeriodFiltred && (
                  <TimelineScheduler
                    resources={resourcesAndPeriodFiltred}
                    events={events}
                    configModal={config => this.configModal(config)}
                    deleteEvent={ev => this.deleteEvent(ev)}
                  />
                )}
              </div>
            </div>
          </div>
        </Template>
        <ModalSchedule
          resources={resources}
          nomCircuit={nomCircuit}
          type={modalType}
          itemSelected={itemSelected}
          isModalOpen={isModalOpen}
          onClose={cancel => this.modalClose(cancel)}
          handleActionEvent={ev => this.handleActionEvent(ev)}
          deleteEvent={ev => this.deleteEvent(ev)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  userAccount: state.userAccountReducer.userAccount,
  resources: state.resourceReducer.resources,
  events: state.resourceReducer.events,
  putEvent: state.resourceReducer.putEvent,
  deleteEvent: state.resourceReducer.deleteEvent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_RESOURCES: token => dispatch({
    type: 'GET_RESOURCES',
    token,
  }),
  GET_RESERVATIONS: token => dispatch({
    type: 'GET_RESERVATIONS',
    token,
  }),
  PUT_RESERVATIONS: (token, event, action) => dispatch({
    type: 'PUT_RESERVATIONS',
    token,
    event,
    action,
  }),
  DELETE_RESERVATIONS: (token, eventId) => dispatch({
    type: 'DELETE_RESERVATIONS',
    token,
    eventId,
  }),
  RESET_RESERVATIONS: () => dispatch({
    type: 'RESET_RESERVATIONS',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResourcesSchedule))
