import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getCollaboratorsResults,
  putCollaboratorsResults,
  // deleteCollaboratorsListResults,
} from './actions'

function* getCollaboratorsAsync(params) {
  const {
    token,
  } = params
  const data = yield call(api.get_collaborators, token)
  yield put(getCollaboratorsResults(data))
}

function* putCollaboratorsAsync(params) {
  const {
    token,
    collaborators,
  } = params
  const data = yield call(api.put_collaborators, token, collaborators)
  yield put(putCollaboratorsResults(data))
}

// function* deleteCollaboratorsAsync(params) {
//   const {
//     token,
//     customerId,
//   } = params
//   const data = yield call(api.delete_customer_content, token, customerId)
//   yield put(deleteCollaboratorsResults(data))
// }

function* fetchResult() {
  yield takeEvery('GET_COLLABORATORS', getCollaboratorsAsync)
  yield takeEvery('PUT_COLLABORATORS', putCollaboratorsAsync)
//   yield takeEvery('DELETE_COLLABORATORS_', deleteCollaboratorsAsync)
}

export default fetchResult
