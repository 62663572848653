/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import TexteSimple from './components/TexteSimple';
// import CircuitHtml from '..'


class GenerateBill extends React.Component {
  render() {
    return (
      <TexteSimple />
    );
  }
}


export default (GenerateBill)
