/* eslint-disable react/jsx-filename-extension */

import React from 'react'
import { useJsApiLoader } from '@react-google-maps/api';
import MapDirectionsRenderer from './Map'

export default function ApiLoader() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBf5L8MeduGyZDoRS7HAC_O-rqsvE9Jg5c',
  })

  return (isLoaded ? (
    <MapDirectionsRenderer />
  ) : <></>)
}
