import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Template,
  InvoiceManagement,
} from '../../components'

class Invoice extends PureComponent {
  static propTypes = {
    GET_INVOICE_LIST: PropTypes.func.isRequired,

  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state

    }
  }

  componentDidMount() {
    const {
      GET_INVOICE_LIST,

    } = this.props
    GET_INVOICE_LIST()
  }


  render() {
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <InvoiceManagement />
          </div>
        </Template>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  invoiceList: state.invoiceReducer.invoiceList,
})

const mapDispatchToProps = dispatch => ({
  GET_INVOICE_LIST: userId => dispatch({
    type: 'GET_INVOICE_LIST',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoice))
