import axios from 'axios'
import stage from '../../../../config'

const apiUrl = stage.api.duyen.url
export default {
  get_suppliers_list(userId) {
    return axios.get(`${apiUrl}/api/lists/suppliersList/${userId}`)
      .then((res) => {
        if (res.data.error) return res.data
        if (res.status === 200) return res.data
        return null
      })
  },


  put_suppliers_content(token, suppliersContent, action) {
    switch (action) {
      case 'update':
        return axios.put(`${apiUrl}/api/suppliers/update`, { suppliersContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      case 'create':
        return axios.put(`${apiUrl}/api/suppliers/create`, { suppliersContent }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(res => res.data)
      default:
        return null;
    }
  },

  delete_suppliers_content(token, supplierId) {
    return axios.delete(`${apiUrl}/api/suppliers/delete/${supplierId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.data)
  },

}
