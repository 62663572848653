import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import tableIcons from '../../../components/utils/icons/icons'

export default function RequestTable(props) {
  const {
    tableName,
    widthDiv,
    userId,
    request,
    columns,
    title,
    deleteRequest,
    showRequest,
    editRequest,
    acceptRequest,
    isUserAuthorized,
  } = props


  const deleteReq = (event, requestDeleted) => {
    deleteRequest(requestDeleted)
  }

  return (
    <MaterialTable
      style={{ marginBottom: '30px', width: widthDiv }}
      title={title}
      data={request}
      columns={columns}
      icons={tableIcons}
      options={{
        filtering: true,
        search: false,
        sorting: false,
        headerStyle: { textAlign: 'left' },
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export quote'),
        }, {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export quote'),
        }],
        actionsColumnIndex: -1,
        columnResizable: true,
        doubleHorizontalScroll: true,
        columnsButton: true,
        pageSize: 5,
        pageSizeOptions: [5, 10, 20],
      }}
      actions={[
        {
          icon: tableIcons.Show,
          tooltip: 'Show request',
          onClick: (event, rowData) => showRequest(rowData.id_request),
        },
        title === 'Pending Request' && {
          icon: tableIcons.Edit,
          tooltip: 'Edit request',
          onClick: (event, rowData) => editRequest(rowData.id_request),
        },
        rowData => ({
          icon: tableIcons.Accept,
          tooltip: 'Accept request',
          onClick: () => acceptRequest(rowData.id_request),
          hidden: rowData.id_conseiller_dest !== userId || tableName === 'allRequest',
        }),
        {
          icon: tableIcons.Delete,
          tooltip: 'Delete request',
          onClick: (event, rowData) => deleteReq(event, rowData),
          hidden: !isUserAuthorized,
        },
      ]}
    />
  );
}

RequestTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  widthDiv: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  request: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isUserAuthorized: PropTypes.bool.isRequired,
  deleteRequest: PropTypes.func.isRequired,
  showRequest: PropTypes.func.isRequired,
  editRequest: PropTypes.func.isRequired,
  acceptRequest: PropTypes.func,
};

RequestTable.defaultProps = {
  acceptRequest: () => {},
};
