// Trip
export const GET_TRIP_EXTENSION_RESULTS = 'GET_TRIP_EXTENSION_RESULTS'
export const PUT_TRIP_EXTENSION_RESULTS = 'PUT_TRIP_EXTENSION_RESULTS'
export const RESET_TRIP_EXTENSION = 'RESET_TRIP_EXTENSION'

export const resetTripExtension = () => ({
  type: RESET_TRIP_EXTENSION,
})

export const getTripExtensionResults = data => ({
  type: GET_TRIP_EXTENSION_RESULTS,
  tripExtension: data,
})

export const putTripExtensionResults = data => ({
  type: PUT_TRIP_EXTENSION_RESULTS,
  putTripExtension: data,
})
