import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getUserAccountResults,
  putUserAccountResults,
  // deleteUserAccountResults,
} from './actions'

function* getUserAccountAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_user_account, userId)
  yield put(getUserAccountResults(data))
}

function* putUserAccountAsync(params) {
  const {
    token,
    userAccount,
    userId,
  } = params
  const data = yield call(api.put_user_account, token, userAccount, userId)
  yield put(putUserAccountResults(data))
}

// function* deleteUserAccountAsync(params) {
//   const {
//     token,
//     customerId,
//   } = params
//   const data = yield call(api.delete_customer_content, token, customerId)
//   yield put(deleteUserAccountResults(data))
// }

function* fetchResult() {
  yield takeEvery('GET_USER_ACCOUNT', getUserAccountAsync)
  yield takeEvery('PUT_USER_ACCOUNT', putUserAccountAsync)
//   yield takeEvery('DELETE_USER_ACCOUNT', deleteUserAccountAsync)
}

export default fetchResult
