import React from 'react'
import ReactDOM from 'react-dom'
import '@babel/polyfill'
import './assets/styles/index.scss'
import './assets/fonts/Myriad-Arabic-Regular.ttf'
import './assets/fonts/AvertaDemo-ExtraBoldItalic.otf'
import './assets/fonts/AvertaDemo-Regular.otf'
import Router from './redux/router'
// import * as serviceWorker from './serviceWorker'
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Router />,
  document.getElementById('root'),
)
registerServiceWorker()
