import React from 'react'
import PropTypes from 'prop-types';
import isAllowed from '../utils/profilAuthorization'

export default function AllowedToChange(props) {
  const {
    userId,
    profil,
    tripDetails,
  } = props

  const isAllowedToChange = profil && tripDetails ? isAllowed(userId, profil, tripDetails) : true

  if (isAllowedToChange) return null
  return (
    <p className="profil-authorization">You are not allowed to modify this trip</p>
  )
}

AllowedToChange.propTypes = {
  userId: PropTypes.string.isRequired,
  profil: PropTypes.number.isRequired,
  tripDetails: PropTypes.array.isRequired,
}
