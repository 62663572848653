import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getActivityListResults,
  putActivityContentResults,
  deleteActivityContentResults,
} from './actions'

function* getActivityListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_activity_list, userId)
  yield put(getActivityListResults(data))
}

function* putActivityContentAsync(params) {
  const {
    token,
    activityContent,
    action,
  } = params
  const data = yield call(api.put_activity_content, token, activityContent, action)
  yield put(putActivityContentResults(data))
}

function* deleteActivityContentAsync(params) {
  const {
    token,
    activityId,
  } = params
  const data = yield call(api.delete_activity_content, token, activityId)
  yield put(deleteActivityContentResults(data))
}

function* fetchResult() {
  yield takeEvery('GET_ACTIVITY_LIST', getActivityListAsync)
  yield takeEvery('PUT_ACTIVITY_CONTENT', putActivityContentAsync)
  yield takeEvery('DELETE_ACTIVITY_CONTENT', deleteActivityContentAsync)
}

export default fetchResult
