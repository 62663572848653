import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import { CssGrid } from '../../configCSS'
import {
  Template,
  ActionButton,
  TextEditor,
  Uploader,
  Input,
} from '../../components'
import stage from '../../config'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage

class Company extends PureComponent {
  static propTypes = {
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    PUT_COMPANY_CONTENT: PropTypes.func.isRequired,
    RESET_COMPANY_CONTENT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    companyContent: PropTypes.object,
    //  informationsContent: PropTypes.object,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    companyContent: {},
    //   informationsContent: {},
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.reload = this.reload.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.displayContactInformations = this.displayContactInformations.bind(this)
    this.displayCompanyContentInputs = this.displayCompanyContentInputs.bind(this)
    this.validate = this.validate.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.state = {
      hasChangedCompanyContent: false,
      companyContent: {},
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      newImagelogo: [],
      newURLlogo: [],
      isValidated: false,
    }
  }

  componentDidMount() {
    const {
      history,
      companyContent,
      userAccount,
      GET_COMPANY_CONTENT,
      GET_USER_ACCOUNT,
    } = this.props
    const {
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(userAccount)) GET_USER_ACCOUNT(userId)
    // check if props exists
    if (!_.isEmpty(companyContent)) {
      // come back from 'Gallery'
      if (history.location.state) {
        const {
          state,
          currentImg,
          pictureSelected,
        } = history.location.state
        this.setState({
          companyContent: {
            ...state.item,
            logo: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
        })
      } else {
        this.setState({
          companyContent,
        })
      }
    } else {
      GET_COMPANY_CONTENT(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      companyContent: companyContentOld,
    } = this.props
    const {
      companyContent,
      putCompanyContent,
    } = nextprops
    // Content saved in DB -> get back updated content
    if (putCompanyContent && putCompanyContent.success) {
      this.reload()
      this.setState({
        validMsg: 'Modification was successfully recorded !',
        hasChangedCompanyContent: false,
        isValidated: false,
      })
      this.resetValidMsg()
    }
    if (companyContent !== companyContentOld) {
      this.setState({ companyContent })
    }
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const {
      userId,
    } = this.state
    const {
      RESET_COMPANY_CONTENT,
      GET_COMPANY_CONTENT,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
    } = this.props
    RESET_COMPANY_CONTENT()
    ROW_TRIP_SELECT([])
    RESET_TRIP_INFORMATION()
    GET_COMPANY_CONTENT(userId)
  }

  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = () => {
    const {
      companyContent,
      newURLlogo,
      newImagelogo,
    } = this.state
    const {
      company,
      logo,
    } = companyContent

    // display image(s) modified
    if (!_.isEmpty(newURLlogo)) return [newURLlogo, newImagelogo]

    const imageNameArray = logo ? logo.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default.png`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray, field) => {
    this.setState({
      [`newImage${field}`]: imagesDataArray.map(img => img.imageName),
      [`newURL${field}`]: imagesDataArray.map(img => img.imageUrl),
      hasChangedCompanyContent: true,
    });
  }

  deleteImage = (urlArray, imageArray, field) => {
    const { companyContent } = this.state
    this.setState({
      companyContent: {
        ...companyContent,
        [field]: imageArray.join(),
      },
      [`newImage${field}`]: imageArray,
      [`newURL${field}`]: urlArray,
      hasChangedCompanyContent: true,
    })
  }

  redirectToGallery = () => {
    const {
      companyContent,
    } = this.state
    const {
      history,
    } = this.props
    // this.validate()
    history.push('gallery', {
      currentPage: 'company',
      isSelectionAction: true,
      nbMaxImg: 1,
      currentImg: companyContent.logo ? companyContent.logo.split(',') : [],
      state: {
        item: companyContent,
      },
    })
  }

  // On change Editor
  handleEditorChange = field => (content) => {
    const { companyContent } = this.state
    this.setState({
      companyContent: {
        ...companyContent,
        [field]: content,
      },
      validateError: false,
      errorMsg: null,
    });
  }


  // save all other input field
  handleChange = field => (event) => {
    const { companyContent } = this.state
    this.setState({
      companyContent: {
        ...companyContent,
        [field]: event.target.value,
      },
      validateError: false,
      errorMsg: null,
    })
  }

  // Editor
  displayTextEditor = (field) => {
    const { companyContent } = this.state
    const { userAccount } = this.props
    const { profil } = userAccount
    const fieldDisabled = !profil || profil > 1
    return (
      <TextEditor
        object={companyContent}
        value={companyContent[field]}
        handleEditorChange={this.handleEditorChange(field)}
        disabled={fieldDisabled}
      />
    )
  }

  displayContactInformations = (fieldDisabled) => {
    const {
      companyContent,
    } = this.state
    return (
      <div>
        {/* adresse */}
        <Input
          id="adresse"
          label="Adress"
          multiline
          value={!_.isEmpty(companyContent) && companyContent.adresse ? companyContent.adresse : ''}
          onChange={this.handleChange('adresse')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
          disabled={fieldDisabled}
        />
        <CssGrid
          container
          spacing={2}
        >
          {/* phone */}
          <CssGrid item md={4} xs={12}>
            <Input
              id="phone"
              label="Phone"
              value={!_.isEmpty(companyContent) && companyContent.phone ? companyContent.phone : ''}
              onChange={this.handleChange('phone')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              disabled={fieldDisabled}
            />
          </CssGrid>
          {/* mobile */}
          <CssGrid item md={4} xs={12}>
            <Input
              id="mobile"
              label="Mobile"
              value={!_.isEmpty(companyContent) && companyContent.mobile ? companyContent.mobile : ''}
              onChange={this.handleChange('mobile')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              disabled={fieldDisabled}
            />
          </CssGrid>
          {/* fax */}
          <CssGrid item md={4} xs={12}>
            <Input
              id="fax"
              label="Fax"
              value={!_.isEmpty(companyContent) && companyContent.fax ? companyContent.fax : ''}
              onChange={this.handleChange('fax')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
              disabled={fieldDisabled}
            />
          </CssGrid>
        </CssGrid>
        {/* website */}
        <Input
          id="website"
          label="Website"
          value={!_.isEmpty(companyContent) && companyContent.website ? companyContent.website : ''}
          onChange={this.handleChange('website')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
          disabled={fieldDisabled}
        />
        {/* mail */}
        <Input
          id="mail"
          label="Mail"
          value={!_.isEmpty(companyContent) && companyContent.mail ? companyContent.mail : ''}
          onChange={this.handleChange('mail')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
          disabled={fieldDisabled}
        />
      </div>
    )
  }

  // return all other input
  displayCompanyContentInputs = () => {
    const {
    //  informationsContent,
      userAccount,
    } = this.props
    // const { theme_devis } = informationsContent
    const { profil } = userAccount
    const fieldDisabled = !profil || profil > 1
    const slogan = userAccount.language ? `${userAccount.language}_slogan` : 'fr_slogan'
    return (
      <div>
        <CssGrid
          container
          spacing={2}
        >

          <CssGrid item md={9} xs={12}>
            {/* Slogan */}
            <div className="editorStyle">
              <p className="editorTitle background-white">Text for header</p>
              {this.displayTextEditor(slogan)}
            </div>
            <div className="editorStyle">
              <p className="editorTitle background-white">Text for footer</p>
              {this.displayTextEditor('description')}
            </div>
          </CssGrid>

          {/* Uploader */}
          <CssGrid item md={3} xs={12} className="logo-company-div">
            <p className="logo-label">Logo</p>
            <Uploader
              imageDataArray={this.getImageUrl('logo')}
              saveImage={imagesDataArray => this.saveImage(imagesDataArray, 'logo')}
              redirectToGallery={() => this.redirectToGallery()}
              deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray, 'logo')}
              allowDrop={!fieldDisabled}
            />
          </CssGrid>
        </CssGrid>
        {/* description NOT USED */}
        {/* <div className="editorStyle">
          <p className="editorTitle background-white">Description</p>
          {this.displayTextEditor('description')}
        </div> */}
        {this.displayContactInformations(fieldDisabled)}

      </div>
    )
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      token,
      companyContent,
      newImagelogo,
    } = this.state
    const {
      PUT_COMPANY_CONTENT,
    } = this.props

    // save images
    const newCompanyContent = {
      ...companyContent,
      logo: newImagelogo.join() || companyContent.logo,
    }
    this.setState({
      isValidated: true,
    })
    PUT_COMPANY_CONTENT(token, newCompanyContent)
  }

  render() {
    const {
      hasChangedCompanyContent,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      companyContent,
    } = this.state
    const { userAccount } = this.props
    const { profil } = userAccount
    const fieldDisabled = !profil || profil > 1
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>MY COMPANY</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <div className="company-name">
                <p>{companyContent.company ? companyContent.company.toUpperCase() : null}</p>
              </div>
              {this.displayCompanyContentInputs()}
            </div>
            <ActionButton
              label="Validate"
              disabled={fieldDisabled}
              onClick={e => this.validate(e)}
            />
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={hasChangedCompanyContent && !isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
  putCompanyContent: state.companyReducer.putCompanyContent,
  userAccount: state.userAccountReducer.userAccount,
  informationsContent: state.informationsReducer.informationsContent,
  tripDetails: state.api.tripDetails,
})

const mapDispatchToProps = dispatch => ({
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
  PUT_COMPANY_CONTENT: (token, companyContent) => dispatch({
    type: 'PUT_COMPANY_CONTENT',
    token,
    companyContent,
  }),
  RESET_COMPANY_CONTENT: () => dispatch({
    type: 'RESET_COMPANY_CONTENT',
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Company))
