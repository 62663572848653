/* eslint-disable consistent-return */
import _ from 'lodash'

const accoColumns = [
  {
    title: 'Thematic',
    field: 'activity_theme',
    editable: 'never',
    resizable: true,
    cellStyle: (e, rowData) => {
      if (rowData.activity_type === 'individual') {
        return {
          backgroundColor: '#CB8562',
          borderRadius: 5,
          textAlign: 'center',
        };
      }
      if (rowData.activity_type === 'group' || _.isEmpty(rowData.activity_type)) {
        return {
          backgroundColor: '#CBC062',
          borderRadius: 5,
          textAlign: 'center',
        };
      }
    },
  },
  {
    title: 'Supplier name',
    field: 'supplier',
    editable: 'never',
    resizable: true,
  },

  {
    title: 'Service name',
    field: 'nomService',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Room detail',
    field: 'roomDetail',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Currency',
    field: 'currencyService',
    editable: 'never',
    resizable: true,
  },

  {
    title: 'Price calculated from database by room',
    field: 'priceCalculatedAdult',
    editable: 'never',
    resizable: true,
    hidden: true,
  },


  {
    title: 'Price for cotation for Hotel for all Rooms',
    field: 'final_price_adult',
    editable: 'never',
    resizable: true,
    cellStyle: (e, rowData) => {
      if (rowData.final_price_adult !== null && rowData.final_price_adult !== rowData.priceCalculatedAdultSum) {
        return {
          backgroundColor: '#A3D1CE',
          borderRadius: 5,
          textAlign: 'center',
        };
      }

      return {
        backgroundColor: '#BED3B4',
        borderRadius: 5,
        textAlign: 'center',
      }
    },
  },

]


// eslint-disable-next-line import/prefer-default-export
export { accoColumns }
