import {
  GET_SUPPLIERS_LIST_RESULTS,
  RESET_SUPPLIERS_CONTENT,
  PUT_SUPPLIERS_CONTENT_RESULTS,
  DELETE_SUPPLIERS_CONTENT_RESULTS,
} from './actions'

const initialState = {
  suppliersList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS_LIST_RESULTS:
      return {
        ...state,
        suppliersList: action.suppliersList,
      }
    case RESET_SUPPLIERS_CONTENT:
      return {
        ...state,
        putSuppliersContent: { success: false },
        deleteSuppliersContent: null,
      }
    case PUT_SUPPLIERS_CONTENT_RESULTS:
      return {
        ...state,
        putSuppliersContent: action.putSuppliersContent,
      }
    case DELETE_SUPPLIERS_CONTENT_RESULTS:
      return {
        ...state,
        deleteSuppliersContent: action.deleteSuppliersContent,
      }
    default:
      return state
  }
}
