import {
  PUT_TOUR_OPERATOR_RESULTS,
  PUT_INFORMATION_COMMENT_RESULTS,
  RESET_TOUR_OPERATOR,
  PUT_TRIP_BILLING_DEPOSIT_RESULTS,
} from './actions'
import {
  RESET_APP,
  RESET_TRIP_INFORMATION,
} from '../../../components/api/actions'

const initialState = {
  putTourOperator: {},
  tripBilling: {},
  putTripBilling: null,
  putInformationComment: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState
    case RESET_TRIP_INFORMATION:
      return {
        ...state,
        putTourOperator: {},
        putInformationComment: {},
      }
    case PUT_TOUR_OPERATOR_RESULTS:
      return {
        ...state,
        putTourOperator: action.putTourOperator,
      }
    case PUT_INFORMATION_COMMENT_RESULTS:
      return {
        ...state,
        putInformationComment: action.putInformationComment,
      }
    case RESET_TOUR_OPERATOR:
      return {
        ...state,
        putTourOperator: {},
        putInformationComment: {},
      }
    case PUT_TRIP_BILLING_DEPOSIT_RESULTS:
      return {
        ...state,
        putTripBilling: action.putTripBilling,
      }
    default:
      return state
  }
}
