import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import tableIcons from '../../../../components/utils/icons/icons'
import {
  Input,
} from '../../../../components';
import { CssGrid } from '../../../../configCSS'

const {
  Show,
  Edit,
  Delete,
} = tableIcons

export default function ResourceCard(props) {
  const {
    resource,
    resourceIndex,
    openResourceCard,
    deleteResource,
  } = props;
  const {
    id,
    name,
    category,
    serial_number,
  } = resource

  return (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item xs={1} className="resource-number">
        <span>{resourceIndex + 1}</span>
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id}name`}
          label="Name"
          value={name}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={2}>
        <Input
          id={`${id}Category`}
          label="Category"
          value={category}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={2}>
        <Input
          id={`${id}serial_number`}
          label="Serial number"
          value={serial_number}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={1} className="action-resource-card" onClick={() => openResourceCard('show', resource)}>
        <Tooltip title="Show" className="tooltip-traveler">
          <Show />
        </Tooltip>
      </CssGrid>
      <CssGrid item xs={1} className="action-resource-card" onClick={() => openResourceCard('update', resource)}>
        <Tooltip title="Modify" className="tooltip-traveler">
          <Edit />
        </Tooltip>
      </CssGrid>
      <CssGrid item xs={1} className="action-resource-card" onClick={() => deleteResource(resource)}>
        <Tooltip title="Delete" className="tooltip-traveler">
          <Delete />
        </Tooltip>
      </CssGrid>
    </CssGrid>
  );
}

ResourceCard.propTypes = {
  resource: PropTypes.object.isRequired,
  resourceIndex: PropTypes.number.isRequired,
  openResourceCard: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired,
};
