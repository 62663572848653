import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
  ActionButton,
  Input,
} from '../../components'
import stage from '../../config'

const apiUrl = stage.api.duyen.url

class RecoverPassword extends PureComponent {
  state = {
    email: '',
    submitted: false,
    ctrlError: '',
  }

  error = () => {
    const { ctrlError } = this.state
    return ctrlError ? <p style={{ color: 'red' }}>{ctrlError}</p> : null
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
      submitted: false,
      ctrlError: '',
    })
  }

  controlEmailFormat = (email) => {
    const isEmail = /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/g
    return isEmail.test(email)
  }

  sendPasswordResetEmail = (e) => {
    e.preventDefault()
    const { email } = this.state
    if (this.controlEmailFormat(email)) {
      axios.post(`${apiUrl}/api/email/recover_password/user/${email}`)
      this.setState({
        email: '',
        submitted: true,
        ctrlError: '',
      })
    } else {
      this.setState({
        ctrlError: 'Format password invalid !',
        submitted: false,
      })
    }
  }

  render() {
    const { email, submitted } = this.state
    return (
      <div className="reset-pwd-container">
        <h2>Reset your password</h2>
        {submitted ? (
          <div className="reset-password-form-wrapper">
            <h3>
              If that account is in our system, we emailed you a link to reset
              your password.
            </h3>
            <h3>
              If you don't see the email, please look in your spam folder.
            </h3>
            <Link to="/" className="ghost-btn">
              Return to sign in
            </Link>
          </div>
        ) : (
          <div className="reset-password-form-wrapper">
            <h3>
              Enter your email and we'll send you reset instructions.
            </h3>
            {this.error()}
            <form onSubmit={this.sendPasswordResetEmail}>
              <Input
                id="emailRecover"
                label="Email"
                value={email}
                className="input-reset-email"
                onChange={this.handleChange}
                placeholder="Email address"
                margin="normal"
                required
              />
              <ActionButton
                className="password-reset-btn"
                type="submit"
                label="Send email"
                onClick={e => this.sendPasswordResetEmail(e)}
              />
            </form>
            <Link to="/" className="back-to-signin">I remember my password</Link>
          </div>
        )}
      </div>
    )
  }
}
export default withRouter(RecoverPassword)
