import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Input,
} from '../../../components';
import { CssGrid } from '../../../configCSS'
import stage from '../../../config'
import idCard from '../../../assets/images/id-card.png'
import idCard2x from '../../../assets/images/id-card@2x.png'
import idCard3x from '../../../assets/images/id-card@3x.png'

const savedImgUrl = stage.imgStorage

export default function CollaboratorSummary(props) {
  const {
    collaborator,
    profilUserList,
    profilChange,
    handleOpen,
  } = props;
  const {
    id_conseiller,
    firstname,
    lastname,
    image,
    company,
    profil,
  } = collaborator

  const avatar = image || 'default-avatar.jpg'

  return (
    <CssGrid
      container
      spacing={2}
    >
      <CssGrid item xs={1} className="avatar-container">
        <img src={`${savedImgUrl}/${company}/${avatar}`} alt={`avatar-${lastname}`} />
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id_conseiller}firstname`}
          label="Firstname"
          value={firstname}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id_conseiller}lastname`}
          label="Lastname"
          value={lastname}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </CssGrid>
      <CssGrid item xs={3}>
        <Input
          id={`${id_conseiller}profil`}
          label="Profil"
          value={parseInt(profil, 10)}
          onChange={e => profilChange(e, id_conseiller)}
          margin="normal"
          variant="outlined"
          select
        >
          {profilUserList.map(p => (
            <MenuItem key={`profil-${p.id_profil}`} value={p.id_profil}>
              {p.profil_utilisateur}
            </MenuItem>
          ))}
        </Input>
      </CssGrid>
      <CssGrid item xs={1} className="id-card-container" onClick={() => handleOpen(id_conseiller)}>
        <img src={`${savedImgUrl}/${company}/${idCard}`} srcSet={`${idCard2x} 2x, ${idCard3x} 3x`} alt={`avatar-${lastname}`} />
      </CssGrid>
    </CssGrid>
  );
}

CollaboratorSummary.propTypes = {
  collaborator: PropTypes.object.isRequired,
  profilUserList: PropTypes.array.isRequired,
  profilChange: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};
