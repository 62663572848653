import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import tableIcons from '../../../components/utils/icons/icons'
import {
  Template,
  AddButton,
  Loader,
} from '../../../components'
import requestorsColumns from '../../../components/utils/table/requestorsColumns'

const {
  Edit,
} = tableIcons

class RequestorTable extends PureComponent {
  static propTypes = {
    GET_CUSTOMER_LIST: PropTypes.func.isRequired,
    DELETE_CUSTOMER_CONTENT: PropTypes.func.isRequired,
    RESET_CUSTOMER_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    customerList: PropTypes.array,
    deleteCustomerContent: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    customerList: [],
    deleteCustomerContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.displayErrorMsg = this.displayErrorMsg.bind(this)
    this.resetMsg = this.resetMsg.bind(this)
    this.updateRequestorList = this.updateRequestorList.bind(this)
    this.deleteRequestors = this.deleteRequestors.bind(this)
    this.editRequestor = this.editRequestor.bind(this)
    this.getIdRequestor = this.getIdRequestor.bind(this)
    this.goToRequestor = this.goToRequestor.bind(this)
    this.displayToolBarSelect = this.displayToolBarSelect.bind(this)
    this.displayToolBar = this.displayToolBar.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      customerList: [],
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      customerList,
      GET_CUSTOMER_LIST,
      LOADER,
    } = this.props
    const { userId } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(customerList)) {
      GET_CUSTOMER_LIST(userId)
      LOADER(true)
    } else {
      this.setState({
        customerList,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { userId } = this.state
    const {
      customerList,
      deleteCustomerContent,
      RESET_CUSTOMER_CONTENT,
      GET_CUSTOMER_LIST,
      LOADER,
    } = this.props
    if (deleteCustomerContent && deleteCustomerContent.success) {
      RESET_CUSTOMER_CONTENT()
      GET_CUSTOMER_LIST(userId)
      this.reload(deleteCustomerContent.message)
      this.resetMsg()
    }
    if (deleteCustomerContent && !deleteCustomerContent.success) {
      RESET_CUSTOMER_CONTENT()
      GET_CUSTOMER_LIST(userId)
      LOADER(false)
      this.displayErrorMsg(deleteCustomerContent.message)
      this.resetMsg()
    }
    if (customerList !== prevProps.customerList) {
      LOADER(false)
      this.updateRequestorList()
    }
  }

  resetMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
        errorMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  displayErrorMsg = (msg) => {
    this.setState({
      errorMsg: msg,
    })
  }

  updateRequestorList = () => {
    const { customerList } = this.props
    this.setState({
      customerList,
    })
  }

  deleteRequestors = (rowsDeleted) => {
    const { token } = this.state
    const {
      customerList,
      DELETE_CUSTOMER_CONTENT,
      LOADER,
    } = this.props
    const indexRequestorsDeleted = rowsDeleted.data.map(d => d.dataIndex)
    if (confirm(`Do you really want to delete requestor${indexRequestorsDeleted.length > 1 ? 's' : ''} ?`)) {
      LOADER(true)
      const idRequestorsDeleted = indexRequestorsDeleted.map(i => customerList[i].id_demandeur)
      DELETE_CUSTOMER_CONTENT(token, idRequestorsDeleted)
      // update state without travelers deleted
      const newRequestorList = _.cloneDeep(customerList)
      indexRequestorsDeleted.map(id => newRequestorList.splice(id, 1))
      this.setState({
        customerList: newRequestorList,
      })
    }
  }

  editRequestor = (rowsSelected) => {
    const idRequestor = this.getIdRequestor(rowsSelected)
    this.goToRequestor({
      source: 'requestor-table',
      idRequestor,
    })
  }

  getIdRequestor = (rowsSelected) => {
    const {
      customerList,
    } = this.props
    return customerList[rowsSelected.data[0].dataIndex].id_demandeur
  }

  goToRequestor = (data = {}) => {
    const { history } = this.props
    history.push('requestor-management', { ...data })
  }

  // ************************* Display *************************************
  // custom tool bar when a day is selected
  displayToolBarSelect = selectedRows => (
    <Toolbar>
      <Tooltip title="Modify">
        <IconButton
          aria-label="modify"
          onClick={() => this.editRequestor(selectedRows)}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => this.deleteRequestors(selectedRows)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )

  // custom tool bar
  displayToolBar = () => (
    <AddButton
      title="Add new day"
      goTo={() => this.goToRequestor()}
    />
  )

  render() {
    const {
      errorMsg,
      validMsg,
      isValidated,
      customerList,
    } = this.state
    const { isLoading } = this.props

    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'multiple',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'requestors_table.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      customToolbarSelect: (selectedRows, displayData) => this.displayToolBarSelect(selectedRows, displayData),
      customToolbar: displayData => this.displayToolBar(displayData),
    }

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="travelerContainer">
              <h2>REQUESTORS LIST</h2>
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <MUIDataTable
                className="table"
                title="Requestors"
                data={customerList}
                columns={requestorsColumns}
                options={options}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  customerList: state.customerReducer.customerList,
  deleteCustomerContent: state.customerReducer.deleteCustomerContent,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_CUSTOMER_LIST: userId => dispatch({
    type: 'GET_CUSTOMER_LIST',
    userId,
  }),
  DELETE_CUSTOMER_CONTENT: (token, customerId) => dispatch({
    type: 'DELETE_CUSTOMER_CONTENT',
    token,
    customerId,
  }),
  RESET_CUSTOMER_CONTENT: () => dispatch({
    type: 'RESET_CUSTOMER_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestorTable))
