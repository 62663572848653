const pendingRequestColumns = [
  {
    title: 'Firstname',
    field: 'firstname',
    editable: 'never',
    resizable: true,
  },
  /* {
    title: 'Lastname',
    field: 'lastname',
    editable: 'never',
    resizable: true,
    // cellStyle: {
    //   width: 50,
    //   minWidth: 50,
    // },
    // headerStyle: {
    //   width: 50,
    //   minWidth: 50,
    // },
  }, */
  {
    title: 'Trip',
    field: 'trip_name',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Email',
    field: 'email',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Travel agent',
    field: 'advisor',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Status',
    field: 'status',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Creation date',
    field: 'date_request',
    editable: 'never',
    resizable: true,
  },
  // {
  //   title: 'Pick up date',
  //   field: 'date_accept',
  //   editable: 'never',
  //   resizable: true,
  // },
  {
    title: 'id',
    field: 'id_request',
    editable: 'never',
    resizable: true,
    hidden: true,
  },
]

const allRequestColumns = [
  {
    title: 'Firstname',
    field: 'firstname',
    editable: 'never',
    resizable: true,
  },
  /* {
    title: 'Lastname',
    field: 'lastname',
    editable: 'never',
    resizable: true,
    // cellStyle: {
    //   width: 50,
    //   minWidth: 50,
    // },
    // headerStyle: {
    //   width: 50,
    //   minWidth: 50,
    // },
  }, */
  {
    title: 'Trip',
    field: 'trip_name',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Country',
    field: 'country',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Travel agent',
    field: 'advisor',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Status',
    field: 'status',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Creation date',
    field: 'date_request',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'Pick up date',
    field: 'date_accept',
    editable: 'never',
    resizable: true,
  },
  {
    title: 'id',
    field: 'id_request',
    editable: 'never',
    resizable: true,
    hidden: true,
  },
]

export { pendingRequestColumns, allRequestColumns }
