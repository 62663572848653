export const PUT_TOUR_OPERATOR_RESULTS = 'PUT_TOUR_OPERATOR_RESULTS'
export const PUT_INFORMATION_COMMENT_RESULTS = 'PUT_INFORMATION_COMMENT_RESULTS'
export const RESET_TOUR_OPERATOR = 'RESET_TOUR_OPERATOR'
export const PUT_TRIP_BILLING_DEPOSIT_RESULTS = 'PUT_TRIP_BILLING_DEPOSIT_RESULTS'


export const putTourOperatorResults = data => ({
  type: PUT_TOUR_OPERATOR_RESULTS,
  putTourOperator: data,
})

export const putInformationCommentResults = data => ({
  type: PUT_INFORMATION_COMMENT_RESULTS,
  putInformationComment: data,
})

export const resetTourOperator = () => ({
  type: RESET_TOUR_OPERATOR,
})

export const putTripBillingDepositResults = data => ({
  type: PUT_TRIP_BILLING_DEPOSIT_RESULTS,
  putTripBilling: data,
})
