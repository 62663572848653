import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import api from '../components/api/reducers'
import customerReducer from '../pages/clients/api_requestors/reducers'
import travelerReducer from '../pages/clients/api_travelers/reducers'
import userAccountReducer from '../pages/userAccount/api/reducers'
import companyReducer from '../pages/company/api/reducers'
import informationsReducer from '../pages/management/informations/api/reducers'
import extensionReducer from '../pages/management/extension/api/reducers'
import activityReducer from '../pages/management/activity/api/reducers'
import tripExtensionReducer from '../pages/tripExtension/api/reducers'
import tripSiteReducer from '../pages/tripSite/api/reducers'
import tripReducer from '../pages/trip/api/reducers'
import billingReducer from '../pages/billing/api/reducers'
import suppliersReducer from '../pages/management/suppliers/api/reducers'
import pictureReducer from '../pages/management/gallery/api/reducers'
import collaboratorsReducer from '../pages/collaborators/api/reducers'
import requestReducer from '../pages/request/api/reducers'
import tourOperatorReducer from '../pages/tourOperator/api/reducers'
import resourceReducer from '../pages/resources/api/reducers'
import invoiceReducer from '../pages/invoice/api/reducers'


const AppReducer = combineReducers({
  router: routerReducer,
  api,
  customerReducer,
  travelerReducer,
  userAccountReducer,
  companyReducer,
  informationsReducer,
  extensionReducer,
  activityReducer,
  tripExtensionReducer,
  tripSiteReducer,
  tripReducer,
  suppliersReducer,
  billingReducer,
  pictureReducer,
  collaboratorsReducer,
  invoiceReducer,
  requestReducer,
  tourOperatorReducer,
  resourceReducer,
})

export default AppReducer
