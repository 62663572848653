import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'

class Room extends PureComponent {
  static propTypes = {
    GET_ROOM_LIST: PropTypes.func.isRequired,
    RESET_ROOM_CONTENT: PropTypes.func.isRequired,
    PUT_ROOM_CONTENT: PropTypes.func.isRequired,
    DELETE_ROOM_CONTENT: PropTypes.func.isRequired,
    putRoomContent: PropTypes.object,
    deleteRoomContent: PropTypes.object,
    roomList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    roomList: [],
    putRoomContent: {},
    deleteRoomContent: {},
  }

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.validate = this.validate.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.onChange = this.onChange.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedRoom = this.getModifiedRoom.bind(this)
    this.updateRoom = this.updateRoom.bind(this)
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.state = {
      actionLabel: 'Create',
      roomList: [],
      roomContent: '',
      isNewRoom: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      disabledActionBtn: true,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      roomList,
      GET_ROOM_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(roomList)) {
      this.setState({
        roomList: _.sortBy(roomList, o => o.type_chambre),
      })
    } else {
      GET_ROOM_LIST(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      roomContent,
      roomList: roomListOld,
    } = this.state
    const {
      roomList,
      putRoomContent,
      deleteRoomContent,
      RESET_ROOM_CONTENT,
    } = nextprops
    if (putRoomContent && putRoomContent.success) {
      this.reload()
      this.setState({
        roomContent: {
          ...roomContent,
          id_type_chambre: putRoomContent.id,
        },
        validMsg: 'the room was successfully recorded !',
      })
      this.resetValidMsg()
      this.handleClose()
    }
    // room delete in DB -> get back updated list
    if (deleteRoomContent && deleteRoomContent.success) {
      this.reload()
      this.setState({
        roomContent: '',
        validMsg: 'the room was successfully deleted !',
      })
      this.resetValidMsg()
    }
    // location can not be delete in DB -> it is used yet in day or accomodation
    if (deleteRoomContent && !deleteRoomContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteRoomContent.message,
      })
      RESET_ROOM_CONTENT()
    }
    if (roomList !== roomListOld) {
      this.setState({
        roomList: _.sortBy(roomList, o => o.type_chambre),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_ROOM_CONTENT,
      GET_ROOM_LIST,
    } = this.props
    RESET_ROOM_CONTENT()
    GET_ROOM_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { roomList } = this.state

    this.setState({
      validateError: false,
    })

    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = roomList.filter(d => d.type_chambre === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          roomContent: {
            id_type_chambre: null,
            type_chambre: newValue,
          },
          disabledActionBtn: false,
          isNewRoom: true,
        });
      } else {
        this.setState({
          roomContent: valueExist[0],
          disabledActionBtn: true,
          isNewRoom: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      const isNew = _.findIndex(roomList, o => o.type_chambre.toLowerCase() === newValue.inputValue.toLowerCase()) < 0
      // Create a new value from the user input
      this.setState({
        roomContent: {
          id_type_chambre: null,
          type_chambre: newValue.inputValue,
        },
        disabledActionBtn: !isNew,
        isNewRoom: isNew,
      });
    } else {
      this.setState({
        roomContent: newValue,
        disabledActionBtn: true,
        isNewRoom: false,
      });
    }
  }

  filterOptions = (options, params) => {
    const { roomList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = roomList.filter(d => d.type_chambre.toLowerCase() === params.inputValue.toLowerCase())

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        type_chambre: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.type_chambre;
  }

  // ***************************
  // Display input

  displayAutocompleteInput = () => {
    const {
      roomContent,
      roomList,
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="room-field"
            value={roomContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={roomList}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.type_chambre}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Room"
                margin="normal"
                placeholder="Select a room or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item xs={1} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      roomContent,
    } = this.state
    const roomId = roomContent.id_type_chambre
    const { DELETE_ROOM_CONTENT } = this.props
    if (confirm('Do you really want to delete this room ?')) {
      if (roomId) {
        DELETE_ROOM_CONTENT(token, roomId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: roomContent.type_chambre ? 'This room do not exist.' : 'Please, select a room.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { roomContent } = this.state
    if (!roomContent || !roomContent.id_type_chambre) return null
    this.setState({
      isNewRoom: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      roomContent,
      isNewRoom,
      token,
    } = this.state
    const { PUT_ROOM_CONTENT } = this.props
    this.setState({
      disabledActionBtn: true,
    })
    // Check all required inputs
    if (!roomContent.type_chambre) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewRoom) {
      PUT_ROOM_CONTENT(token, roomContent, 'create')
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new location
  updateRoom = () => {
    const {
      token,
      roomContent,
    } = this.state
    const { PUT_ROOM_CONTENT } = this.props
    PUT_ROOM_CONTENT(token, roomContent, 'update')
    this.handleClose()
  }

  getModifiedRoom = (item) => {
    const { roomContent } = this.state
    this.setState({
      roomContent: {
        ...roomContent,
        type_chambre: _.capitalize(item.room),
      },
    }, () => this.updateRoom())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      roomContent,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      disabledActionBtn,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>ROOM MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!roomContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedRoom(item)}
            disabled={false}
            title="Enter the new value for this room"
            type="room"
            modalContent="room"
            warning="This will update this room in all quotes !"
            inputValue={!_.isEmpty(roomContent) ? roomContent.type_chambre : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  roomList: state.api.roomList,
  putRoomContent: state.api.putRoomContent,
  deleteRoomContent: state.api.deleteRoomContent,
})

const mapDispatchToProps = dispatch => ({
  GET_ROOM_LIST: userId => dispatch({
    type: 'GET_ROOM_LIST',
    userId,
  }),
  RESET_ROOM_CONTENT: () => dispatch({
    type: 'RESET_ROOM_CONTENT',
  }),
  PUT_ROOM_CONTENT: (token, roomContent, action) => dispatch({
    type: 'PUT_ROOM_CONTENT',
    token,
    roomContent,
    action,
  }),
  DELETE_ROOM_CONTENT: (token, roomId) => dispatch({
    type: 'DELETE_ROOM_CONTENT',
    token,
    roomId,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Room))
