/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Input,
  ModalComponent,
} from '..'


class DayLineAcco extends PureComponent {
  static propTypes = {
    accomodationList: PropTypes.array,
    item: PropTypes.object,
    room: PropTypes.object,
    nbRoom: PropTypes.array,
    index: PropTypes.number,
    locationList: PropTypes.array,
    tripAllDays: PropTypes.array,
    updateItemsAcco: PropTypes.func.isRequired,
    roomByAccomodationList: PropTypes.array,
    isModalOpenInChildren: PropTypes.func.isRequired,
  }

  static defaultProps = {
    accomodationList: [],
    roomByAccomodationList: [],
    locationList: [],
    item: {},
    room: {},
    nbRoom: [],
    index: 0,
    tripAllDays: [],

  }

  constructor(props) {
    super(props);
    this.displayInput = this.displayInput.bind(this)
    this.onChangeAcco = this.onChangeAcco.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onChangeAcco = this.onChangeAcco.bind(this)
    this.displayDisplayModal = this.displayDisplayModal.bind(this)
    this.handleRoomChange = this.handleRoomChange.bind(this)
    this.handleRoomNbChange = this.handleRoomNbChange.bind(this)
    this.getOptionLabelAccomodation = this.getOptionLabelAccomodation.bind(this)
    this.getOptionLabelRoom = this.getOptionLabelRoom.bind(this)
    this.displayInput = this.displayInput.bind(this)
    this.displayAutocompleteRoom = this.displayAutocompleteRoom.bind(this)
    this.state = {
      item: {},
      isModalOpen: false,
    }
  }

  componentDidMount() {
    const {
      item,
    // roomByAccomodationList,
      // GET_ROOM_BY_ACCOMODATION_LIST,
    } = this.props
    // const { userId } = this.state
    this.setState({
      item,
    })
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    return null
  }

  componentDidUpdate() {

  }

  handleClose = () => {
    const {
      isModalOpenInChildren,
    } = this.props
    this.setState({
      isModalOpen: false,
    });
    isModalOpenInChildren(false)
  };

  displayDisplayModal = () => {
    const {
      item,
      isModalOpen,
    } = this.state
    const modalDisabled = false
    return (
      <ModalComponent
        isModalOpen={isModalOpen}
        onClose={this.handleClose}
        onResponseNo={this.handleClose}
        onResponseYes={newItem => this.handleRoomNbChange(newItem)}
        title="How many room in this category ?"
        modalContent="number_room"
        type="number"
        inputValue="1"
        simpleAction
        disabled={modalDisabled}
      />
    )
  }


  onChangeAcco = (content, reason) => {
    const {
      updateItemsAcco,
      index,
      tripAllDays,
    } = this.props
    const nbOccurences = tripAllDays.filter(tAd => tAd.lieu_fin_journee === tripAllDays[index].lieu_fin_journee)
    const indicesOccurences = _.map(nbOccurences, occurrence => tripAllDays.findIndex(element => element === occurrence));
    updateItemsAcco(content, index, 'accoChange', indicesOccurences)
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);

    return true
  }

  handleRoomChange = (newValue, reason) => {
    const {
      updateItemsAcco,
      index,
      room,
      nbRoom,
      isModalOpenInChildren,
    } = this.props
    if (reason === 'select-option') {
      isModalOpenInChildren(true)
      this.setState({
        isModalOpen: true,
      });
      updateItemsAcco(newValue, index, 'roomChange', '')
    }
    if (reason === 'remove-option') {
      const indexRoomDeleted = _.indexOf(room, _.differenceWith(room, newValue, _.isEqual)[0]);
      nbRoom.splice(indexRoomDeleted, 1)
      updateItemsAcco(newValue, index, 'roomChange', '')
      updateItemsAcco(nbRoom, index, 'nbRoomChange', '')
    }
    return true
  }

  handleRoomNbChange = (content) => {
    const {
      updateItemsAcco,
      index,
      room,
      nbRoom,
      tripAllDays,
    } = this.props
    const nbOccurences = tripAllDays.filter(tAd => tAd.lieu_fin_journee === tripAllDays[index].lieu_fin_journee)
    const indicesOccurences = _.map(nbOccurences, occurrence => tripAllDays.findIndex(element => element === occurrence));

    if (room.length === 1) {
      nbRoom[0] = content.number_room
    } else {
      nbRoom.push(content.number_room)
    }
    this.setState({
      isModalOpen: false,
    });
    updateItemsAcco(nbRoom, index, 'nbRoomChange', indicesOccurences)
    const { scrollY } = window;
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 0);
    return true
  }

  getOptionLabelAccomodation = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.nom_hebergement) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.nom_hebergement;
  }

  getOptionLabelRoom = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.room) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.type_chambre;
  }

  displayInput = (field, list, item, index) => (
    <Autocomplete
      id={`${field}-field-${item.id_hebergement}`}
      value={item}
      onChange={(event, newValue, reason) => {
        this.onChangeAcco(newValue, reason);
      }}
      selectOnFocus
      options={list}
      getOptionLabel={this.getOptionLabelAccomodation}
      renderOption={option => option.nom_hebergement}
      freeSolo
      renderInput={params => (
        <Input
          {...params}
          label={`${_.capitalize(field)} day ${index + 1}`}
          margin="normal"
          placeholder={`Select a ${field} or create a new one`}
          variant="outlined"
        />
      )}
    />
  )

  displayAutocompleteRoom = (field, content, list, indexRoom) => {
    const { nbRoom } = this.props
    const dayRoomList = !_.isEmpty(content) && content[0].type_chambre !== 'none' ? content : [];
    return (
      <Autocomplete
        multiple
        id={`${field}-field-${content.id}`}
        value={dayRoomList}
        options={list}
        onChange={(event, newValue, reason) => {
          this.handleRoomChange(newValue, reason);
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={this.getOptionLabelRoom}
        renderOption={option => option.type_chambre}
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip variant="outlined" label={`${nbRoom[index] || 1}x ${option.type_chambre}` || ''} {...getTagProps({ index })} />
        ))
        }
        renderInput={params => (
          <Input
            {...params}
            variant="outlined"
            label={`Rooms for day ${indexRoom + 1}`}
            margin="normal"
            className="largeWidth"
          />
        )}
      />
    )
  }

  // ***************************
  render() {
    const { isModalOpen } = this.state
    const {
      accomodationList,
      item,
      index,
      tripAllDays,
      room,
      roomByAccomodationList,
      nbRoom,
      locationList,
    } = this.props
    const stripe = index % 2 === 0 ? 'stripe' : null
    const nomNuit = locationList.filter(lL => (lL.id_lieu === tripAllDays[index].lieu_fin_journee))
    const accomodationFiltered = _.filter(accomodationList, a => (a.id_location === tripAllDays[index].lieu_fin_journee || a.universel === 1))
    const filteredRoomList = isNaN(item.id_hebergement) ? [] : roomByAccomodationList.filter(r => r.id_hebergement === item.id_hebergement)
    // background striped line by line
    return (
      <div className={`dayLine ${stripe}`}>
        {/* </div> */}
        <div className="billingTitleCenter">Day {index + 1} - {nomNuit[0].lieu}</div>
        {this.displayInput('nom_hebergement', accomodationFiltered, item, index)}
        {this.displayAutocompleteRoom('room', room, filteredRoomList, index)}
        {isModalOpen && this.displayDisplayModal()}

      </div>
    )
  }
}

const mapStateToProps = state => ({
  accomodationList: state.api.accomodationList,
  tripAccomodation: state.api.tripAccomodation,
  tripAllDays: state.api.tripAllDays,
  tripAccomodationOpt1: state.api.tripAccomodationOpt1,
  tripAccomodationOpt2: state.api.tripAccomodationOpt2,
  tripContent: state.api.tripContent,
  roomByAccomodationList: state.api.roomByAccomodationList,
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
})


export default withRouter(connect(mapStateToProps)(DayLineAcco))
