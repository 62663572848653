export const GET_COMPANY_CONTENT_RESULTS = 'GET_COMPANY_CONTENT_RESULTS'
export const PUT_COMPANY_CONTENT_RESULTS = 'PUT_COMPANY_CONTENT_RESULTS'
export const DELETE_COMPANY_CONTENT_RESULTS = 'DELETE_COMPANY_CONTENT_RESULTS'
export const RESET_COMPANY_CONTENT = 'RESET_COMPANY_CONTENT'

export const getCompanyContentResults = data => ({
  type: GET_COMPANY_CONTENT_RESULTS,
  companyContent: data,
})

export const putCompanyContentResults = data => ({
  type: PUT_COMPANY_CONTENT_RESULTS,
  putCompanyContent: data,
})

export const deleteCompanyContentResults = data => ({
  type: DELETE_COMPANY_CONTENT_RESULTS,
  deleteCompanyContent: data,
})

export const resetCompanyContent = () => ({
  type: RESET_COMPANY_CONTENT,
})
