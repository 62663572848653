import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MaterialTable from '@material-table/core';
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import tableIcons from '../utils/icons/icons'
import {
  ModalComponent,
  TabPanel,
  Input,
} from '..'

import DisplayInvoice from './component/DisplayInvoice'

import {
  resourcesColumns,
} from './configComponents/columns'
import { CssGrid, AntTabs } from '../../configCSS'

import stage from '../../config'

const savedImgUrl = stage.imgStorage

class InvoiceManagement extends PureComponent {
    static propTypes = {
      invoiceList: PropTypes.array,
      companyContent: PropTypes.object,
      history: PropTypes.object.isRequired,
      PUT_COMPANY_INVOICE: PropTypes.func.isRequired,
      PUT_TRIP_BILLING_BILLINGREF: PropTypes.func.isRequired,
      RESET_GENERATE_INVOICE: PropTypes.func.isRequired,
      GET_INVOICE_LIST: PropTypes.func.isRequired,
      putTripBilling: PropTypes.object,
    }

      static defaultProps = {
        invoiceList: [],
        companyContent: {},
        putTripBilling: {},
      }

      constructor(props) {
        super(props);
        this.goToTrip = this.goToTrip.bind(this)
        this.generateBill = this.generateBill.bind(this)
        this.getinvoicelist = this.getinvoicelist.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.resetValidMsg = this.resetValidMsg.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.state = {
          token: localStorage.getItem('token'),
          newInvoiceId: {},
          isModalOpen: false,
          filterStartDate: '',
          filterEndDate: '',
          invoiceList: [],
          keyTab: 0,
          userId: localStorage.getItem('userId'),
          validMsg: null,
          idCircuit: '',
        };
      }

      componentDidMount() {
        const {
          companyContent,
          GET_INVOICE_LIST,
        } = this.props
        const {
          userId,
        } = this.state
        //    if (!localStorage.getItem('isLogged')) {
        //    history.push('/')
        //  }

        if (!_.isEmpty(companyContent)) {
          this.setState({
            newInvoiceId: {
              invoice_id: companyContent.invoice_id,
            },
          })
        }
        GET_INVOICE_LIST(userId)
        //    this.filtreCircuitList()
      }

      componentWillReceiveProps() {
      }

      componentDidUpdate() {
        this.getinvoicelist()

        // Delete *********************************************************************************
        // after delete refresh list
      }

getinvoicelist = () => {
  const {
    GET_INVOICE_LIST,
    RESET_GENERATE_INVOICE,
    putTripBilling,
    invoiceList,
  } = this.props
  const {
    userId,
    invoiceList: invoiceListOld,
  } = this.state

  if (!_.isEqual(invoiceList, invoiceListOld)) {
    this.setState({
      invoiceList: invoiceList,
    })
  }
  if (putTripBilling && putTripBilling.success) {
    RESET_GENERATE_INVOICE()
    GET_INVOICE_LIST(userId)
    this.setState({
      invoiceList,
      validMsg: 'the invoice reference was successfully recorded !',

    })
    this.resetValidMsg()
  }
}

resetValidMsg =() => {
  setTimeout(() => {
    this.setState({
      validMsg: null,
    })
  }, 3000)
}

// TABS
   onChangeTab = (event, keyTab) => {
     this.setState({
       keyTab,
     })
   }

 tabProps = index => ({
   id: `tab-${index}`,
   'aria-controls': `tabpanel-${index}`,
 })

 goToTrip = (data = {}) => {
   const { history } = this.props
   history.push('trip', { ...data })
 }

       generateBill = () => {
         const {
           companyContent,
           PUT_COMPANY_INVOICE,
           PUT_TRIP_BILLING_BILLINGREF,
         } = this.props
         const {
           token,
           newInvoiceId,
           invoiceList,
           idCircuit,
         } = this.state
         const circuitToGenerate = invoiceList.filter(c => c.id_devis === idCircuit)
         const checkifBillOnFirst = invoiceList.filter(c => moment(c.billingReference_creation_date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))

         // increment the billing reference id if first on the month, reanitialze incrementation
         const IncInvoice = (moment().format('D') === '1' && _.isEmpty(checkifBillOnFirst)) ? (
           {
             invoice_id: 1,
             id_company: companyContent.id_company,
           }) : ({
           invoice_id: newInvoiceId.invoice_id + 1,
           id_company: companyContent.id_company,
         })

         this.setState({ isModalOpen: false });
         if (circuitToGenerate[0].sellingPrice === null) {
           alert('No prices and bills associated to this quote!')
         } else if (!_.isEmpty(companyContent) && _.isEmpty(circuitToGenerate[0].billingReference)) {
           if (moment().format('D') === '1' && _.isEmpty(checkifBillOnFirst)) {
             this.setState({
               newInvoiceId: {
                 ...newInvoiceId,
                 invoice_id: 1,
               },
             })
           } else {
             this.setState({
               newInvoiceId: {
                 ...newInvoiceId,
                 invoice_id: newInvoiceId.invoice_id + 1,
               },
             })
           }

           PUT_COMPANY_INVOICE(token, IncInvoice)

           const billingDetails = {}
           billingDetails.billingReference = `F-${moment().format('YYYY-MM')}-${IncInvoice.invoice_id}`
           billingDetails.billingReference_creation_date = moment().format('YYYY-MM-DD')

           PUT_TRIP_BILLING_BILLINGREF(token, idCircuit, billingDetails)
         } else {
           alert('Invoice reference already exists or network connexion issue!')
         }
       }

       handleChange = (e) => {
         const {
           value, name,
         } = e.target
         this.setState({
           [name]: value,

         })
       }

       // Modal
  handleOpen = (data = {}) => {
    this.setState({
      isModalOpen: true,
      idCircuit: data.idCircuit,

    });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const
      {
        companyContent,
      } = this.props

    const {
      keyTab,
      invoiceList,
      validMsg,
      filterStartDate,
      filterEndDate,
      isModalOpen,
    } = this.state
    const EtatinvoiceListProgressbyID = _.isEmpty(filterEndDate) ? (
      invoiceList.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked' || c.status === 'confirmed-booked').filter(c => c.date_creation >= filterStartDate)
    ) : (
      invoiceList.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked' || c.status === 'confirmed-booked').filter(c => c.date_creation >= filterStartDate).filter(c => moment(c.date_creation).format('YYYY-MM') <= filterEndDate)

    )
    return (
      <div className="management">
        <div className="logo-dashboard">
          <img src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
        </div>
        <AppBar position="static" className="tab-bar">
          <AntTabs value={keyTab} onChange={this.onChangeTab} aria-label="simple tabs example">
            <Tab label="PROGRAM CONFIRMED" {...this.tabProps(0)} />
            <Tab label="INVOICES" {...this.tabProps(1)} />
          </AntTabs>
        </AppBar>
        <TabPanel value={keyTab} index={0}>
          <div className="TitreDashBoard">                    PROGRAM CONFIRMED</div>
          {validMsg ? <div className="validMsg">{validMsg}</div> : null}

          <div>
            <CssGrid
              container
              spacing={1}
            >
              <CssGrid className="question" item md="auto" xs={12}>
                <h3>Quote created on :</h3>
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  type="month"
                  name="filterStartDate"
                  value={filterStartDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e)}
                  label="date from"
                  margin="normal"
                  variant="outlined"
                />

              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <Input
                  type="month"
                  label="to"
                  name="filterEndDate"
                  value={filterEndDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => this.handleChange(e)}
                  margin="normal"
                  variant="outlined"
                />
              </CssGrid>


            </CssGrid>
          </div>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <MaterialTable
                style={{ marginBottom: '30px' }}
                data={EtatinvoiceListProgressbyID}
                columns={resourcesColumns}
                icons={tableIcons}
                options={{
                  exportMenu: [{
                    label: 'Export PDF',
                    exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export quote'),
                  }, {
                    label: 'Export CSV',
                    exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export quote'),
                  }],
                  filtering: true,
                  search: true,
                  pageSize: 20,
                  sorting: false,
                  headerStyle: { textAlign: 'left' },
                  actionsColumnIndex: -1,
                  columnResizable: true,
                  doubleHorizontalScroll: true,
                  selectableRows: 'single',
                  selectableRowsOnClick: true,
                  columnsButton: true,
                }}
                actions={[
                  {
                    icon: tableIcons.Show,
                    tooltip: 'Show trip',

                    onClick: (event, rowData) => {
                      const NomCircuit = rowData.nom_devis
                      this.goToTrip({
                        source: 'homePage',
                        NomCircuit,
                      })
                    },
                  },
                  {
                    icon: tableIcons.Edit,
                    tooltip: 'Generate invoice Id',

                    onClick: (event, rowData) => {
                      const idCircuit = rowData.id_devis
                      this.handleOpen({
                        idCircuit,
                      })
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={this.generateBill}
            title="this action is irreversible are you sure you want to proceed?"
          />

        </TabPanel>
        <TabPanel value={keyTab} index={1}>

          <DisplayInvoice />
        </TabPanel>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  companyContent: state.companyReducer.companyContent,
  invoiceList: state.invoiceReducer.invoiceList,
  putTripBilling: state.invoiceReducer.putTripBilling,

})

const mapDispatchToProps = dispatch => ({

  PUT_COMPANY_INVOICE: (token, companyContent) => dispatch({
    type: 'PUT_COMPANY_INVOICE',
    token,
    companyContent,
  }),

  PUT_TRIP_BILLING_BILLINGREF: (token, idDevis, billingDetails) => dispatch({
    type: 'PUT_TRIP_BILLING_BILLINGREF',
    token,
    idDevis,
    billingDetails,
  }),


  GET_INVOICE_LIST: userId => dispatch({
    type: 'GET_INVOICE_LIST',
    userId,
  }),
  RESET_GENERATE_INVOICE: () => dispatch({
    type: 'RESET_GENERATE_INVOICE',
  }),

})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceManagement))
