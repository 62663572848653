import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Input,
} from '../../../../components'

export default function SelectTrip(props) {
  const {
    field,
    initContent,
    list,
    updateTripSelected,
  } = props

  const [content, setTripContent] = useState({})

  useEffect(() => {
    if (!_.isEmpty(initContent)) onChangeTrip(null, initContent)
  }, [initContent])

  useEffect(() => {
    updateTripSelected(content)
  }, [content])


  // On change trip
  const onChangeTrip = (event, newValue) => {
    // on press Enter
    if (typeof newValue === 'string') {
      const firstTripInfo = _.filter(list, o => o.nom_devis.toLowerCase() === newValue.toLowerCase())[0]
      if (firstTripInfo) setTripContent(firstTripInfo)
      else setTripContent({})
    // clear input trip
    } else if (!newValue) {
      setTripContent({})
    // Create a new value from the user input (ADD 'xxx')
    } else if (newValue && newValue.inputValue) {
      // TODO
      // messge d'erreur
      setTripContent({})
    // select value
    } else {
      const tripInfo = _.filter(list, o => o.id_devis === newValue.id_devis)[0]
      setTripContent(tripInfo)
    }
  }

  const filterOptionsTrip = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    return filtered;
  }

  const getOptionLabelTrip = (option) => {
    if (_.isEmpty(option)) return ''
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // after input clear
    if (!option.nom_devis) {
      return ''
    }
    // Regular option
    return option.nom_devis
  }

  return (
    <Autocomplete
      id={`${field}-field`}
      value={content}
      onChange={onChangeTrip}
      filterOptions={filterOptionsTrip}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={list}
      getOptionLabel={getOptionLabelTrip}
      renderOption={option => option.nom_devis}
      freeSolo
      renderInput={params => (
        <Input
          {...params}
          label={_.capitalize(field)}
          margin="normal"
          placeholder={`Select a ${field} or create a new one`}
          variant="outlined"
          required
          // error={validateError ? params.inputProps.value === '' : false}
        />
      )}
    />
  )
}

SelectTrip.propTypes = {
  field: PropTypes.string.isRequired,
  initContent: PropTypes.object,
  list: PropTypes.array.isRequired,
  updateTripSelected: PropTypes.func.isRequired,
};

SelectTrip.defaultProps = {
  initContent: {},
};
