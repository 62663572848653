import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import translateBoard from '../../generate/utils/translateBoard'


import {
  Template,
  Input,
  ActionButton,
  TextEditor,
  Uploader,
} from '../../components'
import stage from '../../config'

const apiUrl = stage.api.duyen.url
const savedImgUrl = stage.imgStorage
const langageArray = translateBoard.map(l => (
  {
    code: l.isoCode,
    name: l.nativeName,
  }
))

const CssGrid = withStyles({
  root: {
    '& .MuiFormControl-root': {
      display: 'grid',
    },
  },
})(Grid);

class Account extends PureComponent {
  static propTypes = {
    GET_PROFIL_USER_LIST: PropTypes.func.isRequired,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    PUT_USER_ACCOUNT: PropTypes.func.isRequired,
    RESET_USER_ACCOUNT: PropTypes.func.isRequired,
    ROW_TRIP_SELECT: PropTypes.func.isRequired,
    RESET_TRIP_INFORMATION: PropTypes.func.isRequired,
    GET_COLLABORATORS: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
    profilUserList: PropTypes.array,
  }

  static defaultProps = {
    userAccount: {},
    profilUserList: [],
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.displayUserInfoInputs = this.displayUserInfoInputs.bind(this)
    this.displayTextEditor = this.displayTextEditor.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.checkRequiredInputs = this.checkRequiredInputs.bind(this)
    this.validate = this.validate.bind(this)
    this.redirectToGallery = this.redirectToGallery.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.getImageUrl = this.getImageUrl.bind(this)
    this.state = {
      userAccount: {},
      profilUserList: [],
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      firstnameError: false,
      lastnameError: false,
      emailError: false,
      newImageArray: [],
      newURLArray: [],
      hasChangedUserAccount: false,
      isValidated: false,
    }
  }

  componentDidMount() {
    const {
      history,
      userAccount,
      profilUserList,
      GET_PROFIL_USER_LIST,
      GET_USER_ACCOUNT,
    } = this.props
    const {
      userId,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // check if props exists
    if (!_.isEmpty(profilUserList)) {
      this.setState({
        profilUserList,
      })
    } else {
      GET_PROFIL_USER_LIST()
    }
    if (!_.isEmpty(userAccount)) {
      // come back from 'Gallery'
      if (history.location.state) {
        const {
          state,
          currentImg,
          pictureSelected,
        } = history.location.state
        this.setState({
          userAccount: {
            ...state.item,
            image: pictureSelected ? pictureSelected.join() : currentImg.join(),
          },
          isValidated: false,
        })
      } else {
        this.setState({ userAccount })
      }
    } else {
      GET_USER_ACCOUNT(userId)
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      userAccount: userAccountOld,
      profilUserList: profilUserListOld,
    } = this.props
    const {
      userAccount,
      profilUserList,
      putUserAccount,
    } = nextprops
    // Customer saved in DB -> get back updated list
    if (putUserAccount && putUserAccount.success) {
      this.reload()
      this.setState({
        validMsg: 'Modification was successfully recorded !',
        hasChangedUserAccount: false,
        isValidated: false,
      })
      this.resetValidMsg()
    }
    if (userAccount !== userAccountOld) {
      this.setState({ userAccount })
    }
    if (profilUserList !== profilUserListOld) {
      this.setState({ profilUserList })
    }
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const {
      userId,
      token,
    } = this.state
    const {
      RESET_USER_ACCOUNT,
      GET_USER_ACCOUNT,
      ROW_TRIP_SELECT,
      RESET_TRIP_INFORMATION,
      GET_COLLABORATORS,
    } = this.props
    RESET_USER_ACCOUNT()
    ROW_TRIP_SELECT([])
    RESET_TRIP_INFORMATION()
    GET_USER_ACCOUNT(userId)
    GET_COLLABORATORS(token)
  }

  // ************************************************
  // IMAGE
  // Upload and show image

  getImageUrl = () => {
    const {
      userAccount,
      newURLArray,
      newImageArray,
    } = this.state
    const {
      company,
      image,
    } = userAccount

    // display image(s) modified
    if (!_.isEmpty(newURLArray)) return [newURLArray, newImageArray]

    const imageNameArray = image ? image.split(',') : null

    // display default image
    if (_.isEmpty(imageNameArray)) return [[`${apiUrl}/images/default-avatar.jpg`], []]

    // display image(s) saved in database
    const imageUrlArray = []
    imageNameArray.map((imgName) => {
      imageUrlArray.push(`${savedImgUrl}/${company}/${imgName}`)
      return null
    })
    return [imageUrlArray, imageNameArray]
  }

  saveImage = (imagesDataArray) => {
    this.setState({
      newImageArray: imagesDataArray.map(img => img.imageName),
      newURLArray: imagesDataArray.map(img => img.imageUrl),
      hasChangedUserAccount: true,
    });
  }

  deleteImage = (urlArray, imageArray) => {
    const { userAccount } = this.state
    this.setState({
      userAccount: {
        ...userAccount,
        image: imageArray.join(),
      },
      newImageArray: imageArray,
      newURLArray: urlArray,
      hasChangedUserAccount: true,
    })
  }

  redirectToGallery = () => {
    const {
      userAccount,
    } = this.state
    const {
      history,
    } = this.props
    // this.validate()
    history.push('gallery', {
      currentPage: 'myAccount',
      isSelectionAction: true,
      nbMaxImg: 1,
      currentImg: userAccount.image ? userAccount.image.split(',') : [],
      state: {
        item: userAccount,
      },
    })
  }

  // *********************************************
  // save all new informations for this user
  handleChange = field => (event) => {
    const { userAccount } = this.state
    this.setState({
      userAccount: {
        ...userAccount,
        [field]: event.target.value,
      },
      [`${field}Error`]: false,
      validateError: false,
      errorMsg: null,
      hasChangedUserAccount: true,
    })
  }

  // On change Editor
  handleEditorChange = field => (content) => {
    const { userAccount } = this.state
    this.setState({
      userAccount: {
        ...userAccount,
        [field]: content,
      },
      validateError: false,
      errorMsg: null,
    });
  }


  // ****************************************
  // Editor
  displayTextEditor = () => {
    const {
      userAccount,
    } = this.state
    const presentation = userAccount.language ? `${userAccount.language}_presentation` : 'fr_presentation'
    return (
      <TextEditor
        object={userAccount}
        value={userAccount[presentation] || ''}
        handleEditorChange={this.handleEditorChange(presentation)}
      />
    )
  }

  // return all other input
  displayUserInfoInputs = () => {
    const {
      userAccount,
      profilUserList,
      firstnameError,
      lastnameError,
      emailError,
    } = this.state
    const { history } = this.props
    const profil = _.filter(profilUserList, o => o.id_profil === userAccount.profil)[0]
    return (
      <div>
        <Input
          id="lastname"
          label="Lastname"
          value={!_.isEmpty(userAccount) && userAccount.lastname ? userAccount.lastname : ''}
          onChange={this.handleChange('lastname')}
          required
          error={lastnameError}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <Input
          id="firstname"
          label="Firstname"
          value={!_.isEmpty(userAccount) && userAccount.firstname ? userAccount.firstname : ''}
          onChange={this.handleChange('firstname')}
          required
          error={firstnameError}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <CssGrid
          container
          spacing={2}
        >
          <CssGrid item md={9} xs={12}>
            <Input
              id="email"
              label="Email"
              value={!_.isEmpty(userAccount) && userAccount.email ? userAccount.email : ''}
              onChange={this.handleChange('email')}
              required
              error={emailError}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <CssGrid
              container
              spacing={2}
            >
              <CssGrid item md={9} xs={12}>
                <Input
                  id="password"
                  label="Password"
                  value="***********"
                  // onChange={this.handleChange('phone')}
                  margin="normal"
                  variant="outlined"
                  className="largeWidth"
                  disabled
                />
              </CssGrid>
              <CssGrid item md={3} xs={12}>
                <div className="changePasswordDiv">
                  <a
                    href="#"
                    // className={`button ${disabledClass} ${backgroundColor}`}
                    type="button"
                    // disabled={disabled}
                    onClick={() => history.push('changePassword')}
                  >
                    Modify password
                  </a>
                </div>
              </CssGrid>
            </CssGrid>
            <Input
              id="phone"
              label="Phone"
              value={!_.isEmpty(userAccount) && userAccount.phone ? userAccount.phone : ''}
              onChange={this.handleChange('phone')}
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <div className="editorStyle">
              <p className="editorTitle background-white">Description</p>
              {this.displayTextEditor()}
            </div>
            <Input
              id="langage"
              label="Langage"
              select
              value={!_.isEmpty(userAccount) && userAccount.language ? userAccount.language : ''}
              onChange={this.handleChange('language')}
              margin="normal"
              variant="outlined"
              width="100%"
            >
              {_.map(langageArray, (l, k) => <MenuItem key={`langage-${k}`} value={l.code.toString()}>{l.name}</MenuItem>)}

            </Input>
            <Input
              id="validity"
              label="Date Validity"
              value={!_.isEmpty(userAccount) && userAccount.date_validity ? userAccount.date_validity : ''}
              disabled
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
            <Input
              id="profil"
              label="Profil"
              value={!_.isEmpty(userAccount) && userAccount.profil && profil ? profil.profil_utilisateur : ''}
              disabled
              margin="normal"
              variant="outlined"
              className="largeWidth"
            />
          </CssGrid>
          <CssGrid item md={3} xs={12}>
            <Uploader
              imageDataArray={this.getImageUrl()}
              saveImage={imagesDataArray => this.saveImage(imagesDataArray)}
              redirectToGallery={() => this.redirectToGallery()}
              deleteImage={(urlArray, imageArray) => this.deleteImage(urlArray, imageArray)}
              allowDrop
            />
          </CssGrid>
        </CssGrid>
      </div>
    )
  }

  // *****************************************
  // Control that all required input are well fiiled
  checkRequiredInputs = (e) => {
    e.preventDefault()
    const {
      userAccount,
    } = this.state
    const {
      lastname,
      firstname,
      email,
    } = userAccount
    // check valid inputs
    if (lastname && firstname && email) {
      this.validate()
    } else {
      if (lastname === '') this.setState({ lastnameError: true })
      if (firstname === '') this.setState({ firstnameError: true })
      if (email === '') this.setState({ emailError: true })
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs.',
      })
    }
  }

  // *******************************
  // Validate
  validate = () => {
    const {
      token,
      userId,
      userAccount,
      newImageArray,
    } = this.state
    const {
      PUT_USER_ACCOUNT,
    } = this.props
    const newUserAccount = {
      ...userAccount,
      image: newImageArray.join() || userAccount.image,
    }
    this.setState({
      isValidated: true,
    })
    PUT_USER_ACCOUNT(token, newUserAccount, userId)
  }

  render() {
    const {
      validMsg,
      validateError,
      errorMsg,
      hasChangedUserAccount,
      isValidated,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>MY ACCOUNT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayUserInfoInputs()}
            </div>
            <ActionButton
              label="Validate"
              onClick={e => this.checkRequiredInputs(e)}
            />
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={hasChangedUserAccount && !isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userAccount: state.userAccountReducer.userAccount,
  putUserAccount: state.userAccountReducer.putUserAccount,
  profilUserList: state.api.profilUserList,
  tripDetails: state.api.tripDetails,
})

const mapDispatchToProps = dispatch => ({
  GET_PROFIL_USER_LIST: () => dispatch({
    type: 'GET_PROFIL_USER_LIST',
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  PUT_USER_ACCOUNT: (token, userAccount, userId) => dispatch({
    type: 'PUT_USER_ACCOUNT',
    token,
    userAccount,
    userId,
  }),
  RESET_USER_ACCOUNT: () => dispatch({
    type: 'RESET_USER_ACCOUNT',
  }),
  ROW_TRIP_SELECT: rowsSelected => dispatch({
    type: 'ROW_TRIP_SELECT',
    rowsSelected,
  }),
  RESET_TRIP_INFORMATION: () => dispatch({
    type: 'RESET_TRIP_INFORMATION',
  }),
  GET_COLLABORATORS: token => dispatch({
    type: 'GET_COLLABORATORS',
    token,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account))
