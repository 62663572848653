import React, { forwardRef } from 'react';

import Add from '@material-ui/icons/Add';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumnOutlined from '@material-ui/icons/ViewColumnOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CodeIcon from '@material-ui/icons/Code';


const tableIcons = {
  Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnOutlined {...props} ref={ref} />),
  Show: forwardRef((props, ref) => <VisibilityOutlinedIcon {...props} ref={ref} />),
  Accept: forwardRef((props, ref) => <AddShoppingCartIcon {...props} ref={ref} />),
  Remove: forwardRef((props, ref) => <RemoveShoppingCartIcon {...props} ref={ref} />),
  ArrowLeft: forwardRef((props, ref) => <ArrowBackIosOutlinedIcon {...props} ref={ref} />),
  Copy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref} />),
  Copyhtml: forwardRef((props, ref) => <CodeIcon {...props} ref={ref} />),
  ArrowRight: forwardRef((props, ref) => <ArrowForwardIosOutlinedIcon {...props} ref={ref} />),
};

export default tableIcons
