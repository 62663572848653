import React from 'react';
import PropTypes from 'prop-types'

import {
  Header,
  Menu,
} from '..'

const Template = ({ children }) => (
  <div className="template">
    <Header />
    <div className="main">
      <Menu />
      {children}
    </div>
  </div>
)

Template.defaultProps = {
  children: [],
}

Template.propTypes = {
  children: PropTypes.any,
}

export default Template
