import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MaterialTable from '@material-table/core';
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import tableIcons from '../utils/icons/icons'

import {
  colorBarGraph,
  MenuSelectGraph,
} from '../../configCSS'
import {
  resourcesColumns,
  resourcesColumnsRequest,
  resourcesColumnsRequest1,

} from './configComponents/columns'
import {
  BarGraph,
} from '..'
import stage from '../../config'

const savedImgUrl = stage.imgStorage

class DashBoard extends PureComponent {
    static propTypes = {
      listCircuit: PropTypes.array,
      userAccount: PropTypes.object,
      companyContent: PropTypes.object,
      request: PropTypes.array,
      history: PropTypes.object.isRequired,
    }

      static defaultProps = {
        listCircuit: [],
        userAccount: {},
        companyContent: {},
        request: [],

      }

      constructor(props) {
        super(props);
        this.displayGraphTripCreated = this.displayGraphTripCreated.bind(this)
        this.handleChangeAdvisor = this.handleChangeAdvisor.bind(this)
        this.handleChangeTimeline = this.handleChangeTimeline.bind(this)
        this.goToTrip = this.goToTrip.bind(this)
        this.listCircuitSorted = props.listCircuit.sort((a, b) => {
          const aDate = new Date(moment(a.date_creation, 'DD-MM-YYYY'))
          const bDate = new Date(moment(b.date_creation, 'DD-MM-YYYY'))
          if (bDate > aDate) return -1
          if (bDate < aDate) return 1
          return 0
        })
        this.state = {
          yearList: [
            'Last 12 months',
            ..._.reverse(_.uniq(this.listCircuitSorted.map(d => moment(d.date_creation, 'DD-MM-YYYY').format('YYYY')))),
          ],
          timelineSelected: 'Last 12 months',
          defaultTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          // get for labels last 12 months
          // defaultTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          labelsTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          statusSelected: ['in progress', 'confirmed', 'completed', 'no answer', 'canceled'],
          // keyTab: 0,
          // EtatlistCircuit: [],

        };
      }

      componentDidMount() {
        //    if (!localStorage.getItem('isLogged')) {
        //    history.push('/')
        //  }
        this.filtreCircuitList()
        this.filtreRequestList()
      }

      componentWillReceiveProps() {
      }

      componentDidUpdate() {

      }

      filtreCircuitList = () => {
        const
          {
            listCircuit,
            userAccount,
          } = this.props
        const Profil = `${userAccount.firstname} ${userAccount.lastname}`
        this.setState({
          EtatlistCircuitProgressbyID: listCircuit.filter(c => c.status === 'in progress').filter(c => c.advisor === Profil),
          EtatlistCircuitConfirmedbyID: listCircuit.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked').filter(c => c.advisor === Profil),
          EtatlistCircuitConfirmedbyIDOperator: listCircuit.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked').filter(c => c.operator === Profil),
          ComptelistCircuitProgressbyID: (listCircuit.filter(c => c.status === 'in progress').filter(c => c.advisor === Profil).length),
          ComptelistCircuitConfirmedbyID: (listCircuit.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked').filter(c => c.advisor === Profil).length),
          ComptelistCircuitConfirmedbyIDOperator: (listCircuit.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked').filter(c => c.operator === Profil).length),

        })
      }

      fillLabelsTimeline = (yearSelected) => {
        const { defaultTimeline } = this.state
        const labelsTimelineSelected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        // get the 12 last months where a trip was created
        if (yearSelected === 'Last 12 months') {
          return defaultTimeline.slice(defaultTimeline.length - 12)
        }
        // build an array with the 12 months for the year selected
        return labelsTimelineSelected.map((d, i) => moment(`${i + 1}-${yearSelected}`, 'M-YYYY').format('MMM YY'))
      }

      handleChangeTimeline = (event) => {
        const { value } = event.target

        this.setState({
          timelineSelected: value,
          labelsTimeline: this.fillLabelsTimeline(value),
        })
      }

      handleChangeAdvisor = (event) => {
        // value is an array of value checked ["name1", "name2", ...]
        this.setState({
          statusSelected: [
            ...event.target.value,
          ],
        })
      };

      displayGraphTripCreated = () => {
        const {
          yearList,
          timelineSelected,
          labelsTimeline,
          statusSelected,
        } = this.state
        const { userAccount } = this.props
        const advisorList = ['in progress', 'confirmed', 'confirmed-booked', 'completed', 'no answer', 'canceled', 'template', 'lost', 'old version']
        const profile = [`${userAccount.firstname} ${userAccount.lastname}`]

        // get number of trip created by advisor and by month
        const datasetsFindAdvisor = indexAdvisor => labelsTimeline.map(l => (this.listCircuitSorted.filter(t => t.advisor === profile[0] && t.status === statusSelected[indexAdvisor] && l === moment(t.date_modif_status, 'YYYY-MM-DD').format('MMM YY'))).length)

        // build datastes for graph
        const datasets = statusSelected.map((ad, i) => (
          {
            label: ad,
            data: datasetsFindAdvisor(i),
            backgroundColor: colorBarGraph[i],
          }
        ))

        return (
          <div className="graph-main-div">
            <h2 className="graph-title">Your quote</h2>
            <div className="select-div">
              <FormControl className="select-control">
                <InputLabel id="advisor-select-label-dashboard">Status</InputLabel>
                <Select
                  labelId="Quote_advisor-checkbox-label"
                  id="Quote_advisor-checkbox"
                  multiple
                  value={statusSelected}
                  onChange={this.handleChangeAdvisor}
                  input={<Input />}
                  // eslint-disable-next-line no-confusing-arrow
                  renderValue={selected => selected.length > 1 ? `${selected[1]} ...` : 'Total'}
                  MenuProps={MenuSelectGraph}
                >
                  {advisorList.map(name => (
                    <MenuItem key={`dashboard${name}`} value={name}>
                      <Checkbox checked={statusSelected.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="select-control">
                <InputLabel id="timeline">Date</InputLabel>
                <Select
                  labelId="Timeline-checkbox-label"
                  id="Timeline-checkbox-dashboard"
                  value={timelineSelected}
                  onChange={this.handleChangeTimeline}
                >
                  {yearList.map(year => (
                    <MenuItem key={`Annee${year}`} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <BarGraph
              id="tripPerAdvisorDashBoard"
              key="tripPerAdvisorDashBoard"
              labels={labelsTimeline}
              datasets={datasets}
              message="Trip created per advisor"
            />
          </div>
        )
      }

      filtreRequestList = () => {
        const { request, userAccount } = this.props
        const Profil = `${userAccount.firstname} ${userAccount.lastname}`
        this.setState({
          // EtatlistCircuitConfirm: listCircuit.filter(c => c.status === 'confirmed'),
          EtatRequestReceivedbyID: request.filter(c => c.status === 'received').filter(c => c.advisor === Profil),
          EtatRequestInProgressbyID: request.filter(c => c.status === 'in progress').filter(c => c.advisor === Profil),
          EtatRequestOperatorbyID: request.filter(c => c.status === 'pending operator').filter(c => c.advisor === Profil),
          CompteRequestReceivedbyID: (request.filter(c => c.status === 'received').filter(c => c.advisor === Profil)).length,
          CompteRequestOperatorbyID: (request.filter(c => c.status === 'pending operator').filter(c => c.advisor === Profil)).length,

        })
      }

      goTo = (dest) => {
        const { history } = this.props
        history.push(dest)
      }

       goToTrip = (data = {}) => {
         const { history } = this.props
         history.push('trip', { ...data })
       }

       render() {
         const
           {
             listCircuit,
             userAccount,
             companyContent,
           } = this.props

         const {
           EtatlistCircuitProgressbyID,
           EtatlistCircuitConfirmedbyID,
           EtatlistCircuitConfirmedbyIDOperator,
           EtatRequestReceivedbyID,
           EtatRequestInProgressbyID,
           EtatRequestOperatorbyID,
           CompteRequestReceivedbyID,
           CompteRequestOperatorbyID,
           ComptelistCircuitProgressbyID,
           ComptelistCircuitConfirmedbyID,
           ComptelistCircuitConfirmedbyIDOperator,
         } = this.state

         return (
           <div>
             <div className="logo-dashboard">
               <img src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />
             </div>
             <Grid container spacing={2} justifyContent="center">
               <Grid item lg={4} md={12}>
                 <div className="avatar-container-dashboard">
                   <img src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
                 </div>
                 <div className="TitreDashBoard">Hello {userAccount.firstname} {userAccount.lastname}   </div>
                 <div className="TexteRapport">
                   <br />
                   Saler pending request : {CompteRequestReceivedbyID}
                   <br />
                   <br />
                   Operator pending request : {CompteRequestOperatorbyID}
                   <br />
                   <br />
                   Quote in progress :{ComptelistCircuitProgressbyID}
                   <br />
                   <br />
                   Quote confirmed (as advisor) : {ComptelistCircuitConfirmedbyID}
                   <br />
                   <br />
                   Quote confirmed (as operator) : {ComptelistCircuitConfirmedbyIDOperator}
                 </div>
               </Grid>
               <Grid item lg={6} md={12}>
                 {this.displayGraphTripCreated()}
               </Grid>
             </Grid>
             <div className="TitreDashBoard">Your Requests</div>
             <div>{listCircuit && listCircuit[0].nom_programme}</div>
             <Grid container spacing={2} justifyContent="left">
               <Grid item xs={4}>
                 <MaterialTable
                   style={{ marginBottom: '10px' }}
                   title="pending request (as saler)"
                   data={EtatRequestReceivedbyID}
                   columns={resourcesColumnsRequest1}
                   icons={tableIcons}
                   options={{
                     search: false,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Save User',
                       onClick: () => {
                         this.goTo('request')
                       },
                     },
                   ]}
                 />
               </Grid>
               <Grid item xs={4}>
                 <MaterialTable
                   style={{ marginBottom: '10px' }}
                   title="your accepted request (as saler)"
                   data={EtatRequestInProgressbyID}
                   columns={resourcesColumnsRequest1}
                   icons={tableIcons}
                   options={{
                     search: false,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Save User',
                       onClick: () => {
                         this.goTo('request')
                       },
                     },
                   ]}
                 />
               </Grid>
               <Grid item xs={4}>
                 <MaterialTable
                   title="pending request as operator"
                   data={EtatRequestOperatorbyID}
                   columns={resourcesColumnsRequest}
                   icons={tableIcons}
                   options={{
                     search: false,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                     //  filterType: 'textField',
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Show request',
                       onClick: () => {
                         this.goTo('request')
                       },
                     },
                   ]}
                 />
               </Grid>
             </Grid>
             <br />
             <div className="TitreDashBoard">                    WORK IN PROGRESS</div>
             <Grid container spacing={2} justifyContent="center">
               <Grid item xs={4}>
                 <MaterialTable
                   style={{ marginBottom: '30px' }}
                   title="Your quote in progress"
                   data={EtatlistCircuitProgressbyID}
                   columns={resourcesColumns}
                   icons={tableIcons}
                   options={{
                     search: false,
                     pageSize: 10,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     selectableRows: 'single',
                     selectableRowsOnClick: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Show trip',

                       onClick: (event, rowData) => {
                         const NomCircuit = rowData.nom_devis
                         this.goToTrip({
                           source: 'homePage',
                           NomCircuit,
                         })
                       },
                     },
                   ]}
                 />
               </Grid>
               <Grid item xs={4}>
                 <MaterialTable
                   style={{ marginBottom: '30px' }}
                   title="Your quote confirmed (as advisor)"
                   data={EtatlistCircuitConfirmedbyID}
                   columns={resourcesColumns}
                   icons={tableIcons}
                   options={{
                     search: false,
                     pageSize: 10,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Go to trip',
                       onClick: (event, rowData) => {
                         const NomCircuit = rowData.nom_devis
                         this.goToTrip({
                           source: 'homePage',
                           NomCircuit,
                         })
                       },
                     },
                   ]}
                 />
               </Grid>
               <Grid item xs={4}>
                 <MaterialTable
                   style={{ marginBottom: '30px' }}
                   title="Your quote confirmed (as operator)"
                   data={EtatlistCircuitConfirmedbyIDOperator}
                   columns={resourcesColumns}
                   icons={tableIcons}
                   options={{
                     search: false,
                     pageSize: 10,
                     sorting: false,
                     headerStyle: { textAlign: 'left' },
                     actionsColumnIndex: -1,
                     columnResizable: true,
                     doubleHorizontalScroll: true,
                     columnsButton: true,
                     customToolbar: false,
                     filter: false,
                   }}
                   actions={[
                     {
                       icon: tableIcons.Show,
                       tooltip: 'Go to trip',
                       onClick: (event, rowData) => {
                         const NomCircuit = rowData.nom_devis
                         this.goToTrip({
                           source: 'homePage',
                           NomCircuit,
                         })
                       },
                     },
                   ]}
                 />
               </Grid>
             </Grid>
           </div>
         )
       }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  companyContent: state.companyReducer.companyContent,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  userAccount: state.userAccountReducer.userAccount,
  request: state.requestReducer.request,
  tripContent: state.api.tripContent,
})

export default withRouter(connect(mapStateToProps)(DashBoard))
