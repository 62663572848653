import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'
import {
  Template,
  Loader,
} from '../../../components'
import resourcesColumns from '../../../components/utils/table/resourcesColumns'

class TravelerTable extends PureComponent {
  static propTypes = {
    GET_RESOURCES: PropTypes.func.isRequired,
    DELETE_RESOURCES: PropTypes.func.isRequired,
    RESET_RESOURCES: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    resources: PropTypes.array,
    deleteResources: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    resources: [],
    deleteResources: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.updateResources = this.updateResources.bind(this)
    this.deleteTravelers = this.deleteTravelers.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      resources: [],
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
    }
  }

  componentDidMount() {
    const {
      history,
      resources,
      GET_RESOURCES,
      LOADER,
    } = this.props
    const { token } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (_.isEmpty(resources)) {
      GET_RESOURCES(token)
      LOADER(true)
    } else {
      this.setState({
        resources,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { token } = this.state
    const {
      resources,
      deleteResources,
      RESET_RESOURCES,
      GET_RESOURCES,
      LOADER,
    } = this.props
    if (deleteResources && deleteResources.success) {
      RESET_RESOURCES()
      GET_RESOURCES(token)
      this.reload(deleteResources.message)
      this.resetValidMsg()
    }
    if (resources !== prevProps.resources) {
      LOADER(false)
      this.updateResources()
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = (msg) => {
    this.setState({
      validMsg: msg,
    })
  }

  updateResources = () => {
    const { resources } = this.props
    this.setState({
      resources,
    })
  }

  deleteTravelers = (rowsDeleted) => {
    const { token } = this.state
    const {
      resources,
      DELETE_RESOURCES,
      LOADER,
    } = this.props
    const indexTravelersDeleted = rowsDeleted.data.map(d => d.dataIndex)
    if (confirm(`Do you really want to delete traveler${indexTravelersDeleted.length > 1 ? 's' : ''} ?`)) {
      LOADER(true)
      const idTravelersDeleted = indexTravelersDeleted.map(i => resources[i].id_traveler)
      DELETE_RESOURCES(token, idTravelersDeleted)
      // update state without travelers deleted
      const newTravelerList = _.cloneDeep(resources)
      indexTravelersDeleted.map(id => newTravelerList.splice(id, 1))
      this.setState({
        resources: newTravelerList,
      })
    }
  }

  render() {
    const {
      validateError,
      errorMsg,
      validMsg,
      isValidated,
      resources,
    } = this.state
    const { isLoading } = this.props

    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      pagination: true,
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      selectableRows: 'single',
      selectableRowsOnClick: true,
      print: true,
      download: true,
      downloadOptions: {
        filename: 'resources-list.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
      searchOpen: true,
      onRowsDelete: (rowsDeleted, data) => this.deleteTravelers(rowsDeleted, data),
    }

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="resourcesContainer">
              <h2>RESOURCES LIST</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {isLoading ? <Loader /> : <div className="loader-null" />}
              <MUIDataTable
                className="table"
                title="Resources"
                data={resources}
                columns={resourcesColumns}
                options={options}
              />
            </div>
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.isLoading,
  resources: state.resourceReducer.resources,
  deleteResources: state.resourceReducer.deleteResources,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  GET_RESOURCES: token => dispatch({
    type: 'GET_RESOURCES',
    token,
  }),
  DELETE_RESOURCES: (token, resourceId) => dispatch({
    type: 'DELETE_RESOURCES',
    token,
    resourceId,
  }),
  RESET_RESOURCES: () => dispatch({
    type: 'RESET_RESOURCES',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TravelerTable))
