
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import MaterialTable from '@material-table/core';
import moment from 'moment'
import { connect } from 'react-redux'
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import tableIcons from '../../utils/icons/icons'
import { CssGrid } from '../../../configCSS'
import { Input } from '../..'

import {
  resourcesInvoiceColumns,

} from '../configComponents/columns'

class DisplayInvoice extends PureComponent {
  static propTypes = {
    invoiceList: PropTypes.array,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    invoiceList: [],
  }

  constructor(props) {
    super(props);
    this.goToTrip = this.goToTrip.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      filterStartDate_invoice: '',
      filterEndDate_invoice: '',
    }
  }

  componentDidMount() {
  }

  componentDidUpdate() {


  }

 goToTrip = (data = {}) => {
   const { history } = this.props
   history.push('trip', { ...data })
 }

  handleChange = (e) => {
    const {
      value, name,
    } = e.target
    this.setState({
      [name]: value,

    })
  }


  render() {
    const
      {
        invoiceList,
      } = this.props
    const {
      filterStartDate_invoice,
      filterEndDate_invoice,
    } = this.state
    const EtatinvoiceListReferencebyID = _.isEmpty(filterEndDate_invoice) ? (
      invoiceList.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked' || c.status === 'confirmed-booked').filter(c => c.billingReference !== null).filter(c => c.billingReference_creation_date >= filterStartDate_invoice)
    ) : (
      invoiceList.filter(c => c.status === 'confirmed' || c.status === 'confirmed-booked' || c.status === 'confirmed-booked').filter(c => c.billingReference !== null).filter(c => c.billingReference_creation_date >= filterStartDate_invoice).filter(c => moment(c.billingReference_creation_date).format('YYYY-MM') <= filterEndDate_invoice)
    )


    return (


      <div>
        <div className="TitreDashBoard">                    INVOICES</div>
        <div>
          <CssGrid
            container
            spacing={1}
          >
            <CssGrid item md="auto" xs={12}>
              <h3>Invoices created on :</h3>
            </CssGrid>
            <CssGrid item md={3} xs={12}>
              <Input
                type="month"
                name="filterStartDate_invoice"
                value={filterStartDate_invoice}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.handleChange(e)}
                label="date from"
                margin="normal"
                variant="outlined"
              />

            </CssGrid>
            <CssGrid item md={3} xs={12}>
              <Input
                type="month"
                label="to"
                name="filterEndDate_invoice"
                value={filterEndDate_invoice}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => this.handleChange(e)}
                margin="normal"
                variant="outlined"
              />
            </CssGrid>


          </CssGrid>
        </div>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <MaterialTable
              style={{ marginBottom: '30px' }}
              data={EtatinvoiceListReferencebyID}
              columns={resourcesInvoiceColumns}
              icons={tableIcons}
              options={{
                exportMenu: [{
                  label: 'Export PDF',
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export quote'),
                }, {
                  label: 'Export CSV',
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export quote'),
                }],
                search: true,
                filtering: true,
                pageSize: 20,
                sorting: false,
                headerStyle: { textAlign: 'left' },
                actionsColumnIndex: -1,
                columnResizable: true,
                doubleHorizontalScroll: true,
                selectableRows: 'single',
                selectableRowsOnClick: true,
                columnsButton: true,
              }}
              actions={[
                {
                  icon: tableIcons.Show,
                  tooltip: 'Show trip',

                  onClick: (event, rowData) => {
                    const NomCircuit = rowData.nom_devis
                    this.goToTrip({
                      source: 'homePage',
                      NomCircuit,
                    })
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  invoiceList: state.invoiceReducer.invoiceList,

})
export default withRouter(connect(mapStateToProps)(DisplayInvoice))
