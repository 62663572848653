import React from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import Tabs from '@material-ui/core/Tabs';

const primaryColor = '#0bcdd6'
const secondaryColor = '#03838A'
const eventBgColor = '#D62094'

const CssGrid = withStyles({
  root: {
    '& .MuiFormControl-root': {
      display: 'grid',
    },
  },
})(Grid);

const CssSelect = withStyles(theme => ({
  input: {
    borderRadius: 4,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '21px 26px 14px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: primaryColor,
    },
  },
}))(InputBase);

const CSSRadio = withStyles({
  root: {
    color: primaryColor,
    '&$checked': {
      color: primaryColor,
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const CssCheckbox = withStyles({
  root: {
    '&$checked': {
      color: primaryColor,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const AntTabs = withStyles({
  root: {
    borderBottom: `1px solid ${secondaryColor}`,
  },
  indicator: {
    backgroundColor: secondaryColor,
  },
})(Tabs);

const colorBarGraph = [
  '#0BCDD6',
  '#00E36F',
  '#00A3ED',
  '#00EDB7',
  '#0060E3',
  '#AAD600',
  '#D66820',
  '#9720D6',
  '#D6BC20',
  '#D62094',
  '#15848A',
  '#8A7600',
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuSelectGraph = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export {
  CssGrid, CssSelect, CSSRadio, CssCheckbox, AntTabs, primaryColor, eventBgColor, colorBarGraph, MenuSelectGraph,
}
