import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  getInvoiceListResults,
  putTripBillingBillingRefResults,
  putCompanyInvoiceResults,
} from './actions'

function* getInvoiceListAsync() {
  const data = yield call(api.get_invoice_list)
  yield put(getInvoiceListResults(data))
}

function* putCompanyInvoiceAsync(params) {
  const {
    token,
    companyContent,
  } = params
  const data = yield call(api.put_company_invoice, token, companyContent)
  yield put(putCompanyInvoiceResults(data))
}
function* putTripBillingBillingRefAsync(params) {
  const {
    token,
    idDevis,
    billingDetails,
  } = params
  const data = yield call(api.put_trip_billing_billingref, token, idDevis, billingDetails)
  yield put(putTripBillingBillingRefResults(data))
}
function* fetchResult() {
  yield takeEvery('GET_INVOICE_LIST', getInvoiceListAsync)
  yield takeEvery('PUT_COMPANY_INVOICE', putCompanyInvoiceAsync)
  yield takeEvery('PUT_TRIP_BILLING_BILLINGREF', putTripBillingBillingRefAsync)
}

export default fetchResult
