import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import {
  Template,
} from '../../components'

class InConstruction extends PureComponent {
  render() {
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h3 style={{ color: '#EE0000' }}>Page under construction...</h3>
            </div>
          </div>
        </Template>
      </div>
    )
  }
}

export default withRouter(InConstruction)
