/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable key-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import BillHorizon from './BillHorizon'
import Bill from './Bill'

class TexteSimple extends PureComponent {
  static propTypes = {
    informationsContent: PropTypes.object,
  }

  static defaultProps = {

    informationsContent: {},


  }

  render() {
    const {

      informationsContent,

    } = this.props
    const { theme_devis } = informationsContent

    return (
      <div>
        {theme_devis === 'standard' && <Bill />}
        {theme_devis === 'duyen' && <Bill />}
        {theme_devis === 'standard2' && <Bill />}
        {theme_devis === 'prenium' && <Bill />}
        {theme_devis === 'duyen-template' && <Bill />}
        {theme_devis === 'Horizon' && <BillHorizon />}
        {theme_devis === 'Typic' && <Bill />}
        {theme_devis === 'dream' && <Bill />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  informationsContent: state.informationsReducer.informationsContent,

})


export default withRouter(connect(mapStateToProps)(TexteSimple))
