import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from 'react-big-scheduler';
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button';
import moment from 'moment'
import _ from 'lodash'
import withDragDropContext from './withDnDContext'
import { eventBgColor } from '../../../../configCSS'

import 'react-big-scheduler/lib/css/style.css';

const localMoment = moment.locale()
class TimelineScheduler extends Component {
  static propTypes = {
    resources: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    configModal: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.compare = this.compare.bind(this)
    this.updateResources = this.updateResources.bind(this)
    this.updateEvents = this.updateEvents.bind(this)
    this.setConfigModal = this.setConfigModal.bind(this)
    this.prevClick = this.prevClick.bind(this)
    this.nextClick = this.nextClick.bind(this)
    this.onViewChange = this.onViewChange.bind(this)
    this.onSelectDate = this.onSelectDate.bind(this)
    this.eventClicked = this.eventClicked.bind(this)
    this.ops1 = this.ops1.bind(this)
    this.ops2 = this.ops2.bind(this)
    this.newEvent = this.newEvent.bind(this)
    this.updateEventStart = this.updateEventStart.bind(this)
    this.updateEventEnd = this.updateEventEnd.bind(this)
    this.moveEvent = this.moveEvent.bind(this)
    this.onScrollRight = this.onScrollRight.bind(this)
    this.onScrollLeft = this.onScrollLeft.bind(this)
    this.onScrollTop = this.onScrollTop.bind(this)
    this.onScrollBottom = this.onScrollBottom.bind(this)
    this.toggleExpandFunc = this.toggleExpandFunc.bind(this)
    this.conflictOccurred = this.conflictOccurred.bind(this)

    this.schedulerData = new SchedulerData(moment().format(DATE_FORMAT), ViewTypes.Month, false, false, { defaultEventBgColor: eventBgColor, checkConflict: true });
    this.schedulerData.localeMoment.locale(localMoment);
    this.schedulerData.config.schedulerWidth = '82%'
    this.state = {
      viewModel: this.schedulerData,
      resources: [],
      events: [],
      firstRenderEvents: true,
    };
  }

  componentDidMount() {
    const {
      resources,
      events,
    } = this.state
    if (!_.isEmpty(resources)) {
      this.schedulerData.setResources(resources.sort(this.compare));
      this.updateResources()
    }
    if (!_.isEmpty(events)) {
      this.schedulerData.setEvents(events);
      this.updateEvents()
    }

    this.setState({
      viewModel: this.schedulerData,
    })
  }

  componentDidUpdate() {
    const {
      resources,
      events,
    } = this.props
    const {
      // firstRenderResources,
      firstRenderEvents,
      resources: stateResources,
      events: stateEvents,
    } = this.state

    // Affichage des reservation au premier rendu
    if (!_.isEmpty(events) && firstRenderEvents) {
      this.schedulerData.setEvents(events);
      this.updateEvents()
    }
    if (resources !== stateResources) {
      this.schedulerData.setResources(resources.sort(this.compare));
      this.updateResources()
    }
    if (events !== stateEvents) {
      this.schedulerData.setEvents(events);
      this.updateEvents()
    }
  }

  // Sort resources A -> Z
  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const titleA = a.name.toUpperCase();
    const titleB = b.name.toUpperCase();

    let comparison = 0;
    if (titleA > titleB) {
      comparison = 1;
    } else if (titleA < titleB) {
      comparison = -1;
    }
    return comparison;
  }

  updateResources = () => {
    const { resources } = this.props
    this.setState({
      resources,
      viewModel: this.schedulerData,
    })
  }

  updateEvents = () => {
    const { events } = this.props
    this.setState({
      events,
      firstRenderEvents: false,
      viewModel: this.schedulerData,
    })
  }

  // ****************** Scheduler *****************
  setConfigModal = (modalType, item) => {
    const { configModal } = this.props
    const config = {
      isModalOpen: true,
      modalType,
      modalContent: 'event',
      item,
    }
    configModal(config)
  }

  // Action on scheduler
  prevClick = (schedulerData) => {
    const { events } = this.state
    schedulerData.prev();
    schedulerData.setEvents(events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    const { events } = this.state
    schedulerData.next();
    schedulerData.setEvents(events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    const { events } = this.state
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective,
    );

    schedulerData.setEvents(events);

    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    const { events } = this.state
    schedulerData.setDate(date);
    schedulerData.setEvents(events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {
    this.setConfigModal('modify', event)
  };

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${
        event.title
      }}`,
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${
        event.title
      }}`,
    );
  };

  // return schedulerData, slotId, slotName, start, end, type, item
  newEvent = (schedulerData, slotId, slotName, start, end) => {
    let newFreshId = 0;
    schedulerData.events.forEach((event) => {
      if (event.id >= newFreshId) newFreshId = event.id + 1;
    });

    const newEvent = {
      id: newFreshId,
      title: '',
      start: moment(start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
      end: moment(end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
      resourceId: slotId,
    };
    schedulerData.addEvent(newEvent);
    this.setState({
      viewModel: schedulerData,
      events: schedulerData.events,
    }, () => {
      this.setConfigModal('create', newEvent)
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    const { events } = this.state
    schedulerData.updateEventStart(event, newStart);
    const newEventsArray = events.map(e => (e.id === event.id ? Object.assign({}, e, event) : e))

    this.setState({
      viewModel: schedulerData,
      events: newEventsArray,
    }, () => {
      this.setConfigModal('modify', newEventsArray.filter(e => e.id === event.id)[0])
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    const { events } = this.state
    schedulerData.updateEventEnd(event, newEnd);
    const newEventsArray = events.map(e => (e.id === event.id ? Object.assign({}, e, event) : e))

    this.setState({
      viewModel: schedulerData,
      events: newEventsArray,
    }, () => {
      this.setConfigModal('modify', newEventsArray.filter(e => e.id === event.id)[0])
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    const { events } = this.state
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    const newEventsArray = events.map(e => (e.id === event.id ? Object.assign({}, e, event) : e))

    this.setState({
      viewModel: schedulerData,
      events: newEventsArray,
    }, () => {
      this.setConfigModal('modify', newEventsArray.filter(e => e.id === event.id)[0])
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollTop', schedulerData, schedulerContent, maxScrollTop);
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollBottom', schedulerData, schedulerContent, maxScrollTop);
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  conflictOccurred = () => {
    alert('A conflict occur between start and end time')
  }

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
    const { deleteEvent } = this.props
    return (
      <div style={{ width: '300px' }}>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div className="status-dot" style={{ backgroundColor: statusColor }} />
          </Col>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={title}>{title}</span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header1-text">{start.format('MMM-D')}  <span className="header2-text">{start.format('HH:mm')}</span> - {end.format('MMM-D')}  <span className="header2-text">{end.format('HH:mm')}</span></span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <Button
              onClick={() => this.eventClicked(null, eventItem)}
              style={{
                border: 'none',
                borderRadius: '14px',
                padding: '10px 20px',
                marginTop: '10px',
                color: '#ffffff',
                backgroundColor: statusColor,
                cursor: 'pointer',
              }}
            >
              MODIFY
            </Button>
            <Button
              onClick={() => deleteEvent(eventItem)}
              style={{
                border: 'none',
                backgroundColor: 'inherit',
                padding: '10px 20px',
                marginTop: '10px',
                marginLeft: '20px',
                color: '#ff0000',
                cursor: 'pointer',
              }}
            >
              delete
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  demoButtonClicked = (eventItem) => {
    alert(`You just clicked demo button. event title: ${eventItem.title}`);
  }

  render() {
    const { viewModel } = this.state;
    return (
      <div className="scheduler-filter-container">
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          // viewEventText="Modify"
          // viewEvent2Text="Delete"
          viewEvent2Click={this.ops2}
          updateEventStart={this.updateEventStart}
          updateEventEnd={this.updateEventEnd}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
          conflictOccurred={this.conflictOccurred}
          eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
        />
      </div>
    );
  }
}

export default withDragDropContext(TimelineScheduler)
// export default withDragDropContext(Basic);
