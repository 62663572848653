
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Interweave from 'interweave';
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import _ from 'lodash'
import stage from '../../../config'
import MapContainer from '../../MapContainer'
// eslint-disable-next-line no-unused-vars
import translateBoard from '../../../generate/utils/translateBoard'

const savedImgUrl = stage.imgStorage

class GenerateHtmlDuyen extends PureComponent {
  static propTypes = {
    tripDetails: PropTypes.array,
    tripAllDaysAccomodations: PropTypes.array,
    tripAllDays: PropTypes.array,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
    userAccount: PropTypes.object,
    tripContent: PropTypes.array,
    tripPension: PropTypes.array,
    tripAccomodation: PropTypes.array,
    roomList: PropTypes.array,
    locationList: PropTypes.array,
    tripBilling: PropTypes.array,

  }

  static defaultProps = {
    userAccount: {},
    tripDetails: [],
    tripPension: [],
    tripBilling: [],
    tripAccomodation: [],
    tripContent: [],
    companyContent: {},
    informationsContent: {},
    tripAllDaysAccomodations: [],
    locationList: [],
    tripAllDays: [],
    roomList: [],


  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  handleOSizeChange = (event) => {
    document.documentElement.style.setProperty('--CarteTypic-size', `${event.currentTarget.value}px`)
  }

  handleOSizeChange2 = (event) => {
    document.documentElement.style.setProperty('--hauteurBrefTypic-size', `${event.currentTarget.value}px`)
  }

  handleOSizeChange3 = (event) => {
    document.documentElement.style.setProperty('--hauteurHotelTypic-size', `${event.currentTarget.value}px`)
  }

  handleOSizeChange4 = (event) => {
    document.documentElement.style.setProperty('--hauteurPrixTypic-size', `${event.currentTarget.value}px`)
  }


  render() {
    const findOcc = (arr, key) => {
      const arr2 = [];

      arr.forEach((x) => {
        // Checking if there is any object in arr2
        // which contains the key value
        if (arr2.some(val => val[key] === x[key])) {
          // If yes! then increase the occurrence by 1
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              // eslint-disable-next-line no-plusplus
              k.occurrence++
            }
          })
        } else {
          // If not! Then create a new object initialize
          // it with the present iteration key's value and
          // set the occurrence to 1
          const a = {}
          a[key] = x[key]
          a.occurrence = 1
          arr2.push(a);
        }
      })
      return arr2
    }

    const {
      tripDetails,
      tripAccomodation,
      tripPension,
      tripAllDaysAccomodations,
      tripAllDays,
      tripBilling,
      companyContent,
      informationsContent,
      userAccount,
      tripContent,
      roomList,
      locationList,
    } = this.props


    const {
      prenom_responsable_groupe,
      nom_responsable_groupe,
      langage,
      version,
      nb_adulte,
      nb_enfant,
      nb_bebe,
      telephone,
      destination,
      desc_voyage,
      centre_interet,
      e_mail,
    } = tripDetails[0]

    const {
      guide,
      chauffeur,
      nb_chambre,
      type_chambre,
    } = tripContent[0]
    // *****************************************************************
    const slogan = `${langage}_slogan`
    const presentation = `${langage}_presentation`
    const longueurVoyage = tripAllDaysAccomodations.length
    const NombreInformation = [0, 1, 2, 3, 4];
    const tauxchange = `${langage}_taux_change`
    const guideArray = _.split(guide, ',')
    const chauffeurArray = _.split(chauffeur, ',')
    const roomTypeArray = _.split(type_chambre, ',')
    const roomsNumberArray = nb_chambre ? _.split(nb_chambre, ',') : null
    const Hotel_Unique = _.uniqBy(tripAllDaysAccomodations, 'CleLieuAcco')
    const Nombre_Occurence_Hotel = findOcc(tripAllDaysAccomodations, 'CleLieuAcco')
    const langageTranslation = translateBoard.filter(l => l.isoCode === langage)[0].translation
    const firstname = prenom_responsable_groupe || null
    const lastname = nom_responsable_groupe || null
    const nbAdult = nb_adulte && nb_adulte !== '0' ? `${nb_adulte} ${nb_adulte > 1 ? 'adultes' : 'adulte'}` : ''
    const nbChildren = nb_enfant && nb_enfant !== '0' ? `${nb_enfant} ${nb_enfant > 1 ? 'enfants' : 'enfant'}` : ''
    const nbBaby = nb_bebe && nb_bebe !== '0' ? `${nb_bebe} ${nb_bebe > 1 ? 'bébés' : 'bébé'}` : ''
    const nbAdultNumb = nb_adulte && !isNaN(parseInt(nb_adulte, 10)) ? parseInt(nb_adulte, 10) : 0
    const nbChildNumb = nb_enfant && !isNaN(parseInt(nb_enfant, 10)) ? parseInt(nb_enfant, 10) : 0
    const nbBabyNumb = nb_bebe && !isNaN(parseInt(nb_bebe, 10)) ? parseInt(nb_bebe, 10) : 0
    const totalTraveler = nbAdultNumb + nbChildNumb + nbBabyNumb
    const dateDepart = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').format('DD MMMM YYYY')
    const dateFin = moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'fr').add(longueurVoyage, 'd').format('DD MMMM YYYY')
    const TabIncJour = tripAllDaysAccomodations.map(item => (item.day_duration))
    const remarque = `${langage}_remarque`
    const categorie = `${langage}_categorie`
    const prixPersNoReduc = Math.round((tripBilling.sellingPrice / totalTraveler) * 100) / 100
    const travelerName = firstname || lastname ? [firstname, lastname].join(' ') : null
    const nbOfRoomLabel = (index) => {
      const roomsTypeForDay = roomTypeArray[index] && roomTypeArray[index] !== '1' ? roomTypeArray[index].split('-') : []
      const roomsNbForDay = roomsNumberArray && roomsNumberArray[index] !== 'null' ? roomsNumberArray[index].split('-') : []
      const roomsNameForDay = roomsTypeForDay.map(rT => roomList.find(r => r.id_type_chambre === parseInt(rT, 10)).type_chambre)
      const label = _.isEmpty(roomsNbForDay) ? [] : roomsNameForDay.map((r, j) => `${r} (x${roomsNbForDay[j]})${roomsNameForDay.length === j ? '' : '. '}`)
      return label.join('')
    }
    const Couleurdefaut = {
      color: informationsContent.main_color,
    };


    return (

      // eslint-disable-next-line react/jsx-no-comment-textnodes
      <div>
        <div className="GlobalFileTypic">

          <div className="page-header-typic">
            <img className="LogoTypic" src={`${savedImgUrl}/${companyContent.company}/${companyContent.logo}`} />

            <div className="sousTitreTypic" style={Couleurdefaut}>Devis Voyage 2022/2023 | {travelerName}</div>

            <br />

          </div>
          <div>
            <img className="ImageCoverTypic" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].cover_image}`} />
            <div className="titreTypic" style={Couleurdefaut}>{tripDetails && (tripDetails[0].nom_programme)}</div>
            <div className="sousTitreTypic" style={Couleurdefaut}>{longueurVoyage} {langageTranslation.days} / {longueurVoyage - 1} {langageTranslation.nightOnPlace}</div>
            <br />
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={3}>
                <img className="ImageConseilTypic" src={`${savedImgUrl}/${companyContent.company}/${userAccount.image}`} />
              </Grid>
              <Grid item xs={9}>
                <Interweave content={userAccount[presentation]} />

              </Grid>
            </Grid>
            <br />
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12}>

                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Tableau1TypicHead" item xs={4}>{langageTranslation.quote}. {version} </Grid>
                  <Grid className="Tableau1TypicHead" item xs={8}>{travelerName}</Grid>
                </Grid>

                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>{langageTranslation.privateTrip}</Grid>
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>No. des personnes</Grid>
                  <Grid className="Tableau1Typic_droite" style={Couleurdefaut} item xs={4}>{totalTraveler} {langageTranslation.persons}</Grid>

                </Grid>
                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>{langageTranslation.destination}</Grid>
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>
                    Nu. de téléphone

                  </Grid>
                  <Grid className="Tableau1Typic_droite" style={Couleurdefaut} item xs={4}>
                    <div>{telephone}</div>
                  </Grid>

                </Grid>
                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>{destination}</Grid>
                  <Grid className="Tableau1Typic" style={Couleurdefaut} item xs={4}>
                    Email
                  </Grid>
                  <Grid className="Tableau1Typic_droite" style={Couleurdefaut} item xs={4}>
                    <div>{e_mail}</div>
                  </Grid>

                </Grid>
                <Grid container spacing={0} justifyContent="center">
                  <Grid className="Tableau1Typic_bas" style={Couleurdefaut} item xs={4}>Dates du Circuit</Grid>
                  <Grid className="Tableau1Typic_bas_droite" style={Couleurdefaut} item xs={8}>Du {dateDepart} au {dateFin} </Grid>

                </Grid>
              </Grid>
            </Grid>
            <hr size="1" color={informationsContent.main_color} />
            <div className="page-footer-typic">
              <Interweave content={companyContent[slogan]} />

              <img className="imageFooter" src={`${savedImgUrl}/${companyContent.company}/footer-logo-1651634708394.png`} />


            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>
                  <div className="page-header-space-typic" />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="CoupePas">
                    <div className="titreTypic2" style={Couleurdefaut}><mark className="backRed">{langageTranslation.itineraryInBriefTypic}</mark></div>
                    <br />

                    <div className="TabItineraireGlobalTypic">
                      <Grid container spacing={0} justifyContent="center">
                        <Grid item xs={12}>
                          <Grid container spacing={0} justifyContent="center">
                            <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={1}>{langageTranslation.day}</Grid>
                            <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{langageTranslation.date}</Grid>
                            <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={5}>{langageTranslation.itinerary}</Grid>
                            <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{langageTranslation.accomodation}</Grid>
                            <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{langageTranslation.guideDriver}</Grid>

                          </Grid>
                          {tripAllDaysAccomodations.map((item, index) => {
                            const IncDay = _.sum(TabIncJour.slice(0, index))
                            const guideNeed = guideArray[index] === '1' ? langageTranslation.guide : ''
                            const driverNeed = chauffeurArray[index] === '1' ? langageTranslation.driver : ''
                            const union = guideNeed && driverNeed ? '&' : ''
                            const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver

                            const datejour = tripDetails[0].date_depart

                              ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'FR').add(IncDay, 'd').format('ddd DD MMM'))
                              : ''
                            return (
                              <div key={`Bref${item.id_journee}`}>
                                <a href={`#Jour${index}`}>
                                  <Grid container spacing={0} justifyContent="center">
                                    <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={1}>{langageTranslation.day} {index + 1}</Grid>
                                    <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{datejour}</Grid>

                                    <Grid className="TabIntroTypic_desc" item xs={5}><span style={Couleurdefaut}>{item.nom_journee_court} {tripPension[index].pension !== 'none' ? (`(${tripPension[index].pension})`) : ('')}</span> {item.transport && (<span className="enRouge">, {item.transport}</span>)}</Grid>
                                    <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</Grid>
                                    <Grid className="TabIntroTypic" style={Couleurdefaut} item xs={2}>{asteriskText}</Grid>

                                  </Grid>
                                </a>
                              </div>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </div>
                    <input className="adjustSize" name="size" min="15" max="40" type="range" defaultValue="35" onChange={this.handleOSizeChange2} />
                    <div className="sousTitreTypicRight">Noter: B = P.déjeuner, L = Déjeuner et D = Dîner</div>
                  </div>
                  <br />
                  <div className="sousTitreTypicLeft"><mark className="backRed">Remarques</mark></div>
                  <br />
                  <Interweave content={langageTranslation.typicRemark1} />
                  <br />
                  <div className="CoupePas">

                    <Interweave content={desc_voyage} />
                  </div>
                  {(tripDetails[0].image || (tripDetails[0].map_localisation !== null && tripDetails[0].map_localisation !== 'null')) && (

                    <div>
                      <br />
                      <br />
                      <div className="CoupePas">
                        <div className="titreTypic2" style={Couleurdefaut}><mark className="backRed">{langageTranslation.titreCarteTypic}</mark></div>
                        <br />
                        <br />
                        {tripDetails[0].image
                          ? (
                            <div>
                              <Grid container spacing={0} justifyContent="center">
                                <Grid item xs={12}>
                                  <img className="CarteTypic" src={`${savedImgUrl}/${companyContent.company}/${tripDetails[0].image}`} />
                                </Grid>
                              </Grid>
                              <input className="adjustSize" name="size" min="200" max="1600" type="range" defaultValue="1200" onChange={this.handleOSizeChange} />
                            </div>
                          ) : (

                            tripDetails[0].map_localisation && <MapContainer />

                          )

                         }
                      </div>
                    </div>
                  )}
                  <div className="CoupePas">

                    <Interweave content={centre_interet} />
                  </div>
                  <br />
                  <br />
                  <div className="CoupePas">
                    <div className="titreTypic2" style={Couleurdefaut}><mark className="backRed">{langageTranslation.detailedProgram}</mark></div>
                    <br />
                    <br />

                    {tripAllDays.map((item_jour, index) => {
                      const desc = `desc_jour_${index + 1}`;
                      const imageArray = item_jour.image.split(',')
                      const IncDay = _.sum(TabIncJour.slice(0, index + 1))
                      const datejour = tripDetails[0].date_depart ? _.capitalize(moment(tripDetails[0].date_depart, 'YYYY-MM-DD', 'FR').add(IncDay, 'd').format('dddd DD MMMM YYYY'))
                        : ''
                      const guideNeed = guideArray[index] === '1' ? langageTranslation.withGuide : ''
                      const driverNeed = chauffeurArray[index] === '1' ? langageTranslation.driver : ''
                      const union = guideNeed && driverNeed ? ` ${langageTranslation.and} ` : ''
                      const asteriskText = `${guideNeed}${union}${driverNeed}` !== '' ? `${guideNeed}${union}${driverNeed}` : langageTranslation.noGuideNoDriver


                      return (
                        <div key={`detail${item_jour.id_journee}`}>
                          <div className="CoupePas">
                            <div className="titreJourTypic" id={`Jour${index}`} style={Couleurdefaut}> Jour {index + 1} : {item_jour.nom_journee} {tripPension[index].pension !== 'none' ? (`(${tripPension[index].pension})`) : ('')}</div>
                            <div className="datejourTypic" style={Couleurdefaut}> {datejour}</div>
                            <div className="paragrapheTypic" style={Couleurdefaut}> &#9654; {asteriskText}</div>
                            {item_jour.distance && (<div className="paragrapheTypic" style={Couleurdefaut}>&#9654; {item_jour.distance}</div>)}

                            {imageArray[0] !== '' && imageArray.length === 1
                              ? (

                                <Grid container spacing={2} justifyContent="center">
                                  <Grid item xs={4}>
                                    <img className="Image1Jour1Typic" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                                  </Grid>
                                  <Grid className="OptionTextJournee" item xs={8}>
                                    <Interweave content={tripContent[0][desc]} />

                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement} {tripAccomodation[index][categorie]}</span>)}
                                    {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={tripAccomodation[index].adresse} /></span><span>)</span></>)}

                                    {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                    <br />
                                  </Grid>


                                </Grid>


                              ) : (
                                <div>

                                  <Grid container spacing={0} justifyContent="center">
                                    <Grid className="OptionTextJournee" item xs={12}>
                                      <Interweave content={tripContent[0][desc]} />
                                      {tripAccomodation[index].nom_hebergement !== 'none' && (<span className="OptionService">{langageTranslation.accomodation}: {tripAccomodation[index].nom_hebergement}</span>)}
                                      {tripAccomodation[index].adresse !== '' && (<><span> (</span><span><Interweave content={tripAccomodation[index].adresse} /></span><span>)</span></>)}

                                      {tripAccomodation[index].nom_hebergement !== 'none' && (<div className="OptionService">{!nbOfRoomLabel(index) ? '' : (`${langageTranslation.room}: ${nbOfRoomLabel(index)}`)}</div>)}
                                      <br />
                                    </Grid>
                                  </Grid>

                                </div>
                              )
                       }
                          </div>
                          {imageArray[0] !== '' && imageArray.length === 2
                    && (
                      <div>
                        <Grid container spacing={10} justifyContent="center">
                          <Grid item xs={4}>
                            <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                          </Grid>
                          <Grid item xs={4}>
                            <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                          </Grid>
                        </Grid>
                      </div>
                    )}

                          {imageArray[0] !== '' && imageArray.length === 3
            && (
              <div>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={4}>
                    <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[0]}`} />
                  </Grid>
                  <Grid item xs={4}>
                    <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[1]}`} />
                  </Grid>
                  <Grid item xs={4}>
                    <img className="Image1Jour" src={`${savedImgUrl}/${companyContent.company}/${imageArray[2]}`} />
                  </Grid>
                </Grid>
              </div>
            )}

                        </div>
                      )
                    })}
                  </div>
                  <br />
                  <br />
                  <div className="sousTitreTypicRight">{langageTranslation.serviceEnd}</div>
                  <div className="sousTitreTypic" style={Couleurdefaut}>L’équipe TYPIC Travel vous souhaite le bon voyage</div>
                  <br />
                  <br />


                  <div className="CoupePas">
                    <div className="titreTypic2" style={Couleurdefaut}><mark className="backRed">{langageTranslation.yourHotels2}</mark></div>
                    <br />
                    <br />
                    <div className="TabItineraireGlobalTypic">

                      <Grid container spacing={0} justifyContent="center">
                        <Grid item xs={12}>

                          <Grid container spacing={0} justifyContent="center">
                            <Grid className="TabIntroTypicHotel" style={Couleurdefaut} item xs={3}>{langageTranslation.destination}</Grid>
                            <Grid className="TabIntroTypicHotel" style={Couleurdefaut} item xs={7}>{langageTranslation.accomodation}</Grid>
                            <Grid className="TabIntroTypicHotel" style={Couleurdefaut} item xs={2}>{langageTranslation.nightNumber}</Grid>

                          </Grid>

                          {Hotel_Unique && Hotel_Unique.map((item, i) => {
                            const LieuFinJournee = _.find(locationList, o => o.id_lieu === item.lieu_fin_journee)

                            return (
                              <div key={`Acco${item.id_journee}`}>
                                {item.nom_hebergement !== 'none'
                  && (
                  <div>
                    <Grid container spacing={0} justifyContent="center">
                      <Grid className="TabIntroTypicHotel" style={Couleurdefaut} item xs={3}>{LieuFinJournee.lieu}</Grid>
                      <Grid className="TabIntroTypicHotelDesc" style={Couleurdefaut} item xs={7}>
                        <div>{item.nom_hebergement} {item[categorie]}</div>
                        <Interweave content={item.adresse} />
                      </Grid>
                      <Grid className="TabIntroTypicHotel" style={Couleurdefaut} item xs={2}>{Nombre_Occurence_Hotel[i].occurrence}</Grid>

                    </Grid>
                  </div>
                  )}
                              </div>

                            )
                          })}

                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <input className="adjustSize" name="size" min="30" max="60" type="range" defaultValue="37" onChange={this.handleOSizeChange3} />

                  <br />
                  <br />
                  {NombreInformation.map((item, index) => {
                    const InformationText = langage && `${langage}_information_${index + 1}_description`;
                    const InformationImage = `information_${index + 1}_image`;
                    const InformationTitre = langage && `${langage}_information_${index + 1}`;

                    return (
                      <div key={`info${item}`}>
                        {informationsContent[InformationText] !== ''
                    && (
                    <div className="CoupePas">
                      <br />
                      <div className="sousTitreTypicLeft"><mark className="backRed">{informationsContent[InformationTitre]}</mark></div>
                      <br />
                      {informationsContent[InformationImage] !== '' ? (
                        <Grid container spacing={1} justifyContent="center">
                          <Grid item xs={4}>
                            <img className="imgInformation" src={`${savedImgUrl}/${companyContent.company}/${informationsContent[InformationImage]}`} />
                          </Grid>
                          <Grid item xs={8}>
                            <Interweave content={informationsContent[InformationText]} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={1} justifyContent="center">
                          <Grid item xs={12}>
                            <Interweave content={informationsContent[InformationText]} />
                          </Grid>
                        </Grid>
                      )
                      }
                    </div>
                    )}
                      </div>
                    )
                  })}
                  <br />
                  <br />
                  {tripBilling.tripPrice >= 0 && (
                  <div>
                    <div className="CoupePas">
                      <div className="titreTypic2" style={Couleurdefaut}><mark className="backRed">TABLEAU DE TARIFICATION</mark></div>

                      <br />
                      <div style={Couleurdefaut}> ({langageTranslation.priceCalc} {tripBilling.currency} {langageTranslation.priceCalc2} {nbAdult && nbAdult} {nbChildren && nbChildren} {nbBaby && nbBaby}) </div>
                      <br />
                      {tripBilling.displayTabCheck === 0 ? (
                        <div className="TabItineraireGlobalTypic">

                          <Grid container spacing={0} align="row" justifyContent="center">
                            <Grid item xs={12}>
                              <div style={Couleurdefaut} className="TabIntroTypicPrix"> {tripDetails[0].nom_programme}</div>

                            </Grid>
                            <Grid item xs={6}>
                              {totalTraveler !== 0 && (<div style={Couleurdefaut} className="TabIntroTypicPrix"> {langageTranslation.groupSize}</div>)}
                              <div style={Couleurdefaut} className="TabIntroTypicPrix"> {langageTranslation.pricePerPerson}   </div>

                            </Grid>
                            <Grid item xs={6}>
                              {totalTraveler !== 0 && (<div style={Couleurdefaut} className="TabIntroTypicPrix"> {totalTraveler}</div>)}
                              <div style={Couleurdefaut} className="TabIntroTypicPrix">  {prixPersNoReduc} {tripBilling.currency} </div>

                            </Grid>
                            {tripBilling.amountDiscount !== 0 && (
                            <Grid item xs={12}>
                              <div className="TabIntroTypicPrixReduc">{langageTranslation.giftDiscount1} {tripBilling.discount} {langageTranslation.giftDiscount2}  </div>
                            </Grid>
                            )}
                            <Grid item xs={6}>
                              {tripBilling.amountDiscount !== 0 ? (<div style={Couleurdefaut} className="TabIntroTypicPrix">{langageTranslation.priceAfterDiscount} </div>)
                                : (
                                  <div style={Couleurdefaut} className="TabIntroTypicPrix">{langageTranslation.pricePerPerson} </div>
                                )}


                            </Grid>
                            <Grid item xs={6}>
                              <div style={Couleurdefaut} className="TabIntroTypicPrix"> {tripBilling.pricePerPerson} {tripBilling.currency}/personne </div>


                            </Grid>
                            <Grid item xs={6}>
                              <div style={Couleurdefaut} className="TabIntroTypicPrix">
                                {langageTranslation.total}
                              </div>


                            </Grid>
                            <Grid item xs={6}>
                              <div style={Couleurdefaut} className="TabIntroTypicPrix">
                                {tripBilling.tripPrice} {tripBilling.currency}

                              </div>
                            </Grid>
                          </Grid>

                        </div>
                      ) : (
                        <Grid container spacing={0} align="row" justifyContent="center">
                          <Grid item xs={12}>
                            <div style={Couleurdefaut} className="titreGrasCentre"> {tripDetails[0].nom_programme}</div>

                          </Grid>
                          <Grid item xs={12}>
                            <Interweave content={tripBilling.tabPrice} />
                          </Grid>
                        </Grid>
                      )}
                      <br />
                      <input className="adjustSize" name="size" min="30" max="60" type="range" defaultValue="37" onChange={this.handleOSizeChange4} />

                      <div className="sousTitreTypicRight" style={Couleurdefaut}>{informationsContent[tauxchange]}</div>
                    </div>
                    <br />
                  </div>
                  )}
                  <div className="CoupePas">
                    <br />
                    <div className="sousTitreTypicLeft"><mark className="backRed">PRESTATIONS COMPRISES</mark></div>
                    <br />

                    <Grid container spacing={0} align="row" justifyContent="center">

                      <Grid className="bordPointilleDroit" item xs={6}>
                        <div style={Couleurdefaut}>{langageTranslation.priceContained}: </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend} />
                        <br />


                      </Grid>
                      <Grid item xs={6}>
                        <div style={Couleurdefaut}>{langageTranslation.priceDoNotContained}: </div>
                        <br />
                        <Interweave content={tripDetails[0].prix_comprend_pas} />
                        <br />
                      </Grid>
                    </Grid>

                  </div>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={12}>
                      <Interweave content={informationsContent[remarque]} />
                    </Grid>
                  </Grid>
                  <div className="CoupePas">
                    <img className="imageTypicFin" src={`${savedImgUrl}/${companyContent.company}/temoignageTypic-1651634081483.png`} />
                  </div>
                  <div className="CoupePas">
                    <img className="imageTypicFin" src={`${savedImgUrl}/${companyContent.company}/typicend-1651479356788.png`} />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="page-footer-space" />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tripDetails: state.api.tripDetails,
  locationList: state.api.locationList,
  companyContent: state.companyReducer.companyContent,
  tripContent: state.api.tripContent,
  informationsContent: state.informationsReducer.informationsContent,
  tripAccomodation: state.api.tripAccomodation,
  tripAccomodationOpt: state.api.tripAccomodationOpt,
  tripRoom: state.api.tripRoom,
  tripBilling: state.billingReducer.tripBilling,
  tripPrice: state.billingReducer.tripPrice,
  tripPension: state.api.tripPension,
  tripAllDays: state.api.tripAllDays,
  tripAllDaysAccomodations: state.api.tripAllDaysAccomodations,
  daysList: state.api.daysList,
  collaborators: state.collaboratorsReducer.collaborators,
  roomList: state.api.roomList,
  userAccount: state.userAccountReducer.userAccount,

})

export default withRouter(connect(mapStateToProps)(GenerateHtmlDuyen))
