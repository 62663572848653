import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import tableIcons from '../utils/icons/icons'

const {
  Add,
} = tableIcons

export default function AddButton(props) {
  const {
    title,
    goTo,
  } = props;

  return (
    <span className="add-button-customized">
      <Tooltip title={title}>
        <IconButton
          aria-label="add_new_day"
          onClick={() => goTo()}
        >
          <Add />
        </IconButton>
      </Tooltip>
    </span>
  );
}

AddButton.propTypes = {
  title: PropTypes.string.isRequired,
  goTo: PropTypes.func.isRequired,
};
