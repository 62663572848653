import _ from 'lodash'

const getProgressionBooking = (tripPrice) => {
  // get array with all item check results [0, 1, 1, 0,....]
  const progressionBookingAll = _.flatten(tripPrice.map(day => _.pull(_.map(day, (p, key) => {
    if (key.includes('id_') || key.includes('Comment') || key.includes('Check') || key.includes('dayRank')) return null
    if (!p) return null

    return day[`${key}Check`]
  }), null)))
  // get array of check item results only [1, 1, 1, ...]
  const progressionBookingChecked = _.compact(progressionBookingAll).length
  return Math.round((progressionBookingChecked / progressionBookingAll.length) * 100)
}

export default getProgressionBooking
