import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Template,
  ActionButton,
} from '../../components'
import {
  CollaboratorSummary, ModalCard,
} from './component'

class Collaborators extends PureComponent {
  static propTypes = {
    GET_COLLABORATORS: PropTypes.func.isRequired,
    GET_PROFIL_USER_LIST: PropTypes.func.isRequired,
    PUT_COLLABORATORS: PropTypes.func.isRequired,
    RESET_COLLABORATORS: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    collaborators: PropTypes.array,
    putCollaborators: PropTypes.object,
    profilUserList: PropTypes.array,
  }

  static defaultProps = {
    collaborators: [],
    putCollaborators: {},
    profilUserList: [],
  }

  constructor(props) {
    super(props);
    this.profilChange = this.profilChange.bind(this)
    this.displayValidMessage = this.displayValidMessage.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      collaborators: [],
      collaboratorsModified: [],
      token: localStorage.getItem('token'),
      isValidated: true,
      isModalOpen: false,
      collaboratorCard: {},
      validMsg: '',
      errorMsg: '',
    }
  }

  componentDidMount() {
    const {
      history,
      collaborators,
      profilUserList,
      GET_COLLABORATORS,
      GET_PROFIL_USER_LIST,
    } = this.props
    const {
      token,
    } = this.state
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    // check if props exists
    if (!_.isEmpty(collaborators)) {
      this.setState({
        collaborators,
      })
    } else {
      GET_COLLABORATORS(token)
    }
    if (_.isEmpty(profilUserList)) {
      GET_PROFIL_USER_LIST()
    }
  }

  componentDidUpdate(previousProps) {
    const {
      token,
    } = this.state
    const {
      collaborators,
      putCollaborators,
      RESET_COLLABORATORS,
      GET_COLLABORATORS,
    } = this.props

    if (collaborators !== previousProps.collaborators) {
      this.updateCollaborators(collaborators)
    }
    if (putCollaborators && putCollaborators.success) {
      RESET_COLLABORATORS()
      GET_COLLABORATORS(token)
      this.displayValidMessage()
      this.resetValidMsg()
    }
  }

  updateCollaborators = (collaborators) => {
    this.setState({
      collaborators,
    })
  }

  displayValidMessage = () => {
    this.setState({
      errorMsg: '',
      validMsg: 'Modification was successfully recorded !',
      isValidated: true,
    })
  }

  resetValidMsg = () => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  profilChange = (e, id_conseiller) => {
    const {
      collaborators,
      collaboratorsModified,
    } = this.state
    this.setState({
      collaborators: collaborators.map(c => (c.id_conseiller === id_conseiller ? Object.assign(c, { profil: e.target.value }) : c)),
      isValidated: false,
    })
    collaboratorsModified.push(id_conseiller)
  }

  handleOpen = (id) => {
    const { collaborators } = this.state
    this.setState({
      collaboratorCard: collaborators.filter(c => c.id_conseiller === id)[0],
    }, () => this.setState({
      isModalOpen: true,
    }));
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  validate = (e) => {
    e.preventDefault()
    const {
      token,
      collaborators,
      collaboratorsModified,
    } = this.state
    const {
      PUT_COLLABORATORS,
    } = this.props

    const hasAdministrator = collaborators.find(c => c.profil === 1)
    if (!hasAdministrator) {
      this.setState({
        errorMsg: 'You must define an admistrator at least !',
      })
    } else {
      const collaboratorsToUpdate = collaboratorsModified.map(id => collaborators.filter(c => c.id_conseiller === id)[0])
      PUT_COLLABORATORS(token, collaboratorsToUpdate)
    }
  }

  render() {
    const {
      collaborators,
      validMsg,
      errorMsg,
      isValidated,
      isModalOpen,
      collaboratorCard,
    } = this.state
    const {
      profilUserList,
    } = this.props

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>COLLABORATORS</h2>
              {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              <div>
                {collaborators && collaborators.map(collab => (
                  <CollaboratorSummary
                    key={collab.id_conseiller}
                    collaborator={collab}
                    profilUserList={profilUserList}
                    profilChange={this.profilChange}
                    handleOpen={id_conseiller => this.handleOpen(id_conseiller)}
                  />
                ))}
              </div>
            </div>
            <ActionButton
              label="Validate"
              onClick={e => this.validate(e)}
            />
            <ModalCard
              isModalOpen={isModalOpen}
              onClose={() => this.handleClose()}
              collaborator={collaboratorCard}
            />
          </div>
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  collaborators: state.collaboratorsReducer.collaborators,
  putCollaborators: state.collaboratorsReducer.putCollaborators,
  profilUserList: state.api.profilUserList,
})

const mapDispatchToProps = dispatch => ({
  GET_COLLABORATORS: token => dispatch({
    type: 'GET_COLLABORATORS',
    token,
  }),
  PUT_COLLABORATORS: (token, collaborators) => dispatch({
    type: 'PUT_COLLABORATORS',
    token,
    collaborators,
  }),
  RESET_COLLABORATORS: () => dispatch({
    type: 'RESET_COLLABORATORS',
  }),
  GET_PROFIL_USER_LIST: () => dispatch({
    type: 'GET_PROFIL_USER_LIST',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collaborators))
