import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import api from './api'
import {
  putSuppliersContentResults,
  getSuppliersListResults,
  deleteSuppliersContentResults,
} from './actions'

function* getSuppliersListAsync(params) {
  const {
    userId,
  } = params
  const data = yield call(api.get_suppliers_list, userId)
  yield put(getSuppliersListResults(data))
}

function* putSuppliersContentAsync(params) {
  const {
    token,
    suppliersContent,
    action,
  } = params
  const data = yield call(api.put_suppliers_content, token, suppliersContent, action)
  yield put(putSuppliersContentResults(data))
}

function* deleteSuppliersContentAsync(params) {
  const {
    token,
    supplierId,
  } = params
  const data = yield call(api.delete_suppliers_content, token, supplierId)
  yield put(deleteSuppliersContentResults(data))
}


function* fetchResult() {
  yield takeEvery('GET_SUPPLIERS_LIST', getSuppliersListAsync)
  yield takeEvery('PUT_SUPPLIERS_CONTENT', putSuppliersContentAsync)
  yield takeEvery('DELETE_SUPPLIERS_CONTENT', deleteSuppliersContentAsync)
}

export default fetchResult
