import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Prompt } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'

class RequestorManagement extends PureComponent {
  static propTypes = {
    GET_CUSTOMER_LIST: PropTypes.func.isRequired,
    PUT_CUSTOMER_CONTENT: PropTypes.func.isRequired,
    DELETE_CUSTOMER_CONTENT: PropTypes.func.isRequired,
    RESET_CUSTOMER_CONTENT: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    customerList: PropTypes.array,
    putCustomerContent: PropTypes.object,
    deleteCustomerContent: PropTypes.object,
  }

  static defaultProps = {
    customerList: [],
    putCustomerContent: {},
    deleteCustomerContent: {},
  }

  constructor(props) {
    super(props);
    this.reload = this.reload.bind(this)
    this.displayAutocompleteCustomer = this.displayAutocompleteCustomer.bind(this)
    this.displayDayContenInputs = this.displayDayContenInputs.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItemTitle = this.modifyItemTitle.bind(this)
    this.validate = this.validate.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.fillEmptyCustomerName = this.fillEmptyCustomerName.bind(this)
    this.onChangeCustomer = this.onChangeCustomer.bind(this)
    this.filterOptionsCustomer = this.filterOptionsCustomer.bind(this)
    this.getOptionLabelCustomer = this.getOptionLabelCustomer.bind(this)
    this.getModifiedItem = this.getModifiedItem.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      customerList: [],
      customerContent: {},
      isNewCustomer: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      validateError: false,
      errorMsg: null,
      validMsg: null,
      isValidated: true,
      actionLabel: 'Create',
      isModalOpen: false,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      customerList,
      GET_CUSTOMER_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(customerList)) {
      this.setState({
        customerList: _.sortBy(customerList, c => c.demandeur),
      })
    } else {
      GET_CUSTOMER_LIST(userId)
    }
    // access from requestor table for editing mode
    if (history.location.state && history.location.state.source === 'requestor-table') {
      const {
        idRequestor,
      } = history.location.state
      const requestorToEdit = customerList.filter(d => d.id_demandeur === idRequestor)[0]
      this.setState({
        customerContent: requestorToEdit,
        actionLabel: 'Modify',
      })
    }
  }

  componentWillReceiveProps(nextprops) {
    const {
      customerContent,
      customerList: customerListOld,
    } = this.state
    const {
      customerList,
      putCustomerContent,
      deleteCustomerContent,
      RESET_CUSTOMER_CONTENT,
    } = nextprops
    // Customer saved in DB -> get back updated list
    if (putCustomerContent && putCustomerContent.success) {
      this.setState({
        customerContent: {
          ...customerContent,
          id_demandeur: putCustomerContent.id,
        },
        validMsg: 'the customer was successfully recorded !',
        isValidated: true,
      })
      this.reload()
      this.resetValidMsg()
    }
    // Customer delete in DB -> get back updated list
    if (deleteCustomerContent && deleteCustomerContent.success) {
      this.reload()
      this.setState({
        customerContent: {},
        validMsg: 'the customer was successfully deleted !',
        isValidated: true,
      })
      this.resetValidMsg()
    }
    // customer can not be delete in DB -> it is used yet in quote
    if (deleteCustomerContent && !deleteCustomerContent.success) {
      this.setState({
        validateError: true,
        errorMsg: deleteCustomerContent.message,
      })
      RESET_CUSTOMER_CONTENT()
    }
    if (customerList !== customerListOld) {
      this.setState({
        customerList: _.sortBy(customerList, c => c.demandeur),
      })
    }
  }

  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_CUSTOMER_CONTENT,
      GET_CUSTOMER_LIST,
    } = this.props
    RESET_CUSTOMER_CONTENT()
    GET_CUSTOMER_LIST(userId)
  }

  // *******************************
  // On Input value change

  // On change customer
  onChangeCustomer = (event, newValue) => {
    const {
      customerList,
      isValidated,
    } = this.state
    if (!isValidated) {
      if (!confirm('You have not save changes ! Do you really want to continu ?')) {
        return null
      }
    }
    if (typeof newValue === 'string') {
      const valueExist = customerList.filter(d => d.demandeur === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          customerContent: {
            id_demandeur: null,
            demandeur: newValue,
          },
          actionLabel: 'Create',
          isNewCustomer: true,
          isValidated: false,
        });
      } else {
        this.setState({
          customerContent: valueExist[0],
          actionLabel: 'Modify',
          isNewCustomer: false,
          isValidated: true,
        });
      }
    } else if (!newValue) {
      this.setState({
        customerContent: {},
        actionLabel: 'Create',
        isNewCustomer: false,
        isValidated: true,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      this.setState({
        customerContent: {
          id_demandeur: null,
          demandeur: newValue.inputValue,
        },
        actionLabel: 'Create',
        isNewCustomer: true,
        isValidated: false,
      });
    } else {
      this.setState({
        customerContent: newValue,
        actionLabel: 'Modify',
        isNewCustomer: false,
        isValidated: true,
      });
    }
    this.setState({
      errorMsg: null,
    })
    return null
  }

  filterOptionsCustomer = (options, params) => {
    const { customerList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = customerList.filter(d => d.demandeur === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        demandeur: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  }

  getOptionLabelCustomer = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // after input clear
    if (!option.demandeur) {
      return ''
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.demandeur;
  }

  // save all other (other than title) new informations for this day
  handleChange = field => (event) => {
    const { customerContent } = this.state
    this.setState({
      customerContent: {
        ...customerContent,
        [field]: event.target.value,
      },
      isValidated: false,
    })
    if (field === 'nom_responsable_groupe' || field === 'prenom_responsable_groupe') {
      this.setState({
        validateError: false,
        errorMsg: null,
      })
    }
  }

  // ***************************
  // Display input

  // return autocomplete input for customer data
  displayAutocompleteCustomer = (field, content, list) => {
    const {
      validateError,
    } = this.state

    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item sm={10} xs={12}>
          <Autocomplete
            id={`${field}-field`}
            value={content}
            onChange={this.onChangeCustomer}
            filterOptions={this.filterOptionsCustomer}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={list}
            getOptionLabel={this.getOptionLabelCustomer}
            renderOption={option => option.demandeur}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label={_.capitalize(field)}
                margin="normal"
                placeholder={`Select a ${field} or create a new one`}
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Modify title" aria-label="modify" onClick={this.modifyItemTitle}>
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item sm={1} xs={12} className="option-div">
          <div className="option-div-css">
            <Tooltip title="Delete" aria-label="delete" onClick={this.deleteItem}>
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // return all other input
  displayDayContenInputs = () => {
    const { customerContent } = this.state
    return (
      <div>
        <Input
          id="lastname"
          label="Lastname"
          value={!_.isEmpty(customerContent) && customerContent.nom_responsable_groupe ? customerContent.nom_responsable_groupe : ''}
          onChange={this.handleChange('nom_responsable_groupe')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <Input
          id="firstname"
          label="Firstname"
          value={!_.isEmpty(customerContent) && customerContent.prenom_responsable_groupe ? customerContent.prenom_responsable_groupe : ''}
          onChange={this.handleChange('prenom_responsable_groupe')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <Input
          id="email"
          label="Email"
          value={!_.isEmpty(customerContent) && customerContent.e_mail ? customerContent.e_mail : ''}
          onChange={this.handleChange('e_mail')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <Input
          id="phone"
          label="Phone"
          value={!_.isEmpty(customerContent) && customerContent.telephone ? customerContent.telephone : ''}
          onChange={this.handleChange('telephone')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
        <Input
          id="society"
          label="Society"
          value={!_.isEmpty(customerContent) && customerContent.society ? customerContent.society : ''}
          onChange={this.handleChange('society')}
          margin="normal"
          variant="outlined"
          className="largeWidth"
        />
      </div>
    )
  }

  fillEmptyCustomerName = (e) => {
    e.preventDefault()
    const { customerContent } = this.state
    const {
      demandeur,
      nom_responsable_groupe,
      prenom_responsable_groupe,
    } = customerContent
    // Check all required inputs
    if (!customerContent.demandeur && !customerContent.nom_responsable_groupe && !customerContent.prenom_responsable_groupe) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill at least one of this inputs : customer, lastname or firstname',
      })
      return null
    }
    // Fill the customer identity
    if (!demandeur) {
      const name = _.trim(`${nom_responsable_groupe || ''} ${prenom_responsable_groupe || ''}`)
      this.setState({
        customerContent: {
          ...customerContent,
          demandeur: name,
        },
        isNewCustomer: true,
      })
    }
    this.setState({
      isValidated: true,
    })
    setTimeout(() => this.validate(), 200)
    return null
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      customerContent,
    } = this.state
    const customerId = customerContent.id_demandeur
    const { DELETE_CUSTOMER_CONTENT } = this.props

    if (confirm('Do you really want to delete this customer ?')) {
      if (customerId) {
        DELETE_CUSTOMER_CONTENT(token, customerId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: customerContent.demandeur ? 'This customer do not exist.' : 'Please, select a customer.',
        })
      }
    }
  }

  // Modify
  modifyItemTitle = () => {
    this.handleOpen()
  }

  // *******************************
  // Validate
  validate = () => {
    const {
      customerContent,
      isNewCustomer,
      token,
    } = this.state
    const {
      PUT_CUSTOMER_CONTENT,
    } = this.props

    const newCustomerContent = {
      ...customerContent,
      demandeur: customerContent.demandeur ? customerContent.demandeur : '',
      nom_responsable_groupe: customerContent.nom_responsable_groupe ? customerContent.nom_responsable_groupe : '',
      prenom_responsable_groupe: customerContent.prenom_responsable_groupe ? customerContent.prenom_responsable_groupe : '',
      e_mail: customerContent.e_mail ? customerContent.e_mail : '',
      telephone: customerContent.telephone ? customerContent.telephone : '',
    }

    if (isNewCustomer) PUT_CUSTOMER_CONTENT(token, newCustomerContent, 'create')
    else PUT_CUSTOMER_CONTENT(token, newCustomerContent, 'update')
  }

  // **************************
  // Modal action

  getModifiedItem = (item) => {
    const { customerContent } = this.state
    this.setState({
      customerContent: {
        ...customerContent,
        demandeur: item.customer,
      },
    }, () => this.handleClose())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
    setTimeout(() => {
      this.setState({
        isValidated: false,
      })
    }, 300);
  };

  // ***********************************************
  render() {
    const {
      customerList,
      customerContent,
      validMsg,
      validateError,
      errorMsg,
      isValidated,
      actionLabel,
      isModalOpen,
    } = this.state

    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>CUSTOMER MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteCustomer('customer', customerContent, customerList)}
              {this.displayDayContenInputs()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={_.isEmpty(customerContent)}
              onClick={e => this.fillEmptyCustomerName(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedItem(item)}
            disabled={false}
            title="Enter the new name for this customer"
            modalContent="customer"
            // warning="This will update this customer in all quotes !"
            type="customer"
            inputValue={!_.isEmpty(customerContent) ? customerContent.demandeur : ''}
          />
        </Template>
        <React.Fragment>
          <Prompt
            when={!isValidated}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customerList: state.customerReducer.customerList,
  putCustomerContent: state.customerReducer.putCustomerContent,
  deleteCustomerContent: state.customerReducer.deleteCustomerContent,
})

const mapDispatchToProps = dispatch => ({
  GET_CUSTOMER_LIST: userId => dispatch({
    type: 'GET_CUSTOMER_LIST',
    userId,
  }),
  PUT_CUSTOMER_CONTENT: (token, customerContent, action) => dispatch({
    type: 'PUT_CUSTOMER_CONTENT',
    token,
    customerContent,
    action,
  }),
  DELETE_CUSTOMER_CONTENT: (token, customerId) => dispatch({
    type: 'DELETE_CUSTOMER_CONTENT',
    token,
    customerId,
  }),
  RESET_CUSTOMER_CONTENT: () => dispatch({
    type: 'RESET_CUSTOMER_CONTENT',
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestorManagement))
