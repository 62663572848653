import React from 'react'

const Fonddefaut = {
  borderRadius: 5,
  textAlign: 'center',
  backgroundColor: '#F5B041',

};

const travelersColumns = [
  {
    name: 'id_traveler',
    label: 'ID',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'firstname',
    label: 'Firstname',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'lastname',
    label: 'Lastname',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'mail',
    label: 'Mail',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'sexe',
    label: 'Sexe',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'date_birth',
    label: 'Date birth',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'nationality',
    label: 'Nationality',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'job',
    label: 'Job',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'passport',
    label: 'Passport number',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'image',
    label: 'Scan Password',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'date_exp',
    label: 'Date exp password',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'nom_programme',
    label: 'Trip',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'nb_voyage',
    label: 'nb_voyage',
    options: {
      // eslint-disable-next-line consistent-return
      customBodyRender: (value) => {
        if (value > 1) {
          return (
            <div style={Fonddefaut}>{value}</div>
          )
        } return (
          <div>{value}</div>
        )
      },
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'date_arrival',
    label: 'Date arrival',
    options: {
      filter: true,
      sort: true,
      display: 'false',
    },
  },
  {
    name: 'Travel agent',
    label: 'Advisor',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
  {
    name: 'date_creation',
    label: 'Date creation',
    options: {
      filter: true,
      sort: true,
      display: 'true',
    },
  },
]

export default travelersColumns
